import './loaderOverlay.scss'
import template from './loaderOverlay.tpl.pug'

const loaderOverlay = {
  template: template(),
  bindings: {
    message: '@',
    showLoader: '<?',
  },
}

export default loaderOverlay
