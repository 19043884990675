import {
  errorParser,
  hideInvalid,
  getHttpCodes,
} from '../../../../../utils/managementUtils.js'

const TRANSITION_DELAY = 2000

export default class RouterInfoFormController {
  constructor($state, $timeout, ManagementService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.MS = ManagementService
    this.hideInvalid = hideInvalid
    this.errorParser = errorParser
  }

  $onInit() {
    this.route = {
      compSlug: this.campaign.company.slug,
      campSlug: this.campaign.slug,
      route_continue: ['500'],
    }
    this.state = {
      actionTitle: 'Route Setup',
      creationErrors: null,
      creatingRoute: false,
      askNextAction: false,
    }

    getHttpCodes().then(codes => {
      this.statusCodes = codes
    })
  }

  createRoute(route) {
    if (this.state.creationErrors) this.resetErrors()
    this.state.actionTitle = 'Creating'
    this.state.creatingRoute = true
    this.MS.createEntity({ entity: 'campaignRouter', data: route })
      .then(route => {
        this.$timeout(() => {
          this.state.actionTitle = 'Route Created'
          this.state.askNextAction = true
        }, TRANSITION_DELAY)
      })
      .catch(err => {
        console.error('Error creating campaign route: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, TRANSITION_DELAY)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingRoute = false
        }, TRANSITION_DELAY)
      })
  }

  createAnother() {
    this.route = {
      compSlug: this.campaign.company.slug,
      campSlug: this.campaign.slug,
      route_continue: [500],
    }
    this.state.actionTitle = 'Route Setup'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit() {
    this.$state.go('^.^', null, { reload: true })
  }

  resetErrors() {
    this.state.creationErrors = null
  }

  backTo() {
    this.$state.go('^.^')
  }
}
