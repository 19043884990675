// @flow

import type AuthService from '../../../../auth/services/AuthService.js'
import type SideNavService from '../../services/SideNavService.js'
import type { PermissionGroup, UserPermission } from 'permissions.js'
import type { Company, Campaign } from 'types/entities.js'
import type { MenuSection, SideNavMenu } from '../../services/SideNavService.js'

import { MANAGE_GROUP } from 'permissions.js'

export default class SideNavController {
  $mdSidenav: Function
  $state: Object
  AuthService: AuthService
  SNS: SideNavService
  MANAGE_GROUP: PermissionGroup
  company: Company
  campaign: Campaign
  menu: SideNavMenu
  userRoles: UserPermission
  activeIndex: ?number
  state: {|
    company: Company,
    campaign: Campaign,
    sideNav: {|
      activeMenuIndex: ?number,
      menu: SideNavMenu,
    |},
  |}

  constructor(
    $mdSidenav: Function,
    $state: Object,
    AuthService: AuthService,
    SideNavService: SideNavService,
  ) {
    'ngInject'
    this.$mdSidenav = $mdSidenav
    this.$state = $state
    this.AuthService = AuthService
    this.SNS = SideNavService
    this.MANAGE_GROUP = MANAGE_GROUP
    this.state = {
      company: this.company,
      campaign: this.campaign,
      sideNav: {
        activeMenuIndex: null,
        menu: [],
      },
    }
  }

  $onInit() {
    this.state.sideNav.menu = this.SNS.createMenuForRole(
      this.menu,
      this.userRoles,
    )
    this.activeIndex = this.state.sideNav.activeMenuIndex
    this.setActiveMenuElement()
  }

  includedState(section: MenuSection, currentState: string): boolean {
    if (section.pages !== undefined && section.pages.length > 0) {
      return section.pages
        .map(s => s.state)
        .concat(section.includedSubstates || [])
        .some(state => {
          const re = new RegExp(`^${state}.*`)
          return re.test(currentState)
        })
    } else {
      return section.state === currentState
    }
  }

  toggleSideNav(section: MenuSection, index: number) {
    if (section === undefined) {
      this.$mdSidenav('leftLarge').toggle()
      return
    }
    if (section.type != 'topLink') {
      if (index !== this.activeIndex) {
        this.toggleSection(index)
      }
      if (section.state === undefined) {
        this.$mdSidenav('leftLarge').toggle()
      }
    } else if (section.type === 'topLink') {
      this.toggleSection(index)
    }
  }

  toggleSection(index: number) {
    if (index === this.activeIndex) {
      this.activeIndex = null
      return
    }
    this.activeIndex = index
  }

  setActiveMenuElement() {
    let firstMenuElemWord
    for (let [index, menuElem] of this.state.sideNav.menu.entries()) {
      firstMenuElemWord = menuElem.name.split(' ')[0].toLocaleLowerCase()
      if (
        this.$state.current.name.includes(firstMenuElemWord) ||
        firstMenuElemWord.includes('manage')
      ) {
        this.activeIndex = index
      }
    }
  }

  isDashboardSection() {
    return this.$state.current.name.includes('dashboard')
  }
}
