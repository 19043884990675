// @flow

import type PitchService from 'global/index/api-services/PitchService'
import type ToastService from 'global/index/services/ToastService'
import type { FilterListConfig } from 'common-components/filter-list/component/filterList'
import type { Company, RapidResponseSegment } from 'types/entities'

import CPSD from '../../../../../dialogs/create-pitch-segments/createPitchSegments.js'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class CompanyRapidResponseSegmentsController {
  $mdDialog: Object
  $state: Object
  PS: PitchService
  TS: ToastService
  MS: Object
  RRS: Object
  company: Company
  columnsConfig: FilterListConfig
  endpoint: string
  state: {
    loading: boolean,
    rrSegments: RapidResponseSegment[],
  }

  constructor(
    PitchService: PitchService,
    ToastService: ToastService,
    ManagementService: Object,
    $mdDialog: Object,
    $state: Object,
  ) {
    'ngInject'
    this.PS = PitchService
    this.MS = ManagementService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.endpoint = 'rapid-response/segments'

    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'Type', accessor: 'type', type: 'text' },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      { title: 'Active', accessor: 'active', type: 'active' },
      {
        title: 'Edit',
        icon: 'editor:mode_edit',
        type: 'menu',
        menu: [
          {
            title: 'XML',
            icon: 'action:code',
            actionCb: segment => this.editSegment(segment),
          },
          {
            title: 'Variables',
            icon: 'action:visibility',
            actionCb: segment => this.goToVariables(segment),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: segment => this.deleteSegment(segment),
          },
        ],
      },
    ]

    this.state = {
      loading: true,
      rrSegments: [],
      topBarButtons: [
        {
          title: 'New Segment',
          icon: 'content:add',
          action: this.createSegment.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onChanges(bindings: Object) {
    if (bindings.company && !this.RRS) {
      this._setupRRS(this.company)
    }
    this.getSegments()
  }

  _setupRRS(company: Company) {
    let service = this.PS.setupRapidResponse(company.slug)
    if (service.company) {
      this.RRS = service.company.one('segments')
    }
  }

  getSegments() {
    this.RRS.get()
      .then(segments => {
        this.state.rrSegments = segments.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching rapid response segments',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  goToVariables(segment: RapidResponseSegment) {
    const params = {
      compUuid: this.company.uuid,
      compSlug: this.company.slug,
      uuid: segment.uuid,
      endpointRoute: this.endpoint,
      isRapidResponse: true,
    }
    this.$state.go('manage.editSegmentVariables', params)
  }

  editSegment(segment: RapidResponseSegment) {
    const company = this.company
    const locals = {
      isCreate: false,
      isRapidResponse: true,
      service: this.RRS,
      endpoint: this.endpoint,
      segment,
      company,
    }
    CPSD.locals = locals
    this.$mdDialog.show(CPSD)
  }

  createSegment(ev: Event) {
    const company = this.company
    const locals = {
      isCreate: true,
      isRapidResponse: true,
      service: this.RRS,
      endpoint: this.endpoint,
      segment: null,
      company,
    }
    CPSD.locals = locals
    this.$mdDialog
      .show(CPSD)
      .then(createdSegment => {
        this.state.rrSegments.push(createdSegment)
      })
      .catch(err => console.info('Create Dialog Closed'))
  }

  deleteSegment(segment: RapidResponseSegment) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Rapid Response Segment',
      entityName: segment.name,
      identityEndpoint: this.RRS.one(segment.uuid),
      entity: segment,
      routeWhenSuccessful: 'manage.editCompany.rapidResponseSegments',
    }
    this.$mdDialog.show(DED)
  }
}
