export const formOptions = [
  {
    accessor: 'name',
    inputType: 'input',
    label: 'Phrase Book',
    type: 'text',
    attributes: ['required'],
  },
  {
    accessor: 'slug',
    inputType: 'input',
    label: 'Slug',
    type: 'text',
    attributes: ['disabled'],
  },
]
