import ListsController from './controller/ListsController.js'
const template = require('./lists.tpl.pug')

const lists = {
  template: template(),
  controller: ListsController,
  bindings: {
    company: '<',
    campaign: '<',
    offsetMinutes: '<',
    realm: '<',
    listRealm: '<',
  }
}

export default lists
