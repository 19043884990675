import PitchDetailsController from './controller/PitchDetailsController.js'
const template = require('./pitchDetails.component.tpl.pug')

const pitchDetails = {
  template: template(),
  controller: PitchDetailsController,
  bindings: {
    pitchName: '<',
    pitchPanel: '<',
    pitchVersions: '<',
    pitchActiveVersion: '<',
    commitErrors: '<',
    updateErrors: '<',
    onCommit: '&',
    onUpdate: '&'
  }
}

export default pitchDetails

