/**
 * Created by amielsinue on 7/3/17.
 */
import './manageGlobalPitchPhraseBooks.component.scss'
import ManageGlobalPitchPhraseBooksController from './controller/ManageGlobalPitchPhraseBooksController.js'
const template = require('./manageGlobalPitchPhraseBooks.component.tpl.pug')

const manageGlobalPitchPhraseBooks = {
  template: template(),
  controller: ManageGlobalPitchPhraseBooksController,
  bindings: {},
}

export default manageGlobalPitchPhraseBooks
