import PasswordInfoFormController from './controller/PasswordInfoFormController.js'
const template = require('./passwordInfoForm.component.tpl.pug')

const passwordInfoForm = {
  template: template(),
  controller: PasswordInfoFormController,
  bindings: {
    user: '<',
    lastState: '<',
  }
}

export default passwordInfoForm
