// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService from '../../../../global/index/api-services/IdentityService.js'
import type { PermissionGroup } from 'permissions.js'
import type { DidPool } from 'types/entities.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../../common-components/management-search/component/managementSearch.component.js'

import { ADMIN_GROUP } from 'permissions.js'

export default class ManageDncService {
  Dids: Object
  mff: Object
  defaultEditRoles: PermissionGroup

  constructor(
    IdentityService: IdentityService,
    managementFilterFactory: Object,
  ) {
    'ngInject'

    this.Dids = IdentityService.Did
    this.mff = managementFilterFactory
    this.defaultEditRoles = ADMIN_GROUP
  }

  // Using getters makes it so we don't have to worry about mutation where these are used

  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 5,
      helperText: 'Try searching for a phonenumber',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Company',
        field: 'company',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'slug',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.companies, {
          assignable: false,
        }),
      },
      {
        label: 'Campaign',
        field: 'campaigns',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'slug',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.campaigns, {
          assignable: false,
        }),
      },
      {
        label: 'Active',
        field: 'active',
        hasAll: true,
        defaultValue: null,
        options: [
          { title: 'Active', value: true },
          { title: 'Inactive', value: false },
        ],
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Number', accessor: 'number', type: 'text' },
        { title: 'Timezone', accessor: 'timezone', type: 'text' },
        { title: 'Owned', accessor: 'owned', type: 'boolean' },
        {
          title: 'Pool',
          accessor: 'pool',
          type: 'text',
          parser: (pool: DidPool): ?string => {
            if (!pool) return null
            return pool.name
          },
        },
        { title: 'CName', accessor: 'cname_string', type: 'text' },
        { title: 'CName Valid', accessor: 'cname_valid', type: 'boolean' },
        { title: 'Active', accessor: 'active', type: 'active' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editDid',
        },
      ],
      optionalColumns: [],
    }
  }
}
