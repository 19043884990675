// @flow

import './managementAsync.component.scss'
import ManagementAsyncTableController from './managementAsyncTable.controller.js'
import template from './managementAsyncTable.component.tpl.pug'

type TableColumnConfig = {|
  title: string,
  accessor?: string,
  type: string,
  icon?: string,
  actionCb?: string,
  parser?: any => any,
|}

export type TableConfig = {|
  trackBy: string,
  columns: TableColumnConfig[],
  optionalColumns: TableColumnConfig[],
|}

const managementAsyncTable = {
  template: template(),
  controller: ManagementAsyncTableController,
  bindings: {
    entity: '@',
    entityPlural: '@',
    tableConfig: '<',
    isLoading: '<',
    tableData: '<',
    tableDataCount: '<',
    hasPagination: '<',
    refreshFunc: '&',
    onPaginate: '<',
    paginateCurrentPage: '<',
    paginateLimit: '<',
  },
}

export default managementAsyncTable
