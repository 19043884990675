import template from './sideNavShortIcons.tpl.pug'

const sideNavShortIcons = {
  template: template(),
  bindings: {
    page: '<',
    sectionIndex: '<',
    toggleSideNav: '&',
    active: '<',
    isDashboardSection: '&',
  },
}

export default sideNavShortIcons
