// Manage Realms
import { manageRealms, manageRealmsConfig } from './manage.realms.js'


export default function manageRealmsRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageRealms, manageRealmsConfig)    

}
