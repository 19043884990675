import { forEach, map, reduce } from 'ramda'

function combineHours(day, hour, hoursDialed, aggregate, wasDialed) {
  if (aggregate) {
    day.y += hour.y
    return day
  } else if (wasDialed) {
    day.dividend.value += hour.dividend.value
    day.dividend.name = hour.dividend.name
    day.divisor.value += hour.divisor.value
    day.divisor.name = hour.divisor.name
    day.y += hour.y / hoursDialed
    return day
  } else {
    return day
  }
}

function hourCombiner(acc, hour, hoursDialed, date) {
  return function(key) {
    if (!acc[key]) {
      acc[key] = {
        y: 0,
        x: date * 1000, // convert to milliseconds
        format: hour.metrics[key].format,
        aggregate: hour.metrics[key].aggregate || false,
        isRate: hour.metrics[key].isRate || false,
        hours: [],
      }
    }
    if (!acc[key].dividend && !acc[key].divisor) {
      acc[key].dividend = { name: '', value: 0 }
      acc[key].divisor = { name: '', value: 0 }
    }
    acc[key].hours.push(hour.metrics[key])
    acc[key] = combineHours(
      acc[key],
      hour.metrics[key],
      hoursDialed,
      hour.metrics[key].aggregate,
      hour.dialed,
    )
    // eslint-disable-next-line
    acc[key].y = acc[key].y
  }
}

function aggregateHoursToDay(acc, hour, hoursDialed, date) {
  const metricKeys = Object.keys(hour.metrics)
  forEach(hourCombiner(acc, hour, hoursDialed, date), metricKeys)
  return acc
}

function reduceMetricsForDay(hoursDialed, date) {
  return function(acc, hour) {
    acc = aggregateHoursToDay(acc, hour, hoursDialed, date)
    return acc
  }
}

function calculateMetricsForDay(hours, date) {
  const hoursDialed = hours.filter(hour => hour.dialed).length || 1
  return reduce(reduceMetricsForDay(hoursDialed, date), {}, hours)
}

function dayMapper(day) {
  day.metrics = calculateMetricsForDay(day.hours, day.date)
  return day
}

function calcMetricsByDay(stats) {
  return {
    days: map(dayMapper, stats.days),
    metrics: stats.metrics,
  }
}

export { calcMetricsByDay }
