import { filter, map, max, min, pipe, reduce } from 'ramda'
import roundTo from 'round-to'

export default class MetricHealthService {
  constructor($window, DualAxisSplineChart, MetricSeriesService) {
    'ngInject'

    this.$window = $window
    this.MSS = MetricSeriesService
    this.DAS = DualAxisSplineChart
    this.stripDatesAndZeros = map(day => {
      if (day.y) return day.y
    })
    this.getMax = reduce(max, -Infinity)
    this.getMin = reduce(min, Infinity)
    this.getValueMax = pipe(this.stripDatesAndZeros, this.getMax)
    this.getValueMin = pipe(this.stripDatesAndZeros, this.getMin)
  }

  getNormalizedAvg(dividend, divisorArr) {
    const hasValues = filter(day => day.y > 0, divisorArr)
    const divideBy = hasValues.length ? hasValues.length : 1
    return dividend / divideBy
  }

  getPercentChange(base, compare) {
    const percentChange = (compare - base) / base * 100
    return Number.isNaN(percentChange) ? 0 : percentChange
  }

  getTrend(n, sort) {
    const inverted = sort === 'ascending'
    if (n > 0) {
      return {
        class: inverted ? 'down' : 'up',
        icon: 'action:trending_up',
      }
    } else if (n < 0) {
      return {
        class: inverted ? 'up' : 'down',
        icon: 'action:trending_down',
      }
    } else {
      return {
        class: 'flat',
        icon: 'action:trending_flat',
      }
    }
  }

  getGoalHealth(value, goal) {
    if (!goal) {
      return null
    } else if (value >= goal.threshold.ok) {
      return { style: { 'background-color': '#2B957A' } }
    } else if (value < goal.threshold.ok && value >= goal.threshold.warn) {
      return { style: { 'background-color': '#eade05' } }
    } else {
      return { style: { 'background-color': '#dc3e3e' } }
    }
  }

  dayTooltipFormatter() {
    const dividendString = this.dividend.name
      ? `${this.dividend.name}: <b>${roundTo(this.dividend.value, 2)}</b></br>`
      : ''
    const divisorString = this.divisor.name
      ? `${this.divisor.name}: <b>${roundTo(this.divisor.value, 2)}</b></br>`
      : ''
    var tooltipMarkup = `
      <hr>
      ${dividendString}
      ${divisorString}
      <b>${this.series.name}</b>: <b>${this.format.prepend || ''}${roundTo(
      this.y,
      2,
    )}${this.format.append || ''}</b></br>
    `
    return tooltipMarkup
  }

  createFileName(metric) {
    const campaignName = this.$window.document.title.split(' ').join('_')
    const formattedTitle = metric.format.title
      .split(' ')
      .filter(char => char !== '/')
      .join('_')
      .concat('_')
      .concat(campaignName)
    return formattedTitle
  }

  createChartConfig(metric, max, min) {
    const formattedBaseRange = this.configFormatter(
      metric.key,
      metric.baseRange,
    )
    const formattedCompareRange = this.configFormatter(
      metric.key,
      metric.compareRange,
    )
    const chart = this.DAS.getChart()
    const series = {
      baseRange: {
        name: `${metric.format.title}`,
        data: formattedBaseRange.days,
        tooltip: {
          useHTML: true,
          pointFormatter: this.dayTooltipFormatter,
        },
        zIndex: 1,
      },
      compareRange: {
        name: `${metric.format.title}`,
        data: formattedCompareRange.days,
        tooltip: {
          useHTML: true,
          pointFormatter: this.dayTooltipFormatter,
        },
        zIndex: 2,
      },
    }

    if (series.compareRange.data.length === 1)
      series.baseRange.data.push(series.compareRange.data[0])
    this.MSS.registerMainSeries(metric, series)
    this.MSS.registerDrilldownSeries(metric, [
      ...formattedBaseRange.drillDownSeries,
      ...formattedCompareRange.drillDownSeries,
    ])
    chart.options.drilldown.series = [
      ...formattedCompareRange.drillDownSeries,
      ...formattedBaseRange.drillDownSeries,
    ]
    chart.options.tooltip.useHTML = true
    chart.options.exporting.filename = this.createFileName(metric)
    chart.options.exporting.chartOptions.title.text = metric.format.title
    chart.yAxis.plotLines = this.addPlotLines(metric, min, max, metric.goal)
    chart.series = [series.compareRange, series.baseRange]
    return chart
  }

  addPlotLines(metric, min, max, goal) {
    const plotLines = []
    if (goal) {
      const plotGoal = this.createPlotLineObject(
        'Goal',
        goal.threshold.ok,
        metric.format,
        '#008000',
      )
      plotLines.push(plotGoal)
    }
    const plotMax = this.createPlotLineObject(
      'Max',
      max,
      metric.format,
      '#FF0000',
    )
    const plotMin = this.createPlotLineObject(
      'Min',
      min,
      metric.format,
      '#0533ff',
    )
    plotLines.push(plotMax, plotMin)
    return plotLines
  }

  createPlotLineObject(label, value, format, hexColor) {
    return {
      label: {
        text: `${label} ${format.prepend || ''}${value}${format.append || ''}`,
      },
      color: hexColor,
      dashStyle: 'ShortDash',
      width: 2,
      value: value,
      zIndex: 0,
    }
  }

  configFormatter(key, metric) {
    const addDrilldown = (acc, day) => {
      const dayHours = {
        name: `${metric.format.title}`,
        id: `${day.x}_${key}_${metric.days.length}`,
        data: day.hours,
        tooltip: {
          useHTML: true,
          pointFormatter: function() {
            if (!this.isCalc) {
              return `
                      <hr></hr>
                      ${this.format.title}: <b>${this.format.prepend ||
                ''}${roundTo(this.y, 2)}${this.format.append || ''}</b></br>
                      `
            }

            return `
              <hr>
              ${this.dividend.name}: <b>${roundTo(
              this.dividend.value,
              2,
            )}</b></br>
              ${this.divisor.name}: <b> ${roundTo(
              this.divisor.value,
              2,
            )}</b></br>
              ${this.format.title}: <b>${this.format.prepend || ''}${
              this.y
            }${this.format.append || ''}</b></br>
            `
          },
        },
      }
      acc.drillDownSeries.push(dayHours)
      day.drilldown = `${day.x}_${key}_${metric.days.length}`
      acc.days.push(day)
      return acc
    }
    return reduce(addDrilldown, { drillDownSeries: [], days: [] }, metric.days)
  }
}
