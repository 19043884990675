// @flow

import './titleBarTabNav.scss'
import TitleBarTabNavController from './controller/TitleBarTabNavController.js'
import template from './titleBarTabNav.tpl.pug'

type TabConfig = {
  name: string,
  route: string,
  largeButton?: boolean,
}

export type NavItems = TabConfig[]

const titleBarTabNav = {
  template: template(),
  controller: TitleBarTabNavController,
  bindings: {
    backToRoute: '<?',
    entity: '<',
    entityName: '@?',
    entityNamePlural: '@?',
    hasNavDropdown: '<?',
    subNavSection: '@?', // if used this string should reference a menu section from the ManageMenuService
    isEditEntityPage: '<',
    navItems: '<',
  },
}

export default titleBarTabNav
