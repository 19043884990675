import AudioResourceController from './controller/AudioResourceController'
import template from './audioResource.tpl.pug'

const AudioResource = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: AudioResourceController
}

export default AudioResource

