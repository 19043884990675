import UpdateDidController from './controller/UpdateDidController.js'
import template from './updateDid.tpl.pug'

const updateDid = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: UpdateDidController,
}

export default updateDid
