import {
  CAMPAIGN_METRICS,
  LIST_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'averageTalkTimePerTransfer',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Average Talk Time / Transfer',
    append: '/min',
  },
}

const keys = {
  componentKeys: [
    CAMPAIGN_METRICS,
    LIST_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'transfer_talk_time', convertTo: 'minutes' },
  divisor: { stat: 'transfer' },
}

const averageTalkTimePerTransfer = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { averageTalkTimePerTransfer }
