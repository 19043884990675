import React, { useState, useEffect } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Input } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: 100 + '%',
  },
  inputFocuse: {
    borderBottom: '1px solid #1194f6',
    '& div.MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    }
  },
  label: {
    color: '#777777',
    fontSize: 16,
  },
  focusedLabel: {
    color: '#1194f6 !important',
  },
  underline: {
    '&:after': {
      borderBottom: `0.1em  solid #1194f6`,
    },
    '&:before': { borderBottom: '1px solid #d5d5d5' },
    '&:hover:before': {
      borderBottom: '0.1em solid #1194f6 !important',
      backgroundColor: 'transparent',
    },
  },
}))

const AppSelect = props => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        classes={{ root: classes.label, focused: classes.focusedLabel }}
        className={props.labelClass || classes.label}
        id="demo-simple-select-label"
        shrink={props.shrink}
      >
        {props.label}
      </InputLabel>
      <Select
        id="demo-simple-select-label"
        value={props.item}
        onChange={e => props.onChange(e.target.value)}
        IconComponent={ExpandMoreIcon}
        input={
          <Input
            classes={{
              underline: classes.underline,
              focused: classes.inputFocuse,
            }}
          />
        }
        InputProps={{
          classes: {
            underline: classes.underline,
            focused: classes.input,
          },
        }}
      >
        {typeof props.data !== undefined &&
          props.data.map(item => (
            <MenuItem
              key={props.keyName !== 'undefined' ? item[props.keyName] : item}
              value={item}
            >
              {typeof item === 'object' ? item[props.displayName] : item}
            </MenuItem>
          ))}
      </Select>
      {props.helpText !== undefined && (
        <FormHelperText>{props.helpText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default AppSelect
