// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncTable from 'common-components/async-table/AsyncTable'
import TableSearch from 'common-components/table-search-prompt/TableSearch'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    classes: Object,
    active: Boolean,
}

const styles = {
    root: {
        margin: '20px 0',
        width: '100%',
    },
    header: {
        color: '#444851',
        fontSize: '14px',
        backgroundColor: '#eeeeee',
        borderRadius: '3px'
    },
    appBar: {
        backgroundColor: 'transparent',
    },
    cellStyle: {
        color: '#444851',
        padding: '10px 0 10px 16px !important'
    }
}


function CallHistory(props: Props) {
    const [searchField, setSearchField] = useState('');
    const [list, setList] = useState([{
        uuid: 'baa28e0f7...', called: 'Feb 10, 2019 \n1:55 p.m.', status: 'No answer',
        disposition: '-', revenue: '-', duration: '44', direction: 'Outbound',
        cause: 'Normal_clearing', did: '1100946729', rep: '-', location: '-'
    },
    {
        uuid: 'a228e0f7...', called: 'Jan 2, 2019 \n3:55 p.m.', status: 'No answer',
        disposition: '-', revenue: '-', duration: '44', direction: 'Outbound',
        cause: 'Normal_clearing', did: '3903946729', rep: '-', location: '-'
    }]);
    const [loadingData, setLoadingData] = useState(false);
    var copyList = list;
    //var filteredData = copyList.filter(item => item.name.includes(searchField));

    console.log(list)
    useEffect(() => {
        /* axios.
            get(`${API_BASE_URL}/identity/campaign/list/`)
            .then(response => {
                setLoadingData(false)
                setList(response.data)
            })
            .catch(console.error) */

    }, []);

    const Loader = () => (
        <Grid alignItems="center"
            container
            direction="column"
            justify="center"
            style={{ margin: '40px 0' }}>
            <Grid item>
                <CircularProgress size={55} style={{ color: '#777777' }} />
            </Grid>
            <Grid item>
                <p>Loading data...</p>
            </Grid>
        </Grid >
    )


    function desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }

    const HistoryTableRow = ({
        element,
        rowClass,
        cellClass,
    }: {
        row: Object,
        rowClass: string,
        cellClass: string,
    }) => (
            <TableRow className={rowClass}>
                <TableCell className={cellClass}>{element.uuid}</TableCell>
                <TableCell className={cellClass}>{element.called}</TableCell>
                <TableCell className={cellClass}>{element.status}</TableCell>
                <TableCell className={cellClass}>{element.disposition}</TableCell>
                <TableCell className={cellClass}>{element.revenue}</TableCell>
                <TableCell className={cellClass}>{element.duration}</TableCell>
                <TableCell className={cellClass}>{element.direction}</TableCell>
                <TableCell className={cellClass}>{element.cause}</TableCell>
                <TableCell className={cellClass}>{element.did}</TableCell>
                <TableCell className={cellClass}>{element.rep}</TableCell>
                <TableCell className={cellClass}>{element.location}</TableCell>
            </TableRow>
        )


    return (
        <React.Fragment>
            <TableSearch
                placeholder="Search"
                handleChange={e => console.log('searching')} />
            <AsyncTable
                loading={loadingData}
                Loader={
                    <Loader />
                }
                tableData={list}
                headers={['UUID', 'Called', 'Status', 'Disposition', 'Revenue', 'Duration', 'Direction', 'Hangup cause', 'Prospect DID', 'Rep', 'Location']}
                render={(listData, { row, cell, icon }) =>
                    listData.map(element => (
                        <HistoryTableRow
                            key={element.uuid}
                            element={element}
                            rowClass={row}
                            cellClass={[cell, props.classes.cellStyle].join(' ')}
                            iconClass={icon}
                        />
                    ))
                }
            />
        </React.Fragment>
    )
}


export default withStyles(styles)(CallHistory)
