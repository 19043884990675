// Manage Global Pitch Segments
import {
  manageGlobalPitchSegments,
  manageGlobalPitchSegmentsConfig,
} from './manage.globalPitchSegments'

export default function manageGlobalPitchSegmentsRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(
    manageGlobalPitchSegments,
    manageGlobalPitchSegmentsConfig,
  )
}
