import ManageAccessListsController from './controller/ManageAccessListsController.js'
const template = require('./manageAccessLists.component.tpl.pug')

const manageAccessLists = {
  template: template(),
  controller: ManageAccessListsController,
  bindings: {
    compSlug: '<',
    pageTitle: '<'
  }
}

export default manageAccessLists
