// Manage Global Rapid Response
import {
  manageGlobalRapidResponse,
  manageGlobalRapidResponseConfig,
} from './manage.globalRapidResponse'
import {
  manageGlobalRapidResponseTests,
  manageGlobalRapidResponseTestsConfig,
} from './child-states/rapid-response-test/manage.globalRapidResponseTests'
import {
  manageGlobalRapidResponseSegments,
  manageGlobalRapidResponseSegmentsConfig,
} from './child-states/rapid-response-segments/manage.globalRapidResponse.segments'

export default function manageGlobalRapidResponseRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageGlobalRapidResponse, manageGlobalRapidResponseConfig)
    .state(manageGlobalRapidResponseTests, manageGlobalRapidResponseTestsConfig)
    .state(
      manageGlobalRapidResponseSegments,
      manageGlobalRapidResponseSegmentsConfig,
    )
}
