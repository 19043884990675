// @flow

import * as React from 'react'
import { useState } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'

import { theme } from 'theme'

import { makeStyles } from '@material-ui/core/styles'

import AppModal from '../../../components/AppModal.js'
import { Tab, Tabs } from '@material-ui/core'
import AddSingleUser from './AddSingleUser.js'
import BulkCreateUser from './BulkCreateUser.js'

const useStyles = makeStyles(theme => ({
  line: {
    width: 100 + '%',
    borderBottom: '1px solid #d5d5d5',
  },
  active: {
    '& span.MuiFormControlLabel-label': {
      color: '#777777',
      fontSize: 16,
    },
  },
}))


const ManageUsers = (props) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(props.$state.router.globals.current.component === 'personalInfoForm')
  const [tab, setTab] = useState(0)
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }
  const closeModal = () => {
    props.$state.go('manage.users')
  }
 
  return (
        <MuiThemeProvider theme={theme} >
          <AppModal
            modalWith={tab === 0 ? "80%" : "50%"}
            showModal={openModal}
            hideModal={closeModal}
            title="Add New User"
            
          >
            <Paper className={classes.root}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Single Create" />
                <Tab label="Bulk Create"/>
              </Tabs>
            </Paper>

            <div>
              {
                tab === 0 ? (
                  <React.Fragment>
                    <AddSingleUser {...props} closeModal={closeModal} />
                  </React.Fragment>                  
                ) : (
                  <React.Fragment>
                    <BulkCreateUser {...props} closeModal={closeModal}/>
                  </React.Fragment>
                )
              }
            </div>
          </AppModal>
          
        </MuiThemeProvider>

    )
}

export default ManageUsers
