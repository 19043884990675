import moment from 'moment'
import { filter, map, min, max, reduce } from 'ramda'
import roundTo from 'round-to'
import { calculateMetrics } from 'util/calculate/metrics/calculateMetrics.js'

export default class GatewayService {
  constructor($q, StatsService, MetricsService, GoalsService) {
    'ngInject'
    this.$q = $q
    this.GS = GoalsService
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.getComponentMetrics = MetricsService.getMetrics.bind(MetricsService)
    this.calculateMetrics = calculateMetrics
    this.buildMetrics = map(this.builder.bind(this))
  }

  getMetrics(campaigns, component) {
    const hydratedCampaigns = this.addComponentDetails(campaigns, component)
    const promisedCampaigns = this.makeCampaignRequests(hydratedCampaigns)
    return this.$q
      .all(promisedCampaigns)
      .then(this.buildMetrics)
      .then(this.compareMetrics.bind(this))
      .then(this.orderByActive)
  }

  orderByActive(data) {
    const seperator = (acc, campaign) => {
      switch (campaign.data) {
        case 'inactive':
          acc.inactive.push(campaign)
          break
        case 'paused':
          acc.paused.push(campaign)
          break
        default:
          acc.active.push(campaign)
      }
      return acc
    }
    const seperated = reduce(
      seperator,
      { active: [], paused: [], inactive: [] },
      data,
    )
    const combined = [
      ...seperated.active,
      ...seperated.paused,
      ...seperated.inactive,
    ]
    return combined
  }

  compareMetrics(campaigns) {
    const compare = campaign => {
      if (campaign.data === 'inactive' || campaign.data === 'paused')
        return { data: campaign.data, details: campaign.details }
      const newData = this.compareRanges(campaign.metrics)
      return {
        data: newData,
        details: campaign.details,
      }
    }
    return map(compare, campaigns)
  }

  compareRanges(metrics) {
    const calc = metric => {
      metric.average = metric.baseRange.aggregate
        ? this.getNormalizedAvg(metric.baseRange.y, metric.baseRange.days)
        : metric.baseRange.y
      metric.percentChange = this.getPercentChange(
        metric.compareRange.y,
        metric.baseRange.y,
      )
      metric.goal = metric.goal
        ? this.compareGoal(metric.compareRange.y, metric.goal)
        : { style: metric.percentChange.trend.style }
      metric.min = this.getMin(metric.baseRange.days)
      metric.max = this.getMax(metric.baseRange.days)
      return metric
    }
    return map(calc, metrics)
  }

  compareGoal(value, goal) {
    if (goal.ok === null) {
      return {
        threshold: null,
      }
    } else if (value >= goal.ok) {
      return {
        value: goal.ok,
        threshold: 'ok',
      }
    } else if (value < goal.ok && value > goal.danger) {
      return {
        value: goal.ok,
        threshold: 'warning',
      }
    } else {
      return {
        value: goal.ok,
        threshold: 'danger',
      }
    }
  }

  getMin(data) {
    const filtered = map(day => {
      if (day.y) return day.y
    })
    const minRes = reduce(min, Infinity, filtered(data))
    return isFinite(minRes) ? min : 0
  }

  getMax(data) {
    const filtered = map(day => {
      if (day.y) return day.y
    })
    const dataMax = reduce(max, -Infinity, filtered(data))
    return isFinite(dataMax) ? dataMax : 0
  }

  getNormalizedAvg(baseRange, baseRangeByDay) {
    const daysWithRevision = filter(day => day.y > 0, baseRangeByDay).length
    return roundTo(baseRange / daysWithRevision, 2) || 0
  }

  getPercentChange(today, average) {
    const percentChange = ((today - average) / average) * 100
    const value = Number.isNaN(percentChange) ? 0 : roundTo(percentChange, 2)
    if (value > 0) {
      return {
        value: value,
        trend: {
          style: { fill: 'green' },
          icon: 'action:trending_up',
        },
      }
    } else if (value < 0) {
      return {
        value: value,
        trend: {
          style: { fill: 'red' },
          icon: 'action:trending_down',
        },
      }
    } else {
      return {
        value: value,
        trend: {
          style: { fill: 'gray' },
          icon: 'action:trending_flat',
        },
      }
    }
  }

  builder(campaign) {
    if (campaign.data === 'inactive' || campaign.data === 'paused')
      return campaign
    const campaignMetrics = this.getComponentMetrics(campaign.details.metrics)
    const baseRange = {
      metrics: campaignMetrics.call,
      stats: [...campaign.baseRange.results],
    }
    const compareRange = {
      metrics: campaignMetrics.call,
      stats: [...campaign.compareRange.results],
    }
    const rangesCalculated = {
      baseRange: this.calculateMetrics(baseRange, 'campaign'),
      compareRange: this.calculateMetrics(compareRange, 'campaign'),
    }
    const mergedRanges = this.addGoals(
      this.mergeRanges(rangesCalculated),
      campaign.goals,
    )
    delete campaign.baseRange
    delete campaign.compareRange
    campaign.metrics = mergedRanges
    return campaign
  }

  addGoals(ranges, goals) {
    goals.forEach(goal => {
      if (goal.active) ranges[goal.key].goal = goal.threshold
    })
    return ranges
  }

  mergeRanges(ranges) {
    const keys = Object.keys(ranges.baseRange)
    const merger = (acc, key) => {
      acc[key] = {
        key: key,
        format: ranges.baseRange[key].format,
        baseRange: ranges.baseRange[key],
        compareRange: ranges.compareRange[key],
      }
      return acc
    }
    return reduce(merger, {}, keys)
  }

  addComponentDetails(campaigns, component) {
    const hydrator = campaign => {
      const startDate = moment
        .utc()
        .utcOffset(campaign.default_timezone_offset_minutes)
        .startOf('day')
      const endDate = moment(startDate).endOf('day')
      campaign.compareRangeDetails = {
        context: 'campaign',
        compSlug: campaign.company_slug,
        campSlug: campaign.slug,
        epochStart: startDate.unix(),
        epochEnd: endDate.unix(),
        requestTime: moment().startOf('minute'),
      }
      campaign.baseRangeDetails = {
        context: 'campaign',
        historical: true,
        compSlug: campaign.company_slug,
        campSlug: campaign.slug,
        epochStart: moment(startDate)
          .subtract(89, 'days')
          .unix(),
        epochEnd: endDate.subtract(1, 'days').unix(),
        requestTime: moment().startOf('minute'),
      }
      campaign.context = component.context
      campaign.companyId = campaign.company
      campaign.company = campaign.company_slug
      campaign.campaign = campaign.slug
      campaign.metrics = component.defaultMetrics
      return campaign
    }
    return map(hydrator, campaigns)
  }

  makeCampaignRequests(campaigns) {
    const requester = campaign => {
      if (!campaign.active) {
        return { data: 'inactive', details: campaign }
      } else if (campaign.paused) {
        return { data: 'paused', details: campaign }
      }
      return this.$q.all({
        baseRange: this.promisifyStats(campaign.baseRangeDetails),
        compareRange: this.promisifyStats(campaign.compareRangeDetails),
        goals: this.GS.getMetricGoals({
          compSlug: campaign.company_slug,
          campSlug: campaign.slug,
        }),
        details: campaign,
      })
    }
    return map(requester, campaigns)
  }
}
