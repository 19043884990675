import './campaignPitch.scss'
import CampaignPitchController from './controller/CampaignPitchController.js'
const template = require('./campaignPitch.component.tpl.pug')

const campaignPitch = {
  controller: CampaignPitchController,
  template: template(),
  bindings: {
    user: '<',
    campaign: '<',
    company: '<',
    urlProp: '@',
    currentTab: '@',
  },
}

export default campaignPitch
