import userBulkUploadDialog from '../../../dialogs/user-bulk-upload/userBulkUploadDialog.js'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import { contains, isEmpty, map } from 'ramda'
import { SUPER_USER_ONLY, TEAM_LEADER_GROUP } from 'permissions.js'
import { groupFilter } from 'util/permissions/groups.js'

export default class EditTeamController {
  constructor(
    $state,
    $mdDialog,
    ManagementService,
    EditTeamService,
    ManageUsersService,
    ToastService,
    IdentityService,
  ) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.groups = IdentityService.Groups
    this.ETS = EditTeamService
    this.MS = ManagementService
    this.MUS = ManageUsersService
    this.TS = ToastService
    this.SUPER_USER_ONLY = SUPER_USER_ONLY
    this.state = {
      locations: [],
      searchList: [],
      addQueue: {},
      removeQueue: {},
      members: [],
      allowedLocationLeaderGroups: null,
      editTeam: {
        show: false,
      },
    }

    this.onSearchUpdate = input => {
      this.MS.findEntity({
        entity: 'user',
        searchText: input.searchText,
        editable: true,
      }).then(results => {
        this.state.searchList = results
      })
    }
  }

  $onInit() {
    this.editableTeam = Object.assign({}, this.team)
    this.getTeamLeader(this.team, 'team')
    this.getLocationOptions(['location'])
    this.getTeamMembers(this.team)
    this.getLeaderGroupPks()
  }

  getTeamLeader(team, entityName) {
    this.MS.populateEntityRelations(team, { entityName })
      .then(relations => {
        this.teamLeaderObj = relations.leader
        this.editableTeam.leader = relations.leader
      })
      .catch(err => {
        this.TS.show({
          text: 'Error fetching team leader',
        })
        console.error(err)
      })
  }

  searchUsers(searchText) {
    const searchConfig = {
      entity: 'user',
      searchText,
      editable: true,
      groups: this.allowedLocationLeaderGroups,
    }
    return this.MS.findEntity(searchConfig)
      .then(users => users)
      .catch(err => {
        this.TS.show({
          text: 'An error occurred while searching',
        })
        console.error(err)
      })
  }

  getLeaderGroupPks() {
    this.groups.list
      .get()
      .then(groups => groups.plain())
      .then(groupFilter(TEAM_LEADER_GROUP))
      .then(map(gr => gr.pk))
      .then(groups => {
        this.allowedLocationLeaderGroups = groups
      })
  }

  parseTeamLeader(leader) {
    if (!leader) return 'None'
    return `${leader.username} | ${leader.first_name} ${leader.last_name}`
  }

  getLocationOptions(relations) {
    this.MS.getRelationOptions(relations, { editable: false })
      .then(options => {
        this.state.locations = options.location
      })
      .catch(err => {
        this.TS.show({
          text: 'An error occurred while getting location options',
        })
        console.error(err)
      })
  }

  updateTeam(team) {
    const parsedTeam = this.parseTeam(team)
    this.MS.updateEntity({ entity: 'team', data: parsedTeam })
      .then(updatedTeam => {
        this.team = updatedTeam
        this.editableTeam = Object.assign({}, updatedTeam)
        this.getTeamLeader(updatedTeam, 'team')
        this.teamForm.$setPristine()
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error updating team',
        })
      })
  }

  parseTeam(team) {
    const leaderId = team.leader ? team.leader.uuid : null
    return Object.assign({}, team, { leader: leaderId })
  }

  getTeamMembers(team) {
    const params = { team: team.uuid }
    this.MS.getEntityList(params, 'user').then(members => {
      this.state.members = members.results
    })
  }

  goToTeamLocation(locationId) {
    this.$state.go('manage.editLocation', { uuid: locationId })
  }

  cancelMove() {
    this.state.members.forEach(member => {
      member.selected = false
    })
    this.state.nonMembers.forEach(member => {
      member.selected = false
    })
    this.state.addMap = {}
    this.state.removeMap = {}
  }

  removeMembers(removeQueue, team) {
    this.ETS.removeMembers(removeQueue, team)
      .then(removedMembersIds => {
        this.state.members = this.state.members.filter(
          member => !contains(member.uuid, removedMembersIds),
        )
      })
      .catch(err => {
        this.TS.show({
          text: 'Error removing members',
        })
        console.error('Error removing members ', err)
      })
  }

  addMembers(addQueue, team) {
    this.ETS.addMembers(addQueue, team)
      .then(addedMembers => {
        this.state.members.push(...addedMembers)
        this.state.addQueue = {}
      })
      .catch(err => {
        this.TS.show({
          text: 'Error adding members',
        })
        console.error('Error adding members', err)
      })
  }

  addUserToQueue(user) {
    if (!this.state.addQueue[user.uuid]) {
      this.state.addQueue[user.uuid] = user
    }
  }

  toggleRemove(user) {
    const queueUser = this.state.removeQueue[user.uuid]
    if (queueUser) {
      user.selected = false
      delete this.state.removeQueue[user.uuid]
      return
    }
    user.selected = true
    this.state.removeQueue[user.uuid] = user
  }

  queueLength(queueObj) {
    return isEmpty(queueObj)
  }

  removeUserFromQueue(user) {
    delete this.state.addQueue[user.uuid]
  }

  removeAllFromQueue() {
    this.state.addQueue = {}
  }

  toggleSection(section) {
    this.state[section].show = !this.state[section].show
  }

  cancelEdit(form) {
    this.editableTeam = Object.assign({}, this.team, {
      leader: this.teamLeaderObj,
    })
    form.$setPristine()
  }

  findUser(searchText) {
    this.MUS.findUser(searchText, true)
      .then(users => {
        this.state.searchList = users.filter(
          user => user.team !== this.team.uuid,
        )
      })
      .catch(err => {
        console.error('User Search Error', err)
      })
  }

  openBulkUpload(team, location) {
    const UBUD = userBulkUploadDialog
    UBUD.locals = {
      team,
      location,
    }
    this.$mdDialog.show(UBUD).then(createdUsers => {
      this.state.members = this.state.members.concat(createdUsers)
    })
  }

  openDeleteDialog(team) {
    const DTD = deleteEntityDialog
    DTD.locals = {
      title: 'Delete Team',
      entityName: team.name,
      identityEndpoint: this.MS._Team.fetch.one(team.uuid),
      entity: team,
      routeWhenSuccessful: 'manage.locations',
    }
    this.$mdDialog.show(DTD)
  }
}
