import Papa from 'papaparse'
import { map } from 'ramda'
import moment from 'moment'

export default class StackChartController {
  constructor($timeout, RepRankingsService) {
    'ngInject'
    this.$timeout = $timeout
  }

  $onInit() {
    this.makeCsv = this.getCsvData.bind(
      this,
      this.chartConfig,
      this.dateRange.startDate,
      this.dateRange.endDate,
    )

    this.state = {
      showChart: false,
    }
  }

  toggleChart(chart) {
    this.state.showChart = !this.state.showChart
    if (this.state.showChart) {
      const chartRef = chart.getHighcharts()
      this.$timeout(() => {
        chartRef.reflow()
      }, 100)
    }
  }

  parseRepChart(data) {
    const formatted = map(rep => {
      const format = {}
      format.agent = rep.rep
      format[rep.dividend.name] = rep.dividend.value
      format[rep.divisor.name] = rep.divisor.value
      format[rep.format.title] = rep.y
      format.unit = rep.format.append
      return format
    }, data)
    const json = JSON.stringify(formatted)
    return Papa.unparse(json)
  }

  createFileName(name, startDate, endDate) {
    const startStr = moment(startDate).format('M-D-YYYY')
    const endStr = moment(endDate).format('M-D-YYYY')
    return `reps_${name}_${startStr}_${endStr}.csv`
  }

  getCsvData(chartConfig, startDate, endDate) {
    const rawData = chartConfig.series[0].data
    const fileName = this.createFileName(chartConfig.key, startDate, endDate)
    const data = this.parseRepChart(rawData)
    return {
      fileName,
      data,
    }
  }
}
