import {
  simpleDivision,
  simpleDivisionNormalized,
  simpleDivisionByRep,
  simpleRate,
  simpleRateByRep,
  hangupCauseRate,
  passStat } from 'util/calculate/metrics/calculation/metricCalculators.js'

function buildAggregator (metaData, calcConfig) {
  const aggregator = {
    calc: passStat,
    aggregate: true,
    isRate: false,
    isCalc: false,
  }
  metaData.format = Object.assign(metaData.format, { trend: false })

  return Object.assign(metaData, calcConfig, aggregator)
}

function buildSimpleDivision (metaData, calcConfig) {
  const divide = {
    calc: simpleDivision,
    calcByRep: simpleDivisionByRep,
    isCalc: true,
    isRate: false,
  }
  metaData.format = Object.assign(metaData.format, { trend: true })
  return Object.assign(metaData, calcConfig, divide)
}

function buildSimpleRate (metaData, calcConfig) {
  const rate = {
    calc: simpleRate,
    calcByRep: simpleRateByRep,
    isCalc: true,
  }
  metaData.format = Object.assign(metaData.format, { trend: true, append: '%' })
  return Object.assign(metaData, calcConfig, rate)
}

function buildHangupCauseRate (metaData, calcConfig) {
  const rate = {
    calc: hangupCauseRate,
    isCalc: true,
    isRate: true
  }
  metaData.format = Object.assign(metaData.format, { trend: true, append: '%' })
  return Object.assign(metaData, calcConfig, rate)
}

function buildDivideNormalized (metaData, calcConfig) {
  const divideNormalized = {
    calc: simpleDivisionNormalized,
    isCalc: true,
    isRate: false,
  }
  return Object.assign(metaData, calcConfig, divideNormalized)
}

function addMethods (metaData, calcConfig) {
  switch (calcConfig.calcType) {
    case 'aggregate':
      return buildAggregator(metaData, calcConfig)
    case 'divide':
      return calcConfig.isRate
        ? buildSimpleRate(metaData, calcConfig)
        : buildSimpleDivision(metaData, calcConfig)
    case 'divideNormalized':
      return buildDivideNormalized(metaData, calcConfig)
    case 'hangupCause':
      return buildHangupCauseRate(metaData, calcConfig)
  }
}

function getCalculationMethods (metaData, calcConfig) {
  if (!calcConfig) return null
  return addMethods(metaData, calcConfig)
}

export { getCalculationMethods }
