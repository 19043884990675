// @flow

import type { SearchConfig } from '../../../../../common-components/management-search/component/managementSearch.component.js'
import type { TableConfig } from '../../../../../common-components/management-async-table/component/managementAsyncTable.component.js'

export default class DidTableService {
  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 5,
      helperText: 'Try searching for a phone-number',
      searchValue: '',
      searchResults: [],
    }
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Number', accessor: 'number', type: 'text' },
        /*{
          title: 'Rotate',
          type: 'button-left',
          icon: 'action:settings_backup_restore',
          actionCb: 'rotateDid',
        },*/
        { title: 'Timezone', accessor: 'timezone', type: 'text' },
        { title: 'Active', accessor: 'active', type: 'active' },
        { title: 'Owned', accessor: 'owned', type: 'boolean' },
        { title: 'CName', accessor: 'cname_string', type: 'text' },
        { title: 'CName Valid', accessor: 'cname_valid', type: 'boolean' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editDid',
        },
      ],
      optionalColumns: [],
    }
  }

  get rotatedTableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Number', accessor: 'number', type: 'text' },
        { title: 'Timezone', accessor: 'timezone', type: 'text' },
        { title: 'Status', accessor: 'active', type: 'active' },
        { title: 'Owned', accessor: 'owned', type: 'boolean' },
        { title: 'Actual CNam', accessor: 'cname_string', type: 'text' },
        { title: 'CNam valid', accessor: 'cname_valid', type: 'boolean' },
        /*{
          title: 'Re-activate',
          type: 'button',
          actionCb: 'reactivateDid',
        },*/
      ],
      optionalColumns: [],
    }
  }
}
