import './audioDialog.scss'
import AudioDialogController from './controller/AudioDialogController.js'
const template = require('./audioDialog.dialog.tpl.pug')

const audioDialogDialog = {
  parent: angular.element(document.body),
  template: template(),
  bindToController: true,
  controllerAs: '$ctrl',
  controller: AudioDialogController,
}

export default audioDialogDialog

