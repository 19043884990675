import './pitchPhraseBooks.component.scss'
import PitchPhraseBooksController from './controller/PitchPhraseBooksController.js'
const template = require('./pitchPhraseBooks.component.tpl.pug')

const pitchPhraseBooks = {
  template: template(),
  controller: PitchPhraseBooksController,
  bindings: {
    companyService: '<',
    globalService: '<',
    phraseBooks: '<',
    onUpdate: '&'
  }
}

export default pitchPhraseBooks
