import { permissions, MANAGE_GROUP } from 'permissions.js'
import { makePageTitle } from '../../../utils/resolve/pageTitle.js'

const { RECORDER } = permissions

// manage resources view

const MANAGE_AUDIO_VOICE = 'manage.audioVoice'
const pageTitleConfig = { title: 'Recording' }

const manageAudioVoiceConfig = {
  url: '/audio/voice',
  component: 'manageAudioVoice',
  data: {
    permissions: {
      only: [...MANAGE_GROUP, RECORDER],
    },
  },
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  },
}

export { MANAGE_AUDIO_VOICE, manageAudioVoiceConfig }
