import './activeToggle.scss'
import template from './activeToggle.tpl.pug'

const activeToggle = {
  template: template(),
  bindings: {
    label: '@',
    active: '<',
    disabled: '<',
    onToggle: '&',
  },
}

export default activeToggle
