import AgentController from './controller/AgentController.js'
const template = require('./agents.tpl.pug')

const dashboardAgents = {
  template: template(),
  controller: AgentController,
  bindings: {
    company: '<',
    campaign: '<',
    realm: '<'
  }
}

export default dashboardAgents
