import DateRangePickerController from './controller/DateRangePickerController.js'
import './dateRangePicker.scss'
const template = require('./dateRangePicker.tpl.pug')

const dateRangePicker = {
  template: template(),
  controller: DateRangePickerController,
  bindings: {
    dateTime: '<',
    dateRange: '=',
    timezoneOffset: '<',
    dateRangeLoading: '<loadingWatcher'
  }
}

export default dateRangePicker
