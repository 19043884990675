// create companies info view

const manageCompaniesCreateCompanyInfo = 'manage.companies.create.companyInfo'

const manageCompaniesCreateCompanyInfoConfig = {
  url: '/create',
  component: 'createCompanyInfoForm'
}

export { manageCompaniesCreateCompanyInfo, manageCompaniesCreateCompanyInfoConfig }
