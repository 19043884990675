// Edit Globa Phrase Book View

const manageEditPhraseBook = 'manage.editPhraseBook'

const manageEditPhraseBookConfig = {
  url: '/phrase-book/global/edit/{phraseBookId}',
  params: {
    phraseBookId: null,
    phraseBook: null,
    previousRoute: null,
  },
  component: 'editPhraseBook',
  resolve: {
    phraseBookId: function($transition$) {
      'ngInject'
      return $transition$.params().phraseBookId
    },
    service: function(phraseBookId, PitchService) {
      'ngInject'
      return PitchService.setupPhraseBook({ phraseBookId })
    },
    phraseBook: function($transition$, service, phraseBookId) {
      'ngInject'
      const phraseBook = $transition$.params().phraseBook
      if (phraseBook) return phraseBook
      return service.base
        .one()
        .get()
        .then(phraseBook => phraseBook.plain())
    },
    previousRoute: function() {
      'ngInject'
      return {
        state: 'manage.globalPitchPhraseBooks',
        routerHref: 'manage.globalPitchPhraseBooks',
        params: null,
      }
    },
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Editing Phrase Book'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageEditPhraseBook, manageEditPhraseBookConfig }
