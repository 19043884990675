import { identity, memoizeWith } from 'ramda'

export default class StatsService {
  constructor(Restangular, Report, RealmService, ReportService) {
    'ngInject'

    this.RealmService = RealmService
    this.ReportService = ReportService
    this.getStats = memoizeWith(identity, this.statFetcher)
    this.lastRequest = {
      isClean: true,
      time: {},
      realm: null,
    }

    this.dialerLive = {
      get({ realms, campaigns }) {
        const url = `/live/dialer`
        return this.ReportService.reporting
          .one(url)
          .get({ realms, campaigns })
          .then(data => data.plain())
      },
    }

    this.repsLive = {
      stat: 'repsLive',
      get(details) {
        const url = `/live/rep/${details.compSlug}/${details.campSlug}`
        return this.ReportService.reporting
          .one(url)
          .get({ realms: details.realm })
          .then(data => {
            return data.plain()
          })
      },
    }

    this.stationsLive = {
      stat: 'stationsLive',
      get(details) {
        const url = `/live/rep/${details.compSlug}/${details.campSlug}`
        return this.ReportService.reporting
          .one(url)
          .get({ realms: details.realm })
          .then(data => {
            return data.plain()
          })
      },
    }

    this.hopperSpeed = {
      stat: 'hopperSpeed',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/hopper-fill`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.repWaitTime = {
      stat: 'repWaitTime',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/rep-wait_time`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.serverLoad = {
      stat: 'serverLoad',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/system-load`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.keypressLag = {
      stat: 'keypressLag',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/actions-keypress_delay`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.hopperFill = {
      stat: 'hopperFill',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/hopper-fill`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.hopperSize = {
      stat: 'hopperSize',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/hopper-size`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.dialingSpeed = {
      stat: 'dialingSpeed',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/dialer-dialing_time`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.schedulerSpeed = {
      stat: 'schedulerSpeed',
      get(details) {
        const url = `/datadog/query/${details.compSlug}/${
          details.campSlug
        }/scheduler-schedule_calls`
        return this.RealmService.Realm.one(url)
          .get()
          .then(data => {
            return data.plain()
          })
      },
    }

    this.activeProspects = {
      stat: 'activeProspects',
      get(details) {
        const url = `/data/active/${details.compSlug}/${details.campSlug}`
        return this.ReportService.reporting
          .one(url)
          .get({ realms: details.realm })
          .then(data => {
            return data.plain()
          })
      },
    }

    this.scheduledProspects = {
      stat: 'scheduledProspects',
      get(details) {
        const url = `/data/scheduled/${details.compSlug}/${details.campSlug}`
        return this.ReportService.reporting
          .one(url)
          .get({ realms: details.realm })
          .then(data => {
            return data.plain()
          })
      },
    }

    this.activeDialableProspects = {
      stat: 'activeDialableProspects',
      type: 'prospect',
      get(details) {
        const url = `/data/active/dialable/${details.compSlug}/${
          details.campSlug
        }`
        return this.ReportService.reporting
          .one(url)
          .get({ realms: details.realm })
          .then(data => {
            return data.plain()
          })
      },
    }
  }

  statFetcher(url, realm = null) {
    return this.ReportService.reporting
      .one(url)
      .get({ realms: realm })
      .then(data => data)
  }

  isCacheValid(requestTime, realm) {
    const timeSinceLastRequest = requestTime.diff(
      this.lastRequest.time,
      'minutes',
    )
    if (this.lastRequest.isClean && !this.lastRequest.realm) {
      this.lastRequest.time = requestTime
      this.lastRequest.isClean = false
      this.lastRequest.realm = realm
    } else if (timeSinceLastRequest >= 5 || realm !== this.lastRequest.realm) {
      this.lastRequest.time = requestTime
      this.lastRequest.realm = realm
      this.getStats = memoizeWith(identity, this.statFetcher) // resets cache
    }
  }

  makeUrl(details, tag) {
    const context = tag ? details.context + tag : details.context
    switch (context) {
      case 'data':
        return details.historical
          ? `${details.context}/historical/${details.compSlug}/${
              details.campSlug
            }/${details.listSlug}/${details.epochStart}/${details.epochEnd}`
          : `${details.context}/${details.compSlug}/${details.campSlug}/${
              details.listSlug
            }/${details.epochStart}/${details.epochEnd}`
      case 'data:byAttempt':
        return details.historical
          ? `${details.context}/by-attempts/historical/${details.compSlug}/${
              details.campSlug
            }/${details.listSlug}/${details.epochStart}/${details.epochEnd}`
          : `${details.context}/by-attempts/${details.compSlug}/${
              details.campSlug
            }/${details.listSlug}/${details.epochStart}/${details.epochEnd}`
      default:
        return details.historical
          ? `${details.context}/historical/${details.compSlug}/${
              details.campSlug
            }/${details.epochStart}/${details.epochEnd}`
          : `${details.context}/${details.compSlug}/${details.campSlug}/${
              details.epochStart
            }/${details.epochEnd}`
    }
  }

  promisifyStats(details, tag) {
    const url = this.makeUrl(details, tag)
    this.isCacheValid(details.requestTime, details.realm)
    return this.getStats(url, details.realm)
  }
}
