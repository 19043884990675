import CompanyRapidResponseTestsController from './controller/CompanyRapidResponseTestsController'
import template from './companyRapidResponseTests.component.tpl.pug'

const companyRapidResponseTests = {
  template: template(),
  controller: CompanyRapidResponseTestsController,
  bindings: {
    company: '<'
  }
}

export default companyRapidResponseTests
