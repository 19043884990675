import ARD from '../../../dialogs/audio-resource/audioResource.js'
import ARFD from '../../../dialogs/audio-resource-file/audioResourceFile.js'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import { tableConfig } from '../config/audioResourcesTable.js'

export default class ManageAudioResourcesController {
  constructor(
    PitchService,
    ManagementService,
    ToastService,
    $state,
    $mdDialog,
  ) {
    'ngInject'
    this.MS = ManagementService
    this.$state = $state
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.searchText = ''
    this.tableConfig = tableConfig
    this.state = {
      isLoading: true,
      tableData: [],
      topBarButtons: [
        {
          title: 'New Audio Resource',
          icon: 'content:add',
          action: this.openAudioResourceFormDialog.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }

    this.searchAudio = ({ searchText }) => {
      this.searchText = searchText
    }
  }

  $onInit() {
    this.AudioResourceService = this._getAudioResourceService()
    this.getResources()
  }

  _getAudioResourceService() {
    return this.PS.setupAudioResources().global
  }

  getResources() {
    this.AudioResourceService.get()
      .then(items => {
        this.state.tableData = items.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching global audio resources',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.isLoading = false
      })
  }

  openAudioResourceFormDialog(audioResource) {
    ARD.locals = {
      isCreate: !audioResource,
      service: this.AudioResourceService,
      openAudioResourceFileFormDialog: this.openAudioFileDialog.bind(this),
      audioResource: { ...audioResource },
    }
    this.$mdDialog.show(ARD).then(resource => {
      if (ARD.locals.isCreate) {
        this.state.tableData.push(resource)
      } else {
        audioResource = Object.assign(audioResource, resource)
      }
    })
  }

  openAudioFileDialog(audioResource) {
    const locals = {
      service: this.AudioResourceService,
      audioResource,
    }
    ARFD.locals = locals
    this.$mdDialog.show(ARFD).then(({ wasUpdate, resource }) => {
      if (wasUpdate) {
        audioResource.file = resource.file
      }
    })
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev)
  }

  deleteAudioResource(audioResource) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Audio Resource',
      entityName: audioResource.name,
      identityEndpoint: this.AudioResourceService.one(audioResource.uuid),
      entity: audioResource,
      routeWhenSuccessful: this.$state.current.name,
    }
    this.$mdDialog.show(DED)
  }
}
