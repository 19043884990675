import './sideNavToggle.scss'
import SideNavToggleController from './controller/SideNavToggleController.js'
const template = require('./sideNavToggle.tpl.pug')

const sideNavToggle = {
  template: template(),
  controller: SideNavToggleController,
  bindings: {
    section: '<',
    sectionIndex: '<',
    activeIndex: '<',
    toggleSection: '&',
    toggleNav: '&',
    isDashboardSection: '&',
  },
}

export default sideNavToggle
