import ListOverviewController from './controller/ListOverviewController.js'
const template = require('./listOverview.tpl.pug')

const listOverview = {
  template: template(),
  controller: ListOverviewController,
  bindings: {
    company: '<',
    campaign: '<',
    offsetMinutes: '<',
    globalRealm: '<',
    listRealm: '<',
    list: '<'
  }
}

export default listOverview
