// Manage Campaigns
import { manageCampaigns, manageCampaignsConfig } from './manage.campaigns.js'
import {
  manageCampaignsCreate,
  manageCampaignsCreateConfig,
} from './child-states/manage-campaigns-create/manage.campaigns.create.js'
import {
  manageCampaignsCreateCampaignInfo,
  manageCampaignsCreateCampaignInfoConfig,
} from './child-states/manage-campaigns-create/child-states/campaign-info/manage.campaigns.create.campaignInfo.js'

export default function manageCampaignRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageCampaigns, manageCampaignsConfig)
    .state(manageCampaignsCreate, manageCampaignsCreateConfig)
    .state(
      manageCampaignsCreateCampaignInfo,
      manageCampaignsCreateCampaignInfoConfig,
    )
}
