import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'
const API_BASE_URL = getBaseUrl(window.location.host, true)

const ModalAddItem = React.forwardRef((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    editDnc(item) {
      editDnc(item)
    },
  }))

  const [modalLoading, setModalLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [form, setForm] = useState({
    name: '',
    notes: 'test test',
    expiration_days: '',
    company_slug: 'global',
  })

  const getCompanies = () => {
    console.log('getCompanies')
    axios
      .get(`${API_BASE_URL}/identity/company/list/`, {
        headers: {
          Authorization: `token ${JSON.parse(
            localStorage['ngStorage-ppToken'],
          )}`,
        },
      })
      .then(res => {
        setCompanies(res.data)

        let modal = document.querySelectorAll('.modal')
        M.Modal.init(modal, {
          onCloseEnd: () => {
            setForm({
              name: '',
              notes: 'test test',
              expiration_days: '',
              company_slug: 'global',
            })
            setIsEdit(false)
          },
        })

        let elems = document.querySelectorAll('.dropdown-trigger')
        M.Dropdown.init(elems)

        let select = document.querySelectorAll('select')
        M.FormSelect.init(select)

        props.initModals()
        props.getData()
      })
  }

  const addItem = () => {
    setModalLoading(true)
    axios
      .post(`${API_BASE_URL}/dnc/company/${form.company_slug}/list/`, form, {
        headers: {
          Authorization: `token ${JSON.parse(
            localStorage['ngStorage-ppToken'],
          )}`,
        },
      })
      .then(res => {
        setForm({
          name: '',
          notes: 'test test',
          expiration_days: '',
          company_slug: 'global',
        })
        setModalLoading(false)
        document.querySelector('#btnCancel1').click()
        props.getData()
      })
  }

  const editItem = () => {
    setModalLoading(true)
    axios
      .put(
        `${API_BASE_URL}/dnc/company/${form.company_slug}/list/${props.selectedDnc.uuid}/`,
        form,
        {
          headers: {
            Authorization: `token ${JSON.parse(
              localStorage['ngStorage-ppToken'],
            )}`,
          },
        },
      )
      .then(res => {
        setForm({
          name: '',
          notes: 'test test',
          expiration_days: '',
          company_slug: 'global',
        })
        setModalLoading(false)
        document.querySelector('#btnCancel1').click()
        props.getData()
      })
  }

  const editDnc = item => {
    props.setSelectedDnc(item)

    let temp = { ...form }
    temp.name = item.name
    temp.expiration_days = item.expiration_days
    temp.notes = item.notes
    temp.company_slug = item.company_slug
    setForm(temp)
    setIsEdit(true)
  }

  const handleForm = (type, value) => {
    let temp = { ...form }
    temp[type] = value
    setForm(temp)
  }

  useEffect(() => {
    let select = document.querySelectorAll('select')
    M.FormSelect.init(select)
    M.updateTextFields()
  }, [form])

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <div id="modal1" className="modal modalEdit">
      <div className="modal-header p-2 bg-blue white-text">
        <div className="center-align" style={{ fontSize: 20 }}>
          {form.company_slug === '' || (form.name === '' && 'Add DNC List')}
          {form.name !== '' && 'Edit DNC List'}
        </div>
      </div>
      <div className="modal-content">
        {modalLoading && (
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        )}
        <div className="row">
          <div className="input-field col s12">
            <input
              id="Name"
              type="text"
              value={form.name}
              onChange={e => handleForm('name', e.target.value)}
            />
            <label htmlFor="Name">Name*</label>
          </div>
          <div className="input-field col s12">
            <select
              id="#select"
              onChange={e => handleForm('company_slug', e.target.value)}
              value={form.company_slug}
            >
              <option value="global">Global</option>
              {companies.map((item, index) => (
                <option
                  style={{ fontSize: 16, color: '#4a4a4a' }}
                  key={index}
                  value={item.slug}
                >
                  {item.name}
                </option>
              ))}
            </select>
            <label className="mb-1">Company</label>
          </div>
          <div className="input-field col s12">
            <input
              id="days"
              type="number"
              maxLength="6"
              value={form.expiration_days}
              onChange={e => handleForm('expiration_days', e.target.value)}
            />
            <label htmlFor="days">Expiration days*</label>
            <span className="helper-text">
              Number of days the number stays on the DNC list
            </span>
          </div>
        </div>
      </div>
      <div className="modal-footer" style={{ height: 80, textAlign: 'center' }}>
        <a
          id="btnCancel1"
          className="modal-close btn-flat btn-cancel grey lighten-3 center-align pl-2 pr-2"
        >
          CANCEL
        </a>{' '}
        &nbsp; &nbsp; &nbsp;
        <a
          disabled={
            form.company_slug === '' ||
            form.name === '' ||
            form.expiration_days === ''
          }
          onClick={isEdit ? editItem : addItem}
          className="shadow-0 btn-flat text-white bg-success center-align btn-save pl-4 pr-4"
        >
          Save
        </a>
      </div>
    </div>
  )
})

export default ModalAddItem
