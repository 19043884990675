// create companies view

const manageCompaniesCreate = 'manage.companies.create'

const manageCompaniesCreateConfig = {
  abstract: true,
  component: 'rightDrawer'
}

export { manageCompaniesCreate, manageCompaniesCreateConfig }
