import { map, mapObjIndexed, prop, reduce, sortBy } from 'ramda'
import moment from 'moment'
import roundTo from 'round-to'
import { calculateMetrics } from 'util/calculate/metrics/calculateMetrics.js'
import { calculateMetricsByRep } from 'util/calculate/metrics/by-rep/calculateMetricsByRep.js'

export default class RepTrendService {
  constructor(
    $q,
    $mdToast,
    StatsService,
    MetricsService,
    RepStatsService,
    DualAxisSplineChart,
  ) {
    'ngInject'

    this.$q = $q
    this.SS = StatsService
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.metrics = MetricsService.metrics
    this.pullStats = MetricsService.pullStats
    this.RSS = RepStatsService
    this.DAS = DualAxisSplineChart
  }

  getRepTrends(component, dateRange, selectedRange, realm) {
    const details = { component, dateRange, selectedRange, realm }
    const detailsTodayRep = this.createTodayDetails(details, 'rep')
    const detailsTodayCamp = this.createTodayDetails(details, 'campaign')
    const detailsRangeRep = this.createRangeDetails(details, 'rep')
    const detailsRangeCamp = this.createRangeDetails(details, 'campaign')

    const allStats = {
      repsToday: this.fetchStats(detailsTodayRep, component.metrics),
      repsBaseRange: this.fetchStats(detailsRangeRep, component.metrics),
      campToday: this.fetchStats(detailsTodayCamp, component.metrics),
      campBaseRange: this.fetchStats(detailsRangeCamp, component.metrics),
    }

    return this.$q
      .all(allStats)
      .then(this.calculateMetrics.bind(this))
      .then(this.combiner.bind(this))
      .then(this.putRepsInArray.bind(this))
      .then(data => {
        return data
      })
  }

  calculateMetrics(data) {
    return {
      repsToday: calculateMetricsByRep(data.repsToday),
      repsBaseRange: calculateMetricsByRep(data.repsBaseRange),
      campToday: calculateMetrics(data.campToday),
      campBaseRange: calculateMetrics(data.campBaseRange),
    }
  }

  fetchStats(query, metrics) {
    const stats = this.promisifyStats(query).then(data => data.plain().results)

    return this.$q.all({
      stats,
      metrics,
    })
  }

  createTodayDetails({ component, dateRange, realm }, context) {
    return {
      context,
      compSlug: component.company,
      campSlug: component.campaign,
      realm: realm,
      epochStart: moment(dateRange.startDate).unix(),
      epochEnd: moment(dateRange.endDate).unix(),
      requestTime: dateRange.requestTime,
    }
  }

  createRangeDetails({ component, dateRange, selectedRange, realm }, context) {
    const daysBack = moment(dateRange.startDate).subtract(
      selectedRange.days,
      'days',
    )
    const end = moment(dateRange.endDate)
    return {
      context,
      historical: true,
      compSlug: component.company,
      campSlug: component.campaign,
      realm: realm,
      epochStart: daysBack.unix(),
      epochEnd: end.unix(),
      requestTime: dateRange.requestTime,
    }
  }

  combiner(data) {
    const packageMetrics = (metric, key) => {
      const reps = metric.data
      const packageRep = rep => {
        const repBase = data.repsBaseRange[key].data[rep.rep] || {
          rep: rep.rep,
          days: [],
          dialed: 0,
          y: 0,
        }
        const campBase = data.campBaseRange[key]
        const campToday = data.campToday[key]
        rep.daysOn = moment.duration(repBase.dialed, 'hours').asDays()
        rep.campToday = campToday.y
        rep.campaignAverage = campBase.y
        rep.chartConfig = this.createChartConfig(repBase, campBase)
        rep.percentChange = {
          vsSelf: this.getPercentageChange(rep.y, repBase.y),
          vsCamp: this.getPercentageChange(rep.y, campBase.y),
        }
        return rep
      }
      return map(packageRep, reps)
    }
    return mapObjIndexed(packageMetrics, data.repsToday)
  }

  putRepsInArray(data) {
    const toArray = metric => {
      const repKeys = Object.keys(metric)
      return reduce(
        (acc, key) => {
          acc.push(metric[key])
          return acc
        },
        [],
        repKeys,
      )
    }
    return map(toArray, data)
  }

  getPercentageChange(today, average) {
    if (!average) return 0
    const difference = today - average
    const combAverage = today + average / 2
    const percentDiff = roundTo(difference / combAverage * 100, 2)
    return percentDiff
  }

  sortByDate(metricArr) {
    return sortBy(prop('date'), metricArr)
  }

  createChartConfig(rep, camp) {
    const series = {
      rep: {
        name: rep.rep,
        data: rep.days,
        zIndex: 2,
      },
      campaign: {
        name: 'Campaign',
        data: camp.days,
        zIndex: 1,
      },
    }
    const chartConfig = this.DAS.getChart()
    chartConfig.series = [series.rep, series.campaign]
    chartConfig.options.tooltip.useHTML = true
    chartConfig.options.tooltip.formatter = function() {
      var tooltipMarkup = `
        <b>${moment(this.x).format('MMM D YYYY')}</b></br>
        <hr>
      `
      this.points.forEach(point => {
        tooltipMarkup += `
          ${point.series.name}: <b>${point.point.format.prepend || ''}${roundTo(
          point.y,
          2,
        )}${point.point.format.append || ''}</b></br>
        `
      })
      return tooltipMarkup
    }
    return chartConfig
  }
}
