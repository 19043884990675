import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'calls',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Calls',
  },
}

const keys = {
  statKey: 'calls',
  componentKeys: [DATA_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'aggregate',
}

const calls = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { calls }
