import { contains, curry, filter } from 'ramda'

function isRecorderOnly(roles) {
  return roles.RECORDER && Object.keys(roles).length === 1
}

const filterCampaignsForVoice = curry(function(voice, campaigns) {
  function voiceHasCampaign(campaign) {
    return contains(campaign.uuid, voice.campaigns)
  }
  return voice ? filter(voiceHasCampaign, campaigns) : campaigns
})

export { isRecorderOnly, filterCampaignsForVoice }
