import DetailsDialogController from '../../controllers/DetailsDialogController.js'

export default class UserDetailsDialogController extends DetailsDialogController {
  constructor ($mdDialog, ManagementService, ToastService, IdentityService) {
    'ngInject'
    super(...arguments)
    this.entityName = 'user'
    this.service = IdentityService.User.manage.fetch.one(this.entity.uuid)
    this.fetchEntity()
  }
}
