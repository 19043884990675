import CPVD from '../../../../../dialogs/create-prospect-voices/createProspectVoices'

export default class RapidResponseTestsVoicesController {
  constructor(PitchService, EditPitchService, ToastService, $mdDialog) {
    'ngInject'

    this.PS = PitchService
    this.service = null
    this.EPS = EditPitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.isProspect = true
    this.state = {
      voices: [],
      goBackRoute: 'manage.globalRapidResponse.tests',
    }
  }

  $onInit() {
    this.editableRapidResponseTest = Object.assign({}, this.rapidResponseTest)
    let compSlug = this.compSlug || 'global'
    let services = this.PS.setupRapidResponse(compSlug)
    let service = compSlug === 'global' ? services.global : services.company
    this.service = service.one('tests').one(this.compUuid)
  }

  updateVoices(fields) {
    return this.EPS.updatePitchDetails(fields, this.service).then(
      updatedRapidResponseTest => {
        this.rapidResponseTest = updatedRapidResponseTest
        this.TS.show({
          text: 'Rapid Response Test has been successfully updated',
        })
        return updatedRapidResponseTest
      },
    )
    // errors handled in child component pitchDetails
  }

  handleCreate() {
    const locals = {
      compSlug: this.compSlug,
      testUuid: this.compUuid,
      rapidResponseTest: this.rapidResponseTest,
    }
    CPVD.locals = locals
    this.$mdDialog.show(CPVD).then(() => {
      const rrService = this.PS.setupBase(null, 'rapid-response').global
      const rrTestService = rrService.one('tests')
      rrTestService
        .get()
        .then(tests => {
          const rrTests = tests.plain()
          this.rapidResponseTest.voices = rrTests.filter(
            test => test.uuid === this.compUuid,
          )[0].voices
        })
        .catch(err => {
          this.TS.show({
            text: 'There was a problem fetching rapid response tests',
          })
        })
    })
  }
}
