import { IMPERSONATE } from 'util/event.js'

export default class GlobalController {
  constructor(
    $scope,
    $mdDialog,
    $localStorage,
    $sessionStorage,
    $state,
    RoleStore,
    ManagementService,
  ) {
    'ngInject'

    this.$localStorage = $localStorage
    this.$sessionStorage = $sessionStorage
    this.$scope = $scope
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.RS = RoleStore
    this.MS = ManagementService

    this.state = {
      impersonate: {
        roles: null,
        username: null,
        active: false,
      },
    }

    this.init()

    $scope.$on(IMPERSONATE, (e, data) => {
      data.roles = Object.keys(this.RS.getAllRoles())
      this.$localStorage.impersonateInfo = data
      this.populateImpersonateState(data)
      this.user = data
    })
  }

  init() {
    if (
      this.$localStorage.impersonateInfo &&
      this.$localStorage.impersonateToken
    ) {
      this.populateImpersonateState(this.$localStorage.impersonateInfo)
      this.user = this.$localStorage.impersonateInfo
    }
  }

  stopImpersonating() {
    delete this.$localStorage.impersonateToken
    delete this.$localStorage.impersonateInfo
    this.$sessionStorage.$reset()
    Object.assign(this.state.impersonate, {
      roles: null,
      username: null,
      active: false,
    })
    this.$state.go('gateway', null, { reload: true })
    console.warn('No longer impersonating')
  }

  populateImpersonateState(state) {
    Object.assign(this.state.impersonate, {
      username: state.username,
      roles: state.roles,
      active: true,
    })
  }

  openDetailsDialog(event, user) {
    const dialog = this.MS.getDialog('userDetails')
    dialog.locals = { entity: user }
    this.$mdDialog.show(dialog)
  }
}
