import { containsRole } from '../../../../../../../utils/managementUtils.js'

export default class RoleInfoFormController {
  constructor($state, IdentityService) {
    'ngInject'

    this.$state = $state
    this.IS = IdentityService
    this.state = {
      showDescription: {},
    }
  }

  $onInit() {
    this.user.groups = []
  }

  backTo() {
    this.goingBack = true
    this.$state.go(
      'manage.users.create.personalInfo',
      { user: this.user },
      { location: false },
    )
  }

  goTo() {
    const nextState = this.routeNextState(this.user.groups)
    this.$state.go(nextState, { user: this.user }, { location: false })
  }

  getTeams() {
    return this.IS.Team.list
      .get({ editable: true })
      .then(teams => {
        this.teams = teams.plain()
      })
      .catch(err => {
        console.error('ERROR: ', err)
      })
  }

  getLocations() {
    return this.IS.Location.list
      .get({ editable: true })
      .then(locations => {
        this.locations = locations.plain()
      })
      .catch(err => {
        console.error('ERROR: ', err)
      })
  }

  showDescriptions(userGroups) {
    if (Array.isArray(userGroups)) {
      this.state.showDescription = userGroups.reduce((show, group) => {
        show[group.name] = true
        return show
      }, {})
      return
    }
    this.state.showDescription = {}
  }

  routeNextState(groups) {
    const formattedGroups = groups.map(group => group.name)
    const hasRole = containsRole(formattedGroups, [
      'SUPER_USER',
      'REALM_ADMIN',
      'CLIENT_MANAGER',
    ])
    if (hasRole) {
      return 'manage.users.create.passwordInfo'
    }
    return 'manage.users.create.companyInfo'
  }
}
