import './reactivateDidConfirmation.scss'
import ReactivateDidConfirmationController from './controller/ReactivateDidConfirmationController'
const template = require('./reactivateDidConfirmation.tpl.pug')

const reactivateDidConfirmation = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ReactivateDidConfirmationController,
}

export default reactivateDidConfirmation
