// dashboard.goals view

const dashboardDialerLive = 'dashboard.dialerLive'

const dashboardDialerLiveConfig = {
  url: '/dialer-live',
  component: 'dialerLive',
  resolve: {
    campaign: function (selectedCampaign, RealmService) {
      'ngInject'
      return selectedCampaign
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    }
  }
}

export { dashboardDialerLive, dashboardDialerLiveConfig }

