export default function () {
  function linkFunc (scope, element, attrs, ctrl) {
    const invertComparison = attrs.invert || false
    ctrl.$validators.compareTo = function (modelVal, viewVal) {
      const value = modelVal || viewVal
      return invertComparison
        ? value !== scope.compareTo
        : value === scope.compareTo
    }
    scope.$watch('compareTo', function () {
      ctrl.$validate()
    })
  }

  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      compareTo: '='
    },
    link: linkFunc
  }
}
