export default class ListMetricsController {
  constructor (ListOverviewService) {
    'ngInject'
    this.LOS = ListOverviewService
    this.state = {
      showListMetrics: !!this.metrics,
    }
  }

  $onChanges (bindings) {
    this.updateBindings(bindings)
    this.state.showListMetrics = !!this.metrics
    this.makeHourAveragesCsv = this.hourAveragesToCSV.bind(this, this.metrics, this.details.slug)
  }

  updateBindings (bindings) {
    const bndKeys = Object.keys(bindings)
    bndKeys.forEach((bnd) => {
      this[bnd] = bindings[bnd].currentValue
    })
  }

  toggleListDetails () {
    this.state.showListMetrics = !this.state.showListMetrics
  }

  hourAveragesToCSV (metricsObj = {}, listSlug = 'unknownList') {
    return {
      fileName: `${listSlug}_hour_averages.csv`,
      data: this.LOS.hourAveragesToCSV(metricsObj)
    }
  }
}
