import moment from 'moment'
import roundTo from 'round-to'
import Papa from 'papaparse'
import { map, reduce, pipe, filter, mapObjIndexed, zipWith } from 'ramda'
import { listOverviewMetricsConfig } from '../config/listOverviewMetricsConfig.js'
import { calculateMetricsByAttempt } from 'util/calculate/metrics/by-attempt/calculateMetricsByAttempt.js'
import { calculateMetrics } from 'util/calculate/metrics/calculateMetrics.js'

export default class ListOverviewService {
  constructor($q, RealmService, StatsService, MetricsService, ReportService) {
    'ngInject'

    this.$q = $q
    this.Lists = ReportService.lists
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.getMetricObjs = MetricsService.getMetrics.bind(MetricsService)

    this.getReducedHours = pipe(
      this.reduceHours.bind(this),
      this.getHourAverage.bind(this),
    )

    this.metrics = listOverviewMetricsConfig
  }

  getListDetails(company, campaign, listSlug) {
    return this.Lists.get(`${company.slug}/${campaign.slug}/${listSlug}`).then(
      list => list.plain(),
    )
  }

  getListMetrics(company, campaign, listSlug, listRealm, offsetMinutes) {
    const listDetails = this.createListDetails({
      company,
      campaign,
      listSlug,
      listRealm,
      offsetMinutes,
    })
    const listMetrics = this.getMetricObjs(this.metrics).call
    return this.getListStats(listDetails, listMetrics)
      .then(this.buildMetrics.bind(this))
      .then(this.addAttempts.bind(this))
      .then(this.addReducedHours.bind(this))
  }

  addReducedHours(listMetrics) {
    const pureMetrics = Object.assign({}, listMetrics)
    const reducedHours = this.getReducedHours(pureMetrics)
    return mapObjIndexed((metric, key) => {
      metric.hourAverages = reducedHours[key].hourAverages
      return metric
    }, listMetrics)
  }

  buildMetrics(list) {
    const listMetrics = {
      metrics: list.metrics,
      stats: [...list.data],
    }
    const listMetricsByAttempt = {
      metrics: list.metrics,
      data: [...list.dataByAttempt],
    }
    return {
      metricsByAttempt: calculateMetricsByAttempt(listMetricsByAttempt),
      metrics: calculateMetrics(listMetrics, 'list'),
    }
  }

  addAttempts(listMetrics) {
    const metricKeys = Object.keys(listMetrics.metrics)
    const metrics = listMetrics.metrics
    const attemptMetrics = listMetrics.metricsByAttempt
    const adder = (acc, metricKey) => {
      acc[metricKey] = metrics[metricKey]
      acc[metricKey].attempts = this.getAllAttempts(metricKey, attemptMetrics)
      return acc
    }
    return reduce(adder, {}, metricKeys)
  }

  getAllAttempts(metricKey, attemptMetrics) {
    const attemptKeys = Object.keys(attemptMetrics.attempts)
    const getter = (acc, attemptKey) => {
      acc.push({
        attempt: attemptKey,
        y: attemptMetrics.attempts[attemptKey][metricKey].y || 0,
      })
      return acc
    }
    return reduce(getter, [], attemptKeys)
  }

  createListDetails(queryData) {
    const { campaign, company, listSlug, listRealm, offsetMinutes } = queryData
    const startDate = moment()
      .utc()
      .utcOffset(offsetMinutes)
      .startOf('day')
    const epochStart = moment(startDate).subtract(89, 'days')
    const epochEnd = moment(startDate).endOf('day')
    const details = {
      context: 'data',
      historical: true,
      compSlug: company.slug,
      campSlug: campaign.slug,
      listSlug,
      realm: listRealm,
      epochStart: epochStart.unix(),
      epochEnd: epochEnd.unix(),
      requestTime: moment().startOf('minute'),
    }
    return details
  }

  buildMetricsByAttempt(list, context) {
    const filteredAttempts = filter(
      attempt => !!attempt.data.length,
      list.attempts,
    )
    const buildByAttempt = attempt => {
      const attemptPackage = {
        metrics: list.metrics,
        data: attempt.data,
      }
      return {
        metrics: this.calculateByAttempt(attemptPackage, context),
        attempts: attempt.attempts,
      }
    }
    return map(buildByAttempt, filteredAttempts)
  }

  calculateByAttempt(attemptData, context) {
    const calc = attempt => {
      const attemptPackage = {
        metrics: attemptData.metrics,
        stats: attempt.data,
      }
      return calculateMetrics(attemptPackage, context)
    }
    return map(calc, attemptData.data)
  }

  getListStats(details, metrics) {
    const promisedStats = {
      dataByAttempt: this.promisifyStats(details, ':byAttempt').then(
        data => data.plain().results,
      ),
      data: this.promisifyStats(details).then(data => data.plain().results),
      metrics,
    }
    return this.$q.all(promisedStats)
  }

  zipHours(x, y) {
    if (Array.isArray(x)) return x.concat(y)
    return [x, y]
  }

  combineHours(acc, day) {
    const hourValues = day.hours
    if (acc.length === 0) return acc.concat(hourValues)
    return zipWith(this.zipHours, acc, hourValues)
  }

  metricHourParser(metric) {
    return reduce(this.combineHours.bind(this), [], metric.days)
  }

  reduceHours(metricsObj = {}) {
    return map(this.metricHourParser.bind(this), metricsObj)
  }

  getHourAverage(metricsObj = {}) {
    const getAverage = arr => {
      const sum = sum(arr)
      const length = arr.length
      const average = sum / length
      return average > 0 ? roundTo(average, 2) : 0
    }
    return map(metricHours => {
      const averages = map(hourArray => {
        const formattedHourArray = hourArray
          .map(hour => hour.y)
          .filter(value => value > 0)
        return getAverage(formattedHourArray)
      }, metricHours)

      return { hourAverages: averages }
    }, metricsObj)
  }

  hourAveragesToCSV(metricsObj) {
    const data = reduce(
      (acc, key) => {
        const hours = metricsObj[key].hourAverages
        hours.forEach((hour, index) => {
          if (Array.isArray(acc.data[index])) {
            acc.data[index].push(hour)
            return
          }
          acc.data.push([hour])
        })
        acc.fields.push(metricsObj[key].format.title)
        return acc
      },
      { fields: [], data: [] },
      Object.keys(metricsObj),
    )

    data.fields.unshift('Hour of Day')
    return Papa.unparse({
      fields: data.fields,
      data: data.data.map((row, index) => [index, ...row]),
    })
  }
}
