import angular from 'angular'
import { react2angular } from 'react2angular'

// Services
import ManageDncService from './component/services/ManageDncService.js'

// components
import manageDnc from './component/react-manageDnc.js'

export default angular
  .module('portalApp.manage.dnc', [])
  .service('ManageDncService', ManageDncService)
  .component('manageDnc', react2angular(manageDnc)).name
//.component('manageDnc', manageDnc).name
