import './pitchSegments.component.scss'
import PitchSegmentsController from './controller/PitchSegmentsController.js'
const template = require('./pitchSegments.component.tpl.pug')

const pitchSegments = {
  template: template(),
  controller: PitchSegmentsController,
  bindings: {
    companyService: '<',
    globalService: '<',
    segments: '<',
    onUpdate: '&',
    campaign: '<'
  }
}

export default pitchSegments

