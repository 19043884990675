import ManageAccessListsEditController from './controller/ManageAccessListsEditController.js'
const template = require('./manageAccessListsEdit.component.tpl.pug')

const manageAccessListsEdit = {
  controller: ManageAccessListsEditController,
  template: template(),
  bindings: {
    entity: '<',
    entityIndex: '<',
    onEntityUpdate: '&',
  }
}

export default manageAccessListsEdit
