import './rotateDid.scss'
import RotateDidController from './controller/RotateDidController'
const template = require('./rotateDid.tpl.pug')

const rotateDid = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: RotateDidController,
}

export default rotateDid
