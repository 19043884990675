import { find, findIndex, propEq, reduce } from 'ramda'

const recorderGroupFinder = find(propEq('name', 'RECORDER'))

function voiceHydrator(acc, voiceId) {
  const voiceIndex = findIndex(propEq('uuid', voiceId))(acc.available)
  if (voiceIndex > -1) {
    acc.active.push(acc.available[voiceIndex])
    acc.available.splice(voiceIndex, 1)
  }
  return acc
}

function hydrateVoices(voiceIds) {
  return function(voiceUsers) {
    const base = {
      active: [],
      available: voiceUsers,
    }
    return reduce(voiceHydrator, base, voiceIds)
  }
}

export { hydrateVoices, recorderGroupFinder }
