// create campaign router route view

const manageCampaignRouterCreateRouteInfo = 'manage.campaignRouter.create.routeInfo'

const manageCampaignRouterCreateRouteInfoConfig = {
  params: {
    router: null,
    campaign: null,
  },
  component: 'routeInfoForm',
  resolve: {
    router: function ($transition$) {
      'ngInject'
      return $transition$.params().router
    },
    campaign: function ($transition$) {
      'ngInject'
      return $transition$.params().campaign
    }
  }
}

export { manageCampaignRouterCreateRouteInfo, manageCampaignRouterCreateRouteInfoConfig }
