export default class QueueService {
  constructor ($q) {
    'ngInject'
    this.$q = $q
  }

  execQueue ({ queue = [], maxConcurrent = 1, promiseCb }, promisedTasks = [], deferred) {
    if (!deferred) {
      deferred = this.$q.defer()
    } else if (!queue.length) {
      this.$q.all(promisedTasks).then(deferred.resolve) // End recursion and fullfull deferred
    }

    const toRun = queue.splice(0, maxConcurrent) // Mutate queue to keep concurrent runs in sync

    toRun.forEach(task => {
      const taskPromise = promiseCb(task)
        .then(res => {
          this.execQueue({ queue, promiseCb }, promisedTasks, deferred) // recurse on success
          return res
        })
        .catch(err => {
          this.execQueue({ queue, promiseCb }, promisedTasks, deferred) // recurse on failure
          return err
        })
      promisedTasks.push(taskPromise)
    })

    return deferred.promise
  }
}
