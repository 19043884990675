import { range } from 'ramda'

const defaultPFValues = {
  description: '',
  option_group: '',
  active: true,
  editable: true,
  required: false,
}

export default class PanelFieldDialogController {
  constructor($q, $mdDialog) {
    'ngInject'

    this.$q = $q
    this.$mdDialog = $mdDialog
    this.fieldNumbers = range(1, 11)
    this.editablePanelField = {}

    // FYI This controller does not have native lifecycle hooks
    this.state = {
      submitting: false,
      error: false,
      optionGroups: [],
      optionGroupSelected: null,
    }
    this.onInit()
  }

  onInit() {
    this.fetchOptionGroups().then(() => {
      this.initialPanelField = this.panelField || defaultPFValues
      let optionGroup = this.initialPanelField.option_group
      Object.assign(this.editablePanelField, this.initialPanelField, {
        panel: this.panelId,
      })
      this.state.optionGroupSelected = optionGroup
    })
  }

  updateOrCreate(panelField) {
    this.state.optionGroupSelected = panelField.option_group
    this.isCreate
      ? this.createPanelField(panelField)
      : this.updatePanelField(panelField)
  }

  normalizePanelField(panelField) {
    // have to normalize object for api
    let ogUuid = panelField.option_group ? panelField.option_group.uuid : null
    return Object.assign({}, panelField, { option_group: ogUuid })
  }
  createPanelField(panelField) {
    this.state.submitting = true
    const normalizedPanelField = this.normalizePanelField(panelField)
    this.panelFieldService
      .customPOST(normalizedPanelField)
      .then(createdPanelField => {
        this.close(Object.assign(createdPanelField, panelField))
      })
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  fetchOptionGroups() {
    return this.$q
      .all({
        companyOptions: this.fetchCompanyOptionGroups(),
        globalOptions: this.fetchGlobalOptionGroups(),
      })
      .then(({ companyOptions, globalOptions }) => {
        this.state.optionGroups = [{ name: 'None', uuid: null }].concat([
          ...companyOptions,
          ...globalOptions,
        ])
      })
  }

  fetchCompanyOptionGroups() {
    return this.fieldOptionService.get().then(options => options.plain())
  }

  fetchGlobalOptionGroups() {
    return this.globalFieldOptionService.get().then(options => options.plain())
  }

  updatePanelField(panelField) {
    this.state.submitting = true
    panelField = this.normalizePanelField(panelField)
    this.panelFieldService
      .one(panelField.uuid)
      .patch(panelField)
      .then(updatedPanelField => {
        Object.assign(this.editablePanelField, updatedPanelField)
        this.close(this.editablePanelField)
      })
      .catch(console.error)
      .finally(() => {
        this.state.submitting = false
      })
  }

  resetError() {
    this.state.error = false
  }

  close(panelField) {
    panelField
      ? this.$mdDialog.hide({ wasCreate: this.isCreate, panelField })
      : this.$mdDialog.cancel()
  }
}
