// create users team info view

const manageUsersCreateTeamInfo = 'manage.users.create.teamInfo'

const manageUsersCreateTeamInfoConfig = {
  params: {
    user: null
  },
  component: 'teamInfoForm',
  resolve: {
    user: function ($state, $transition$) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) {
        $state.go('manage.users.create.personalInfo')
      }
      return user
    },
    teams: function (IdentityService) {
      'ngInject'
      return IdentityService.Team.list.get({ editable: true })
        .then(teams => teams.plain())
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreateTeamInfo, manageUsersCreateTeamInfoConfig }
