// create campaign router view

const manageEditAccessListNodeEdit = 'manage.editAccessList.node.edit'

const manageEditAccessListNodeEditConfig = {
  params: {
    node: null,
    accessList: null,
    company: null
  },
  component: 'editAccessListNodeForm',
  resolve: {
    node: function ($transition$) {
      'ngInject'
      return $transition$.params().node
    },
    accessList: function ($transition$) {
      'ngInject'
      return $transition$.params().accessList
    },
    company: function ($transition$) {
      'ngInject'
      return $transition$.params().company
    }
  }
}

export { manageEditAccessListNodeEdit, manageEditAccessListNodeEditConfig }
