export default class MetricInfoDialogController {
  constructor ($mdDialog, $sce) {
    'ngInject'
    this.$mdDialog = $mdDialog
    // angular will not trust this resource url unless explicitly told to
    // https://code.angularjs.org/1.5.3/docs/api/ng/service/$sce
    this.fileKey = $sce.trustAsResourceUrl(require(`../../../../markdown/metrics/${this.key}.md`))
  }

  close () {
    this.$mdDialog.hide()
  }
}
