import React, { useState, useEffect } from 'react'
import { Grid, FormControl, InputAdornment, Input } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppModal from '../../../../components/AppModal.js'
import AppSearchInput from '../../../../components/AppSearchInput.js'
import SearchIcon from '@material-ui/icons/Search'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
const faker = require('faker')

const useStyles = makeStyles(theme => ({
  form: {
    width: 100 + '%',
  },
  searchIcon: {
    color: '#bbbbbb',
  },
  tableHead: {
    backgroundColor: '#fbfbfb',
    borderTop: 'solid 1px #eeeeee',
    borderBottom: 'solid 1px #eeeeee',
    fontWeight: 500,
    fontSize: 14,
  },
  menu: {
    position: 'absolute',
    right: 45,
    'z-index': 9999,
    width: 150,
  },
}))

const QAHistory = props => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [data, setData] = useState(() => {
    let numbers = [1, 2, 3, 4, 5, 6, 7]
    let data = []
    numbers.forEach(item => {
      let obj = {
        uuid: faker.random.uuid(),
        last: faker.name.lastName(),
        disposition: 'Do not call',
        quality: '',
        ncs: '',
        location: 'sig',
        prospect: `${faker.random.number()} (8_CST_S2_Dec_1_4)`,
        submits: '-',
      }
      data.push(obj)
    })
    return data
  })

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-child(odd)': {
        backgroundColor: '#fbfbfb',
      },
      border: 'none',
    },
  }))(TableRow)

  const StyledTableCell = withStyles(theme => ({
    root: {
      border: 'none',
      padding: 20,
    },
  }))(TableCell)

  const StyledTable = withStyles(theme => ({
    root: {
      border: 'solid 1px #eeeeee',
    },
  }))(Table)

  useEffect(() => {
    setSearchResults(data)
  }, [data])

  return (
    <AppModal
      modalWith={'90%'}
      title={'History'}
      showModal={props.showModal}
      hideModal={props.hideModal}
    >
      <Grid container justify="center">
        <Grid item xs={12} style={{ padding: 25 }}>
          <AppSearchInput value={search} onChange={e => setSearch(e)} />
        </Grid>

        <StyledTable className="m-2">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>UUID</TableCell>
              <TableCell align="left">
                <a onClick={() => sortList('last')} className="cursor-pointer">
                  <span className="left">Called</span>
                  <i className="material-icons left">keyboard_arrow_down</i>
                </a>
              </TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Disposition</TableCell>
              <TableCell align="left">Revenue</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Hangup cause</TableCell>
              <TableCell align="left">Prospect DID</TableCell>
              <TableCell align="left">Rep</TableCell>
              <TableCell align="left">Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <tr></tr>
            {searchResults.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {item.uuid}
                </StyledTableCell>
                <StyledTableCell align="left">{item.last}</StyledTableCell>
                <StyledTableCell align="left">Do not call</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left">Si.</StyledTableCell>
                <StyledTableCell align="left">{item.prospect}</StyledTableCell>
                <StyledTableCell align="left">-</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Grid>
    </AppModal>
  )
}

export default QAHistory
