import './buyDidConfirmation.scss'
import BuyDidConfirmationController from './controller/BuyDidConfirmationController'
const template = require('./buyDidConfirmation.tpl.pug')

const buyDidConfirmation = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: BuyDidConfirmationController,
}

export default buyDidConfirmation
