import { pick } from 'ramda'
import ARD from '../../../../dialogs/audio-recording/audioRecording.dialog.js'
import ADD from '../../../../dialogs/audio-dialog/audio.dialog.js'
import BaseAudioController from '../../common/controller/BaseAudioController.js'
import {
  unrecordedConfig,
  rerecordConfig,
  recordedConfig,
  addButtonActions,
} from '../config/pitchAudioTables.js'

export default class PitchAudioController extends BaseAudioController {
  constructor(
    $mdDialog,
    $sessionStorage,
    IdentityService,
    ManagementService,
    RoleStore,
    PitchService,
    ManageAudioService,
    ToastService,
    $rootScope,
    $scope,
  ) {
    'ngInject'
    super(...arguments)

    this.audioType = 'pitch'
    this.viewingDialog = ADD
    this.recordingDialog = ARD
    this.unrecordedTable = unrecordedConfig
    this.rerecordTable = addButtonActions(
      { MOVE_TO_RECORD: this.moveToRecord.bind(this) },
      rerecordConfig,
    )
    this.recordedTable = addButtonActions(
      { MOVE_TO_RERECORD: this.moveToRerecord.bind(this) },
      recordedConfig,
    )
    $scope.$watch('$ctrl.state.selected.pitchVersion', (newVal, oldVal) => {
      $rootScope.refreshOptions.isVisible = !!newVal
    })
    Object.assign(this.state.selectOptions, {
      pitchVersions: [],
    })
    this.disableButton = true
    this.isSelectionApplied = false
    this.state.errors = {
      pitch_not_available: false,
    }
  }

  selectCampaignCb(campaign) {
    this.isSelectionApplied = false
    this.state.errors.pitch_not_available = false
    this.state.loadingAudio = true
    Object.assign(this.state.selected, { pitchVersion: null })
    campaign.company = campaign.company.uuid
      ? campaign.company.uuid
      : campaign.company // ensure this is always a uuid string
    sessionStorage.setItem("voiceRealms", JSON.stringify(campaign.realms))
    this.MS.populateEntityRelations(campaign, {
      entityName: 'campaign',
      populate: ['company'],
    })
      .then(this.PS.setupBase.bind(this.PS))
      .then(services => services.companyCampaign.one('').get())
      .then(pitch => {
        this.state.selectOptions.pitchVersions = [...pitch.versions]
        this.state.loadingAudio = false
        this.state.selected.pitchVersion = pitch.active_version
        this.disableButton = false
        this.setAudio([], [], [])
      })
      .catch(err => {
        this.state.loadingAudio = false
        if (err.status === 403) {
          this.state.errors.pitch_not_available = true
          return
        }
        this.state.selectOptions.pitchVersions = []
        this.TS.show({ text: 'Problem fetching pitch versions' })
        console.error(err)
      })
  }

  selectVersionCb(pitchVersionId) {
    this.state.loadingAudio = true
    this.state.errors.pitch_not_available = false
    this.setupVoiceService(pitchVersionId)
    const serviceKeys = ['recorded', 'rerecord', 'unrecorded']
    const service = pick(serviceKeys, this.voiceAudioService)
    this.MAS.fetchVoiceAudio({ service, audio: serviceKeys })
      .then(this.setAudio.bind(this))
      .finally(() => {
        this.state.loadingAudio = false
      })
  }

  setupVoiceService(pitchVersionId) {
    const voiceId = this.state.selected.voice
      ? this.state.selected.voice.uuid
      : this.user.uuid
    const serviceConfig = {
      voiceId,
      pitchVersionId,
    }
    this.voiceAudioService = this.PS.setupDialogVoice(serviceConfig)
  }

  setAudio({ recorded, rerecord, unrecorded }) {
    this.unrecorded = unrecorded
    this.rerecord = rerecord
    this.recorded = recorded
  }

  fetchAudio() {
    this.selectVersionCb(this.state.selected.pitchVersion)
    this.disableButton = true
    this.isSelectionApplied = true
  }

  clearSelections() {
    Object.assign(this.state.selected, {
      voice: null,
      campaign: null,
      pitchVersion: null,
    })
  }

  canFetchAudio() {
    this.disableButton = false
    this.isSelectionApplied = false
  }
}
