import { MANAGE_GROUP } from 'permissions.js'

// edit location view

const manageEditLocation = 'manage.editLocation'

const manageEditLocationConfig = {
  url: '/locations/edit/{uuid}',
  params: {
    uuid: null,
    entity: null,
  },
  component: 'editLocation',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ]
    }
  },
  resolve: {
    location: function ($state, $transition$, IdentityService) {
      'ngInject'
      if ($transition$.params().entity) return $transition$.params().entity
      return IdentityService.Location.fetch
        .one($transition$.params().uuid)
        .get()
        .then(location => location.plain())
    },
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Editing Location'
      $window.document.title = pageTitle
      return pageTitle
    },
  }
}

export { manageEditLocation, manageEditLocationConfig }
