import GoalsController from './controller/GoalsController.js'
const template = require('./goals.tpl.pug')

const goals = {
  template: template(),
  controller: GoalsController,
  bindings: {
    company: '<',
    campaign: '<',
    selectedRealm: '<',
  }
}

export default goals
