// @flow

import type { Permission, UserPermission } from 'permissions.js'

import { pipe, intersection, complement, isEmpty } from 'ramda'

type SectionType = 'toggle' | 'link'

export type Page = {
  name: string,
  type: SectionType,
  icon: string,
  state: string,
  permissions: Array<Permission>,
}

export type MenuSection = {
  name: string,
  type: SectionType,
  icon: string,
  permissions: Array<Permission>,
  // these substates are states that aren't direct links from the menu but
  // can be considered child states of that menu section
  includedSubstates: Array<string>,
  pages: Array<Page>,
}

export type SideNavMenu = MenuSection[]

export default class SideNavService {
  createMenuForRole(menu: SideNavMenu, userRoles: UserPermission) {
    const roleKeys = Object.keys(userRoles).filter(role =>
      userRoles[role].validator(),
    )
    const overlaps = pipe(
      intersection(roleKeys),
      complement(isEmpty),
    )
    // Filters different menu sections and pages by user role
    return menu
      .filter(section => overlaps(section.permissions))
      .map(section => {
        if (!section.pages) return section
        section.pages = section.pages.filter(page => overlaps(page.permissions))
        return section
      })
  }
}
