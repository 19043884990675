// @flow

import type { BaseService } from 'restangular'
import type { PitchAudioData, FullPitchAudio } from 'types/entities.js'

export default class AsyncAudioController {
  MAS: Object
  loading: boolean
  audioLoaded: boolean
  audio: PitchAudioData
  fullAudio: ?FullPitchAudio
  service: BaseService

  constructor(ManageAudioService: Object) {
    'ngInject'
    this.MAS = ManageAudioService
    this.loading = false
    this.audioLoaded = false
  }

  getAudioVoice(ev: Event) {
    ev.stopPropagation()
    if (!this.audioLoaded) {
      this.loading = true
      this.MAS.populateAudioFiles({
        service: this.service,
        audio: [this.audio],
      })
        .then((populatedAudio: FullPitchAudio[]) => {
          this.fullAudio = populatedAudio[0]
          this.audioLoaded = true
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
