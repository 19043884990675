export default class ManageCompaniesCreateController {
  constructor ($state) {
    'ngInject'

    this.$state = $state
  }

  backTo () {
    this.$state.go('manage.companies')
  }
}
