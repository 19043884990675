import angular from 'angular'

// components
import editRapidResponseTests from './component/editRapidResponseTests.component.js'
import editRapidResponseTestsMenu from './sub-components/menu/component/rapidResponseTestsMenu.component'
import editRapidResponseTestsSettings from './sub-components/settings/component/rapidResponseTestsSettings.component'
import editRapidResponseTestsSegments from './sub-components/segments/component/rapidResponseTestsSegments.component'
import editRapidResponseTestsVoices from './sub-components/voices/component/rapidResponseTestsVoices.component'
import editRapidResponseTestsCampaigns from './sub-components/campaigns/component/rapidResponseTestsCampaigns.component'
import editRapidResponseTestsVariables from './sub-components/variables/component/rapidResponseTestsVariables.component'

export default angular
  .module('portalApp.manage.editAccessListNode', [])
  .component('editRapidResponseTests', editRapidResponseTests)
  .component('editRapidResponseTestsMenu', editRapidResponseTestsMenu)
  .component('editRapidResponseTestsSettings', editRapidResponseTestsSettings)
  .component('editRapidResponseTestsSegments', editRapidResponseTestsSegments)
  .component('editRapidResponseTestsVoices', editRapidResponseTestsVoices)
  .component('editRapidResponseTestsCampaigns', editRapidResponseTestsCampaigns)
  .component('editRapidResponseTestsVariables', editRapidResponseTestsVariables)
  .name
