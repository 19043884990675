export default function theme($mdThemingProvider) {
  'ngInject'
  const customPrimary = {
    '50': '#acbccb',
    '100': '#9cafc1',
    '200': '#8ca3b7',
    '300': '#7d96ad',
    '400': '#6d8aa4',
    '500': '#5f7d98',
    '600': '#557088',
    '700': '#4b6379',
    '800': '#425669',
    '900': '#384959',
    A100: '#bbc9d5',
    A200: '#cbd5de',
    A400: '#dbe2e8',
    A700: '#2e3c4a',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('customPrimary', customPrimary)

  const customAccent = {
    '50': '#667f26',
    '100': '#76932c',
    '200': '#86a632',
    '300': '#96ba37',
    '400': '#a3c744',
    '500': '#accd57',
    '600': '#c0d97f',
    '700': '#c9df92',
    '800': '#d3e5a6',
    '900': '#ddeaba',
    A100: '#1194f6',
    A200: '#b6d36b',
    A400: '#accd57',
    A700: '#e6f0cd',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('customAccent', customAccent)

  const customWarn = {
    '50': '#ffcdcc',
    '100': '#ffb4b3',
    '200': '#ff9b99',
    '300': '#ff8280',
    '400': '#ff6966',
    '500': '#ff504d',
    '600': '#ff3733',
    '700': '#ff1e1a',
    '800': '#ff0500',
    '900': '#e60400',
    A100: '#ffe6e6',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#cc0300',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('customWarn', customWarn)

  const customBackground = {
    '50': '#ffffff',
    '100': '#ffffff',
    '200': '#ffffff',
    '300': '#ffffff',
    '400': '#ffffff',
    '500': '#fcfcfc',
    '600': '#efefef',
    '700': '#e2e2e2',
    '800': '#d6d6d6',
    '900': '#c9c9c9',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#bcbcbc',
    cancel: '#dddddd',
    contrastDefaultColor: 'dark',
  }
  $mdThemingProvider.definePalette('customBackground', customBackground)

  const ppPrimary = {
    '50': '#808694',
    '100': '#737988',
    '200': '#676d7b',
    '300': '#5b616d',
    '400': '#50545f',
    '500': '#444851',
    '600': '#383c43',
    '700': '#2d2f35',
    '800': '#212327',
    '900': '#15171a',
    A100: '#8e93a0',
    A200: '#9ca1ac',
    A400: '#aaaeb7',
    A700: '#0a0a0c',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('ppPrimary', ppPrimary)

  const ppAccent = {
    '50': '#4f6121',
    '100': '#5f7428',
    '200': '#6e872e',
    '300': '#7e9a35',
    '400': '#8dad3b',
    '500': '#9cbe43',
    '600': '#b0cc69',
    '700': '#bad27c',
    '800': '#c4d98f',
    '900': '#cedfa2',
    A100: '#b0cc69',
    A200: '#a6c556',
    A400: '#9cbe43',
    A700: '#d8e6b5',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('ppAccent', ppAccent)

  const ppWarn = {
    '50': '#fef2e5',
    '100': '#fddfbd',
    '200': '#fcca91',
    '300': '#fab565',
    '400': '#f9a544',
    '500': '#f89523',
    '600': '#f78d1f',
    '700': '#f6821a',
    '800': '#f57815',
    '900': '#f3670c',
    A100: '#ffffff',
    A200: '#fff1ea',
    A400: '#ffd0b7',
    A700: '#ffbf9d',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette('ppWarn', ppWarn)

  const managementToolbarPrimary = {
    '50': '#ffffff',
    '100': '#ffffff',
    '200': '#ffffff',
    '300': '#ffffff',
    '400': '#ffffff',
    '500': '#ffffff',
    '600': '#f2f2f2',
    '700': '#e6e6e6',
    '800': '#d9d9d9',
    '900': '#cccccc',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#bfbfbf',
  }
  $mdThemingProvider.definePalette(
    'managementToolbarPrimary',
    managementToolbarPrimary,
  )

  const managementToolbarAccent = {
    '50': '#043e6a',
    '100': '#054d83',
    '200': '#065b9b',
    '300': '#076ab4',
    '400': '#0878cc',
    '500': '#0987e5',
    '600': '#2a9ff7',
    '700': '#42aaf8',
    '800': '#5bb5f9',
    '900': '#73c0fa',
    A100: '#2a9ff7',
    A200: '#1194f6',
    A400: '#0987e5',
    A700: '#8ccbfb',
  }
  $mdThemingProvider.definePalette(
    'managementToolbarAccent',
    managementToolbarAccent,
  )

  const managementToolbarWarn = {
    '50': '#c3c9cf',
    '100': '#b5bdc4',
    '200': '#a7b0b9',
    '300': '#98a3ae',
    '400': '#8a97a2',
    '500': '#7c8a97',
    '600': '#6e7d8b',
    '700': '#63707d',
    '800': '#58646f',
    '900': '#4d5760',
    A100: '#d1d6db',
    A200: '#e0e3e6',
    A400: '#eef0f1',
    A700: '#414a52',
    contrastDefaultColor: 'light',
  }
  $mdThemingProvider.definePalette(
    'managementToolbarWarn',
    managementToolbarWarn,
  )

  $mdThemingProvider
    .theme('default')
    .primaryPalette('customPrimary')
    .accentPalette('customAccent')
    .warnPalette('customWarn')
    .backgroundPalette('customBackground')

  $mdThemingProvider
    .theme('ppColors')
    .primaryPalette('ppPrimary')
    .accentPalette('ppAccent')
    .warnPalette('ppWarn')

  $mdThemingProvider
    .theme('managementToolbar')
    .primaryPalette('managementToolbarPrimary')
    .accentPalette('managementToolbarAccent')
    .warnPalette('managementToolbarWarn')
}
