// Dashboard
import {
  dashboardDialerLive,
  dashboardDialerLiveConfig,
} from './dashboard.dialerLive.js'

export default function dashboardDialerLiveRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardDialerLive, dashboardDialerLiveConfig)
}
