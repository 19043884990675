import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const BaseButton = withStyles({
  root: {
    boxShadow: 'none',
    height: 40,
    color: '#444851',
    fontSize: 14,
    border: '1px solid',
    borderRadius: 3,
    backgroundColor: '#eeeeee ',
    borderColor: '#eeeeee ',
    fontFamily: ['Roboto'].join(','),
  },
})(Button)

const useStyles = makeStyles(theme => ({
  BtnGreen: {
    color: 'white',
    backgroundColor: '#b6d36b ',
    borderColor: '#b6d36b ',
    '&:hover': {
      backgroundColor: '#b6d36b',
      borderColor: '#b6d36b',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#b6d36b',
      borderColor: '#b6d36b',
      boxShadow: 'none',
    },
  },
  BtnDefault: {
    color: '#444851',
    backgroundColor: '#eeeeee',
    borderColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#7c8a97',
      borderColor: '#7c8a97',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#7c8a97',
      borderColor: '#7c8a97',
      boxShadow: 'none',
    },
  },
  BtnGrey: {
    color: 'white',
    backgroundColor: '#7c8a97',
    borderColor: '#7c8a97',
    '&:hover': {
      backgroundColor: '#7c8a97',
      borderColor: '#7c8a97',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#7c8a97',
      borderColor: '#7c8a97',
      boxShadow: 'none',
    },
  },
  BtnRed: {
    color: 'white',
    backgroundColor: '#ff504d',
    borderColor: '#ff504d',
    '&:hover': {
      backgroundColor: '#ff504d',
      borderColor: '#ff504d',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#ff504d',
      borderColor: '#ff504d',
      boxShadow: 'none',
    },
  },
}))

const AppButton = props => {
  const classes = useStyles()
  const type = props.type || 'Default'

  return (
    <BaseButton
      style={{ width: props.width || '100%' }}
      className={classes[`Btn${type}`] + ` ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </BaseButton>
  )
}

export default AppButton
