import RapidResponseTestsController from './controller/RapidResponseTestsController'
import template from './rapidResponseTests.component.tpl.pug'

const rapidResponseTests = {
  template: template(),
  controller: RapidResponseTestsController,
  bindings: {
    entity: '<',
  },
}

export default rapidResponseTests
