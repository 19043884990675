import { addIndex, reduce } from 'ramda'

export default class ListGraphController {
  constructor($scope, $mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.makeCsv = this.parseCsv.bind(
      this,
      this.pieChartConfig,
      this.chartConfig,
      this.title,
      this.listSlug,
    )
  }

  parseCsv(pieChart, lineChart, title, listSlug) {
    const pie = pieChart.getHighcharts()
    const line = lineChart.getHighcharts()
    const append = line.getDataRows().length > 25 ? '_90_Day' : '_24_Hour'
    const formatTitle = title
      .split(' ')
      .filter(char => char !== '/')
      .join('_')
      .concat(append)
    const combinedData = this.combineCSV(pie.getCSV(), line.getCSV())
    return {
      fileName: `${listSlug}_${formatTitle}.csv`,
      data: combinedData,
    }
  }

  combineCSV(one, two) {
    const reduceIndexed = addIndex(reduce)
    const oneArr = one.split('\n')
    const twoArr = two.split('\n')
    const combined = reduceIndexed(this.csvReducer, [], [oneArr, twoArr])
    const csvString = combined.join('\n')
    return csvString
  }

  csvReducer(acc, csv, index) {
    csv.forEach((line, lineIndex) => {
      if (index === 0) {
        acc.push(line)
      } else if (acc[lineIndex] && index > 0) {
        acc[lineIndex] += `,,${line}`
      } else {
        acc.push(
          `${Array(index + 2)
            .fill(',')
            .join('')}${line}`,
        )
      }
    })
    return acc
  }

  close() {
    this.$mdDialog.hide()
  }
}
