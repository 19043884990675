import './listDetails.scss'
import ListDetailsController from './controller/ListDetailsController.js'
const template = require('./listDetails.tpl.pug')

const listDetails = {
  template: template(),
  controller: ListDetailsController,
  bindings: {
    details: '<',
    metrics: '<',
    loading: '<'
  }
}

export default listDetails
