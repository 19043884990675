export default class CampaignSettingsController {
  constructor($state, ManagementService, EditCampaignService) {
    'ngInject'

    this.$state = $state
    this.MS = ManagementService
    this.ECS = EditCampaignService

    this.state = {
      loading: {
        details: true,
        dataPosting: true,
      },
      routers: [],
    }
  }

  $onInit() {
    this.getCampaignRouter(this.campaign)
  }

  createNewRoute(campaign) {
    this.$state.go('manage.editCampaign.dataPosting.create.routerInfo', {
      campaign,
    })
  }

  toggleSection(toggleId) {
    this.state.show[toggleId] = !this.state.show[toggleId]
  }

  getCampaignRouter(campaign) {
    this.state.loading.dataPosting = true
    this.MS
      .populateEntityRelations(this.campaign, { entityName: 'campaign' })
      .then(campaign => {
        this.campaign = campaign
        this.uploadUrl = `https://api.perfectpitchtech.com/prospect/upload/${campaign
          .company.slug}/${campaign.slug}/`
        return this.ECS.getCampaignRouter(campaign)
      })
      .then(routers => {
        this.state.routers = routers
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.state.loading.dataPosting = false
      })
  }
}
