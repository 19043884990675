import angular from 'angular'

// Services
import SideNavService from './services/SideNavService.js'

// components
import sideNav from './component/sideNav.js'
import sideNavLink from './sub-components/side-nav-link/sideNavLink.js'
import sideNavToggle from './sub-components/side-nav-toggle/sideNavToggle.js'
import sideNavTopLink from './sub-components/side-nav-top-link/sideNavTopLink.js'
import sideNavShortIcons from './sub-components/side-nav-short-icons/sideNavShortIcons.js'

export default angular
  .module('portalApp.sideNav', [])
  .service('SideNavService', SideNavService)
  .component('sideNav', sideNav)
  .component('sideNavLink', sideNavLink)
  .component('sideNavToggle', sideNavToggle)
  .component('sideNavTopLink', sideNavTopLink)
  .component('sideNavShortIcons', sideNavShortIcons).name
