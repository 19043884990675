// @flow

import DED from '../../delete-entity/deleteEntity.js'
import { find, propEq } from 'ramda'

import type { Did, DidPool } from 'types/entities.js'

type PoolData = {
  company: string,
  campaign: string,
  pools: Array<DidPool>,
}

export default class UpdateDidController {
  did: Did // passed to dialog
  editableDid: Did
  company: string
  campaign: string
  $q: Object
  $mdDialog: Object
  $state: Object
  Company: Object
  Campaign: Object
  DidPool: Object
  Did: Object
  TS: Object
  state: {
    loading: boolean,
    submitting: boolean,
    pools: Array<DidPool>,
  }

  constructor(
    $q: Object,
    $mdDialog: Object,
    $state: Object,
    IdentityService: Object,
    ToastService: Object,
  ) {
    'ngInject'

    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.Company = IdentityService.Company
    this.Campaign = IdentityService.Campaign
    this.DidPool = IdentityService.DidPool
    this.Did = IdentityService.Did
    this.TS = ToastService
    this.state = {
      loading: true,
      submitting: false,
      pools: [],
    }

    this.onInit()
  }

  onInit() {
    this.editableDid = Object.assign({}, this.did)
    this.getPoolData()
    this.getDidPools()
  }

  updateDid(updatedDid: Did) {
    this.state.submitting = true
    const uriData = {
      uuid: updatedDid.uuid,
      company: this.company,
      campaign: this.campaign,
      pool: this.did.pool.uuid || this.did.pool, // should always be an object but the api might hold a surprise
    }

    // only pass the uuid string to the api
    if (typeof updatedDid.pool !== 'string' && updatedDid.pool.uuid) {
      updatedDid.pool = updatedDid.pool.uuid
    }

    this.Did.edit
      .patch(updatedDid, uriData)
      .then(updatedDid => {
        // add the full pool object
        updatedDid.pool = find(
          propEq('uuid', updatedDid.pool),
          this.state.pools,
        )
        this.$mdDialog.hide(updatedDid)
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error updating DID',
        })
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  deleteDid(): void {
    const uriData = {
      uuid: this.did.uuid,
      company: this.company,
      campaign: this.campaign,
      pool: this.did.pool.uuid || this.did.pool,
    }
    DED.locals = {
      title: 'Delete DID',
      entityName: this.did.number,
      entity: this.did,
      identityEndpoint: this.Did.one(uriData),
      routeParams: { uuid: uriData.pool },
      routeWhenSuccessful: this.$state.current.name,
    }
    this.$mdDialog.show(DED)
  }

  getPoolData(): Promise<PoolData> {
    return this.$q
      .all({
        campaign: this.getPoolCampaign(),
        company: this.getPoolCompany(),
        pools: this.getDidPools(),
      })
      .then(poolData => {
        this.campaign = poolData.campaign
        this.company = poolData.company
        this.state.pools = poolData.pools
      })
      .finally(() => {
        this.state.loading = false
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error Fetching DID details',
        })
      })
  }

  getPoolCampaign(): Promise<string> {
    if (this.campaign) {
      // Campaign should be a slug here
      return this.$q.resolve(this.campaign)
    } else {
      // Go fetch the full campaign from API
      if (typeof this.did.pool !== 'string') {
        return this.Campaign.fetch
          .one(this.did.pool.campaign)
          .get()
          .then(campaign => campaign.slug)
      }
      // Shouldn't ever get here unless controller has incorrect properties
      return this.$q.reject('Failed to get campaign for pool')
    }
  }

  getPoolCompany(): Promise<string> {
    if (this.company) {
      // Company should be a slug here
      return this.$q.resolve(this.company)
    } else {
      // Go fetch the full company from API
      if (typeof this.did.pool !== 'string') {
        return this.Company.fetch
          .one(this.did.pool.company)
          .get()
          .then(company => company.slug)
      }
      // Shouldn't ever get here unless controller has incorrect properties
      return this.$q.reject('Failed to get company for pool')
    }
  }

  toggleProp(bool: boolean, prop: string) {
    this.editableDid[prop] = bool
  }

  getDidPools() {
    return this.DidPool.list.get().then(pools => pools.plain())
  }

  close() {
    this.$mdDialog.cancel()
  }
}
