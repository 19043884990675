import { ENV, API } from 'settings.js'

export function getBaseUrl(host, rc = false) {
  const prodHost = /^portal\./
  const rcHost = /^rc-portal\./

  if (ENV.API_BASE_URL) {
    return ENV.API_BASE_URL
  } else if (host.match(rcHost)) {
    return rc ? API.production.rc : API.production.base
  } else if (host.match(prodHost)) {
    return rc ? API.production.rc : API.production.base
  } else {
    return rc ? API.development.rc : API.development.base
  }
}

export function BaseRestangular(RestangularProvider) {
  'ngInject'
  const Rest = RestangularProvider
  const baseUrl = getBaseUrl(window.location.host, false)
  Rest.setBaseUrl(baseUrl)
  Rest.setRequestSuffix('/')
  if (ENV.NODE_ENV !== 'test') {
    console.warn(`Using ${baseUrl} as api server`)
  }
}

export function RCRestangular(Restangular) {
  'ngInject'
  return Restangular.withConfig(RestangularConfigurer => {
    const baseUrl = getBaseUrl(window.location.host, true)
    RestangularConfigurer.setBaseUrl(baseUrl)
  })
}

export function AWSRestangular(Restangular) {
  'ngInject'
  return Restangular.withConfig(RestangularConfigurer => {
    const baseUrl = API.aws.base
    RestangularConfigurer.setBaseUrl(baseUrl)
  })
}
