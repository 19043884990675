export default class ProspectService {
  constructor (Restangular) {
    'ngInject'

    this.router = Restangular.all('/prospect/router/')

    this.lists = {
      all: Restangular.all('/prospect/list/all'),
      fetch: Restangular.all('/prospect/list'),
    }

    this.createCampaignRoute = {
      post: (data) => {
        const { compSlug, campSlug, ...routeData } = data
        const normalizedRouteData = this.normalizeRouteData(routeData)
        return this.router
          .one(`${compSlug}/${campSlug}`)
          .customPOST(normalizedRouteData)
      }
    }

    this.createRouterRoute = {
      post: (data) => {
        const { compSlug, campSlug, routerId, ...routeData } = data
        return this.router
          .one(`${compSlug}/${campSlug}/${routerId}/route`)
          .customPOST(routeData)
      }
    }
  }

  normalizeRouteData (routeData) {
    const routeContinue = routeData.route_continue
      .map(code => code.toString())
      .join(',')

    return Object.assign({}, routeData, { route_continue: routeContinue })
  }
}

