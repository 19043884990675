// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

type Classes = {
  row: string,
  cell: string,
  icon: string,
}

type Props = {
  loading: boolean,
  headers: string[],
  tableData: Array<Object>,
  Loader: React.Node,
  render: (tableData: Array<Object>, classes: Classes) => React.Node,
  classes: Object,
  innerRef: string,
}

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  header: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#444851',
    backgroundColor: 'rgba(124, 138, 151, 0.05)',
    borderTop: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee'
  },
  headerRow: {
    height: 50,
  },
  row: {
    height: 50,
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(124, 138, 151, 0.05)',
    },
  },
  cell: {
    borderBottom: 'none',
  },
  icon: {
    width: 10,
    height: 10,
  },
}


function AsyncTable(props: Props) {
  const { loading, classes, headers, tableData, Loader } = props

  return (
    <div>
      {loading ? (
        Loader
      ) : (
          <Table className={classes.table}>
            {headers.length > 0 &&
              <TableHead>
                <TableRow className={classes.headerRow}>
                  {
                    headers.map((header, idx) => (
                      <TableCell className={classes.header} key={idx}>
                        {header}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
            }
            <TableBody>
              {props.render(tableData, {
                row: classes.row,
                cell: classes.cell,
                icon: classes.icon,
              })}
            </TableBody>
          </Table>
        )}
    </div>
  )
}

export default withStyles(styles)(AsyncTable)
