import './sumTotalTable.scss'
import SumTotalTableController from './controller/sumTotalTableController.js'
const template = require('./sumTotalTable.tpl.pug')

const sumTotalTable = {
  template: template(),
  controller: SumTotalTableController,
  bindings: {
    listName: '@',
    entity: '@',
    entityPlural: '@',
    placeholderText: '@',
    onSearch: '<',
    searchText: '<',
    isLoading: '<',
    tableData: '<',
    tableConfig: '<',
    sumTotalValues: '<',
  },
}

export default sumTotalTable
