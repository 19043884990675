const changePassword = 'changePassword'

const changePasswordConfig = {
  url: '/change-password',
  component: 'changePassword',
  resolve: {
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Change Password'
      $window.document.title = pageTitle
      return pageTitle
    }
  }
}

export { changePassword, changePasswordConfig }
