import { reduce } from 'ramda'
import metricInfoDialog from '../../../../dialogs/metric-info/metricInfoDialog.js'

export default class RepsLiveController {
  constructor($mdDialog, StatsService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.SS = StatsService
    this.state = {
      loading: true,
      reps: 0,
      stations: 0,
      error: false,
    }
  }

  $onInit() {
    this.fetchData(this.metric.details)
  }

  $onChanges(bindings) {
    this.updateBindings(bindings)
  }

  updateBindings(bindings) {
    const bndKeys = Object.keys(bindings)
    bndKeys.forEach(bnd => {
      this[bnd] = bindings[bnd].currentValue
    })
    this.fetchData(this.metric.details)
  }

  fetchData(details) {
    this.SS[this.key].get
      .call(this.SS, details)
      .then(data => {
        this.state.reps = data.results.length
        this.state.stations = this.getStations(data.results)
        this.state.loading = false
      })
      .catch(err => {
        this.state.loading = false
        this.state.error = true
        console.error('Reps Live Error: ', err)
      })
  }

  getStations(reps) {
    const totalStations = (acc, rep) => {
      acc += rep.replogin.count
      return acc
    }
    return reduce(totalStations, 0, reps)
  }

  openInfo(ev, title, metricKey) {
    const MID = metricInfoDialog
    MID.targetEvent = ev
    MID.locals = {
      title: this.title,
      key: metricKey,
    }
    this.$mdDialog.show(MID)
  }
}
