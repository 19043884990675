// Manage Edit Voices
import {
  manageEditVoices,
  manageEditVoicesConfig,
} from './manage.editVoices.js'

export default function manageEditVoicesRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(manageEditVoices, manageEditVoicesConfig)
}
