import './manageAudioVoice.scss'
import ManageAudioVoiceController from './controller/ManageAudioVoiceController.js'
const template = require('./manageAudioVoice.component.tpl.pug')

const manageAudioVoice = {
  controller: ManageAudioVoiceController,
  template: template(),
  bindings: {},
}

export default manageAudioVoice

