// Manage Audio Resources
import {
  MANAGE_AUDIO_RESOURCES,
  manageAudioResourcesConfig,
} from './manage.audioResources.js'

export default function manageAudioResourcesRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(MANAGE_AUDIO_RESOURCES, manageAudioResourcesConfig)
}
