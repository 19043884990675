// Manage Companies
import { manageCompanies, manageCompaniesConfig } from './manage.companies.js'
import {
  manageCompaniesCreate,
  manageCompaniesCreateConfig,
} from './child-states/manage-companies-create/manage.companies.create.js'
import {
  manageCompaniesCreateCompanyInfo,
  manageCompaniesCreateCompanyInfoConfig,
} from './child-states/manage-companies-create/child-states/company-info/manage.companies.create.companyInfo.js'

export default function manageCompaniesRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageCompanies, manageCompaniesConfig)
    .state(manageCompaniesCreate, manageCompaniesCreateConfig)
    .state(
      manageCompaniesCreateCompanyInfo,
      manageCompaniesCreateCompanyInfoConfig,
    )
}
