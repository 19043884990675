import { MANAGE_GROUP } from 'permissions'
// manage teams view

const manageTeams = 'manage.teams'

const manageTeamsConfig = {
  url: '/teams',
  component: 'manageTeams',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  }
}

export { manageTeams, manageTeamsConfig }

