import { clone, pickBy, reduce } from 'ramda'
import { getRealmSlug } from 'util/realm.js'
import componentSettingsDialog from '../../../../dialogs/component-settings/componentSettingsDialog.js'

export default class CampaignHealthController {
  constructor($mdDialog, $timeout, CampaignHealthService, ToastService) {
    'ngInject'
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.CHS = CampaignHealthService
    this.TS = ToastService

    this.subMetrics = {}
    this.allowedSubKeys = {}
  }

  $onInit() {
    this.state = {
      searching: false,
      showMetrics: true,
      company: this.company,
      campaign: this.campaign,
      realm: getRealmSlug(this.realm),
      componentDetails: this.componentDetails,
      dateRange: this.dateRange,
    }

    this.CHS.registerComponent(
      this.componentDetails,
      this.company.slug,
      this.campaign.slug,
    )
    this.CHS.getSettings(
      this.company.slug,
      this.campaign.slug,
      this.componentDetails.key,
    )
      .then(data => {
        this.CHS.components[data.key].metrics = data.value
        this.buildMetrics(data.key, this.state.dateRange, this.state.realm)
      })
      .catch(err => {
        if (err.status === 404) {
          this.CHS.components[
            this.componentDetails.key
          ].metrics = this.state.componentDetails.defaultMetrics
          this.buildMetrics(
            this.componentDetails.key,
            this.state.dateRange,
            this.state.realm,
          )
          return
        }
        this.TS.show({
          text: `Problem fetching ${this.title}`,
        })
      })
  }

  $onChanges(bindings) {
    if (
      bindings.dateRange &&
      bindings.componentDetails &&
      !bindings.componentDetails.isFirstChange()
    ) {
      this.state.dateRange = clone(this.dateRange)
      this.buildMetrics(
        this.componentDetails.key,
        this.state.dateRange,
        this.state.realm,
      )
    }

    if (bindings.dateRange && !bindings.dateRange.isFirstChange()) {
      this.state.dateRange = clone(this.dateRange)
      this.buildMetrics(
        this.componentDetails.key,
        this.state.dateRange,
        this.state.realm,
      )
    }
  }

  buildMetrics(key, dateRange, realm) {
    this.state.searching = true
    this.CHS.buildMetrics(key, dateRange, realm)
      .then(metrics => {
        const primaryMetrics = this.primaryMetricsFilter(metrics)
        this.primaryMetrics = Object.keys(primaryMetrics).length
          ? this.primaryMetricsFilter(metrics)
          : []
        this.componentDetails.subSections.forEach(section => {
          const subMetrics = this.subMetricsFilter(metrics, section.key)
          this.subMetrics[section.key] = [...subMetrics]
        })
        this.state.searching = false
        this.$timeout(() => {
          this.state.showMetrics = true
        }, 1)
      })
      .catch(() => {
        this.state.searching = false
        this.TS.show({
          text: 'Problem Fetching Metrics',
        })
      })
  }

  primaryMetricsFilter(metrics) {
    const isSub = (val, key) => !val.format.subSection
    const primaryObj = pickBy(isSub, metrics)
    this.allowedPrimaryKeys = Object.keys(primaryObj)
    return reduce(
      (acc, key) => {
        primaryObj[key].key = key
        acc.push(primaryObj[key])
        return acc
      },
      [],
      this.allowedPrimaryKeys,
    )
  }

  subMetricsFilter(metrics, subKey) {
    const regex = new RegExp(subKey)
    const isSub = (val, key) => regex.test(val.format.subSection)
    const subObj = pickBy(isSub, metrics)
    this.allowedSubKeys[subKey] = Object.keys(subObj)
    return reduce(
      (acc, key) => {
        subObj[key].key = key
        acc.push(subObj[key])
        return acc
      },
      [],
      this.allowedSubKeys[subKey],
    )
  }

  moveMetric(index, key) {
    if (key === 'primaryMetrics') {
      this[key].splice(index, 1)
    } else {
      this.subMetrics[key].splice(index, 1)
    }
    const settingsList = this.combineMetricLists(
      this.primaryMetrics,
      this.subMetrics,
    )
    this.CHS.saveSettings(
      this.company.slug,
      this.campaign.slug,
      this.componentDetails.key,
      settingsList,
    ).catch(() => {
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .textContent('Error saving metrics order')
          .position('top right')
          .theme('default')
          .hideDelay(5000),
      )
    })
  }

  combineMetricLists(primaryList, subMetricsObj) {
    let combined = [...primaryList]
    const subKeys = Object.keys(subMetricsObj)
    if (subKeys.length) {
      subKeys.forEach(key => {
        if (subMetricsObj[key].length) {
          combined = combined.concat(subMetricsObj[key])
        }
      })
    }
    return combined
  }

  openSettings(ev, componentKey) {
    const userMetrics = this.combineMetricLists(
      this.primaryMetrics,
      this.subMetrics,
    )
    const CSD = componentSettingsDialog
    CSD.targetEvent = ev
    CSD.locals = {
      title: this.title,
      company: this.state.company,
      campaign: this.state.campaign,
      userMetrics: [...userMetrics],
      defaultMetrics: [...this.state.componentDetails.defaultMetrics],
      componentKey: componentKey,
      serviceName: 'MetricsService',
    }
    this.$mdDialog.show(CSD).then(userSettings => {
      this.state.showMetrics = false
      this.healthMetrics = []
      this.CHS.components[userSettings.key].metrics = userSettings.value
      this.buildMetrics(userSettings.key, this.state.dateRange)
    })
  }

  toggleMetrics() {
    this.state.showMetrics = !this.state.showMetrics
  }
}
