import angular from 'angular'
import { react2angular } from 'react2angular'

// components
import { QAprospectSettings } from './component/QAprospectSettings.component.js'

const dependencies = [
    '$state',
    'IdentityService',
    'SideNavService',
    'ManageMenuService',
    'RoleStore',
]

export default angular
    .module('portalApp.manage.qaProspect', [])
    .component('qaProspectSettings', react2angular(QAprospectSettings, [], dependencies))
    .name
