import { reduce } from 'ramda'

function combineDays(range, day, aggregate) {
  if (!aggregate) {
    range.dividend.value += day.dividend.value
    range.dividend.name = day.dividend.name
    range.divisor.value += day.divisor.value
    range.divisor.name = day.divisor.name
  }
  range.y += day.y
  return range
}

function buildRangeMetric(acc, day) {
  return function(key) {
    if (!acc[key]) {
      acc[key] = {
        y: 0,
        format: day.metrics[key].format,
        aggregate: day.metrics[key].aggregate || false,
        isRate: day.metrics[key].isRate || false,
        days: [],
      }
    }
    if (!acc[key].aggregate && !acc[key].dividend && !acc[key].divisor) {
      acc[key].dividend = { name: '', value: 0 }
      acc[key].divisor = { name: '', value: 0 }
    }
    acc[key].days.push(day.metrics[key])
    acc[key] = combineDays(
      acc[key],
      day.metrics[key],
      day.metrics[key].aggregate,
    )
  }
}

function rangeCalculator(acc, day) {
  Object.keys(day.metrics).forEach(buildRangeMetric(acc, day))
  return acc
}

function calcMetricForRange(stats) {
  return reduce(rangeCalculator, {}, stats.days)
}

export { calcMetricForRange }
