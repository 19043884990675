// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import SvgIcon from '@material-ui/core/SvgIcon'

type Props = {
  classes: Object,
  children: React.ChildrenArray<React.Element<typeof SvgIcon> | string>,
  buttonColor: 'primary' | 'secondary' | 'default',
  disabled: boolean,
  innerRef: string,
  type: 'button' | 'submit' | 'reset',
  handleClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
}

const styles = {
  button: {
    minWidth: 110,
    height: 40,
    borderRadius: '2px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0 16px',
    boxShadow: 'none',
  },
}

function ActionButton(props: Props) {
  return (
    <Button
      color={props.buttonColor}
      variant="contained"
      size="small"
      type={props.type}
      disabled={props.disabled}
      className={props.classes.button}
      onClick={props.handleClick}
      disableRipple={true}
    >
      {props.children ? props.children : undefined}
    </Button>
  )
}

ActionButton.defaultProps = {
  type: 'button',
  disabled: false,
  buttonColor: 'secondary',
}

export default withStyles(styles)(ActionButton)
