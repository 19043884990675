import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// Manage Rapid Response View

const manageGlobalRapidResponse = 'manage.globalRapidResponse'
const pageTitleConfig = {
  title: 'Manage Rapid Response'
}

const manageGlobalRapidResponseConfig = {
  abstract: true,
  url: '/global-rapid-response',
  component: 'manageGlobalRapidResponse',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageGlobalRapidResponse, manageGlobalRapidResponseConfig }
