// @flow

import { createMuiTheme } from '@material-ui/core/styles'

const palette = {
  primary: {
    main: '#444851',
  },
  secondary: {
    main: '#b6d36b',
    dark: '#a6c556',
    contrastText: '#fff',
  },
  error: {
    main: '#ff504d',
    contrastText: '#444851',
  }
}

export const theme = createMuiTheme({ palette })
