import { findIndex, propEq } from 'ramda'
import {
  populatePitchObjects,
  pitchCollectionEqual,
} from '../../../../utils/pitchObjects'
import CPSD from '../../../../../dialogs/create-pitch-segments/createPitchSegments.js'
import DED from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class RapidResponseTestsSegmentsController {
  constructor(
    $state,
    $timeout,
    $q,
    $mdDialog,
    $localStorage,
    ManagementService,
    IdentityService,
    PitchService,
    ToastService,
    EditPhraseBookService,
    RoleStore,
  ) {
    'ngInject'

    this.MS = ManagementService
    this.$timeout = $timeout
    this.IS = IdentityService
    this.PS = PitchService
    this.EPBS = EditPhraseBookService
    this.service = null
    this.TS = ToastService
    this.$q = $q
    this.$localStorage = $localStorage
    this.$mdDialog = $mdDialog

    this.state = {
      activeSegments: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      globalSegments: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      companySegments: {
        initial: [],
        editable: [],
      },
      search: {
        activeSegments: '',
        companySegments: '',
      },
      endpoint: 'segments',
    }
    this.globalSegmentFilter = this.createSegmentFilter('globalSegments')
    this.activeSegmentFilter = this.createSegmentFilter('activeSegments')
    this.companySegmentFilter = this.createSegmentFilter('companySegments')
    this.pitchSegmentsEqual = pitchCollectionEqual
    this.$state = $state
  }

  $onChanges() {
    let services = this.PS.setupRapidResponse(this.compSlug)
    let service =
      this.compSlug === 'global' ? services.global : services.company
    this.rapidResponseTestService = service.one('tests').one(this.compUuid)

    this.companyService = service.one('segments')
    this.globalService = this.PS
      .setupRapidResponse('global')
      .global.one('segments')
    // wait for parent component to provide services and initial active segments
    this.getSegments(this.companyService, this.globalService, [])
  }

  getSegments(companyService, globalService, pitchSegments) {
    this.$q
      .all({
        company: companyService.get(),
        global: globalService.get(),
      })
      .then(({ company, global }) => {
        return {
          company: company.plain(),
          global: global.plain(),
        }
      })
      .then(populatePitchObjects(pitchSegments, 'Segment'))
      .then(this.setActiveSegments.bind(this))
      .finally(this.isEditingDialog())
  }

  setAllSegments({
    activeSegments = [],
    companySegments = [],
    globalSegments = [],
  }) {
    Object.assign(this.state, {
      activeSegments: {
        initial: activeSegments,
        editable: [...activeSegments],
      },
      companySegments: {
        initial: companySegments,
        editable: [...companySegments],
      },
      globalSegments: {
        initial: globalSegments,
        editable: [...globalSegments],
      },
    })
  }

  setActiveSegments({
    activeSegments = [],
    companySegments = [],
    globalSegments = [],
  }) {
    if (this.rapidResponseTest.segments.length) {
      this.rapidResponseTest.segments.forEach(segment => {
        if (this.compSlug !== 'global') {
          companySegments.forEach(companySegment => {
            if (segment === companySegment.uuid) {
              activeSegments.push(companySegment)
              this.removeSegment(
                companySegments.indexOf(companySegment),
                companySegments,
              )
            }
          })
        }
        globalSegments.forEach(globalSegment => {
          if (segment === globalSegment.uuid) {
            activeSegments.push(globalSegment)
            this.removeSegment(
              globalSegments.indexOf(globalSegment),
              globalSegments,
            )
          }
        })
      })
    }
    this.setAllSegments({ activeSegments, companySegments, globalSegments })
  }

  openSegmentMenu($mdMenu, ev) {
    $mdMenu.open(ev)
  }

  isEditingDialog() {
    if (this.$localStorage.editingDialog) {
      const segment = JSON.parse(this.$localStorage.editingDialog)
      this.openSegmentDialog(this.companyService, segment)
      delete this.$localStorage.editingDialog
    }
  }

  openSegmentDialog(service, segment) {
    const company = this.campaign
    const endpoint = this.state.endpoint
    const locals = {
      isCreate: !segment,
      isRapidResponse: true,
      service: service,
      segment,
      company,
      endpoint,
    }
    CPSD.locals = locals
    this.$mdDialog.show(CPSD).then(({ segment, wasCreate }) => {
      if (wasCreate) {
        this.state.companySegments.editable.push(segment)
        this.state.companySegments.initial.push(segment)
      } else {
        this.updateEditedSegment(segment)
      }
    })
  }

  updateEditedSegment(segment) {
    const activeSegmentIndex = findIndex(
      propEq('uuid', segment.uuid),
      this.state.activeSegments.editable,
    )
    const companySegmentIndex = findIndex(
      propEq('uuid', segment.uuid),
      this.state.companySegments.editable,
    )
    if (activeSegmentIndex > -1) {
      this.state.activeSegments.editable.splice(activeSegmentIndex, 1, segment)
      this.state.activeSegments.initial.splice(activeSegmentIndex, 1, segment)
    } else if (companySegmentIndex > -1) {
      this.state.companySegments.editable.splice(
        companySegmentIndex,
        1,
        segment,
      )
      this.state.companySegments.initial.splice(companySegmentIndex, 1, segment)
    }
  }

  editVariables(segment) {
    const params = {
      compSlug: this.compSlug,
      uuid: segment.uuid,
      endpointRoute: this.state.endpoint,
    }
    this.$state.go('manage.editSegmentVariables', params)
  }

  openDeleteDialog(service, segment) {
    DED.locals = {
      entityName: segment.name,
      identityEndpoint: service.one(segment.uuid),
      routeWhenSuccessful: 'manage.editCampaign.pitch',
    }
    this.$mdDialog.show(DED)
  }

  createSegmentFilter(key) {
    return ({ searchText }) => {
      this.state.search[key] = searchText
    }
  }

  saveActiveSegments(activeSegments) {
    this.updateSegments({ segments: activeSegments })
  }

  updateSegments(segments) {
    let segmentUuids = segments.segments.map(segment => {
      return segment.uuid
    })
    this.rapidResponseTestService
      .patch({ segments: segmentUuids })
      .then(updatedRapidResponseTest => {
        this.rapidResponseTest = updatedRapidResponseTest.plain()
        this.state.activeSegments.initial = [
          ...this.state.activeSegments.editable,
        ]
        this.TS.show({
          text: 'Rapid Response Test has been successfully updated',
        })
      })
      .catch(err => {
        this.TS.show({
          text: 'Error updating rapid response segments',
        })
        console.error(err)
      })
  }

  removeSegment(index, arr) {
    const segment = arr.splice(index, 1)[0]
    if (segment.company) {
      this.state.companySegments.editable.push(segment)
    } else {
      this.state.globalSegments.editable.push(segment)
    }
  }

  addSegment(index, arr) {
    const segment = arr.splice(index, 1)[0]
    this.state.activeSegments.editable.push(segment)
  }

  cancelMove() {
    this.resetSegmentsList('activeSegments')
    this.resetSegmentsList('companySegments')
    this.resetSegmentsList('globalSegments')
  }

  resetSegmentsList(listKey = '') {
    this.state[listKey].editable = [...this.state[listKey].initial]
  }

  toggleSearch(key) {
    this.state[key].showSearch = !this.state[key].showSearch
    this.state.search[key] = ''
  }
}
