// Dashboard
import {
  dashboardOverview,
  dashboardOverviewConfig,
} from './dashboard.overview.js'

export default function dashboardOverviewRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardOverview, dashboardOverviewConfig)
}
