// Campaign Qa prospect settings view

const manageEditCampaignQAprospect = 'manage.qaProspect'

const manageEditCampaignQAprospectConfig = {
    url: '/campaign/qa-prospect-settings/{campaignId}',
    params: {
        campaignId: null,
    },
    component: 'qaProspectSettings',
}

export { manageEditCampaignQAprospect, manageEditCampaignQAprospectConfig }
