import './teamInfoForm.component.scss'
import TeamInfoFormController from './controller/TeamInfoFormController.js'
const template = require('./teamInfoForm.component.tpl.pug')

const teamInfoForm = {
  template: template(),
  controller: TeamInfoFormController,
  bindings: {
    user: '<',
    teams: '<',
    lastState: '<',
  }
}

export default teamInfoForm
