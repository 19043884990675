// css
import './component/goals.scss'

// services
import GoalsService from './services/GoalsService.js'

// components
import goals from './component/goals.js'
import metricGoal from './sub-components/metric-goal/metricGoal.js'

export default angular
  .module('portalApp.dashboard.goals', [])
  .service('GoalsService', GoalsService)
  .component('goals', goals)
  .component('metricGoal', metricGoal)
  .name
