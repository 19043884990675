import './rightDrawer.component.scss'
import RightDrawerController from './controller/RightDrawerController.js'
const template = require('./rightDrawer.component.tpl.pug')

const rightDrawer = {
  controller: RightDrawerController,
  template: template()
}

export default rightDrawer
