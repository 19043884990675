// create users personal info view

const manageUsersCreatePersonalInfo = 'manage.users.create.personalInfo'

const manageUsersCreatePersonalInfoConfig = {
  params: {
    user: null
  },
  component: 'personalInfoForm',
  resolve: {
    user: function ($transition$) {
      'ngInject'
      return $transition$.params().user || {}
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreatePersonalInfo, manageUsersCreatePersonalInfoConfig }
