import RepTrendController from './controller/RepTrendController.js'
const template = require('./repTrend.tpl.pug')

const repTrend = {
  template: template(),
  controller: RepTrendController,
  bindings: {
    company: '<',
    campaign: '<',
    offsetMinutes: '<',
    realm: '<',
  }
}

export default repTrend
