import PitchPanelsController from './controller/PitchPanelsController.js'
const template = require('./pitchPanels.component.tpl.pug')

const pitchPanels = {
  template: template(),
  controller: PitchPanelsController,
  bindings: {
    guiService: '<',
    pitch: '<',
  }
}

export default pitchPanels

