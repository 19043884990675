// Edit global options group view

const manageEditGlobalOptionGroups = 'manage.editGlobalOptionGroups'

const manageEditGlobalOptionGroupsConfig = {
  url: '/global-option-group/edit/{uuid}',
  component: 'editGlobalOptionGroups'
}

export { manageEditGlobalOptionGroups, manageEditGlobalOptionGroupsConfig }
