import CampaignDetailsController from './controller/CampaignDetailsController.js'
const template = require('./campaignDetails.tpl.pug')

const campaignDetails = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CampaignDetailsController,
}

export default campaignDetails
