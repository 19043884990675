// @flow

import Store from '../../classes/Store.js'

export default class PermissionsStore extends Store {
  permissions: 'permissions'

  constructor () {
    super()
    this.permissions = 'permissions'
    super.createStoreSpace(this.permissions)
  }

  definePermission (name: string, validator: () => boolean) {
    const contents = { name, validator }
    super.setInStore(this.permissions, name, contents)
  }

  defineMultiplePermissions (permissions: string[], validator: () => boolean) {
    permissions.forEach((perm) => {
      const contents = { perm, validator }
      super.setInStore(this.permissions, perm, contents)
    })
  }

  getPermissionDefinition (name: string) {
    return super.getFromStore(this.permissions, name)
  }

  getAllPermissions () {
    return super.getStore(this.permissions)
  }

  removePermission (name: string) {
    super.removeFromStore(this.permissions, name)
  }

  clearPermissions () {
    super.clearStore(this.permissions)
  }
}
