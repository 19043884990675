import './createPitchVariables.scss'
import CreatePitchVariablesController from './controller/createPitchVariables.controller'
const template = require('./createPitchVariables.tpl.pug')

const createPitchVariables = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreatePitchVariablesController
}

export default createPitchVariables
