import { addIndex, clone, reduce } from 'ramda'
import { getRealmSlug } from 'util/realm.js'
import componentSettingsDialog from '../../../../dialogs/component-settings/componentSettingsDialog.js'

export default class ChartContainerController {
  constructor($mdToast, $mdDialog, RepRankingsService, MetricsService) {
    'ngInject'
    this.$mdToast = $mdToast
    this.$mdDialog = $mdDialog
    this.MS = MetricsService
    this.RRS = RepRankingsService
    this.state = {
      searching: false,
      realm: getRealmSlug(this.realm),
    }
  }

  $onInit() {
    this.componentDetails = Object.assign(this.componentConfig, {
      company: this.company.slug,
      campaign: this.campaign.slug,
    })
    this.RRS.getSettings(
      this.company.slug,
      this.campaign.slug,
      this.componentDetails.key,
    )
      .then(data => {
        this.componentDetails.metrics = data.value
        this.buildCharts(
          this.componentDetails,
          this.dateRange,
          this.state.realm,
        )
      })
      .catch(err => {
        if (err.status === 404) {
          this.componentDetails.metrics = this.componentDetails.defaultMetrics
          this.buildCharts(
            this.componentDetails,
            this.dateRange,
            this.state.realm,
          )
          return
        }
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent('Problem fetching metrics')
            .position('top right')
            .theme('default')
            .hideDelay(5000),
        )
      })
  }

  $onChanges(bindings) {
    if (
      bindings.dateRange &&
      bindings.componentDetails &&
      !bindings.componentDetails.isFirstChange()
    ) {
      this.state.dateRange = clone(this.dateRange)
      this.buildCharts(
        this.componentDetails.key,
        this.state.dateRange,
        this.state.realm,
      )
    }

    if (bindings.dateRange && !bindings.dateRange.isFirstChange()) {
      this.state.dateRange = clone(this.dateRange)
      this.buildCharts(this.componentDetails, this.dateRange, this.state.realm)
    }
  }

  buildCharts(component, dateRange, realm) {
    this.state.searching = true
    this.RRS.buildCharts(component, dateRange, realm)
      .then(charts => {
        const keysArr = Object.keys(charts)
        this.metricsLeftColumn = this.makeLeftColumn(charts, keysArr)
        this.metricsRightColumn = this.makeRightColumn(charts, keysArr)
        this.state.searching = false
      })
      .catch(() => {
        this.state.searching = false
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent('Problem fetching metrics')
            .position('top right')
            .theme('default')
            .hideDelay(5000),
        )
      })
  }

  makeLeftColumn(metricsObj, keys) {
    const indexedRed = addIndex(reduce)
    const getOddsIndex = (acc, key, index) => {
      if (index % 2 === 0) {
        metricsObj[key].key = key
        acc.push(metricsObj[key])
      }
      return acc
    }
    return indexedRed(getOddsIndex, [], keys)
  }

  makeRightColumn(metricsObj, keys) {
    const indexedRed = addIndex(reduce)
    const getEvensIndex = (acc, key, index) => {
      if (index % 2 !== 0) {
        metricsObj[key].key = key
        acc.push(metricsObj[key])
      }
      return acc
    }
    return indexedRed(getEvensIndex, [], keys)
  }

  moveChart(index, arrayKey) {
    this[arrayKey].splice(index, 1)
    const metricKeys = this.combineEveryOther(
      this.metricsLeftColumn,
      this.metricsRightColumn,
    )
    this.RRS.saveSettings(
      this.company.slug,
      this.campaign.slug,
      'rep_stack_metrics',
      metricKeys,
    ).catch(() => {
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .textContent('Error saving metrics order')
          .position('top right')
          .theme('default')
          .hideDelay(5000),
      )
    })
  }

  combineEveryOther(arr1, arr2) {
    const totalLength = arr1.length + arr2.length
    const combined = new Array(totalLength)
    let indexer1 = 0
    let indexer2 = 1
    arr1.forEach((metric, index) => {
      combined.splice(indexer1, 1, metric)
      indexer1 += 2
    })
    arr2.forEach((metric, index) => {
      combined.splice(indexer2, 1, metric)
      indexer2 += 2
    })
    return combined
      .filter(metric => {
        if (metric.key) return true
        return false
      })
      .map(metric => metric.key)
  }

  openSettings(ev, component) {
    const combinedMets = this.combineEveryOther(
      this.metricsLeftColumn,
      this.metricsRightColumn,
    )
    const userMetrics = this.MS.getUserMetrics(combinedMets) || []
    const CSD = componentSettingsDialog
    CSD.targetEvent = ev
    CSD.locals = {
      title: 'Rep Stack Rankings',
      company: this.company,
      campaign: this.campaign,
      userMetrics: userMetrics,
      defaultMetrics: this.componentDetails.defaultMetrics,
      componentKey: component.key,
      serviceName: 'MetricsService',
    }
    this.$mdDialog
      .show(CSD)
      .then(userSettings => {
        this.componentDetails.metrics = userSettings.value
        this.buildCharts(this.componentDetails, this.dateRange)
      })
      .catch(console.error)
  }
}
