import './createProspectVoices.scss'
import CreateProspectVoicesController from './controller/CreateProspectVoicesController'
const template = require('./createProspectVoices.tpl.pug')

const createProspectVoices = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreateProspectVoicesController,
}

export default createProspectVoices
