import DidTableController from './controller/DidTableController.js'
import template from './didTable.component.tpl.pug'
import './didTable.component.scss'

const didTable = {
  template: template(),
  controller: DidTableController,
  bindings: {
    pool: '<',
    getCompany: '&',
    getCampaign: '&',
  },
}

export default didTable
