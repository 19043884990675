import './rotateDidConfirmation.scss'
import RotateDidConfirmationController from './controller/RotateDidConfirmationController'
const template = require('./rotateDidConfirmation.tpl.pug')

const rotateDidConfirmation = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: RotateDidConfirmationController,
}

export default rotateDidConfirmation
