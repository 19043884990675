import EditRouteInfoController from './controller/EditRouteInfoController.js'
const template = require('./editRouteInfoForm.component.tpl.pug')

const editRouteInfo = {
  template: template(),
  controller: EditRouteInfoController,
  bindings: {
    route: '<',
    router: '<',
    campaign: '<',
  }
}

export default editRouteInfo
