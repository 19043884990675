// Manage Edit Companies
import {
  manageEditCompany,
  manageEditCompanyConfig,
} from './manage.editCompany.js'
import {
  manageEditCompanyAudioResources,
  manageEditCompanyAudioResourcesConfig,
} from './child-states/edit-company-audio-resources/manage.editCompany.audio-resources.js'
import {
  manageEditCompanyPhraseBooks,
  manageEditCompanyPhraseBooksConfig,
} from './child-states/edit-company-phrase-books/manage.editCompany.phrase-books.js'
import {
  manageEditCompanyRapidResponseSegments,
  manageEditCompanyRapidResponseSegmentsConfig,
} from './child-states/edit-company-rapid-response-segments/manage.editCompany.rapid-response-segments'
import {
  manageEditCompanyRapidResponseTests,
  manageEditCompanyRapidResponseTestsConfig,
} from './child-states/edit-company-rapid-response-tests/manage.editCompany.rapid-response-tests'
import {
  manageEditCompanyRapidResponseVariables,
  manageEditCompanyRapidResponseVariablesConfig,
} from './child-states/edit-company-rapid-response-variables/manage.editCompany.rapid-response-variables'
import {
  manageEditCompanySettings,
  manageEditCompanySettingsConfig,
} from './child-states/edit-company-settings/manage.editCompany.settings.js'

export default function manageEditCompaniesRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditCompany, manageEditCompanyConfig)
    .state(
      manageEditCompanyAudioResources,
      manageEditCompanyAudioResourcesConfig,
    )
    .state(manageEditCompanyPhraseBooks, manageEditCompanyPhraseBooksConfig)
    .state(
      manageEditCompanyRapidResponseSegments,
      manageEditCompanyRapidResponseSegmentsConfig,
    )
    .state(
      manageEditCompanyRapidResponseTests,
      manageEditCompanyRapidResponseTestsConfig,
    )
    .state(
      manageEditCompanyRapidResponseVariables,
      manageEditCompanyRapidResponseVariablesConfig,
    )
    .state(manageEditCompanySettings, manageEditCompanySettingsConfig)
}
