import EditAccessListController from './controller/EditAccessListController.js'
const template = require('./editAccessListNode.component.tpl.pug')

const editAccessListNode = {
  template: template(),
  controller: EditAccessListController,
  bindings: {
    accessList: '<',
    node: '<'
  }
}

export default editAccessListNode
