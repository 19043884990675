import { REP_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'stationsLive',
  type: 'repsLive',
}

const formatting = {
  format: {
    title: 'Stations',
  },
}

const keys = {
  componentKeys: [REP_METRICS],
  healthKey: 'reps_live',
}
const stationsLive = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { stationsLive }
