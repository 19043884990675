// @flow

import type { GuiOptionGroup } from 'types/entities.js'

export default class OptionsGroupDialogController {
  $mdDialog: Object
  service: Object
  state: {
    submitting: boolean,
    error: boolean,
  }

  constructor($mdDialog: Object) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.state = {
      submitting: false,
      error: false,
    }
  }

  createOptionsGroup(group: { name: string }) {
    this.state.submitting = true
    this.service
      .customPOST(group)
      .then(createdGroup => createdGroup.plain())
      .then(this.close.bind(this))
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  close(createdGroup: GuiOptionGroup) {
    const closer = createdGroup ? this.$mdDialog.hide : this.$mdDialog.cancel
    closer(createdGroup)
  }
}
