import { contains, filter, pipe, props, reduce, values } from 'ramda'

import { activeDialableProspects } from 'util/metrics/metric-configs/activeDialableProspects.js'
import { activeProspects } from 'util/metrics/metric-configs/activeProspects.js'
import { answerSeizureRatio } from 'util/metrics/metric-configs/answerSeizureRatio'
import { answered } from 'util/metrics/metric-configs/answered.js'
import { averageTalkTime } from 'util/metrics/metric-configs/averageTalkTime.js'
import { averageTalkTimePerTransfer } from 'util/metrics/metric-configs/averageTalkTimePerTransfer.js'
import { badNumbers } from 'util/metrics/metric-configs/badNumbers.js'
import { billableHours } from 'util/metrics/metric-configs/billableHours.js'
import { callbackCalls } from 'util/metrics/metric-configs/callbackCalls.js'
import { calls } from 'util/metrics/metric-configs/calls.js'
import { callsPerBillableHour } from 'util/metrics/metric-configs/callsPerBillableHour.js'
import { callsPerMachineHour } from 'util/metrics/metric-configs/callsPerMachineHour.js'
import { carrierFailure } from 'util/metrics/metric-configs/carrierFailure.js'
import { completedTransfers } from 'util/metrics/metric-configs/completedTransfers.js'
import { contactRate } from 'util/metrics/metric-configs/contactRate.js'
import { contacted } from 'util/metrics/metric-configs/contacted.js'
import { contactedConversionRate } from 'util/metrics/metric-configs/contactedConversionRate.js'
import { conversionRate } from 'util/metrics/metric-configs/conversionRate.js'
import { dialingSpeed } from 'util/metrics/metric-configs/dialingSpeed.js'
import { doNotCallRate } from 'util/metrics/metric-configs/doNotCallRate.js'
import { dynamicDNCUploads } from 'util/metrics/metric-configs/dynamicDNCUploads.js'
import { dynamicDupeUploads } from 'util/metrics/metric-configs/dynamicDupeUploads.js'
import { dynamicInvalidUploads } from 'util/metrics/metric-configs/dynamicInvalidUploads.js'
import { dynamicValidUploads } from 'util/metrics/metric-configs/dynamicValidUploads.js'
import { hopperSize } from 'util/metrics/metric-configs/hopperSize.js'
import { hopperSpeed } from 'util/metrics/metric-configs/hopperSpeed.js'
import { inboundCalls } from 'util/metrics/metric-configs/inboundCalls.js'
import { interruptRate } from 'util/metrics/metric-configs/interruptRate.js'
import { interruptsPerCall } from 'util/metrics/metric-configs/interruptsPerCall.js'
import { invalidCallRate } from 'util/metrics/metric-configs/invalidCallRate.js'
import { invalidCalls } from 'util/metrics/metric-configs/invalidCalls.js'
import { keypressLag } from 'util/metrics/metric-configs/keypressLag.js'
import { machineHours } from 'util/metrics/metric-configs/machineHours.js'
import { metricFactory } from 'util/metrics/metricFactory.js'
import { outboundAbandoned } from 'util/metrics/metric-configs/outboundAbandoned.js'
import { outboundAbandonmentRate } from 'util/metrics/metric-configs/outboundAbandonmentRate.js'
import { outboundCalls } from 'util/metrics/metric-configs/outboundCalls.js'
import { payableHours } from 'util/metrics/metric-configs/payableHours.js'
import { repWaitTime } from 'util/metrics/metric-configs/repWaitTime.js'
import { repsLive } from 'util/metrics/metric-configs/repsLive.js'
import { resolvedProspects } from 'util/metrics/metric-configs/resolvedProspects.js'
import { revenue } from 'util/metrics/metric-configs/revenue.js'
import { revenuePerBillableHour } from 'util/metrics/metric-configs/revenuePerBillableHour.js'
import { revenuePerCall } from 'util/metrics/metric-configs/revenuePerCall.js'
import { revenuePerMachineHour } from 'util/metrics/metric-configs/revenuePerMachineHour.js'
import { sales } from 'util/metrics/metric-configs/sales.js'
import { salesPerBillableHour } from 'util/metrics/metric-configs/salesPerBillableHour.js'
import { salesPerCall } from 'util/metrics/metric-configs/salesPerCall.js'
import { salesPerMachineHour } from 'util/metrics/metric-configs/salesPerMachineHour.js'
import { scheduledProspects } from 'util/metrics/metric-configs/scheduledProspects.js'
import { schedulerSpeed } from 'util/metrics/metric-configs/schedulerSpeed.js'
import { serverLoad } from 'util/metrics/metric-configs/serverLoad.js'
import { silencePerKeypress } from 'util/metrics/metric-configs/silencePerKeypress.js'
import { speedToLead } from 'util/metrics/metric-configs/speedToLead.js'
import { stationsLive } from 'util/metrics/metric-configs/stationsLive.js'
import { talkTime } from 'util/metrics/metric-configs/talkTime.js'
import { transferMinutes } from 'util/metrics/metric-configs/transferMinutes.js'
import { transfersPerBillableHour } from 'util/metrics/metric-configs/transfersPerBillableHour.js'
import { transfersPerMachineHour } from 'util/metrics/metric-configs/transfersPerMachineHour.js'
import { uploadedDncProspects } from 'util/metrics/metric-configs/uploadedDncProspects.js'
import { uploadedDuplicateProspects } from 'util/metrics/metric-configs/uploadedDuplicateProspects.js'
import { uploadedInvalidProspects } from 'util/metrics/metric-configs/uploadedInvalidProspects.js'
import { uploadedValidProspects } from 'util/metrics/metric-configs/uploadedValidProspects.js'
import { uploadsProcessed } from 'util/metrics/metric-configs/uploadsProcessed.js'
import { utilizationRate } from 'util/metrics/metric-configs/utilizationRate.js'
import { voicemailRate } from 'util/metrics/metric-configs/voicemailRate.js'

export default class MetricService {
  constructor() {
    'ngInject'

    this.metrics = [
      activeDialableProspects,
      activeProspects,
      answerSeizureRatio,
      answered,
      averageTalkTime,
      averageTalkTimePerTransfer,
      badNumbers,
      billableHours,
      callbackCalls,
      calls,
      callsPerBillableHour,
      callsPerMachineHour,
      carrierFailure,
      completedTransfers,
      contactRate,
      contacted,
      contactedConversionRate,
      conversionRate,
      dialingSpeed,
      doNotCallRate,
      dynamicDNCUploads,
      dynamicDupeUploads,
      dynamicInvalidUploads,
      dynamicValidUploads,
      hopperSize,
      hopperSpeed,
      inboundCalls,
      interruptRate,
      interruptsPerCall,
      invalidCallRate,
      invalidCalls,
      keypressLag,
      machineHours,
      outboundAbandoned,
      outboundAbandonmentRate,
      outboundCalls,
      payableHours,
      repWaitTime,
      repsLive,
      resolvedProspects,
      revenue,
      revenuePerBillableHour,
      revenuePerCall,
      revenuePerMachineHour,
      sales,
      salesPerBillableHour,
      salesPerCall,
      salesPerMachineHour,
      scheduledProspects,
      schedulerSpeed,
      serverLoad,
      silencePerKeypress,
      speedToLead,
      stationsLive,
      talkTime,
      transferMinutes,
      transfersPerBillableHour,
      transfersPerMachineHour,
      uploadedDncProspects,
      uploadedDuplicateProspects,
      uploadedInvalidProspects,
      uploadedValidProspects,
      uploadsProcessed,
      utilizationRate,
      voicemailRate,
    ].reduce((acc, metric) => {
      acc[metric.metaData.key] = metricFactory(
        metric.metaData,
        metric.calculationConfig,
      )
      return acc
    }, {})
  }

  getMetrics(metrics, context) {
    // context is used during metric calculation
    const metricTypesObj = {
      call: [],
      dataDog: [],
      repsLive: [],
      prospect: [],
      metricKeysArr: metrics,
    }
    const metricStatsReducer = (acc, key) => {
      const metric = this.metrics[key]
      acc[metric.type].push(metric)
      return acc
    }
    return reduce(metricStatsReducer, metricTypesObj, metrics)
  }

  pullStats(metrics) {
    const statsPuller = (acc, metric) => {
      metric.statsRequired.forEach(stat => {
        acc.push(stat)
      })
      return acc
    }
    const stats = reduce(statsPuller, [], metrics)
    return new Set(stats)
  }

  getUserMetrics(metricKeys) {
    return props(metricKeys, this.metrics)
  }

  getComponentMetrics(componentKey) {
    const hasKey = contains(componentKey)
    const filterMetrics = value => hasKey(value.componentKeys)
    const filterComponentMetrics = pipe(filter(filterMetrics), values)
    return filterComponentMetrics(this.metrics)
  }

  validateMetricKeys(allowedKeys, keys) {
    return filter(key => {
      return contains(key, allowedKeys)
    }, keys)
  }
}
