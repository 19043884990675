import { reduce } from 'ramda'
import { combineRepObjects } from './repMerger.js'

function mergeOrCreateRepRange(metric, repMetrics, format) {
  if (!metric) {
    return { data: repMetrics, format: format }
  } else {
    metric.data = combineRepObjects(metric.data, repMetrics)
    return metric
  }
}

function getValueIfDialed(dialed, yVal) {
  if (!dialed) return 0
  return yVal / dialed
}

function repReducer(reps, date) {
  return function(acc, repKey) {
    let clonedRep = Object.assign({}, reps[repKey], { x: date * 1000 })
    clonedRep.y = getValueIfDialed(reps[repKey].dialed, reps[repKey].y)
    clonedRep.days = [Object.assign({}, clonedRep)]
    acc[repKey] = clonedRep
    return acc
  }
}

function calculateRepDay(reps, date) {
  return reduce(repReducer(reps, date), {}, Object.keys(reps))
}

function calculateRange(acc, metrics, day) {
  return function(metricKey) {
    const reps = day.metrics[metricKey]
    const repMetricsForDay = calculateRepDay(reps, day.date)
    acc[metricKey] = mergeOrCreateRepRange(
      acc[metricKey],
      repMetricsForDay,
      metrics[metricKey].format,
    )
  }
}

function calculateRangeMetrics(metrics) {
  return function rangeReducer(acc, day) {
    Object.keys(day.metrics).forEach(calculateRange(acc, metrics, day)) // mutating acc here
    return acc
  }
}

function calcMetricsByRange(data) {
  return reduce(calculateRangeMetrics(data.metrics), {}, data.days)
}

export { calcMetricsByRange }
