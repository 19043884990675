// create campaigns view

const manageCampaignsCreate = 'manage.campaigns.create'

const manageCampaignsCreateConfig = {
  abstract: true,
  component: 'rightDrawer'
}

export { manageCampaignsCreate, manageCampaignsCreateConfig }
