// campaign create info view

const manageCampaignsCreateCampaignInfo = 'manage.campaigns.create.campaignInfo'

const manageCampaignsCreateCampaignInfoConfig = {
  url: '/create',
  component: 'createCampaignInfo'
}

export { manageCampaignsCreateCampaignInfo, manageCampaignsCreateCampaignInfoConfig }
