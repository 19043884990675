import '../../segments/component/rapidResponseTestsSegments.scss'
import RapidResponseTestsSegmentsController from './controller/RapidResponseTestsSegments.controller'
const template = require('./rapidResponseTestsSegments.component.tpl.pug')

const editRapidResponseTestsSegments = {
  template: template(),
  controller: RapidResponseTestsSegmentsController,
  bindings: {
    compSlug: '<',
    compUuid: '<',
    rapidResponseTest: '<',
    openSections: '<',
  },
}

export default editRapidResponseTestsSegments
