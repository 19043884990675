import { find, propEq } from 'ramda'

export default class CampaignInfoFormController {
  constructor($state, RoleStore, $element) {
    'ngInject'

    this.$element = $element
    this.$state = $state
    this.RS = RoleStore
    this.checkSelected = (camp, userCamps) => {
      if (Array.isArray(userCamps)) {
        return find(propEq('uuid', camp.uuid))(userCamps)
      }
      return false
    }
  }

  $onInit() {
    this.creatorRoles = Object.keys(this.RS.getAllRoles())
    
    this.$element.find('input').on('keydown', function(ev) {
      ev.stopPropagation()
    })
  }

  backTo() {
    this.goingBack = true
    this.$state.go(
      'manage.users.create.companyInfo',
      { user: this.user },
      { location: false },
    )
  }

  goTo() {
    this.$state.go(
      'manage.users.create.teamInfo',
      { user: this.user },
      { location: false },
    )
  }
}
