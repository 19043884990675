import React, { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: '#444851',
    }
  }
}))

const AppSnackbar = props => {
  const classes = useStyles()
  
  return (
    <Snackbar
      className={classes.root}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal,
      }}
      key={`${props.vertical},${props.horizontal}`}
      open={props.open}
      onClose={props.close}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      variant="success"
      message={
        <span id="client-snackbar" className={classes.message}>
          {props.text}
        </span>
      }
    />
  )
}

export default AppSnackbar
