import { MANAGE_GROUP } from 'permissions.js'
// manage campaigns view

const manageCampaigns = 'manage.campaigns'

const manageCampaignsConfig = {
  url: '/campaigns',
  component: 'manageCampaigns',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  },
  resolve: {
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Manage Campaigns'
      $window.document.title = pageTitle
      return pageTitle
    }
  }
}

export { manageCampaigns, manageCampaignsConfig }
