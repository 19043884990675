export default class AudioResourceController {
  constructor ($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.state = {
      savingAudioResource: false,
      addAudioFile: false,
      error: false,
    }
    this.audioResourceFile = {
      file: {},
      no_modifications: false,
      convert: true,
      fade_in: true,
      fade_out: true,
      file_ext: null
    }
  }

  uploadFile (audioResourceFile) {
    this.state.savingAudioResource = true
    let service = this.service.one(`${this.audioResource.uuid}/upload`)
    let formData = new FormData()
    audioResourceFile.file_ext = audioResourceFile.file.name.split('.').pop()
    formData.append('file', audioResourceFile.file)
    formData.append('no_modifications', audioResourceFile.no_modifications)
    formData.append('convert', audioResourceFile.convert)
    formData.append('fade_in', audioResourceFile.fade_in)
    formData.append('fade_out', audioResourceFile.fade_out)
    formData.append('file_ext', audioResourceFile.file_ext)

    service.withHttpConfig({transformRequest: angular.identity})
      .customPOST(formData, '', {file_ext: this.audioResourceFile.file_ext}, { 'Content-Type': undefined })
      .then(fileUploaded => fileUploaded.plain())
      .then(response => {
        this.audioResource.file = response
        this.close(this.audioResource)
      }).catch(err => {
      this.state.error = true
      console.error(err)
    }).finally(data => {
      this.state.savingAudioResource = false
    })
  }

  resetError () {
    this.state.error = false
  }

  close (audioResource) {
    audioResource && audioResource.uuid
      ? this.$mdDialog.hide(audioResource)
      : this.$mdDialog.cancel()
  }
}
