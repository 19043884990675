// @flow

import type { RestangularPromise } from 'restangular'
// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService, {
  IdentityLocation,
  IdentityTeam,
} from '../../../global/index/api-services/IdentityService.js'
import type { Team } from 'types/entities.js'
import type { PermissionGroup } from 'permissions.js'
import type { FilterConfig } from '../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../common-components/management-search/component/managementSearch.component.js'

import { LOCATION_LEADER_GROUP } from 'permissions.js'

export default class ManageLocationsService {
  Location: IdentityLocation
  Team: IdentityTeam
  defaultEditRoles: PermissionGroup

  constructor(IdentityService: IdentityService) {
    'ngInject'
    this.Location = IdentityService.Location
    this.Team = IdentityService.Team
    this.defaultEditRoles = LOCATION_LEADER_GROUP
  }

  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 5,
      helperText: 'Try searching for a location name, or uuid',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Sort By',
        field: 'order_by',
        defaultValue: '-datetime_modified',
        options: [
          { title: 'Recently Edited', value: '-datetime_modified' },
          { title: 'Name A-Z', value: 'name' },
          { title: 'Name Z-A', value: '-name' },
        ],
      },
      {
        label: 'Active Status',
        field: 'active',
        defaultValue: null,
        options: [
          { title: 'All', value: null },
          { title: 'Active', value: true },
          { title: 'Inactive', value: false },
        ],
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Name', accessor: 'name', type: 'text' },
        { title: 'Slug', accessor: 'slug', type: 'text' },
        { title: 'Active', accessor: 'active', type: 'active' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editOrView',
        },
      ],
      optionalColumns: [{ title: 'UUID', accessor: 'uuid', type: 'text' }],
    }
  }

  getLocationTeams(locationId: string): RestangularPromise<Team[]> {
    return this.Team.list
      .get({ location: locationId })
      .then(teams => teams.plain())
  }
}
