import './updatePassword.component.scss'
import UpdatePasswordController from './controller/UpdatePasswordController.js'
const template = require('./updatePassword.component.tpl.pug')

const updatePassword = {
  template: template(),
  controller: UpdatePasswordController,
  bindings: {
    userId: '<'
  }
}

export default updatePassword
