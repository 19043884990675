// create locations view

const createLocation = 'manage.locations.newLocation.create'

const createLocationConfig = {
  url: '/create',
  component: 'createLocation'
}

export { createLocation, createLocationConfig }
