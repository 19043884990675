import { DIALER_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'hopperSize',
  type: 'dataDog',
}

const formatting = {
  format: {
    title: 'Hopper Size',
  },
}

const keys = {
  componentKeys: [DIALER_METRICS],
  healthKey: 'performance_health',
}

const hopperSize = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { hopperSize }
