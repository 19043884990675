// create campaign router view

const manageEditCompanyPhraseBookPhraseEdit =
  'manage.editCompanyPhraseBook.phrase.edit'

const manageEditCompanyPhraseBookPhraseEditConfig = {
  params: {
    service: null,
    phrase: null,
  },
  component: 'editPhraseForm',
  resolve: {
    service: function($transition$) {
      'ngInject'
      return $transition$.params().service
    },
    phrase: function($transition$) {
      'ngInject'
      return $transition$.params().phrase
    },
  },
}

export {
  manageEditCompanyPhraseBookPhraseEdit,
  manageEditCompanyPhraseBookPhraseEditConfig,
}
