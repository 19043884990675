import AudioResourceFileController from './controller/AudioResourceFileController'
const template = require('./audioResourceFile.tpl.pug')

const AudioResourceFile = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: AudioResourceFileController
}

export default AudioResourceFile
