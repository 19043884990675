export default class EditCampaignService {
  constructor (ProspectService) {
    'ngInject'
    this.PS = ProspectService
  }

  getCampaignRouter ({ slug, company }) {
    return this.PS.router.one(`${company.slug}/${slug}`)
      .get()
      .then(router => router.plain())
  }
}
