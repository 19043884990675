// @flow

import type PitchService from 'global/index/api-services/PitchService.js'
import type ToastService from 'global/index/services/ToastService.js'
import type { FilterListConfig } from 'common-components/filter-list/component/filterList.js'
import type { Company, PhraseBook } from 'types/entities'

import CPBD from '../../../../dialogs/create-phrase-book/createPhraseBook.js'

export default class CompanyPhraseBooksController {
  MS: Object
  PS: PitchService
  TS: ToastService
  PhraseBooks: Object
  company: Company
  $state: Object
  $mdDialog: Object
  columnsConfig: FilterListConfig
  state: {
    loading: boolean,
    companyPhraseBooks: PhraseBook[],
  }

  constructor(
    PitchService: PitchService,
    ManagementService: Object,
    ToastService: ToastService,
    $state: Object,
    $mdDialog: Object,
  ) {
    'ngInject'

    this.MS = ManagementService
    this.$state = $state
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog

    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      {
        title: 'Edit',
        type: 'button',
        icon: 'editor:mode_edit',
        actionCb: phrasebook => this.editPhraseBook(phrasebook),
      },
    ]

    this.state = {
      loading: true,
      companyPhraseBooks: [],
      topBarButtons: [
        {
          title: 'New Phrase Book',
          icon: 'content:add',
          action: this.createPhraseBook.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onInit() {
    this.PhraseBooks = this.PS.setupPhraseBooks({
      company: this.company,
    }).phraseBooks
    this.getPhraseBooks()
  }

  getPhraseBooks() {
    this.PhraseBooks.get()
      .then(phraseBooks => phraseBooks.plain())
      .then(phraseBooks => {
        this.state.companyPhraseBooks = phraseBooks
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching global pitch segments',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  createPhraseBook() {
    CPBD.locals = {
      isCreate: true,
      service: this.PhraseBooks,
    }
    this.$mdDialog
      .show(CPBD)
      .then(createdPhraseBook => {
        this.state.companyPhraseBooks.push(createdPhraseBook)
      })
      .catch(err => {
        if (err) console.error(err)
      })
  }

  editPhraseBook(phraseBook: PhraseBook) {
    this.$state.go('manage.editCompanyPhraseBook', {
      compSlug: this.company.slug,
      compId: this.company.uuid,
      phraseBookId: phraseBook.uuid,
      phraseBook: phraseBook,
    })
  }
}
