import { CAMPAIGN_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'revenue',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Revenue',
    prepend: '$',
  },
}

const keys = {
  statKey: 'revenue',
  componentKeys: [CAMPAIGN_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'aggregate',
}

const revenue = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { revenue }
