import { any, map } from 'ramda'
import {
  hideInvalid,
  errorParser,
} from '../../../../../utils/managementUtils.js'
import { TEAM_LEADER_GROUP } from 'permissions.js'
import { groupFilter } from 'util/permissions/groups.js'

export default class EditLocationCreateTeamController {
  constructor(
    $state,
    $timeout,
    IdentityService,
    ManagementService,
    ManageLocationsService,
  ) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.IS = IdentityService
    this.MS = ManagementService
    this.MLS = ManageLocationsService
    this.hideInvalid = hideInvalid
    this.anyFalse = any(x => x === false)
    this.state = {
      actionTitle: 'Team Info',
      creationErrors: null,
      creatingTeam: false,
      askNextAction: false,
      groupIds: null,
    }
  }

  $onInit() {
    this.IS.Groups.list
      .get()
      .then(groups => groups.plain())
      .then(groupFilter(TEAM_LEADER_GROUP))
      .then(map(gr => gr.pk))
      .then(groups => {
        this.state.groupIds = groups
      })
  }

  createTeam(team, locationId) {
    const parsedTeam = this.parseTeamObject(team, locationId)
    this.resetErrors()
    this.state.actionTitle = 'Creating'
    this.state.creatingTeam = true
    this.MS.createEntity({ entity: 'team', data: parsedTeam })
      .then(campaign => {
        this.$timeout(() => {
          this.state.actionTitle = 'Team Created'
          this.state.askNextAction = true
        }, 2000)
      })
      .catch(err => {
        console.error('Error creating campaign: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, 2000)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingTeam = false
        }, 2000)
      })
  }

  resetErrors() {
    this.state.creationErrors = null
  }

  createAnother() {
    this.team = {}
    this.state.actionTitle = 'Team Info'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  backTo({ reload = false }) {
    this.$state.go('manage.editLocation', { uuid: this.locationId }, { reload })
  }

  searchUsers(searchText) {
    return this.IS.User.manage.list
      .get({
        assignable: true,
        search: searchText,
        groups: this.state.groupIds,
      })
      .then(users => {
        return users.plain().results
      })
      .catch(err => {
        console.error('COULD NOT GET USER LIST', err)
      })
  }

  parseTeamObject(team, locationId) {
    const { name, leader } = team
    return {
      name,
      leader: leader.uuid,
      location: locationId,
    }
  }
}
