import './editEntityToolbar.scss'
import EditEntityToolbarController from './controller/EditEntityToolbarController.js'
const template = require('./editEntityToolbar.component.tpl.pug')

const editEntityToolbar = {
  template: template(),
  controller: EditEntityToolbarController,
  bindings: {
    entity: '<',
    options: '<',
    backToRoute: '@',
    showContent: '<',
    loading: '<',
    toggleId: '@?',
    onToggle: '&',
  }
}

export default editEntityToolbar
