export default class CreatePhraseBookController {
  constructor ($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.state = {
      creating: false,
      error: false,
    }
  }

  createPhraseBook (phraseBook) {
    this.state.creating = true
    this.service.customPOST(phraseBook)
      .then(createdPhraseBook => createdPhraseBook.plain())
      .then(this.close.bind(this))
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
  }

  resetError () {
    this.state.error = false
  }

  close (phraseBook) {
    phraseBook && phraseBook.uuid
      ? this.$mdDialog.hide(phraseBook)
      : this.$mdDialog.cancel()
  }
}

