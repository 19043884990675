import './changePassword.scss'
import ChangePasswordDialogController from './controller/ChangePasswordDialogController.js'
const template = require('./changePassword.tpl.pug')

const changePassword = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ChangePasswordDialogController
}

export default changePassword
