// @flow

import type ManageMenuService from '../../../../manage/services/ManageMenuService.js'
import type { MenuSection } from '../../../side-nav/services/SideNavService.js'
import type { PermissionGroup, StoredPermissions } from 'permissions.js'
import type { NavItems } from '../titleBarTabNav.js'

import { MANAGE_GROUP } from 'permissions.js'

export default class TitleBarTabNavController {
  entity: Object // passed to component
  navItems: NavItems // passed to component
  backToRoute: ?{ name: string, route: string } // passed to component
  hasNavDropdown: boolean // passed to component
  isEditEntityPage: boolean // passed to component
  subNavSection: string // passed to component
  MMS: ManageMenuService
  SNS: Object
  RS: Object
  MANAGE_GROUP: PermissionGroup
  $state: Object
  menu: ?Array<Object>
  userRoles: ?StoredPermissions
  sideNavMenu: Array<MenuSection>
  subNavMenu: Array<{ name: string, state: string }>

  constructor(
    $state: Object,
    SideNavService: Object,
    ManageMenuService: ManageMenuService,
    RoleStore: Object,
  ) {
    'ngInject'
    this.MMS = ManageMenuService
    this.RS = RoleStore
    this.$state = $state
    this.SNS = SideNavService
    this.MANAGE_GROUP = MANAGE_GROUP
  }

  $onInit() {
    if (this.hasNavDropdown) {
      this.menu = this.MMS.getMenu()
      this.userRoles = this.RS.getAllRoles()
      this.sideNavMenu = this.SNS.createMenuForRole(this.menu, this.userRoles)
      this._createSubNav()
    }
  }

  _createSubNav() {
    this.subNavMenu = this.sideNavMenu
      .filter(section => section.name === this.subNavSection)
      .reduce((acc, section) => {
        section.pages.forEach(page => {
          if (!page.state.includes(this.$state.current.name)) {
            acc.push(page)
          }
        })
        return acc
      }, [])
  }

  goTo(state: string) {
    this.$state.go(state)
  }

  openMenu($mdMenu: Object, ev: Event) {
    $mdMenu.open(ev)
  }
}
