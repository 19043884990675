import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'answerSeizureRatio',
  type: 'call',
  access: ['stats', 'causes'],
}

const formatting = {
  format: {
    title: 'ASR',
    append: '%',
    subSection: 'hangup_causes',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'sub_metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'normal_clearing' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const answerSeizureRatio = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { answerSeizureRatio }
