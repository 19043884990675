import {
  errorParser,
  hideInvalid,
} from '../../../../../utils/managementUtils.js'

const TRANSITION_DELAY = 2000

export default class EditPhraseBookPhraseFormController {
  constructor($state, $timeout, $mdDialog, ManagementService, PitchService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.MS = ManagementService
    this.PS = PitchService
    this.hideInvalid = hideInvalid
    this.errorParser = errorParser
    this.service = null
    this.editMode = false
    this.formStates = {
      initial: 0,
      saving: 1,
      saved: 2,
      error: 3,
    }

    this.state = {
      actionTitle: '',
      creationErrors: null,
      creatingPhrase: false,
      askNextAction: false,
    }
  }

  $onInit() {
    // if (this.phrase) {
    //   this.editablePhrase = angular.copy(this.phrase)
    // }
    // if (this.phrase && this.phrase.phrase) {
    //   this.editMode = true
    // }
    // let services = this.PS.setupPhraseBooks(this.compSlug)
    // this.service = services.global
    // if (this.compSlug && this.compSlug !== 'global') {
    //   this.service = services.company
    // }
    // this.service = this.service.one(this.phraseBook.uuid).one('phrases')
    // if (this.editMode) {
    //   this.service = this.service.one(this.phrase.uuid)
    // }
    // this.state.actionTitle = this.getActionTitle(this.formStates.initial)
    // getHttpCodes().then(codes => {
    //   this.statusCodes = codes
    // })
  }

  getActionTitle(state) {
    if (state === this.formStates.initial) {
      return this.editMode
        ? `Editing ${this.phrase.phrase}`
        : `New Phrase for ${this.phraseBook.name}`
    }
    if (state === this.formStates.saving) {
      return this.editMode ? `Updating` : 'Creating'
    }
    if (state === this.formStates.saved) {
      return this.editMode ? `Updated` : 'Phrase Created'
    }
  }

  savePhrase(phrase) {
    this.state.creatingPhrase = true
    let method = this.service.customPOST
    if (this.editMode) {
      method = this.service.customPATCH
    }
    method(phrase)
      .then(phrase => {
        this.$timeout(() => {
          this.state.actionTitle = this.getActionTitle(this.formStates.saved)
          this.state.askNextAction = true
        }, TRANSITION_DELAY)
      })
      .catch(err => {
        console.error('Error creating phrase: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, TRANSITION_DELAY)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingPhrase = false
        }, TRANSITION_DELAY)
      })
  }

  createAnother() {
    this.editablePhrase = {}
    this.state.actionTitle = this.getActionTitle(this.formStates.initial)
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit() {
    this.$state.go('^.^', null, { reload: true })
  }

  resetErrors() {
    this.state.creationErrors = null
  }

  backTo() {
    this.$state.go('^.^')
  }
}
