// @flow

import type IdentityService from '../../../global/index/api-services/IdentityService.js'
import type SideNavService from 'common-components/side-nav/services/SideNavService.js'
import type RoleStore from '../../../../permissions/role-store/services/RoleStore.js'
import type { Realm } from 'types/entities'
import type { Page } from 'common-components/side-nav/services/SideNavService.js'
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { MuiThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import { theme } from 'theme'
import GeneralHeader from 'common-components/general-header/GeneralHeader'
import SettingsTabs from 'common-components/settings-tabs/SettingsTabs'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    // All Angular services
    IdentityService: IdentityService,
    SideNavService: SideNavService,
    ManageMenuService: Object,
    RoleStore: RoleStore,
    $state: Object,
}

type State = {
    active: boolean,
    activeTxt: String,
    name: string,
    slug: string,
    uuid: string,
}

export class ManageRealmsEdit extends React.Component<Props, State> {
    state = {
        active: false,
        activeTxt: '',
        name: '',
        slug: '',
        uuid: '',
    }

    componentDidMount() {
        let url = window.location.href;
        url = url.split("edit/");
        let uuid = url[1]

        axios.
            get(`${API_BASE_URL}/identity/realm/${uuid}/`)
            .then(res => {
                let active = res.data.active ? 'Active' : 'Inactive'
                this.setState({
                    active: res.data.active,
                    activeTxt: active,
                    name: res.data.name,
                    slug: res.data.slug,
                    uuid: res.data.uuid
                })
            })
            .catch(err => console.log(err));
    }


    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <GeneralHeader
                    padding="30px 0 36px"
                    title="Realms"
                    name={this.state.name}
                    active={this.state.active}
                    activeTxt={this.state.activeTxt}
                />
                <Paper style={{ width: '100%', overflowX: 'auto', padding: '30px' }}>
                    <div style={{ fontSize: '18px', color: '#444851', fontWeight: '500' }}>Realms Settings</div>
                    <SettingsTabs name={this.state.name}
                        active={this.state.active}
                        activeTxt={this.state.activeTxt}
                        slug={this.state.slug}
                        uuid={this.state.uuid} />
                </Paper>

            </MuiThemeProvider>
        )
    }
}
