// @flow
// eslint-disable-next-line no-unused-vars
import type IdentityService from '../../../../global/index/api-services/IdentityService.js'
import type ToastService from '../../../../global/index/services/ToastService.js'
import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type { Element } from 'restangular'
import type {
  RapidResponseTest,
  RapidResponseSegment,
  TtsVoice,
  User,
} from 'types/entities.js'
import { recorderGroupFinder } from 'util/voices.js'

export default class CreateTestsController {
  TS: ToastService
  $mdDialog: Object
  testService: Object // passed to dialog
  isCreate: boolean // passed to dialog
  rapidResponseService: Object // passed to dialog
  PS: PitchService
  ttsService: Element
  Users: Element
  Groups: Element
  state: {|
    submitting: boolean,
    rapidResponseSegments: RapidResponseSegment[],
    tts_voices: TtsVoice[],
    prospectVoices: User[],
    error: boolean,
  |}

  constructor(
    $mdDialog: Object,
    ToastService: ToastService,
    IdentityService: IdentityService,
    PitchService: PitchService,
  ) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.state = {
      submitting: false,
      error: false,
      rapidResponseSegments: [],
      prospectVoices: [],
      tts_voices: [],
    }
    this.Groups = IdentityService.Groups.list
    this.PS = PitchService
    this.ttsService = this._getTtsService()
    this.Users = IdentityService.User.manage.list
  }

  $onInit() {
    this.ttsService = this._getTtsService()
  }

  createTest(newTest: RapidResponseTest) {
    this.testService
      .customPOST(newTest)
      .then(createdTest => {
        this.close(Object.assign(createdTest, newTest))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  getSegments() {
    return this.rapidResponseService
      .get()
      .then(segments => {
        this.state.rapidResponseSegments = segments.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching rapid response segments',
        })
        console.error(err)
      })
  }

  getVoices() {
    return this.Groups.get()
      .then(groups => groups.plain())
      .then(recorderGroupFinder)
      .then(group => this.Users.get({ groups: group.pk }))
      .then(prospectVoices => {
        this.state.prospectVoices = prospectVoices.plain().results
      })
  }
  getTTSVoices() {
    return this.ttsService.getList().then(ttsVoices => {
      this.state.tts_voices = ttsVoices
    })
  }

  close(newTest: RapidResponseTest) {
    newTest
      ? this.$mdDialog.hide({ wasCreate: this.isCreate, newTest })
      : this.$mdDialog.cancel()
  }

  _getTtsService() {
    return this.PS.setupTtsVoices()
  }
}
