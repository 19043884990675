// create location team view

const manageEditLocationCreateTeam = 'manage.editLocation.createTeam'

const manageEditLocationCreateTeamConfig = {
  abstract: true,
  url: '/team',
  component: 'rightDrawer',
}

export { manageEditLocationCreateTeam, manageEditLocationCreateTeamConfig }
