class DirectiveCtrl {
  constructor () {
    this.control = {}
    this.control.show = !this.collapsed
    this.control.toggle = () => {
      this.control.show = !this.control.show
    }
  }
}

export default function collapseContent ($timeout) {
  'ngInject'
  function linkFunc (scope, elem, attrs) {
    const myElem = elem[0]
    $timeout(() => {
      let height = myElem.offsetHeight
      myElem.style.height = `${height}px`
    }, 200)
  }

  return {
    restrict: 'E',
    scope: {},
    controller: DirectiveCtrl,
    controllerAs: 'vm',
    bindToController: {
      control: '=dControlObject',
      collapsed: '=dStartCollapsed'
    },
    link: linkFunc
  }
}
