import './editGlobalOptionGroups.scss'
import EditGlobalOptionGroupsController from './controller/EditGlobalOptionGroupsController.js'
const template = require('./editGlobalOptionGroups.component.tpl.pug')

const editGlobalOptionGroups = {
  controller: EditGlobalOptionGroupsController,
  template: template(),
  bindings: {
    guiService: '<',
  },
}

export default editGlobalOptionGroups
