// @flow

import type { BaseService } from 'restangular'
import type { Campaign, Company } from 'types/entities.js'
import type {
  GlobalAndMaybeCompanyBase,
  GlobalAndMaybeCompanyElem,
  GlobalAndMaybeCompanyCampaign,
  VoiceDialogConfig,
  PhraseDialogConfig,
  RecordingService,
} from './types.js'

import { omit, pick, pipe } from 'ramda'

export default class PitchService {
  Rest: BaseService
  RCRest: BaseService

  constructor(Restangular: Object, RCRestangular: Object) {
    'ngInject'
    this.Rest = Restangular
    this.RCRest = RCRestangular
  }

  setupBase(
    campaign?: ?Campaign,
    appendToBase?: string = '',
  ): GlobalAndMaybeCompanyCampaign {
    let services = {}
    services.global = this.Rest.service(
      ['pitch/global', appendToBase].join('/'),
    )
    if (campaign && campaign.slug && typeof campaign.company !== 'string') {
      const companySlug = campaign.company.slug
      Object.assign(services, {
        companyCampaign: this.Rest.service(
          [
            `pitch/company/${companySlug}/campaign/${campaign.slug}`,
            appendToBase,
          ].join('/'),
        ),
        company: this.Rest.service(
          [`pitch/company/${companySlug}`, appendToBase].join('/'),
        ),
      })
    }
    return services
  }

  setupCompanyBase(company?: Company): GlobalAndMaybeCompanyBase {
    let services = {}
    services.global = this.Rest.service('pitch/global')
    if (company && company.slug) {
      Object.assign(services, {
        company: this.Rest.service(`pitch/company/${company.slug}`),
      })
    }
    return services
  }

  setupPitchSegmentVariables(
    compSlug: string = 'global',
  ): GlobalAndMaybeCompanyBase {
    let services = {}
    services.global = this.Rest.service('pitch/global/segments')
    if (compSlug !== 'global') {
      Object.assign(services, {
        company: this.Rest.service(`pitch/company/${compSlug}/segments`),
      })
    }
    return services
  }

  setupRapidResponseSegmentVariables(
    compSlug: string = 'global',
  ): GlobalAndMaybeCompanyBase {
    let services = {}
    services.global = this.Rest.service('pitch/global/rapid-response/segments')
    if (compSlug !== 'global') {
      Object.assign(services, {
        company: this.Rest.service(
          `pitch/company/${compSlug}/rapid-response/segments`,
        ),
      })
    }
    return services
  }

  setupRapidResponse(compSlug: string = 'global'): GlobalAndMaybeCompanyBase {
    let services = {}
    services.global = this.Rest.service('pitch/global/rapid-response')
    if (compSlug !== 'global') {
      Object.assign(services, {
        company: this.Rest.service(`pitch/company/${compSlug}/rapid-response`),
      })
    }
    return services
  }

  setupTtsVoices() {
    let service = this.Rest.service('pitch/tts/').one('voices')
    return service
  }

  setupAudioResources(compSlug: string = 'global'): GlobalAndMaybeCompanyElem {
    let services = {}
    services.global = this.Rest.service('pitch/global/audio').one('resources')
    if (compSlug !== 'global') {
      Object.assign(services, {
        company: this.Rest.service(`pitch/company/${compSlug}/audio`).one(
          'resources',
        ),
      })
    }
    return services
  }

  setupDialogVoice(config: VoiceDialogConfig): RecordingService {
    const {
      pitchVersionId = '',
      voiceId = '',
      toOmit = [],
      toPick = ['base', 'recorded', 'rerecord', 'unrecorded'],
    } = config
    const filterService = pipe(
      omit(toOmit),
      pick(toPick),
    )
    const voiceBase = this.Rest.service(
      `pitch/audio/version/${pitchVersionId}/voice/${voiceId}`,
    )
    const services: { [serviceKey: string]: Object } = {
      base: voiceBase,
      recorded: voiceBase.one('recorded'),
      rerecord: voiceBase.one('rerecord'),
      unrecorded: voiceBase.one('unrecorded'),
    }
    return filterService(services)
  }

  setupPhraseBooks(config?: { company: Company }) {
    const phraseBooksBase = config
      ? this.Rest.service(`pitch/company/${config.company.slug}`)
      : this.Rest.service('pitch/global')
    return {
      base: phraseBooksBase,
      phraseBooks: phraseBooksBase.one('phrases'),
    }
  }

  setupPhraseBook(config: { phraseBookId: string, companySlug?: string }) {
    const { phraseBookId, companySlug = null } = config
    const phraseBookBase = companySlug
      ? this.Rest.service(
          `pitch/company/${companySlug}/phrases/${phraseBookId}`,
        )
      : this.Rest.service(`pitch/global/phrases/${phraseBookId}`)

    return {
      base: phraseBookBase,
      phrases: phraseBookBase.one('phrases'),
    }
  }

  setupPhraseBookVoice(config: PhraseDialogConfig): RecordingService {
    const {
      voiceId = '',
      phraseBookId = '',
      companySlug = null,
      toOmit = [],
      toPick = ['base', 'recorded', 'rerecord', 'unrecorded'],
    } = config
    const filterService = pipe(
      omit(toOmit),
      pick(toPick),
    )
    const phraseBase = companySlug
      ? this.Rest.service(
          `pitch/company/${companySlug}/audio/phrase-book/${phraseBookId}/voice/${voiceId}`,
        )
      : this.Rest.service(
          `pitch/global/audio/phrase-book/${phraseBookId}/voice/${voiceId}`,
        )
    const services: { [serviceKey: string]: Object } = {
      base: phraseBase,
      recorded: phraseBase.one('recorded'),
      rerecord: phraseBase.one('rerecord'),
      unrecorded: phraseBase.one('unrecorded'),
    }
    return filterService(services)
  }

  setupProspectVoice(config: VoiceDialogConfig) {
    const {
      pitchVersionId = '',
      voiceId = '',
      toOmit = [],
      toPick = ['base', 'recorded', 'rerecord', 'unrecorded'],
    } = config
    const filterService = pipe(
      omit(toOmit),
      pick(toPick),
    )
    const voiceBase = this.Rest.service(
      `pitch/audio/version/${pitchVersionId}/prospect/${voiceId}`,
    )
    const services: { [serviceKey: string]: Object } = {
      base: voiceBase,
      recorded: voiceBase.one('recorded'),
      rerecord: voiceBase.one('rerecord'),
      unrecorded: voiceBase.one('unrecorded'),
    }
    return filterService(services)
  }

  setupAddProspectVoice() {
    const service = this.RCRest.service(`pitch/audio/prospect/voice`)
    return service
  }
}
