// Edit Rapid Response Test view

const manageEditRapidResponseTestsSegments =
  'manage.editRapidResponseTests.segments'

const manageEditRapidResponseTestsSegmentsConfig = {
  url: '/segments',
  component: 'editRapidResponseTestsSegments',
}

export {
  manageEditRapidResponseTestsSegments,
  manageEditRapidResponseTestsSegmentsConfig,
}
