import deleteEntityDialog from '../../../../dialogs/delete-entity/deleteEntity.js'

export default class CampaignSettingsController {
  constructor ($state, $timeout, $mdDialog, ManagementService, IdentityService,
    ToastService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.TS = ToastService
    this.IS = IdentityService
    this.MS = ManagementService

    this.urlRegex = /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&]{1}[\da-z.-]+)*[/?]?$/

    this.state = {
      loading: {
        details: true,
        dataPosting: true,
      },
      show: {
        details: false,
        dataPosting: false,
      },
      routers: [],
    }
  }

  $onInit () {
    this.editableCompany = Object.assign({}, this.company)
    this.$timeout(() => {
      this.state.show.details = true
      this.state.loading.details = false
    }, 500)
  }
  updateCompany (company) {
    this.MS.updateEntity({ entity: 'company', data: company })
      .then(updatedCompany => {
        this.company = Object.assign({}, updatedCompany)
        this.companyEditForm.$setPristine()
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0]
          })
        }
        this.TS.show({
          text: 'Error updating company'
        })
      })
  }
  cancelEdit (form) {
    this.editableCompany = Object.assign({}, this.company)
    form.$setPristine()
  }
  deleteCompany (company) {
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Company',
      entityName: company.name,
      identityEndpoint: this.IS.Company.fetch.one(company.uuid),
      entity: company,
      routeWhenSuccessful: 'manage.companies'
    }
    this.$mdDialog.show(DCD)
  }

  toggleActive (bool) {
    this.editableCompany.active = bool
  }
}
