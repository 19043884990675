export default class PerformanceHealthController {
  constructor(StatsService) {
    'ngInject'

    this.SS = StatsService
  }

  $onInit() {
    this.state = {
      loading: true,
    }

    if (this.metric.details.realm) {
      this.SS[this.metric.key].get
        .call(this.SS, this.metric.details)
        .then(data => {
          this.value = data[0].value
          this.state.loading = false
        })
    } else {
      this.state.loading = false
    }
  }
}
