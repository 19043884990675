import './viewEditToggle.scss'
const template = require('./viewEditToggle.tpl.pug')

const viewEditToggle = {
  template: template(),
  bindings: {
    edit: '<',
    onToggle: '<',
    tip: '@',
  }
}

export default viewEditToggle
