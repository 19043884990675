// create campaign router view

const manageEditCampaignCreateRouterInfo =
  'manage.editCampaign.dataPosting.create.routerInfo'

const manageEditCampaignCreateRouterInfoConfig = {
  params: {
    campaign: null,
  },
  component: 'routerInfoForm',
  resolve: {
    campaign: function($transition$) {
      'ngInject'
      return $transition$.params().campaign
    },
  },
}

export {
  manageEditCampaignCreateRouterInfo,
  manageEditCampaignCreateRouterInfoConfig,
}
