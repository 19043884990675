import { clone } from 'ramda'

export default class MetricGoalController {
  constructor() {
    'ngInject'
    this.state = {
      dirty: false,
      metricCopy: clone(this.metric),
      showGoals: false,
    }
  }

  toggleShow() {
    this.state.showGoals = !this.state.showGoals
  }

  touched() {
    this.state.dirty = true
    this.metric.active = false
  }

  saveGoal(goal) {
    this.state.metricCopy = clone(goal)
    this.updateGoal({ goal: goal })
  }

  reset(form) {
    this.metric = clone(this.state.metricCopy)
  }
}
