// CSS
import 'angular-material/angular-material.css'
import 'angular-material-icons/angular-material-icons.css'
import 'angular-material-data-table/dist/md-data-table.css'
import 'animate.css/animate.css'
import './index.scss'
import './utils.scss'

import angular from 'angular'
import Highcharts from 'highcharts/highstock.src.js'
import highchartsDrilldown from 'highcharts/modules/drilldown.src.js'
import highchartsExporting from 'highcharts/modules/exporting.src.js'
import highchartsExportData from 'highcharts/modules/export-data.src.js'

// Portal App Modules
import portalAppGlobal from './global/index/global.index.js'
import portalAppCommonComponents from './common-components/commonComponents.js'
import portalAppAuth from './auth/auth.js'
import portalAppUpdatePassword from './updatePassword/updatePassword.js'
import manageChangePassword from './changePassword/changePassword'
import portalAppDashboard from './dashboard/dashboard.js'
import portalAppGateway from './gateway/gateway.js'
import portalAppManage from './manage/manage.js'

// add module to Highcharts
highchartsExporting(Highcharts)
highchartsExportData(Highcharts)
highchartsDrilldown(Highcharts)

Highcharts.setOptions({
  lang: {
    drillUpText: '<< Back To {series.name}',
  },
  global: {
    useUTC: false,
  },
})

export default angular.module('portalApp', [
  portalAppGlobal,
  portalAppCommonComponents,
  portalAppAuth,
  portalAppUpdatePassword,
  manageChangePassword,
  portalAppDashboard,
  portalAppGateway,
  portalAppManage,
]).name
