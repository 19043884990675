// @flow

import type PitchService from 'global/index/api-services/PitchService'
import type ToastService from 'global/index/services/ToastService'
import type { FilterListConfig } from 'common-components/filter-list/component/filterList'
import type { Company, RapidResponseTest } from 'types/entities'

import CTD from '../../../../../dialogs/create-tests/createTests.js'

export default class CompanyRapidResponseTestsController {
  PS: PitchService
  TS: ToastService
  columnsConfig: FilterListConfig
  company: Company
  $mdDialog: Object
  $state: Object
  $localStorage: Object
  rrTestService: Object
  rrSegmentService: Object
  state: {
    loading: boolean,
    rrTests: RapidResponseTest[],
  }

  constructor(
    PitchService: PitchService,
    ToastService: ToastService,
    $mdDialog: Object,
    $state: Object,
    $localStorage: Object,
  ) {
    'ngInject'
    this.PS = PitchService
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.$localStorage = $localStorage

    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'Final Revenue', accessor: 'final_revenue', type: 'text' },
      {
        title: 'Test Type',
        accessor: 'test_type',
        type: 'text',
        parser: type => (type === 1 ? 'Long' : 'Short'),
      },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      { title: 'live', accessor: 'live', type: 'boolean' },
      { title: 'Active', accessor: 'active', type: 'active' },
      {
        title: 'Edit',
        icon: 'editor:mode_edit',
        type: 'button',
        actionCb: test => this.editTest(test),
      },
    ]

    this.state = {
      loading: true,
      rrTests: [],
      topBarButtons: [
        {
          title: 'New Test',
          icon: 'content:add',
          action: this.createTest.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onChanges() {
    const service = this.PS.setupRapidResponse(this.company.slug).company
    if (service) {
      this.rrTestService = service.one('tests')
      this.rrSegmentService = service.one('segments')
      this.getRapidResponseTests()
    }
  }

  getRapidResponseTests() {
    this.state.loading = true
    this.rrTestService
      .get()
      .then(tests => {
        this.state.rrTests = tests.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching rapid response tests',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  createTest() {
    CTD.locals = {
      isRapidResponse: true,
      testService: this.rrTestService,
      rapidResponseService: this.rrSegmentService,
      endpoint: 'rapid-response/tests',
      company: this.company.slug,
      test: null,
    }
    return this.$mdDialog.show(CTD).then(() => {
      this.$state.go(this.$state.current.name, null, { reload: true })
    })
  }

  editTest(test: RapidResponseTest) {
    this.$state.go('manage.editRapidResponseTests.menu', {
      compSlug: this.company.slug,
      testUuid: test.uuid,
      entity: test,
    })
  }
}
