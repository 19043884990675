import angular from 'angular'

// services
import DidTableService from './sub-components/did-table/component/service/DidTableService.js'
// components
import editDidPool from './component/editDidPool.component'
import didTable from './sub-components/did-table/component/didTable.component.js'

export default angular
  .module('portalApp.manage.editDidPool', [])
  .service('DidTableService', DidTableService)
  .component('didTable', didTable)
  .component('editDidPool', editDidPool).name
