import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
import { ADMIN_GROUP } from 'permissions.js'

// Edit Access Lists View

const manageEditAccessList = 'manage.editAccessList'

const pageTitleConfig = {
  title: 'Edit Access List'
}

const manageEditAccessListConfig = {
  url: '/access-list/{compSlug}/edit/{uuid}',
  params: {
    compSlug: null,
    uuid: null,
    entity: null,
    entityIndex: null
  },
  component: 'editAccessList',
  data: {
    permissions: {
      only: [ ...ADMIN_GROUP ]
    }
  },
  resolve: {
    compSlug: function ($transition$) {
      'ngInject'
      return $transition$.params().compSlug || 'global'
    },
    accessList: function ($transition$, IdentityService) {
      'ngInject'
      const entity = $transition$.params().entity
      if (entity) return entity

      let compSlug = $transition$.params().compSlug || 'global'
      return IdentityService.Acl.fetch
        .one($transition$.params().uuid, compSlug)
        .get()
        .then(accessList => accessList.plain())
    },
    pageTitle: makePageTitle(pageTitleConfig),
  },
}

export { manageEditAccessList, manageEditAccessListConfig }
