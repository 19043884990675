import React, { useEffect, useState } from 'react'
import axios from 'axios'
var slugify = require('slugify')
var moment = require('moment')
import { getBaseUrl } from '../../../../global/config/providers/restangularProvider/rest.js'
import { PulseLoader } from 'react-spinners'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppSearchInput from '../../../../components/AppSearchInput.js'
import AppButton from '../../../../components/AppButton.js'
import AppModal from '../../../../components/AppModal.js'
import AppTextField from '../../../../components/AppTextField.js'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Link,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './changelog.scss'

const useStyles = makeStyles(theme => ({
  form: {
    width: 100 + '%',
  },
  searchIcon: {
    color: '#bbbbbb',
  },
  tableHead: {
    backgroundColor: '#fbfbfb',
    borderTop: 'solid 1px #eeeeee',
    borderBottom: 'solid 1px #eeeeee',
    fontWeight: 500,
    fontSize: 14,
    a: {},
  },
  menu: {
    position: 'absolute',
    right: 45,
    'z-index': 9999,
    width: 150,
  },
  link: {
    color: '#444851',
    fontSize: 14,
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#fbfbfb',
      cursor: 'pointer',
    },
  },
  paperList: {
    position: 'absolute',
    width: 250,
    padding: 10,
    marginTop: 5,
  },
}))

// Axios configuration
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
if (localStorage.getItem('ngStorage-ppToken') != null) {
  axios.defaults.headers.common['Authorization'] = `token ${JSON.parse(
    localStorage['ngStorage-ppToken'],
  )}`
}

const Spinner = () => {
  return (
    <div className="SyncLoader">
      <PulseLoader sizeUnit={'px'} size={10} color={'#9e9e9e'} loading={true} />
    </div>
  )
}

const API_BASE_URL = getBaseUrl(window.location.host, true)
const changelog = props => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [autocomplete, setAutocomplete] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [campaign, setCampaign] = useState({})
  const [changelog, setChangelog] = useState('')
  const [user, setUser] = useState('')
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalInfo, setModalInfo] = useState(() => {
    return {
      user: '',
    }
  })
  const [sortedUser, setSortedUser] = useState(false)
  const [sortedCreated, setSortedCreated] = useState(false)

  const getCampaign = () => {
    setLoading(true)

    let path = window.location.hash.replace('/settings', '')
    let uuid = path.substring(path.length - 36, path.length)

    axios
      .get(`${API_BASE_URL}/identity/campaign/${uuid}/`, {
        headers: {
          Authorization: `token ${JSON.parse(
            localStorage['ngStorage-ppToken'],
          )}`,
        },
      })
      .then(res => {
        setCampaign(res.data)
        getChangelogs(res.data.slug)
      })
  }

  const getChangelogs = slug => {
    axios
      .post(`${API_BASE_URL}/identity/changelog/filter/`, {
        campaign: slug,
      })
      .then(res => {
        res.data.data.forEach((item, index) => {
          if (Object.keys(item.changed_fields).length > 0) {
            item.changed_fields = printChangeFields(
              item.changed_fields,
              item.original_fields,
            )
          }
        })
        console.log('data: ', res.data)

        setData(res.data)
        setSearchResult(res.data)
        setLoading(false)
      })
  }

  const getNextData = () => {
    if (loading) return
    else setLoading(true)
    axios
      .post(`${API_BASE_URL}/identity/changelog/filter/`, {
        campaign: campaign.slug,
        last_evaluated_key: searchResult.last_evaluated_key,
      })
      .then(res => {
        searchResult.last_evaluated_key = res.data.last_evaluated_key
        res.data.data.forEach((item, index) => {
          if (Object.keys(item.changed_fields).length > 0) {
            item.changed_fields = printChangeFields(
              item.changed_fields,
              item.original_fields,
            )
          }
        })

        res.data.data.forEach((item, index) => {
          searchResult.data[searchResult.data.length] = item
        })
        setSearchResult({ ...searchResult })
        setLoading(false)
      })
  }

  const cleanText = text => {
    if (text['S']) {
      return text['S']
    } else if ('BOOL' in text) return text['BOOL'] ? 'TRUE' : 'FALSE'
    return text
  }

  const printChangeFields = (fields, originalFields) => {
    let field = Object.keys(fields).reduce(
      (text, key) =>
        `New ${key}: "${cleanText(fields[key])}"  <br/>` +
        (originalFields && originalFields[key]
          ? `Before ${key}: "${cleanText(originalFields[key])}"`
          : ``) +
        (text ? ` <br/><br/>${text}` : ``),
      '',
    )
    return field
  }

  const sortList = (sortedItem, type) => {
    let results = []

    if (sortedItem)
      results = data.data.sort((a, b) => (a[type] > b[type] ? -1 : 1))
    else results = data.data.sort((a, b) => (a[type] < b[type] ? -1 : 1))

    if (type === 'user') setSortedUser(!sortedUser)
    if (type === 'created') setSortedCreated(!sortedCreated)

    let tempData = { ...data }
    tempData.data = results
    setSearchResult({ ...tempData })
  }

  const handleAutocomplete = value => {
    setUser(value)
    axios
      .get(`${API_BASE_URL}/identity/user/manage/list/`, {
        params: {
          search: value,
          limit: 10,
        },
      })
      .then(res => {
        setAutocomplete(res.data.results)
      })
  }

  const getDataBy = (type, value) => {
    setLoading(true)
    if (type === 'by_date') {
      let newValue = moment(value).format('MMM D, YYYY')
      value = slugify(newValue)
    } else setUser(value)

    axios
      .post(`${API_BASE_URL}/identity/changelog/filter/`, {
        campaign: campaign.slug,
        [type]: value,
      })
      .then(res => {
        res.data.data.forEach((item, index) => {
          if (Object.keys(item.changed_fields).length > 0) {
            item.changed_fields = printChangeFields(
              item.changed_fields,
              item.original_fields,
            )
          }
        })
        setSearchResult(res.data)
        setLoading(false)
      })
  }

  const handlerSeachUser = user => {
    setUser(user)
    setAutocomplete([])
    getDataBy('by_name', user)
  }

  const handleClear = () => {
    setUser('')
    setChangelog('')
    setSelectedDate(null)
    setSearchResult(data)
  }

  const getTrimChangeLogChanges = changedFields => {
    const changedFieldsRows = changedFields.split('<br/>')
    let trimChangeLogChanges = ''
    for (let idx = 0; idx < changedFieldsRows.length; idx++) {
      if (idx == 2) {
        trimChangeLogChanges += '...'
        break
      }
      if (idx == 1) {
        trimChangeLogChanges += changedFieldsRows[idx]
      } else {
        trimChangeLogChanges += changedFieldsRows[idx] + '<br/>'
      }
    }
    3
    return trimChangeLogChanges
  }

  useEffect(() => {
    getCampaign()
  }, [])

  useEffect(() => {
    if ('data' in data) {
      let newData = [...data.data]
      const filteredData = newData.filter(item =>
        item.changed_fields.includes(changelog),
      )

      searchResult.data = filteredData
      setSearchResult({ ...searchResult })
    }
  }, [changelog])

  const [selectedDate, setSelectedDate] = React.useState(null)

  const handleDateChange = date => {
    setSelectedDate(date)
    getDataBy('by_date', date)
  }

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-child(odd)': {
        backgroundColor: '#fbfbfb',
      },
      border: 'none',
    },
  }))(TableRow)

  const StyledTableCell = withStyles(theme => ({
    root: {
      border: 'none',
      padding: 20,
      color: '#777777',
    },
  }))(TableCell)

  const StyledHeadTableCell = withStyles(theme => ({
    root: {
      border: 'none',
      fontSize: 14,
      fontFamily: 'Roboto',
      letterSpacing: 'normal',
      color: '#444851',
    },
  }))(TableCell)

  const handleModal = item => {
    setModalInfo(item)
    setModal(true)
  }

  return (
    <div className="changelog" style={{ minHeight: 70 + 'vh', height: 'auto' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} className="mb-1">
          <AppTextField
            value={changelog}
            placeholder="Search"
            icon={<SearchIcon />}
            onChange={e => setChangelog(e)}
          />
        </Grid>
        <Grid item xs={4}>
          <AppTextField
            placeholder="User"
            value={user}
            icon={<PersonIcon />}
            onChange={value => handleAutocomplete(value)}
          />
          {autocomplete.length > 0 && (
            <ClickAwayListener onClickAway={() => setAutocomplete([])}>
              <Paper className={classes.paperList}>
                <Grid container spacing={2}>
                  {autocomplete.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      onClick={() => handlerSeachUser(item.username)}
                      className={classes.listItem}
                    >
                      {item.username}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </ClickAwayListener>
          )}
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider
            style={{ width: 100 + '%' }}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              margin="none"
              id="date-picker-dialog"
              placeholder="Created"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4} align="right">
          <AppButton width={165} onClick={handleClear}>Cancel</AppButton>
        </Grid>
      </Grid>

      <Table className="mt-2">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledHeadTableCell>
              <a
                onClick={() => sortList(sortedUser, 'user')}
                className="cursor-pointer"
              >
                <span className="left">User</span>
                {sortedUser ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </a>
            </StyledHeadTableCell>
            <StyledHeadTableCell align="left">
              <a
                onClick={() => sortList(sortedCreated, 'created')}
                className="cursor-pointer"
              >
                <span className="left">Created</span>
                {sortedCreated ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </a>
            </StyledHeadTableCell>
            <StyledHeadTableCell align="left">Time</StyledHeadTableCell>
            <StyledHeadTableCell align="left">Changes</StyledHeadTableCell>
            <StyledHeadTableCell align="left"></StyledHeadTableCell>
          </TableRow>
        </TableHead>
        {'data' in searchResult && (
          <TableBody>
            <tr></tr>
            {searchResult.data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {item.user}
                </StyledTableCell>
                <StyledTableCell align="left">{item.created}</StyledTableCell>
                <StyledTableCell align="left">
                  {moment(item.timestamp).format('HH:mm:ss')}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.changed_fields.length > 0 &&
                      getTrimChangeLogChanges(item.changed_fields),
                  }}
                ></StyledTableCell>
                <StyledTableCell align="right" className="position-relative">
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => handleModal(item)}
                    className={classes.link}
                  >
                    Details
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        )}
      </Table>

      <AppModal
        modalWith={651}
        showModal={modal}
        hideModal={() => setModal(false)}
        title="Change details"
      >
        <div className="p-3">
          <Grid container spacing={2}>
            <Grid item xs={3} style={{ color: '#' + 444851 }}>
              User:
            </Grid>
            <Grid item xs={9} style={{ color: '#' + 777777 }}>
              {modalInfo.user}
            </Grid>
            <Grid item xs={3} style={{ color: '#' + 444851 }}>
              Created:
            </Grid>
            <Grid item xs={9} style={{ color: '#' + 777777 }}>
              {modalInfo.created}
            </Grid>
            <Grid item xs={3} style={{ color: '#' + 444851 }}>
              Time:
            </Grid>
            <Grid item xs={9} style={{ color: '#' + 777777 }}>
              {modalInfo.time}
            </Grid>
            <Grid item xs={3} style={{ color: '#' + 444851 }}>
              Changes:
            </Grid>
            <Grid
              item
              xs={9}
              style={{ color: '#' + 777777 }}
              dangerouslySetInnerHTML={{
                __html: modalInfo.changed_fields,
              }}
            ></Grid>
            <Grid item xs={12} className="text-center mt-4">
              <AppButton
                onClick={() => setModal(false)}
                width={140}
              >Cancel</AppButton>
            </Grid>
          </Grid>
        </div>
      </AppModal>
    </div>
  )
}

export default changelog
