import BDRD from '../../buy-did-results/buyDidResults.js'
import BDCD from '../../buy-did-confirmation/buyDidConfirmation.js'

export default class BuyDidController {
  constructor($mdDialog, DidListService, ToastService, $sessionStorage) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.DidListService = DidListService
    this.TS = ToastService
    this.userEmail = $sessionStorage.user.email
    this.showMoreLessText = 'Advanced search'
    this.showAdvancedSearch = true
    this.state = {
      npa: null,
      numberOfDids: 1,
      nxx: null,
      cname: null,
      carrier: 'signalwire',
      carriers: ['382','signalwire'],
      unactives: [],
      actives: [],
      unactiveShowSearch: false,
      activeShowSearch: false,
      showLoader: false,
      search: {
        active: '',
        unactive: ''
      }
    }
  }

  $onInit() {
    this.searchDidForm = {}
    this.searchButtonDisabled = false
    this.activeFilter = this.createSegmentFilter('active')
    this.unactiveFilter = this.createSegmentFilter('unactive')
    this.state.unactives = this.DidListService.statesCountriesAreaCodes.get()
    /*this.DidListService.getStatesCountries
      .get()
      .then(data => {
        this.state.unactives = data
      })*/
  }

  searchDid(searchDidForm) {
    const stateShortNameList = this.state.actives.map((element, i) => element.state_short_name)
    if (stateShortNameList.length > 0) {
      const data = {
        number_did_to_purchase: this.state.numberOfDids,
        provider: this.state.carrier,
        states: stateShortNameList.join(","),
        prefix: this.state.nxx,
        account: this.userEmail,
        cname: this.state.cname,
      }
      const uriData = {
        company: this.company,
        campaign: this.campaign,
        pool: this.pool.uuid,
      }
      this.searchButtonDisabled = true
      this.state.showLoader = true
      this.DidListService.buyMassiveDids
        .post(data, uriData)
        .then(response => {
          this.goToBuyDidConfirmationDialog(data)
        })
        .catch(err => {
          console.error(err)
          // if error is the callback of the POST call due to CORS call, then will move to confirmation dialog
          if ((err.config !== undefined || err.config !== null) &&
            err.config.method === "POST" && err.config.jsonpCallbackParam === "callback") {
            this.goToBuyDidConfirmationDialog(data)     
          } else {
            this.TS.show({
              text: 'Error while buying dids',
            })
          }
        })
        .finally(() => {
          this.searchButtonDisabled = false
          this.state.showLoader = false
        })
    } else {
      this.TS.show({
        text: 'Please add states to buy dids.',
      })
    }
    /*let prefix = null
    if (this.state.npa) {
      prefix = this.state.npa
      if (this.state.nxx) {
        prefix = prefix.toString() + this.state.nxx.toString()
      }
    }
    const data = {
      prefix: prefix,
      how_many: this.state.numberOfDids ? parseInt(this.state.numberOfDids) : 5,
    }
    const uriData = {
      company: this.company,
      campaign: this.campaign,
    }
    this.searchButtonDisabled = true
    this.DidListService.searchList
      .post(data, uriData)
      .then(data => {
        if (data.numbers.length > 0) {
          const entityList = data.numbers.split(',').map((element, i) => {
            const number = element.match(/\d+/g).join([])
            return {
              phone: { tag: '+' + number, value: false },
              extraInfo: null,
              tier: i.toString(),
            }
          })
          BDRD.locals = {
            did: this.did,
            pool: this.pool,
            company: this.company,
            campaign: this.campaign,
            refreshDidList: this.refreshDidList.bind(this),
            entityList: entityList,
            provider: '382',
          }
          this.$mdDialog.show(BDRD)
        } else {
          console.error('Empty DID list')
          this.TS.show({
            text:
              'There were no DIDs to buy with the current search criteria, try another search criteria.',
          })
        }
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error while searching dids',
        })
      })
      .finally(() => {
        this.searchButtonDisabled = false
      })
    */
  }

  goToBuyDidConfirmationDialog(data) {
    BDCD.locals = {
      did: this.did,
      pool: this.pool,
      company: this.company,
      campaign: this.campaign,
      refreshDidList: this.refreshDidList.bind(this),
      assigned: data,
      didsPurchased: this.state.numberOfDids,
    }
    this.$mdDialog.show(BDCD)
  }

  showMoreLess() {
    this.showAdvancedSearch = !this.showAdvancedSearch
    this.showMoreLessText = this.showAdvancedSearch
      ? 'Show less'
      : 'Advanced search'
    if (this.showMoreLessText == 'Advanced search') {
      this.state.numberOfDids = 1
      this.state.nxx = null
      this.state.carrier = null
      this.state.cname = null
    }
  }

  close() {
    this.$mdDialog.destroy()
  }

  remove(index, arr, state) {
    // finding real index when the search is on
    if (this.state.search.active) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].state === state) {
          index = i
          break
        }
      }
    }
    const elem = arr.splice(index, 1)[0]
    this.state.unactives.push(elem)
  }

  add(index, arr, state) {
    // finding real index when the search is on
    if (this.state.search.unactive) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].state === state) {
          index = i
          break
        }
      }
    }
    const elem = arr.splice(index, 1)[0]
    this.state.actives.push(elem)
  }

  addAll() {
    for (let elem of this.state.unactives) {
      this.state.actives.push(elem)
    }
    this.state.unactives = []
  }

  removeAll() {
    for (let elem of this.state.actives) {
      this.state.unactives.push(elem)
    }
    this.state.actives = []
  }

  toggleSearch(key) {
    this.state.search[key] = ''
    key = key + 'ShowSearch'
    this.state[key] = !this.state[key]
  }

  createSegmentFilter(key) {
    return ({ searchText }) => {
      this.state.search[key] = searchText
    }
  }

  isDisabledBuyDid() {
    return !(this.state.numberOfDids <= 25 && this.state.numberOfDids > 0 && this.state.carrier) || this.searchButtonDisabled
  }

}