import './tableSearchPrompt.scss'
const template = require('./tableSearchPrompt.tpl.pug')

const tableSearchPrompt = {
  template: template(),
  bindings: {
    message: '@'
  }
}

export default tableSearchPrompt
