import moment from 'moment'
import { repInfoConfig } from '../config/agentConfig.js'
import { createRep } from '../util/agentUtil.js'

export default class AgentController {
  constructor($interval, AgentService, ToastService) {
    'ngInject'
    this.interval = $interval
    this.intervalMilliSeconds = 15000
    this.intervalPromise = null
    this.isIntervalActive = false

    this.title = 'Live Agent Report'
    this.AS = AgentService
    this.TS = ToastService

    this.state = {
      repInfo: repInfoConfig,
    }

    this.searchRep = ({ searchText }) => {
      this.searchText = searchText
    }
  }

  $onInit() {
    this.state.selected = {
      company: this.company,
      campaign: this.campaign,
      realm: this.realm,
    }
    this.callInterval()
  }

  $onDestroy() {
    this.cancelInterval()
  }

  pauseOrPlayInterval() {
    if (this.isIntervalActive) {
      this.cancelInterval()
      this.isIntervalActive = false
    } else {
      this.callInterval()
    }
  }

  cancelInterval() {
    this.interval.cancel(this.intervalPromise)
  }

  callInterval() {
    this.isIntervalActive = true
    this.getAgentReports()

    this.intervalPromise = this.interval(() => {
      this.getAgentReports()
    }, this.intervalMilliSeconds)
  }

  getAgentReports() {
    const companySlug = this.state.selected.company
      ? this.state.selected.company.slug
      : null
    const campaignSlug = this.state.selected.campaign
      ? this.state.selected.campaign.slug
      : null
    this.state.repInfo.loading = true

    if (companySlug && campaignSlug) {
      this.AS.getLiveAgentReports(companySlug, campaignSlug)
        .then(agents => {
          // Setting repInfoData using mapper from createRep function of agentUtil
          this.state.repInfo.repInfoData = agents.results.map(createRep)
          // Setting false the loading state
          this.state.repInfo.loading = false
          // Setting last time updated variable
          this.lastTimeUpdated = 'Last Sync: ' + moment().format('h:mm:ss A')
        })
        .catch(err => {
          this.TS.show({
            text: `Problem fetching ${this.title}`,
          })
          console.error('Live Agent Error: ', err)
        })
    }
  }
}
