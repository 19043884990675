import ProspectHealthController from './controller/ProspectHealthController.js'
import './prospectHealth.scss'
const template = require('./prospectHealth.tpl.pug')

const prospectHealth = {
  template: template(),
  controller: ProspectHealthController,
  bindings: {
    key: '<',
    metric: '<'
  }
}

export default prospectHealth
