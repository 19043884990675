const identifiers = {
  key: 'schedulerSpeed',
  type: 'dataDog',
}

const formatting = {
  format: {
    title: 'Scheduler Speed',
    append: '/sec',
  }
}

const keys = {
  componentKeys: [ 'dialer_metrics' ],
  healthKey: 'performance_health',
}

const schedulerSpeed = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { schedulerSpeed }
