import './manage.scss'
import ManageController from './controller/ManageController.js'
import template from './manage.tpl.pug'

const manage = {
  template: template(),
  controller: ManageController,
  bindings: {
    user: '<',
  },
}

export default manage
