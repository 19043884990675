import { pick } from 'ramda'
import ARD from '../../../../dialogs/audio-recording/audioRecording.dialog.js'
import PDD from '../../../../dialogs/audio-dialog/audio.dialog.js'
import BaseAudioController from '../../common/controller/BaseAudioController.js'
import {
  unrecordedConfig,
  rerecordConfig,
  recordedConfig,
  addButtonActions,
} from '../config/prospectAudioTables.js'

export default class ProspectAudioController extends BaseAudioController {
  constructor(
    $mdDialog,
    $sessionStorage,
    IdentityService,
    ManagementService,
    RoleStore,
    PitchService,
    ManageAudioService,
    ToastService,
    $rootScope,
    $scope,
  ) {
    'ngInject'
    super(...arguments)
    this.disableButton = true
    this.isSelectionApplied = false
    this.audioType = 'prospect'
    this.viewingDialog = PDD
    this.recordingDialog = ARD
    this.unrecordedTable = unrecordedConfig
    this.rerecordTable = addButtonActions(
      { MOVE_TO_RECORD: this.moveToRecord.bind(this) },
      rerecordConfig,
    )
    this.recordedTable = addButtonActions(
      { MOVE_TO_RERECORD: this.moveToRerecord.bind(this) },
      recordedConfig,
    )
    $scope.$watch('$ctrl.state.selected.pitchVersion', (newVal, oldVal) => {
      $rootScope.refreshOptions.isVisible = newVal ? true : false
    })
    Object.assign(this.state.selectOptions, {
      pitchVersions: [],
    })
  }

  selectCampaignCb(campaign) {
    this.isSelectionApplied = false
    Object.assign(this.state.selected, { pitchVersion: null })
    campaign.company = campaign.company.uuid
      ? campaign.company.uuid
      : campaign.company // ensure this is always a uuid string
    this.MS.populateEntityRelations(campaign, {
      entityName: 'campaign',
      populate: ['company'],
    })
      .then(this.PS.setupBase.bind(this.PS))
      .then(services => services.companyCampaign.one('').get())
      .then(pitch => {
        this.state.selectOptions.pitchVersions = [...pitch.versions]
      })
      .catch(err => {
        if (err.status === 403) {
          this.showAlert({
            title: 'No Pitch Available',
            text: `This voice hasn't been assigned to the selected campaign's pitch. Please contact a Perfect Pitch administrator to grant the correct access`,
          })
          return
        }
        this.state.selectOptions.pitchVersions = []
        this.TS.show({ text: 'Problem fetching pitch versions' })
        console.error(err)
      })
  }

  selectVersionCb(pitchVersionId) {
    this.state.loadingAudio = true
    this.setupVoiceService(pitchVersionId)
    const serviceKeys = ['recorded', 'rerecord', 'unrecorded']
    const service = pick(serviceKeys, this.voiceAudioService)
    this.MAS.fetchVoiceAudio({ service, audio: serviceKeys })
      .then(this.setAudio.bind(this))
      .finally(() => {
        this.state.loadingAudio = false
      })
  }

  setupVoiceService(pitchVersionId) {
    const voiceId = this.state.selected.voice
      ? this.state.selected.voice.uuid
      : this.user.uuid
    const serviceConfig = {
      voiceId,
      pitchVersionId,
      isProspectVoice: false,
    }
    this.voiceAudioService = this.PS.setupProspectVoice(serviceConfig)
  }

  fetchAudio() {
    this.selectVersionCb(this.state.selected.pitchVersion)
    this.disableButton = true
    this.isSelectionApplied = true
  }

  clearSelections() {
    Object.assign(this.state.selected, {
      voice: null,
      campaign: null,
      pitchVersion: null,
    })
  }
  canFetchAudio() {
    this.disableButton = false
    this.isSelectionApplied = false
  }
}
