// Helper directive for working with angular st-table module

export default function () {
  function linkFunc (scope, elem, attrs, ctrl) {
    'ngInject'
    return elem.bind('click', () => {
      return scope.$apply(() => {
        let tableState = ctrl.tableState()
        tableState.search.predicateObject = {}
        tableState.pagination.start = 0
        return ctrl.pipe()
      })
    })
  }
  return {
    restrict: 'A',
    require: '^stTable',
    link: linkFunc
  }
}
