import { map, reduce } from 'ramda'
import { combineRepObjects } from './repMerger.js'

function getRepHourMetrics(acc, rep) {
  acc[rep.rep] = rep
  return acc
}

function hourCombiner(acc, hour) {
  return function(metricKey) {
    const repMetricsForHour = reduce(
      getRepHourMetrics,
      {},
      hour.metrics[metricKey],
    )

    if (!acc[metricKey]) {
      acc[metricKey] = repMetricsForHour
    } else {
      acc[metricKey] = combineRepObjects(acc[metricKey], repMetricsForHour)
    }
  }
}

function dayReducer(acc, hour) {
  Object.keys(hour.metrics).forEach(hourCombiner(acc, hour)) // mutating acc here
  return acc
}

function dayMapper(day) {
  return {
    metrics: reduce(dayReducer, {}, day.hours),
    date: day.date,
  }
}

function calcMetricsByDay(stats) {
  return {
    days: map(dayMapper, stats.days),
    metrics: stats.metrics,
  }
}

export { calcMetricsByDay }
