import './booleanToggle.scss'
const template = require('./booleanToggle.tpl.pug')

const booleanToggle = {
  template: template(),
  bindings: {
    label: '@',
    value: '<',
    textfortrue: '@',
    textforfalse: '@',
    onToggle: '&',
  }
}

export default booleanToggle
