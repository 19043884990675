import angular from 'angular'

// Services
import ManageDidService from './component/services/ManageDidService'

// filters
// import filterCampaignsForCompany from './filters/filterCampaignsForCompany.js'

// components
import manageDid from './component/manageDid.component'

export default angular
  .module('portalApp.manage.did', [])
  .service('ManageDidService', ManageDidService)
  .component('manageDid', manageDid).name
