import { pipe, map, fromPairs, reduce } from 'ramda'
import { calculateMetrics } from 'util/calculate/metrics/calculateMetrics.js'

function buildRange(data) {
  const rangePackage = {
    metrics: data.callMetrics,
    stats: [...data.range.results],
  }
  return {
    range: calculateMetrics(rangePackage, 'campaign'),
    goals: data.goals,
    callMetrics: data.callMetrics,
    nonCallMetrics: data.nonCallMetrics,
    metricKeysArr: data.metricKeysArr,
  }
}

function buildBaseAndCompareRange(data) {
  const baseRangePackage = {
    metrics: data.callMetrics,
    stats: [...data.baseRange.results],
  }
  const compareRangePackage = {
    metrics: data.callMetrics,
    stats: [...data.compareRange.results],
  }
  return {
    baseRange: calculateMetrics(baseRangePackage, 'campaign'),
    compareRange: calculateMetrics(compareRangePackage, 'campaign'),
    goals: data.goals,
    callMetrics: data.callMetrics,
    nonCallMetrics: data.nonCallMetrics,
    metricKeysArr: data.metricKeysArr,
  }
}

const rangeMerger = ranges => (acc, key) => {
  acc.push({
    key: key,
    aggregate: ranges.baseRange[key].aggregate || false,
    format: ranges.baseRange[key].format,
    baseRange: ranges.baseRange[key],
    compareRange: ranges.compareRange[key],
  })
  return acc
}

function combineNonCallMetrics(nonCallMetrics) {
  const createMetricsObj = pipe(
    map(metric => [metric.key, metric]),
    fromPairs,
  )
  return function(mergedMetricsArr) {
    return createMetricsObj(mergedMetricsArr.concat(...nonCallMetrics))
  }
}

function metricOrderer(metricKeys) {
  return function(metrics) {
    return reduce(
      (acc, key) => {
        acc[key] = metrics[key]
        return acc
      },
      {},
      metricKeys,
    )
  }
}

const addGoals = goals => metric => {
  const goalsObj = reduce(
    (acc, goal) => {
      acc[goal.key] = goal
      return acc
    },
    {},
    goals,
  )

  if (goalsObj[metric.key] && goalsObj[metric.key].active) {
    metric.goal = goalsObj[metric.key]
  }

  return metric
}

function buildMetricsWithGoals(ranges) {
  return pipe(
    reduce(rangeMerger(ranges), []),
    combineNonCallMetrics(ranges.nonCallMetrics),
    metricOrderer(ranges.metricKeysArr),
    map(addGoals(ranges.goals)),
  )
}

function mergeRanges(ranges) {
  return {
    results: buildMetricsWithGoals(ranges)(Object.keys(ranges.baseRange)),
    callMetrics: ranges.callMetrics,
    dataDogMetrics: ranges.dataDogMetrics,
  }
}

export { buildRange, buildBaseAndCompareRange, mergeRanges }
