// @flow

import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../global/index/services/ToastService.js'
import type { FilterListConfig } from '../../../../common-components/filter-list/component/filterList.js'
import type { TitleBarButtonConfig } from '../../../../common-components/title-bar/component/titleBarComponent.js'

import {
  allPass,
  always,
  complement,
  cond,
  equals,
  is,
  reduce,
  T,
  where,
} from 'ramda'
import CPVD from '../../../dialogs/create-pitch-variables/createPitchVariables'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'

type DisplayVariable = {|
  key: string,
  value: string,
  pitchUsage: string,
|}

export default class EditSegmentVariablesController {
  PS: PitchService
  TS: ToastService
  $state: Object
  $mdDialog: Object
  $localStorage: Object
  $window: Object
  columnsConfig: FilterListConfig
  service: Object
  uuid: string // passed to component
  compUuid: string // passed to component
  compSlug: string // passed to component
  isRapidResponse: boolean // passed to component
  variables: Array<{ [key: string]: string }>
  buildDisplayVariables: (Array<[string, mixed]>) => Array<DisplayVariable>
  topBarButtons: TitleBarButtonConfig
  getRoute: ({ isRapidResponse: boolean, compSlug: string }) => string
  state: {|
    loading: boolean,
    pitchVariables: Array<DisplayVariable>,
    goBack?: {
      name: 'Global Segments' | 'Company Segments',
      route: string,
    },
  |}

  constructor(
    $mdDialog: Object,
    $state: Object,
    $window: Object,
    $localStorage: Object,
    PitchService: PitchService,
    ToastService: ToastService,
  ) {
    'ngInject'
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.$window = $window
    this.$localStorage = $localStorage
    this.TS = ToastService
    this.PS = PitchService
    this.topBarButtons = [
      {
        title: 'New Variable',
        icon: 'content:add',
        action: this.openVariablesDialog.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]

    // Build the data structure needed to display properly in table
    this.buildDisplayVariables = reduce(
      (acc: Array<DisplayVariable>, [key, value]: [string, string]) => {
        acc.push({
          key,
          value,
          pitchUsage: `{{global.${key}}}`,
        })
        return acc
      },
      [],
    )

    this.columnsConfig = [
      { title: 'Key', accessor: 'key', type: 'text' },
      { title: 'Value', accessor: 'value', type: 'text' },
      {
        title: 'Variable Usage',
        accessor: 'pitchUsage',
        type: 'text',
        copyable: true,
      },
      {
        title: 'Edit',
        type: 'menu',
        icon: 'editor:mode_edit',
        menu: [
          {
            title: 'Change Values',
            icon: 'action:swap_horiz',
            actionCb: variable => this.openVariablesDialog(variable),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: variable => this.deleteVariable(variable),
          },
        ],
      },
    ]

    this.state = {
      loading: true,
      pitchVariables: [],
    }
  }

  $onInit() {
    this.service = this._setupService({
      compSlug: this.compSlug,
      uuid: this.uuid,
      isRapidResponse: this.isRapidResponse || false,
    })
    this.getRoute = cond([
      [
        where({ isRapidResponse: equals(true), compSlug: equals('global') }),
        always('manage.globalRapidResponse.segments'),
      ],
      [
        where({
          isRapidResponse: equals(true),
          compSlug: allPass([is(String), complement(equals('global'))]),
        }),
        always(
          `manage.editCompany.rapidResponseSegments({slug: '${
            this.compSlug
          }', uuid: '${this.compUuid}'})`,
        ),
      ],
      [T, always('manage.globalPitchSegments')],
    ])
    this._getGoBackState()
    this._getVariables()
  }

  _getGoBackState() {
    this.state.goBack = {
      name: this.compSlug === 'global' ? 'Global Segments' : 'Company Segments',
      route: this.getRoute({
        isRapidResponse: this.isRapidResponse,
        compSlug: this.compSlug,
      }),
    }
  }

  _setupService(config: {|
    compSlug: string,
    uuid: string,
    isRapidResponse: boolean,
  |}) {
    const service = this.isRapidResponse
      ? this.PS.setupRapidResponseSegmentVariables(config.compSlug)
      : this.PS.setupPitchSegmentVariables(config.compSlug)
    if (service.company) {
      return service.company.one(config.uuid)
    } else {
      return service.global.one(config.uuid)
    }
  }

  _getVariables() {
    this.service
      .get()
      .then(segment => {
        this.variables = segment.plain().variables
        this.state.pitchVariables = this.buildDisplayVariables(
          Object.entries(this.variables),
        )
      })
      .catch(err => {
        this.TS.show({
          text: 'Error loading variables',
        })
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  openVariablesDialog(variable: DisplayVariable) {
    CPVD.locals = {
      isCreate: !variable,
      service: this.service,
      variables: this.variables,
      variable,
    }
    return this.$mdDialog.show(CPVD).then(re => {
      if (this.$state.params.key) {
        this.$localStorage.$default({
          editingDialog: JSON.stringify(re),
        })
        this.$window.history.back()
      } else {
        this.$state.go(this.$state.current.name, this.$state.params, {
          reload: true,
        })
      }
    })
  }

  deleteVariable(variable: DisplayVariable) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Variable',
      entityName: variable.key,
      identityEndpoint: this.service,
      entity: variable,
      routeWhenSuccessful: this.$state.current.name,
      isVariable: true,
      variables: this.variables,
    }
    this.$mdDialog.show(DED)
  }
}
