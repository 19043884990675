// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type ManagementService from '../../../services/ManagementService.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../../../global/index/services/ToastService.js'
import type ManageCampaignsService from '../../services/ManageCampaignsService.js'
import type { BaseState } from '../../../controllers/ManagementController.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TitleBarButtonConfig } from '../../../../common-components/title-bar/component/titleBarComponent.js'

import { containsRole, addColumns } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class ManageCampaignsController extends ManagementController {
  MCS: ManageCampaignsService
  filterConfig: FilterConfig
  topBarButtons: TitleBarButtonConfig
  state: BaseState

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
    ManageCampaignsService: ManageCampaignsService,
  ) {
    'ngInject'
    super(...arguments)
    this.MCS = ManageCampaignsService
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()

    this.entity = 'campaign'
    this.entityList = []
    this.editState = 'manage.editCampaign.settings'
    this.createState = 'manage.campaigns.create.campaignInfo'
    this.detailsDialog = 'campaignDetails'
    this.tableConfig = this.bindActionCallbacks(this.MCS.tableConfig)
    this.searchConfig = this.MCS.searchConfig
    this.filterConfig = this.MCS.filterConfig
    this.searchConfig.editable = containsRole(
      userRoles,
      this.MCS.defaultEditRoles,
    )
    this.topBarButtons = [
      {
        title: 'New Campaign',
        icon: 'content:add',
        action: this.createEntity.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.state.queryParams = this.getInitialParams(this.filterConfig)
    const addColumnsOptions = {
      userRoles,
      allowedRoles: ADMIN_GROUP,
      sliceIndex: 2,
      columns: this.tableConfig.columns,
      addColumns: this.tableConfig.optionalColumns,
    }

    this.tableConfig.columns = addColumns(addColumnsOptions)
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }
}
