import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import { ADMIN_GROUP } from 'permissions.js'
import formConfig from '../config/formConfig.js'

export default class EditCampaignRouterController {
  constructor(
    $state,
    $mdDialog,
    ManagementService,
    ToastService,
    RoleStore,
    ManageRouterService,
  ) {
    'ngInject'

    this.TS = ToastService
    this.MRS = ManageRouterService
    this.$mdDialog = $mdDialog
    this.ADMIN_GROUP = ADMIN_GROUP
    this.$state = $state
  }

  $onInit() {
    this.state = {
      backToRoute: {
        name: 'Routers',
        route: `manage.editCampaign.dataPosting({slug: '${
          this.campaign.slug
        }', uuid: '${this.campaign.uuid}'})`,
      },
      formOptions: formConfig,
      showRouteDetails: false,
      showRoutes: true,
      routes: [],
    }
    this.router = this.normalizeRouter(this.router)
    this.editableRouter = Object.assign({}, this.router)
    this.getRoutes({
      compSlug: this.campaign.company.slug,
      campSlug: this.campaign.slug,
      routerId: this.router.uuid,
    })
  }

  createRoute(router, campaign) {
    this.$state.go('.create.routeInfo', { router, campaign })
  }

  updateRouter(entity) {
    this.MRS.updateRouter({
      compSlug: this.campaign.company.slug,
      campSlug: this.campaign.slug,
      router: entity,
    })
      .then(updatedRouter => {
        const normalizedRouter = this.normalizeRouter(updatedRouter)
        this.router = Object.assign({}, normalizedRouter, {
          campaign: this.campaign,
        })
        this.state.setPristine = true
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error updating router',
        })
      })
  }

  normalizeRouter(router) {
    if (Array.isArray(router.route_continue)) return router
    const continueList = router.route_continue.split(',')
    router.route_continue = continueList
    return Object.assign(router, { route_continue: continueList })
  }

  toggleSection(sectionId) {
    this.state[sectionId] = !this.state[sectionId]
  }

  getRoutes(routerDetails) {
    this.state.loadingRoutes = true
    this.MRS.getRouterRoutes(routerDetails)
      .then(routes => {
        this.state.routes = routes
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Problem fetching campaign routing details',
        })
      })
      .finally(() => {
        this.state.loadingRoutes = false
      })
  }

  toggleActive(bool) {
    this.editableRouter.active = bool
  }

  cancelEdit(form) {
    this.editableRouter = Object.assign({}, this.router)
    form.$setPristine()
  }

  deleteRouter(entity) {
    const DRD = deleteEntityDialog
    const compSlug = this.campaign.company.slug
    const campSlug = this.campaign.slug
    const endPoint = this.MRS.router.one(
      `${compSlug}/${campSlug}/${entity.uuid}`,
    )

    DRD.locals = {
      title: 'Delete Router',
      entityName: entity.name,
      identityEndpoint: endPoint,
      entity: entity,
      routeWhenSuccessful: 'manage.editCampaign.dataPosting',
      routeParams: { slug: this.campaign.slug, uuid: this.campaign.uuid },
    }
    this.$mdDialog.show(DRD)
  }
}
