// Manage Global Option Groups
import {
  manageGlobalOptionGroups,
  manageGlobalOptionGroupsConfig,
} from './manage.globalOptionGroups'

export default function manageGlobalOptionGroupsRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(manageGlobalOptionGroups, manageGlobalOptionGroupsConfig)
}
