import { ADMIN_GROUP } from 'permissions.js'
import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// manage realms view

const manageRealms = 'manage.realms'
const pageTitleConfig = {
  title: 'Manage Realms'
}

const manageRealmsConfig = {
  url: '/realms',
  component: 'manageRealms',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig)
  }
}

export { manageRealms, manageRealmsConfig }
