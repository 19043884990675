import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'scheduledProspects',
  type: 'prospect',
}

const formatting = {
  format: {
    title: 'Scheduled Prospects',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'prospect_health',
}
const scheduledProspects = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { scheduledProspects }
