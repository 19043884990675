//@flow

import type { Pitch } from 'types/entities.js'
import type { PitchDetailFields } from './types.js'

export default class EditPitchService {
  updatePitchDetails(
    fields: PitchDetailFields,
    service: Object,
  ): Promise<Pitch> {
    return service.patch(fields).then(pitch => pitch.plain())
  }
}
