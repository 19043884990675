// create campaign router view

const manageEditAccessListNode = 'manage.editAccessList.node'

const manageEditAccessListNodeConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageEditAccessListNode, manageEditAccessListNodeConfig }
