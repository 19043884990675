import BDD from '../../buy-did/buyDid.js'
import BDCD from '../buyDidConfirmation.js'

export default class BuyDidConfirmationController {
  constructor($mdDialog, DidListService, ToastService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.DidListService = DidListService
    this.TS = ToastService
    this.state = {
      showLoader: false,
    }
  }

  $onInit() {
    this.state.assigned = this.assigned
  }

  searchDid() {
    const uriData = {
      company: this.company,
      campaign: this.campaign,
      pool: this.pool.uuid,
    }
    this.searchButtonDisabled = true
    this.state.showLoader = true
    this.DidListService.buyMassiveDids
        .post(this.assigned, uriData)
        .then(response => {
          this.goToBuyDidConfirmationDialog(this.assigned)
        })
        .catch(err => {
          console.error(err)
          // if error is the callback of the POST call due to CORS call, then will move to confirmation dialog
          if ((err.config !== undefined || err.config !== null) &&
            err.config.method === "POST" && err.config.jsonpCallbackParam === "callback") {
            this.goToBuyDidConfirmationDialog(this.assigned)     
          } else {
            this.TS.show({
              text: 'Error while buying dids',
            })
          }
        })
        .finally(() => {
          this.searchButtonDisabled = false
          this.state.showLoader = false
        })
    /*
    BDD.locals = {
      pool: this.pool,
      company: this.company,
      campaign: this.campaign,
      refreshDidList: this.refreshDidList.bind(this),
    }
    this.$mdDialog.show(BDD)
    */
  }

  goToBuyDidConfirmationDialog(data) {
    BDCD.locals = {
      did: this.did,
      pool: this.pool,
      company: this.company,
      campaign: this.campaign,
      refreshDidList: this.refreshDidList.bind(this),
      assigned: this.assigned,
      didsPurchased: this.didsPurchased + this.assigned.number_did_to_purchase,
    }
    this.$mdDialog.show(BDCD)
  }

  close() {
    this.$mdDialog.destroy()
  }
}
