// create location team form

const createLocationTeam = 'manage.editLocation.createTeam.create'

const createLocationTeamConfig = {
  url: '/create',
  component: 'editLocationCreateTeam',
  resolve: {
    locationId: function ($transition$) {
      'ngInject'
      return $transition$.params().uuid
    }
  }
}

export { createLocationTeam, createLocationTeamConfig }
