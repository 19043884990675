import EditPhraseBookController from './controller/EditPhraseBookController.js'
const template = require('./editPhraseBook.component.tpl.pug')

const editPhraseBook = {
  controller: EditPhraseBookController,
  template: template(),
  bindings: {
    compSlug: '<?',
    phraseBook: '<',
    previousRoute: '<',
    service: '<',
  },
}

export default editPhraseBook
