import moment from 'moment'
import { always, cond, T } from 'ramda'
import { HIRE_DATE_FORMAT } from '../../../../utils/managementUtils.js'

export default class ManageUsersEditService {
  constructor($q, IdentityService, ManagementService, AuthService, RoleStore) {
    'ngInject'

    this.$q = $q
    this.IS = IdentityService
    this.MS = ManagementService
    this.AS = AuthService
    this.RS = RoleStore
  }

  updateUser(user) {
    const normUser = this.normalizeUser(user)
    return this.MS.updateEntity({ entity: 'user', data: normUser })
  }

  normalizeUser(user) {
    /*
    | due to the way that md-select is handling md-options ng-value attribute, having a true
    | null value is preventing selection on first click. The workaround is to
    | use the string 'null' an empty string would work as well but specifying
    | the intended value seems like a good tradeoff since we have to convert to
    | null anyway.
    */
    user.company = user.company === 'null' ? null : user.company
    user.team = user.team === 'null' ? null : user.team
    user.hire_date = user.hire_date
      ? moment(user.hire_date).format(HIRE_DATE_FORMAT)
      : null
    return user
  }

  fetchRelations() {
    return this.$q
      .all({
        teams: this.getTeams(),
        companies: this.getCompanies(),
        campaigns: this.getCampaigns(),
        groups: this.getGroups(),
      })
      .then(relation => relation)
  }

  getGroups() {
    return this.IS.Groups.list
      .get({ assignable: true })
      .then(groups => groups.plain())
      .catch(err => {
        console.error("Couldn't fetch groups", err)
      })
  }

  getTeams() {
    return this.IS.Team.list
      .get({ editable: true })
      .then(teams => teams.plain())
      .catch(err => {
        console.error("Couldn't fetch teams", err)
      })
  }

  getCompanies() {
    return this.IS.Company.list
      .get({ editable: true })
      .then(companies => companies.plain())
      .catch(err => {
        console.error("Couldn't fetch companies", err)
      })
  }

  getCampaigns() {
    return this.IS.Campaign.list
      .get({ editable: true, active: true })
      .then(camps => camps.plain())
      .catch(() => {
        console.error("Couldn't fetch campaigns")
      })
  }

  nextImpersonationState(user) {
    const recorderOnly = user =>
      this.RS.getAllRoles().RECORDER && user.groups.length === 1
    const getNextState = cond([
      [recorderOnly, always('manage.audioVoice.pitchAudio')],
      [T, always('gateway')],
    ])
    return { user, nextState: getNextState(user) }
  }

  impersonate(userId) {
    return this.IS.User.manage.impersonate
      .one(`${userId}`)
      .get()
      .then(resp => resp.auth_token)
      .then(this.AS.impersonate.bind(this.AS))
      .then(this.nextImpersonationState.bind(this))
  }
}
