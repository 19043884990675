import roundTo from 'round-to'
import { contains } from 'ramda'
import metricInfoDialog from '../../../../../dialogs/metric-info/metricInfoDialog.js'
import listGraphDialog from '../../../../dialogs/list-graph/listGraphDialog.js'

export default class ListMetricHealthController {
  constructor($mdDialog, MetricHealthService, ListMetricHealthService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.MHS = MetricHealthService
    this.LMHS = ListMetricHealthService
    this.createChartConfig = this.LMHS.createChartConfig.bind(this.LMHS)
    this.createPieChartConfig = this.LMHS.createPieChartConfig.bind(this.LMHS)
    this.state = {
      attempts: [],
      max: null,
      min: null,
      chartConfig: {},
    }
  }

  $onInit() {
    this.containsKey = contains(this.key)
    this.metric.key = this.key
    this.getMetricHealth(this.metric)
  }

  getMetricHealth(metric) {
    const maxMin = this.getMaxMin(this.metric.days)
    this.state.attempts = this.LMHS.addAttemptPercentages(metric)
    this.state.pieChartConfig = this.createPieChartConfig(
      this.state.attempts,
      metric,
      this.listSlug,
    )
    this.state.chartConfig = this.createChartConfig(
      this.listSlug,
      metric,
      maxMin.max,
      maxMin.min,
    )
  }

  getMaxMin(statArray) {
    const max = this.MHS.getValueMax(statArray)
    const min = this.MHS.getValueMin(statArray)
    return {
      max: isFinite(max) ? roundTo(max, 2) : 0,
      min: isFinite(min) ? roundTo(min, 2) : 0,
    }
  }

  openStatChart(event, chartConfig, pieChartConfig, metric, listSlug) {
    const LGD = listGraphDialog
    LGD.targetEvent = event
    LGD.locals = {
      title: metric.format.title,
      listSlug,
      pieChartConfig,
      chartConfig,
    }
    this.$mdDialog.show(LGD)
  }

  openInfo(ev, title, metricKey) {
    const MID = metricInfoDialog
    MID.targetEvent = ev
    MID.locals = {
      title: title,
      key: metricKey,
    }
    this.$mdDialog.show(MID)
  }
}
