// create campaign router view

const manageEditPhraseBookPhraseCreate = 'manage.editPhraseBook.phrase.create'

const manageEditPhraseBookPhraseCreateConfig = {
  params: {
    service: null,
  },
  component: 'createPhraseForm',
  resolve: {
    service: function($transition$) {
      'ngInject'
      return $transition$.params().service
    },
  },
}

export {
  manageEditPhraseBookPhraseCreate,
  manageEditPhraseBookPhraseCreateConfig,
}
