export default class QueueService {
  constructor (ReportService) {
    'ngInject'
    this.RS = ReportService
  }

  getLiveDialerQueueReports (campaignSlugs, realmSlugs) {
    const params = {}
    if (campaignSlugs) {
      params.campaigns = campaignSlugs
    }
    if (realmSlugs) {
      params.realms = realmSlugs
    }

    const url = '/live/dialer/queue/'
    return this.RS.reporting
      .one(url)
      .get(params)
      .then((data) => {
        return data.plain()
      })
  }
}
