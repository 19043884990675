import './micPermissionOverlay.scss'
import MicPermissionController from './controller/MicPermissionController.js'
const template = require('./micPermissionOverlay.component.tpl.pug')

const micPermissionOverlay = {
  controller: MicPermissionController,
  template: template(),
  bindings: {
    promptMessage: '@',
    dismissedMessage: '@',
    deniedMessage: '@',
    wasDismissed: '<',
    wasDenied: '<',
    askAgain: '&',
    backOut: '&',
  }
}

export default micPermissionOverlay

