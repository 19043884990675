import { hideInvalid } from '../../../../../utils/managementUtils.js'

export default class createLocationController {
  constructor($state, $timeout, IdentityService, ManageLocationsService) {
    'ngInject'

    this.$timeout = $timeout
    this.$state = $state
    this.IS = IdentityService
    this.MLS = ManageLocationsService
    this.hideInvalid = hideInvalid
    this.state = {
      creationError: false,
      creatingLocation: false,
    }
  }

  $onInit() {
    this.getUsers()
  }

  createNewLocation(location) {
    this.state.creatingLocation = true
    this.MLS
      .createNewLocation(location)
      .then(() => {
        this.$state.go('manage.locations', null, { reload: true })
      })
      .catch(err => {
        this.state.creationError = true
        this.$timeout(() => {
          this.state.creationError = false
        }, 5000)
        console.error('Error Creating Location:', err)
      })
      .finally(() => {
        this.state.creatingLocation = false
      })
  }

  getUsers() {
    this.IS.User.manage.list
      .get({ assignable: true })
      .then(usersList => {
        this.usersList = usersList.plain()
      })
      .catch(err => {
        console.error('COULD NOT FETCH USERS LIST: ', err)
      })
  }
}
