// @flow

import type { Did, DidPool } from 'types/entities.js'

export default class CreateDidController {
  isCreate: boolean
  pool: DidPool
  company: string
  campaign: string
  TS: Object
  $mdDialog: Object
  DIDS: Object
  state: {
    numbers: string,
    error: boolean,
    submitting: boolean,
  }

  constructor(
    $q: Object,
    $mdDialog: Object,
    $state: Object,
    $window: Object,
    IdentityService: Object,
    ToastService: Object,
  ) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.DIDS = IdentityService.DIDS
    this.TS = ToastService

    this.state = {
      numbers: '',
      submitting: false,
      error: false,
    }
  }

  createDid(numbers: string) {
    this.state.submitting = true
    const data = {
      numbers,
    }
    const uriData = {
      company: this.company,
      campaign: this.campaign,
      pool: this.pool.uuid,
    }
    this.DIDS.create
      .post(data, uriData)
      .then(createdDids => {
        if (createdDids.errors) {
          throw new Error(createdDids.errors)
        }
        this.close(createdDids)
      })
      .catch(err => {
        this.state.error = true
        console.error(err)
        this.TS.show({
          text: 'Error creating DID',
        })
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  close(createdDids: Array<Did>) {
    createdDids
      ? this.$mdDialog.hide({ wasCreate: this.isCreate, createdDids })
      : this.$mdDialog.cancel()
  }
}
