import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box, Grid, Input, TextField } from '@material-ui/core'
import AppSelect from '../../../../components/AppSelect.js'
import AppButton from '../../../../components/AppButton.js'
import AppTextField from '../../../../components/AppTextField.js'
import AppSnackbar from '../../../../components/AppSnackbar.js'
import { makeStyles, withStyles } from '@material-ui/core/styles'
const slugify = require('slugify')

const useStyles = makeStyles(theme => ({
  label: {
    color: '#999999',
    fontSize: 22,
    fontWeight: 500,
  },
}))

import { getBaseUrl } from '../../../../global/config/providers/restangularProvider/rest.js'
let Header
const API_BASE_URL = getBaseUrl(window.location.host, true)
const dialer = props => {
  const classes = useStyles()
  const [data, setData] = useState({ dialingparams: {} })
  const [campUUID, setCampUUID] = useState('')
  const [hasValue, setHasValue] = useState(0)
  const [form, setForm] = useState({
    dialer_interval: 10,
    call_ratio: 1,
    damper_threshold: 70,
    max_requested_calls_per_agent: 0,
    min_requested_calls_per_agent: 2,
  })

  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: '',
  })

  const list = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

  const handlerForm = (type, value) => {
    if (value === '' || value <= 0) {
      value = 0
    } else if (type !== 'dialer_interval') value = parseFloat(value)

    let temp = { ...form }
    temp[type] = value
    setForm(temp)
  }

  useEffect(() => {
    Header = {
      headers: {
        Authorization: `token ${JSON.parse(localStorage['ngStorage-ppToken'])}`,
      },
    }

    getCampaing()
  }, [])

  const getCampaing = () => {
    const url = window.location.href
    let splitUrl = url.split('/')
    console.log('uuID: ', splitUrl)
    setCampUUID(splitUrl[8])
  }

  useEffect(() => {
    let dialingparams = { ...form }
    setData({ dialingparams })
    handlerHasValues()
  }, [form])

  const handlerHasValues = () => {
    let values = 0
    Object.keys(form).forEach(item => {
      values += form[item]
    })
    setHasValue(values)
  }

  const handlerCancel = () => {
    setHasValue(0)
    setData({ dialingparams: {} })
    setForm({
      dialer_interval: 10,
      call_ratio: 1,
      damper_threshold: 70,
      max_requested_calls_per_agent: 0,
      min_requested_calls_per_agent: 2,
    })
  }

  const handlerSave = () => {
    axios
      .patch(`${API_BASE_URL}/identity/campaign/${campUUID}/ `, data, Header)
      .then(res => {
        let options = { ...snackOptions }
        options.text = 'Saved successfully!'
        options.open = true
        setSnackOptions(options)
        setTimeout(() => {
          let options = { ...snackOptions }
          options.open = false
          setSnackOptions(options)
        }, 2000)
      })
  }

  return (
    <Box className="dialer">
      <Grid container spacing={2}>
        <Grid item xs={6} className="mb-2">
          <AppSelect
            label="Dialer interval*"
            data={list}
            item={form.dialer_interval}
            displayName={'dialer_interval'}
            shrink
            labelClass={classes.label}
            onChange={value => handlerForm('dialer_interval', value)}
            helpText="Number of seconds between calling the dialer algorithm."
          />
        </Grid>
        <Grid item xs={6} className="mb-2">
          <AppTextField
            type="number"
            shrink
            labelClass={classes.label}
            value={form.max_requested_calls_per_agent}
            onChange={value =>
              handlerForm('max_requested_calls_per_agent', value)
            }
            label="Max requested calls per agent"
            helperText="Max number of calls / logged-in stations on a single dial request. 0 is no limit."
          />
        </Grid>
        <Grid item xs={6} className="mb-2">
          <AppTextField
            type="number"
            shrink
            labelClass={classes.label}
            value={form.call_ratio}
            onChange={value => handlerForm('call_ratio', value)}
            label="Call ratio*"
            helperText="The autodialer will call a new prospect whenever the ratio of outgoing calls to ready rep replogins falls below this value."
          />
        </Grid>
        <Grid item xs={6} className="mb-2">
          <AppTextField
            type="number"
            value={form.min_requested_calls_per_agent}
            onChange={value =>
              handlerForm('min_requested_calls_per_agent', value)
            }
            shrink
            labelClass={classes.label}
            label="Min requested calls per agent"
          />
        </Grid>
        <Grid item xs={6} className="mb-2">
          <AppTextField
            type="number"
            shrink
            labelClass={classes.label}
            value={form.damper_threshold}
            onChange={value => handlerForm('damper_threshold', value)}
            label="Damper Threshold"
            helperText="Number of seconds between calling the dialer algorithm."
          />
        </Grid>
        {hasValue > 0 && (
          <Grid item xs={12} className="m-3 text-center">
            <AppButton onClick={handlerCancel} width={'25%'}>Cancel</AppButton>
            <AppButton
              className="ml-2"
              type="Green"
              onClick={handlerSave}
              width={'25%'}>Save</AppButton>
          </Grid>
        )}
      </Grid>
      <AppSnackbar
        vertical="top"
        horizontal="right"
        open={snackOptions.open}
        text={snackOptions.text}
      />
    </Box>
  )
}

export default dialer
