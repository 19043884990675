import { MANAGE_GROUP } from 'permissions.js'

export default class EditPhraseBookService {
  constructor() {
    'ngInject'
  }

  getPhraseBookConfig() {
    return {
      entity: 'phrase',
      tableConfig: {
        trackBy: 'uuid',
        columns: [
          { title: 'Name', accessor: 'name' },
          { title: 'Phrase', accessor: 'phrase' },
          { title: 'Slug', accessor: 'slug' },
        ],
        optionalColumns: [{ title: 'UUID', accessor: 'uuid' }],
      },
      searchConfig: {
        editable: true,
        limit: 5,
        helperText: 'Try searching for a name, phrase',
        searchValue: '',
        searchResults: [],
      },
      filterConfig: [],
      entityList: [],
      defaultEditRoles: MANAGE_GROUP,
    }
  }
}
