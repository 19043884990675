import angular from 'angular'

import GlobalController from './controllers/GlobalController.js'
import portalAppGlobalConfig from '.././config/global.config.js'
import portalAppGlobalRouter from '.././router/global.router.js'

// run blocks
import requestIntercept from './run-blocks/requestIntercept.js'

// Services
import IdentityService from './api-services/IdentityService.js'
import ToastService from './services/ToastService.js'
import RealmService from './api-services/RealmService.js'
import ReportService from './api-services/ReportService.js'
import ProspectService from './api-services/ProspectService.js'
import PitchService from './api-services/PitchService.js'
import AccessListService from './api-services/AccessListService.js'
import DidListService from './api-services/DidListService.js'
import QueueService from './services/QueueService.js'

// Factories
import ApiConfig from './api-factories/ApiConfig'
import User from './api-factories/User.js'
import Lists from './api-factories/Lists.js'
import Login from './api-factories/Login.js'
import Logout from './api-factories/Logout.js'
import Campaign from './api-factories/Campaign.js'
import Company from './api-factories/Company.js'
import Report from './api-factories/Report.js'
import PortalSettings from './api-factories/PortalSettings.js'
import CampaignSettings from './api-factories/CampaignSettings.js'

// Directives
import dCollapseContent from './directives/d-collapse-content/dCollapseContent.js'
import dAutoFocus from './directives/d-auto-focus/dAutoFocus.js'
import stResetSearch from './directives/st-reset-search/stResetSearch.js'
import uniqueInput from './directives/unique-input/uniqueInput.js'
import compareTo from './directives/compare-to/compareTo.js'
import dBrace from './directives/d-brace/dBrace.js'
import sticky from './directives/sticky/sticky.js'
import addAttr from './directives/add-attr/addAttr'

// animations
import slideToggle from './animations/slideToggle.js'

// filters
import abbreviateNum from './filters/abbreviateNum.js'
export default angular
  .module('portalApp.global', [portalAppGlobalConfig, portalAppGlobalRouter])
  .run(requestIntercept)
  .filter('abbreviateNum', abbreviateNum)
  .service('IdentityService', IdentityService)
  .service('RealmService', RealmService)
  .service('ReportService', ReportService)
  .service('ProspectService', ProspectService)
  .service('PitchService', PitchService)
  .service('AccessListService', AccessListService)
  .service('DidListService', DidListService)
  .service('ToastService', ToastService)
  .service('QueueService', QueueService)
  .factory('ApiConfig', ApiConfig)
  .factory('Campaign', Campaign)
  .factory('Company', Company)
  .factory('User', User)
  .factory('Lists', Lists)
  .factory('Login', Login)
  .factory('Logout', Logout)
  .factory('Report', Report)
  .factory('PortalSettings', PortalSettings)
  .factory('CampaignSettings', CampaignSettings)
  .controller('GlobalController', GlobalController)
  .directive('dCollapseContent', dCollapseContent)
  .directive('dAutoFocus', dAutoFocus)
  .directive('stResetSearch', stResetSearch)
  .directive('uniqueInput', uniqueInput)
  .directive('compareTo', compareTo)
  .directive('dBrace', dBrace)
  .directive('stickyHeader', sticky)
  .directive('addAttr', addAttr)
  .animation('.slide-toggle', slideToggle).name
