// Edit Campaign view

const manageEditCompanyPhraseBook = 'manage.editCompanyPhraseBook'

const manageEditCompanyPhraseBookConfig = {
  url: '/company/{compSlug}/{compId}/phrase-book/edit/{phraseBookId}',
  params: {
    compSlug: null,
    compId: null,
    phraseBookId: null,
  },
  component: 'editPhraseBook',
  resolve: {
    compId: function($transition$) {
      'ngInject'
      return $transition$.params().compId
    },
    compSlug: function($transition$) {
      'ngInject'
      return $transition$.params().compSlug
    },
    phraseBookId: function($transition$) {
      'ngInject'
      return $transition$.params().phraseBookId
    },
    service: function(compSlug, phraseBookId, PitchService) {
      'ngInject'
      return PitchService.setupPhraseBook({
        phraseBookId: phraseBookId,
        companySlug: compSlug,
      })
    },
    phraseBook: function($transition$, compSlug, phraseBookId, service) {
      'ngInject'
      const phraseBook = $transition$.params().phraseBook
      if (phraseBook) return phraseBook
      return service.base
        .one()
        .get()
        .then(phraseBook => phraseBook.plain())
    },
    previousRoute: function($transition$, compSlug, compId) {
      'ngInject'
      return {
        state: 'manage.editCompany.phraseBooks',
        routerHref: `manage.editCompany.phraseBooks({slug: ${
          compSlug
        }, uuid: '${compId}'})`,
        params: { slug: compSlug, uuid: compId },
      }
    },
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Editing Phrase Book'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageEditCompanyPhraseBook, manageEditCompanyPhraseBookConfig }
