// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type ManagementService from '../../../services/ManagementService.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../../../global/index/services/ToastService.js'
import type ManageLocationsService from '../../services/ManageLocationsService.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TitleBarButtonConfig } from '../../../../common-components/title-bar/component/titleBarComponent.js'

import { containsRole, addColumns } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class ManageLocationsController extends ManagementController {
  MLS: ManageLocationsService
  filterConfig: FilterConfig
  topBarButtons: TitleBarButtonConfig

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
    ManageLocationsService: ManageLocationsService,
  ) {
    'ngInject'
    super(...arguments)
    this.MLS = ManageLocationsService
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()

    this.entity = 'location'
    this.entityList = []
    this.editState = 'manage.editLocation'
    this.createState = 'manage.locations.newLocation.create'
    this.tableConfig = this.bindActionCallbacks(this.MLS.tableConfig)
    this.searchConfig = this.MLS.searchConfig
    this.filterConfig = this.MLS.filterConfig
    this.searchConfig.editable = containsRole(
      userRoles,
      this.MLS.defaultEditRoles,
    )
    this.topBarButtons = [
      {
        title: 'New Location',
        icon: 'content:add',
        action: this.createEntity.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.state.queryParams = this.getInitialParams(this.filterConfig)
    const addColumnsOptions = {
      userRoles,
      allowedRoles: ADMIN_GROUP,
      sliceIndex: 2,
      columns: this.tableConfig.columns,
      addColumns: this.tableConfig.optionalColumns,
    }

    this.tableConfig.columns = addColumns(addColumnsOptions)
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }
}
