import React from 'react'
import { useState, useEffect } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { theme } from 'theme'
import AppButton from 'components/AppButton.js'
import AppSnackbar from 'components/AppSnackbar'
import { makeStyles } from '@material-ui/core/styles'
import AppModal from '../../../components/AppModal'
import { Grid, FormControlLabel, Checkbox, Typography, CircularProgress } from '@material-ui/core'
import LoaderReact from '../../../common-components/loader-overlay/loader-react'
import CreateLoader from 'common-components/create-loader/CreateLoader'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'
import AppTextField from 'components/AppTextField.js'
import "./CreateLocationReact.scss"
import axios from 'axios';
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest';
import MenuItem from '@material-ui/core/MenuItem';

const API_BASE_URL = 'https://api.perfectpitchtech.com'
const useStyles = makeStyles(theme => ({
  line: {
    width: 100 + '%',
    borderBottom: '1px solid #d5d5d5',
  },
  active: {
    '& span.MuiFormControlLabel-label': {
      color: '#777777',
      fontSize: 16,
    },
  },
}))
export default function CreateLocationReact(props) {
  const classes = useStyles()
  
  const [openModal, setOpenModal] = useState(props.$state.router.globals.current.component === "createLocation")
  const closeModal = () => {
    props.$state.go('manage.locations', null, { reload: true })
  }
  const [loading, setLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [snack, setSnack] = useState({ active: false, text: '' })
  const [confirmation, setConfirmation] = useState(false)
  const [button, setButton] = useState({ disabled: true, type: 'Gray' })
  

  const [optionLoading, setOptionLoading] = useState(true)
  const [name, setName] = useState('')
  const [leader, setLeader] = useState('')
  const [active, setActive] = useState(true)
  const [timer, setTimer] = useState(null)
  const [errMsg, setErrMsg] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [leaderText, setLeaderText] = useState('Please search for a location leader');
  
  useEffect(() => {
    if(searchTerm.length > 0){
      setOptionLoading(true)
      const delayDebounceFn = setTimeout( async () => {
        let searchRes =  await axios.get(`${API_BASE_URL}/identity/user/manage/list/?assignable=true&groups=10&groups=2&groups=4&groups=8&groups=6&search=${searchTerm}`)
        .then(({data}) => {
          let items = data.results.map(item => {
            return item
          })
          return items
        })
        setOptionLoading(false)
        setSearchResult(searchRes)
    }, 500)
    
    return () => clearTimeout(delayDebounceFn)
  
    }else{
      setLeaderText('Please search for a location leader')
      setLeader('')
    }
   

  }, [searchTerm])
  
  const handleActive = event => setActive(event.target.checked)

  const handlerItemClick =  (item) => {
   setOptionLoading(false)
   setLeader(item.uuid)
   setSearchResult([])
   setSearchTerm(`${item.username} | ${item.first_name} ${item.last_name}`)
   setLeaderText(`${item.username} is selected as Location Leader`)
  }
  const closeConfirmation = () => {
    props.$state.go('manage.locations', null, { reload: true })
  }
  const handleSubmit  = () => {
    setSaveLoading(true)
    axios.post(`${API_BASE_URL}/identity/location/create/`, {
      "leader": leader,
      "name": name,
      "active": active
    })
    .then(({data}) => {
      setSaveLoading(false)
      setConfirmation(true)
    })
    .catch(err => {
      setSaveLoading(false)
      setConfirmation(false)
      if (err.response) {
        let errText = 'There is a problem adding location. Please try again'
        if(err.response.data.name){
          setErrMsg({ ...errMsg, name: err.response.data.name })
        }
        if(err.response.data.leader){
          setErrMsg({ ...errMsg, leader: 'Invalid leader. Please select a leader again' })
        }
        
        setSnack({
          active: true,
          text: errText,
        })
      }
    })
  }
  const checkForm = () => {
    if(name.length > 0){
      if(searchTerm.length > 0){
        if(!leader.length > 0){
          return true
        }
      }
      return false
    }else{
      return true
    }
  }
  const createAnother = () => {
    setErrMsg({})
    setName('')
    setLeader('')
    setSearchTerm('')
    setSearchResult([])
    setActive(true)
    setConfirmation(false)
    setLoading(false)
    
    
  }
  const closeSnackbar = () => {
    setSnack({ active: false, text: '' })
  }
  return (
    <MuiThemeProvider theme={theme}>
      <AppModal
       modalWith={"30%"}
       showModal={openModal}
       hideModal={closeModal}
       title="Add New Location"
      >
        <React.Fragment>
          {
            loading ? 
              <LoaderReact message='Please wait a moment' />
            :
              saveLoading ? 
              (
                <CreateLoader
                  message="One moment. We're creating the new Location..."
                  handleCancel={() => {
                    props.$state.go('manage.locations', null, { reload: true })
                  }}
                />
              )
            :
              confirmation ?
              (
                <ConfirmationDialog
                  message={`You have added New Location`}
                  SubmitTxt="ADD ANOTHER"
                  handleCancel={()=>{
                    props.$state.go('manage.locations', null, { reload: true })
                  }}
                  handleCreateAnother={createAnother}
                  removeClose={true}
                />
              )
            :
             (
              <React.Fragment>
                <Grid container className="p-3" spacing={4}>
                  <Grid item xs={12}>
                    <AppTextField
                      required
                      value={name}
                      label="Location name"
                      onChange={value => {
                        setName(value)
                      }}
                      error={errMsg.name ? true : false}
                      helperText={
                        errMsg.name ? errMsg.name : 'Please enter a location name'
                      }
                    />
                  </Grid>
                    
                  <Grid item xs={12}>
                    <AppTextField
                      value={searchTerm}
                      label="Location Leader"
                      onChange={value => {
                        setSearchTerm(value)
                      }}
                      error={errMsg.leader ? true : false}
                      helperText={
                        errMsg.leader ? errMsg.leader : leaderText
                      }
                    />
                    
                    {
                      searchTerm.length > 0 ?
                      <div>
                        
                        {
                          optionLoading ?  
                          <div className={'menu-loading'}>
                            <CircularProgress size={15} style={{ color: '#1194f6' }} />
                            &nbsp;&nbsp;&nbsp;
                            <b style={{ color: '#777' }}>Please wait a moment...</b>
                          </div>
                          :  
                          (
                            <div className={searchResult.length > 0 ? 'menu-item' : ''}>
                              {
                                searchResult.length > 0 ?
                                <React.Fragment>
                                {
                                  searchResult.map(item => {
                                    return (
                                      <MenuItem key={item.uuid} onClick={()=>{
                                        handlerItemClick(item)
                                      }}>{item.username} | {item.first_name} {item.last_name}</MenuItem>
                                    )
                                  })
                                }
                                </React.Fragment>
                                :
                                searchResult.length === 0 && leader.length === 0 ?
                                <div className={'menu-loading'}>
                                  <b style={{ color: '#777' }}>No result...</b>
                                </div>
                                : null
                              }
                            </div>
                          )
                        }
                        

                      </div>
                      : null
                    }
                  </Grid>
                  
                  <Grid item xs={12}>
                    <FormControlLabel
                      className={classes.active}
                      control={
                        <Checkbox
                          checked={active}
                          onChange={handleActive}
                          value={active}
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className="text-center mt-1">
                  <AppButton onClick={closeConfirmation} width={165}>
                    CANCEL
                  </AppButton>
                  <AppButton
                    onClick={handleSubmit}
                    className="ml-2"
                    type={checkForm() ? "Gray" : "Green"}
                    width={165}
                    disabled={checkForm()}
                  >
                    SAVE
                  </AppButton>
              </Grid>
              </React.Fragment>
             )
          }
         
        </React.Fragment>
        <AppSnackbar
          vertical="top"
          horizontal="right"
          open={snack.active}
          close={closeSnackbar}
          text={snack.text}
        />
        <br/>
      </AppModal>
      
    </MuiThemeProvider>
  )
}
