import React, { useState, useEffect } from 'react'
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'
import { PulseLoader } from 'react-spinners'
import '../manageDnc.scss'
import M from 'materialize-css'
import axios from 'axios'
import ModalAddItem from './dnc-modalAddItem'
import Paginator from '../../../components/Paginator'
import Icon from '@material-ui/core/Icon'
const moment = require('moment')

//coment new tested
const Spinner = () => {
  return (
    <div className="SyncLoader">
      <PulseLoader sizeUnit={'px'} size={10} color={'#9e9e9e'} loading={true} />
    </div>
  )
}

const API_BASE_URL = getBaseUrl(window.location.host, true)
let Header

const manageDnc = props => {
  const [data, setData] = useState({})
  const [searchResult, setSearchResult] = useState({})
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedDnc, setSelectedDnc] = useState({})
  const modalAddItem = React.useRef()
  const [hasFiles, setHasFiles] = useState(0)
  const [isUploading, setIsUploading] = useState(true)

  const getData = () => {
    setLoading(true)
    axios
      .get(`${API_BASE_URL}/dnc/list/?page_size=10`, {
        headers: {
          Authorization: `token ${JSON.parse(
            localStorage['ngStorage-ppToken'],
          )}`,
        },
      })
      .then(res => {
        setLoading(false)
        setData(res.data)
        setSearchResult(res.data)
        let elems = document.querySelectorAll('.dropdown-trigger')
        M.Dropdown.init(elems)
      })
  }

  const searchDnc = () => {
    axios
      .get(`${API_BASE_URL}/dnc/list/?search=${search}&page_size=10`, {
        headers: {
          Authorization: `token ${JSON.parse(
            localStorage['ngStorage-ppToken'],
          )}`,
        },
      })
      .then(res => {
        setLoading(false)
        setSearchResult(res.data)
        let elems = document.querySelectorAll('.dropdown-trigger')
        M.Dropdown.init(elems)
      })
  }

  const initModals = () => {
    let modal2 = document.querySelectorAll('#modal2')
    M.Modal.init(modal2, {
      onCloseEnd: () => {
        setSelectedDnc({})
        setHasFiles(0)
        setIsUploading(true)
      },
      onOpenEnd: () => console.log('modal 2'),
    })
  }

  useEffect(() => {
    Header = {
      headers: {
        Authorization: `token ${JSON.parse(localStorage['ngStorage-ppToken'])}`,
      },
    }
  }, [])

  useEffect(() => {
    searchDnc()
  }, [search])

  return (
    <div className="container-fluid managednc">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
      />
      <br />
      <br />
      <div className="row">
        <div className="col s12">
          <span className="grey-text" style={{ fontSize: 1.5 + 'em' }}>
            DNC
          </span>
          <a
            className="btn-flat shadow-0 right text-white bg-success modal-trigger"
            data-target="modal1"
          >
            <i className="material-icons left">add</i>
            DNC List
          </a>
        </div>
      </div>
      <br />
      <div
        className="z-depth-1 center-align"
        style={{ minHeight: 360, borderRadius: 4 }}
      >
        <div className="row">
          <div className=" input-field col s12">
            <input
              onChange={e => setSearch(e.target.value)}
              placeholder="Search DNC List"
              value={search}
              type="text"
            />
            <i className="material-icons btn-searchDnc">search</i>
          </div>
        </div>
        {loading && <Spinner></Spinner>}
        {!loading && (
          <table className="striped">
            <thead className="table-header">
              <tr>
                <th className="pl-2">
                  <a className="cursor-pointer ">
                    <span className="left">Name</span>
                    <i className="material-icons left">keyboard_arrow_down</i>
                  </a>
                </th>
                <th>Globals</th>
                <th>Get updates</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {'results' in searchResult &&
                searchResult.results.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        paddingLeft: 21,
                        textDecoration: 'underline',
                      }}
                    >
                      <a className="text-grey">{item.name}</a>
                    </td>
                    <td>
                      <div
                        className={
                          `edge ` + (item.is_global ? 'bg-default' : 'bg-red')
                        }
                      >
                        {item.is_global && <span>Yes</span>}
                        {!item.is_global && <span>No</span>}
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          `edge ` + (item.is_static ? 'bg-default' : 'bg-red')
                        }
                      >
                        {item.is_global && <span>Yes</span>}
                        {!item.is_global && <span>No</span>}
                      </div>
                    </td>
                    <td className="right pr-2">
                      <a
                        className="dropdown-trigger"
                        data-target={`drop` + index}
                      >
                        <i
                          className="material-icons"
                          style={{ fontSize: 16, color: '#444851' }}
                        >
                          settings
                        </i>
                      </a>

                      <ul
                        id={`drop` + index}
                        className="dropdown-content drop-custom"
                      >
                        <li>
                          <a
                            style={{ fontSize: 16, color: '#4a4a4a' }}
                            onClick={() => modalAddItem.current.editDnc(item)}
                            data-target="modal1"
                            className="modal-trigger"
                          >
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ fontSize: 16, color: '#4a4a4a' }}
                            onClick={() => setSelectedDnc(item)}
                            data-target="modal2"
                            className="modal-trigger"
                          >
                            Upload
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      {'results' in searchResult && (
        <Paginator
          colection={searchResult}
          setColection={data => setSearchResult(data)}
          urlPage={'/dnc/company/global/list/?page=PAGE&page_size=10'}
        />
      )}
      <ModalUpload
        hasFiles={hasFiles}
        isUploading={isUploading}
        selectedDnc={selectedDnc}
        setSelectedDnc={() => setSelectedDnc({})}
        setHasFiles={value => setHasFiles(value)}
        setIsUploading={value => setIsUploading(value)}
      />
      <ModalAddItem
        ref={modalAddItem}
        selectedDnc={selectedDnc}
        setSelectedDnc={item => setSelectedDnc(item)}
        getData={getData}
        initModals={initModals}
      />
    </div>
  )
}

const ModalUpload = props => {
  const [fileInput] = useState(React.createRef())
  const [modalLoading, setModalLoading] = useState(false)
  const [data, setData] = useState([])
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [fileError, setFileError] = useState(false)

  useEffect(() => {
    console.log('modal upload')

    let fromDate = document.querySelector('.datepickerFrom')
    M.Datepicker.init(fromDate, {
      autoClose: true,
      onSelect: e => setFromDate(moment(e).toISOString()),
    })
    let toDate = document.querySelector('.datepickerTo')
    M.Datepicker.init(toDate, {
      autoClose: true,
      onSelect: e => setToDate(moment(e).toISOString()),
    })
  }, [data])

  const handleFile = () => {
    props.setHasFiles(1)
    let fileName = fileInput.current.files[0].name
    var file_extension = fileName.split('.').pop()
    if (file_extension !== 'csv') setFileError(true)
    else setFileError(false)
  }

  useEffect(() => {
    if (fromDate === null) return
    filterByDate()
  }, [fromDate, toDate])

  const clearFilters = () => {
    setFromDate(null)
    setToDate(null)
    document.querySelector('#datepickerFrom').value = ''
    document.querySelector('#datepickerTo').value = ''
    M.updateTextFields()
    getHistoryList()
  }

  const filterByDate = () => {
    let url = `/dnc/company/${props.selectedDnc.company_slug}/list/${props.selectedDnc.uuid}/bulk_upload/?datetime_processed__gte=${fromDate}`
    if (toDate !== null) url += `&datetime_processed__lt=${toDate}`

    setModalLoading(true)
    axios.get(`${API_BASE_URL}${url}&page_size=10`, Header).then(res => {
      console.log(res.data)
      setModalLoading(false)
      setData(res.data)
    })
  }

  const uploadCSV = () => {
    setModalLoading(true)
    let bodyFormData = new FormData()
    bodyFormData.append('csv_file ', fileInput.current.files[0])
    axios
      .post(
        `${API_BASE_URL}/dnc/company/${props.selectedDnc.company_slug}/list/${props.selectedDnc.uuid}/bulk_upload/`,
        bodyFormData,
        Header,
      )
      .then(res => {
        setModalLoading(false)
        let toastHTML =
          '<span>File uploaded successfully!</span><button class="btn-flat toast-action"><i class="material-icons">check</i></button>'
        M.toast({ html: toastHTML })
        getHistoryList()
      })
  }

  const getHistoryList = () => {
    setData([])
    props.setHasFiles(0)
    props.setIsUploading(false)
    setModalLoading(true)
    axios
      .get(
        `${API_BASE_URL}/dnc/company/${props.selectedDnc.company_slug}/list/${props.selectedDnc.uuid}/bulk_upload/?page_size=10`,
        Header,
      )
      .then(res => {
        console.log(res.data)
        setModalLoading(false)
        setData(res.data)
      })
  }

  return (
    <div
      id="modal2"
      className={
        'modal modalUpload ' + (props.isUploading ? '' : 'historyStyle')
      }
    >
      {props.isUploading && (
        <div>
          <div className="modal-header p-2 bg-blue white-text center-align">
            <b>Upload numbers</b>
          </div>
          <div className="modal-content">
            {modalLoading && (
              <div className="progress">
                <div className="indeterminate"></div>
              </div>
            )}
            <div className="row">
              <div className="col s12 center-align">
                <button className="btn btn-flat btn-tab activeTab">
                  UPLOAD FILE
                </button>
                <button
                  onClick={getHistoryList}
                  className="btn btn-flat btn-tab"
                >
                  HISTORY
                </button>
              </div>
            </div>{' '}
            <br />
            <div className="row">
              <div className="col s12 center-align">
                <div className="file-field input-field">
                  <label className="label-file-input">SCV File</label>
                  <div className="btn input-file btn-flat grey lighten-5">
                    <span>
                      {' '}
                      <i className="material-icons left">file_upload</i> Choose
                      File
                    </span>
                    <input
                      ref={fileInput}
                      accept=".csv"
                      onChange={handleFile}
                      type="file"
                    />
                  </div>
                  <div className="file-path-wrapper pl-0">
                    <input
                      onChange={e => handleFile(e)}
                      className="file-path validate"
                      type="text"
                      placeholder="No File Choosen"
                    />
                  </div>
                  {!fileError && (
                    <span className="helper-text left">
                      A plain text CSV file with a single column: number
                    </span>
                  )}
                  {fileError && (
                    <span className="helper-text red-text text-darken-1 left">
                      File must have .csv extention
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-footer"
            style={{ height: 80, textAlign: 'center' }}
          >
            <a className="modal-close btn-flat btn-cancel grey lighten-3 center-align pl-2 pr-2">
              CANCEL
            </a>{' '}
            &nbsp; &nbsp; &nbsp;
            <a
              disabled={props.hasFiles === 0 || fileError}
              onClick={uploadCSV}
              className="btn shadow-0 bg-success center-align btn-save"
            >
              UPLOAD
            </a>
          </div>
        </div>
      )}
      {!props.isUploading && (
        <div>
          <div className="modal-header p-2 bg-blue white-text center-align">
            <b>Upload numbers</b>
          </div>
          <div className="modal-content">
            {modalLoading && (
              <div className="progress">
                <div className="indeterminate"></div>
              </div>
            )}
            <div className="row">
              <div className="col s12 center-align">
                <button
                  onClick={() => props.setIsUploading(true)}
                  className="btn btn-flat btn-tab"
                >
                  UPLOAD FILE
                </button>
                <button className="btn btn-flat btn-tab activeTab">
                  HISTORY
                </button>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6">
                <i className="material-icons icon-input grey-text">
                  <Icon>calendar_today</Icon>
                </i>
                <input
                  id="datepickerFrom"
                  type="text"
                  className="datepickerFrom"
                ></input>
                <label htmlFor="datepickerFrom">From date</label>
              </div>
              <div className="input-field col s6">
                <i className="material-icons icon-input grey-text">
                  <Icon>calendar_today</Icon>
                </i>
                <input
                  disabled={fromDate === null}
                  id="datepickerTo"
                  type="text"
                  className="datepickerTo"
                ></input>
                <label htmlFor="datepickerTo">To date</label>
              </div>
              <div className="col s12 center-align">
                {fromDate !== null && (
                  <button onClick={clearFilters} className="btn-flat btn-sm">
                    clear filter
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col s12 center-align">
                <table className="striped">
                  <thead style={{ backgroundColor: '#f2f2f280' }}>
                    <tr style={{ color: '#444851' }}>
                      <th className="pl-2">Date</th>
                      <th>Attempted</th>
                      <th>Valid</th>
                      <th>Invalid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr></tr>
                    {'results' in data &&
                      data.results.map((item, index) => (
                        <tr key={index}>
                          <td className="pl-2">
                            {item.datetime_start_process !== null &&
                              moment(item.datetime_start_process).format(
                                'MMM D, YYYY',
                              )}
                            {item.datetime_start_process === null && (
                              <span className="grey-text">
                                No date process yet
                              </span>
                            )}
                          </td>
                          <td>
                            <span className="left mr-3">
                              {item.valid_numbers_count +
                                item.invalid_numbers_count}
                            </span>
                            {item.csv_file !== null && (
                              <a
                                href={item.csv_file}
                                style={{ color: '#444851' }}
                              >
                                <i className="material-icons">file_download</i>
                              </a>
                            )}
                          </td>
                          <td>
                            <span className="left mr-3">
                              {item.valid_numbers_count}
                            </span>
                            {item.valid_csv_file !== null && (
                              <a
                                href={item.valid_csv_file}
                                style={{ color: '#444851' }}
                              >
                                <i className="material-icons">file_download</i>
                              </a>
                            )}
                          </td>
                          <td>
                            <span className="left mr-3">
                              {item.invalid_numbers_count}
                            </span>
                            {item.invalid_csv_file !== null && (
                              <a
                                href={item.invalid_csv_file}
                                style={{ color: '#444851' }}
                              >
                                <i className="material-icons">file_download</i>
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {'results' in data && (
              <Paginator
                colection={data}
                setColection={data => setData(data)}
                urlPage={`/dnc/company/${props.selectedDnc.company_slug}/list/${props.selectedDnc.uuid}/bulk_upload/?page=PAGE&page_size=10`}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default manageDnc
