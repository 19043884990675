import ListMetricHealthController from './controller/ListMetricHealthController.js'
import './listMetricHealth.scss'
const template = require('./listMetricHealth.tpl.pug')

const listMetricHealth = {
  template: template(),
  controller: ListMetricHealthController,
  bindings: {
    metric: '<',
    key: '<',
    listSlug: '<'
  }
}

export default listMetricHealth
