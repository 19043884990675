import { findIndex, propEq } from 'ramda'
import CPSD from '../../../../dialogs/create-pitch-segments/createPitchSegments.js'
import DED from '../../../../dialogs/delete-entity/deleteEntity.js'
import {
  populatePitchObjects,
  pitchCollectionEqual,
} from '../../../utils/pitchObjects.js'

export default class PitchSegmentsController {
  constructor($mdDialog, $q, $state, $localStorage) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.$q = $q
    this.$localStorage = $localStorage
    this.state = {
      activeSegments: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      globalSegments: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      companySegments: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      search: {
        activeSegments: '',
        companySegments: '',
      },
      endpoint: 'segments',
    }
    this.globalSegmentFilter = this.createSegmentFilter('globalSegments')
    this.activeSegmentFilter = this.createSegmentFilter('activeSegments')
    this.companySegmentFilter = this.createSegmentFilter('companySegments')
    this.pitchSegmentsEqual = pitchCollectionEqual
    this.$state = $state
  }

  $onChanges(bindings) {
    // wait for parent component to provide services and initial active segments
    if (
      bindings.companyService &&
      bindings.globalService &&
      bindings.segments.isFirstChange()
    ) {
      this.getSegments(this.companyService, this.globalService, this.segments)
    } else if (bindings.segments) {
      this.state.activeSegments.initial = [
        ...this.state.activeSegments.editable,
      ]
    }
  }

  getSegments(companyService, globalService, pitchSegments) {
    this.$q
      .all({
        company: companyService.get(),
        global: globalService.get(),
      })
      .then(({ company, global }) => {
        return {
          company: company.plain(),
          global: global.plain(),
        }
      })
      .then(populatePitchObjects(pitchSegments, 'Segment'))
      .then(this.setAllSegments.bind(this))
      .finally(this.isEditingDialog())
  }

  setAllSegments({
    activeSegments = [],
    companySegments = [],
    globalSegments = [],
  }) {
    Object.assign(this.state, {
      activeSegments: {
        initial: activeSegments,
        editable: [...activeSegments],
      },
      companySegments: {
        initial: companySegments,
        editable: [...companySegments],
      },
      globalSegments: {
        initial: globalSegments,
        editable: [...globalSegments],
      },
    })
  }

  openSegmentMenu($mdMenu, ev) {
    $mdMenu.open(ev)
  }

  isEditingDialog() {
    if (this.$localStorage.editingDialog) {
      const segment = JSON.parse(this.$localStorage.editingDialog)
      this.openSegmentDialog(this.companyService, segment)
      delete this.$localStorage.editingDialog
    }
  }

  openSegmentDialog(service, segment) {
    const company = this.campaign
    const endpoint = this.state.endpoint
    const locals = {
      isCreate: !segment,
      isRapidResponse: false,
      service: service,
      segment,
      company,
      endpoint,
    }
    CPSD.locals = locals
    this.$mdDialog.show(CPSD).then(({ segment, wasCreate }) => {
      if (wasCreate) {
        this.state.companySegments.editable.push(segment)
        this.state.companySegments.initial.push(segment)
      } else {
        this.updateEditedSegment(segment)
      }
    })
  }

  updateEditedSegment(segment) {
    const activeSegmentIndex = findIndex(
      propEq('uuid', segment.uuid),
      this.state.activeSegments.editable,
    )
    const companySegmentIndex = findIndex(
      propEq('uuid', segment.uuid),
      this.state.companySegments.editable,
    )
    if (activeSegmentIndex > -1) {
      this.state.activeSegments.editable.splice(activeSegmentIndex, 1, segment)
      this.state.activeSegments.initial.splice(activeSegmentIndex, 1, segment)
    } else if (companySegmentIndex > -1) {
      this.state.companySegments.editable.splice(
        companySegmentIndex,
        1,
        segment,
      )
      this.state.companySegments.initial.splice(companySegmentIndex, 1, segment)
    }
  }

  editVariables(service, segment) {
    const params = {
      compSlug: this.$state.params.slug,
      uuid: segment.uuid,
      endpointRoute: this.state.endpoint,
    }
    this.$state.go('manage.editSegmentVariables', params)
  }
  openDeleteDialog(service, segment) {
    DED.locals = {
      entityName: segment.name,
      identityEndpoint: service.one(segment.uuid),
      routeWhenSuccessful: 'manage.editCampaign.pitch',
    }
    this.$mdDialog.show(DED)
  }

  createSegmentFilter(key) {
    return ({ searchText }) => {
      this.state.search[key] = searchText
    }
  }

  saveActiveSegments(activeSegments) {
    this.onUpdate({ segments: activeSegments })
  }

  removeSegment(index, arr) {
    const segment = arr.splice(index, 1)[0]
    if (segment.company) {
      this.state.companySegments.editable.push(segment)
    } else {
      this.state.globalSegments.editable.push(segment)
    }
  }

  cancelMove() {
    this.resetSegmentsList('activeSegments')
    this.resetSegmentsList('companySegments')
    this.resetSegmentsList('globalSegments')
  }

  resetSegmentsList(listKey = '') {
    this.state[listKey].editable = [...this.state[listKey].initial]
  }

  toggleSearch(key) {
    this.state[key].showSearch = !this.state[key].showSearch
    this.state.search[key] = ''
  }
}
