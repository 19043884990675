// create users campaign info view

const manageUsersCreateCampaignInfo = 'manage.users.create.campaignInfo'

const manageUsersCreateCampaignInfoConfig = {
  params: {
    user: null
  },
  component: 'campaignInfoForm',
  resolve: {
    user: function ($state, $transition$) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) {
        $state.go('manage.users.create.personalInfo')
      }
      return user
    },
    campaigns: function ($transition$, IdentityService) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) return []
      return IdentityService.Campaign.list.get()
        .then((camps) => {
          if (user.company) {
            return camps.plain().filter(camp => camp.company === user.company.uuid)
          }
          return camps.plain()
        })
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreateCampaignInfo, manageUsersCreateCampaignInfoConfig }
