import './gateway.scss'

import angular from 'angular'
import { react2angular } from 'react2angular'

// services
import GatewayService from './services/GatewayService.js'

// components
//import gateway from './component/gateway.js'
import gateway from './component/react-gateway.js'

export default angular
  .module('portalApp.gateway', [])
  .service('GatewayService', GatewayService)
  .component(
    'gateway',
    react2angular(
      gateway,
      [],
      ['$injector', 'AuthService', '$state', '$sessionStorage'],
    ),
  ).name
//.component('gateway', gateway).name
