import './toolbarSearchInput.scss'
import ToolbarSearchInputController from './controller/ToolbarSearchInputController.js'
const template = require('./toolbarSearchInput.tpl.pug')

const toolbarSearchInput = {
  template: template(),
  controller: ToolbarSearchInputController,
  bindings: {
    placeholderText: '@',
    onUpdate: '<'
  }
}

export default toolbarSearchInput
