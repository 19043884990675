import { containsRole, addColumns } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'

export default class ManageAccessListsController extends ManagementController {
  constructor ($state, $mdDialog, ManagementService, ToastService, RoleStore,
               ManageAccessListsService, AccessListService) {
    'ngInject'
    super(...arguments)
    this.MACL = ManageAccessListsService
    this.ACLS = AccessListService
    this.companyOptions = []
  }

  $onInit () {
    const userRoles = this.RS.getAllRoles()
    this.companySelected = this.compSlug

    this.config = this.MACL.getAccessListsManagementConfig()
    this.config.searchConfig.editable = containsRole(userRoles, this.config.defaultEditRoles)
    this.state.queryParams = this.getInitialParams(this.config.filterConfig)

    const addColumnsOptions = {
      userRoles,
      allowedRoles: [ 'SUPER_USER', 'REALM_ADMIN' ],
      sliceIndex: (this.config.tableConfig.columns.length - 1),
      columns: this.config.tableConfig.columns,
      addColumns: this.config.tableConfig.optionalColumns
    }

    this.config.tableConfig.columns = addColumns(addColumnsOptions)
    this.getAclRelationOptions(['company'])
    this.fetchAcls()
  }

  createEntity () {
    this.$state.go(this.config.createState, {'compSlug': this.companySelected})
  }
  openView (event, entity, index) {
    let params = this.MS.createStateParams(entity, index)
    if (entity.company) {
      let company = this.companyOptions.find((c) => entity.company === c.uuid)
      params['compSlug'] = company.slug
    }
    this.$state.go(this.config.editState, params)
  }

  fetchAcls () {
    this.getEntityList(this.state.queryParams, this.config.entity, this.config.searchConfig.editable)
  }

  onAccessListUpdate (accessList, accessListIndex) {
    const entityListIndex = this.MS.getEntityIndex(accessList.uuid, this.config.entityList)
    const searchListIndex = this.MS.getEntityIndex(accessList.uuid, this.config.searchConfig.searchResults)
    this.MS.updateEntityInList(entityListIndex, this.config.entityList, accessList)
    this.MS.updateEntityInList(searchListIndex, this.config.searchConfig.searchResults, accessList)
  }

  getAclRelationOptions (relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
      })
      .catch(err => {
        this.TS.show({
          text: 'Problem fetching options, you wont be able to create a access list'
        })
        console.error(err)
      })
  }
}
