import { errorParser } from '../../../../utils/managementUtils.js'

export default class PitchDetailsController {
  constructor (ToastService, $mdDialog) {
    'ngInject'

    this.TS = ToastService
    this.$mdDialog = $mdDialog

    this.state = {
      pitch: {
        name: null,
        panel: null,
        active_version: null
      },
      versions: [],
      detailsErrors: [],
      commitErrors: [],
    }
  }

  $onChanges (bindings) {
    if (bindings.pitchName || bindings.pitchPanel || bindings.pitchVersions || bindings.pitchActiveVersion) {
      this.state.pitch = Object.assign(this.state.pitch, {
        name: this.pitchName,
        panel: this.pitchPanel,
        active_version: this.pitchActiveVersion
      })
      this.state.versions = this.pitchVersions
    }
  }

  updatePitch (pitch) {
    this.onUpdate({ fields: pitch })
      .then(() => {
        this.pitchForm.$setPristine()
      })
      .catch(err => {
        this.state.detailsErrors = errorParser(err.data)
        console.error(err)
      })
  }

  commitPitch () {
    this.onCommit()
      .then(latestVersion => {
        this.TS.show({
          text: 'Successfully committed Pitch'
        })
        this.afterCommit(latestVersion)
        this.state.versions.push(latestVersion)
      })
      .catch(err => {
        if (err.status === 400) {
          this.state.commitErrors = [err.data]
        } else {
          this.state.commitErrors = ['An Unknown Error Occurred']
        }
        console.error(err)
      })
  }

  afterCommit (latestVersion) {
    let confirm = this.$mdDialog.confirm()
      .title('Would you like to set the version you just committed to the active version?')
      .ok('Yes')
      .cancel('No')

    this.$mdDialog.show(confirm).then(() => {
      let activeVersion = latestVersion.uuid
      this.updatePitch({
        name: this.pitchName,
        panel: this.pitchPanel,
        active_version: activeVersion
      })
    })
  }

  resetForm (form) {
    this.state.pitch = Object.assign(this.state.pitch, {
      name: this.pitchName,
      panel: this.pitchPanel,
    })
    form.$setPristine()
  }

  dismissErrors (key) {
    this.state[key] = []
  }
}
