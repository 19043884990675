// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'
import type { Campaign } from 'types/entities'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'
import RemoveIcon from '@material-ui/icons/Remove';
import Link from '@material-ui/core/Link';

import ActionButton from 'common-components/action-button/ActionButton'
import AsyncTable from 'common-components/async-table/AsyncTable'
import TableSearch from 'common-components/table-search-prompt/TableSearch'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    classes: Object,
}

const styles = {
    addLink: {
        color: '#1194f6',
        cursor: 'pointer',
        '&:hover ': {
            textDecoration: 'none',
        }
    },
    inlineElement: {
        display: 'inline-block',
        fontSize: 16,
    },
    header: {
        backgroundColor: '#5f7d98',
        fontSize: '12px',
        fontWeight: '500',
        color: '#ffffff',
        textAlign: 'center',
    },
    IconBtn: {
        fontSize: 15,
        color: '#444851',
        textDecoration: 'underline',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
}

function CampaignsDialog(props: Props) {
    const [counter, setCounter] = useState(0)
    const [list, setList] = useState([])
    const [selectedCampaigns, setSelectedCampaigns] = useState([])
    const [loadingData, setLoadingData] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [showSearch, setShowSearch] = useState(false);
    const [searchField, setSearchField] = useState('');
    var copyList = list;
    var filteredData = copyList.filter(item => item.name.includes(searchField));

    useEffect(() => {
        axios.
            get(`${API_BASE_URL}/identity/campaign/list/`)
            .then(response => {
                setLoadingData(false)
                setList(response.data)
            })
            .catch(console.error)
    }, []);

    const Loader = () => (
        <Grid alignItems="center"
            container
            direction="column"
            justify="center"
            style={{ margin: '40px 0' }}>
            <Grid item>
                <CircularProgress size={55} style={{ color: '#777777' }} />
            </Grid>
            <Grid item>
                <p>Loading campaigns...</p>
            </Grid>
        </Grid >
    )

    const CampaignTableRow = ({
        campaign,
        rowClass,
        cellClass,
    }: {
        campaign: Campaign,
        rowClass: string,
        cellClass: string,
    }) => (
            <TableRow className={rowClass}>
                <TableCell className={cellClass} style={{ color: '#444851' }}>{campaign.name}
                </TableCell>
                <TableCell className={cellClass} style={{ color: '#444851', fontSize: '14px', textAlign: 'right', }}>
                    <IconButton disableRipple={true} className={props.classes.IconBtn}
                        onClick={() => addCampaign(campaign)}>
                        <AddIcon style={{ fontSize: '14px', color: '#444851' }} /> Add
                    </IconButton>
                </TableCell>
            </TableRow>
        )

    const SelectedTableRow = ({
        campaign,
        rowClass,
        cellClass,
    }: {
        campaign: Campaign,
        rowClass: string,
        cellClass: string,
    }) => (
            <TableRow className={rowClass}>
                <TableCell className={cellClass} style={{ color: '#444851' }}>{campaign.name}
                </TableCell>
                <TableCell className={cellClass} style={{ color: '#444851', fontSize: '14px', textAlign: 'right', }}>
                    <IconButton disableRipple={true} className={props.classes.IconBtn}
                        onClick={() => removeCampaign(campaign)}>
                        <RemoveIcon style={{ fontSize: '14px' }} /> Remove
                        </IconButton>
                </TableCell>
            </TableRow>
        )

    const addCampaign = (campaign) => {
        setCounter(counter + 1)
        setDisabled(false)
        setList(list.filter(el => el.uuid !== campaign.uuid))
        setSelectedCampaigns([...selectedCampaigns, campaign])
    }

    const removeCampaign = (campaign) => {
        setSelectedCampaigns(selectedCampaigns.filter(el => el.uuid !== campaign.uuid))
        setList([campaign, ...list])
        setCounter(counter - 1)
    }

    const handleSubmit = () => {
        selectedCampaigns.forEach(campaign => {
            delete campaign.realms
        });

        props.handleCreate(selectedCampaigns);
    }

    const addAll = () => {
        setCounter(list.length)
        setSelectedCampaigns([...selectedCampaigns, ...list])
        setList([])
        setDisabled(false)
    }

    const handleSearch = () => {
        setShowSearch(true)
    }

    const cancelSearch = () => {
        setShowSearch(false)
        setSearchField('')
    }

    return (
        < React.Fragment >
            <DialogTitle id="create-realm-dialog"
                className={props.classes.header}>Add Campaign
                <IconButton
                    disableRipple={true}
                    aria-label="close"
                    className="closeButton"
                    onClick={props.handleCancel}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <p>Campaign</p>
                <Grid container style={{ border: "1px solid #eeeeee" }}>
                    <Grid item style={{ width: "50%", height: "100%" }}>
                        <DialogContent style={{ padding: 0, }} >
                            <div style={{
                                padding: 15, borderBottom: "1px solid #eeeeee",
                                color: '#777777'
                            }}>
                                {counter + ' items selected'}
                            </div>
                            <div style={{ overflowY: 'auto', maxHeight: '520px' }}>
                                <AsyncTable
                                    loading={false}
                                    Loader={
                                        <Loader />
                                    }
                                    tableData={selectedCampaigns}
                                    headers={[]}
                                    render={(selectedCampaigns: Campaign[], { row, cell }) =>
                                        selectedCampaigns.map(campaign => (
                                            <SelectedTableRow
                                                key={campaign.uuid}
                                                campaign={campaign}
                                                rowClass={row}
                                                cellClass={cell}
                                            />
                                        ))
                                    }
                                />
                            </div>
                        </DialogContent>
                    </Grid>
                    <Grid item style={{ width: "50%", height: "100%" }}>
                        <DialogContent style={{ padding: 0, borderLeft: "1px solid #eeeeee", }}>
                            <div style={{ padding: 15, borderBottom: "1px solid #eeeeee" }}>
                                <div className={props.classes.inlineElement}
                                    style={{ color: '#777777' }}>
                                    Campaign list
                                </div>
                                <div className={props.classes.inlineElement}
                                    style={{ float: 'right' }}>
                                    <Link className={props.classes.addLink}
                                        onClick={handleSearch}>
                                        <SearchIcon style={{
                                            color: '#1194f6',
                                            fontSize: 22,
                                        }} />
                                    </Link>
                                </div>
                                <div className={props.classes.inlineElement}
                                    style={{
                                        float: 'right',
                                        marginRight: 20
                                    }}>
                                    <Link className={props.classes.addLink}
                                        style={{
                                            color: '#1194f6',
                                        }} onClick={addAll}>Add all</Link>
                                </div>
                            </div>

                            <div>
                                {
                                    showSearch &&
                                    <div style={{
                                        width: '100%', padding: 0,
                                        borderBottom: "1px solid #eeeeee"
                                    }} >
                                        <div className={props.classes.inlineElement}
                                            style={{
                                                width: '80%'
                                            }}>
                                            <TableSearch
                                                placeholder="Search campaign"
                                                handleChange={e =>
                                                    setSearchField(e.target.value)} />
                                        </div>
                                        <div className={props.classes.inlineElement}
                                            style={{
                                                padding: '20px 28px 15px 0',
                                                float: 'right'
                                            }}>
                                            <Link style={{
                                                color: '#444851', cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }} onClick={cancelSearch}>Cancel</Link>
                                        </div>
                                    </div >

                                }

                                <div style={{ overflowY: 'auto', maxHeight: '520px' }}>
                                    <AsyncTable
                                        loading={loadingData}
                                        Loader={
                                            <Loader />
                                        }
                                        tableData={filteredData}
                                        headers={[]}
                                        render={(list: Campaign[], { row, cell, icon }) =>
                                            list.map(campaign => (
                                                <CampaignTableRow
                                                    key={campaign.uuid}
                                                    campaign={campaign}
                                                    rowClass={row}
                                                    cellClass={cell}
                                                    iconClass={icon}
                                                />
                                            ))
                                        }
                                    />
                                </div>
                            </div>

                        </DialogContent>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="center" spacing={2}
                    style={{ marginBottom: '15px', marginTop: '15px' }}>
                    <Grid item>
                        <ActionButton handleClick={props.handleCancel}
                            buttonColor="default" >CANCEL</ActionButton>
                    </Grid>
                    <Grid item>
                        <ActionButton
                            handleClick={handleSubmit}
                            disabled={disabled}
                            buttonColor="secondary"
                        > SAVE </ActionButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </React.Fragment >
    )
}


export default withStyles(styles)(CampaignsDialog)