import { map, reduce } from 'ramda'

function repFormatter(metric) {
  return function(acc, key) {
    const rep = metric.data[key]
    delete rep.x
    acc.push(rep)
    return acc
  }
}

function metricFormatter(metric) {
  const formattedData = reduce(
    repFormatter(metric),
    [],
    Object.keys(metric.data),
  )
  return Object.assign(metric, { data: formattedData })
}

function formatRepMetrics(metrics) {
  return map(metricFormatter, metrics)
}

function formatRepData(data) {
  return {
    stats: [...data.stats.results],
    metrics: data.metrics,
  }
}

export { formatRepData, formatRepMetrics }
