import { MANAGE_GROUP } from 'permissions.js'
// manage locations view

const manageLocations = 'manage.locations'

const manageLocationsConfig = {
  url: '/locations',
  component: 'manageLocations',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  },
  resolve: {
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Manage Locations'
      $window.document.title = pageTitle
      return pageTitle
    }
  }
}

export { manageLocations, manageLocationsConfig }
