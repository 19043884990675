// Manage Edit Access Lists
import {
  manageEditAccessList,
  manageEditAccessListConfig,
} from './manage.editAccessList.js'
import {
  manageEditAccessListNode,
  manageEditAccessListNodeConfig,
} from './child-states/access-list-node/manage.AccessListNode.js'
import {
  manageEditAccessListNodeCreate,
  manageEditAccessListNodeCreateConfig,
} from './child-states/access-list-node/child-states/node-create/manage.editAccessListNode.create.js'
import {
  manageEditAccessListNodeEdit,
  manageEditAccessListNodeEditConfig,
} from './child-states/access-list-node/child-states/node-edit/manage.editAccessListNode.edit.js'

export default function manageEditAccessListsRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditAccessList, manageEditAccessListConfig)
    .state(manageEditAccessListNode, manageEditAccessListNodeConfig)
    .state(manageEditAccessListNodeCreate, manageEditAccessListNodeCreateConfig)
    .state(manageEditAccessListNodeEdit, manageEditAccessListNodeEditConfig)
}
