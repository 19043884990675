export default function icons($mdIconProvider) {
  'ngInject'
  $mdIconProvider
    .iconSet(
      'navigation',
      require('../../../../assets/icons/navigation-icons.svg'),
    )
    .iconSet('action', require('../../../../assets/icons/action-icons.svg'))
    .iconSet('alert', require('../../../../assets/icons/alert-icons.svg'))
    .iconSet('content', require('../../../../assets/icons/content-icons.svg'))
    .iconSet('social', require('../../../../assets/icons/social-icons.svg'))
    .iconSet('mdi', require('../../../../assets/icons/mdi-icons.svg'))
    .iconSet('editor', require('../../../../assets/icons/editor-icons.svg'))
    .iconSet('file', require('../../../../assets/icons/file-icons.svg'))
    .iconSet('maps', require('../../../../assets/icons/maps-icons.svg'))
    .iconSet(
      'communication',
      require('../../../../assets/icons/communication-icons.svg'),
    )
    .iconSet('hardware', require('../../../../assets/icons/hardware-icons.svg'))
    .iconSet('av', require('../../../../assets/icons/av-icons.svg'))
    .iconSet('image', require('../../../../assets/icons/image-icons.svg'))
    .iconSet('custom', require('../../../../assets/icons/custom-icons.svg'))
}
