import { map, reduce } from 'ramda'

function metricsReducer(data, metrics, hourStamp) {
  function calcMetric(acc, metric) {
    acc[metric.key] = metric.calc(metric, data, hourStamp)
    return acc
  }
  return reduce(calcMetric, {}, metrics)
}

function calcHourMetrics(hour, metrics) {
  const attemptNums = Object.keys(hour.data)
  const hourStamp = hour.date * 1000
  const calcPerAttempt = attNum => ({
    attemptNumber: attNum,
    metrics: metricsReducer(hour.data[attNum], metrics, hourStamp),
  })

  return map(calcPerAttempt, attemptNums)
}

function makeHourData(metrics = []) {
  return function(hour) {
    return {
      attempts: calcHourMetrics(hour, metrics),
      date: hour.date,
    }
  }
}

function hourCalculator(metrics = []) {
  return function(day) {
    const hourData = map(makeHourData(metrics), day.data)
    return { hours: hourData, date: day.date }
  }
}

function hourByAttemptCalculator(data, mapFunc) {
  return map(mapFunc, data)
}

function calcMetricsByHourByAttempt(data = [], metrics = []) {
  return hourByAttemptCalculator(data, hourCalculator(metrics))
}

function calcByAttemptByHour({ data = [], metrics = [] }) {
  return {
    days: calcMetricsByHourByAttempt(data, metrics),
    metrics,
  }
}

export { calcByAttemptByHour }
