// dashboard.listOverview view

const dashboardListOverview = 'dashboard.listOverview'

const dashboardListOverviewConfig = {
  url: '/list-overview/{listSlug}/{realm}',
  params: {
    listSlug: null,
    realm: null,
  },
  component: 'listOverview',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign) {
      'ngInject'
      return selectedCampaign
    },
    offsetMinutes: function (timezoneOffsetMinutes) {
      'ngInject'
      return timezoneOffsetMinutes
    },
    globalRealm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    },
    listRealm: function ($transition$) {
      'ngInject'
      return $transition$.params().realm
    },
    list: function ($transition$, ProspectService, company, campaign) {
      'ngInject'
      return ProspectService.lists.fetch
        .one(`${company.slug}/${campaign.slug}/${$transition$.params().listSlug}`)
        .get({ realms: $transition$.params().realm })
        .then(list => list.plain())
    }
  }
}

export { dashboardListOverview, dashboardListOverviewConfig }
