// @flow

import debounce from 'lodash/debounce'

const positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP_LEFT: 'top left',
  TOP_RIGHT: 'top right',
  BOTTOM_LEFT: 'bottom left',
  BOTTOM_RIGHT: 'bottom right',
}

type ToastPositions = $Keys<typeof positions>
type ShowToastArgs = {
  text: string,
  position?: ToastPositions,
  theme?: string,
  hideDelay?: number,
}
type ShowFunc = ShowToastArgs => void

export default class ToastService {
  $mdToast: any
  show: ShowFunc
  constructor($mdToast: any) {
    'ngInject'
    this.$mdToast = $mdToast
    this.show = debounce(this._showToast.bind(this), 5000, { leading: true })
  }

  _showToast({
    text = 'No message text set',
    position = 'top right',
    theme = 'default',
    hideDelay = 5000,
  }: ShowToastArgs) {
    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent(text)
        .position(position)
        .theme(theme)
        .hideDelay(hideDelay),
    )
  }
}
