export default class CreatePitchVariablesController {
  constructor($q, $mdDialog, $state) {
    'ngInject'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.createVariable = {}
    this.state = {
      submitting: false,
      error: false,
    }
    this.init()
  }

  init() {
    this.createVariable.key = this.variable ? this.variable.key : ''
    this.createVariable.value = this.variable ? this.variable.value : ''
  }

  update(newVariable) {
    this.state.submitting = true
    this.service
      .patch({
        variables: Object.assign(this.variables, {
          [newVariable.key]: newVariable.value,
        }),
      })
      .then(rrTest => rrTest.plain().variables)
      .then(variables => {
        this.close(variables)
      })
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  resetError() {
    this.state.error = false
  }

  close(newVariables) {
    newVariables ? this.$mdDialog.hide(newVariables) : this.$mdDialog.cancel()
  }
}
