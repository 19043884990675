// create campaign router view

const manageEditCompanyPhraseBookPhrase = 'manage.editCompanyPhraseBook.phrase'

const manageEditCompanyPhraseBookPhraseConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageEditCompanyPhraseBookPhrase, manageEditCompanyPhraseBookPhraseConfig }
