import DialerLiveController from './controller/DialerLiveController.js'
const template = require('./dialerLive.tpl.pug')

const dialerLive = {
  template: template(),
  controller: DialerLiveController,
  bindings: {
    campaign: '<',
    realm: '<'
  }
}

export default dialerLive

