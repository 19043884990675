import angular from 'angular'

// services
import ManageAccessListsService from './services/ManageAccessListsService'

// components
import manageAccessLists from './component/manageAccessLists.component'
import manageAccessListsCreate from './sub-components/manage-access-lists-create/component/manageAccessListsCreate.component.js'
import manageAccessListsEdit from './sub-components/manage-access-lists-edit/component/manageAccessListsEdit.component.js'

export default angular
  .module('portalApp.manage.access-lists', [])
  .service('ManageAccessListsService', ManageAccessListsService)
  .component('manageAccessLists', manageAccessLists)
  .component('manageAccessListsCreate', manageAccessListsCreate)
  .component('manageAccessListsEdit', manageAccessListsEdit)
  .name
  // .component('manageAccessListsCreate', {})
  // .component('manageAccessListsEdit', {})
