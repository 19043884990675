/*
| Directive for checking the uniqueness of an input
*/

export default function uniqueInput ($q, IdentityService) {
  'ngInject'
  function checkInput (value, userName, propertyName, ctrl) {
    return $q(function (resolve, reject) {
      IdentityService.User.manage.unique[propertyName].post({
        [propertyName]: value
      })
      .then(isUnique => {
        if (value === userName) {
          return resolve()
        }
        if (isUnique.valid || !ctrl.$dirty) {
          return resolve()
        } else if (!value && !ctrl.$validators.required) {
          return resolve()
        }
        return reject()
      })
      .catch(() => {
        reject()
      })
    })
  }

  function linkFunc (scope, elem, attrs, ctrl) {
    ctrl.$asyncValidators.uniqueInput = function (modelVal, viewVal) {
      const value = modelVal || viewVal
      const propertyName = attrs.propertyName
      const userName = attrs.userName
      return checkInput(value, userName, propertyName, ctrl)
    }
  }

  return {
    restrict: 'A',
    require: 'ngModel',
    link: linkFunc
  }
}
