import './reactivateDid.scss'
import ReactivateDidController from './controller/ReactivateDidController'
const template = require('./reactivateDid.tpl.pug')

const reactivateDid = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ReactivateDidController,
}

export default reactivateDid
