import { map, reduce } from 'ramda'

function metricsToObject(acc, metric) {
  acc[metric.key] = metric
  return acc
}

function reduceMetricsForHour(hour) {
  const hourStamp = hour.date * 1000
  const repKeys = Object.keys(hour.data)
  const reps = hour.data

  return function(acc, metric) {
    acc[metric.key] = repKeys.map(repKey =>
      metric.calcByRep(metric, reps[repKey], repKey, hourStamp),
    )
    return acc
  }
}

function calculateHourMetrics(hour, metrics) {
  return reduce(reduceMetricsForHour(hour), {}, metrics)
}

function hourMapper(day, metrics) {
  return function(hour) {
    const hourMetrics = calculateHourMetrics(hour, metrics)
    return { metrics: hourMetrics, date: day.date }
  }
}

function dayMapper(metrics) {
  return function(day) {
    return {
      hours: map(hourMapper(day, metrics), day.data),
      date: day.date,
    }
  }
}

function calcMetricsByHour(data) {
  return {
    days: map(dayMapper(data.metrics), data.stats),
    metrics: reduce(metricsToObject, {}, data.metrics),
  }
}

export { calcMetricsByHour }
