import ProspectAudioController from './controller/ProspectAudioController.js'
const template = require('./prospectAudio.component.tpl.pug')

const prospectAudio = {
  controller: ProspectAudioController,
  template: template(),
  bindings: {
    voice: '<',
  },
}

export default prospectAudio
