// Edit Campaign Settings view

const manageEditCampaignSettings = 'manage.editCampaign.settings'

const manageEditCampaignSettingsConfig = {
  url: '/settings',
  component: 'campaignSettings',
}

export { manageEditCampaignSettings, manageEditCampaignSettingsConfig }
