import PanelFieldDialogController from './controller/PanelFieldDialogController.js'
const template = require('./panelFieldDialog.tpl.pug')

const panelFieldDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: PanelFieldDialogController,
}

export default panelFieldDialog

