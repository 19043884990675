// @flow

import './filterList.scss'
import FilterListController from './controller/FilterListController.js'
import template from './filterList.tpl.pug'

type MenuSection = {
  title: string,
  icon: string,
  actionCb: Function,
}

type TableRowConfig = {
  title: string,
  copyable?: boolean,
  accessor?: string,
  parser?: mixed => mixed,
  type: 'text' | 'active' | 'button' | 'menu' | 'boolean',
  icon?: string,
  actionCb?: Function,
  menu?: Array<MenuSection>,
}

export type FilterListConfig = Array<TableRowConfig>

const filterList = {
  template: template(),
  controller: FilterListController,
  bindings: {
    entity: '@',
    entityPlural: '@',
    global: '<?',
    handleCreate: '&?',
    isLoading: '<',
    listName: '@',
    tableConfig: '<',
    tableData: '<',
  },
}

export default filterList
