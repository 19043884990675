// Manage Edit Segment Variables
import {
  editSegmentVariables,
  editSegmentVariablesConfig,
} from './manage.editSegmentVariables'

export default function editSegmentVariablesRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(editSegmentVariables, editSegmentVariablesConfig)
}
