// Dashboard
import {
  dashboardRepTrend,
  dashboardRepTrendConfig,
} from './dashboard.repTrend.js'

export default function dashboardRepTrendRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardRepTrend, dashboardRepTrendConfig)
}
