const STRING = 'string'
const AUDIO = 'audio'
const BUTTON = 'button'

const MOVE_TO_RERECORD = 'MOVE_TO_RERECORD'
const MOVE_TO_RECORD = 'MOVE_TO_RECORD'

const unrecordedConfig = [
  { title: 'Name', accessor: 'name', type: STRING },
  { title: 'Phrase', accessor: 'phrase', type: STRING },
]

const rerecordConfig = [
  { title: 'Name', accessor: 'name', type: STRING },
  { title: 'Audio', accessor: 'processed_url', type: AUDIO },
  {
    title: 'Undo',
    icon: 'content:redo',
    actionType: MOVE_TO_RECORD,
    type: BUTTON,
  },
]

const recordedConfig = [
  { title: 'Name', accessor: 'name', type: STRING },
  { title: 'Audio', accessor: 'processed_url', type: AUDIO },
  {
    title: 'Rerecord',
    icon: 'content:undo',
    actionType: MOVE_TO_RERECORD,
    type: BUTTON,
  },
]

function attachCallbacks(cbs, column) {
  if (column.actionType) {
    column.action = cbs[column.actionType]
  }
  return column
}

// Use this to bind functions in a controller to cells in the table
function addButtonActions(actionCbs = {}, config = []) {
  return config.map(attachCallbacks.bind(null, actionCbs))
}

export { unrecordedConfig, rerecordConfig, recordedConfig, addButtonActions }
