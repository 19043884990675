import moment from 'moment'
import { repRankingsConfig } from '../config/repRankingsConfig.js'

export default class RepRankingsController {
  constructor(RepRankingsService) {
    'ngInject'

    this.RRS = RepRankingsService
    this.componentConfig = repRankingsConfig
  }

  $onInit() {
    const timezoneOffset = this.offsetMinutes
    const localOffset = moment().utcOffset()
    this.dateTime = moment.utc().utcOffset(timezoneOffset)
    const today = this.dateTime.startOf('day').toDate()
    const endDate = this.dateTime.endOf('day').toDate()
    const normalizedOffset = Math.abs(timezoneOffset) + localOffset

    this.dateRange = {
      startDate: today,
      endDate: endDate,
      currentDate: today,
      normalizedOffset,
      requestTime: moment().startOf('minute'),
    }

    this.state = {
      searching: false,
      selected: {
        company: this.company,
        campaign: this.campaign,
        realm: this.realm,
      },
    }
  }
}
