const campaignMetricsConfig = {
  context: 'campaign',
  key: 'campaign_metrics',
  defaultMetrics: [
    'salesPerBillableHour',
    'sales',
    'revenuePerBillableHour',
    'revenue',
    'completedTransfers',
  ],
  subSections: [],
}

const dialerMetricsConfig = {
  context: 'campaign',
  key: 'dialer_metrics',
  subSections: [{ key: 'performance_health', title: 'Performance' }],
  defaultMetrics: [
    'repWaitTime',
    'callsPerMachineHour',
    'dialingSpeed',
    'hopperSize',
    'schedulerSpeed',
    'hopperSpeed',
    'serverLoad',
    'keypressLag',
  ],
}

const repMetricsConfig = {
  context: 'campaign',
  key: 'rep_metrics',
  subSections: [{ key: 'rapid_repsonse', title: 'Rapid Response' }],
  defaultMetrics: [
    'repsLive',
    'stationsLive',
    'silencePerKeypress',
    'utilizationRate',
    'billableHours',
    'payableHours',
    'machineHours',
  ],
}

const dataMetricsConfig = {
  context: 'campaign',
  key: 'data_metrics',
  subSections: [
    { key: 'hangup_causes', title: 'Hangup Causes' },
    { key: 'dispositions', title: 'Dispositions' },
  ],
  defaultMetrics: [
    'activeProspects',
    'activeDialableProspects',
    'calls',
    'contacted',
    'inboundCalls',
    'outboundCalls',
    'conversionRate',
    'contactRate',
    'dynamicValidUploads',
    'dynamicInvalidUploads',
  ],
}

export {
  campaignMetricsConfig,
  dialerMetricsConfig,
  repMetricsConfig,
  dataMetricsConfig,
}
