// create campaign router view

const manageEditCampaignCreate = 'manage.editCampaign.dataPosting.create'

const manageEditCampaignCreateConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageEditCampaignCreate, manageEditCampaignCreateConfig }
