import DialerQueueController from './controller/DialerQueueController.js'
const template = require('./dialerQueue.tpl.pug')

const dashboardQueue = {
  template: template(),
  controller: DialerQueueController,
  bindings: {
    campaign: '<',
    realm: '<'
  }
}

export default dashboardQueue

