import { prepend } from 'ramda'
import selectMetricsDialog from '../../select-metrics/selectMetricsDialog.js'

export default class FullscreenGraphController {
  constructor($scope, $mdDialog, MetricSeriesService) {
    'ngInject'
    this.allSeries = MetricSeriesService.series
    this.makeCsv = this.parseCsv.bind(this, this.chartConfig, this.title)
    this.$mdDialog = $mdDialog
    this.filteredMetrics = this.filterMetrics(this.metricKey, this.allSeries)
    this.state = {
      selectedMetrics: {},
    }
    $scope.$on('$destroy', this.reset.bind(this))
  }

  addSeries(current, metrics, seriesObjs) {
    const buildNewSeries = (acc, key) => {
      acc.main.push(seriesObjs[key].baseRange, seriesObjs[key].compareRange)
      acc.drilldown.push(seriesObjs.drilldown[key])
      return acc
    }
    const combined = prepend(current, metrics.map(metric => metric.key))
    const newSeries = combined.reduce(buildNewSeries, {
      main: [],
      drilldown: [],
    })
    this.chartConfig.options.drilldown.series = newSeries.drilldown.reduce(
      (acc, drilldown) => acc.concat(drilldown),
      [],
    )
    this.chartConfig.series = newSeries.main
  }

  close() {
    this.$mdDialog.hide()
  }

  reset() {
    // sets the chart to only have its original series
    this.addSeries(this.metricKey, [], this.allSeries)
  }

  openSelector(event, metrics, selectedMetrics) {
    const SMD = selectMetricsDialog
    SMD.targetEvent = event
    SMD.locals = {
      selectedMetrics: this.filterMetrics(null, selectedMetrics),
      metrics: this.filteredMetrics,
    }
    this.$mdDialog
      .show(SMD)
      .then(metrics => {
        this.addSeries(this.metricKey, metrics, this.allSeries)
        this.state.selectedMetrics = this.updateSelectedMetrics(metrics)
      })
      .catch(() => {})
  }

  updateSelectedMetrics(metricsArr) {
    return metricsArr.reduce((acc, metric) => {
      acc[metric.key] = metric
      return acc
    }, {})
  }

  filterMetrics(currentKey, seriesObjs) {
    const filterKeys = key => {
      return key !== currentKey && key !== 'drilldown'
    }
    const makeMetricArr = (acc, key) => {
      const title = seriesObjs[key].format
        ? seriesObjs[key].format.title
        : seriesObjs[key].title
      acc.push({
        title: title,
        key: key,
      })
      return acc
    }

    return Object.keys(seriesObjs)
      .filter(filterKeys)
      .reduce(makeMetricArr, [])
  }

  parseCsv(chart, title) {
    const highChart = chart.getHighcharts()
    const exportLength = highChart.getDataRows().length
    const append = exportLength > 25 ? '_90_Day' : '_24_Hour'
    const formatTitle = title
      .split(' ')
      .filter(char => char !== '/')
      .join('_')
      .concat(append)
    return {
      fileName: `${formatTitle}.csv`,
      data: highChart.getCSV(),
    }
  }
}
