import EditLocationCreateTeamController from './controller/EditLocationCreateTeamController.js'
const template = require('./editLocationCreateTeam.component.tpl.pug')

const editLocationCreateTeam = {
  controller: EditLocationCreateTeamController,
  template: template(),
  bindings: {
    locationId: '<',
  }
}

export default editLocationCreateTeam
