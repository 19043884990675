import { range } from 'ramda'
import { errorParser } from '../../../utils/managementUtils.js'

const fontSizes = Array.from(range(12, 25), num => ({
  value: num,
  display: `${num}px`,
}))
const blankState = {
  loading: false,
  errors: [],
  successfulCreate: false,
}
const blankSegment = {
  name: '',
  type: '',
  xml: '',
}

export default class CreatePitchSegmentsController {
  constructor($timeout, $mdDialog, ToastService, $state) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.$timeout = $timeout
    this.TS = ToastService

    this.fontSizes = fontSizes
    this.segment = this.segment || Object.assign({}, blankSegment)
    this.state = Object.assign({}, blankState)
    this.editorOptions = {
      mode: 'xml',
      theme: 'chrome',
      fontSize: 12,
      reset: false,
    }
    this.options = [
      {
        name: 'Global Defaults',
        value: 'defaults',
        isRapidResponse: false,
      },
      {
        name: 'Global Options',
        value: 'options',
        isRapidResponse: false,
      },
      {
        name: 'Nodes',
        value: 'nodes',
        isRapidResponse: false,
      },
      {
        name: 'Response Test',
        value: 'response-tests',
        isRapidResponse: true,
      },
      {
        name: 'Failure',
        value: 'failures',
        isRapidResponse: true,
      },
      {
        name: 'Intro',
        value: 'intros',
        isRapidResponse: true,
      },
      {
        name: 'No Response',
        value: 'no-responses',
        isRapidResponse: true,
      },
      {
        name: 'Ending',
        value: 'endings',
        isRapidResponse: true,
      },
    ]
  }

  submitSegment(segment, isCreate = true) {
    if (isCreate) {
      this.createSegment(segment)
    } else {
      this.updateSegment(segment)
    }
  }

  createSegment(segment) {
    this.resetErrors()
    this.state.loading = true
    this.service
      .customPOST(segment)
      .then(segment => {
        this.state.successfulCreate = true
        this.segment = segment.plain()
      })
      .catch(err => {
        this.state.errors = errorParser(err.data)
      })
      .finally(() => {
        this.state.loading = false
        this.$state.go(this.$state.current.name, null, { reload: true })
      })
  }

  updateSegment(segment) {
    this.state.loading = true
    this.service
      .one(segment.uuid)
      .patch(segment)
      .then(updatedSegment => {
        this.segment = updatedSegment
        this.close(true)
        this.TS.show({
          text: 'Segment Updated',
        })
      })
      .catch(err => {
        this.state.errors = errorParser(err.data)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  handleTextUpdate(text = '') {
    if (text.length > 0) {
      this.segment.xml = text
    } else {
      this.segment.xml = ''
    }
  }

  resetErrors() {
    this.state.errors = []
  }

  resetBlank(form) {
    this.segment = Object.assign({}, blankSegment)
    this.state = Object.assign({}, blankState)
    this.resetEditor()
    form.$setUntouched()
    form.$setPristine()
  }

  resetEditor(editorDocument) {
    return function() {
      editorDocument.setValue('')
    }
  }

  close(successfulCreate = false) {
    const close =
      successfulCreate || !this.isCreate
        ? this.$mdDialog.hide
        : this.$mdDialog.cancel
    close({
      wasCreate: this.isCreate,
      segment: this.segment,
    })
  }
}
