import './rapidResponseTestsVoices.scss'
import RapidResponseTestsVoicesController from './controller/RapidResponseTestsVoices.controller'
const template = require('./rapidResponseTestsVoices.component.tpl.pug')

const editRapidResponseTestsVoices = {
  template: template(),
  controller: RapidResponseTestsVoicesController,
  bindings: {
    compSlug: '<',
    compUuid: '<',
    rapidResponseTest: '<',
  },
}

export default editRapidResponseTestsVoices
