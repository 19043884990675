const params = {
  slug: null,
  uuid: null,
  entity: null,
}

function getCampaign ($transition$, IdentityService) {
  'ngInject'
  const campaign = $transition$.params().entity
  if (campaign) return campaign
  return IdentityService.Campaign.fetch
    .one($transition$.params().uuid)
    .get()
    .then(campaign => campaign.plain())
}

export { params, getCampaign }
