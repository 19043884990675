import OverviewController from './controller/OverviewController.js'
const template = require('./dashboard.overview.tpl.pug')

const overview = {
  template: template(),
  controller: OverviewController,
  bindings: {
    company: '<',
    campaign: '<',
    offsetMinutes: '<',
    realm: '<',
  }
}

export default overview
