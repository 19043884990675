import TrendTableController from './controller/TrendTableController.js'
import './trendTable.scss'
const template = require('./trendTable.tpl.pug')

const trendTable = {
  template: template(),
  controller: TrendTableController,
  bindings: {
    selectedCampaign: '<',
    currentCompany: '<',
    offsetMinutes: '<',
    realm: '<',
  }
}

export default trendTable
