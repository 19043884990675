import EditRapidResponseTestsController from './controller/EditRapidResponseTestsController.js'
const template = require('./editRapidResponseTests.component.tpl.pug')

const editRapidResponseTests = {
  template: template(),
  controller: EditRapidResponseTestsController,
  bindings: {
    compSlug: '<',
    compUuid: '<',
    rapidResponseTest: '<',
  },
}

export default editRapidResponseTests
