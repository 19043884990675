// Manage Rapid Response Segments View

const manageGlobalRapidResponseSegments = 'manage.globalRapidResponse.segments'

const manageGlobalRapidResponseSegmentsConfig = {
  url: '/segments',
  component: 'rapidResponseSegments'
}

export { manageGlobalRapidResponseSegments, manageGlobalRapidResponseSegmentsConfig }
