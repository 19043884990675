import angular from 'angular'

// Services
import ManageAccessListNodeService from './services/ManageAccessListNodeService.js'

// components
import editAccessListNode from './component/editAccessListNode.component.js'
import editAccessListNodeForm from './sub-components/edit-access-list-node-form/component/editAccessListNodeForm.js'

export default angular
  .module('portalApp.manage.editAccessListNode', [])
  .service('ManageAccessListNodeService', ManageAccessListNodeService)
  .component('editAccessListNode', editAccessListNode)
  .component('editAccessListNodeForm', editAccessListNodeForm)
  .name
