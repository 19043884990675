import './metricInfoDialog.scss'
import MetricInfoDialogController from './controller/MetricInfoDialogController.js'
const template = require('./metricInfoDialog.tpl.pug')

const metricInfoDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: MetricInfoDialogController
}

export default metricInfoDialog
