import TrackBillableController from './controller/TrackBillableController.js'
const template = require('./trackBillable.tpl.pug')

const selectMetricsDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: TrackBillableController
}

export default selectMetricsDialog
