import EditSegmentVariablesController from './controller/EditSegmentVariablesController'
import template from './editSegmentVariables.tpl.pug'

const editSegmentVariables = {
  controller: EditSegmentVariablesController,
  template: template(),
  bindings: {
    uuid: '<',
    compUuid: '<',
    compSlug: '<',
    isRapidResponse: '<',
  },
}

export default editSegmentVariables
