import RepsLiveController from './controller/RepsLiveController.js'
import './repsLive.scss'
const template = require('./repsLive.tpl.pug')

const repsLive = {
  template: template(),
  controller: RepsLiveController,
  bindings: {
    key: '<',
    metric: '<',
    company: '<',
    campaign: '<',
    realm: '<'
  }
}

export default repsLive
