// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
    classes: Object,
    placeholder: string,
}

const styles = {
    input: {
        margin: '15px',
        fontSize: 18,
        color: '#bbbbbb',
        borderBottom: '1px solid #eeeeee',
        '&:hover:before,&:before, &:after ': {
            borderBottom: '1px solid #eeeeee!important',
        },
    },
    inputFocused: {
        borderBottom: '2px solid #1194f6!important',
        color: '#000000de',
    },
    searchIcon: {
        fontSize: 20,
        color: '#bbbbbb',
    }
}

function TableSearch(props: Props) {

    return (
        <FormControl fullWidth>
            <Input
                type='search'
                className={props.classes.input}
                classes={{ focused: props.classes.inputFocused }}
                placeholder={props.placeholder}
                onChange={props.handleChange}
                endAdornment={<InputAdornment position="end">
                    <SearchIcon className={props.classes.searchIcon} />
                </InputAdornment>}
            />
        </FormControl>
    )
}

TableSearch.defaultProps = {
    type: 'search',
}

export default withStyles(styles)(TableSearch)
