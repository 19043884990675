import ManageGlobalRapidResponseController from './controller/ManageGlobalRapidResponseController.js'
import template from './manageGlobalRapidResponse.component.tpl.pug'

const manageGlobalRapidResponse = {
  template: template(),
  controller: ManageGlobalRapidResponseController,
  bindings: {},
}

export default manageGlobalRapidResponse
