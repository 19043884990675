import DashboardController from './controller/DashboardController.js'
const template = require('./dashboard.component.tpl.pug')

const dashboard = {
  template: template(),
  controller: DashboardController,
  bindings: {
    user: '<',
    userCampaigns: '<',
    selectedCampaign: '<',
    selectedRealm: '<',
    campaignRealms: '<',
    currentCompany: '<',
    timezoneOffsetMinutes: '<',
  }
}

export default dashboard
