import './campaignHealth.scss'
import CampaignHealthController from './controller/CampaignHealthController.js'
const template = require('./campaignHealth.tpl.pug')

const campaignHealth = {
  template: template(),
  controller: CampaignHealthController,
  bindings: {
    title: '@',
    componentDetails: '<',
    company: '<',
    campaign: '<',
    realm: '<',
    dateRange: '<'
  }
}

export default campaignHealth
