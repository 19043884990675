// @flow

import * as React from 'react'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'

type Props = {
  children: string,
  classes: Object,
  className: string,
  innerRef: any,
}

const style = {
  cell: {
    textDecoration: 'underline',
  },
}

function UnderlineCell(props: Props) {
  const classes = `${props.className} ${props.classes.cell}`
  return <TableCell className={classes}>{props.children}</TableCell>
}

export default withStyles(style)(UnderlineCell)
