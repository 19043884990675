import angular from 'angular'

// common components
import activeToggle from './active-toggle/activeToggle.js'
import asyncAudio from './async-audio/asyncAudio'
import booleanToggle from './boolean-toggle/booleanToggle.js'
import collapseToggle from './collapse-toggle/collapseToggle.js'
import emptyState from './empty-state/emptyState.js'
import filterList from './filter-list/filterList'
import headMenu from './head-menu/headMenu'
import loaderOverlay from './loader-overlay/loaderOverlay.js'
import noContentError from './no-content-error/noContentError.js'
import paginationFooter from './pagination-footer/paginationFooter.js'
import profileMenu from './profile-menu/profileMenu.js'
import sideNav from './side-nav/sideNav.js'
import tableSearchPrompt from './table-search-prompt/tableSearchPrompt.js'
import timer from './timer/timer.js'
import titleBar from './title-bar/titleBarComponent'
import titleBarTabNav from './title-bar-tab-nav/titleBarTabNav.js'
import toolBarSearchInput from './toolbar-search-input/toolBarSearchInput.js'
import viewEditToggle from './view-edit-toggle/viewEditToggle.js'

export default angular.module('portalApp.common.components', [
  activeToggle,
  asyncAudio,
  booleanToggle,
  collapseToggle,
  emptyState,
  filterList,
  headMenu,
  loaderOverlay,
  noContentError,
  paginationFooter,
  profileMenu,
  sideNav,
  tableSearchPrompt,
  timer,
  titleBar,
  titleBarTabNav,
  toolBarSearchInput,
  viewEditToggle,
]).name
