import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from 'theme'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import PlayIcon from '@material-ui/icons/PlayArrow'
import Link from '@material-ui/core/Link'
import Snackbar from '@material-ui/core/Snackbar'
import Dialog from '@material-ui/core/Dialog'
import ActionButton from 'common-components/action-button/ActionButton'
import Loader from 'common-components/loader-overlay/loader-react'
import AppSelect from '../../../../components/AppSelect.js'
import NewResourceForm from '../../../../components/NewResourceForm.js'
import { getBaseUrl } from '../../../../global/config/providers/restangularProvider/rest.js'
const slugify = require('slugify')

let Header
const API_BASE_URL = getBaseUrl(window.location.host, true)
const useStyles = makeStyles(theme => ({
  redirectLink: {
    cursor: 'pointer',
    color: '#1194f6',
  },
  dialer: {
    height: '55vh',
  },
  pStyled: {
    color: '#7c8a97',
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
  },
  btn: {
    padding: '0px !important',
    fontSize: '14px !important',
    color: '#444851 !important',
    margin: '15px 0px !important',
    cursor: 'pointer',
    display: 'block',
  },
  container6: {
    marginBottom: 20,
    paddingRight: 30,
  },
}))
const ivr = props => {
  const classes = useStyles()
  const [url, setUrl] = useState('/manage/audio/resources')
  const [uuid, setUuid] = useState('')
  const [companySlug, setCompanySlug] = useState('')
  const [campaign, setCampaign] = useState('')
  const [resources, setResources] = useState(false)
  const [greetLong, setGreetLong] = useState('')
  const [exitSound, setExitSound] = useState('')
  const [greetShort, setGreetShort] = useState('')
  const [invalidSound, setInvalidSound] = useState('')
  const [data, setData] = useState([])
  const [showButtons, setShowButtons] = useState(false)
  const [open, setOpen] = useState(false)
  const [snackCreate, setSnackCreate] = useState(false)
  const [initState, setInitState] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [showPlayer, setShowPlayer] = useState({
    greetLong: false,
    exitSound: false,
    greetShort: false,
    invalidSound: false,
  })
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const redirectAudio = () => (window.location.href = url)

  const handleCancel = () => {
    let element = document.getElementById('divButtons')
    element.classList.add('fade-out')
    //set initial values for dropdowns
    initState.ivr_greet_long_pitch
      ? setGreetLong(
          data.find(
            audio =>
              slugify(audio.name, '_') === initState.ivr_greet_long_pitch,
          ),
        )
      : setGreetLong('')
    initState.ivr_greet_short_pitch
      ? setGreetShort(
          data.find(
            audio =>
              slugify(audio.name, '_') === initState.ivr_greet_short_pitch,
          ),
        )
      : setGreetShort('')
    initState.ivr_invalid_sound_pitch
      ? setInvalidSound(
          data.find(
            audio =>
              slugify(audio.name, '_') === initState.ivr_invalid_sound_pitch,
          ),
        )
      : setInvalidSound('')
    initState.ivr_exit_sound_pitch
      ? setExitSound(
          data.find(
            audio =>
              slugify(audio.name, '_') === initState.ivr_exit_sound_pitch,
          ),
        )
      : setExitSound('')
    setTimeout(function() {
      setShowButtons(false)
    }, 500)
  }

  const handleSave = () => {
    const callback = {
      ivr_greet_long_pitch: greetLong.name
        ? slugify(greetLong.name, '_')
        : null,
      ivr_greet_short_pitch: greetShort.name
        ? slugify(greetShort.name, '_')
        : null,
      ivr_invalid_sound_pitch: invalidSound.name
        ? slugify(invalidSound.name, '_')
        : null,
      ivr_exit_sound_pitch: exitSound.name
        ? slugify(exitSound.name, '_')
        : null,
    }
    axios
      .patch(`${API_BASE_URL}/identity/campaign/${uuid}/`, { callback })
      .then(res => {
        let element = document.getElementById('divButtons')
        element.classList.add('fade-out')
        setOpen(true)
        setInitState(res.data.callback)
        setTimeout(function() {
          setShowButtons(false)
        }, 500)
      })
      .catch(err => console.log(err))
  }

  const handlerPlayer = (type, value) => {
    let temp = { ...showPlayer }
    temp[type] = value
    setShowPlayer(temp)
  }

  const handleClose = () => {
    setOpen(false)
    setSnackCreate(false)
  }

  const handleOpenDialog = () => setAddDialogOpen(true)

  const cancelAddDialog = () => setAddDialogOpen(false)

  const updateResources = newItem => {
    setSnackCreate(true)
    setAddDialogOpen(false)
    setResources(false)
    setIsLoading(true)
    getData(companySlug, campaign)
  }

  useEffect(() => {
    if (window.location.origin.includes('localhost')) {
      setUrl('/#!/manage/audio/resources')
    }
    Header = {
      headers: {
        Authorization: `token ${JSON.parse(localStorage['ngStorage-ppToken'])}`,
      },
    }
    setTimeout(function() {
      getCampaign()
    }, 1000)
  }, [])

  const getCampaign = () => {
    let url = window.location.href.split('edit/')
    let camp = url[1].split('/')
    const slug = camp[0]
    axios
      .get(`${API_BASE_URL}/identity/campaign/list/?slug=${slug}`, Header)
      .then(res => {
        if (res.data[0].callback !== null) setInitState(res.data[0].callback)
        setUuid(res.data[0].uuid)
        setCampaign(res.data[0])
        getCompany(res.data[0])
      })
      .catch(err => console.log(err))
  }

  const getCompany = campaign => {
    axios
      .get(`${API_BASE_URL}/identity/company/${campaign.company}/`, Header)
      .then(res => {
        setCompanySlug(res.data.slug)
        getData(res.data.slug, campaign)
      })
      .catch(err => console.log(err))
  }

  const getData = (companySlug, campaign) => {
    axios
      .get(
        `${API_BASE_URL}/pitch/company/${companySlug}/audio/resources/`,
        Header,
      )
      .then(res => {
        if (res.data.length > 0) {
          //set initial values for dropdowns
          if (campaign.callback !== null) {
            campaign.callback.ivr_greet_long_pitch
              ? setGreetLong(
                  res.data.find(
                    audio =>
                      slugify(audio.name, '_') ===
                      campaign.callback.ivr_greet_long_pitch,
                  ),
                )
              : setGreetLong('')
            campaign.callback.ivr_greet_short_pitch
              ? setGreetShort(
                  res.data.find(
                    audio =>
                      slugify(audio.name, '_') ===
                      campaign.callback.ivr_greet_short_pitch,
                  ),
                )
              : setGreetShort('')
            campaign.callback.ivr_invalid_sound_pitch
              ? setInvalidSound(
                  res.data.find(
                    audio =>
                      slugify(audio.name, '_') ===
                      campaign.callback.ivr_invalid_sound_pitch,
                  ),
                )
              : setInvalidSound('')
            campaign.callback.ivr_exit_sound_pitch
              ? setExitSound(
                  res.data.find(
                    audio =>
                      slugify(audio.name, '_') ===
                      campaign.callback.ivr_exit_sound_pitch,
                  ),
                )
              : setExitSound('')
          }
          setData(res.data)
          setResources(true)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  const PlayButton = props => {
    return (
      <Link
        className={props.classes.btn}
        onClick={() => props.switch(props.nameControl, !props.showPlayer)}
      >
        {props.showPlayer ? (
          <span style={{ textDecoration: 'underline' }}>Close</span>
        ) : (
          <React.Fragment>
            <PlayIcon style={{ fontSize: '14px', marginRight: 8 }} />{' '}
            <span style={{ textDecoration: 'underline' }}>
              Click to play audio
            </span>
          </React.Fragment>
        )}
      </Link>
    )
  }

  const SelectAudioList = props => {
    return (
      <div>
        <AppSelect
          label={props.label}
          keyName={'uuid'}
          data={props.data}
          displayName={'name'}
          item={props.item}
          onChange={item => (props.setItem(item), setShowButtons(true))}
          helpText={
            props.item !== '' &&
            props.item.file !== null &&
            'name' in props.item ? (
              <PlayButton
                classes={classes}
                showPlayer={props.showPlayer}
                nameControl={props.objectName}
                switch={(type, value) => props.switch(type, value)}
              />
            ) : (
              'No audio resource'
            )
          }
        />
        {props.showPlayer && props.item.file !== null && (
          <audio
            controls
            style={{ width: 100 + '%' }}
            src={props.item.file.processed_url}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        )}
      </div>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      {isLoading ? (
        <React.Fragment>
          <Loader message="One moment. We're loading the data..." />
        </React.Fragment>
      ) : (
        <MuiThemeProvider theme={theme}>
          {!resources ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              className={classes.dialer}
            >
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <p className={classes.pStyled}>There is no IVR resources yet</p>
                <p className="grey-text">
                  To add or edit company audio resources just hit the button
                  "Add resources"
                </p>
                <br />
                <ActionButton handleClick={redirectAudio}>
                  <Add style={{ fontSize: 20, marginRight: 5 }} /> Add resources
                </ActionButton>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              style={{ paddingLeft: 5 }}
            >
              <Grid item xs={12}>
                <p style={{ color: '#444851', marginTop: '25px' }}>
                  To add company audio resources click{' '}
                  <span
                    className={classes.redirectLink}
                    onClick={handleOpenDialog}
                  >
                    <Add style={{ fontSize: 15 }} />
                    Add new resource
                  </span>
                </p>
              </Grid>
              <Grid item xs={6} className={classes.container6}>
                <SelectAudioList
                  label={'IVR greet long'}
                  data={data}
                  item={greetLong}
                  objectName={'greetLong'}
                  setItem={item => setGreetLong(item)}
                  showPlayer={showPlayer.greetLong}
                  switch={(type, value) => handlerPlayer(type, value)}
                />
              </Grid>
              <Grid item xs={6} className={classes.container6}>
                <SelectAudioList
                  label={'IVR Exit sound'}
                  data={data}
                  item={exitSound}
                  objectName={'exitSound'}
                  setItem={item => setExitSound(item)}
                  showPlayer={showPlayer.exitSound}
                  switch={(type, value) => handlerPlayer(type, value)}
                />
              </Grid>
              <Grid item xs={6} className={classes.container6}>
                <SelectAudioList
                  label={'IVR Greet short'}
                  data={data}
                  item={greetShort}
                  objectName={'greetShort'}
                  setItem={item => setGreetShort(item)}
                  showPlayer={showPlayer.greetShort}
                  switch={(type, value) => handlerPlayer(type, value)}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} className={classes.container6}>
                <SelectAudioList
                  label={'IVR Invalid Sound'}
                  data={data}
                  item={invalidSound}
                  objectName={'invalidSound'}
                  setItem={item => setInvalidSound(item)}
                  showPlayer={showPlayer.invalidSound}
                  switch={(type, value) => handlerPlayer(type, value)}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              {showButtons && (
                <Grid item xs={12} id="divButtons">
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                      <ActionButton
                        handleClick={handleSave}
                        buttonColor="secondary"
                      >
                        SAVE
                      </ActionButton>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <ActionButton
                        handleClick={handleCancel}
                        buttonColor="default"
                      >
                        CANCEL
                      </ActionButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          <Dialog
            className="newResourceModal"
            open={addDialogOpen}
            onClose={cancelAddDialog}
            maxWidth="xs"
            fullWidth={false}
            aria-labelledby="add-resource-dialog"
          >
            <NewResourceForm
              companySlug={companySlug}
              handleCreate={updateResources}
              handleCancel={cancelAddDialog}
            />
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackCreate}
            autoHideDuration={3000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">New resource has been created</span>}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Changes have been saved</span>}
          />
        </MuiThemeProvider>
      )}
    </MuiThemeProvider>
  )
}

export default ivr
