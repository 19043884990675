export default class TeamInfoFormController {
  constructor ($state) {
    'ngInject'
    this.$state = $state
  }

  backTo () {
    this.goingBack = true
    this.$state.go('manage.users.create.campaignInfo', { user: this.user }, { location: false })
  }

  goTo () {
    this.$state.go('manage.users.create.passwordInfo', { user: this.user }, { location: false })
  }
}
