import { contains, findIndex, propEq, compose, reduce, filter } from 'ramda'

export default class ComponentSettingsDialogController {
  constructor(
    $mdDialog,
    $mdToast,
    $injector,
    $timeout,
    $scope,
    PortalSettings,
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.$timeout = $timeout
    this.PS = PortalSettings
    this.metricService = $injector.get(this.serviceName)
    this.componentMetrics = this.getComponentMetrics(
      this.metricService.metrics,
      this.componentKey,
    )
    this.metricsObj = {
      value: this.userMetrics ? this.userMetrics.map(x => x.key) : [],
    }
    this.state = {
      saving: false,
      showJson: false,
      metricsJsonEdit: JSON.stringify(
        Object.assign({}, this.metricsObj),
        null,
        4,
      ),
      updateOnClose: false,
      userSettings: {},
      saveError: false,
      invalidKeyError: '',
      invalidJSON: '',
    }

    $scope.$watchCollection('cs.userMetrics', (newVal, oldVal) => {
      if (!newVal) return
      const keys = newVal.map(x => x.key)
      this.state.metricsJsonEdit = JSON.stringify({ value: keys }, null, 4)
    })
  }

  openJson() {
    this.state.showJson = !this.state.showJson
  }

  inList(metric, list) {
    return findIndex(propEq('key', metric.key))(list) > -1
  }

  toggle(metric, list) {
    const index = findIndex(propEq('key', metric.key))(list)
    if (index > -1) list.splice(index, 1)
    else list.push(metric)
  }

  getComponentMetrics(metrics, componentKey) {
    const metricKeys = Object.keys(metrics)
    const makeMetricArr = reduce((acc, key) => {
      acc.push(metrics[key])
      return acc
    }, [])
    const filterMetrics = filter(metric => {
      return findIndex(key => key === componentKey)(metric.componentKeys) > -1
    })
    return compose(
      filterMetrics,
      makeMetricArr,
    )(metricKeys)
  }

  addAllMetrics() {
    this.userMetrics = this.componentMetrics
  }

  addDefaultMetrics() {
    this.userMetrics = reduce(
      (acc, key) => {
        const metric = this.metricService.metrics[key]
        acc.push(metric)
        return acc
      },
      [],
      this.defaultMetrics,
    )
  }

  saveSettings(metrics) {
    this.state.saving = true
    const metricKeys = metrics.value ? metrics.value : metrics.map(x => x.key)
    return this.PS.user
      .one(`${this.company.slug}/${this.campaign.slug}/${this.componentKey}`)
      .customPOST({ value: metricKeys })
      .then(res => {
        this.state.saving = false
        this.state.updateOnClose = true
        this.state.userSettings = Object.assign({}, res.plain(), {
          key: this.componentKey,
        })
        this.close()
      })
      .catch(() => {
        this.state.saveError = true
        this.$timeout(() => {
          this.state.saveError = false
        }, 5000)
      })
  }

  saveSettingsJson(metricsJson, componentMetrics) {
    try {
      const metricsObj = JSON.parse(metricsJson)
      if (!this.validateMetricKeys(metricsObj.value, componentMetrics)) {
        this.state.invalidKeyError =
          'Invalid Metric Key, your JSON contains a metric key that is not allowed for the current component'
        this.$timeout(() => {
          this.state.invalidKeyError = ''
        }, 10000)
        return
      }
      this.saveSettings(metricsObj)
    } catch (e) {
      this.state.invalidJSON = 'JSON invalid, double check for typos'
      this.$timeout(() => {
        this.state.invalidJSON = ''
      }, 10000)
    }
  }

  validateMetricKeys(keys, componentMetrics) {
    const componentKeys = componentMetrics.map(x => x.key)
    const filteredKeys = filter(key => {
      return contains(key, componentKeys)
    }, keys)
    return filteredKeys.length === keys.length
  }

  resetJson() {
    this.state.metricsJsonEdit = JSON.stringify(
      Object.assign({}, this.metricsObj),
      null,
      4,
    )
  }

  copiedToast() {
    this.$mdToast.show(
      this.$mdToast
        .simple()
        .textContent('Copied Settings')
        .position('top right')
        .theme('default')
        .hideDelay(2000),
    )
  }

  close() {
    const update = this.state.updateOnClose
      ? this.$mdDialog.hide
      : this.$mdDialog.cancel
    update(this.state.userSettings)
  }
}
