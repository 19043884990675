export default class PitchVoicesController {
  constructor (EditPitchService) {
    'ngInject'
    this.EPS = EditPitchService
  }

  updatePitch (fields) {
    return this.EPS.updatePitchDetails(fields, this.service)
      .then(updatedPitch => {
        this.pitch = updatedPitch
        return updatedPitch
      })
    // errors handled in child component pitchDetails
  }
}
