// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'
import type { Campaign } from 'types/entities'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import MapIcon from '@material-ui/icons/Map';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RemoveIcon from '@material-ui/icons/Remove';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import TableLoader from 'common-components/loader/table-loader/TableLoader'
import CopyElement from 'common-components/copy-element/CopyElement'
import AsyncTable from 'common-components/async-table/AsyncTable'
import CampaignsDialog from 'manage/edit-realms/sub-component/CampaignsDialog'
import DeleteDialog from 'common-components/delete-dialog/DeleteDialog'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'
import CreateLoader from 'common-components/create-loader/CreateLoader'

import {
    ActiveCell,
    UnderlineCell,
} from 'common-components/table-cells/tableCells'

const API_BASE_URL = getBaseUrl(window.location.host, true)
const CancelToken = axios.CancelToken;
let cancel;

type Props = {
    classes: Object,
}

const styles = {
    addLink: {
        color: '#1194f6',
        cursor: 'pointer',
        '&:hover ': {
            textDecoration: 'none',
        }
    },
    IconBtn: {
        fontSize: 15,
        color: '#444851',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
    addCampaign: {
        backgroundColor: '#fafafa',
        padding: '15px',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#777777',
        marginBottom: 15,
    }
}


function AssociatedCampaigns(props: Props) {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [campaigns, setCampaigns] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [campaignSelected, setCampaignSelected] = useState({})
    const [open, setOpen] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const openAddDialog = () => {
        setCreateDialogOpen(true)
        setDeleteDialogOpen(false)
        setConfirmation(false)
    }

    const openDeleteDialog = (campaign => {
        setDeleteDialogOpen(true)
        setCampaignSelected(campaign)
    })

    const cancelDeleteDialog = () => {
        setDeleteDialogOpen(false)
        setConfirmation(false);
    }

    const cancelAddDialog = () => {
        setCreateDialogOpen(false);
        setConfirmation(false);
    }

    const updateCampaigns = () => {
        setLoadingDelete(true);

        axios.
            patch(`${API_BASE_URL}/identity/realm/${props.uuid}/`, {
                campaigns: campaigns.filter(el => el.uuid !== campaignSelected.uuid)
            }, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(response => {
                setLoadingDelete(false);
                setConfirmation(true);
                setCampaigns(response.data.campaigns);
            })
            .catch(console.error)
    }

    const createCampaigns = (selectedCampaigns) => {
        setCreateDialogOpen(false)
        axios.
            patch(`${API_BASE_URL}/identity/realm/${props.uuid}/`, {
                campaigns: [...selectedCampaigns, ...campaigns]
            })
            .then(response => {
                setCampaigns(response.data.campaigns);
                setOpen(true);
            })
            .catch(console.error)

    }

    const CampaignTableRow = ({
        campaign,
        rowClass,
        cellClass,
    }: {
        campaign: Campaign,
        rowClass: string,
        cellClass: string,
    }) => (
            <TableRow className={rowClass}>
                <UnderlineCell className={cellClass}>{campaign.name}
                </UnderlineCell>
                <TableCell className={cellClass} style={{ color: '#777777' }}>{campaign.slug}
                </TableCell>
                <TableCell className={cellClass} style={{ color: '#777777' }}>{campaign.uuid}
                    <CopyElement title="Copy UUID" value={campaign.uuid} response="UUID copied to clipboard" />
                </TableCell>
                <ActiveCell className={cellClass}>{campaign.active == true ? 'Active' : 'Inactive'}</ActiveCell>
                <TableCell className={cellClass} style={{ fontSize: '14px', textAlign: 'right', }}>
                    <IconButton disableRipple={true} className={props.classes.IconBtn}
                        onClick={() => openDeleteDialog(campaign)}>
                        <RemoveIcon style={{ fontSize: '14px', }} /> Remove
                    </IconButton>
                </TableCell>
            </TableRow>
        )

    useEffect(() => {
        if (props.uuid) {
            axios.
                get(`${API_BASE_URL}/identity/realm/${props.uuid}/`)
                .then(response => {
                    setCampaigns(response.data.campaigns);
                    setLoadingData(false)
                })
                .catch(console.error)
        }
    }, [props]);

    const handleClose = () => setOpen(false);

    const handleCancelDelete = () => {
        console.log("cancel creation");
        setLoadingDelete(false);
        cancel();
    }

    return (
        <React.Fragment>
            <div className={props.classes.addCampaign}>
                You can associate a campaign to this realm right from this page.
                <div style={{ display: 'Inline-block', float: 'right', }}>
                    <Link className={props.classes.addLink}
                        onClick={openAddDialog}
                    ><AddIcon style={{ fontSize: 16, marginRight: 5 }} />Add Campaign
                        </Link>
                    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message="Campaigns have been added"
                    />
                </div>
            </div>
            <AsyncTable
                loading={loadingData}
                Loader={<TableLoader
                    headerText="Management"
                    message="One moment, we are loading your content..."
                    Icon={MapIcon}
                />
                }
                tableData={campaigns}
                headers={['Name', 'Slug', 'UUID', 'Status', '']}
                render={(campaigns: Campaign[], { row, cell, icon }) =>
                    campaigns.map(campaign => (
                        <CampaignTableRow
                            key={campaign.uuid}
                            campaign={campaign}
                            rowClass={row}
                            cellClass={cell}
                            iconClass={icon}
                        />
                    ))
                }
            />

            <Dialog
                open={createDialogOpen}
                onClose={cancelAddDialog}
                maxWidth={'md'}
                fullWidth={true}
                aria-labelledby="add-campaign-dialog"
            >
                <CampaignsDialog
                    uuidRealm={props.uuid}
                    handleCancel={cancelAddDialog}
                    handleCreate={createCampaigns}
                />
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={cancelDeleteDialog}
                aria-labelledby="delete-campaign-dialog"
            >
                {confirmation ?

                    <ConfirmationDialog
                        message={'You have removed "' + campaignSelected.name +
                            '" from ' + props.nameRealm}
                        SubmitTxt="ADD CAMPAIGN"
                        handleCancel={cancelDeleteDialog}
                        handleCreateAnother={openAddDialog} />
                    :

                    loadingDelete ?
                        <React.Fragment>
                            <CreateLoader
                                message="One moment. We're removing the campaign..."
                                handleCancel={handleCancelDelete}
                            />
                        </React.Fragment> :
                        <DeleteDialog
                            message={'Are you sure you want to remove "' + campaignSelected.name +
                                '" from ' + props.nameRealm + "?"}
                            btnTxt="YES, REMOVE"
                            handleCancel={cancelDeleteDialog}
                            handleRemove={updateCampaigns}
                        />
                }

            </Dialog>

        </React.Fragment>
    )
}

export default withStyles(styles)(AssociatedCampaigns)
