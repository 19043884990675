import roundTo from 'round-to'
import FullscreenGraphController from '../../../../dialogs/fullscreen-graph/controllers/FullscreenGraphController.js'
import metricInfoDialog from '../../../../dialogs/metric-info/metricInfoDialog.js'
const fullScreenGraphTemplate = require('../../../../dialogs/fullscreen-graph/fullscreenGraph.tpl.pug')

export default class MetricHealthController {
  constructor ($mdDialog, abbreviateNumFilter, MetricHealthService) {
    'ngInject'
    this.MHS = MetricHealthService
    this.$mdDialog = $mdDialog
    this.abbreviateNumFilter = abbreviateNumFilter
  }

  $onInit () {
    this.getMetricHealth(this.metric)
    const maxMin = this.getMaxMin(this.metric.baseRange.days)
    this.maxStat = maxMin.max
    this.minStat = maxMin.min
    this.chartConfig = this.MHS.createChartConfig(this.metric, maxMin.max, maxMin.min)
  }

  $onChanges (bindings) {
    this.updateBindings(bindings) // update bindings to recalc metric health
    this.getMetricHealth(this.metric)
    const maxMin = this.getMaxMin(this.metric.baseRange.days)
    this.maxStat = maxMin.max
    this.minStat = maxMin.min
    this.chartConfig = this.MHS.createChartConfig(this.metric, maxMin.max, maxMin.min)
  }

  updateBindings (bindings) {
    const bndKeys = Object.keys(bindings)
    bndKeys.forEach((bnd) => {
      this[bnd] = bindings[bnd].currentValue
    })
  }

  getMetricHealth (metric) {
    this.ninetyDayAverage = metric.aggregate
      ? this.MHS.getNormalizedAvg(metric.baseRange.y, metric.baseRange.days)
      : metric.baseRange.y
    this.compareAverage = metric.compareRange.y
    const percentChange = this.MHS.getPercentChange(metric.baseRange.y, metric.compareRange.y)
    this.trend = metric.format.trend
      ? this.MHS.getTrend(percentChange, this.metric.format.sort)
      : null
    this.percentChange = percentChange
    this.goalHealth = this.MHS.getGoalHealth(metric.compareRange.y, metric.goal)
  }

  getMaxMin (statArray) {
    const max = this.MHS.getValueMax(statArray)
    const min = this.MHS.getValueMin(statArray)
    return {
      max: isFinite(max) ? roundTo(max, 2) : 0,
      min: isFinite(min) ? roundTo(min, 2) : 0
    }
  }

  openStatChart (event, chartConfig, metric) {
    const parentElement = angular.element(document.body)
    this.$mdDialog.show({
      parent: parentElement,
      targetEvent: event,
      autoWrap: false,
      template: fullScreenGraphTemplate(),
      bindToController: true,
      controllerAs: 'fs',
      locals: {
        title: metric.format.title,
        metricKey: metric.key,
        chartConfig: chartConfig
      },
      controller: FullscreenGraphController
    })
  }

  openInfo (ev, title, metricKey) {
    const MID = metricInfoDialog
    MID.targetEvent = ev
    MID.locals = {
      title: title,
      key: metricKey
    }
    this.$mdDialog.show(MID)
  }
}
