import './component/editCompany.scss'
import angular from 'angular'

// Services

// components
import editCompany from './component/editCompany.component.js'
import companySettings from './sub-components/company-settings/companySettings.component.js'
import companyPhraseBooks from './sub-components/company-phrase-books/companyPhraseBooks.component.js'
import companyAudioResources from './sub-components/company-audio-resources/companyAudioResources.component.js'
import companyRapidResponseTests from './sub-components/company-rapid-reponse-tests/component/companyRapidResponseTests.component'
import companyRapidResponseSegments from './sub-components/company-rapid-response-segments/component/companyRapidResponseSegments.component'
import companyRapidResponseVariables from './sub-components/company-rapid-response-variables/component/companyRapidResponseVariables.component'

export default angular
  .module('portalApp.manage.editCompany', [])
  .component('editCompany', editCompany)
  .component('companySettings', companySettings)
  .component('companyPhraseBooks', companyPhraseBooks)
  .component('companyAudioResources', companyAudioResources)
  .component('companyRapidResponseTests', companyRapidResponseTests)
  .component('companyRapidResponseSegments', companyRapidResponseSegments)
  .component('companyRapidResponseVariables', companyRapidResponseVariables)
  .name
