import {
  addIndex,
  compose,
  map,
  mapObjIndexed,
  prop,
  reduce,
  reverse,
  sortBy,
} from 'ramda'
import moment from 'moment'
import roundTo from 'round-to'
import { getDateDiff } from 'util/time.js'

class NoValidKeysError {
  constructor(status) {
    this.status = status
  }
}

export default class RepRankingsService {
  constructor(
    $q,
    PortalSettings,
    RepStatsService,
    StackedBarChart,
    MetricsService,
  ) {
    'ngInject'

    this.$q = $q
    this.PS = PortalSettings.user
    this.RSS = RepStatsService
    this.SBC = StackedBarChart
    this.getAllowedMetrics = MetricsService.getComponentMetrics.bind(
      MetricsService,
    )
    this.validateMetricKeys = MetricsService.validateMetricKeys.bind(
      MetricsService,
    )
    this.sumMetric = reduce(this.addValue, 0)
    this.totalMetricByRep = map(this.getMetricTotal.bind(this))
    this.totalRepStats = map(this.getRepTotals.bind(this))
  }

  getSettings(compSlug, campSlug, key) {
    return this.PS.get(`${compSlug}/${campSlug}/${key}`)
      .then(data => data.plain())
      .then(data => {
        const allowedKeys = this.getAllowedMetrics(key).map(x => x.key)
        const validatedKeys = this.validateMetricKeys(allowedKeys, data.value)
        if (validatedKeys.length && data.value.length > validatedKeys.length) {
          this.saveSettings(compSlug, campSlug, key, validatedKeys)
        } else if (!validatedKeys.length) {
          throw new NoValidKeysError(404)
        }
        return Object.assign(data, { value: validatedKeys, key })
      })
  }

  saveSettings(compSlug, campSlug, key, settings) {
    const user = this.PS.one(`${compSlug}/${campSlug}/${key}`)
    return user.customPOST({
      value: settings,
    })
  }

  buildCharts(component, dateRange, realm) {
    return this.RSS.getStats(component, dateRange, realm)
      .then(this.buildChartObjects.bind(this))
      .then(data => data)
  }

  getStats(companySlug, campSlug, startDate, endDate, currentDate) {
    const epochEnd = moment(endDate).unix()
    const dateDiff = getDateDiff(startDate, endDate, 'days')

    const promisedCustomRange = this.repStats
      .map(this.buildStatsArr(companySlug, campSlug, epochEnd, dateDiff))
      .map(this.RSS.getStat.bind(this.RSS))

    return this.$q
      .all({
        customRange: this.$q.all(promisedCustomRange),
      })
      .then(this.buildStatsTable.bind(this))
      .then(this.totalRepStats)
      .then(this.RSS.calcRepStats.bind(this.RSS))
      .then(this.buildChartObjects.bind(this))
  }

  buildStatsTable(stats) {
    const reduceIndexed = addIndex(reduce)
    return reduceIndexed(
      this.statsReducer(stats.customRange),
      {},
      this.repStats,
    )
  }

  statsReducer(stats) {
    return (statsTable, statObj, index) => {
      statsTable[statObj.stat] = stats[index]
      return statsTable
    }
  }

  addValue(accum, stat) {
    return accum + stat.value
  }

  getMetricTotal(rep) {
    rep.total = this.sumMetric(rep.data)
    return rep
  }

  getRepTotals(metric) {
    return this.totalMetricByRep(metric)
  }

  buildStatsArr(companySlug, campSlug, epoch, dateDiff) {
    return statObj => {
      statObj.companySlug = companySlug
      statObj.campSlug = campSlug
      statObj.dateDiff = dateDiff
      statObj.epochEnd = epoch
      return statObj
    }
  }

  sortByYAsc(data) {
    return compose(sortBy(prop('y')))(data)
  }

  sortByYDesc(data) {
    return compose(reverse, sortBy(prop('y')))(data)
  }

  chartBuilder(metric, key, metricsObj) {
    const chart = this.SBC.getChart()
    const series = {
      showInLegend: false,
      data:
        metric.format.sort === 'ascending'
          ? this.sortByYAsc(metric.data)
          : this.sortByYDesc(metric.data),
    }
    chart.options.plotOptions.series.dataLabels.formatter = function() {
      return `${this.point.format.prepend || ''}${roundTo(this.y, 2)}${this
        .point.format.append || ''}`
    }
    chart.xAxis.categories = map(x => x.rep, series.data)
    chart.title.text = metric.format.title
    chart.title.style.display = 'none'
    chart.size.height = metric.data.length > 1 ? metric.data.length * 60 : 125
    chart.series.push(series)
    chart.options.tooltip.formatter = function() {
      return `
        ${this.point.dividend.name}: <b>${roundTo(
        this.point.dividend.value,
        2,
      )}</b> </br >
        ${this.point.divisor.name}: <b>${roundTo(
        this.point.divisor.value,
        2,
      )}</b> </br >
        ${this.point.format.title}: <b>${this.point.format.prepend ||
        ''}${roundTo(this.point.y, 2)}${this.point.format.append || ''}</b>
      `
    }
    return chart
  }

  buildChartObjects(metrics) {
    return mapObjIndexed(this.chartBuilder.bind(this), metrics)
  }

  parseToCSV(chartConfig) {
    const data = chartConfig.series[0].data
    return data
  }
}
