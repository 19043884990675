import {
  CAMPAIGN_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'contactedConversionRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Contacted Conversion Rate',
  },
}

const keys = {
  componentKeys: [
    CAMPAIGN_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'qualified' },
  divisor: { stat: 'contacted' },
  isRate: true,
}

const contactedConversionRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { contactedConversionRate }
