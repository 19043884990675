// view for creating new location

const newLocation = 'manage.locations.newLocation'

const newLocationConfig = {
  url: '/location',
  component: 'rightDrawer'
}

export { newLocation, newLocationConfig }
