// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type ManagementService from '../../../services/ManagementService.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../../../global/index/services/ToastService.js'
import type ManageDidService from '../services/ManageDidService.js'
import type { BaseState } from '../../../controllers/ManagementController.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { Did } from 'types/entities.js'

import { ADMIN_GROUP } from 'permissions.js'
import { addColumns, containsRole } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'
import UDD from '../../../dialogs/update-did/updateDid.js'

export default class ManageDidController extends ManagementController {
  MDS: ManageDidService
  filterConfig: FilterConfig
  state: BaseState

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
    ManageDidService: ManageDidService,
  ) {
    'ngInject'
    super(...arguments)
    this.MDS = ManageDidService
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()

    this.entity = 'didAll'
    this.entityList = []
    this.tableConfig = this.bindActionCallbacks(this.MDS.tableConfig)
    this.searchConfig = this.MDS.searchConfig
    this.filterConfig = this.MDS.filterConfig
    this.searchConfig.editable = containsRole(
      userRoles,
      this.MDS.defaultEditRoles,
    )
    this.state.queryParams = this.getInitialParams(this.filterConfig)
    this.state.queryParams.limit = this.paginateLimit

    this.tableConfig.columns = addColumns({
      userRoles,
      allowedRoles: ADMIN_GROUP,
      sliceIndex: this.tableConfig.columns.length - 2,
      columns: this.tableConfig.columns,
      addColumns: this.tableConfig.optionalColumns,
    })
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }

  editDid(ev: Event, did: Did, index: number) {
    UDD.locals = { did }
    this.$mdDialog
      .show(UDD)
      .then(this.onDidUpdate.bind(this))
      .catch(err => {
        if (err) {
          console.error(err)
          this.TS.show({
            text:
              'Did Updated, but an error may require you to refresh the page',
          })
        }
      })
  }

  onDidUpdate(did: Did) {
    const entityListIndex = this.MS.getEntityIndex(
      did.uuid,
      this.entityList,
    )
    const searchListIndex = this.MS.getEntityIndex(
      did.uuid,
      this.searchConfig.searchResults,
    )
    this.MS.updateEntityInList(entityListIndex, this.entityList, did)
    this.MS.updateEntityInList(
      searchListIndex,
      this.searchConfig.searchResults,
      did,
    )
  }
}
