// Company Rapid Response Variables view

const manageEditCompanyRapidResponseVariables = 'manage.editCompany.rapidResponseVariables'

const manageEditCompanyRapidResponseVariablesConfig = {
  url: '/rapid-response-variables',
  component: 'companyRapidResponseVariables',
}

export { manageEditCompanyRapidResponseVariables, manageEditCompanyRapidResponseVariablesConfig }
