// @flow

import type AuthService from '../../../auth/services/AuthService.js'
import type ManageMenuService from '../../services/ManageMenuService.js'
import type { SideNavMenu } from '../../../common-components/side-nav/services/SideNavService.js'
import type { UserRoles } from 'permissions.js'
import type { ProfileMenuConfig } from '../../../common-components/profile-menu/profileMenu.js'

export default class ManageController {
  $mdSidenav: Object
  $mdComponentRegistry: Object
  $state: Object
  AuthService: AuthService
  MMS: ManageMenuService
  RS: Object
  menu: SideNavMenu
  userRoles: UserRoles
  profileMenuConfig: ProfileMenuConfig

  constructor(
    $mdSidenav: Object,
    $mdComponentRegistry: Object,
    $state: Object,
    AuthService: AuthService,
    ManageMenuService: ManageMenuService,
    RoleStore: Object,
  ) {
    'ngInject'
    this.$mdSidenav = $mdSidenav
    this.$state = $state
    this.AuthService = AuthService
    this.MMS = ManageMenuService
    this.RS = RoleStore
    this.profileMenuConfig = {
      sections: [
        {
          title: 'Change Password',
          icon: 'action:face',
          actionCb: () => this.$state.go('changePassword'),
        },
        {
          title: 'Logout',
          icon: 'action:power_settings_new',
          actionCb: () => this.logout(),
        },
      ],
    }
  }

  $onInit(): void {
    this.menu = this.MMS.getMenu()
    this.userRoles = this.RS.getAllRoles()
  }

  logout(): void {
    this.AuthService.logout()
  }

  openMenu($mdMenu: Object, $event: Event): void {
    $mdMenu.open($event)
  }
}
