
import angular from 'angular'

// Services
import EditAccessListService from './services/EditAccessListService.js'

// components
import editAccessList from './component/editAccessList.component.js'

export default angular
  .module('portalApp.manage.editAccessList', [])
  .service('EditAccessListService', EditAccessListService)
  .component('editAccessList', editAccessList)
  .name
