
class NoGoalsError {
  constructor (message) {
    this.message = message
  }
}

export default class GoalsService {
  constructor (PortalSettings, MetricsService) {
    'ngInject'
    this.MS = MetricsService
    this.CS = PortalSettings.campaign
    this.activeGoalMetrics = ['salesPerBillableHour', 'revenuePerBillableHour']
  }

  getMetricGoals (details) {
    return this.CS.get(`${details.compSlug}/${details.campSlug}/metric_goals`)
      .then((data) => {
        const goals = data.value || null
        if (!goals || !goals.length) throw new NoGoalsError('no_saved_goals')
        return goals
      })
      .catch(() => {
        return this.makeDefaultGoals(this.activeGoalMetrics)
      })
  }

  saveMetricGoals (details, goals) {
    const campaignGoals = this.CS.one(`${details.compSlug}/${details.campSlug}/metric_goals`)
    return campaignGoals.customPOST({
      value: goals
    })
    .then((data) => {
      const goals = data.value
      if (!goals.length) throw new NoGoalsError('No Saved Goals')
      return goals
    })
    .catch(() => {
      return this.makeDefaultGoals(this.activeGoalMetrics)
    })
  }

  makeDefaultGoals (activeMetrics) {
    return activeMetrics.map((metricKey) => {
      return {
        key: metricKey,
        format: this.MS.metrics[metricKey].format,
        active: false,
        threshold: {
          ok: null,
          warn: null,
          danger: null
        }
      }
    })
  }
}
