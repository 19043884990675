// @flow

import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type { NavItems } from '../../../../common-components/title-bar-tab-nav/component/titleBarTabNav.js'

type GlobalRapidResponseStates =
  | 'manage.globalRapidResponse.tests'
  | 'manage.globalRapidResponse.segments'

export default class ManageGlobalRapidResponseController {
  PS: PitchService
  $state: Object
  navItems: NavItems
  state: {
    currentNavItem: ?GlobalRapidResponseStates,
  }

  constructor(PitchService: PitchService, $state: Object) {
    'ngInject'
    this.PS = PitchService
    this.$state = $state
    this.navItems = [
      {
        name: 'Response Tests',
        route: 'manage.globalRapidResponse.tests',
        largeButton: true,
      },
      {
        name: 'Response Segments',
        route: 'manage.globalRapidResponse.segments',
        largeButton: true,
      },
    ]
    this.state = {
      currentNavItem: null,
    }
  }

  $onInit() {
    this.state.currentNavItem = this.$state.current.name
  }
}
