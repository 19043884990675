import moment from 'moment'
import { update } from 'ramda'

export default class GoalsController {
  constructor(GoalsService) {
    'ngInject'
    this.GS = GoalsService
  }

  $onInit() {
    this.state = {
      details: {
        compSlug: this.company.slug,
        campSlug: this.campaign.slug,
      },
      goals: {
        allGoals: [],
        right: [],
        left: [],
      },
    }

    this.getGoals(this.state.details)
  }

  getGoals(details) {
    return this.GS.getMetricGoals(details)
      .then(this.splitGoals)
      .then(this.setGoalsOnController.bind(this))
  }

  saveGoals(details, goals) {
    this.state.saving = true
    this.GS.saveMetricGoals(details, goals).catch(console.error)
  }

  setGoalsOnController(goals) {
    this.state.allGoals = goals.allGoals
    this.state.goals.right = goals.right
    this.state.goals.left = goals.left
  }

  splitGoals(goals) {
    const splitter = (acc, goal, index) => {
      goal.index = index
      index % 2 === 0 ? acc.left.push(goal) : acc.right.push(goal)
      return acc
    }
    return goals.reduce(splitter, { left: [], right: [], allGoals: goals })
  }

  updateGoal(goal) {
    goal.lastSave = moment().toDate()
    const newGoals = update(goal.index, goal, this.state.allGoals).map(
      metric => {
        delete metric.$$hashKey
        return metric
      },
    )
    this.saveGoals(this.state.details, newGoals)
  }
}
