import EditAccessListNodeFormController from './controller/EditAccessListNodeFormController.js'
const template = require('./editAccessListNodeForm.tpl.pug')

const editAccessListNodeForm = {
  template: template(),
  controller: EditAccessListNodeFormController,
  bindings: {
    accessList: '<',
    node: '<',
    company: '<'
  }
}

export default editAccessListNodeForm
