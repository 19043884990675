import OGD from '../../../../dialogs/options-group/optionsGroupDialog.js'

export default class PitchOptionGroupsController {
  constructor($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  $onInit() {
    this.state = {
      loading: true,
      optionGroups: [],
      dialogs: {
        createOptionsGroup: OGD,
      },
    }
  }

  $onChanges(bindings) {
    if (bindings.guiService) {
      this.optionGroupsService = this.guiService.one('field-option-group')
      this.getFieldOptionGroups()
    }
  }

  getFieldOptionGroups() {
    this.optionGroupsService
      .get()
      .then(groups => groups.plain())
      .then(groups => {
        this.state.optionGroups = groups
      })
      .catch(console.error)
      .finally(() => {
        this.state.loading = false
      })
  }

  createOptionsGroup(optionGroup) {
    this.openDialog('createOptionsGroup', {
      service: this.optionGroupsService,
    })
      .then(createdOptionsGroup => {
        this.state.optionGroups.push(createdOptionsGroup)
      })
      .catch(console.error)
  }

  openDialog(dialogKey = '', locals = {}) {
    const dialog = this.state.dialogs[dialogKey]
    dialog.locals = locals
    return this.$mdDialog.show(dialog)
  }

  removeGroup() {
    this.state.optionGroups.splice(this.selectedTabIndex, 1)
  }
}
