import { REP_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'utilizationRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Utilization Rate',
  },
}

const keys = {
  componentKeys: [REP_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'talk_time', convertTo: 'hours' },
  divisor: { stat: 'billable_hours', convertTo: 'hours' },
  isRate: true,
}

const utilizationRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { utilizationRate }
