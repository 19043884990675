export default class RightDrawerController {
  constructor ($document, $state) {
    'ngInject'
    this.$document = $document
    this.eventHandler = (e) => {
      if (e.key === 'Escape') {
        $state.go('^.^')
      }
    }
  }

  $onInit () {
    this.$document.on('keydown', this.eventHandler)
  }

  $onDestroy () {
    this.$document.off('keydown', this.eventHandler)
  }
}
