import React, { useState, useEffect } from 'react'
import './statescontries.scss'

const statescontries = props => {
  const [states, setStates] = useState([
    { name: 'Alabama' },
    { name: 'New York' },
    { name: 'Arizona' },
    { name: 'Florida' },
    { name: 'Ohio' },
    { name: 'Los Angeles' },
  ])

  const [statesAdded, setStatesAdded] = useState([])
  const [search, setSearch] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const addAll = () => {
    let stateList = [...states]
    let addedList = [...statesAdded]
    let newArray = addedList.concat(stateList)
    setStatesAdded(newArray)
    setStates([])
  }

  const addItem = item => {
    let stateList = [...states]
    stateList = arrayRemove(stateList, item.name)
    setStates(stateList)

    let addedList = [...statesAdded]
    addedList.push(item)
    setStatesAdded(addedList)
  }

  const removeItem = item => {
    let addedList = [...statesAdded]
    addedList = arrayRemove(addedList, item.name)
    setStatesAdded(addedList)

    let stateList = [...states]
    stateList.push(item)
    setStates(stateList)
  }

  const arrayRemove = (arr, value) => arr.filter(ele => ele.name != value)

  useEffect(() => {
    setSearchResults(states)
  }, [states])

  useEffect(() => {
    props.itemsSelected(statesAdded)
  }, [statesAdded])

  useEffect(() => {
    const results = states.filter(state =>
      state.name.toLowerCase().includes(searchText),
    )
    setSearchResults(results)
  }, [searchText])

  return (
    <div className="states-wrapper">
      <div className="row mb-0">
        <div className="col s6 p-0">
          <div className="row mb-0">
            <div className="col s12">
              <div className="border-bottom" style={{ padding: 14 }}>
                <span style={{ color: '#' + 777777 }}>
                  {statesAdded.length} items selected
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col s12">
              <ul
                id="scroll"
                className={
                  'listAdded ' + (statesAdded.length < 5 ? 'scroll-hidden' : '')
                }
              >
                {statesAdded.map((state, index) => (
                  <li key={index}>
                    {state.name}
                    <span className="right">
                      <i
                        style={{ marginTop: 4, marginRight: 7 }}
                        className="tiny material-icons left"
                      >
                        remove
                      </i>
                      <span
                        onClick={() => removeItem(state)}
                        className="text-underline cursor-pointer"
                      >
                        Remove
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col s6 p-0">
          <div className="row mb-0">
            <div className="col s12">
              <div
                className="border-bottom border-left"
                style={{ padding: 14 }}
              >
                <span style={{ color: '#' + 777777 }}>Add items</span>
                <span className="right">
                  <span onClick={addAll} className="cursor-pointer text-blue">
                    Add all
                  </span>
                  <i
                    className="material-icons right cursor-pointer text-blue"
                    style={{ fontSize: 20 }}
                    onClick={() => setSearch(true)}
                  >
                    search
                  </i>
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col s12">
              {search && (
                <div className="row m-0 border-bottom border-left">
                  <div className="col s10" style={{ paddingLeft: 14 }}>
                    <input
                      style={{
                        fontSize: 14,
                        paddingLeft: 5,
                        marginBottom: 3,
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}
                    />
                  </div>
                  <div
                    className="col s2"
                    style={{ paddingTop: 17, paddingRight: 14 }}
                    onClick={() => setSearch(false)}
                  >
                    <span className="text-underline cursor-pointer right">
                      Cancel
                    </span>
                  </div>
                </div>
              )}
              <ul
                id="scroll"
                className={
                  'stateList border-left ' +
                  (states.length < 5 ? 'scroll-hidden' : '')
                }
              >
                {searchResults.map((state, index) => (
                  <li key={index}>
                    {state.name}
                    <span className="right">
                      <i
                        style={{ marginTop: 4, marginRight: 7 }}
                        className="tiny material-icons left"
                      >
                        add
                      </i>
                      <span
                        onClick={() => addItem(state)}
                        className="text-underline cursor-pointer"
                      >
                        Add
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default statescontries
