import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
import { MANAGE_GROUP } from 'permissions.js'

// manage audio resources view

const MANAGE_AUDIO_RESOURCES = 'manage.audioResources'
const pageTitleConfig = {
  title: 'Manage Audio Resources',
}

const manageAudioResourcesConfig = {
  url: '/audio/resources',
  component: 'manageAudioResources',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  },
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig)
  }
}

export { MANAGE_AUDIO_RESOURCES, manageAudioResourcesConfig }

