
class NoValidKeysError {
  constructor (status) {
    this.status = status
  }
}

export default class CampaignHealthService {
  constructor (PortalSettings, CampaignStatsService, MetricsService) {
    'ngInject'

    this.PS = PortalSettings
    this.getAllowedMetrics = MetricsService.getComponentMetrics.bind(MetricsService)
    this.validateMetricKeys = MetricsService.validateMetricKeys.bind(MetricsService)
    this.CSS = CampaignStatsService
    this.components = {}
    this.stats = {}
  }

  getSettings (compSlug, campSlug, key) {
    return this.PS.user.get(`${compSlug}/${campSlug}/${key}`)
      .then(data => data.plain())
      .then(data => {
        const allowedKeys = this.getAllowedMetrics(key).map(x => x.key)
        const validatedKeys = this.validateMetricKeys(allowedKeys, data.value)
        if (validatedKeys.length && data.value.length > validatedKeys.length) {
          this.saveSettings(compSlug, campSlug, key, validatedKeys)
        } else if (!validatedKeys.length) {
          throw new NoValidKeysError(404)
        }
        return Object.assign(data, { value: validatedKeys, key })
      })
  }

  saveSettings (compSlug, campSlug, componentKey, settingsList) {
    const metricKeys = settingsList.map((x) => {
      if (typeof x === 'string') return x
      return x.key
    })
    return this.PS.user
      .one(`${compSlug}/${campSlug}/${componentKey}`)
      .customPOST({ value: metricKeys })
  }

  registerComponent (component, compSlug, campSlug) {
    this.components[component.key] = component
    this.components[component.key].company = compSlug
    this.components[component.key].campaign = campSlug
  }

  buildMetrics (key, dateRange, realm) {
    const component = this.components[key]
    return this.CSS.getStats(component, dateRange, realm)
      .then((data) => {
        this.components[key].metricObjects = data.metrics
        return data.results
      })
  }
}
