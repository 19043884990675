export default class ManagementAsyncTableController {
  constructor(ToastService) {
    'ngInject'
    this.TS = ToastService
  }

  $onInit() {
    this.trackBy = this.tableConfig.trackBy
    this.tableConfig.columns.forEach(column => {
      if (column.accessor === 'uuid') {
        column.copyable = true
      }
    })
  }

  cancelBubble(ev) {
    ev.cancelBubble = true
  }

  handleCopy(name) {
    this.TS.show({
      text: name ? `${name} copied to clipboard` : 'Copied to clipboard',
    })
  }

  isChecked() {
    let checked = false
    for (let i = 0; i < this.tableConfig.columns.length; i++) {
      if (this.tableConfig.columns[i].type == 'checkbox') {
        let accessor = this.tableConfig.columns[i].accessor
        let checkAccessor = true
        for (let i = 0; i < this.tableData.length; i++) {
          if (!this.tableData[i][accessor].value) {
            checkAccessor = false
            break
          }
        }
        checked = checkAccessor
      }
    }
    return checked
  }

  selectAll() {
    let isChecked = this.isChecked()
    for (let i = 0; i < this.tableConfig.columns.length; i++) {
      if (this.tableConfig.columns[i].type == 'checkbox') {
        let accessor = this.tableConfig.columns[i].accessor
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i][accessor].value = !isChecked
        }
      }
    }
  }
}
