import { findIndex, propEq } from 'ramda'
import DED from '../../../dialogs/delete-entity/deleteEntity.js'
import FOD from '../../../dialogs/field-option/fieldOptionDialog.js'

export default class PitchOptionGroupController {
  constructor($mdDialog, PitchService, $state) {
    'ngInject'

    this.PS = PitchService
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.state = {
      loadingOptions: true,
      options: [],
    }
  }

  $onInit() {
    this.groupUUID = this.$state.params.uuid
    this.groupService = this.PS.setupBase().global.one(
      `gui/field-option-group/${this.groupUUID}`,
    )
    this.optionsService = this.PS.setupBase().global.one(
      `gui/field-option-group/${this.groupUUID}/options`,
    )
    this.getGroup()
  }

  getGroup() {
    this.groupService
      .get()
      .then(group => group.plain())
      .then(group => {
        this.group = group
        this.editableGroup = Object.assign({}, this.group)
      })
      .catch(console.error)
      .finally(() => {
        this.fetchOptions()
      })
  }

  fetchOptions() {
    this.state.loadingFields = true
    this.optionsService
      .get()
      .then(options => options.plain())
      .then(options => {
        this.state.options = options
      })
      .catch(console.error)
      .finally(() => {
        this.state.loadingOptions = false
      })
  }

  resetGroup() {
    this.editableGroup = Object.assign({}, this.group)
    this.groupForm.$setPristine()
  }

  saveGroup(group) {
    delete group.options // nested field not writable
    this.groupService
      .patch(group)
      .then(updatedGroup => {
        Object.assign(this.group, updatedGroup)
        this.editableGroup = Object.assign({}, updatedGroup)
        this.groupForm.$setPristine()
      })
      .catch(console.error)
  }

  updateOrCreateOption(group, option) {
    FOD.locals = {
      isCreate: !option,
      groupId: group.uuid,
      option: Object.assign({}, option),
      service: this.optionsService,
    }
    this.$mdDialog.show(FOD).then(({ wasCreate, option }) => {
      if (!wasCreate) {
        this.updateOrRemoveOption(option, { update: true })
        return
      }
      this.state.options.unshift(option)
    })
  }

  deleteGroup(group) {
    DED.locals = {
      title: 'Delete Field Option Group',
      entityName: group.name,
      identityEndpoint: this.groupService,
      routeWhenSuccessful: 'manage.globalOptionGroup',
    }
    this.$mdDialog.show(DED)
  }

  updateOrRemoveOption(option = {}, config = {}) {
    const { update } = config
    const index = findIndex(propEq('uuid', option.uuid))(this.state.options)
    update
      ? this.state.options.splice(index, 1, option)
      : this.state.options.splice(index, 1)
  }

  deleteOption(option) {
    DED.locals = {
      title: 'Delete Field Option',
      entityName: option.description,
      identityEndpoint: this.optionsService.one(option.uuid),
      routeWhenSuccessful: this.$state.current,
    }
    this.$mdDialog.show(DED)
  }
}
