// @flow

import type { FilterListConfig } from '../../../../common-components/filter-list/component/filterList.js'
import type { PhraseBook } from 'types/entities.js'
import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../global/index/services/ToastService.js'

import { filter } from 'ramda'
import CPBD from '../../../dialogs/create-phrase-book/createPhraseBook'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'

export default class GlobalPhraseBooksController {
  PS: PitchService
  TS: ToastService
  MS: Object
  PhraseBooks: Object
  $state: Object
  $mdDialog: Object
  searchPhraseBook: (searchObject: { searchText: string }) => void
  columnsConfig: FilterListConfig
  state: {
    loading: boolean,
    searchText: string,
    globalPhraseBooks: Array<PhraseBook>,
  }

  constructor(
    PitchService: PitchService,
    ManagementService: Object,
    ToastService: ToastService,
    $state: Object,
    $mdDialog: Object,
  ) {
    'ngInject'

    this.MS = ManagementService
    this.$state = $state
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog

    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      {
        title: 'Edit',
        type: 'button',
        icon: 'editor:mode_edit',
        actionCb: phraseBook => this.editPhraseBook(phraseBook),
      },
    ]

    this.searchPhraseBook = ({ searchText }) => {
      this.state.searchText = searchText
    }

    this.state = {
      loading: true,
      searchText: '',
      globalPhraseBooks: [],
      topBarButtons: [
        {
          title: 'New Phrase Book',
          icon: 'content:add',
          action: this.createPhraseBook.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onInit() {
    this.PhraseBooks = this.PS.setupPhraseBooks().phraseBooks
    this.getPhraseBooks()
  }

  getPhraseBooks() {
    this.PhraseBooks.get()
      .then(phraseBooks => phraseBooks.plain())
      .then(phraseBooks => {
        this.state.globalPhraseBooks = phraseBooks
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching phrasebooks',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  createPhraseBook() {
    CPBD.locals = {
      isCreate: true,
      service: this.PhraseBooks,
    }
    this.$mdDialog
      .show(CPBD)
      .then(createdPhraseBook => {
        this.state.globalPhraseBooks.push(createdPhraseBook)
      })
      .catch(err => {
        if (err) console.error(err)
      })
  }

  editPhraseBook(phraseBook: PhraseBook) {
    this.$state.go('manage.editPhraseBook', {
      phraseBookId: phraseBook.uuid,
      phraseBook: phraseBook,
    })
  }

  deletePhraseBook(ev: Event, phraseBook: PhraseBook) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Global Phrase Book',
      entityName: phraseBook.name,
      identityEndpoint: this.PhraseBooks.one(phraseBook.uuid),
      entity: phraseBook,
      routeWhenSuccessful: 'manage.globalPhraseBooks',
    }
    this.$mdDialog.show(DED).then(() => {
      this.state.globalPhraseBooks = filter(
        item => item.uuid !== phraseBook.uuid,
        this.state.globalPhraseBooks,
      )
    })
  }
}
