// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../global/index/services/ToastService.js'
import type IdentityService, {
  IdentityCampaign,
} from '../../../../global/index/api-services/IdentityService.js'
import type { PermissionGroup } from 'permissions.js'
import type { BaseState } from '../../../controllers/ManagementController.js'

import { find, propEq } from 'ramda'
import { MANAGE_GROUP } from 'permissions.js'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import ManagementController from '../../../controllers/ManagementController.js'

import type {
  Campaign,
  Company,
  DidPool,
  Locale,
  VoiceProvider,
} from 'types/entities.js'

export default class EditDidPoolController extends ManagementController {
  companies: Company[] // passed to component
  campaigns: Campaign[] // passed to component
  pool: DidPool // passed to component
  editablePool: DidPool
  voiceProviders: VoiceProvider[]
  MANAGE_GROUP: PermissionGroup
  IS: IdentityService
  TS: ToastService
  DIDS: Object
  Campaign: IdentityCampaign
  poolEditForm: Object
  state: BaseState & {
    locales: Locale[],
    goBack: {
      name: string,
      route: 'manage.didpools',
    },
  }

  constructor(
    $state: Object,
    $timeout: Object,
    $mdDialog: Object,
    IdentityService: IdentityService,
    PitchService: PitchService,
    ToastService: ToastService,
    ManageDidPoolService: Object,
    RoleStore: Object,
    ManagementService: Object,
    DidListService: Object,
  ) {
    'ngInject'
    super(...arguments)

    this.Campaign = IdentityService.Campaign
    this.DIDS = DidListService
    this.TS = ToastService
    this.IS = IdentityService
    this.$mdDialog = $mdDialog
    this.MANAGE_GROUP = MANAGE_GROUP
    this.state = {
      ...ManagementController.baseState,
      locales: [
        { name: 'United States / Canada', value: 'US' },
        { name: 'Great Britian', value: 'GB' },
        { name: 'Australia', value: 'AU' },
        { name: 'Singapore', value: 'SG' },
      ],
      goBack: {
        name: 'did pools',
        route: 'manage.didpools',
      },
    }
  }

  handleCopy() {
    this.TS.show({
      text: 'Copied To Clipboard',
    })
  }

  toggleActive(bool: boolean) {
    this.editablePool.active = bool
  }

  $onInit() {
    this.editablePool = Object.assign({}, this.pool)
  }

  changeCompany(pool: DidPool) {
    this.editablePool.campaign = ''
    this.Campaign.list
      .get({
        assignable: true,
        company: this.editablePool.company,
      })
      .then(camps => camps.plain())
      .then(camps => {
        this.campaigns = camps
        if (pool.company === this.pool.company) {
          this.editablePool.campaign = this.pool.campaign
        }
      })
  }

  backTo() {
    this.$state.go(this.state.goBack.route)
  }

  updatePool(updatedPool: DidPool) {
    const uriData = {
      company: find(propEq('uuid', updatedPool.company))(this.companies),
      campaign: find(propEq('uuid', updatedPool.campaign))(this.campaigns),
      uuid: updatedPool.uuid,
    }
    this.DIDS.editPool
      .patch(updatedPool, uriData)
      .then(updatedDidPool => {
        if (updatedDidPool.errors) {
          throw new Error(updatedDidPool.errors)
        }
        this.poolEditForm.$setPristine()
        this.TS.show({
          text: 'Did pool has been successfully updated',
        })
        this.pool = updatedDidPool
        this.editablePool = { ...this.pool }
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error updating did pool',
        })
      })
  }

  getPoolCompany() {
    return find(propEq('uuid', this.pool.company))(this.companies)
  }

  getPoolCampaign() {
    return find(propEq('uuid', this.pool.campaign))(this.campaigns)
  }

  cancelEdit(form: Object) {
    this.editablePool = Object.assign({}, this.pool)
    this.changeCompany(this.editablePool)
    form.$setPristine()
  }

  deletePool(pool: DidPool) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Did Pool',
      entityName: pool.name,
      identityEndpoint: this.IS.DidPool.one(pool.uuid),
      entity: pool,
      routeWhenSuccessful: this.state.goBack.route,
    }
    this.$mdDialog.show(DED).then(() => {})
  }
}
