import ManageCompaniesController from './controller/ManageCompaniesController.js'
const template = require('./manageCompanies.component.tpl.pug')

const manageCompanies = {
  template: template(),
  controller: ManageCompaniesController,
  bindings: {}
}

export default manageCompanies
