// @flow

import type IdentityService from '../../../global/index/api-services/IdentityService.js'
import type SideNavService from 'common-components/side-nav/services/SideNavService.js'
import type RoleStore from '../../../../permissions/role-store/services/RoleStore.js'
import type { Page } from 'common-components/side-nav/services/SideNavService.js'
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from 'axios'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import PersonIcon from '@material-ui/icons/Person'
import ArrowBack from '@material-ui/icons/ArrowBackIos'

import ProspectPanel from 'manage/edit-campaign-qa-prospect/sub-component/ProspectPanel'

import { theme } from 'theme'
import './qaSettings.scss'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
  // All Angular services
  IdentityService: IdentityService,
  SideNavService: SideNavService,
  ManageMenuService: Object,
  RoleStore: RoleStore,
  $state: Object,
}

type State = {
  active: boolean,
  activeTxt: String,
  name: string,
  slug: string,
  uuid: string,
}

export class QAprospectSettings extends React.Component<Props, State> {
  state = {
    active: false,
    activeTxt: '',
    name: '',
    slug: '',
    uuid: '',
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Grid
          style={{ padding: '30px 0 36px' }}
          container
          justify="space-between"
          alignItems="center"
        >
          <div style={{ width: '100%', marginBottom: '25px' }}>
            <a
              href={
                window.location.origin +
                window.location.pathname +
                '#!/manage/campaigns'
              }
              style={{
                color: '#1194f6',
                fontSize: '16px',
                textDecoration: 'none',
              }}
            >
              <ArrowBack style={{ fontSize: '12px' }} /> Back to QA Prospects
            </a>
          </div>
          <p style={{ fontSize: 24, margin: '0', color: '#444851' }}>
            Samuel Maira
          </p>
        </Grid>
        <Paper style={{ width: '100%', overflowX: 'auto', padding: '30px' }}>
          <p style={{ margin: '0 0 20px 0', color: '#444851' }}>
            <strong>Prospect settings</strong>
          </p>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <table className="settingsTable">
                <tbody>
                  <tr>
                    <td>Call UUID:</td>
                    <td>aae-f567-11e9-ba83-0242ac110014</td>
                  </tr>
                  <tr>
                    <td>Campaign:</td>
                    <td>2344</td>
                  </tr>
                  <tr>
                    <td>Lead ID:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Hangup cause:</td>
                    <td>Destination_out_of_order</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12} md={6}>
              <table className="settingsTable">
                <tbody>
                  <tr>
                    <td>Call data:</td>
                    <td>Jan 8, 2019, 5:10 pm</td>
                  </tr>
                  <tr>
                    <td>Prospect:</td>
                    <td>Samuel Maria (167553e-111-2221ffd)</td>
                  </tr>
                  <tr>
                    <td>Affiliate:</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Carrier:</td>
                    <td>veracity.vp.perfectpitchtech.com</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>

          <hr />

          <Grid container spacing={3}>
            <Grid item xs={9}>
              <audio
                style={{ width: '100%' }}
                ref="audio_tag"
                src="./static/music/foo.mp3"
                controls
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ textAlign: 'right', alignSelf: 'center' }}
            >
              <PersonIcon style={{ fontSize: '16px' }} /> <u>Prospect Info</u>
            </Grid>
          </Grid>

          <hr />
          <ProspectPanel />
        </Paper>
      </MuiThemeProvider>
    )
  }
}
