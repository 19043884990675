const backToRoute = {
  name: 'overview',
  route: `dashboard.overview({slug: $ctrl.campaign.slug, realmSlug: $ctrl.realm.slug})`,
}

const dialerLiveColumnsConfig = [
  {
    title: 'Server',
    accessor: 'realm',
    type: 'string',
    total: 'tag',
    thSize: '40',
  },
  {
    title: 'Total reps',
    accessor: 'total_reps',
    total: 'sum',
    thSize: '20',
    ordering: 'desc',
  },
  {
    title: 'Stations per Rep / Billable',
    accessor: 'stations_per_rep_and_billable',
    thSize: '93',
  },
  {
    title: 'Total stations',
    accessor: 'total_stations',
    total: 'sum',
    thSize: '40',
    ordering: 'desc',
  },
  {
    title: 'Available stations',
    accessor: 'available_stations',
    total: 'sum',
    thSize: '60',
    ordering: 'desc',
  },
  {
    title: 'Waiting stations',
    accessor: 'waiting_stations',
    total: 'sum',
    thSize: '50',
    ordering: 'desc',
  },
  {
    title: 'Call ratio',
    accessor: 'campaign_call_ratio',
    thSize: '65',
  },
  {
    title: 'Requested / Ringing calls',
    accessor: 'requested_ringing_calls',
    thSize: '35',
  },
  {
    title: 'Inqueue / Connected calls',
    accessor: 'inqueue_connected_calls',
    thSize: '50',
  },
  {
    title: 'Abandon (5/15/60 min)',
    accessor: 'abandonment_5_15_60_min',
    thSize: '70',
  },
  {
    title: 'Active / Dialable prospects',
    accessor: 'active_dialable_prospects',
    thSize: '140',
  },
]

export { backToRoute, dialerLiveColumnsConfig }
