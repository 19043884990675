// Manage Edit Rapid Response Tests
import {
  manageEditRapidResponseTests,
  manageEditRapidResponseTestsConfig,
} from './manage.editRapidResponseTests'
import {
  manageEditRapidResponseTestsMenu,
  manageEditRapidResponseTestsMenuConfig,
} from './child-states/edit-rapid-response-tests-menu/editRapidResponseTests.menu'
import {
  manageEditRapidResponseTestsSegments,
  manageEditRapidResponseTestsSegmentsConfig,
} from './child-states/edit-rapid-response-tests-segments/editRapidResponseTests.segments'
import {
  manageEditRapidResponseTestsSettings,
  manageEditRapidResponseTestsSettingsConfig,
} from './child-states/edit-rapid-response-tests-settings/editRapidResponseTests.settings'
import {
  manageEditRapidResponseTestsVoices,
  manageEditRapidResponseTestsVoicesConfig,
} from './child-states/edit-rapid-response-tests-voices/editRapidResponseTests.voices'
import {
  manageEditRapidResponseTestsVariables,
  manageEditRapidResponseTestsVariablesConfig,
} from './child-states/edit-rapid-response-tests-variables/editRapidResponseTests.variables'
export default function manageEditRapidResponseTestsRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditRapidResponseTests, manageEditRapidResponseTestsConfig)
    .state(
      manageEditRapidResponseTestsMenu,
      manageEditRapidResponseTestsMenuConfig,
    )
    .state(
      manageEditRapidResponseTestsSegments,
      manageEditRapidResponseTestsSegmentsConfig,
    )
    .state(
      manageEditRapidResponseTestsSettings,
      manageEditRapidResponseTestsSettingsConfig,
    )
    .state(
      manageEditRapidResponseTestsVoices,
      manageEditRapidResponseTestsVoicesConfig,
    )
    .state(
      manageEditRapidResponseTestsVariables,
      manageEditRapidResponseTestsVariablesConfig,
    )
}
