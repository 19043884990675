// Dashboard
import {
  dashboardDialerQueue,
  dashboardDialerQueueConfig,
} from './dashboard.dialerQueue.js'

export default function dashboardDialerQueueRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardDialerQueue, dashboardDialerQueueConfig)
}
