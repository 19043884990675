import { errorParser } from '../../../../../../../utils/managementUtils.js'

export default class passwordInfoFormController {
  constructor($state, $timeout, ManageUsersCreateService) {
    'ngInject'

    this.$timeout = $timeout
    this.$state = $state
    this.MUCS = ManageUsersCreateService
    this.containsDigit = /\d/
    this.state = {
      actionTitle: 'Setup Password',
      creationErrors: null,
      creatingUser: false,
      askNextAction: false,
    }
  }

  backTo() {
    this.goingBack = true
    this.$state.go(this.lastState, { user: this.user }, { location: false })
  }

  createUser(user) {
    this.state.actionTitle = 'Creating'
    this.state.creatingUser = true
    this.resetErrors()
    this.MUCS
      .createUser(user)
      .then(() => {
        this.$timeout(() => {
          this.state.actionTitle = 'User Created'
          this.state.askNextAction = true
        }, 2000)
      })
      .catch(err => {
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, 2000)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingUser = false
        }, 2000)
      })
  }

  createAnother() {
    this.$state.go('manage.users.create.personalInfo')
  }

  exit() {
    this.$state.go('manage.users', null, { reload: true })
  }

  resetErrors() {
    this.state.creationErrors = null
  }
}
