// Manage Campaign Router
import {
  manageCampaignRouter,
  manageCampaignRouterConfig,
} from './manage.campaignRouter.js'
import {
  manageCampaignRouterCreate,
  manageCampaignRouterCreateConfig,
} from './child-states/create/manage.campaignRouter.create.js'
import {
  manageCampaignRouterCreateRouteInfo,
  manageCampaignRouterCreateRouteInfoConfig,
} from './child-states/create/route-info/manage.campaignRouter.create.routeInfo.js'
import {
  manageCampaignRouterEdit,
  manageCampaignRouterEditConfig,
} from './child-states/edit/manage.campaigRouter.edit.js'
import {
  manageCampaignRouterEditRoute,
  manageCampaignRouterEditRouteConfig,
} from './child-states/edit/route/manage.campaignRouter.edit.route.js'

export default function manageCampaignRouterRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageCampaignRouter, manageCampaignRouterConfig)
    .state(manageCampaignRouterCreate, manageCampaignRouterCreateConfig)
    .state(
      manageCampaignRouterCreateRouteInfo,
      manageCampaignRouterCreateRouteInfoConfig,
    )
    .state(manageCampaignRouterEdit, manageCampaignRouterEditConfig)
    .state(manageCampaignRouterEditRoute, manageCampaignRouterEditRouteConfig)
}
