import roundTo from 'round-to'
import { reduce } from 'ramda'

export default class ListMetricHealthService {
  constructor($window, DualAxisSplineChart, PieChart, MetricHealthService) {
    'ngInject'

    this.$window = $window
    this.MHS = MetricHealthService
    this.DAS = DualAxisSplineChart
    this.PieChart = PieChart
    this.configFormatter = this.MHS.configFormatter.bind(this.MHS)
    this.addPlotLines = this.MHS.addPlotLines.bind(this.MHS)
  }

  addAttemptPercentages(metric) {
    const base = metric.y
    const attempts = metric.attempts
    const aggregate = metric.aggregate
    const calc = (acc, attempt) => {
      if (!aggregate && attempt.y > 0) {
        acc.push(attempt)
        return acc
      } else if (attempt.y === 0) {
        return acc
      }
      const percentage = (attempt.y / base) * 100
      attempt.percentage = percentage
      acc.push(attempt)
      return acc
    }
    return reduce(calc, [], attempts)
  }

  addListName(filename, listSlug) {
    const formattedListSlug = listSlug.split('-').join('_')
    return `${formattedListSlug}_${filename}`
  }

  createChartConfig(listSlug, metric, max, min) {
    const formattedRange = this.configFormatter(metric.key, metric)
    const chart = this.DAS.getChart()
    const series = {
      name: `${metric.format.title}`,
      data: formattedRange.days,
      tooltip: {
        useHTML: true,
        pointFormatter: this.MHS.dayTooltipFormatter,
      },
    }
    chart.yAxis.plotLines = this.addPlotLines(metric, min, max)
    chart.options.drilldown.series = [...formattedRange.drillDownSeries]
    chart.options.tooltip.useHTML = true
    chart.options.exporting.filename = this.addListName(
      this.MHS.createFileName(metric),
      listSlug,
    )
    chart.options.exporting.chartOptions.title.text = metric.format.title
    chart.series = [series]
    return chart
  }

  createPieChartConfig(attempts, metric, listSlug) {
    const chart = this.PieChart.getChart()
    const series = this.createPieSeries(metric, attempts)
    const title = metric.aggregate
      ? `Total ${metric.format.title}: ${metric.format.prepend}${roundTo(
          metric.y,
          2,
        )}${metric.format.append}`
      : `Average ${metric.format.title}: ${metric.format.prepend}${roundTo(
          metric.y,
          2,
        )}${metric.format.append}`
    const labelFormat = this.labelFormatter(metric)
    chart.options.exporting.filename = this.addListName(
      this.MHS.createFileName(metric),
      listSlug,
    )
    chart.options.tooltip.enabled = false
    chart.options.plotOptions.pie = {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: labelFormat,
      },
    }
    chart.title.text = title
    chart.series.push(series)
    return chart
  }

  labelFormatter(metric) {
    const withAggregate = `
      <b>{point.name}</b>:<br>
      <b>Percentage:</b> {point.y} %<br>
      <b>Total:</b> {point.value:.2f}
    `
    const withoutAggregate = `
      <b>{point.name}</b>:<br>
      <b>Percentage:</b> {point.y} %<br>
    `
    const formatString = metric.aggregate ? withAggregate : withoutAggregate
    return formatString
  }

  pieFormatter() {
    let tooltipMarkup = `
      ${this.series.name}:
    `
    return tooltipMarkup
  }

  createPieSeries(metric, attempts) {
    const format = (acc, attempt) => {
      if ((attempt.percentage || attempt.y) === 0) return acc
      acc.push({
        name: `Attempt ${attempt.attempt}`,
        value: attempt.y,
        y: attempt.percentage
          ? roundTo(attempt.percentage, 2)
          : roundTo(attempt.y, 2),
      })
      return acc
    }
    const formattedAttempts = reduce(format, [], metric.attempts)
    return {
      name: 'Percentage By Attempt',
      data: formattedAttempts,
    }
  }
}
