export default function() {
  function sticky(scope, element, attr) {
    scope.$watch(
      attr.loaded,
      loaded => {
        if (loaded) {
          setTimeout(() => {
            const tHead = element[0].firstElementChild
            const tBody = element[0].lastElementChild
            const singleRowContent = tBody.firstElementChild
              ? tBody.firstElementChild.children
              : null
            if (singleRowContent) {
              let el = document.getElementById('filterList')
              let sizes = [].map.call(singleRowContent, cell => {
                return window.getComputedStyle(cell, null).width
              })
              let newHeader = document.createElement('table')

              newHeader.className = 'md-table'
              el.insertBefore(newHeader, el.lastElementChild)
              newHeader.appendChild(tHead)
              const cells =
                newHeader.firstElementChild.firstElementChild.children
              for (let i = 0; i < cells.length; i++) {
                cells[i].style.cssText = `width: ${sizes[i]}`
              }
            }
          }, 100)
        }
      },
      true,
    )
  }

  return {
    restrict: 'A',
    link: sticky,
  }
}
