import { forEach, map, pipe, reduce } from 'ramda'
import { calculateAttemptMetrics, attemptMetricMerger } from './metrics.js'

function combineDayAttemptMetrics(acc, day) {
  const attemptKeys = Object.keys(day.attempts)

  forEach(attemptNum => {
    if (!acc[attemptNum]) acc[attemptNum] = []
    acc[attemptNum].push(day.attempts[attemptNum])
  }, attemptKeys)

  return acc
}

const calcMetricsbyAttemptByRange = pipe(
  reduce(combineDayAttemptMetrics, {}),
  map(attemptMetricMerger),
  map(calculateAttemptMetrics),
)

function calcByAttemptByRange({ days = [], metrics = [] }) {
  return {
    attempts: calcMetricsbyAttemptByRange(days),
    days,
    metrics,
  }
}

export { calcByAttemptByRange }
