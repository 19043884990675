import { permissions, MANAGE_GROUP } from 'permissions.js'

const { CLIENT, REP } = permissions
// gateway view

const gateway = 'gateway'

const gatewayConfig = {
  url: '/gateway',
  component: 'gateway',
  data: {
    authRequired: true,
    permissions: {
      only: [...MANAGE_GROUP, CLIENT, REP],
      redirectTo: 'signIn',
    },
  },
  resolve: {
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Gateway'
      $window.document.title = pageTitle
      return pageTitle
    },
    campaigns: function(IdentityService) {
      'ngInject'
      return IdentityService.Campaign.list.get({ active: true }).then(data => {
        return data.plain()
      })
    },
  },
}

export { gateway, gatewayConfig }
