// @flow

import type { User } from 'types/entities.js'
import { always, cond, T } from 'ramda'

export default class SignInController {
  $state: any
  TS: any
  AuthService: any
  RS: any
  state: {
    username: string,
    password: string,
    showSpinner: boolean,
    loginError: boolean,
  }

  constructor(
    $state: any,
    ToastService: any,
    AuthService: any,
    RoleStore: any,
  ) {
    'ngInject'
    this.AuthService = AuthService
    this.RS = RoleStore
    this.TS = ToastService
    this.$state = $state

    this.state = {
      username: '',
      password: '',
      showSpinner: false,
      loginError: false,
    }
  }

  login(username: string, password: string) {
    this.state.showSpinner = true
    this.AuthService.login({
      username: username,
      password: password,
    })
      .then(user => {
        const nextState = this.getNextState(user)
        this.$state.go(nextState, { user })
      })
      .catch(() => {
        this.state.showSpinner = false
        this.TS.show({ text: 'Incorrect Login Credentials' })
      })
  }

  getNextState(user: User): string {
    const requirePasswordUpdate = user => user.password_update_required
    const recorderOnly = user =>
      this.RS.getAllRoles().RECORDER && user.groups.length === 1
    const nextState = cond([
      [requirePasswordUpdate, always('updatePassword')],
      [recorderOnly, always('manage.audioVoice.pitchAudio')],
      [T, always('gateway')],
    ])
    return nextState(user)
  }
}
