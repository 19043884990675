// edit campaign router view

const manageCampaignRouterEdit = 'manage.campaignRouter.edit'

const manageCampaignRouterEditConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageCampaignRouterEdit, manageCampaignRouterEditConfig }
