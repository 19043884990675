// Company Rapid Response Segments view

const manageEditCompanyRapidResponseSegments = 'manage.editCompany.rapidResponseSegments'

const manageEditCompanyRapidResponseSegmentsConfig = {
  url: '/rapidResponseSegments',
  component: 'companyRapidResponseSegments',
}

export { manageEditCompanyRapidResponseSegments, manageEditCompanyRapidResponseSegmentsConfig }
