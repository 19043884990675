import moment from 'moment'
import { getRealmSlug } from 'util/realm.js'
import RepTrendDialogController from '../../../dialogs/rep-trend-dialog/controllers/RepTrendDialogController.js'
const dialogTemplate = require('../../../dialogs/rep-trend-dialog/repTrendDialog.tpl.pug')

export default class TrendTableController {
  constructor($mdDialog, $mdToast, RepTrendService, MetricsService) {
    'ngInject'

    this.RTS = RepTrendService
    this.MS = MetricsService
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.ranges = [
      { name: 'Last 10 Days', days: 10 },
      { name: 'Last 30 Days', days: 30 },
      { name: 'Last 60 Days', days: 60 },
      { name: 'Last 90 Days', days: 90 },
    ]
  }

  $onInit() {
    const timezoneOffset = this.offsetMinutes
    const localOffset = moment().utcOffset()
    this.dateTime = moment.utc().utcOffset(timezoneOffset)
    const today = this.dateTime.startOf('day').toDate()
    const endDate = this.dateTime.endOf('day').toDate()
    const normalizedOffset = Math.abs(this.timezoneOffset) + localOffset

    this.dateRange = {
      startDate: today,
      endDate: endDate,
      currentDate: today,
      normalizedOffset,
      requestTime: moment().startOf('minute'),
    }

    this.componentDetails = {
      key: 'rep_trend_metrics',
      company: this.currentCompany.slug,
      campaign: this.selectedCampaign.slug,
    }
    this.state = {
      searching: false,
      selected: {
        company: this.currentCompany,
        campaign: this.selectedCampaign,
        realm: getRealmSlug(this.realm),
        range: this.ranges[0],
        metric: {},
        repMetric: [],
      },
    }
    this.componentDetails.metrics = this.MS.getComponentMetrics(
      this.componentDetails.key,
    )
    this.state.selected.metric = this.componentDetails.metrics[3]
    this.getTrend(
      this.componentDetails,
      this.dateRange,
      this.state.selected.range,
      this.state.selected.realm,
    )
  }

  getTrend(component, dateRange, selectedRange, realm) {
    this.state.searching = true
    this.loadPromise = this.RTS.getRepTrends(
      component,
      dateRange,
      selectedRange,
      realm,
    )
      .then(data => {
        this.repData = data
        this.state.selected.repMetric = this.repData[
          this.state.selected.metric.key
        ]
        this.displayMetric = this.state.repMetric
        this.state.searching = false
      })
      .catch(() => {
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent('Problem fetching table data')
            .position('top right')
            .theme('default')
            .hideDelay(5000),
        )
      })
  }

  changeMetric(metric) {
    this.state.selected.metric = metric
    this.state.selected.repMetric = this.repData[metric.key]
  }

  selectRange(range) {
    this.getTrend(this.componentDetails, this.dateRange, range)
  }

  openRepTrend(event, rep, title, range) {
    const parentElement = angular.element(document.body)
    this.$mdDialog.show({
      parent: parentElement,
      targetEvent: event,
      template: dialogTemplate(),
      bindToController: true,
      controllerAs: 'td',
      locals: {
        title: title,
        range: range,
        rep: rep,
      },
      controller: RepTrendDialogController,
    })
  }

  trendDirection(percentChange) {
    if (percentChange > 0) {
      return 'action:trending_up'
    } else if (percentChange < 0) {
      return 'action:trending_down'
    } else if (percentChange === 0) {
      return 'action:trending_flat'
    }
  }

  trendColor(percentChange) {
    if (percentChange > 0) {
      return { fill: 'green' }
    } else if (percentChange < 0) {
      return { fill: 'red' }
    } else if (percentChange === 0) {
      return { fill: 'gray' }
    }
  }
}
