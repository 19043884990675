import './editDidPool.scss'
import EditDidPoolController from './controller/EditDidPoolController'
const template = require('./editDidPool.component.tpl.pug')

const editDidPool = {
  template: template(),
  controller: EditDidPoolController,
  bindings: {
    pool: '<',
    companies: '<',
    campaigns: '<',
    voiceProviders: '<',
  },
}

export default editDidPool
