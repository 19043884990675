import { pipe, propEq, equals, prop, not, both } from 'ramda'

const isRecording = propEq('isRecording', true)
const isNotRecording = propEq('isRecording', false)
const isPlaying = propEq('isPlaying', true)
const trackIsArmed = propEq('trackArmed', true)
const trackIsNotArmed = propEq('trackArmed', false)
const hasPreviewAudio = propEq('hasPreviewAudio', true)
const allDisabled = propEq('allDisabled', true)
const notAtListBeginning = pipe(
  prop('selectedIndex'),
  equals(0),
  not,
)

function notAtListEnd({ selectedIndex, audioList }) {
  return selectedIndex < audioList.length - 1
}

const canSelectPrevious = both(isNotRecording, notAtListBeginning)
const canSelectNext = both(isNotRecording, notAtListEnd)

export {
  isRecording,
  isNotRecording,
  isPlaying,
  trackIsArmed,
  trackIsNotArmed,
  allDisabled,
  hasPreviewAudio,
  canSelectPrevious,
  canSelectNext,
}
