import React from 'react'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  label: {
    '&$focusedLabel': {
      color: '#1194f6 !important',
    },
  },
  focusedLabel: {},
  underline: {
    '&:after': {
      borderBottom: `0.1em  solid #1194f6`,
    },
    '&:before': { borderBottom: '1px solid #d5d5d5' },
    '&:hover:before': {
      borderBottom: '0.1em solid #1194f6 !important',
      backgroundColor: 'transparent',
    },
  },
}))

const AppTextField = props => {
  const classes = useStyles()

  return (
    <React.Fragment>
      {props.type === 'file' && (
        <input
          accept={props.accept}
          style={{ display: 'none' }}
          id="icon-button-file"
          type="file"
          onChange={e => props.onChange(e.target)}
        />
      )}
      <TextField
        placeholder={props.placeholder}
        type={props.type === 'file' ? 'text' : props.type}
        select={props.select}
        disabled={props.disabled}
        required={props.required}
        error={props.error}
        fullWidth
        id={props.id}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        onBlur={props.onBlur}
        helperText={props.helperText}
        InputLabelProps={{
          shrink: props.shrink,
          classes: {
            root: props.labelClass || classes.label,
            focused: classes.focusedLabel,
          },
        }}
        InputProps={{
          classes: {
            root: classes.underline,
          },
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            >
              {props.type === 'file' ? (
                <label
                  htmlFor="icon-button-file"
                  style={{ width: 'max-content', color: '#1194F6' }}
                >
                  <Button
                    style={{ color: '#1194F6', float: 'initial', fontSize: 14 }}
                    aria-label="upload picture"
                    className="left text-capitalize"
                    component="span"
                  >
                    <CloudUploadIcon style={{ fontSize: 20, marginRight: 7 }} />
                    Choose file
                  </Button>
                </label>
              ) : (
                props.icon
              )}
            </InputAdornment>
          ),
        }}
        label={props.label}
      />
    </React.Fragment>
  )
}

export default AppTextField
