import EditCompanyController from './controller/EditCompanyController.js'
const template = require('./editCompany.component.tpl.pug')

const editCompany = {
  controller: EditCompanyController,
  template: template(),
  bindings: {
    company: '<',
    openSections: '<',
  }
}

export default editCompany
