export default class RepTrendController {
  $onInit() {
    this.state = {
      selected: {
        company: this.company,
        campaign: this.campaign,
        realm: this.realm,
      },
    }
  }
}
