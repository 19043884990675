const R_KEY = 'KeyR'
const LEFT_ARROW = 'ArrowLeft'
const RIGHT_ARROW = 'ArrowRight'
const SPACE_BAR = 'Space'

export {
  R_KEY,
  LEFT_ARROW,
  RIGHT_ARROW,
  SPACE_BAR,
}

