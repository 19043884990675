import RapidResponseTestsSettingsController from './controller/RapidResponseTestsSettingsController'
import template from './rapidResponseTestsSettings.component.tpl.pug'

const editRapidResponseTestsSettings = {
  template: template(),
  controller: RapidResponseTestsSettingsController,
  bindings: {
    compSlug: '<',
    testUuid: '<',
    rapidResponseTest: '<',
  },
}

export default editRapidResponseTestsSettings
