// manage access lists create

const manageAccessListsEdit = 'manage.access-lists.one.edit'

const manageAccessListsEditConfig = {
  url: '/edit/{uuid}',
  params: {
    entity: null,
    entityIndex: null
  },
  template: `<manage-access-lists-edit
               entity="$resolve.entity"
               entity-index="$resolve.entityIndex"
               on-entity-update="$ctrl.onAclUpdate(access-list, aclIndex)">
             </manage-access-lists-edit>`,
  resolve: {
    entity: function ($transition$, $state) {
      'ngInject'
      const entity = $transition$.params().entity
      if (!entity) return $state.go('manage.access-lists')
      return entity
    },
    entityIndex: function ($transition$) {
      'ngInject'
      return $transition$.params().entityIndex
    }
  }

}

export { manageAccessListsEdit, manageAccessListsEditConfig }
