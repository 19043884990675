import { getBaseUrl } from '../global/config/providers/restangularProvider/rest.js'
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import axios from 'axios'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import ActionButton from 'common-components/action-button/ActionButton'
import CreateLoader from 'common-components/create-loader/CreateLoader'
import AppTextField from 'components/AppTextField'

const API_BASE_URL = getBaseUrl(window.location.host, true)
const CancelToken = axios.CancelToken
let cancel

const styles = {
  inputName: {
    margin: '20px 0px',
    '& label.Mui-focused': {
      color: '#1194f6 !important',
    },
    "& div[class*='MuiInput-underline']:after:hover": {
      borderBottom: '1px solid #D5D5D5',
    },
    "& div[class*='MuiInput-underline']:before:hover": {
      borderBottom: '1px solid #D5D5D5 !important',
    },
    "& div[class*='MuiInput-underline']:hover:not(.Mui-disabled):before": {
      borderBottom: '1px solid #1194F6 !important',
    },
    "& div[class*='MuiInput-underline']:not(.Mui-error):after": {
      borderBottom: '1px solid #1194F6 !important',
    },
    "& div[class*='MuiInput-underline']:before": {
      borderBottom: '1px solid #D5D5D5 !important',
    },
  },
  inputFile: {
    display: 'none',
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
    color: '#444851',
    padding: 0,
  },
  textLabel: {
    color: '#444851',
    textDecoration: 'underline',
    fontSize: '14px',
  },
  formControl: {
    marginRight: 15,
  },
  helper: {
    color: '#777777',
    fontSize: 13,
    margin: '-5px 0 15px 0',
  },
}
const NewResourceForm = props => {
  const [fileInput, setFileInput] = useState({})
  const [hasFiles, setHasFiles] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState({})
  const [expanded, setExpanded] = useState(false)
  const [showCollapse, setShowCollapse] = useState(true)
  const [name, setName] = useState('')
  const [fileName, setFileName] = useState('Not file chosen')
  const [form, setForm] = useState({
    no_modifications: false,
    convert: true,
    fade_in: true,
    fade_out: true,
    file_ext: null,
  })
  const handleSubmit = () => {
    setIsLoading(true)
    let formData = new FormData()
    if (hasFiles == 1) {
      formData.append('file', fileInput.files[0])
      formData.append('no_modifications', form.no_modifications)
      formData.append('convert', form.convert)
      formData.append('fade_in', form.fade_in)
      formData.append('fade_out', form.fade_out)
      formData.append('file_ext', form.file_ext)
    }
    axios
      .post(
        `${API_BASE_URL}/pitch/company/${props.companySlug}/audio/resources/`,
        {
          name: name,
        },
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c
          }),
        },
      )
      .then(res => {
        let resourceUUID = res.data.uuid
        if (hasFiles == 1) {
          axios
            .post(
              `${API_BASE_URL}/pitch/company/${props.companySlug}/audio/resources/${resourceUUID}/upload/`,
              formData,
              {
                cancelToken: new CancelToken(function executor(c) {
                  cancel = c
                }),
              },
            )
            .then(res => {
              setIsLoading(false)
              props.handleCreate(res.data)
            })
            .catch(err => console.log(err))
        } else {
          setIsLoading(false)
          props.handleCreate(res.data)
        }
      })
      .catch(err => console.log(err))
  }
  const handleCancelCreation = () => {
    console.log('cancel creation')
    setIsLoading(false)
    cancel()
  }
  const handleExpandClick = () => {
    setExpanded(!expanded)
    setShowCollapse(!showCollapse)
  }
  const handleChange = element => event => {
    setForm({ ...form, [element]: event.target.checked })
  }
  const handlerFile = e => {
    setFileInput(e)
    if (e.files[0]) {
      setHasFiles(1)
      let tempFile = e.files[0].name
      setFileName(tempFile)
      let file_extension = tempFile.split('.').pop()
      setForm({ ...form, file_ext: file_extension })
    } else {
      setFileName('Not file chosen')
      setHasFiles(0)
    }
  }
  return isLoading ? (
    <React.Fragment>
      <CreateLoader
        message="One moment. We're adding the new resource..."
        handleCancel={handleCancelCreation}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <DialogTitle id="add-resource-dialog">
        Add new resource
        <IconButton
          disableRipple={true}
          aria-label="close"
          className="closeButton"
          onClick={props.handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            required
            className={props.classes.inputName}
            label="Audio name"
            id="standard-required"
            fullWidth
            type="text"
            value={name}
            onChange={e => {
              setName(e.target.value)
              setErrMsg({ ...errMsg, name: '' })
            }}
            error={errMsg.name ? true : false}
            helperText={errMsg.name ? errMsg.name : ' '}
            onBlur={() => {
              if (name) {
                setDisabled(false)
                setErrMsg({ ...errMsg, name: '' })
              } else {
                setDisabled(true)
                setErrMsg({ ...errMsg, name: 'A resource name is required' })
              }
            }}
          />
          <AppTextField
            type="file"
            accept="audio/*"
            placeholder={fileName}
            onChange={e => handlerFile(e)}
          />
          <div style={{ margin: '20px 0px 0px 0px' }}>
            {showCollapse && (
              <IconButton
                className={props.classes.button}
                disableRipple={true}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <span className={props.classes.textLabel}>Advanced</span>{' '}
                <ExpandMoreIcon />
              </IconButton>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple={true}
                          checked={form.no_modifications}
                          onChange={handleChange('no_modifications')}
                          value="no_modifications"
                        />
                      }
                      label="No modifications"
                    />
                    <p className={props.classes.helper}>
                      Disable modifications to the audio file upon uploading.
                    </p>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple={true}
                          checked={form.fade_in}
                          onChange={handleChange('fade_in')}
                          value="fade_in"
                        />
                      }
                      label="Fade in"
                    />
                    <p className={props.classes.helper}>
                      Enable fading in the audio when played.
                    </p>
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple={true}
                          checked={form.convert}
                          onChange={handleChange('convert')}
                          value="convert"
                        />
                      }
                      label="Convert"
                    />
                    <p className={props.classes.helper}>
                      Enable converting the audio file to a different format.
                    </p>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple={true}
                          checked={form.fade_out}
                          onChange={handleChange('fade_out')}
                          value="fade_out"
                        />
                      }
                      label="Fade out"
                    />
                    <p className={props.classes.helper}>
                      Enable fading out the audio when played.
                    </p>
                  </FormGroup>
                </Grid>
              </Grid>
              <IconButton
                style={{ marginTop: 10 }}
                className={props.classes.button}
                disableRipple={true}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show less"
              >
                <span className={props.classes.textLabel}>Show less</span>{' '}
                <ExpandLessIcon />
              </IconButton>
            </Collapse>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justify="center"
          spacing={2}
          style={{ marginBottom: '15px', marginTop: '15px' }}
        >
          <Grid item>
            <ActionButton
              handleClick={handleSubmit}
              disabled={disabled}
              buttonColor="secondary"
            >
              SAVE AUDIO{' '}
            </ActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </React.Fragment>
  )
}
export default withStyles(styles)(NewResourceForm)
