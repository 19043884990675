import {
  CAMPAIGN_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'revenuePerCall',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Revenue / Call',
    prepend: '$',
  },
}

const keys = {
  componentKeys: [
    CAMPAIGN_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'revenue' },
  divisor: { stat: 'calls' },
}

const revenuePerCall = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { revenuePerCall }
