import PhraseAudioController from './controller/PhraseAudioController.js'
const template = require('./phraseAudio.component.tpl.pug')

const phraseAudio = {
  controller: PhraseAudioController,
  template: template(),
  bindings: {},
}

export default phraseAudio

