export default class ReactivateDidConfirmationController {
  constructor($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  close() {
    this.$mdDialog.destroy()
  }
}
