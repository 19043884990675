import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
import { MANAGE_GROUP } from 'permissions.js'

// Manage Global Pitch Segments View

const manageGlobalPitchSegments = 'manage.globalPitchSegments'
const pageTitleConfig = {
  title: 'Manage Pitch Segments'
}

const manageGlobalPitchSegmentsConfig = {
  url: '/global-pitch-segments',
  component: 'manageGlobalPitchSegments',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  },
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageGlobalPitchSegments, manageGlobalPitchSegmentsConfig }
