import { any, map, reduce, contains } from 'ramda'

const anyFalse = any(x => x === false)
const anyTrue = any(x => x === true)
const parseUserRoles = userRoles => {
  if (Array.isArray(userRoles)) return userRoles
  return Object.keys(userRoles)
}

export const HIRE_DATE_FORMAT = 'YYYY-MM-DD'

export function setVisibility(baseObj, keysArr, bool) {
  const setBool = (acc, key) => {
    acc[key] = bool
    return acc
  }
  return keysArr.reduce(setBool, baseObj)
}

export function getHttpCodes() {
  return import('statuses').then(statuses =>
    statuses.default.codes.map(code => code.toString()),
  )
}

export function hideInvalid(angularForm, keys = []) {
  const keyValidator = key => {
    if (angularForm[key]) {
      return angularForm[key].$valid
    }
    return false
  }
  const validKeys = keys.map(keyValidator)
  return anyFalse(validKeys)
}

export function containsRole(userRoles, has = []) {
  const roles = parseUserRoles(userRoles)
  const hasRole = map(role => {
    return contains(role, has)
  }, roles)
  return anyTrue(hasRole)
}

export function errorParser(errObj) {
  if (typeof errObj === 'string' || typeof errObj !== 'object') {
    return ['An Unknown Error Occurred']
  }

  const errorKeys = Object.keys(errObj)
  const parse = (acc, errKey) => {
    const error = errObj[errKey].join ? errObj[errKey].join('') : errObj[errKey]
    acc.push(error)
    return acc
  }
  return reduce(parse, [], errorKeys)
}

export function addColumns(options) {
  const { userRoles, allowedRoles, sliceIndex, columns, addColumns } = options

  if (containsRole(userRoles, allowedRoles)) {
    return columns
      .slice(0, sliceIndex)
      .concat(addColumns)
      .concat(columns.slice(sliceIndex))
  }

  return columns
}
