// dashboard.goals view

const dashboardGoals = 'dashboard.goals'

const dashboardGoalsConfig = {
  url: '/goals',
  component: 'goals',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign) {
      'ngInject'
      return selectedCampaign
    }
  }
}

export { dashboardGoals, dashboardGoalsConfig }
