// @flow

import type PitchService from 'global/index/api-services/PitchService.js'
import type { FilterListConfig } from 'common-components/filter-list/component/filterList.js'
import type { RapidResponseTest } from 'types/entities.js'

type DisplayVariable = {|
  key: string,
  value: string,
  variableUsage: string,
|}

import { reduce } from 'ramda'
import CPVD from '../../../../../dialogs/create-pitch-variables/createPitchVariables'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class RapidResponseTestsVariablesController {
  PS: PitchService
  $mdDialog: Object
  $state: Object
  columnsConfig: FilterListConfig
  rapidResponseTest: RapidResponseTest
  variables: { [key: string]: string }
  compSlug: string
  compUuid: string
  rrService: Object
  buildDisplayVariables: (
    acc: Array<DisplayVariable>,
    Array<[string, mixed]>,
  ) => Array<DisplayVariable>
  state: {
    testVariables: DisplayVariable[],
  }

  constructor($mdDialog: Object, $state: Object, PitchService: PitchService) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.PS = PitchService

    this.buildDisplayVariables = reduce(
      (acc: Array<DisplayVariable>, [key, value]: [string, string]) => {
        acc.push({
          key,
          value,
          variableUsage: `{{global.${key}}}`,
        })
        return acc
      },
    )

    this.columnsConfig = [
      { title: 'Key', accessor: 'key', type: 'text' },
      { title: 'Value', accessor: 'value', type: 'text' },
      {
        title: 'Variable Usage',
        accessor: 'variableUsage',
        type: 'text',
        copyable: true,
      },
      {
        title: 'Edit',
        type: 'menu',
        icon: 'editor:mode_edit',
        menu: [
          {
            title: 'Change Values',
            icon: 'action:swap_horiz',
            actionCb: variable => this.openVariablesDialog(variable),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: variable => this.deleteVariable(variable),
          },
        ],
      },
    ]

    this.state = {
      testVariables: [],
      topBarButtons: [
        {
          title: 'New Test Variable',
          icon: 'content:add',
          action: this.openVariablesDialog.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onInit() {
    this.variables = this.rapidResponseTest.variables
    this.rrService = this.setupService()
    this._populateTestVariables(this.variables)
  }

  _populateTestVariables(variables: { [key: string]: string }) {
    this.state.testVariables = this.buildDisplayVariables(
      [],
      Object.entries(variables),
    )
  }

  setupService() {
    const service = this.PS.setupRapidResponse(this.compSlug)
    return service.company && this.compUuid
      ? service.company.one(`tests/${this.compUuid}`)
      : service.global.one('tests')
  }

  openVariablesDialog(variable: DisplayVariable) {
    CPVD.locals = {
      isCreate: !variable,
      service: this.rrService.one(this.rapidResponseTest.uuid),
      variables: this.variables,
      variable,
    }
    this.$mdDialog.show(CPVD).then(variables => {
      this._populateTestVariables(variables)
    })
  }

  deleteVariable(variable: DisplayVariable) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Test Variable',
      entityName: variable.key,
      identityEndpoint: this.rrService.one(this.rapidResponseTest.uuid),
      entity: variable,
      routeWhenSuccessful: false,
      isVariable: true,
      variables: this.variables,
    }
    this.$mdDialog.show(DED).then(() => {
      delete this.variables[variable.key]
      this._populateTestVariables(this.variables)
    })
  }
}
