// Manage Edit Phrase Book
import {
  manageEditPhraseBook,
  manageEditPhraseBookConfig,
} from './manage.editPhraseBook'
import {
  manageEditPhraseBookPhrase,
  manageEditPhraseBookPhraseConfig,
} from './child-states/phrase-book-phrase/manage.PhraseBookPhrase'
import {
  manageEditPhraseBookPhraseCreate,
  manageEditPhraseBookPhraseCreateConfig,
} from './child-states/phrase-book-phrase/child-states/phrase-create/manage.editPhraseBookPhrase.create'
import {
  manageEditPhraseBookPhraseEdit,
  manageEditPhraseBookPhraseEditConfig,
} from './child-states/phrase-book-phrase/child-states/phrase-edit/manage.editPhraseBookPhrase.edit'

export default function manageEditPhraseBookRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditPhraseBook, manageEditPhraseBookConfig)
    .state(manageEditPhraseBookPhrase, manageEditPhraseBookPhraseConfig)
    .state(
      manageEditPhraseBookPhraseCreate,
      manageEditPhraseBookPhraseCreateConfig,
    )
    .state(manageEditPhraseBookPhraseEdit, manageEditPhraseBookPhraseEditConfig)
}
