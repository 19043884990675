import './asyncAudio.scss'
import AsyncAudioController from './controller/AsyncAudioController'
import template from './asyncAudio.tpl.pug'

const asyncAudio = {
  template: template(),
  controller: AsyncAudioController,
  bindings: {
    service: '<',
    audio: '<',
  },
}

export default asyncAudio
