// Manage Edit Campaign
import {
  manageEditCampaign,
  manageEditCampaignConfig,
} from './manage.editCampaign.js'
import {
  manageEditCampaignSettings,
  manageEditCampaignSettingsConfig,
} from './child-states/edit-campaign-settings/manage.editCampaign.settings.js'
import {
  manageEditCampaignPitch,
  manageEditCampaignPitchConfig,
} from './child-states/edit-campaign-pitch/manage.editCampaign.pitch.js'
import {
  manageEditCampaignDataPosting,
  manageEditCampaignDataPostingConfig,
} from './child-states/edit-campaign-data-posting/manage.editCampaign.dataPosting.js'
import {
  manageEditCampaignCreate,
  manageEditCampaignCreateConfig,
} from './child-states/edit-campaign-create/manage.editCampaign.create.js'
import {
  manageEditCampaignCreateRouterInfo,
  manageEditCampaignCreateRouterInfoConfig,
} from './child-states/edit-campaign-create/child-states/router-info/manage.editCampaign.create.routerInfo.js'

export default function manageEditCampaignRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditCampaign, manageEditCampaignConfig)
    .state(manageEditCampaignSettings, manageEditCampaignSettingsConfig)
    .state(manageEditCampaignDataPosting, manageEditCampaignDataPostingConfig)
    .state(manageEditCampaignCreate, manageEditCampaignCreateConfig)
    .state(
      manageEditCampaignCreateRouterInfo,
      manageEditCampaignCreateRouterInfoConfig,
    )
    .state(manageEditCampaignPitch, manageEditCampaignPitchConfig)
}
