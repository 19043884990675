const repInfoConfig = {
  loading: false,
  showSearch: true,
  repInfoData: [],
  columnConfig: [
    { title: 'Rep', accessor: 'rep', type: 'string' },
    { title: 'Location', accessor: 'location', type: 'string' },
    { title: 'Team', accessor: 'team', type: 'string' },
    { title: '# Stations', accessor: 'station_number', type: 'numeric' },
    { title: 'Stations', accessor: 'stations', type: 'color' }
  ]
}

export { repInfoConfig }
