export default class ListOverviewController {
  constructor($q, ListOverviewService) {
    'ngInject'
    this.$q = $q
    this.LOS = ListOverviewService
  }

  $onInit() {
    this.state = {
      loading: true,
    }

    this.getListData(
      this.company,
      this.campaign,
      this.list,
      this.listRealm,
      this.offsetMinutes,
    )
  }

  getListData(company, campaign, list, listRealm, offsetMinutes) {
    this.state.loading = true
    const listData = {
      details: list,
      metrics: this.getListMetrics(
        company,
        campaign,
        list,
        listRealm,
        offsetMinutes,
      ),
    }
    return this.$q.all(listData).then(data => {
      this.details = data.details
      this.metrics = data.metrics
      this.state.loading = false
    })
  }

  getListDetails(company, campaign, list) {
    return this.LOS.getListDetails(company, campaign, list.slug)
  }

  getListMetrics(company, campaign, list, listRealm, offsetMinutes) {
    return this.LOS.getListMetrics(
      company,
      campaign,
      list.slug,
      listRealm,
      offsetMinutes,
    )
  }
}
