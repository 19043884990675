export default class EditVoicesController {
  constructor (
    $state,
    $window,
    PitchService,
    EditPitchService
  ) {
    'ngInject'
    this.PS = PitchService
    this.EPS = EditPitchService
    this.$state = $state
    this.$window = $window
    this.state = {
      loading: true
    }
  }

  $onChanges () {
    this.companyService = this.setupService()
    this.getVoices()
  }

  setupService () {
    const endpointRoute = this.$state.params.endpointRoute
    const compSlug = this.$state.params.compSlug
    const uuid = this.$state.params.uuid
    if (compSlug === 'global') {
      return this.PS.setupBase().global.one(`${endpointRoute}/${uuid}`)
    }
    return this.PS.setupVariables(compSlug).company.one(`${endpointRoute}/${uuid}`)
  }

  getVoices () {
    this.companyService.get()
      .then(voices => {
        this.rapidResponseTest = voices.plain()
        this.state.loading = false
      })
  }

  updateVoices (fields) {
    return this.EPS.updatePitchDetails(fields, this.companyService)
      .then(updatedRapidResponseTest => {
        this.rapidResponseTest = updatedRapidResponseTest
        return updatedRapidResponseTest
      })
    // errors handled in child component pitchDetails
  }

  historyBack () {
    this.$window.history.back()
  }
}
