export default class RepTrendDialogController {
  constructor ($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
  }

  close () {
    this.$mdDialog.hide()
  }
}
