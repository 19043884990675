// create users role info view

const manageUsersCreateRoleInfo = 'manage.users.create.roleInfo'

const manageUsersCreateRoleInfoConfig = {
  params: {
    user: null
  },
  component: 'roleInfoForm',
  resolve: {
    user: function ($state, $transition$) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) {
        $state.go('manage.users.create.personalInfo')
      }
      return user
    },
    groups: function (IdentityService) {
      'ngInject'
      return IdentityService.Groups.list.get({ assignable: true })
        .then(groups => groups.plain())
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreateRoleInfo, manageUsersCreateRoleInfoConfig }
