const globalSlug = 'global'

export default class AccessListService {
  constructor(Restangular) {
    'ngInject'

    this.acl = Restangular.all('acl/')

    this.createNode = {
      post: data => {
        const { compSlug = globalSlug, aclId, ...nodeData } = data

        let uriParts = [compSlug, aclId, 'node']
        if (compSlug !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        return this.acl.one(`${uri}/`).customPOST(nodeData)
      },
    }

    this.editNode = {
      patch: data => {
        const { compSlug = globalSlug, acl, ...nodeData } = data

        let uriParts = [compSlug, acl, 'node', nodeData.uuid]
        if (compSlug !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        return this.acl.one(`${uri}/`).customPUT(nodeData)
      },
    }

    this.deleteNode = {
      one: data => {
        const { compSlug = globalSlug, acl, ...nodeData } = data

        let uriParts = [compSlug, acl, 'node', nodeData.uuid]
        if (compSlug !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        return this.acl.one(`${uri}/`)
      },
    }

    this.list = {
      get: data => {
        let { company = globalSlug, ...params } = data
        if (company === '') {
          company = globalSlug
        }
        let uriParts = [company]
        if (company !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        let resource = this.acl.all(uri)
        return resource.getList(params)
      },
      one: (uuid, compSlug) => {
        let uriParts = [compSlug, uuid]
        if (compSlug !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        return this.acl.one(`${uri}/`)
      },
    }

    this.create = {
      post: data => {
        const { compSlug = 'global', ...aclData } = data
        let uriParts = [compSlug]
        if (compSlug !== globalSlug) {
          uriParts = ['company'].concat(uriParts)
        }
        let uri = uriParts.join('/')
        return this.acl.one(`${uri}/`).customPOST(aclData)
      },
    }
  }

  saveNode(data) {
    let { editMode, acl, compSlug = 'global', nodeData } = data

    let uriParts = [compSlug, acl, 'node']
    if (compSlug !== globalSlug) {
      uriParts = ['company'].concat(uriParts)
    }
    if (editMode) {
      uriParts.push(nodeData.uuid)
    }

    let uri = uriParts.join('/')
    let resource = this.acl.one(uri)

    if (editMode) {
      return resource.customPUT(nodeData)
    } else {
      return resource.customPOST(nodeData)
    }
  }

  saveAcl(data) {
    let { editMode, campSlug = 'global', ...aclData } = data

    let uriParts = [campSlug]
    if (editMode) {
      uriParts.push(aclData.uuid)
    }

    let uri = uriParts.join('/')
    let resource = this.acl.one(uri)
    if (editMode) {
      return resource.customPUT(aclData)
    } else {
      return resource.customPOST(aclData)
    }
  }
}
