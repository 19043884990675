// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = {
  Icon: React.ComponentType<{ className: string }>,
  headerText: string,
  message: string,
  classes: Object,
  innerRef: string,
}

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  header: {
    color: theme.palette.primary.contrastText,
    height: 190,
    backgroundColor: '#7c8a97',
    '& h1': {
      fontSize: 48,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
  message: {
    padding: '112px 0',
    fontColor: theme.palette.text.primary,
    '& p': {
      color: '#7c8a97',
      marginTop: 40,
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
    width: 60,
    height: 60,
    padding: '0 23px',
  },
})

function TableLoader({ classes, headerText, message, Icon }: Props) {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        alignItems="center"
        className={classes.header}
        container
        justify="center"
      >
        <Grid item>
          <Icon className={classes.icon} />
        </Grid>
        <Grid item>
          <h1>{headerText}</h1>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        className={classes.message}
        container
        direction="column"
        justify="center"
      >
        <Grid item>
          <CircularProgress size={45} style={{ color: '#7c8a97' }} />
        </Grid>
        <Grid item>
          <p>{message}</p>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TableLoader)
