export default class editVariablesController {
  constructor (ToastService, $window, $state, $mdDialog) {
    'ngInject'
    this.TS = ToastService
    this.$window = $window
    this.$state = $state
    this.$mdDialog = $mdDialog
  }

  goToVariables () {
    const params = {
      compSlug: this.company.slug || 'global',
      uuid: this.entity.uuid,
      endpointRoute: this.endpoint
    }
    if (this.createTests.variables === 'add') {
      this.$state.go('manage.editSegmentVariables', params)
    } else {
      Object.assign(params, {
        key: this.createTests.variables.key,
        value: this.createTests.variables.value
      })
      this.$state.go('manage.editSegmentVariables', params)
    }
    this.$mdDialog.cancel()
  }
}
