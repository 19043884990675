import {
  DATA_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
  LIST_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'contactRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Contact Rate',
  },
}

const keys = {
  componentKeys: [
    DATA_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
    LIST_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'contacted' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const contactRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { contactRate }
