export default class EditCompanyController {
  constructor($state) {
    'ngInject'
    this.$state = $state
    this.state = {
      navItems: [
        {
          name: 'Settings',
          route: 'manage.editCompany.settings',
        },
        {
          name: 'Phrase Books',
          route: 'manage.editCompany.phraseBooks',
        },
        {
          name: 'Audio Resources',
          route: 'manage.editCompany.audioResources',
          largeButton: true,
        },
        {
          name: 'RR Tests',
          route: 'manage.editCompany.rapidResponseTests',
          largeButton: true,
        },
        {
          name: 'RR Segments',
          route: 'manage.editCompany.rapidResponseSegments',
          largeButton: true,
        },
      ],
      backTo: {
        name: 'companies',
        route: 'manage.companies',
      },
    }
  }

  $onInit() {
    this.state.currentNavItem = this.$state.current.name
  }

  goTo(stateKey) {
    this.state.currentNavItem = this.state[stateKey]
    this.$state.go(this.state[stateKey])
  }
}
