// Manage Edit Location
import {
  manageEditLocation,
  manageEditLocationConfig,
} from './manage.editLocation.js'
import {
  createLocationTeam,
  createLocationTeamConfig,
} from './child-states/edit-location-create-team/child-states/create/manage.editLocation.createTeam.create.js'
import {
  manageEditLocationCreateTeam,
  manageEditLocationCreateTeamConfig,
} from './child-states/edit-location-create-team/manage.editLocation.createTeam.js'

export default function manageEditLocationRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditLocation, manageEditLocationConfig)
    .state(manageEditLocationCreateTeam, manageEditLocationCreateTeamConfig)
    .state(createLocationTeam, createLocationTeamConfig)
}
