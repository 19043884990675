import { curry } from 'ramda'
import { ADMIN_GROUP } from 'permissions.js'

export default class DetailsDialogController {
  constructor($mdDialog, ManagementService, ToastService) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.MS = ManagementService
    this.TS = ToastService
    this.ADMIN_GROUP = ADMIN_GROUP
    this.state = {
      loading: true,
    }

    this.curriedPopulateRelations = curry((entityName, entity) => {
      return this.MS.populateEntityRelations(entity, { entityName })
    })
  }

  fetchEntity() {
    this.service
      .get()
      .then(entity => entity.plain())
      .then(this.curriedPopulateRelations(this.entityName))
      .then(populatedEntity => {
        this.entity = populatedEntity
      })
      .catch(err => {
        console.error('Error fetching relations: ', err)
        this.TS.show({
          text: 'Error fetching relations',
        })
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  close() {
    this.$mdDialog.hide()
  }
}
