// Edit Campaign view
import { MANAGE_GROUP } from 'permissions.js'
import { params, getCampaign } from '../../../utils/resolve/campaign.js'
import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
import { getOpenSections } from '../../../utils/resolve/openSections.js'
import { getSessionUser } from '../../../utils/resolve/user.js'

const manageEditCampaign = 'manage.editCampaign'

const pageTitleConfig = {
  title: 'Editing Campaign: ',
  resolveEntity: 'campaign',
  accessor: 'name',
}

const manageEditCampaignConfig = {
  abstract: true,
  url: '/campaign/edit/{slug}/{uuid}',
  params: params,
  component: 'editCampaign',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ]
    }
  },
  resolve: {
    user: getSessionUser,
    campaign: getCampaign,
    openSections: getOpenSections('manage.campaignRouter'),
    pageTitle: makePageTitle(pageTitleConfig),
  },
}

export { manageEditCampaign, manageEditCampaignConfig }
