// dashboard.lists view

const dashboardLists = 'dashboard.lists'

const dashboardListsConfig = {
  url: '/lists/{listRealmSlug}',
  params: {
    listRealmSlug: {
      dynamic: true,
      value: null
    }
  },
  component: 'lists',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign) {
      'ngInject'
      return selectedCampaign
    },
    offsetMinutes: function (timezoneOffsetMinutes) {
      'ngInject'
      return timezoneOffsetMinutes
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    },
    listRealm: function (realm, $transition$) {
      'ngInject'
      const slug = $transition$.params().listRealmSlug
      if (slug) {
        return slug
      } else {
        return realm ? realm.slug : slug
      }
    }
  }
}

export { dashboardLists, dashboardListsConfig }
