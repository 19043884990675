import { MANAGE_AUDIO_VOICE } from '../manage.audioVoice.js'

// manage voice phrase audio view
// state will be | manage.audioVoice.phraseAudio
const MANAGE_VOICE_PHRASE_AUDIO = `${MANAGE_AUDIO_VOICE}.phraseAudio`

const phraseAudioConfig = {
  url: '/phrase',
  component: 'phraseAudio',
}

export { MANAGE_VOICE_PHRASE_AUDIO, phraseAudioConfig }

