import PCD from '../../../../dialogs/create-panel/createPanel.js'

export default class PitchGuiController {
  constructor($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog
  }

  $onInit() {
    this.state = {
      loading: true,
      panels: [],
      dialogs: {
        createPanel: PCD,
      },
    }
  }

  $onChanges(bindings) {
    if (bindings.guiService && bindings.pitch) {
      // Can now make requests with panel service
      this.setupPanelsService(this.pitch)
      this.getPanels(this.pitch)
    }
  }

  setupPanelsService(pitch) {
    this.panelsService = this.guiService.one(`panel/${pitch.uuid}`)
  }

  getPanels(pitch) {
    this.panelsService
      .get()
      .then(panels => {
        this.state.panels = panels
      })
      .catch(console.error)
      .finally(() => {
        this.state.loading = false
      })
  }

  createPanel() {
    this.openDialog('createPanel', {
      service: this.guiService.one(`panel/${this.pitch.uuid}`),
    }).then(createdPanel => {
      this.state.panels.push(createdPanel)
    })
  }

  removePanel() {
    this.state.panels.splice(this.selectedTabIndex, 1)
  }

  openDialog(dialogKey = '', locals = {}) {
    const dialog = this.state.dialogs[dialogKey]
    dialog.locals = locals
    return this.$mdDialog.show(dialog)
  }
}
