// css
import './component/agents.scss'

// services
import AgentService from './services/AgentService.js'

// components
import dashboardAgents from './component/agents.js'
import readOnlyTable from './sub-components/read-only-table/component/readOnlyTable.js'

export default angular
  .module('portalApp.dashboard.agents', [])
  .service('AgentService', AgentService)
  .component('dashboardAgents', dashboardAgents)
  .component('readOnlyTable', readOnlyTable)
  .name
