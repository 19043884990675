import moment from 'moment'
import { clone } from 'ramda'
import roundTo from 'round-to'

export default class DualAxisSplineChart {
  constructor($timeout) {
    'ngInject'

    this.lineChart = {
      title: {
        text: '',
      },
      options: {
        credits: false,
        chart: {
          type: 'spline',
          zoomType: 'x',
          title: {
            text: '',
          },
          resetZoomButton: {
            position: {
              align: 'left',
              x: 0,
              y: 30,
            },
            theme: {
              style: {
                color: 'rgba(0, 0, 0, 0.60)',
              },
              fill: 'white',
              'stroke-width': 1,
              stroke: 'silver',
              r: 0,
              states: {
                hover: {
                  style: {
                    color: 'white',
                  },
                  fill: '#b5b5b5',
                },
                select: {
                  stroke: '#039',
                  fill: '#bada55',
                },
              },
            },
          },
          events: {
            drilldown: function(e) {
              const dayTitle = `${moment(e.point.x).format(
                'dddd, MMMM Do YYYY',
              )}`
              const metricTitle = this.options.exporting.chartOptions.title.text
              this.setTitle({ text: `${metricTitle} - ${dayTitle}` })
            },
            drillup: function(e) {
              const truncatedTitle = this.options.exporting.chartOptions.title.text
                .split('-')
                .shift()
              this.options.exporting.chartOptions.title.text = truncatedTitle
              this.setTitle({ text: '' })
            },
          },
        },
        tooltip: {
          followPointer: true,
          shared: true,
        },
        exporting: {
          sourceWidth: 1600,
          sourceHeight: 800,
          csv: {
            dateFormat: '%m-%d-%Y %H:%M:%S',
          },
          chartOptions: {
            title: {
              text: '',
            },
            plotOptions: {
              series: {
                dataLabels: {
                  formatter: function() {
                    if (!this.y) return
                    return `${roundTo(this.y, 2)}`
                  },
                  enabled: true,
                },
              },
            },
          },
        },
        drilldown: {
          allowPointDrilldown: false,
          drillUpButton: {
            relativeTo: 'spacingBox',
            position: {
              align: '',
              y: 0,
              x: 0,
            },
            theme: {
              style: {
                color: 'rgba(0, 0, 0, 0.60)',
              },
              fill: 'white',
              'stroke-width': 1,
              stroke: 'silver',
              r: 0,
              states: {
                hover: {
                  style: {
                    color: 'white',
                  },
                  fill: '#b5b5b5',
                },
                select: {
                  stroke: '#039',
                  fill: '#bada55',
                },
              },
            },
          },
        },
        navigator: {
          enabled: false,
          series: {
            includeInCSVExport: false,
          },
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: '',
        },
        min: 0,
      },
      series: [],
      size: {},
      func: function(chart) {
        $timeout(() => {
          chart.reflow()
        }, 200)
      },
    }
  }

  getChart() {
    return clone(this.lineChart)
  }
}
