import ListGraphController from './controller/ListGraphDialogController.js'
const template = require('./listGraphDialog.tpl.pug')

const listGraphDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ListGraphController
}

export default listGraphDialog
