import PitchOptionGroupsController from './controller/pitchOptionGroupsController.js'
const template = require('./pitchOptionGroups.component.tpl.pug')

const pitchOptionGroups = {
  controller: PitchOptionGroupsController,
  template: template(),
  bindings: {
    guiService: '<',
  }
}

export default pitchOptionGroups

