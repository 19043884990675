import './manageUsersEdit.component.scss'
import ManageUsersEditController from './controller/ManageUsersEditController.js'
const template = require('./manageUsersEdit.component.tpl.pug')

const manageUsersEdit = {
  template: template(),
  controller: ManageUsersEditController,
  bindings: {
    entity: '<',
    entityIndex: '<',
    onEntityUpdate: '&',
  }
}

export default manageUsersEdit
