import { compose, map, reduce, toPairs, zipObj } from 'ramda'
import roundTo from 'round-to'
import metricInfoDialog from '../../../../dialogs/metric-info/metricInfoDialog.js'

export default class ProspectHealthController {
  constructor($mdDialog, StatsService) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.SS = StatsService
    this.state = {
      loading: true,
      count: 0,
      health: {
        prospects: {},
        avgPen: {},
      },
    }
    this.attemptsToArray = compose(map(zipObj(['attempt', 'values'])), toPairs)
  }

  $onInit() {
    if (this.title === 'Scheduled Prospects') return
    this.fetchData(this.metric.details)
  }

  $onChanges(bindings) {
    if (this.title === 'Scheduled Prospects') return
    this.updateBindings(bindings) // update bindings to recalc metric health
    this.fetchData(this.metric.details)
  }

  fetchData(details) {
    this.SS[this.key].get
      .call(this.SS, details)
      .then(data => {
        this.state.count = data.count
        const avgPen = this.getAvgPen(data.results)
        this.state.health.prospects = this.getProspectHealth(data.count)
        this.state.health.avgPen = this.getAvgPenHealth(avgPen)
        this.state.loading = false
      })
      .catch(() => {
        this.state.loading = false
        this.state.count = 'Error'
      })
  }

  updateBindings(bindings) {
    const bndKeys = Object.keys(bindings)
    bndKeys.forEach(bnd => {
      this[bnd] = bindings[bnd].currentValue
    })
  }

  getAvgPen(data) {
    const attArr = this.attemptsToArray(data)
    const multiAttempts = (acc, att) => {
      const attNum = parseInt(att.attempt)
      const value = att.values.num_attempts || 0
      const result = attNum * value
      acc.push(result)
      return acc
    }
    const sum = (acc, num) => {
      acc += num
      return acc
    }
    const sumAttempts = (acc, att) => {
      acc += att.values.num_attempts || 0
      return acc
    }
    const getDividend = compose(reduce(sum, 0), reduce(multiAttempts, []))
    const getDivisor = reduce(sumAttempts, 0)
    return roundTo(getDividend(attArr) / getDivisor(attArr), 2)
  }

  getAvgPenHealth(avgPen) {
    if (avgPen < 3) {
      return {
        value: avgPen,
        class: 'safe',
        icon: 'action:done',
      }
    } else if (avgPen > 3 && avgPen < 5) {
      return {
        value: avgPen,
        class: 'caution',
        icon: 'alert:warning',
      }
    } else {
      return {
        value: avgPen,
        class: 'danger',
        icon: 'alert:warning',
      }
    }
  }

  getProspectHealth(count) {
    if (count > 500) {
      return {
        class: 'safe',
        icon: 'action:done',
      }
    } else if (count > 300 && count < 500) {
      return {
        class: 'caution',
        icon: 'alert:warning',
      }
    } else {
      return {
        class: 'danger',
        icon: 'alert:warning',
      }
    }
  }

  openInfo(ev, title, metricKey) {
    const MID = metricInfoDialog
    MID.targetEvent = ev
    MID.locals = {
      title: this.title,
      key: metricKey,
    }
    this.$mdDialog.show(MID)
  }
}
