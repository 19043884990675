import angular from 'angular'

// Services
import ManagePhraseBookPhraseService from './services/ManagePhraseBookPhraseService.js'

// components
import editPhraseBookPhrase from './component/editPhraseBookPhrase.component.js'
import editPhraseBookPhraseForm from './sub-components/edit-phrase-book-phrase-form/component/editPhraseBookPhraseForm'

export default angular
  .module('portalApp.manage.editPhraseBookPhrase', [])
  .service('ManagePhraseBookPhraseService', ManagePhraseBookPhraseService)
  .component('editPhraseBookPhrase', editPhraseBookPhrase)
  .component('editPhraseBookPhraseForm', editPhraseBookPhraseForm).name
