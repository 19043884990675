import { findIndex, propEq } from 'ramda'

export default class SelecteMetricsController {
  constructor($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.state = {
      selectedMetrics: this.selectedMetrics,
    }
  }

  toggle(metric, list) {
    const index = findIndex(propEq('key', metric.key))(list)
    if (index > -1) list.splice(index, 1)
    else list.push(metric)
  }

  inList(metric, list) {
    return findIndex(propEq('key', metric.key))(list) > -1
  }

  reset() {
    this.state.selectedMetrics = []
  }

  close(selectedMetrics) {
    this.$mdDialog.hide(selectedMetrics)
  }

  cancel() {
    this.$mdDialog.cancel()
  }
}
