export const API = {
  production: {
    base: 'https://api.perfectpitchtech.com',
    rc: 'https://rc-api.perfectpitchtech.com',
  },
  development: {
    base: 'https://rc-api.perfectpitchtech.com',
    rc: 'https://rc-api.perfectpitchtech.com',
  },
  aws: {
    base: 'https://cos1dt20wk.execute-api.us-east-1.amazonaws.com',
    rc: 'https://cos1dt20wk.execute-api.us-east-1.amazonaws.com',
  },
}
export const CREDENTIALS = {
  "username": "gabriel",
  "password": "DEVteam12345"
}
/* These variables are injected by webpack from the commandline, they are accessible from any file
| but are added here for clarity, see webpack.config.js and search for webpack.DefinePlugin
| To add additional keys first add them in the webpack config and reference them here. The globals
| need to be ignored by eslint, see .eslint.json
*/
export const ENV = {
  NODE_ENV: NODE_ENV,
  API_BASE_URL: API_BASE_URL,
  UI_ROUTER_VISUALIZER: UI_ROUTER_VISUALIZER,
}
