import { MANAGE_GROUP } from 'permissions.js'

export default class TitleBarComponentController {
  constructor($state, SideNavService, ManageMenuService, RoleStore) {
    'ngInject'
    this.MMS = ManageMenuService
    this.RS = RoleStore
    this.$state = $state
    this.SNS = SideNavService
    this.MANAGE_GROUP = MANAGE_GROUP
    this.state = {
      sideNav: {
        menu: [],
      },
    }
  }

  $onInit() {
    this.menu = this.MMS.getMenu()
    this.userRoles = this.RS.getAllRoles()
    this.state.sideNav.menu = this.SNS.createMenuForRole(
      this.menu,
      this.userRoles,
    )
    this.setActiveMenuElement()
  }

  setActiveMenuElement() {
    let firstMenuElemWord
    for (let [index, menuElem] of this.state.sideNav.menu.entries()) {
      firstMenuElemWord = menuElem.name.split(' ')[0].toLocaleLowerCase()
      if (
        this.$state.current.name.includes(firstMenuElemWord) ||
        firstMenuElemWord.includes('manage')
      ) {
        const currentMenu = this.state.sideNav.menu[index].pages
        this.activeMenuIndex = currentMenu.filter(
          menuElem => !this.$state.current.name.includes(menuElem.state),
        )
      }
    }
    this.activeMenuIndex.forEach(menuElement => {})
  }

  buttonClass(button) {
    if (typeof button.className == 'undefined') {
      return {}
    }
    let data = {}
    data[button.className] = true
    return data
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev)
  }
}
