// @flow

import { find, propEq } from 'ramda'

import type { Campaign, Company, DidPool, VoiceProvider } from 'types/entities'

export default class CreateDidPoolController {
  $q: Object
  $mdDialog: Object
  $state: Object
  $window: Object
  DIDS: Object
  Campaign: Object
  Company: Object
  TS: Object
  editablePool: DidPool
  isCreate: boolean
  state: {
    submitting: boolean,
    error: boolean,
    companies: Array<Company>,
    campaigns: Array<Campaign>,
    voiceProviders: Array<VoiceProvider>,
  }

  constructor(
    $q: Object,
    $mdDialog: Object,
    $state: Object,
    IdentityService: Object,
    ToastService: Object,
  ) {
    'ngInject'

    this.$q = $q
    this.$mdDialog = $mdDialog
    this.DIDS = IdentityService.DIDS
    this.Campaign = IdentityService.Campaign
    this.Company = IdentityService.Company
    this.TS = ToastService
    this.$state = $state

    this.editablePool = {
      active: true,
      allow_inbound: true,
      campaign: '',
      company: '',
      did_count: 0,
      locale_name: 'US',
      name: '',
      priority: 0,
      sip_uri: '',
      skip_inbound_ivr: false,
      start_node: null,
      vars_prospect_channel: '',
      voice_provider: '',
      matching_preference: '',
    }

    this.state = {
      submitting: false,
      error: false,
      companies: [],
      campaigns: [],
      voiceProviders: [],
    }
  }

  createDidPool(pool: DidPool): void {
    const uriData = {
      company: find(propEq('uuid', pool.company))(this.state.companies),
      campaign: find(propEq('uuid', pool.campaign))(this.state.campaigns),
    }
    this.DIDS.createPool
      .post(pool, uriData)
      .then(createdDidPool => {
        if (createdDidPool.errors) {
          throw new Error(createdDidPool.errors)
        }
        this.close(createdDidPool)
      })
      .catch(err => {
        this.state.error = true
        console.error(err)
        this.TS.show({
          text: 'Error creating a new did pool',
        })
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  getVoiceProviders(): Promise<Array<VoiceProvider>> {
    return this.DIDS.listVoiceProvider
      .get()
      .then(provs => provs.plain())
      .then(provs => {
        this.state.voiceProviders = provs
      })
  }

  getCompanies(): Promise<Array<Company>> {
    return this.Company.list
      .get({ assignable: true })
      .then(comps => comps.plain())
      .then(comps => {
        this.state.companies = comps
      })
  }

  getCampaigns(): Promise<Array<Campaign>> {
    return this.Campaign.list
      .get({
        assignable: true,
        company: this.editablePool.company,
      })
      .then(camps => camps.plain())
      .then(camps => {
        if (!camps.length) {
          this.TS.show({
            text: 'No campaigns available, you cannot create a pool',
          })
        }
        this.state.campaigns = camps
      })
  }

  close(pool: DidPool) {
    pool
      ? this.$mdDialog.hide({ wasCreate: this.isCreate, newDidPool: pool })
      : this.$mdDialog.cancel('Did pool creation canceled')
  }
}
