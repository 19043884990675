import { DIALER_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'repWaitTime',
  type: 'dataDog',
}

const formatting = {
  format: {
    title: 'Avg Wait Time',
  },
}

const keys = {
  componentKeys: [DIALER_METRICS],
  healthKey: 'performance_health',
}

const repWaitTime = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { repWaitTime }
