import ComponentSettingsDialogController from './controller/ComponentSettingsDialogController.js'
const template = require('./componentSettingsDialog.tpl.pug')

const componentSettingsDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: 'cs',
  controller: ComponentSettingsDialogController
}

export default componentSettingsDialog
