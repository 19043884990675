// @flow

import type { Entity } from 'types/entities.js'
import './managementSearch.scss'
import template from './managementSearch.component.tpl.pug'


export type SearchConfig = {|
  editable: boolean,
  limit: number,
  helperText: string,
  searchValue: ?string,
  searchResults: Entity[]
|}

const managementSearch = {
  template: template(),
  bindings: {
    entity: '@',
    entityPlural: '@',
    tableConfig: '<',
    searchConfig: '<',
    hasCreate: '<',
    hideQueryMode: '<',
    onQueryModeToggle: '&',
    onSearchUpdate: '&',
    onEntityClick: '&',
    onCreateClick: '&',
  },
}

export default managementSearch
