import { map } from 'ramda'

function calcMetric(metric) {
  if (metric.aggregate) return metric
  const dividend = metric.dividend.value
  const divisor = metric.divisor.value
  const quotient = metric.isRate ? dividend / divisor * 100 : dividend / divisor
  metric.y = isFinite(quotient) ? quotient : 0
  return metric
}

function dayFinalizer(day) {
  day.metrics = map(calcMetric, day.metrics)
  return day
}

function finalizeDays(data) {
  return {
    metrics: data.metrics,
    days: map(dayFinalizer, data.days),
  }
}

function finalizeRange(data) {
  return map(calcMetric, data)
}

export { finalizeDays, finalizeRange }
