// @flow

import type { SideNavMenu } from '../../common-components/side-nav/services/SideNavService.js'

import { permissions } from 'permissions.js'

const {
  SUPER_USER,
  RECORDER,
  REALM_ADMIN,
  CLIENT_MANAGER,
  LOCATION_MANAGER,
  COMPANY_MANAGER,
  CAMPAIGN_MANAGER,
  TEAM_LEAD,
  REP,
  CLIENT,
} = permissions

export default class ManageMenuService {
  getMenu(): SideNavMenu {
    return [
      {
        name: 'Management',
        type: 'toggle',
        icon: 'social:outline_people',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          COMPANY_MANAGER,
          CAMPAIGN_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
        includedSubstates: [
          'manage.editCampaign',
          'manage.editCompany',
          'manage.editLocation',
          'manage.editTeam',
          'manage.editDidPool',
        ],
        pages: [
          {
            name: 'Campaigns',
            type: 'link',
            icon: 'maps:map',
            state: 'manage.campaigns',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
              CAMPAIGN_MANAGER,
              LOCATION_MANAGER,
              CLIENT,
            ],
          },
          {
            name: 'Users',
            type: 'link',
            icon: 'action:account_circle',
            state: 'manage.users',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
              REP,
            ],
          },
          {
            name: 'Companies',
            type: 'link',
            icon: 'communication:business',
            state: 'manage.companies',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
              LOCATION_MANAGER,
            ],
          },
          {
            name: 'Locations',
            type: 'link',
            icon: 'maps:place',
            state: 'manage.locations',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
            ],
          },
          {
            name: 'Realms',
            type: 'link',
            icon: '',
            state: 'manage.realms',
            permissions: [SUPER_USER, REALM_ADMIN],
          },
          // {
          //   name: 'Access Lists',
          //   type: 'link',
          //   icon: 'mdi:playlist-plus',
          //   state: 'manage.accessLists',
          //   permissions: [SUPER_USER, REALM_ADMIN],
          // },
          {
            name: 'Did Pools',
            type: 'link',
            icon: 'mdi:phone',
            state: 'manage.didpools',
            permissions: [SUPER_USER, REALM_ADMIN],
          },
          {
            name: 'Dids',
            type: 'link',
            icon: 'mdi:phone',
            state: 'manage.dids',
            permissions: [SUPER_USER, REALM_ADMIN],
          },
          {
            name: 'Dnc',
            type: 'link',
            icon: '',
            state: 'manage.dnc',
            permissions: [SUPER_USER, REALM_ADMIN, CAMPAIGN_MANAGER],
          },
        ],
      },
      {
        name: 'Global Pitch',
        type: 'toggle',
        icon: 'action:language',
        permissions: [SUPER_USER, REALM_ADMIN, CLIENT_MANAGER, COMPANY_MANAGER],
        includedSubstates: [
          'manage.editPhraseBook',
          'manage.editSegmentVariables',
          'manage.editGlobalOptionGroups',
          'manage.globalRapidResponse',
        ],
        pages: [
          {
            name: 'Phrase Books',
            type: 'link',
            icon: 'social:public',
            state: 'manage.globalPitchPhraseBooks',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
            ],
          },
          {
            name: 'Segments',
            type: 'link',
            icon: 'social:public',
            state: 'manage.globalPitchSegments',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
            ],
          },
          {
            name: 'Option Group',
            type: 'link',
            icon: 'social:group',
            state: 'manage.globalOptionGroup',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
            ],
          },
          {
            name: 'Rapid Response',
            type: 'link',
            icon: 'content:reply',
            state: 'manage.globalRapidResponse.tests',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
            ],
          },
        ],
      },
      {
        name: 'Audio',
        type: 'toggle',
        icon: 'av:outline_library_music',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          COMPANY_MANAGER,
          CAMPAIGN_MANAGER,
          RECORDER,
        ],
        includedSubstates: ['manage.audioVoice'],
        pages: [
          {
            name: 'Resources',
            type: 'link',
            icon: 'av:library_music',
            state: 'manage.audioResources',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
              CAMPAIGN_MANAGER,
            ],
          },
          {
            name: 'Voice',
            type: 'link',
            icon: 'action:settings_voice',
            state: 'manage.audioVoice.pitchAudio',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              COMPANY_MANAGER,
              CAMPAIGN_MANAGER,
              RECORDER,
            ],
          },
        ],
      },
      {
        name: 'Stations',
        type: 'toggle',
        icon: 'action:record_voice_over',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          COMPANY_MANAGER,
          CAMPAIGN_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
        state: 'manage.stations',
        pages: [],
      },
    ]
  }
}
