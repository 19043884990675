import React, {useState, useEffect} from 'react'
import axios from "axios"
import {makeStyles} from '@material-ui/core/styles'
import {getBaseUrl} from '../../../global/config/providers/restangularProvider/rest.js'

import AppModal from 'components/AppModal.js'
import AppSelect from 'components/AppSelect.js'
import AppTextField from 'components/AppTextField.js'
import AppButton from 'components/AppButton.js'
import CopyElement from 'common-components/copy-element/CopyElement'
import DeleteStation from './DeleteStation'

import DeleteIcon from '@material-ui/icons/Delete';
import {Grid, FormControlLabel, Checkbox } from '@material-ui/core'

const API_BASE_URL = getBaseUrl(window.location.host, true)

const useStyles = makeStyles(theme => ({
    line: {
        width: 100 + '%',
        borderBottom: '1px solid #d5d5d5'
    },
    active: {
        '& span.MuiFormControlLabel-label': {
            color: '#777777',
            fontSize: 16
        }
    }
}))

const EditStation = props => {
    const classes = useStyles()
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [realm, setRealm] = useState(null);
    const [active, setActive] = useState(false);
    const [realmsList, setRealmsList] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [hideModal, setHideModal] = useState(true);
    const [showModal, setShowModal] = useState(props.showModal)

    const saveChanges = () => {
        let data = {
            username: userName,
            password: password,
            realm: realm,
            active: active,
        }

        axios.
        patch(`${API_BASE_URL}/identity/station/${props.station.uuid}/`, data)
        .then(res => {
            props.handleEdit();
        })
        .catch(err => console.log(err)); 
    }

    const handleDelete = () => {setOpenDelete(true), setHideModal(false), setShowModal(false)}

    const handleUser = (value) => setUserName(value)

    const handlePassword = (value) => setPassword(value)

    const handleActive = event => setActive(event.target.checked)

    const handleRealm = (value) => setRealm(value)

    const cancelDelete = () => {setOpenDelete(false), setShowModal(true), setHideModal(true)}

    const cancelAnother = () => {setOpenDelete(false), setShowModal(false), setHideModal(true)}

    const openAdd = () => { setShowModal(false), setOpenDelete(false), setHideModal(true), props.openAddDialog()}

    const fillRealms = () => {
        axios
            .get(`${API_BASE_URL}/identity/realm/list/`)
            .then(realms => {
                setRealmsList(realms.data);
                if (props.station.realm)
                    setRealm( realms.data.find( element =>element.name === props.station.realm.name))
            })
            .catch(console.error)
    }

    useEffect(() => {
        if (props.station != null || props.station != undefined) {
            setRealm(null)
            setUserName(props.station.username)
            setPassword(props.station.password)
            setActive(props.station.active)
            fillRealms();
            setShowModal(props.showModal)
        }
    }, [props])

    return (
        <React.Fragment>
            {
                hideModal ? 
            <AppModal
                modalWith={420}
                showModal={showModal}
                hideModal={props.hideModal}
                title="Edit Station">
                <Grid container className="p-3" spacing={4}>
                    <Grid item xs={12}>
                        <AppTextField
                            label="UUID"
                            value={props.station.uuid}
                            disabled={true}
                            icon={< CopyElement title = "Copy UUID" value = {
                            props.station.uuid
                        }
                        response = "UUID copied to clipboard" />}/>
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextField
                            value={userName}
                            label="Username"
                            helperText="Identifies this station"
                            onChange={value => handleUser(value)}/>
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextField
                            value={password}
                            label="Password"
                            type="password"
                            helperText="Choose a secure password"
                            onChange={value => handlePassword(value)}/>
                    </Grid>

                    <Grid item xs={12}>
                        <AppSelect item={realm} label="Realm (Optional)" keyName='slug' 
                        displayName={'name'}  data={realmsList} onChange={value => handleRealm(value)}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            className={classes.active}
                            control={< Checkbox checked = {
                            active
                        }
                        onChange = {
                            handleActive
                        }
                        value = {
                            active
                        } />}
                            label="Active"/>
                    </Grid>

                    <Grid item xs={12} className="mt-0 pt-0">
                        <div className={classes.line}></div>
                    </Grid>

                    <Grid item xs={6} className="mb-0">
                        <p
                            style={{
                            color: "#999999",
                            fontSize: "16px"
                        }}>Delete station</p>
                    </Grid>
                    <Grid
                        className="mb-0"
                        item
                        xs={6}
                        style={{
                        textAlign: "right"
                    }}>
                        <AppButton type="Red" onClick={handleDelete} width={130}>
                            <DeleteIcon
                                style={{
                                fontSize: 16,
                                paddingRight: 5
                            }}/>
                            Delete
                        </AppButton>
                    </Grid>

                    <Grid item xs={12} className="mt-0 pt-0">
                        <div className={classes.line}></div>
                    </Grid>

                    <Grid item xs={12} className="text-center mt-1">
                        <AppButton onClick={props.hideModal} width={165}>CANCEL</AppButton>
                        <AppButton onClick={saveChanges} className="ml-2" type="Green" width={165}>SEND</AppButton>
                    </Grid>
                </Grid>
            </AppModal>
            : 
            <DeleteStation 
                deleteDialogOpen={openDelete}
                cancelDeleteDialog={cancelDelete}
                cancelAnotherDialog={cancelAnother}
                station={props.station}
                handleDelete={props.handleDelete}
                openAdd={openAdd}
            />
        }

        </React.Fragment>
    )
}

export default EditStation
