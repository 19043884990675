// Manage Edit Global Option Groups
import {
  manageEditGlobalOptionGroups,
  manageEditGlobalOptionGroupsConfig,
} from './manage.editGlobalOptionGroups'

export default function manageEditGlobalOptionGroupsRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(
    manageEditGlobalOptionGroups,
    manageEditGlobalOptionGroupsConfig,
  )
}
