// @flow

export type Permission = $Keys<typeof permissions>
export type PermissionGroup = Array<Permission>
export type StoredPermission = {
  pk: number,
  name: Permission,
}
export type UserRoles = StoredPermission[]
export type UserPermission = {
  [key: Permission]: {
    name: Permission,
    validator: () => boolean,
  },
}
export type StoredPermissions = Array<StoredPermission>

const permissions = {
  SUPER_USER: 'SUPER_USER',
  REALM_ADMIN: 'REALM_ADMIN',
  CLIENT_MANAGER: 'CLIENT_MANAGER',
  COMPANY_MANAGER: 'COMPANY_MANAGER',
  CAMPAIGN_MANAGER: 'CAMPAIGN_MANAGER',
  LOCATION_MANAGER: 'LOCATION_MANAGER',
  TEAM_LEAD: 'TEAM_LEAD',
  REP: 'REP',
  CLIENT: 'CLIENT',
  RECORDER: 'RECORDER',
}

const SUPER_USER_ONLY: PermissionGroup = [permissions.SUPER_USER]
const ADMIN_GROUP: PermissionGroup = [
  permissions.SUPER_USER,
  permissions.REALM_ADMIN,
]
const LOCATION_LEADER_GROUP: PermissionGroup = [
  ...ADMIN_GROUP,
  permissions.CLIENT_MANAGER,
  permissions.LOCATION_MANAGER,
]
const TEAM_LEADER_GROUP: PermissionGroup = [
  ...ADMIN_GROUP,
  permissions.CLIENT_MANAGER,
  permissions.LOCATION_MANAGER,
  permissions.TEAM_LEAD,
]
const COMPANY_MANAGER_GROUP: PermissionGroup = [
  ...ADMIN_GROUP,
  permissions.CLIENT_MANAGER,
  permissions.COMPANY_MANAGER,
]
const CAMPAIGN_MANAGER_GROUP: PermissionGroup = [
  ...ADMIN_GROUP,
  permissions.CLIENT_MANAGER,
  permissions.COMPANY_MANAGER,
  permissions.CAMPAIGN_MANAGER,
]
const MANAGE_GROUP: PermissionGroup = [
  ...ADMIN_GROUP,
  permissions.CLIENT_MANAGER,
  permissions.COMPANY_MANAGER,
  permissions.CAMPAIGN_MANAGER,
  permissions.LOCATION_MANAGER,
  permissions.TEAM_LEAD,
]

export {
  permissions,
  ADMIN_GROUP,
  MANAGE_GROUP,
  SUPER_USER_ONLY,
  LOCATION_LEADER_GROUP,
  CAMPAIGN_MANAGER_GROUP,
  TEAM_LEADER_GROUP,
  COMPANY_MANAGER_GROUP,
}
