import { find } from 'ramda'
import DED from '../../../../../../dialogs/delete-entity/deleteEntity.js'
import PFD from '../../../../../../dialogs/panel-field/panelFieldDialog.js'

export default class PitchGuiPanelController {
  constructor($q, $mdDialog, PitchService, ToastService) {
    'ngInject'

    this.$q = $q
    this.$mdDialog = $mdDialog
    this.PS = PitchService
    this.TS = ToastService

    this.state = {
      loadingFields: true,
      fields: [],
    }
    this.editPanel = false
  }

  $onChanges(bindings) {
    if (bindings.panel.isFirstChange()) {
      this.editablePanel = Object.assign({}, this.panel)
    }

    if (bindings.panel && bindings.baseService) {
      this.setupServices(this.panel)
      this.fetchOptionGroups().then(() => {
        this.fetchPanelFields(this.panel)
      })
    }
  }

  setupServices(panel) {
    this.panelService = this.baseService.one(
      `panel/${panel.pitch}/${panel.uuid}`,
    )
    this.panelFieldService = this.baseService.one('panel-field')
    this.fieldOptionService = this.baseService.one('field-option-group')
    this.globalFieldOptionService = this.PS.setupBase().global.one(
      'gui/field-option-group',
    )
  }
  openMenu($mdMenu, ev) {
    ev.cancelBubble = true
    $mdMenu.open(ev)
  }
  fetchPanelFields() {
    this.state.loadingFields = true
    this.panelService
      .get()
      .then(populatedPanel => {
        this.state.fields = populatedPanel.fields
      })
      .catch(console.error)
      .finally(() => {
        this.state.loadingFields = false
      })
  }

  resetPanel() {
    this.editablePanel = Object.assign({}, this.panel)
    this.panelForm.$setPristine()
    this.toggleEdit()
  }

  updateOrCreatePanelField(panel, panelField, index) {
    PFD.locals = {
      isCreate: !panelField,
      panelId: panel.uuid,
      panelField,
      panelFieldService: this.panelFieldService,
      fieldOptionService: this.fieldOptionService,
      globalFieldOptionService: this.globalFieldOptionService,
    }
    this.$mdDialog.show(PFD).then(({ wasCreate, panelField }) => {
      wasCreate
        ? this.state.fields.push(panelField)
        : this.state.fields.splice(index, 1, panelField)
    })
  }

  savePanel(panel) {
    this.panelService
      .patch({ name: panel.name, description: panel.description })
      .then(updatedPanel => {
        Object.assign(this.editablePanel, updatedPanel)
        Object.assign(this.panel, updatedPanel)
        this.toggleEdit()
      })
  }

  deletePanel(panel) {
    DED.locals = {
      title: 'Delete Panel',
      entityName: panel.name,
      identityEndpoint: this.panelService,
      routeWhenSuccessful: false,
    }
    this.$mdDialog.show(DED).then(() => {
      this.removePanel({ panelId: this.panel.uuid })
    })
  }

  removePanelField(fieldIndex) {
    this.state.fields.splice(fieldIndex, 1)
  }

  deletePanelField(panelField, index) {
    DED.locals = {
      title: 'Delete Panel Field',
      entityName: panelField.label,
      identityEndpoint: this.panelFieldService.one(panelField.uuid),
      routeWhenSuccessful: false,
    }
    this.$mdDialog.show(DED).then(() => {
      this.removePanelField(index)
    })
  }

  fetchOptionGroups() {
    return this.$q
      .all({
        companyOptions: this.fetchCompanyOptionGroups(),
        globalOptions: this.fetchGlobalOptionGroups(),
      })
      .then(({ companyOptions, globalOptions }) => {
        this.state.optionGroups = [{ name: 'None', uuid: null }].concat([
          ...companyOptions,
          ...globalOptions,
        ])
      })
  }

  getOptionGroupName(optionGroupId) {
    if (optionGroupId) {
      let { name = '' } = find(g => {
        return g.uuid === optionGroupId
      }, this.state.optionGroups)
      return name
    }
    return null
  }

  fetchCompanyOptionGroups() {
    return this.fieldOptionService.get().then(options => options.plain())
  }

  fetchGlobalOptionGroups() {
    return this.globalFieldOptionService.get().then(options => options.plain())
  }

  handleCopy() {
    this.TS.show({
      text: 'Copied To Clipboard',
    })
  }
  toggleEdit() {
    this.editPanel = !this.editPanel
  }
}
