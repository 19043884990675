// @flow

import type { FilterListConfig } from '../../../../common-components/filter-list/component/filterList.js'
import type { PitchSegment } from 'types/entities.js'
import type PitchService from '../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../global/index/services/ToastService.js'

import { findIndex, propEq } from 'ramda'
import CPSD from '../../../dialogs/create-pitch-segments/createPitchSegments.js'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'

export default class GlobalPitchSegmentsController {
  PS: PitchService
  TS: ToastService
  GlobalSegmentService: Object
  $mdDialog: Object
  $state: Object
  $localStorage: Object
  searchSegment: (searchObject: { searchText: string }) => void
  state: {
    loading: boolean,
    searchText: string,
    columnsConfig: FilterListConfig,
    globalSegments: Array<PitchSegment>,
    topBarButtons: Array<Object>,
  }

  constructor(
    $mdDialog: Object,
    PitchService: PitchService,
    ToastService: ToastService,
    $localStorage: Object,
    $state: Object,
  ) {
    'ngInject'
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.$localStorage = $localStorage
    this.$state = $state
    this.state = {
      loading: true,
      searchText: '',
      columnsConfig: [
        { title: 'Name', accessor: 'name', type: 'text' },
        { title: 'Slug', accessor: 'slug', type: 'text' },
        { title: 'Type', accessor: 'type', type: 'text' },
        { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
        { title: 'Active', accessor: 'active', type: 'boolean' },
        {
          title: 'Edit',
          type: 'menu',
          icon: 'editor:mode_edit',
          menu: [
            {
              title: 'XML',
              icon: 'action:code',
              actionCb: segment => this.openSegmentDialog(segment),
            },
            {
              title: 'Variables',
              icon: 'action:visibility',
              actionCb: segment => this.goToVariables(segment),
            },
          ],
        },
      ],
      endpoint: 'segments',
      globalSegments: [],
      topBarButtons: [
        {
          title: 'New Segment',
          icon: 'content:add',
          action: this.openSegmentDialog.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }

    this.searchSegment = ({ searchText }) => {
      this.state.searchText = searchText
    }
  }

  $onInit() {
    this.GlobalSegmentService = this.PS.setupBase().global.one('segments')
    this.getSegments()
  }

  isEditingDialog() {
    if (this.$localStorage.editingDialog) {
      const segment = JSON.parse(this.$localStorage.editingDialog)
      this.openSegmentDialog(segment)
      delete this.$localStorage.editingDialog
    }
  }

  getSegments() {
    this.GlobalSegmentService.get()
      .then(globalSegments => {
        this.state.globalSegments = globalSegments.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching global pitch segments',
        })
        console.error(err)
      })
      .finally(() => {
        this.isEditingDialog()
        this.state.loading = false
      })
  }

  goToVariables(segment: PitchSegment) {
    const params = {
      compSlug: 'global',
      uuid: segment.uuid,
      endpointRoute: 'segments',
    }
    this.$state.go('manage.editSegmentVariables', params)
  }

  openSegmentDialog(segment: PitchSegment) {
    const locals = {
      isCreate: !segment,
      isRapidResponse: false,
      service: this.GlobalSegmentService,
      segment: Object.assign({}, segment),
      endpoint: 'segments',
      company: 'global',
    }
    CPSD.locals = locals
    this.$mdDialog
      .show(CPSD)
      .then(({ wasCreate, segment }) => {
        if (wasCreate) {
          this.state.globalSegments.push(segment)
        } else {
          this.updateSegment(segment)
        }
      })
      .catch(console.error)
  }

  updateSegment(segment: PitchSegment) {
    const segmentIndex = findIndex(
      propEq('uuid', segment.uuid),
      this.state.globalSegments,
    )
    if (segmentIndex > -1) {
      this.state.globalSegments.splice(segmentIndex, 1, segment)
    }
  }

  deleteSegment(segment: PitchSegment) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Global Segment',
      entityName: segment.name,
      identityEndpoint: this.GlobalSegmentService.one(segment.uuid),
      entity: segment,
      routeWhenSuccessful: 'manage.globalPitchSegments',
    }
    this.$mdDialog.show(DED)
  }
}
