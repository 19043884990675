import angular from 'angular'

// Services
import ManageDidPoolService from './component/services/ManageDidPoolService'
// import ManageUsersEditService from './sub-components/manage-users-edit/services/ManageUsersEditService.js'
// import ManageUsersCreateService from './sub-components/manage-users-create/services/ManageUsersCreateService.js'

// filters
// import filterCampaignsForCompany from './filters/filterCampaignsForCompany.js'

// components
import manageDidPool from './component/manageDidPool.component'

/*
import manageUsersEdit from './sub-components/manage-users-edit/component/manageUsersEdit.component.js'
import personalInfoForm from './sub-components/manage-users-create/sub-components/personal-info/component/personalInfoForm.component.js'
import roleInfoForm from './sub-components/manage-users-create/sub-components/role-info/component/roleInfoForm.component.js'
import companyInfoForm from './sub-components/manage-users-create/sub-components/company-info/component/companyInfoForm.component.js'
import campaignInfoForm from './sub-components/manage-users-create/sub-components/campaign-info/component/campaignInfoForm.component.js'
import teamInfoForm from './sub-components/manage-users-create/sub-components/team-info/component/teamInfoForm.component.js'
import passwordInfoForm from './sub-components/manage-users-create/sub-components/password-info/component/passwordInfoForm.component.js'
*/
export default /*
  .component('manageUsersEdit', manageUsersEdit)
  .component('personalInfoForm', personalInfoForm)
  .component('roleInfoForm', roleInfoForm)
  .component('companyInfoForm', companyInfoForm)
  .component('manageUsersEdit', manageUsersEdit)
  .component('personalInfoForm', personalInfoForm)
  .component('roleInfoForm', roleInfoForm)
  .component('companyInfoForm', companyInfoForm)
  .component('campaignInfoForm', campaignInfoForm)
  .component('teamInfoForm', teamInfoForm)
  .component('passwordInfoForm', passwordInfoForm)
  */
angular
  .module('portalApp.manage.didpool', [])
  // .filter('filterCampaignsForCompany', filterCampaignsForCompany)
  .service('ManageDidPoolService', ManageDidPoolService)
  /*
  .service('ManageUsersEditService', ManageUsersEditService)
  .service('ManageUsersCreateService', ManageUsersCreateService)
  */
  .component('manageDidPool', manageDidPool).name
