// Edit Rapid Response Test Menu view

const manageEditRapidResponseTestsMenu = 'manage.editRapidResponseTests.menu'

const manageEditRapidResponseTestsMenuConfig = {
  url: '/menu',
  component: 'editRapidResponseTestsMenu',
}

export {
  manageEditRapidResponseTestsMenu,
  manageEditRapidResponseTestsMenuConfig,
}
