export const tableConfig = [
  { title: 'Name', accessor: 'name', type: 'text' },
  { title: 'Slug', accessor: 'slug', type: 'text', copyable: true },
  { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
  {
    title: 'File',
    accessor: 'file',
    type: 'audio',
    parser: item => {
      if (!item) return null
      return item.processed_url
    },
  },
]
