import CompanySettingsController from './controller/CompanySettingsController.js'
const template = require('./companySettings.component.tpl.pug')

const CompanySettings = {
  controller: CompanySettingsController,
  template: template(),
  bindings: {
    company: '<',
    openSections: '<',
  }
}

export default CompanySettings
