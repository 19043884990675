// @flow

import type { Entity } from 'types/entities.js'
import type { RestangularPromise } from 'restangular'

import './filterToolbar.component.scss'
import FilterToolbarController from './controller/FilterToolbarController.js'
import template from './filterToolbar.component.tpl.pug'

type FilterOption = {|
  title: string,
  value: ?string | ?boolean
|}

type FilterField = {|
  label: string,
  field: string,
  defaultValue: ?string,
  valueAccessor?: string,
  labelAccessor?: string,
  hasAll?: boolean,
  options: FilterOption[] | (optionsKey: string, { assignable: boolean }) => RestangularPromise<Entity[]>
|}

export type FilterConfig = FilterField[]

const filterToolbar = {
  template: template(),
  controller: FilterToolbarController,
  bindings: {
    filterConfig: '<',
    paramsState: '<',
    getGroupsCb: '&',
    updateFilterCb: '&',
  }
}

export default filterToolbar
