// @flow
// This controller is extended by various other controllers, be careful when modifying the interface

import type { Element, RestangularPromise } from 'restangular'
import type ManagementService from '../services/ManagementService.js'
import type ToastService from '../../global/index/services/ToastService.js'
import type { Entity, EntityString } from 'types/entities.js'
import type { SearchConfig } from '../common-components/management-search/component/managementSearch.component.js'
import type { TableConfig } from '../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { FilterConfig } from '../common-components/filter-toolbar/component/filterToolbar.component.js'

export type QueryParams = {
  limit?: number,
  [param_key: string]: ?string | ?number | ?boolean,
}

interface Indexable {
  [key: string]: mixed;
}

// BaseState is extendable, you can add any other properties in a class that extends this controller
export type BaseState = {
  isLoading: boolean,
  queryCount: ?number,
  queryParams: QueryParams,
  [key: string]: any,
}

export default class ManagementController implements Indexable {
  // these props have to be added to make flow not complain about dynamic access to class
  // props or methods ie: inst['methodName']
  $key: any
  $value: any

  $state: Object
  $mdDialog: Object
  RS: Object
  MS: ManagementService
  TS: ToastService
  entity: EntityString
  entityList: Entity[]
  tableConfig: TableConfig
  searchConfig: SearchConfig
  detailsDialog: string
  createState: string
  editState: string
  paginateCurrentPage: number
  paginateLimit: number
  state: BaseState
  onPaginate: (page: number) => void

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
  ) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.RS = RoleStore
    this.MS = ManagementService
    this.TS = ToastService
    this.paginateCurrentPage = 1
    this.paginateLimit = 10
    this.state = ManagementController.baseState

    this.onPaginate = (page: number) => {
      this.state.queryParams.page = page
      this.getEntityList(
        this.state.queryParams,
        this.entity,
        this.searchConfig.editable,
      )
    }
  }

  static get baseState(): BaseState {
    return {
      isLoading: true,
      queryCount: null,
      queryParams: {},
    }
  }

  editOrView(event: Event, entity: Entity, index: number) {
    if (this.searchConfig.editable) {
      this.openView(event, entity, index)
      return
    }
    this.openDetailsDialog(event, this.detailsDialog, entity)
  }

  openView(event: Object, entity: Entity, index: number) {
    const params = this.MS.createStateParams(entity, index)
    this.$state.go(this.editState, params)
  }

  onSearchUpdate(searchText: string) {
    this.searchConfig.searchValue = searchText
    this.findEntity(
      searchText,
      this.searchConfig.editable,
      this.searchConfig.limit,
    )
  }

  onQueryModeToggle(bool: boolean) {
    this.searchConfig.editable = bool
    this.findEntity(this.searchConfig.searchValue, bool)
    this.getEntityList(this.state.queryParams, this.entity, bool)
  }

  createEntity() {
    this.$state.go(this.createState)
  }

  findEntity(
    searchText: ?string = '',
    editable: boolean = false,
    limit: number = 5,
  ) {
    this.MS.findEntity({
      searchText,
      editable,
      limit,
      entity: this.entity,
    })
      .then(results => {
        this.searchConfig.searchResults = results
      })
      .catch(err => {
        console.error(`${this.entity} search error:`, err)
        this.TS.show({
          text: `${this.entity} search error`,
        })
      })
  }

  refreshEntityList() {
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }

  bindActionCallbacks(tableConfig: TableConfig) {
    tableConfig.columns.forEach(col => {
      if (col.actionCb) {
        col.actionCb = this[col.actionCb].bind(this)
      }
    })
    return tableConfig
  }

  _callCustomService(
    service: Element,
    params: QueryParams = {},
  ): RestangularPromise<Entity[]> {
    return service
      .get(params)
      .then(res => res.plain())
      .then(this._populateEntityList.bind(this))
      .then(() => {
        if (params.page && typeof params.page === 'number') {
          this.paginateCurrentPage = params.page
        } else {
          this.paginateCurrentPage = this.paginateCurrentPage
        }
      })
      .catch(this._handleEntityListError.bind(this))
      .finally(this._endLoading.bind(this))
  }

  _populateEntityList(res: Entity[] | { count: number, results: Entity[] }) {
    if (Array.isArray(res)) {
      this.state.queryCount = res.length
      this.entityList = res
    } else if (res.count && res.results) {
      this.state.queryCount = res.count
      this.entityList = res.results
    }
    return res
  }

  _endLoading() {
    this.state.isLoading = false
  }

  _handleEntityListError(err: Object) {
    console.error(`Error fetching ${this.entity}: `, err)
    this.TS.show({
      text: `Error fetching ${this.entity}`,
    })
  }

  getEntityList(
    params: QueryParams,
    entity: EntityString,
    editable: boolean,
    service: ?Object = null,
  ) {
    this.state.isLoading = true
    params.editable = editable

    // override management service (this.MS) if not a good fit for implementation
    if (service) return this._callCustomService(service, params)

    // FIXME: this looks up a service from an object in the management service (this.MS) would
    // probably be more flexible if the inheriting controller always setup it's own service
    // rather than configuring in the management service
    this.MS.getEntityList(params, entity)
      .then(this._populateEntityList.bind(this))
      .then(() => {
        if (params.page && typeof params.page === 'number') {
          this.paginateCurrentPage = params.page
        } else {
          this.paginateCurrentPage = this.paginateCurrentPage
        }
      })
      .catch(this._handleEntityListError.bind(this))
      .finally(this._endLoading.bind(this))
  }

  getInitialParams(filterConfig: FilterConfig = []) {
    return filterConfig.reduce((acc, param) => {
      acc[param.field] = param.defaultValue
      return acc
    }, {})
  }

  updateFilter(params: QueryParams = {}) {
    params.page = null
    this.state.queryParams = params
    this.getEntityList(params, this.entity, this.searchConfig.editable)
  }

  openDetailsDialog(event: Event, dialogName: string, entity: Entity) {
    if (!dialogName) return
    const dialog = this.MS.getDialog(dialogName)
    dialog.locals = { entity }
    this.$mdDialog.show(dialog)
  }
}
