// Edit Rapid Response Test view

const manageEditRapidResponseTestsVoices =
  'manage.editRapidResponseTests.voices'

const manageEditRapidResponseTestsVoicesConfig = {
  url: '/voices',
  component: 'editRapidResponseTestsVoices',
}

export {
  manageEditRapidResponseTestsVoices,
  manageEditRapidResponseTestsVoicesConfig,
}
