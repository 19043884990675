import angular from 'angular'
import { react2angular } from 'react2angular'

// components
import { ManageStations } from './component/ManageStations.component.js'

const dependencies = [
  '$state',
  'IdentityService',
  'SideNavService',
  'ManageMenuService',
  'RoleStore',
]

export default angular
  .module('portalApp.manage.stations', [])
  .component('manageStations', react2angular(ManageStations, [], dependencies))
  .name
