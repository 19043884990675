import SideNavTopLinkController from './controller/SideNavTopLinkController.js'
const template = require('./sideNavTopLink.tpl.pug')

const sideNavTopLink = {
  template: template(),
  controller: SideNavTopLinkController,
  bindings: {
    page: '<',
    toggleNav: '&',
  },
}

export default sideNavTopLink
