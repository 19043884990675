// manage users create view

const manageUsersCreate = 'manage.users.create'

const manageUsersCreateConfig = {
  abstract: true,
  // component: 'rightDrawer'
}

export { manageUsersCreate, manageUsersCreateConfig }

