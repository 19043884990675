// @flow

import Store from '../../classes/Store.js'

export default class RoleStore extends Store {
  roles: 'roles'

  constructor () {
    super()
    this.roles = 'roles'
    super.createStoreSpace(this.roles)
  }

  defineRole (name: string, validator: () => boolean) {
    super.setInStore(this.roles, name, { name, validator })
  }

  defineMultipleRoles (roles: string[], validator: () => boolean) {
    roles.forEach((role) => {
      super.setInStore(this.roles, role, { name: role, validator })
    })
  }

  getRoleDefinition (name: string) {
    return super.getFromStore(this.roles, name)
  }

  getAllRoles () {
    return super.getStore(this.roles)
  }

  removeRole (name: string) {
    super.removeFromStore(this.roles, name)
  }

  clearRoles () {
    super.clearStore(this.roles)
  }
}
