import './voiceAudioTable.component.scss'
import VoiceAudioTableController from './controller/VoiceAudioTableController.js'
const template = require('./voiceAudioTable.component.tpl.pug')

const voiceAudioTable = {
  controller: VoiceAudioTableController,
  template: template(),
  bindings: {
    tableTitle: '@',
    tableHeaderClass: '@',
    tableLoading: '<',
    tableConfig: '<',
    pitchVersion: '<',
    userId: '<',
    audioService: '<',
    audioList: '=',
    onRowClick: '&',
    noRow: '<',
  },
}

export default voiceAudioTable
