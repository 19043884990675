import angular from 'angular'

// Services
import ManageRouterService from './services/ManageRouterService.js'

// components
import editCampaignRouter from './component/editCampaignRouter.component.js'
import routeInfoForm from './sub-components/route-info/component/routeInfoForm.component.js'
import editRouteInfoForm from './sub-components/edit-route-info/component/editRouteInfoForm.component.js'

export default angular
  .module('portalApp.manage.editCampaignRouter', [])
  .service('ManageRouterService', ManageRouterService)
  .component('editCampaignRouter', editCampaignRouter)
  .component('routeInfoForm', routeInfoForm)
  .component('editRouteInfoForm', editRouteInfoForm)
  .name
