import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class EditRouteInfoController {
  constructor ($state, $mdDialog, ManageRouterService, ToastService) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.TS = ToastService
    this.MRS = ManageRouterService
    this.ADMIN_GROUP = ADMIN_GROUP
  }

  $onInit () {
    this.editableRoute = Object.assign({}, this.route)
  }

  backTo () {
    this.$state.go('^.^', null, { reload: true })
  }

  updateRoute (route, router, campaign) {
    this.MRS.updateRoute({
      compSlug: campaign.company.slug,
      campSlug: campaign.slug,
      routerId: router.uuid,
      route,
    })
      .then(updatedRoute => {
        this.route = Object.assign({}, updatedRoute)
        this.routeInfoForm.$setPristine()
      })
      .catch((err) => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0]
          })
        }
        this.TS.show({
          text: 'Error Updating User'
        })
      })
  }

  cancelEdit (form) {
    this.editableRoute = Object.assign({}, this.route)
    form.$setPristine()
  }

  handleCopy () {
    this.TS.show({
      text: 'Copied To Clipboard'
    })
  }

  deleteRoute (route, router, campaign) {
    const DRD = deleteEntityDialog
    const compSlug = campaign.company.slug
    const campSlug = campaign.slug
    const endPoint = this.MRS.router.one(`${compSlug}/${campSlug}/${router.uuid}/route/${route.uuid}`)

    DRD.locals = {
      title: 'Delete Route',
      entityName: route.name,
      identityEndpoint: endPoint,
      entity: route,
      routeWhenSuccessful: '^.^',
    }
    this.$mdDialog.show(DRD)
  }
}

