import { contains, equals, findIndex, propEq, reduce } from 'ramda'

function combine(acc, pitchObject, type) {
  const keyActive = `active${type}s`
  const keyCompany = `company${type}s`
  const keyGlobal = `global${type}s`
  const companyIndex = findIndex(propEq('uuid', pitchObject.uuid))(
    acc[keyCompany],
  )
  if (companyIndex >= 0) {
    const item = acc[keyCompany][companyIndex]
    acc[keyActive].push(Object.assign(pitchObject, item))
    acc[keyCompany].splice(companyIndex, 1)
  } else {
    const globalIndex = findIndex(propEq('uuid', pitchObject.uuid))(
      acc[keyGlobal],
    )
    if (globalIndex >= 0) {
      const item = acc[keyGlobal][globalIndex]
      acc[keyActive].push(Object.assign(pitchObject, item))
      acc[keyGlobal].splice(globalIndex, 1)
    }
  }
  return acc
}

function populatePitchObjects(collection, type) {
  return function(pitchObject) {
    const base = {}
    base[`active${type}s`] = []
    base[`company${type}s`] = pitchObject.company
    base[`global${type}s`] = pitchObject.global
    return reduce(
      (a, b) => {
        return combine(a, b, type)
      },
      base,
      collection,
    )
  }
}

function pitchCollectionEqual(arr1 = [], arr2 = []) {
  return equals(arr1, arr2)
}

function combineTests(pitchTests) {
  return function({ company, global }) {
    return reduce(
      buildRapidResponseTests(pitchTests),
      { active: [], company: [], global: [] },
      [...company, ...global],
    )
  }
}

function buildRapidResponseTests(pitchTests) {
  return function(acc, testObj) {
    if (contains(testObj.uuid, pitchTests)) {
      acc.active.push(testObj)
    } else if (testObj.company) {
      acc.company.push(testObj)
    } else {
      acc.global.push(testObj)
    }
    return acc
  }
}

export { populatePitchObjects, pitchCollectionEqual, combineTests }
