import { DIALER_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'serverLoad',
  type: 'dataDog',
}

const formatting = {
  format: {
    title: 'Server Load',
  },
}

const keys = {
  componentKeys: [DIALER_METRICS],
  healthKey: 'performance_health',
}

const serverLoad = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { serverLoad }
