// create companies view

const manageAccessList = 'manage.accessLists.one'

const manageAccessListConfig = {
  abstract: true,
  component: 'rightDrawer'
}

export { manageAccessList, manageAccessListConfig }
