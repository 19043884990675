import CollapseToggleController from './controller/CollapseToggleController.js'
const template = require('./collapseToggle.tpl.pug')

const collapseToggle = {
  template: template(),
  controller: CollapseToggleController,
  bindings: {
    toggleId: '@?',
    collapsed: '<',
    onToggle: '&'
  }
}

export default collapseToggle
