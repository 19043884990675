import { all, pipe } from 'ramda'

class MetricFactoryError extends Error {
  constructor(msg) {
    super(msg)
    this.name = 'MetricFactoryError'
    this.message = msg
  }
}

const acceptableTypes = ['prospect', 'call', 'dataDog', 'repsLive']

const acceptableComponentKeys = [
  'campaign_metrics',
  'rep_stack_metrics',
  'rep_trend_metrics',
  'gateway_metrics',
  'data_metrics',
  'rep_metrics',
  'list_metrics',
  'dialer_metrics',
]

const acceptableHealthKeys = [
  'prospect_health',
  'metric_health',
  'sub_metric_health',
  'performance_health',
  'sub_performance_health',
  'reps_live',
]

const noKeyErrorText = `A metric key must be provided. Any value that can
be used as a Javascript object key is valid`

const noFormatTitleText = `A metric must have format object with a title key.
example: { title: 'Billable Hours' }`

const noFormatObjectText = `A metric object must contain a format object.
example: { key: 'example', format: { title: 'Example Metric' } }`

const badTypeValueText = `A metric must contain a type key with one of the following <String> values: ${acceptableTypes.join(
  ', ',
)}`

const noComponentKeysText = `A metric must have a componentKeys Array. The Array can be empty.
ie: []
acceptable values are: ${acceptableComponentKeys.join(', ')}`

const invalidComponentKeyText = `An invalid component key value was supplied.
Acceptable array values are: ${acceptableComponentKeys.join(', ')}`

const noMetricHealthKeyText = `A metric config object must have a 'metricHealth' key.
Acceptable values are: ${acceptableHealthKeys.join(', ')}`

const invalidMetrichealthKeyText = `The 'metricHealth' object key can only contain the following values: ${acceptableHealthKeys.join(
  ', ',
)}`

function validateMetricKey(config) {
  if (config.key) return config
  throw new MetricFactoryError(noKeyErrorText)
}

function validateFormat(config) {
  if (!config.format) throw new MetricFactoryError(noFormatObjectText)
  else if (!config.format.title) throw new MetricFactoryError(noFormatTitleText)
  return config
}

function validateMetricType(config) {
  if (!acceptableTypes.includes(config.type))
    throw new MetricFactoryError(badTypeValueText)
  return config
}

function checkComponentKeys(keyArray) {
  if (Array.isArray(keyArray) && keyArray.length === 0) return true
  const validated = keyArray.map(key => acceptableComponentKeys.includes(key))
  return all(val => val === true)(validated)
}

function validateComponentKeys(config) {
  if (!config.componentKeys) throw new MetricFactoryError(noComponentKeysText)
  else if (!checkComponentKeys(config.componentKeys))
    throw new MetricFactoryError(invalidComponentKeyText)
  return config
}

function validateMetricHealthKey(config) {
  if (!config.healthKey) {
    throw new MetricFactoryError(noMetricHealthKeyText)
  } else if (!acceptableHealthKeys.includes(config.healthKey)) {
    throw new MetricFactoryError(invalidMetrichealthKeyText)
  } else {
    return config
  }
}

const validator = pipe(
  validateMetricKey,
  validateFormat,
  validateMetricType,
  validateComponentKeys,
  validateMetricHealthKey,
)

function validateMetricConfig(config) {
  if (!config)
    throw new MetricFactoryError(
      'A configuration object must be passed to metricFactory()',
    )
  return validator(config)
}

export { validateMetricConfig }
