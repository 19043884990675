import { mergeWithKey, reduce, mergeWith, map } from 'ramda'

function mergeMetrics(key, left, right) {
  function divisorOrDividend(left, right) {
    return {
      name: left.name,
      value: (left.value || 0) + (right.value || 0),
    }
  }

  switch (key) {
    case 'y':
      return left + right
    case 'dividend':
      return divisorOrDividend(left, right)
    case 'divisor':
      return divisorOrDividend(left, right)
    default:
      return left
  }
}

function mergeAttemptMetrics(acc, metricsObj) {
  return mergeWithKey(mergeMetrics, acc, metricsObj)
}

function attemptMetricMerger(attemptMetrics) {
  return reduce(
    (acc, metricsObj) => {
      if (Object.keys(acc).length === 0) return metricsObj
      return mergeWith(mergeAttemptMetrics, acc, metricsObj)
    },
    {},
    attemptMetrics,
  )
}

function calculateMetric(metric) {
  if (metric.aggregate) return metric
  const quotient = metric.isRate
    ? (metric.dividend.value / metric.divisor.value) * 100
    : metric.dividend.value / metric.divisor.value
  metric.y = isFinite(quotient) ? quotient : 0
  return Object.assign({}, metric)
}

function calculateAttemptMetrics(attemptMetrics) {
  return map(calculateMetric, attemptMetrics)
}

export { calculateAttemptMetrics, attemptMetricMerger }
