export default class EditCampaignController {
  constructor($state) {
    'ngInject'
    this.$state = $state
    this.navItems = [
      {
        name: 'Settings',
        route: 'manage.editCampaign.settings',
      },
      {
        name: 'Pitch',
        route: 'manage.editCampaign.pitch',
      },
      {
        name: 'Data Posting',
        route: 'manage.editCampaign.dataPosting',
      },
    ]

    this.state = {
      backTo: {
        name: 'campaigns',
        route: 'manage.campaigns',
      },
    }
  }

  $onInit() {
    this.state.currentNavItem = this.$state.current.name
  }

  goTo(stateKey) {
    this.state.currentNavItem = this.state[stateKey]
    this.$state.go(this.state[stateKey])
  }
}
