import moment from 'moment'
import { map } from 'ramda'
import { listMetricsConfig } from '../config/listMetricsConfig.js'
import { calculateMetrics } from 'util/calculate/metrics/calculateMetrics.js'

export default class ListsService {
  constructor(
    $q,
    StatsService,
    MetricsService,
    CampaignStatsService,
    ProspectService,
    IdentityService,
  ) {
    'ngInject'

    this.$q = $q
    this.Lists = ProspectService.lists
    this.IS = IdentityService
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.getListMetrics = MetricsService.getMetrics.bind(MetricsService)
    this.calculateMetrics = calculateMetrics
    this.buildMetrics = map(this.builder.bind(this))
    this.listMetrics = listMetricsConfig
  }

  getRealms() {
    return this.IS.Realm.list.get().then(realms => realms.plain())
  }

  getLists(options) {
    const {
      company,
      campaign,
      offsetMinutes,
      realm,
      pageNum = null,
      searchText = null,
    } = options
    const startDate = moment
      .utc()
      .utcOffset(offsetMinutes)
      .startOf('day')
    const endDate = moment(startDate).endOf('day')
    let params = pageNum ? { page: pageNum } : {}
    params = Object.assign(params, { realms: realm, search: searchText })
    return this.Lists.all
      .one(`${company.slug}/${campaign.slug}`)
      .get(params)
      .then(data => {
        const details = {
          context: 'data',
          historical: true,
          compSlug: company.slug,
          campSlug: campaign.slug,
          realm: params.realms,
          epochStart: startDate.subtract(89, 'days').unix(),
          epochEnd: endDate.unix(),
          requestTime: moment().startOf('minute'),
        }
        return {
          lists: this.addListDetails(details, data.results, this.listMetrics),
          pagination: {
            count: data.count,
            next: data.next,
            previous: data.previous,
          },
        }
      })
  }

  addListDetails(details, lists, listMetrics) {
    const hydrator = list => {
      list.details = Object.assign({}, details)
      list.details.listSlug = list.slug
      list.details.realm = list.realm
      list.metrics = listMetrics
      return list
    }
    return map(hydrator, lists)
  }

  getListStats(data) {
    const promisedLists = this.makeListRequests(data.lists)
    return this.$q
      .all({
        lists: this.$q.all(promisedLists),
      })
      .then(this.buildMetricsByList.bind(this))
      .then(lists => {
        return {
          lists,
          pagination: data.pagination,
        }
      })
  }

  buildMetricsByList(data) {
    const makeMetrics = list => {
      return this.builder(list)
    }
    return map(makeMetrics, data.lists)
  }

  builder(list) {
    const listPackage = {
      metrics: this.getListMetrics(list.details.metrics).call,
      stats: [...list.data.results],
    }
    list.metrics = this.calculateMetrics(listPackage, 'list')
    return list
  }

  makeListRequests(lists) {
    const requester = list => {
      return this.$q.all({
        data: this.promisifyStats(list.details),
        details: list,
      })
    }
    return map(requester, lists)
  }
}
