import UserDetailsDialogController from './controller/UserDetailsDialogController.js'
const template = require('./userDetailsDialog.tpl.pug')

const userDetailsDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: UserDetailsDialogController
}

export default userDetailsDialog
