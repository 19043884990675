// @flow

import { contains, range, splitEvery } from 'ramda'
import { getTotalPages, getSelectablePages, hasValue } from '../../utils/paginationHelpers.js'

export default class PaginationFooterController {
  paginateTotal: number
  paginateLimit: number
  paginateCb: ({ page: number }) => void
  paginateCurrentPage: number
  state: {
    currentPage: number,
    pages: number[],
    pageSplits: Array<number[]>,
    selectablePages: number[],
  }

  constructor () {
    this.state = {
      currentPage: 0,
      pages: [],
      pageSplits: [],
      selectablePages: [],
    }
  }

  $onChanges (bindings: any) {
    if (hasValue(bindings.paginateTotal)) {
      this.buildPages()
      this.getPagerState()
    }

    if (bindings.paginateState && !bindings.paginateState.isFirstChange()) {
      this.getPagerState()
    }

    if (bindings.paginateCurrentPage && !bindings.paginateCurrentPage.isFirstChange()) {
      this.getPagerState()
    }
  }

  buildPages () {
    this.state.pages = range(1, getTotalPages(this.paginateTotal, this.paginateLimit) + 1)
    this.state.pageSplits = splitEvery(4, this.state.pages)
  }

  getPagerState () {
    this.state.currentPage = this.paginateCurrentPage
    this.state.selectablePages = getSelectablePages(this.state.currentPage, this.state.pageSplits)
  }

  decrementPage () {
    this.paginateCb({ page: this.state.currentPage - 1 })
  }

  incrementPage () {
    this.paginateCb({ page: this.state.currentPage + 1})
  }

  goToPage (page: number) {
    this.paginateCb({ page })
  }

  isFinalAperture (): boolean {
    if (this.state.currentPage) {
      const lastAperture = this.state.pageSplits[this.state.pageSplits.length - 1]
      return !contains(this.state.currentPage, lastAperture)
    } else {
      return false
    }
  }
}
