import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getBaseUrl } from '../global/config/providers/restangularProvider/rest.js'

const API_BASE_URL = getBaseUrl(window.location.host, true)
let Header

const Paginatior = ({ colection, setColection, urlPage }) => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const maxPagesWithoutSplit = 15
  const numberOfPagesPerRange = 8

  useEffect(() => {
    Header = {
      headers: {
        Authorization: `token ${JSON.parse(localStorage['ngStorage-ppToken'])}`,
      },
    }
  }, [])

  useEffect(() => {
    getTotal(colection.count)
    setPage(colection.previous ? page : 1)
    buildPageRange()
  }, [colection])

  const nextPage = url => {
    if (url === null) return
    else setPage(page + 1)

    axios.get(url, Header).then(res => {
      getTotal(res.data.count)
      setColection(res.data)
    })
  }

  const previousPage = url => {
    if (url === null) return
    else setPage(page - 1)

    axios.get(url, Header).then(res => {
      getTotal(res.data.count)
      setColection(res.data)
    })
  }

  const getTotal = count => {
    if (count) {
      let result = parseInt(count / 10)
      if (count % 10 !== 0) result++
      setTotal(result)
    } else {
      setTotal(0)
    }
  }

  const goToPage = page => {
    setPage(page)
    let url = urlPage.replace('PAGE', page)
    axios.get(`${API_BASE_URL}${url}`, Header).then(res => {
      getTotal(res.data.count)
      setColection(res.data)
    })
  }

  const buildPageRange = () => {
    if (page === undefined || page <= (numberOfPagesPerRange + 1)) {
      setPages(Array.from(Array(numberOfPagesPerRange), (i, index) => index + 2))
    } else if (page >= (total - numberOfPagesPerRange)) {
      setPages(Array.from(Array(numberOfPagesPerRange), (i, index) => total - numberOfPagesPerRange + index))
    } else {
      const startIndex = Math.floor((page - 2) / numberOfPagesPerRange) * numberOfPagesPerRange + 2
      setPages(Array.from(Array(numberOfPagesPerRange), (i, index) => startIndex + index))
    }
  }

  if (total <= 1) return null
  else
    return (
      <ul className="pagination center-align">
        <li className={colection.previous === null ? 'disabled' : ''}>
          <a
            className={'cursor-pointer'}
            onClick={() => previousPage(colection.previous)}
          >
            <i className="material-icons">chevron_left</i>
          </a>
        </li>
        {total <= maxPagesWithoutSplit && [...Array(total)].map((i, index) => (
          <li className={page === index + 1 ? 'active' : ''} key={index}>
            <a className={'cursor-pointer'} onClick={() => goToPage(index + 1)}>
              {index + 1}
            </a>
          </li>
        ))}
        {total > maxPagesWithoutSplit && 
          <li className={page === 1 ? 'active' : ''} key={1}>
            <a className={'cursor-pointer'} onClick={() => goToPage(1)}>
              1
            </a>
          </li>
        }
        {total > maxPagesWithoutSplit && 
          <li>&nbsp;&nbsp;...&nbsp;&nbsp;</li>
        }
        {total > maxPagesWithoutSplit && pages.map((i, index) => (
          <li className={page === i ? 'active' : ''} key={i}>
            <a className={'cursor-pointer'} onClick={() => goToPage(i)}>
              {i}
            </a>
          </li>
        ))}
        {total > maxPagesWithoutSplit && 
          <li>&nbsp;&nbsp;...&nbsp;&nbsp;</li>
        }
        {total > maxPagesWithoutSplit && 
          <li className={page === total ? 'active' : ''} key={total}>
            <a className={'cursor-pointer'} onClick={() => goToPage(total)}>
              {total}
            </a>
          </li>
        }
        <li className={colection.next === null ? 'disabled' : ''}>
          <a
            className={'cursor-pointer'}
            onClick={() => nextPage(colection.next)}
          >
            <i className="material-icons">chevron_right</i>
          </a>
        </li>
      </ul>
    )
}

export default Paginatior
