// Dashboard
import {
  dashboardRepRankings,
  dashboardRepRankingsConfig,
} from './dashboard.repRankings.js'

export default function dashboardRepRankingsRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardRepRankings, dashboardRepRankingsConfig)
}
