// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'

type Props = {
  className: string,
  classes: Object,
  children: React.Node[],
  handleClick: Function,
  innerRef: string,
}

const style = {
  cell: {
    textAlign: 'right',
  },
  button: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    fontWeight: 'bold',
    color: '#444851',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

function ButtonCell(props: Props) {
  const [icon, label] = props.children
  const { button, cell } = props.classes

  return (
    <TableCell className={`${props.className} ${cell}`}>
      <Button className={button} onClick={props.handleClick}>
        {icon}
        {label}
      </Button>
    </TableCell>
  )
}

export default withStyles(style)(ButtonCell)
