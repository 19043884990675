import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'
import { hideInvalid } from '../../../../../utils/managementUtils.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class ManageAccessListsCreateController {
  constructor ($document, $state, $timeout, ManagementService, $mdDialog, ToastService, IdentityService) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.$document = $document
    this.$state = $state
    this.$timeout = $timeout
    this.MS = ManagementService
    this.IS = IdentityService
    this.TS = ToastService
    this.hideInvalid = hideInvalid
    this.ADMIN_GROUP = ADMIN_GROUP
    this.state = {
      actionTitle: 'Edit Access List',
      errors: null,
      updating: false,
      askNextAction: false,
    }
    this.eventHandler = (e) => {
      if (e.key === 'Escape') {
        this.backTo()
      }
    }
  }

  $onInit () {
    this.accessList = {}
    this.allowOptions = [
      {'key': true, 'value': 'Yes'},
      {'key': false, 'value': 'No'},
    ]
    this.getCampaignRelationOptions([ 'company' ])
    this.accessList = Object.assign({}, this.entity)
    this.$document.on('keydown', this.eventHandler)
  }

  $onDestroy () {
    this.$document.off('keydown', this.eventHandler)
  }

  backTo () {
    this.$state.go('manage.access-lists')
  }

  cancelEdit (form) {
    this.accessList = Object.assign({}, this.entity)
    form.$setPristine()
  }

  resetErrors () {
    this.state.creationErrors = null
  }

  update (accessList) {
    this.resetErrors()
    this.state.actionTitle = 'Updating'
    this.state.updating = true
    this.MS.updateEntity({entity: 'acl', data: accessList, method: 'PUT'})
      .then(updatedAcl => {
        this.entity = Object.assign({}, updatedAcl)
        this.accessListForm.$setPristine()
        this.$timeout(() => {
          this.state.actionTitle = 'Access List Updated'
          this.state.askNextAction = true
        }, 2000)
      })
      .catch(err => {
        this.state.updating = false
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0]
          })
        }
        this.TS.show({
          text: 'Error Updating Acl'
        })
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.updating = false
        }, 2000)
      })
  }

  createAnother () {
    this.accessList = {}
    this.state.actionTitle = 'Create Access List'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit () {
    this.$state.go('manage.access-lists', null, { reload: true })
  }

  getCampaignRelationOptions (relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
      })
      .catch(err => {
        this.TS.show({
          text: 'Problem fetching options, you wont be able to create a access list'
        })
        console.error(err)
      })
  }

  deleteAcl (acl) {
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Access List',
      entityName: acl.name,
      identityEndpoint: this.IS.Acl.fetch.one(acl.uuid),
      entity: acl,
      routeWhenSuccessful: 'manage.access-lists'
    }
    this.$mdDialog.show(DCD)
  }
}
