import { all } from 'ramda'

class ProxyRouterArgumentsError extends Error {
  constructor(ids) {
    super(ids)
    this.name = 'RouterProxieArgumentsError'
    this.message = `
      compSlug: ${ids.compSlug} should be a slug
      campSlug: ${ids.campSlug} should be a slug
      routerId: ${ids.routerId} should be a uuid
    `
  }
}

export default class EditRouterService {
  constructor($timeout, ProspectService) {
    'ngInject'

    this.$timeout = $timeout
    this.router = ProspectService.router
  }

  updateRouter(data) {
    const { compSlug, campSlug, router } = data
    return this.router
      .one(`${compSlug}/${campSlug}/${router.uuid}`)
      .customPATCH({
        name: router.name,
        route_continue: router.route_continue.join(','),
        active: router.active,
      })
      .then(updatedRouter => updatedRouter.plain())
  }

  updateRoute(data) {
    const { compSlug, campSlug, routerId, route } = data
    return this.router
      .one(`${compSlug}/${campSlug}/${routerId}/route/${route.uuid}`)
      .customPATCH(route)
      .then(updatedRoute => updatedRoute.plain())
  }

  getRouterRoutes({ compSlug = '', campSlug = '', routerId = '' }) {
    if (!all(str => str && str.length > 0, [compSlug, campSlug, routerId])) {
      throw new ProxyRouterArgumentsError({ compSlug, campSlug, routerId })
    }
    return this.router
      .one(`${compSlug}/${campSlug}/${routerId}/route`)
      .get()
      .then(routes => routes.plain())
  }
}
