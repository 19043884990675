import ManageAccessListsCreateController from './controller/ManageAccessListsCreateController.js'
const template = require('./manageAccessListsCreate.component.tpl.pug')

const manageAccessListsCreate = {
  controller: ManageAccessListsCreateController,
  template: template(),
  bindings: {
    compSlug: '<'
  }
}

export default manageAccessListsCreate
