import { addQueryDetails, addSingleQueryDetail } from 'util/query/queryBuilder.js'
import { buildRange, buildBaseAndCompareRange, mergeRanges } from './campaignStatsHelpers.js'

export default class CampaignStatsService {
  constructor ($q, StatsService, MetricsService, GoalsService) {
    'ngInject'

    this.$q = $q
    this.SS = StatsService
    this.GS = GoalsService
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.getMetrics = MetricsService.getMetrics.bind(MetricsService)
  }

  getStats (component, dateRange, realm) {
    const componentMetrics = this.getMetrics(component.metrics)
    const hydratedMetrics = addQueryDetails(component, dateRange, componentMetrics, realm)
    const ranges = this.fetchRanges(hydratedMetrics)

    return this.$q
      .all(ranges)
      .then(buildBaseAndCompareRange)
      .then(mergeRanges)
  }

  getSingleRangeStats (component, dateRange, realm = null) {
    const componentMetrics = this.getMetrics(component.metrics)
    const hydratedMetrics = addSingleQueryDetail(component, dateRange, componentMetrics, realm)
    const range = this.fetchRange(hydratedMetrics)

    return this.$q
      .all(range)
      .then(buildRange)
  }

  fetchRange (metrics) {
    return {
      range: this.promisifyStats(metrics.range),
      callMetrics: metrics.call,
      nonCallMetrics: [metrics.datadog, metrics.repsLive, metrics.prospect],
      metricKeysArr: metrics.metricKeysArr
    }
  }

  fetchRanges (metrics) {
    return {
      baseRange: this.promisifyStats(metrics.baseRange),
      compareRange: this.promisifyStats(metrics.compareRange),
      goals: this.GS.getMetricGoals({ compSlug: metrics.baseRange.compSlug, campSlug: metrics.baseRange.campSlug }),
      callMetrics: metrics.call,
      nonCallMetrics: [ metrics.dataDog, metrics.repsLive, metrics.prospect ],
      metricKeysArr: metrics.metricKeysArr
    }
  }
}
