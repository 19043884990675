// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    classes: Object,
    active: Boolean,
}

const styles = {
    root: {
        margin: '20px 0',
        width: '100%',
    },
    header: {
        color: '#444851',
        fontSize: '14px',
        backgroundColor: '#eeeeee',
        borderRadius: '3px'
    },
    appBar: {
        backgroundColor: 'transparent',
    }
}

const StyledTabs = withStyles({
    indicator: {
        display: 'none!important',
    },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: '#1194f6 !important',
            color: '#ffffff',
        },
    },
})(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ProspectPanel(props: Props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={props.classes.root}>
            <AppBar position="static" className={props.classes.appBar}>
                <StyledTabs value={value} onChange={handleChange}
                    className={props.classes.header}
                    aria-label="wrapped label tabs" >
                    <StyledTab label="SUMMARY" {...a11yProps(0)} />
                    <StyledTab label="DETAILS" {...a11yProps(1)} />
                    <StyledTab label="TIMES" {...a11yProps(2)} />
                </StyledTabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Disposition:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Campaign:</td>
                                    <td>POSA Pledged Assistence</td>
                                </tr>
                                <tr>
                                    <td>Prospect list:</td>
                                    <td><span
                                        style={{ textDecoration: 'underline' }}>1962 (8 2-Ctzs S3)
                                    </span></td>
                                </tr>
                                <tr>
                                    <td>Pitch:</td>
                                    <td><span
                                        style={{ textDecoration: 'underline' }}>United
                                    Police Officers Association Pitch</span></td>
                                </tr>
                                <tr>
                                    <td>Direction:</td>
                                    <td>Outbound</td>
                                </tr>
                                <tr>
                                    <td>Start note:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Valid:</td>
                                    <td>True</td>
                                </tr>
                                <tr>
                                    <td>Prospect notes:</td>
                                    <td>PPCR_VAR::pledgeassistancecampaign=uploa</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Revenue:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Prospect:</td>
                                    <td>Samuel Maria</td>
                                </tr>
                                <tr>
                                    <td>Status:</td>
                                    <td>No answer</td>
                                </tr>
                                <tr>
                                    <td>Pitch version:</td>
                                    <td>21</td>
                                </tr>
                                <tr>
                                    <td>Manual dial:</td>
                                    <td>False</td>
                                </tr>
                                <tr>
                                    <td>Voice:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Carrier:</td>
                                    <td>veracity1.vp.perfectpitchtech.com</td>
                                </tr>
                                <tr>
                                    <td>Call notes:</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Abandoned:</td>
                                    <td>False</td>
                                </tr>
                                <tr>
                                    <td>Prospect Number:</td>
                                    <td>3457485188</td>
                                </tr>
                                <tr>
                                    <td>Hangup cause:</td>
                                    <td>Destination_out_of_order</td>
                                </tr>
                                <tr>
                                    <td>Rep:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Rep station:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Duration:</td>
                                    <td>0:00:10</td>
                                </tr>
                                <tr>
                                    <td>Queue duration:</td>
                                    <td>0:00:00</td>
                                </tr>
                                <tr>
                                    <td>System disposition:</td>
                                    <td>Max_cause_codes</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Contacted:</td>
                                    <td>False</td>
                                </tr>
                                <tr>
                                    <td>DID:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Hangup cause code:</td>
                                    <td>27</td>
                                </tr>
                                <tr>
                                    <td>Rep location:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Rep team:</td>
                                    <td>None</td>
                                </tr>
                                <tr>
                                    <td>Ring duration:</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td>Talktime duration:</td>
                                    <td>0:00:00</td>
                                </tr>
                                <tr>
                                    <td>Resolved:</td>
                                    <td>True</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Created:</td>
                                    <td>17:10:13.142685</td>
                                </tr>
                                <tr>
                                    <td>Started:</td>
                                    <td>17:10:15.707569</td>
                                </tr>
                                <tr>
                                    <td>Answered:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Bowout:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Archived:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Queue joined:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Queue canceled:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Profile start:</td>
                                    <td>17:10:15.707569</td>
                                </tr>
                                <tr>
                                    <td>Resolved:</td>
                                    <td>07:47:55.623794</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <table className="settingsTable">
                            <tbody>
                                <tr>
                                    <td>Modified:</td>
                                    <td>07:47:55:692240</td>
                                </tr>
                                <tr>
                                    <td>Ended:</td>
                                    <td>17:10:26.247289</td>
                                </tr>
                                <tr>
                                    <td>Bridged:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Applied:</td>
                                    <td>17:10:26.247289</td>
                                </tr>
                                <tr>
                                    <td>Queue answered:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Queue exited:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Queue terminated:</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>Progress media:</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    )
}


export default withStyles(styles)(ProspectPanel)
