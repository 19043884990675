import AccessListDetailController from './controller/AccessListDetailsController.js'
const template = require('./accessListDetails.tpl.pug')

const accessListDetails = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: true,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: AccessListDetailController,
}

export default accessListDetails
