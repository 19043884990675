import './manageLocations.component.scss'
import ManageLocationsController from './controller/ManageLocationsController.js'
const template = require('./manageLocations.component.tpl.pug')

const manageLocations = {
  template: template(),
  controller: ManageLocationsController,
  bindings: {}
}

export default manageLocations
