import { clone } from 'ramda'

export default class StackedBarChart {
  constructor($timeout) {
    'ngInject'

    this.stackedBar = {
      title: {
        text: '',
        style: {},
      },
      options: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              align: 'right',
              color: '#000000',
              x: -15,
              style: {
                textShadow: 'none',
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
        },
      },
      xAxis: {
        categories: [],
      },
      series: [],
      size: {},
      func: function(chart) {
        $timeout(() => {
          chart.reflow()
        }, 200)
      },
    }
  }

  getChart() {
    return clone(this.stackedBar)
  }
}
