import { permissions } from 'permissions.js'

export default class HeadMenuController {
  constructor(AuthService, $state) {
    'ngInject'
    this.$state = $state
    this.AuthService = AuthService
    this.hide = false
    this.recorderOnly = [permissions.RECORDER]
  }

  $onInit() {
    this.hide = this.hideFromMenu()
  }

  hideFromMenu() {
    const isManage = this.$state.$current.name.includes('manage')
    if (isManage) {
      return true
    }
  }

  goToLegacy() {
    if (this.selectedRealm) {
      window.location = `https://${this.selectedRealm}.perfectpitchtech.com`
    }
  }

  logout() {
    this.AuthService.logout()
  }
}
