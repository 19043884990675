// @flow

import type { Element } from 'restangular'
import type { PhraseBook, Company, Campaign } from 'types/entities.js'

type AllPhraseBooks = {
  activePhraseBooks: PhraseBook[],
  companyPhraseBooks: Company[],
  globalPhraseBooks: Campaign[],
}

type SearchPhraseBooks = {
  activePhraseBooks: string,
  companyPhraseBooks: string,
  globalPhraseBooks: string,
}

type BasePhraseBooks = {
  initial: Object[],
  editable: Object[],
}

type State = {|
  activePhraseBooks: BasePhraseBooks,
  globalPhraseBooks: BasePhraseBooks,
  companyPhraseBooks: BasePhraseBooks,
  search: SearchPhraseBooks,
|}

type PhraseBookFilter = ({ searchText: string }) => void
type PitchPhraseBookEqual = (arr1: Object[], arr2: Object[]) => Object

import {
  populatePitchObjects,
  pitchCollectionEqual,
} from '../../../utils/pitchObjects.js'

export default class PitchPhraseBooksController {
  $q: Object
  state: State
  globalPhraseBookFilter: PhraseBookFilter
  activePhraseBookFilter: PhraseBookFilter
  companyPhraseBookFilter: PhraseBookFilter
  pitchPhraseBookEqual: PitchPhraseBookEqual
  companyService: Element
  globalService: Element
  phraseBooks: PhraseBook[]
  onUpdate: Function

  constructor($q: Object) {
    'ngInject'

    this.$q = $q
    this.state = {
      activePhraseBooks: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      globalPhraseBooks: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      companyPhraseBooks: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      search: {
        activePhraseBooks: '',
        companyPhraseBooks: '',
        globalPhraseBooks: '',
      },
    }

    this.globalPhraseBookFilter = this.createPhraseBookFilter(
      'globalPhraseBooks',
    )
    this.activePhraseBookFilter = this.createPhraseBookFilter(
      'activePhraseBooks',
    )
    this.companyPhraseBookFilter = this.createPhraseBookFilter(
      'companyPhraseBooks',
    )
    this.pitchPhraseBookEqual = pitchCollectionEqual
  }

  $onChanges(bindings: Object): void {
    // wait for parent component to provide services and initial active PhraseBooks
    if (
      bindings.companyService &&
      bindings.globalService &&
      bindings.phraseBooks.isFirstChange()
    ) {
      this.getPhraseBooks(this.phraseBooks)
    } else if (bindings.phraseBooks) {
      this.state.activePhraseBooks.initial = [
        ...this.state.activePhraseBooks.editable,
      ]
    }
  }

  getPhraseBooks(phraseBooks: PhraseBook[]): void {
    this.$q
      .all({
        global: this.globalService.get(),
        company: this.companyService.get(),
      })
      .then(({ company, global }) => {
        return {
          company: company.plain(),
          global: global.plain(),
        }
      })
      .then(populatePitchObjects(phraseBooks, 'PhraseBook'))
      .then(this._setAllPhraseBooks.bind(this))
  }

  _setAllPhraseBooks({
    activePhraseBooks = [],
    companyPhraseBooks = [],
    globalPhraseBooks = [],
  }: AllPhraseBooks): void {
    Object.assign(this.state, {
      activePhraseBooks: {
        initial: activePhraseBooks,
        editable: [...activePhraseBooks],
      },
      companyPhraseBooks: {
        initial: companyPhraseBooks,
        editable: [...companyPhraseBooks],
      },
      globalPhraseBooks: {
        initial: globalPhraseBooks,
        editable: [...globalPhraseBooks],
      },
    })
  }

  createPhraseBookFilter(key: string): PhraseBookFilter {
    return ({ searchText }) => {
      this.state.search[key] = searchText
    }
  }

  saveActivePhraseBooks(activePhraseBooks: Object): void {
    this.onUpdate({ phraseBooks: activePhraseBooks })
  }

  removePhraseBook(index: number, arr: Array<Object>): void {
    const item = arr.splice(index, 1)[0]
    if (item.company) {
      this.state.companyPhraseBooks.editable.push(item)
    } else {
      this.state.globalPhraseBooks.editable.push(item)
    }
  }

  cancelMove(): void {
    this._resetPhraseBooksList('activePhraseBooks')
    this._resetPhraseBooksList('companyPhraseBooks')
    this._resetPhraseBooksList('globalPhraseBooks')
  }

  _resetPhraseBooksList(listKey: string = ''): void {
    this.state[listKey].editable = [...this.state[listKey].initial]
  }

  toggleSearch(key: string): void {
    this.state[key].showSearch = !this.state[key].showSearch
    this.state.search[key] = ''
  }
}
