import { MANAGE_GROUP } from 'permissions.js'
// manage users view

const manageDid = 'manage.dids'

const manageDidConfig = {
  url: '/did',
  component: 'manageDid',
  data: {
    permissions: {
      only: [...MANAGE_GROUP],
      redirectTo: 'gateway',
    },
  },
  resolve: {
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Manage Did'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageDid, manageDidConfig }
