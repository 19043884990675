import { map, propEq } from 'ramda'
import { permissions, MANAGE_GROUP } from 'permissions.js'
// dashboard view

const dashboard = 'dashboard'

const dashboardConfig = {
  abstract: true,
  url: '/dashboard/{realmSlug}/{campaignSlug}',
  params: {
    campaignSlug: null,
    realmSlug: 'all',
  },
  component: 'dashboard',
  data: {
    authRequired: true,
    permissions: {
      only: [...MANAGE_GROUP, permissions.CLIENT],
      redirectTo: 'gateway',
    },
  },
  resolve: {
    user: function($sessionStorage) {
      'ngInject'
      return $sessionStorage.user
    },
    userCampaigns: function(IdentityService) {
      'ngInject'
      return IdentityService.Campaign.list.get().then(data => {
        return data.plain()
      })
    },
    selectedCampaign: function(
      $state,
      $transition$,
      userCampaigns,
      IdentityService,
    ) {
      'ngInject'
      const selectedCampaign = userCampaigns.find(camp => {
        return $transition$.params().campaignSlug === camp.slug
      })
      return selectedCampaign
    },
    campaignRealms: function($q, selectedCampaign, IdentityService) {
      'ngInject'
      const promisedRealms = map(realmId => {
        return IdentityService.Realm.fetch.one(realmId).get()
      }, selectedCampaign.realms)

      return $q.all(promisedRealms).then(realms => realms.map(x => x.plain()))
    },
    selectedRealm: function(
      $transition$,
      selectedCampaign,
      campaignRealms,
      RealmService,
    ) {
      'ngInject'
      const realmSlug = $transition$.params().realmSlug
      const realm = find(propEq('slug', realmSlug), campaignRealms)
      if (realmSlug && realm) {
        RealmService.updateRealm(realm)
        return realm
      }
      return null
    },
    currentCompany: function(IdentityService, selectedCampaign) {
      'ngInject'
      return IdentityService.Company.fetch
        .one(selectedCampaign.company)
        .get()
        .then(company => company.plain())
    },
    timezoneOffsetMinutes: function(
      currentCompany,
      selectedCampaign,
      ReportService,
    ) {
      'ngInject'
      return ReportService.info
        .one(`${currentCompany.slug}/${selectedCampaign.slug}`)
        .get()
        .then(campaignInfo => campaignInfo.plain())
        .then(infoArray => infoArray[0].default_timezone_offset_minutes)
    },
  },
}

export { dashboard, dashboardConfig }
