/*
| WARNING:
| the media recorder api seems to make angular components miss their updates
| probably has to do with blocking the event loop or something
| $timeout is used to delay the updates for another tick of the loop
| so the angular components are updated correctly
*/

import { cond, curry, find, omit, propEq } from 'ramda'
import { bindKeyEvents } from 'util/keyEvent.js'
import {
  RIGHT_ARROW,
  LEFT_ARROW,
  SPACE_BAR,
  R_KEY,
} from '../util/audioHotkeys.js'
import {
  getMediaStream,
  constructRecorder,
  bindEventCallbacks,
  createAudioUrl,
} from 'util/recorder.js'
// These are all helper functions that work on this.state
// ex: helperFn(this.state) -> Boolean
import {
  isRecording,
  isNotRecording,
  isPlaying,
  trackIsArmed,
  allDisabled,
  canSelectPrevious,
  canSelectNext,
  hasPreviewAudio,
} from '../util/stateHelpers.js'

import {
  toggleRecording,
  startRecording,
  stopRecording,
  disarmTrack,
  playPreviewAudio,
  stopPreviewAudio,
} from '../util/stateUpdaters.js'

const curriedSetEventCallbacks = curry(bindEventCallbacks)

export default class AudioRecordingDialogController {
  constructor(
    $mdDialog,
    $timeout,
    $scope,
    ToastService,
    ManageAudioService,
    Restangular,
  ) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.$timeout = $timeout
    this.$scope = $scope
    this.TS = ToastService
    this.MAS = ManageAudioService
    this.tempUploadService = Restangular.oneUrl('pitch/audio/temporary-upload')

    this.audioChunks = []
    this.recordedAudio = []
    this.previewAudio = null
    this.errors = []
    this.uploadProgress = {}
    this.fileName = 'Not file choosen'
    this.state = {
      audioList: this.audioList,
      selectedIndex: this.selectedIndex,
      hasRecorder: false,
      hasPreviewAudio: false,
      isRecording: false,
      allDisabled: false,
      isPlaying: false,
      timerReset: false,
      trackArmed: false,
      trackRecorded: false,
      hasMicAccess: false,
      micPermissionDismissed: false,
      micPermissionDenied: false,
      uploadingTake: false,
      uploadingSession: false,
      uploadsComplete: false,
    }
    this.takePreview = {
      hasTake: false,
      takeSegment: null,
      takeUrl: null,
      addedToSession: false,
    }
    this.uploadOptions = {
      fadeIn: false,
      fadeOut: false,
      convert: true,
      noModification: false,
    }

    // These conditions are evaluated when the space bar is pressed
    // execution stops at first true predicate, order matters!
    this.spaceBarHandlers = cond([
      [isRecording, this.handleStop.bind(this)],
      [isPlaying, this.handleStop.bind(this)],
      [trackIsArmed, this.handlePlay.bind(this)],
      [hasPreviewAudio, this.handlePlay.bind(this)],
    ])

    // All keypresses are evaluted here, events do not propagate by default
    this.handleKeypress = bindKeyEvents([
      { code: RIGHT_ARROW, handler: this.nextAudioSegment.bind(this) },
      { code: LEFT_ARROW, handler: this.previousAudioSegment.bind(this) },
      { code: R_KEY, handler: this.handleRecord.bind(this) },
      { code: SPACE_BAR, handler: this.handleSpaceBar.bind(this) },
    ])

    this.recorderEventHandlers = [
      { eventName: 'onstop', callback: this.onStop.bind(this) },
      {
        eventName: 'ondataavailable',
        callback: this.onDataAvailable.bind(this),
      },
    ]
    this.setupRecorder()
  }

  setupRecorder() {
    getMediaStream({ audio: true })
      .then(constructRecorder)
      .then(curriedSetEventCallbacks(this.recorderEventHandlers))
      .then(this.handlePermissionGranted.bind(this))
      .catch(this.handlePermissionDenied.bind(this))
  }

  previousAudioSegment() {
    if (canSelectPrevious(this.state)) {
      this.state.selectedIndex -= 1
      this.selectedAudio = this.state.audioList[this.state.selectedIndex]
      this.state.allDisabled = this.selectedAudio.addedToSession || false
    }
  }

  nextAudioSegment() {
    if (canSelectNext(this.state)) {
      this.state.selectedIndex += 1
      this.selectedAudio = this.state.audioList[this.state.selectedIndex]
      this.state.allDisabled = this.selectedAudio.addedToSession || false
    }
  }

  handleRecord() {
    cond([
      [allDisabled, angular.noop],
      [isNotRecording, toggleRecording.bind(this)],
    ])(this.state)
  }

  handlePlay() {
    this.updateBindings('state', { timerReset: false })
    cond([
      [allDisabled, angular.noop],
      [trackIsArmed, startRecording.bind(this)],
      [hasPreviewAudio, playPreviewAudio.bind(this)],
    ])(this.state)
  }

  handleStop() {
    cond([
      [allDisabled, angular.noop],
      [isRecording, stopRecording.bind(this)],
      [trackIsArmed, disarmTrack.bind(this)],
      [isPlaying, stopPreviewAudio.bind(this)],
    ])(this.state)
  }

  handleSpaceBar() {
    this.spaceBarHandlers(this.state)
  }

  handlePermissionGranted(recorder) {
    this.recorder = recorder
    this.$timeout(() => {
      this.updateBindings('state', {
        hasRecorder: true,
        hasMicAccess: true,
      })
    })
  }

  handlePermissionDenied(err) {
    if (err.name === 'PermissionDismissedError') {
      this.$timeout(() => {
        this.state.micPermissionDenied = false
        this.state.micPermissionDismissed = true
      }, 100)
    } else if (err.name === 'PermissionDeniedError') {
      this.$timeout(() => {
        this.state.micPermissionDismissed = false
        this.state.micPermissionDenied = true
      }, 100)
    }
  }

  onStop(ev) {
    const segment = this.state.audioList[this.state.selectedIndex]
    const audioUrl = createAudioUrl(this.audioChunks)
    this.audioChunks = []
    this.previewAudio = new Audio(audioUrl)
    this.$timeout(() => {
      this.updateBindings('takePreview', {
        hasTake: true,
        takeSegment: segment,
        takeUrl: audioUrl,
      })
    })
  }

  onDataAvailable(ev) {
    this.audioChunks.push(ev.data)
  }

  addToSession(takePreview, uploadOptions) {
    this.state.uploadingTake = true
    this.tempUpload(takePreview, uploadOptions)
      .then(res => {
        this.addTemporaryAudioUploadToSession(takePreview, res)
        this.updateSegment(takePreview)
        this.takePreview.addedToSession = true
      })
      .catch(this.handleUploadError.bind(this))
      .finally(() => {
        this.state.uploadingTake = false
      })
  }

  updateSegment(takePreview) {
    const segment = find(
      propEq('name', takePreview.takeSegment.name),
      this.state.audioList,
    )
    segment.addedToSession = true
    if (segment.name === this.selectedAudio.name) {
      this.updateBindings('state', {
        allDisabled: true,
      })
    }
  }

  addTemporaryAudioUploadToSession(takePreview, tempAudio) {
    this.recordedAudio.push({
      name: takePreview.takeSegment.name,
      url: tempAudio.processed_url,
      takeUrl: takePreview.takeUrl,
      key: takePreview.takeSegment.key || takePreview.takeSegment.uuid,
    })
  }

  uploadSession() {
    this.state.uploadingSession = true
    const toUpload = this.recordedAudio.map(this.makeAudioConfigs.bind(this))
    this.MAS.bulkUploadAudio(toUpload)
      .then(this.reconcileUploads.bind(this, this.recordedAudio))
      .catch(this.reconcileUploads.bind(this, this.recordedAudio))
  }

  tempUpload(takePreview, uploadOptions) {
    return this.MAS.uploadBlobUrl({
      service: this.tempUploadService,
      fileName: takePreview.takeSegment.name,
      blobUrl: takePreview.takeUrl,
      uploadOptions,
      progressHandler: ev => {
        this.takeUploadProgress = Math.floor((ev.loaded * 100) / ev.total)
      },
    })
  }

  makeAudioConfigs(audio, index) {
    const path = /pitch|prospect/.test(this.audioType)
      ? `${audio.key}/upload`
      : `phrase/${audio.key}/upload`
    return {
      service: this.voiceService.base.one(path),
      fileName: audio.name,
      blobUrl: audio.takeUrl,
      uploadOptions: this.uploadOptions,
      progressHandler: ev => {
        const progress = Math.floor((ev.loaded * 100) / ev.total)
        this.uploadProgress[audio.key] = progress
      },
    }
  }

  reconcileUploads(recordedAudio, uploadedAudio) {
    this.state.reconciling = true
    this.uploadProgress = {}

    const revoker = this.revokeUrl.bind(null, 'takeUrl') // de-reference objects for garbage collection
    const getRecorded = audio =>
      find(propEq('name', audio.fileName), recordedAudio)
    const uploadSuccess = uploadedAudio
      .filter(upload => upload.uuid)
      .map(getRecorded)
    const uploadErrors = uploadedAudio
      .filter(upload => !upload.uuid)
      .map(getRecorded)

    if (uploadErrors.length) {
      this.recordedAudio = uploadErrors
      this.confirmRetryUpload(uploadSuccess)
      uploadSuccess.forEach(revoker)
      return
    }

    recordedAudio.forEach(revoker)
    this.refetchAudio()
  }

  confirmRetryUpload(retryUploads) {
    const retry = this.$mdDialog
      .confirm()
      .title('Some Uploads Where Not Successful')
      .textContent('Would you like to try uploading them again?')
      .ariaLabel('Retry Uploads')
      .ok('Retry')
      .cancel('Cancel')
      .multiple(true)

    this.$mdDialog
      .show(retry)
      .then(_ => {
        this.state.uploadingSession = true
        this.state.reconciling = false
        const toUpload = retryUploads.map(this.makeAudioConfigs.bind(this))
        this.MAS.bulkUploadAudio(toUpload)
          .then(this.reconcileUploads.bind(this, retryUploads))
          .catch(this.reconcileUploads.bind(this, retryUploads))
      })
      .catch(_ => {
        retryUploads.forEach(this.revokeUrl.bind(null, 'takeUrl'))
        this.refetchAudio()
      })
  }
  waitSyncLoading(){
    return new Promise(resolve =>{
      var syncInterval = setInterval(()=>{
        if(!JSON.parse(sessionStorage.getItem('syncLoading'))){
          console.log("Syncing audio file success...")
          resolve()
          clearInterval(syncInterval)
        }
      }, 500);
    })
  }
  refetchAudio() {
    this.revokeUrl(null, this.takePreview.takeUrl)
    this.resetTakePreview()
    this.waitSyncLoading().then(()=>{
      return this.MAS.fetchVoiceAudio({
        service: omit(['base'], this.voiceService),
        audio: [this.audioListKey],
      }) 
        .then(audio => {
          this.state.reconciling = false
          this.state.allDisabled = false
          this.state.uploadingSession = false
          this.state.audioList = audio[this.audioListKey]
          this.recordedAudio = []
          this.state.selectedIndex = 0
          this.selectedAudio = this.state.audioList[this.state.selectedIndex]
          if (!this.state.audioList.length) {
            this.state.allDisabled = true
          } else {
            this.state.allDisabled = this.selectedAudio.addedToSession || false
          }
          
        })
        .catch(console.error)
    })
    
  }

  resetAudioChunks() {
    this.audioChunks = []
  }

  removeAudio(audio, index) {
    if (audio.url === this.takePreview.takeUrl) {
      this.takePreview.addedToSession = false
      this.recordedAudio.splice(index, 1)
    } else {
      this.revokeUrl('url', audio) // de-reference object for garbage collection
      this.recordedAudio.splice(index, 1)
    }
    const segment = find(propEq('name', audio.name))(this.state.audioList)
    segment.addedToSession = false
    this.state.allDisabled = this.selectedAudio.addedToSession || false
  }

  updateBindings(nameSpace, update = {}) {
    Object.assign(this[nameSpace], update)
  }

  resetTakePreview() {
    this.state.hasPreviewAudio = false
    Object.assign(this.takePreview, {
      hasTake: false,
      takeSegment: null,
      takeUrl: null,
      addedToSession: false,
    })
  }

  setFile(fileElement) {
    if (fileElement && fileElement.files.length === 1) {
      this.fileName = fileElement.files[0].name
      this.$scope.$apply()
    }
  }

  upload() {
    this.inputFiles = document.getElementById('file')
    if (
      this.inputFiles &&
      this.inputFiles.files.length === 1 &&
      /\.wav$/i.test(this.inputFiles.files[0].name)
    ) {
      this.uploadAudioFile(this.inputFiles.files[0])
        .then(res => {
          this.waitSyncLoading().then(()=>{
            this.TS.show({
              text: `File upload successful`,
            })
          })
        })
        .catch(this.handleUploadError.bind(this))
    } else {
      this.TS.show({
        text: `Error on the selected audio file, choose a valid audio file with extension .wav`,
      })
    }
  }

  uploadAudioFile(audioFile) {
    const path = /pitch|prospect/.test(this.audioType)
      ? `${this.selectedAudio.key}/upload`
      : `phrase/${this.selectedAudio.uuid}/upload`
    return this.MAS.uploadAudioFile({
      service: this.voiceService.base.one(path),
      fileName: this.selectedAudio.name,
      file: audioFile,
      uploadOptions: this.uploadOptions,
      progressHandler: ev => {
        const progress = Math.floor((ev.loaded * 100) / ev.total)
        this.uploadProgress[this.selectedAudio.key] = progress
      },
    })
  }

  handleUploadError(err) {
    this.errors = err.data.map(err => 'Upload Error: '.concat(err))
    console.error(err)
  }

  dismissErrors() {
    this.errors = []
  }

  revokeUrl(accessor, entity) {
    accessor
      ? window.URL.revokeObjectURL(entity[accessor])
      : window.URL.revokeObjectURL(entity)
  }

  close() {
    const confirmClose = this.$mdDialog
      .confirm()
      .title('Are you sure you want to close the Recording Tool?')
      .textContent("Any audio that hasn't been uploaded will be deleted")
      .ariaLabel('Confirm Closing Recording Dialog')
      .ok('Close')
      .cancel('Cancel')
      .multiple(true)

    this.$mdDialog.show(confirmClose).then(this.$mdDialog.hide)
  }
}
