import { containsRole } from '../../../utils/managementUtils.js'
import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import ManagementController from '../../../controllers/ManagementController.js'
import { formOptions } from '../config/formOptions.js'

export default class EditPhraseBookController extends ManagementController {
  constructor(
    $state,
    $mdDialog,
    ManagementService,
    ToastService,
    RoleStore,
    PitchService,
    EditPhraseBookService,
  ) {
    'ngInject'
    super(...arguments)

    this.PS = PitchService
    this.EPBS = EditPhraseBookService
    this.formOptions = formOptions
    this.onPaginate = page => {
      this.state.queryParams.page = page
      this.getEntityList(
        this.state.queryParams,
        this.config.entity,
        this.config.searchConfig.editable,
        this.Phrases,
      )
    }
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()
    const PhraseBookService = this.service
    this.backTo = {
      name: 'Phrase Books',
      route: this.previousRoute.routerHref,
    }
    this.PhraseBook = PhraseBookService.base
    this.Phrases = PhraseBookService.phrases
    this.config = this.EPBS.getPhraseBookConfig()
    this.config.searchConfig.editable = containsRole(
      userRoles,
      this.config.defaultEditRoles,
    )
    this.searchConfig = this.config.searchConfig
    this.state.queryParams = this.getInitialParams(this.config.filterConfig)
    this.state.queryParams.limit = this.paginateLimit
    this.getEntityList(
      Object.assign(this.state.queryParams, { page: 1 }),
      this.config.entity,
      this.config.searchConfig.editable,
      this.Phrases,
    )
    this.state.topBarButtons = [
      {
        title: 'New Phrase',
        icon: 'content:add',
        action: this.createEntity.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
  }

  editOrView(event, entity, index) {
    const params = { service: this.Phrases, phrase: entity }
    const state = this.compSlug
      ? 'manage.editCompanyPhraseBook.phrase.edit'
      : 'manage.editPhraseBook.phrase.edit'
    this.$state.go(state, params)
  }

  createEntity(ev) {
    const params = { service: this.Phrases }
    const state = this.compSlug
      ? 'manage.editCompanyPhraseBook.phrase.create'
      : 'manage.editPhraseBook.phrase.create'
    this.$state.go(state, params)
  }

  findEntity(searchText = '', editable = false, limit = 5) {
    if (!searchText) {
      this.config.searchConfig.searchResults = []
      return
    }
    this.Phrases.get({ search: searchText, limit })
      .then(results => {
        const res = results.plain()
        this.config.searchConfig.searchResults = res.results || res
      })
      .catch(err => {
        console.error(`${this.config.entity} search error:`, err)
        this.TS.show({
          text: `${this.config.entity} search error`,
        })
      })
  }

  backTo() {
    this.$state.go(this.state.goBackRoute, { compSlug: this.compSlug })
  }

  update(phraseBook) {
    this.PhraseBook.one()
      .customPATCH(phraseBook)
      .then(updatedPhraseBook => {
        this.editablePhraseBook = Object.assign({}, updatedPhraseBook)
        this.phraseBook = this.editablePhraseBook
        this.state.setPristine = true
        this.TS.show({
          text: 'Phrase Book has been successfully updated',
        })
      })
      .catch(err => {
        if (err.data && err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error Updating Phrase Book',
        })
      })
  }

  exit() {
    this.$state.go('manage.globalPitchPhraseBooks', null, { reload: true })
  }

  getCampaignRelationOptions(relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
        if (this.phraseBook.company) {
          this.company = this.companyOptions.find(c => {
            return c.uuid === this.phraseBook.company
          })
        }
      })
      .catch(err => {
        this.TS.show({
          text:
            'Problem fetching options, you wont be able to create a phrase books',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading.details = false
      })
  }

  cancelEdit(form) {
    this.editablePhraseBook = Object.assign({}, this.phraseBook)
    form.$setPristine()
  }

  deletePhraseBook(phraseBook) {
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Phrase Book',
      entityName: phraseBook.name,
      identityEndpoint: this.PhraseBook.one(),
      entity: phraseBook,
      routeParams: this.previousRoute.params,
      routeWhenSuccessful: this.previousRoute.state,
    }
    this.$mdDialog.show(DCD)
  }

  editPhrase(phrase, phraseBook) {
    let compSlug = this.compSlug
    let compUuid = this.compUuid
    let params = { phrase, phraseBook, compSlug, compUuid }
    this.$state.go('.phrase.edit', params)
  }
}
