import EditPhraseBookPhraseController from './controller/EditPhraseBookPhraseController.js'
const template = require('./editPhraseBookPhrase.component.tpl.pug')

const editPhraseBookPhrase = {
  template: template(),
  controller: EditPhraseBookPhraseController,
  bindings: {
    phraseBook: '<',
    phrase: '<',
    compSlug: '<'
  }
}

export default editPhraseBookPhrase
