export default class ManageAudioVoiceController {
  constructor($state, $rootScope) {
    'ngInject'
    this.$state = $state
    this.$rootScope = $rootScope
    this.$rootScope.refreshOptions = {
      isVisible: false,
    }
    this.state = {
      navItems: [
        {
          name: 'Pitch Audio',
          route: 'manage.audioVoice.pitchAudio',
        },
        {
          name: 'Phrase Audio',
          route: 'manage.audioVoice.phraseAudio',
        },
        {
          name: 'Prospect Audio',
          route: 'manage.audioVoice.prospectAudio',
        },
      ],
      buttonsList: [
        {
          title: 'Pitch Audio',
          route: 'manage.audioVoice.pitchAudio',
        },
        {
          title: 'Phrase Audio',
          route: 'manage.audioVoice.phraseAudio',
        },
        {
          title: 'Prospect Audio',
          route: 'manage.audioVoice.prospectAudio',
        },
      ],
    }
  }

  $onInit() {
    this.state.currentNavItem = this.$state.current.name
  }

  $onDestroy() {
    this.$rootScope.refreshOptions = null
  }

  goTo(stateKey) {
    this.state.currentNavItem = this.state[stateKey]
    this.$state.go(this.state[stateKey])
  }
}
