import GlobalOptionGroupController from '../controller/manageGlobalOptionGroup.js'
const template = require('./manageGlobalOptionGroup.component.tpl.pug')

const manageGlobalOptionGroup = {
  template: template(),
  controller: GlobalOptionGroupController,
  bindings: {}
}

export default manageGlobalOptionGroup

