import ListMetricsController from './controller/ListMetricsController.js'
const template = require('./listMetrics.tpl.pug')

const listMetrics = {
  template: template(),
  controller: ListMetricsController,
  bindings: {
    metrics: '<',
    details: '<',
    loading: '<'
  }
}

export default listMetrics
