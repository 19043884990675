import { ADMIN_GROUP } from 'permissions.js'
import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// manage realms view

const manageDnc = 'manage.dnc'
const pageTitleConfig = {
  title: 'Manage Dnc',
}

const manageDncConfig = {
  url: '/dnc',
  component: 'manageDnc',
  data: {
    permissions: {
      only: [...ADMIN_GROUP],
      redirectTo: 'gateway',
    },
  },
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  },
}

export { manageDnc, manageDncConfig }
