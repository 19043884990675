import RepRankingsController from './controller/RepRankingsController.js'
const template = require('./repRankings.tpl.pug')

const repRankings = {
  template: template(),
  controller: RepRankingsController,
  bindings: {
    company: '<',
    campaign: '<',
    offsetMinutes: '<',
    realm: '<',
  }
}

export default repRankings
