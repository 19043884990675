import React, { useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import AppButton from '../../../components/AppButton'
import Papa from "papaparse";
import AppSnackbar from 'components/AppSnackbar'
import { useEffect } from 'react';
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest';
import axios from "axios"
import LoaderReact from '../../../common-components/loader-overlay/loader-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import CreateLoader from 'common-components/create-loader/CreateLoader'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'

const API_BASE_URL = 'https://api.perfectpitchtech.com'
export default function BulkCreateUser(props) {
  const [confirmation, setConfirmation] = useState(false)
  const [fileName, setFileName] = useState('')
  const [file, setFile] = useState(null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [button, setButton] = useState({ disabled: true, type: 'Gray' })
  const [roles, setRoles] = useState([])
  const [companies, setCompanies] = useState([])
  const [snack, setSnack] = useState({ active: false, text: '' })
  const [teams, setTeams] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [formData, setFormData] = useState([])
  const [errMsg, setErrMsg] = useState([])



  const getCampaignUUID = (itemCampaign) => {
    let newCampaign = []
    if(itemCampaign.length > 0){
      itemCampaign.forEach(campaign => {
        let item = campaigns.find(el => {
          return el.name.toLowerCase() === campaign.toLowerCase()
        })
        if(item === undefined){
          setSnack({
            active: true,
            text: "Cannot find campaign please refresh page or try again",
          })
          return []
        }
        newCampaign.push(item.uuid)
      });
    }else{
      setSnack({
        active: true,
        text: "There's a problem loading campaigns please refresh page..",
      })
    }
    return newCampaign
  }
  const getCompanyUUID = (itemCompany) => {
    if(companies.length > 0){
      if(itemCompany === ""){
        return null
      }else{
        let newCompany = companies.find(item => {
          return itemCompany.toLowerCase() === item.name.toLowerCase()
        })
        if(newCompany === undefined){
          setSnack({
            active: true,
            text: "Cannot find company please refresh page or try again",
          })
          return ""
        }
        return newCompany.name
      }
    }else{
      setSnack({
        active: true,
        text: "There's a problem loading companies please refresh page..",
      })
      return null
    }
  }
  const getGroupsUUID = (itemGroups) => {
    let newGroup = []
    if(roles.length > 0){
      itemGroups.forEach(element => {
        
        let group = roles.find(item => {
          return item.name.replace(/_+/g, ' ').toLowerCase() === element
        })
        newGroup.push(group.pk)
      });
      return newGroup

    }else{
      setSnack({
        active: true,
        text: "There's a problem loading roles please refresh page..",
      })
    }
  }
  const getTeams = (itemTeam) => {
    if(teams.length > 0){
      if(itemTeam === ""){
        return null
      }else{
        
        let newTeam = teams.find(item => {
          return itemTeam.toLowerCase() === item.name.toLowerCase()
        })
        if(newTeam === undefined){
          setSnack({
            active: true,
            text: "Cannot find team please refresh page or try again",
          })
          return ""
        }
        return newTeam.uuid
      }
    }else{
      setSnack({
        active: true,
        text: "There's a problem loading team please refresh page..",
      })
      return null
    }
  }

  const validateUsername = async (username) => {
    let searchRes =  await axios.get(`${API_BASE_URL}/identity/user/manage/list/?editable=true&limit=100000&search=${username}`)
    .then(({data}) => {
      let items = data.results.map(item => {
        return item.username
      })
      return items
    })
    const found = await searchRes.find(user => {
      return user.toLowerCase() === username.toLowerCase()
      });
    
    if(found !== undefined){
      return true
    }
    
      
  }
  

  const transformData = (data) => {
    setErrMsg([])
    setLoading(true)
    
    let postData = []
    let existingUsername = []
    data.forEach((item, index) => {
      if (index > 0) {
        if (item[0] !== "") {
          
          postData.push({
            "first_name": item[0],
            "last_name": item[1],
            "username": item[2],
            "password": item[3],
            "campaigns": getCampaignUUID(item[4].split(/,\s*/)),
            "groups": getGroupsUUID(item[5].split(/,\s*/)),
            "team": getTeams(item[6]),
            "company": getCompanyUUID(item[7]),
            "email": item[8],
            "hire_date": moment(new Date(item[9])).format('YYYY-MM-DD')
          })
        }
      }
    });

    let usernameArr = postData.map(function(item){ return item.username });
    let invalidUsername = []

    usernameArr.some(function(item, idx){ 
        if(usernameArr.indexOf(item) != idx){
          invalidUsername.push({
            username: [`"${item}" already exist in file`],
            file: true
          })
        } 
    });
    setErrMsg(invalidUsername)

    if(invalidUsername.length === 0){
      data.forEach(async (item, index) => {
        if (index > 0) {
          if (item[0] !== "") {
            if (await validateUsername(item[2])){
              existingUsername.push({username:[`Username ${item[2]} already exist`]})
              setButton({
                type:"Gray",
                disabled: true
              })
            }

            setErrMsg([...errMsg, ...existingUsername])
          }
        }
      });
    }
    console.log("postData.length > 0" , postData)
    if(postData.length > 0 && invalidUsername.length === 0 && existingUsername.length === 0){
      setButton({
        type:"Green",
        disabled: false
      })
      
    }

    setFormData(postData)
    setLoading(false)
  }

  const fileChange = (e) => {
    setFormData([])
    setFileName('')
    setFile(null)
    setButton({
      type:"Gray",
      disabled: true
    })
    const files = e.target.files[0];
    setFileName(files.name)
    setFile(files)
    if (files) {
      setErrMsg([])
      Papa.parse(files, {
        complete: function (results) {
          transformData(results.data)
        }
      }
      )
    }
  }
  useEffect(() => {
    setLoading(true)
    axios.
      get(`${API_BASE_URL}/identity/group/list/?assignable=true`)
      .then(({ data }) => {
        setRoles(data)
      })
      .then(() => {
        axios.get(`${API_BASE_URL}/identity/company/list`)
          .then(({ data }) => {
            setCompanies(data)
            axios.get(`${API_BASE_URL}/identity/campaign/list`)
              .then(({ data }) => {
                setCampaigns(data)
                axios.get(`${API_BASE_URL}/identity/team/list/?editable=true`)
                  .then(({ data }) => {
                    setTeams(data)
                  })
                  .catch(() => {
                    setSnack({
                      active: true,
                      text: "There's a problem loading teams please refresh page..",
                    })
                  })
                setLoading(false)
              }).catch(() => {
                setSnack({
                  active: true,
                  text: "There's a problem loading campaigns please refresh page..",
                })
              })
          })
      })
      .catch(()=>{
        setSnack({
          active: true,
          text: "There's a problem loading roles please refresh page..",
        })
      })
  }, [])
  const closeSnackbar = () => {
    setSnack({ active: false, text: '' })
  }


  const saveData = async () => {
    setSaveLoading(true)
    axios.post(`${API_BASE_URL}/identity/user/manage/create/bulk/`, formData)
    .then((data) => {
      setFormData([])
      setFileName('')
      setFile(null)
      setConfirmation(true)
      setSaveLoading(false)
    }).catch((err)=>{
      setButton({
        type:"Gray",
        disabled: true
      })
      setErrMsg(err.response.data)
      setSaveLoading(false)
      setFormData([])
      setFileName('')
      setFile(null)
    })

  }
  const createAnother = () => { 
    setFormData([])
    setFileName('')
    setFile(null)
    setConfirmation(false)
    setErrMsg([])
  }
  return (
    <React.Fragment>
      {
        loading ?
          <LoaderReact message='Please wait a moment' />
          :
          saveLoading ? (
            <CreateLoader
              message="One moment. We're creating the new Users..."
              handleCancel={() => {
                props.$state.go('manage.users')
              }}
            />
          ): confirmation ?
            (
              <ConfirmationDialog
                message={`You have added New Users`}
                SubmitTxt="ADD ANOTHER"
                handleCancel={()=>{
                  props.$state.go('manage.users')
                }}
                handleCreateAnother={createAnother}
                removeClose={true}
              />
            )
          
          : (
            <React.Fragment>
              
              <Grid container direction="column">
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    margin: '0px 30px',
                    padding: fileName ? '40px 0px 20px 0px' : '10px',
                  }}
                >
                  <span>{fileName}</span>
                </div>

                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '30px',
                    }}
                  >
                    <input
                      accept=".csv"
                      style={{ display: 'none' }}
                      id="contained-button-file"
                      type="file"
                      onChange={(e) => {
                        fileChange(e)
                        setErrMsg([])
                      }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="primary" component="span">
                        <CloudUploadIcon style={{ marginRight: '15px' }} /> Upload
                        File
                      </Button>

                    </label>
                  </div>
                </Grid>

              </Grid>
              {
                Object.values(errMsg).length > 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <b style={{padding:15, color:"#333"}}>Please check and fix the following errors:</b>

                      <div style={{maxHeight: 400, overflow:"auto"}}>
                        {
                          errMsg.map((err, index) => {
                            return (
                              <ListItem>
                                  { 
                                     'file' in err ? (
                                      <div>
                                        <ListItem>
                                            <ListItemText
                                              primary={`${err.username[0]}`}
                                              secondary={`Field: ${Object.keys(errMsg[index])[0]}`}
                                            />
                                          </ListItem>
                                      </div>
                                     ): (
                                      Object.values(err).map(item => {
                                        console.log(item)
                                            return (
                                              <ListItem>
                                                <ListItemText
                                                  primary={`${item[0]}`}
                                                  secondary={`Row ${index + 1} / Field: ${Object.keys(errMsg[index])[0]}`}
                                                />
                                              </ListItem>
                                            )
                                      })
                                     )
                                     
                                  }
                              </ListItem>
                            )
                          })
                        }
                       
                      </div>
                    </Grid>
                  </Grid>
                )
              } 
              <Grid container className="p-3" spacing={4}>
              
                <Grid item xs={12} className="text-center mt-1">
                  <AppButton onClick={() => {
                    props.$state.go('manage.users')
                  }} width={165}>
                    CANCEL
                  </AppButton>
                  <AppButton
                    onClick={saveData}
                    className="ml-2"
                    type={button.type}
                    width={165}
                    disabled={button.disabled || errMsg.length > 0 }
                  >
                    SAVE
                  </AppButton>
                </Grid>
              </Grid>
            </React.Fragment>
          )
      }
    
    <AppSnackbar
      vertical="top"
      horizontal="right"
      open={snack.active}
      close={closeSnackbar}
      text={snack.text}
    />
    </React.Fragment>
  )
}
