import CreatePanelController from './controller/CreatePanelController.js'
const template = require('./createPanel.tpl.pug')

const createPanel = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreatePanelController
}

export default createPanel

