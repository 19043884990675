import {
  DATA_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'doNotCallRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'DNC Rate',
    sort: 'ascending',
    subSection: 'dispositions',
  },
}

const keys = {
  componentKeys: [
    DATA_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'sub_metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'dnc' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const doNotCallRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { doNotCallRate }
