const template = require('./rightDrawerHeader.tpl.pug')

const rightDrawerHeader = {
  template: template(),
  bindings: {
    title: '@',
    goToFunc: '&',
    goToDisabled: '<',
    hideGoTo: '<',
    backToFunc: '&',
    backToDisabled: '<',
    hideBackTo: '<',
  }
}

export default rightDrawerHeader
