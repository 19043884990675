// @flow

import type RoleValidator from '../../role-validator/services/RoleValidator.js'
import type { PermissionGroup } from 'permissions.js'
import type { AngularPromise, Directive } from 'angular'

import angular from 'angular'

class PermissionsDirectiveController {
  RV: RoleValidator
  only: PermissionGroup
  except: PermissionGroup
  isValid: AngularPromise<boolean>

  constructor(RoleValidator: RoleValidator) {
    'ngInject'
    this.RV = RoleValidator
  }

  $onInit() {
    this.isValid = this.RV.validate({ only: this.only, except: this.except })
  }
}

export default function permissionsDirective($q: Object): Directive {
  'ngInject'
  function linkFunc(scope, elem, attr, ctrl: PermissionsDirectiveController) {
    const element = angular.element(elem[0])
    // $FlowFixMe
    element.addClass('ng-hide')
    $q.when(ctrl.isValid).then(isValid => {
      if (isValid) {
        // $FlowFixMe
        element.removeClass('ng-hide')
        return isValid
      }
    })
  }

  return {
    restrict: 'A',
    scope: {},
    // $FlowFixMe
    bindToController: {
      only: '<',
      except: '<',
    },
    controllerAs: '$ctrl',
    // $FlowFixMe
    controller: PermissionsDirectiveController,
    // $FlowFixMe
    link: linkFunc,
  }
}
