// Manage Manage Location
import { manageLocations, manageLocationsConfig } from './manage.locations.js'
import {
  createLocation,
  createLocationConfig,
} from './child-states/create-location/child-states/create/manage.locations.createLocation.create.js'
import {
  newLocation,
  newLocationConfig,
} from './child-states/create-location/manage.locations.createLocation.js'

export default function manageLocationRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageLocations, manageLocationsConfig)
    .state(newLocation, newLocationConfig)
    .state(createLocation, createLocationConfig)
}
