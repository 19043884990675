import MetricHealthController from './controller/MetricHealthController.js'
import './metricHealth.scss'
const template = require('./metricHealth.tpl.pug')

const metricHealth = {
  template: template(),
  controller: MetricHealthController,
  bindings: {
    metric: '<'
  }
}

export default metricHealth
