import RDCD from '../../rotate-did-confirmation/rotateDidConfirmation.js'

export default class RotateDidController {
  constructor($mdDialog, DidListService, ToastService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.DidListService = DidListService
    this.TS = ToastService
    this.rotateButtonDisabled = false
  }

  rotateDid() {
    const data = {
      phone_number: this.did.number,
      rotate: true,
    }
    const uriData = {
      company: this.company,
      campaign: this.campaign,
      pool: this.pool,
    }
    this.rotateButtonDisabled = true
    this.DidListService.buyDid
      .post(data, uriData)
      .then(data => {
        let rotatedNumber = data.number ? data.number : null
        if (rotatedNumber) {
          RDCD.locals = {
            did: this.did,
            pool: this.pool,
            company: this.company,
            campaign: this.campaign,
            rotatedNumber,
          }
          this.$mdDialog.show(RDCD)
        } else {
          console.error('Rotated DID number not returned')
          this.TS.show({
            text: 'Error while rotating DID number',
          })
        }
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error while rotating did',
        })
      })
      .finally(() => {
        this.rotateButtonDisabled = false
        this.refreshDidList()
      })
  }

  close() {
    this.$mdDialog.destroy()
  }
}
