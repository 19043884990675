export default class FieldOptionDialogController {
  constructor($mdDialog) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.state = {
      submitting: false,
      error: false,
      formOptions: [
        {
          accessor: 'description',
          inputType: 'input',
          label: 'Field description',
          type: 'text',
          attributes: ['required'],
        },
        {
          accessor: 'value',
          inputType: 'input',
          label: 'Field value',
          type: 'text',
          attributes: ['required'],
        },
      ],
    }
    this.oldOption = angular.copy(this.option)
  }

  updateOrCreateOption(option = {}) {
    const optionMethod = this.isCreate
      ? this.createOption.bind(this)
      : this.updateOption.bind(this)
    optionMethod(option)
  }

  createOption(option = {}) {
    this.state.submitting = true
    this.service
      .customPOST(option)
      .then(createdOption => createdOption.plain())
      .then(this.close.bind(this))
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  updateOption(option = {}) {
    this.state.submitting = true
    if (this.oldOption.description === option.description) {
      delete option.description
    }
    if (this.oldOption.value === option.value) {
      delete option.value
    }
    this.service
      .one(option.uuid)
      .patch(option)
      .then(updatedOption => updatedOption.plain())
      .then(this.close.bind(this))
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
      .finally(() => {
        this.state.submitting = false
      })
  }

  resetError() {
    this.state.error = false
  }

  close(option) {
    const closer = option ? this.$mdDialog.hide : this.$mdDialog.cancel
    closer({
      wasCreate: this.isCreate,
      option,
    })
  }
}
