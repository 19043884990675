import './dashboard.repRankings.scss'

import angular from 'angular'

// Services
import RepRankingsService from './services/RepRankingsService.js'

// components
import repRankings from './component/repRankings.js'
import chartContainer from './sub-components/chart-container/chartContainer.js'
import stackChart from './sub-components/stack-chart/stackChart.js'

export default angular
  .module('portalApp.dashboard.repRankings', [])
  .service('RepRankingsService', RepRankingsService)
  .component('repRankings', repRankings)
  .component('chartContainer', chartContainer)
  .component('stackChart', stackChart)
  .name
