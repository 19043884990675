import RapidResponseSegmentsController from './controller/RapidResponseSegmentsController'
import template from './rapidResponseSegments.component.tpl.pug'

const rapidResponseSegments = {
  template: template(),
  controller: RapidResponseSegmentsController,
  bindings: {},
}

export default rapidResponseSegments
