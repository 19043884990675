import { cond, equals, map } from 'ramda'

function bindKeyEvents(mappings = [], dontPropagate = false) {
  const bindings = map(key => [equals(key.code), key.handler], mappings)
  return function(ev) {
    if (dontPropagate) ev.stopPropagation()
    cond(bindings)(ev.code)
  }
}

export { bindKeyEvents }
