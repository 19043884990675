// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService from '../../../../../../global/index/api-services/IdentityService.js'
import type { BaseService, Element } from 'restangular'
import type ManagementService from '../../../../../services/ManagementService.js'
import type PitchService from '../../../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../../../global/index/services/ToastService.js'
import type { PermissionGroup } from 'permissions.js'
import type {
  User,
  RapidResponseTest,
  RapidResponseSegment,
  TtsVoice,
} from 'types/entities.js'

import { ADMIN_GROUP } from 'permissions.js'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'
import { recorderGroupFinder } from 'util/voices.js'

export default class RapidResponseTestsSettingsController {
  $q: Object
  $timeout: Function
  $mdDialog: Object
  compSlug: string // passed to component
  testUuid: string // passed to component
  MS: ManagementService
  PS: PitchService
  TS: ToastService
  Groups: Element
  Users: Element
  ADMIN_GROUP: PermissionGroup
  service: BaseService
  ttsService: Element
  testService: Element
  segmentService: Element
  rapidResponseTest: RapidResponseTest // passed to component
  editableRapidResponseTest: RapidResponseTest
  rapidResponseTestEditForm: Object
  goBackRoute: string
  state: {|
    loading: boolean,
    testSegments: RapidResponseSegment[],
    prospectVoices: User[],
    tts_voices: TtsVoice[],
  |}

  constructor(
    $q: Object,
    $timeout: Function,
    $mdDialog: Object,
    ManagementService: ManagementService,
    IdentityService: IdentityService,
    PitchService: PitchService,
    ToastService: ToastService,
  ) {
    'ngInject'

    this.$timeout = $timeout
    this.$q = $q
    this.MS = ManagementService
    this.Groups = IdentityService.Groups.list
    this.Users = IdentityService.User.manage.list
    this.PS = PitchService
    this.ADMIN_GROUP = ADMIN_GROUP
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.state = {
      loading: true,
      testSegments: [],
      prospectVoices: [],
      tts_voices: [],
    }
  }

  $onInit() {
    this.editableRapidResponseTest = Object.assign({}, this.rapidResponseTest)
    this.service = this._getService(this.compSlug)
    this.ttsService = this._getTtsService()
    this.testService = this.service.one('tests').one(this.testUuid)
    this.segmentService = this.service.one('segments')
    this.goBackRoute =
      this.rapidResponseTest.company === 'global'
        ? 'manage.globalRapidResponse.tests'
        : `manage.editCompany.rapidResponseTests({slug:'${
            this.compSlug
          }', uuid:'${this.testUuid}'})`
    this.getTestRelations()
  }

  _getService(compSlug: string = 'global'): BaseService {
    const service = this.PS.setupRapidResponse(compSlug)
    if (service.company) {
      return service.company
    } else {
      return service.global
    }
  }

  _getTtsService() {
    return this.PS.setupTtsVoices()
  }

  update(rapidResponseTest: RapidResponseTest) {
    this.testService
      .customPATCH(rapidResponseTest)
      .then(updatedRapidResponseTest => {
        this.rapidResponseTest = updatedRapidResponseTest
        this.editableRapidResponseTest = { ...updatedRapidResponseTest }
        this.rapidResponseTestEditForm.$setPristine()
        this.TS.show({
          text: 'Rapid Response Test has been successfully updated',
        })
      })
      .catch((err: any) => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error Updating Phrase Book',
        })
      })
  }

  getTestRelations() {
    this.state.loading = true
    this.$q
      .all({
        voices: this._getVoices(),
        segments: this._getTestSegments(),
        tts_voices: this._getTTSVoices(),
      })
      .then(({ voices, segments, tts_voices }) => {
        this.state.prospectVoices = voices
        this.state.testSegments = segments
        this.state.tts_voices = tts_voices
      })
      .catch(err => {
        this.TS.show({
          text: 'Error fetching test relations',
        })
      })
      .finally(() => {
        this.state.loading = false
        this.$timeout(() => {
          this.rapidResponseTestEditForm.$setPristine()
        })
      })
  }

  _getVoices() {
    return this.Groups.get()
      .then(groups => groups.plain())
      .then(recorderGroupFinder)
      .then(group => this.Users.get({ groups: group.pk }))
      .then(prospectVoices => {
        return prospectVoices.plain().results
      })
  }

  _getTTSVoices() {
    return this.ttsService.getList()
  }

  _getTestSegments() {
    return this.segmentService.get().then(segments => segments.plain())
  }

  toggleActive(bool: boolean) {
    this.editableRapidResponseTest.active = bool
  }

  toggleLive(bool: boolean) {
    this.editableRapidResponseTest.live = bool
  }

  deleteRapidResponseTest(rapidResponseTest: RapidResponseTest) {
    const routeWhenSuccessful =
      this.compSlug === 'global'
        ? 'manage.globalRapidResponse.tests'
        : 'manage.editCompany.rapidResponseTests'
    const routeParams = {
      uuid: this.rapidResponseTest.company,
      slug: this.compSlug,
    }
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Rapid Response Test',
      entityName: rapidResponseTest.name,
      identityEndpoint: this.testService,
      entity: rapidResponseTest,
      routeWhenSuccessful,
      routeParams,
    }
    this.$mdDialog.show(DCD)
  }

  cancelEdit(form: Object) {
    this.editableRapidResponseTest = Object.assign({}, this.rapidResponseTest)
    form.$setPristine()
  }
}
