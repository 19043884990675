import { makePageTitle } from '../../../utils/resolve/pageTitle.js'

const manageEditCompany = 'manage.editCompany'

const pageTitleConfig = {
  title: 'Edit Company',
  resolveEntity: 'company',
  accessor: 'name',
}

const manageEditCompanyConfig = {
  abstract: true,
  url: '/company/edit/{slug}/{uuid}',
  params: {
    slug: null,
    uuid: null,
    entity: null,
  },
  component: 'editCompany',
  resolve: {
    company: ($transition$, IdentityService) => {
      'ngInject'
      const company = $transition$.params().entity
      if (company) return company
      return IdentityService.Company.fetch
        .one($transition$.params().uuid)
        .get()
        .then(company => company.plain())
    },
    pageTitle: makePageTitle(pageTitleConfig)
  }
}

export { manageEditCompany, manageEditCompanyConfig }
