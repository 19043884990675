export default function autoFocus ($timeout) {
  'ngInject'
  function linkFunc (scope, elem) {
    scope.$watch('watchValue', (newValue) => {
      $timeout(() => {
        if (newValue === true) {
          elem[0].focus()
        } else if (newValue === false) {
          elem[0].blur()
        }
      })
    }, true)
  }

  return {
    restrict: 'A',
    scope: {
      watchValue: '=dAutoFocusWatch'
    },
    link: linkFunc
  }
}
