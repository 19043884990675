import './campaignDataPosting.scss'
import CampaignDataPostingController from './controller/CampaignDataPostingController.js'
const template = require('./campaignDataPosting.component.tpl.pug')

const campaignDataPosting = {
  controller: CampaignDataPostingController,
  template: template(),
  bindings: {
    campaign: '<',
    openSections: '<',
  },
}

export default campaignDataPosting
