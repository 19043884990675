// @flow

import type { AngularPromise } from 'angular'
import type { PermissionGroup } from 'permissions.js'
import type RoleStore from '../../role-store/services/RoleStore.js'

import { map, any } from 'ramda'

export default class RoleValidator {
  $q: Object
  RS: RoleStore
  areTrue: (mixed) => boolean

  constructor($q: Object, RoleStore: RoleStore) {
    'ngInject'

    this.$q = $q
    this.RS = RoleStore
    this.areTrue = x => x === true
  }

  validate(permissions: { only: PermissionGroup, except: PermissionGroup }): AngularPromise<boolean> {
    const invert = !!permissions.except
    const roles = permissions.only || permissions.except
    return this.validateRoles(roles, invert)
  }

  validateRoles(roles: PermissionGroup, invert: boolean = false): AngularPromise<boolean> {
    return this.$q
      .all(map(this.validateRole.bind(this), roles))
      .then(validatedArray => {
        const isValid =
          validatedArray.length > 0 ? any(this.areTrue, validatedArray) : false
        return invert ? !isValid : isValid
      })
  }

  validateRole(role: string) {
    const roleDef = this.RS.getRoleDefinition(role)
    if (!roleDef.validator) return false
    return roleDef.validator()
  }
}
