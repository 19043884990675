// updatePassword view

const updatePassword = 'updatePassword'

const updatePasswordConfig = {
  url: '/update-password',
  params: {
    userId: null
  },
  component: 'updatePassword',
  data: {
    authRequired: true
  }
}

export { updatePassword, updatePasswordConfig }
