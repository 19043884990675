import { ENV } from 'settings.js'

export default function compile($compileProvider) {
  'ngInject'
  const addDebugInfo = ENV.NODE_ENV === 'development' ? true : false

  /*
  | When false:
  |   No longer attach information about bindings and scopes to DOM nodes
  |   No longer add placeholder comments for structural directives ie: <!-- ngIf: shouldShow() -->
  |
  |   Can be re-enabled from the console by running angular.reloadWithDebugInfo()
  */
  $compileProvider.debugInfoEnabled(addDebugInfo)

  // Disable creating directives with html comments & css classes (common practice to never do this anymore!)
  $compileProvider.commentDirectivesEnabled(false)
  $compileProvider.cssClassDirectivesEnabled(false)
}
