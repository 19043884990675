import { makePageTitle } from '../../../utils/resolve/pageTitle.js'

// manage access lists view

const manageAccessLists = 'manage.accessLists'

const pageTitleConfig = {
  title: 'Manage Access Lists'
}

const manageAccessListsConfig = {
  url: '/access-lists',
  component: 'manageAccessLists',
  resolve: {
    compSlug: function ($transition$) {
      'ngInject'
      let compSlug = $transition$.params().compSlug || 'global'
      return compSlug
    },
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageAccessLists, manageAccessListsConfig }
