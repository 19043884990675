import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Paginator from '../../components/Paginator.js'
import { always, cond, T } from 'ramda'
//import { Header } from '../../util/Headers'
import '../react-gateway.scss'
import { getBaseUrl } from '../../global/config/providers/restangularProvider/rest.js'
import States from './statescontries.js'
// "precommit": "nps flow && nps test.pre && lint-staged"

let Header
const API_BASE_URL = getBaseUrl(window.location.host, true)
const Gateway = props => {
  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const $injector = props.$injector
  const AS = props.AuthService
  const $state = props.$state
  const $sessionStorage = props.$sessionStorage
  const [itemsSelected, setItemsSelected] = useState([])

  useEffect(() => {
    Header = {
      headers: {
        Authorization: `token ${JSON.parse(localStorage['ngStorage-ppToken'])}`,
      },
    }
    getCampains()
  }, [])

  const goToManagment = () => {
    const manageState = cond([
      [T, always('manage.campaigns')],
    ])($sessionStorage.userGroups)
    $state.go(manageState)
  }

  const logout = () => {
    AS.logout()
  }

  const getCampains = () => {
    axios
      .get(
        `${API_BASE_URL}/identity/campaign/list/paginator/?editable=true&order_by=-datetime_modified`,
        Header,
      )
      .then(res => {
        setData(res.data)
      })
  }

  const searchData = value => {
    setSearch(value)
    axios
      .get(
        `${API_BASE_URL}/identity/campaign/list/paginator/?search=${value}`,
        Header,
      )
      .then(res => {
        console.log(res.data)
        setData({ ...res.data })
      })
  }

  const goToDashboard = campaign => {
    console.log(campaign)

    $state.go('dashboard.overview', {
      campaignSlug: campaign.slug,
      server: 'all',
    })
  }

  return (
    <div className="container gateway">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css"
      />
      <br />
      <br />

      <div className="row">
        <div className="col s2 mt-2">
          <a
            onClick={goToManagment}
            href="#"
            className="grey-text grey-darken-4"
          >
            <i className="material-icons small">settings</i>
          </a>
        </div>
        <div className="col s8 center-align">
          <span
            className="grey-text grey-darken-2"
            style={{ fontSize: 2.5 + 'em' }}
          >
            Welcome to the Perfect Pitch Portal
          </span>
        </div>
        <div className="col s2 mt-2">
          <a onClick={logout} href="#" className="grey-text grey-darken-4">
            <i className="material-icons small right">exit_to_app</i>
          </a>
        </div>
      </div>
      {/* <div className="row">
        <div className="col s12">
          <h4>Parent Items Selected {itemsSelected.length}</h4>
          <States itemsSelected={items => setItemsSelected(items)} />
        </div>
      </div> */}
      <br />
      <ul className="collection with-header">
        <li className="collection-header camp-header">
          <i className="material-icons white-text btn-search">search</i>
          <input
            onChange={e => searchData(e.target.value)}
            placeholder="Search campaign"
            value={search}
            type="text"
            className="white-text"
          />
        </li>
        {'results' in data &&
          data.results.map((item, index) => (
            <li
              onClick={() => goToDashboard(item)}
              key={index}
              className="collection-item center-align p-1 grey-text cursor-pointer"
            >
              {item.name}
            </li>
          ))}
      </ul>
      <br />
      {'results' in data && (
        <Paginator
          colection={data}
          setColection={data => setData(data)}
          urlPage={
            '/identity/campaign/list/paginator/?editable=true&order_by=-datetime_modified&page=PAGE'
          }
        />
      )}

      <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script>
    </div>
  )
}

export default Gateway
