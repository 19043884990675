// @flow

import { contains } from 'ramda'
import type { User } from 'types/entities.js'
import type { StoredPermissions } from 'permissions.js'

type storeSessionArgs = { key: string, data: mixed }
type defineUserRolesArgs = { user: User, groupsArray: StoredPermissions }

class NotAuthorizedError {
  message: string
  constructor(message) {
    this.message = message
  }
}

export default class AuthService {
  $localStorage: any
  $sessionStorage: any
  $state: any
  $q: any
  $rootScope: any
  ToastService: any
  User: any
  Groups: any
  RoleStore: any

  constructor(
    $localStorage: any,
    $sessionStorage: any,
    $state: any,
    $q: any,
    $rootScope: any,
    IdentityService: any,
    Login: any,
    Logout: any,
    RoleStore: any,
    ToastService: any,
  ) {
    'ngInject'
    this.$q = $q
    this.$state = $state
    this.$rootScope = $rootScope
    this.$localStorage = $localStorage
    this.$sessionStorage = $sessionStorage
    this.ToastService = ToastService
    this.User = IdentityService.User
    this.Groups = IdentityService.Groups
    this.RoleStore = RoleStore
  }

  isAuthorized(): Promise<User> {
    return this.getProfile().then(
      (profile: User): User => {
        if (profile.username) {
          return profile
        } else {
          throw new Error('User Not Authorized')
        }
      },
    )
  }

  login(credentials: { username: string, password: string }) {
    delete this.$localStorage.ppToken
    return this.User.login
      .post(credentials)
      .then(res => ({
        token: res.auth_token,
        key: 'ppToken',
      }))
      .then(this.setToken.bind(this))
      .then(this.getProfile.bind(this))
      .then(this.getGroups.bind(this))
      .then(this.defineUserRoles.bind(this))
  }

  impersonate(token: string) {
    delete this.$localStorage.impersonateToken
    this.RoleStore.clearRoles()
    return this.$q(resolve => {
      this.setToken({
        token: token,
        key: 'impersonateToken',
      })
      resolve()
    })
      .then(this.getProfile.bind(this))
      .then(this.getGroups.bind(this))
      .then(this.defineUserRoles.bind(this))
  }

  logout() {
    if (this.$localStorage.impersonateToken) {
      this.ToastService.show({
        text: 'Cannot logout while impersonating a user',
      })
      return
    }
    this.User.logout
      .post()
      .then(() => {
        delete this.$localStorage.ppToken
        delete this.$sessionStorage.userGroups
        delete this.$sessionStorage.user
        this.RoleStore.clearRoles()
        this.$state.go('signIn')
      })
      .catch(() => {
        this.$state.go('signIn')
      })
  }

  getGroups(
    user: User,
  ): Promise<{ user: User, groupsArray: StoredPermissions }> {
    if (
      this.$sessionStorage.userGroups &&
      !this.$localStorage.impersonateToken
    ) {
      return this.$q.resolve({
        user,
        groupsArray: this.$sessionStorage.userGroups,
      })
    }

    return this.Groups.list.get().then(groups => {
      const userGroups = groups
        .plain()
        .filter(group => contains(group.pk, user.groups))
      this.storeInSession({ key: 'userGroups', data: userGroups })
      return {
        user,
        groupsArray: userGroups,
      }
    })
  }

  defineUserRoles({ user, groupsArray }: defineUserRolesArgs): User {
    const roles = groupsArray.map(x => x.name)
    this.RoleStore.defineMultipleRoles(roles, () => true)
    return user
  }

  getProfile(): Promise<User> {
    this.$rootScope.loading = true
    const hasTokenAndProfile =
      this.$localStorage.ppToken && this.$sessionStorage.user
    if (hasTokenAndProfile && !this.$localStorage.impersonateToken) {
      this.$rootScope.loading = false
      return this.$q.resolve(this.$sessionStorage.user)
    }

    return this.User.profile
      .get()
      .then(profile => {
        const user = profile.plain()
        this.storeInSession({ key: 'user', data: user })
        return user
      })
      .catch(err => {
        if (err) {
          throw new NotAuthorizedError('Not Authorized')
        }
      })
      .finally(() => {
        this.$rootScope.loading = false
      })
  }

  setToken(tokenObj: { token: string, key: string }) {
    const { key, token } = tokenObj
    this.$localStorage[key] = token
  }

  storeInSession({ key, data }: storeSessionArgs) {
    this.$sessionStorage[key] = data
  }
}
