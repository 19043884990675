// @flow

import type { BaseService, Element, RestangularPromise } from 'restangular'
import type { StoredPermissions } from 'permissions.js'
import type { GlobalAndMaybeCompanyCampaign } from '../../global/index/api-services/types.js'
import type {
  User,
  Campaign,
  Company,
  Team,
  Realm,
  Entity,
  EntityString,
} from 'types/entities.js'

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService from '../../global/index/api-services/IdentityService.js'
// eslint-disable-next-line no-unused-vars
import type PitchService from '../../global/index/api-services/PitchService.js'
import type { RelationData, SearchConfig } from './types/managementService.js'

import { contains, findIndex, pipe, propEq, reduce, T } from 'ramda'
import campaignDetailsDialog from '../dialogs/campaign-details/campaignDetails.js'
import accessListDetailsDialog from '../dialogs/accessList-details/accessListDetails.js'
import userDetailsDialog from '../dialogs/user-details-dialog/userDetailsDialog.js'

class UpdateListError extends Error {
  constructor(msg) {
    super(msg)
    this.message = msg
    this.name = 'UpdateListError'
  }
}

export default class ManagementService {
  _$q: Object
  _Company: Object
  _Campaign: Object
  _Realm: Object
  _Team: Object
  _Groups: Object
  _User: Object
  create: {|
    user: Element,
    company: Element,
    campaign: Element,
    location: Element,
    team: Element,
    campaignRouter: Element,
    routerRoute: Element,
    acl: Element,
    aclNode: Element,
  |}
  list: {|
    acl: Element,
    campaign: Element,
    company: Element,
    did: Element,
    didAll: Element,
    globalPhrases: GlobalAndMaybeCompanyCampaign,
    location: Element,
    pool: Element,
    realm: Element,
    team: Element,
    user: Element,
  |}
  update: {|
    acl: BaseService,
    campaign: BaseService,
    company: BaseService,
    location: BaseService,
    team: BaseService,
    user: BaseService,
    user: BaseService,
  |}
  dialogs: {|
    campaignDetails: Object,
    accessListDetails: Object,
    userDetails: Object,
  |}

  _getEntityRelations: (
    entity: Entity,
    entityName: EntityString,
  ) => Array<{ key: string, uuid: string, method: Function }>

  constructor(
    $q: Object,
    IdentityService: IdentityService,
    PitchService: PitchService,
    ProspectService: Object,
    AccessListService: Object,
  ) {
    'ngInject'

    this._$q = $q
    this._Company = IdentityService.Company
    this._Campaign = IdentityService.Campaign
    this._Realm = IdentityService.Realm
    this._Team = IdentityService.Team
    this._Groups = IdentityService.Groups
    this._User = IdentityService.User.manage

    this.create = {
      user: IdentityService.User.manage.create,
      company: IdentityService.Company.create,
      campaign: IdentityService.Campaign.create,
      location: IdentityService.Location.create,
      team: IdentityService.Team.create,
      campaignRouter: ProspectService.createCampaignRoute,
      routerRoute: ProspectService.createRouterRoute,
      acl: IdentityService.Acl.create,
      aclNode: AccessListService.createNode,
    }

    this.list = {
      acl: IdentityService.Acl.list,
      campaign: IdentityService.Campaign.list,
      company: IdentityService.Company.list,
      did: IdentityService.Did.list,
      didAll: IdentityService.Did.all,
      // FIXME: globalPhrases really shouldn't be here, this object is utilized by .getEntityList()
      // and that function signature really isn't compatible with this value
      globalPhrases: PitchService.setupBase(),
      location: IdentityService.Location.list,
      pool: IdentityService.DidPool.list,
      realm: IdentityService.Realm.list,
      team: IdentityService.Team.list,
      user: IdentityService.User.manage.list,
    }

    this.update = {
      acl: IdentityService.Acl.fetch,
      campaign: IdentityService.Campaign.fetch,
      company: IdentityService.Company.fetch,
      location: IdentityService.Location.fetch,
      team: IdentityService.Team.fetch,
      user: IdentityService.User.manage.fetch,
    }

    this.dialogs = {
      accessListDetails: accessListDetailsDialog,
      campaignDetails: campaignDetailsDialog,
      userDetails: userDetailsDialog,
    }

    this._getEntityRelations = pipe(
      this._getRelationMethods.bind(this),
      this._extractRelationIds.bind(this),
    )
  }

  getAssignableGroups() {
    return this._Groups.list
      .get({ assignable: true })
      .then(groups => groups.plain())
  }

  getDialog(dialogName: string) {
    return this.dialogs[dialogName]
  }

  createEntity(toCreate: {
    entity: string,
    data: Object,
  }): RestangularPromise<*> {
    return this.create[toCreate.entity]
      .post(toCreate.data)
      .then(entity => entity.plain())
  }

  updateEntity(toUpdate: {
    entity: string,
    data: Object,
    method: 'PATCH' | 'PUT',
  }): RestangularPromise<Entity> {
    const { entity, data, method = 'PATCH' } = toUpdate
    let resource = this.update[entity].one(data.uuid)
    if (method === 'PUT') {
      return resource.customPUT(data).then(entity => entity.plain())
    } else {
      return resource.customPATCH(data).then(entity => entity.plain())
    }
  }

  findEntity(searchConfig: SearchConfig): RestangularPromise<?Entity[]> {
    const { entity, searchText = '', ...params } = searchConfig

    if (!searchText) {
      return this._$q.resolve([])
    }

    const {
      editable = false,
      limit = 5,
      groups = null,
      ...otherParams
    } = params
    return this.list[entity]
      .get({
        search: searchText,
        editable,
        limit,
        groups,
        ...otherParams,
      })
      .then(entity => {
        const res = entity.plain()
        return res.results || res
      })
  }

  getEntityList(
    params: Object,
    entity: EntityString,
  ): RestangularPromise<Entity[] | {count: number, results: Entity[]}> {
    return this.list[entity].get(params).then(entity => entity.plain())
  }

  createStateParams(
    entity: Entity,
    index: number,
  ) {
    let params = {
      entity,
      entityIndex: index,
      slug: undefined,
      uuid: undefined,
      username: undefined,
    }

    if (entity.slug && entity.uuid) {
      params.slug = entity.slug
      params.uuid = entity.uuid
    } else if (entity.username) {
      params.username = entity.username
    } else if (entity.uuid) {
      params.uuid = entity.uuid
    }
    return params
  }

  getEntityUser(id: string): RestangularPromise<User> {
    if (!id) return this._$q.resolve(null)
    return this._User.fetch
      .one(id)
      .get()
      .then(user => user.plain())
  }

  getEntityCompany(id: string): RestangularPromise<Company> {
    if (!id) return this._$q.resolve('No Company Association')
    return this._Company.fetch
      .one(id)
      .get()
      .then(company => company.plain())
  }

  getEntityTeam(id: string): RestangularPromise<Team> {
    if (!id) return this._$q.resolve('No Team Association')
    return this._Team.fetch
      .one(id)
      .get()
      .then(team => team.plain())
  }

  getEntityGroups(entityGroups: number[]): StoredPermissions {
    return this._Groups.list.get().then(groups => {
      return groups.plain().filter(group => contains(group.pk, entityGroups))
    })
  }

  getEntityCampaigns(campaignIds: string[]): RestangularPromise<Campaign[]> {
    const promisedCampaigns = campaignIds.map(campId => {
      return this._Campaign.fetch
        .one(campId)
        .get()
        .then(camp => camp.plain())
    })
    return this._$q.all(promisedCampaigns)
  }

  getEntityRealms(realmIds: string[]): RestangularPromise<Realm[]> {
    const promisedRealms = realmIds.map(realmId => {
      return this._Realm.fetch
        .one(realmId)
        .get()
        .then(realm => realm.plain())
    })
    return this._$q.all(promisedRealms)
  }

  _getRelationMethods(entity: Entity, entityName?: string = ''): RelationData {
    const entities = {
      user: {
        company: this.getEntityCompany.bind(this),
        campaigns: this.getEntityCampaigns.bind(this),
        team: this.getEntityTeam.bind(this),
        groups: this.getEntityGroups.bind(this),
      },
      campaign: {
        realms: this.getEntityRealms.bind(this),
        company: this.getEntityCompany.bind(this),
      },
      location: {
        leader: this.getEntityUser.bind(this),
      },
      team: {
        leader: this.getEntityUser.bind(this),
      },
      acl: {
        company: this.getEntityCompany.bind(this),
      },
      did: {
        company: this.getEntityCompany.bind(this),
        campaign: this.getEntityCampaigns.bind(this),
      },
    }

    return {
      entity,
      relations: entities[entityName],
    }
  }

  _extractRelationIds(
    relationData: RelationData,
  ): Array<{ key: string, uuid: string, method: Function }> {
    const entity = relationData.entity
    const relations = relationData.relations
    return Object.keys(relations).map((key: string) => {
      return {
        key,
        uuid: entity[key],
        method: relations[key],
      }
    })
  }

  _callRelationMethods(
    acc: { [string]: RestangularPromise<*> },
    relation: { key: string, uuid: string, method: Function },
  ) {
    acc[relation.key] = relation.method(relation.uuid)
    return acc
  }

  populateEntityRelations(
    entity: Entity,
    config: { entityName: EntityString, populate?: string[] },
  ) {
    const { entityName, populate = [] } = config
    const filterFunc = populate.length
      ? relation => contains(relation.key, populate)
      : T
    const relations = this._getEntityRelations(entity, entityName).filter(
      filterFunc,
    )
    const promisedRealtions = reduce(this._callRelationMethods, {}, relations)
    return this._$q
      .all(promisedRealtions)
      .then(populatedRelations => Object.assign({}, entity, populatedRelations))
  }

  getRelationOptions(
    optionsArray: string[],
    params: { editable: boolean },
  ): RestangularPromise<Entity[]> {
    const { editable = false } = params
    const promisedOptions = reduce(
      (acc, option) => {
        acc[option] = this.list[option]
          .get({ editable })
          .then(res => res.plain())
        return acc
      },
      {},
      optionsArray,
    )

    return this._$q.all(promisedOptions)
  }

  getEntityIndex(uuid: string, array: Entity[]) {
    return findIndex(propEq('uuid', uuid))(array)
  }

  updateEntityInList(
    index: number,
    entityList: Entity[],
    updatedEntity?: Entity,
  ) {
    if (index < 0) {
      return
    } else if (!entityList.length || !updatedEntity) {
      throw new UpdateListError('An array and update object must be passed')
    }
    entityList.splice(index, 1, updatedEntity)
  }
}
