// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import ActionButton from 'common-components/action-button/ActionButton'

const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
    message: {
        padding: '30px',
        fontColor: theme.palette.text.primary,
        '& p': {
            color: '#7c8a97',
            margin: '0 0 25px 0',
            fontWeight: 500,
            textAlign: 'center',
        },
    },
})

function CreateLoader({ classes, message, handleCancel } ) {
    return (
        <Grid container direction="column" className={classes.root}>
            <Grid
                alignItems="center"
                className={classes.message}
                container
                direction="column"
                justify="center"
            >
                <Grid item>
                    <p>{message}</p>
                </Grid>
                <Grid item>
                    <CircularProgress size={45} style={{ color: '#1194f6' }} />
                </Grid>
                <Grid item style={{ marginTop: '25px' }}>
                    <ActionButton
                        handleClick={handleCancel}
                        buttonColor="default">CANCEL</ActionButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(CreateLoader)
