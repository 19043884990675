import angular from 'angular'

// Services
import EditTeamService from './component/services/EditTeamService.js'

// components
import editTeam from './component/editTeam.component.js'

export default angular
  .module('portalApp.manage.editTeam', [])
  .service('EditTeamService', EditTeamService)
  .component('editTeam', editTeam)
  .name
