// @flow

import type { Did, DidPool } from 'types/entities.js'
import Papa from 'papaparse'

export default class ValidateDidsController {
  pool: DidPool
  TS: Object
  $mdDialog: Object
  DidPool: Object
  MDS: Object
  company: string
  campaign: string
  makeCSV: Object
  state: {
    validating: boolean,
    error: boolean,
  }
  $window: Object
  config: Object

  constructor(
    $q: Object,
    $mdDialog: Object,
    $state: Object,
    $window: Object,
    IdentityService: Object,
    ToastService: Object,
    ManageDidPoolService: Object,
  ) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.DidPool = IdentityService.DidPool
    this.TS = ToastService
    this.MDS = ManageDidPoolService
    this.$window = $window
    this.config = {
      entity: 'did',
      entityList: [],
      total: 0,
      tableConfig: {
        trackBy: 'uuid',
        columns: [
          { title: 'Number', accessor: 'number' },
          { title: 'Pool', accessor: 'did_pool_name' },
          {
            title: 'Expected CNam',
            accessor: 'expected_cnam',
            type: 'boolean',
          },
          { title: 'Actual CNam', accessor: 'actual_cnam' },
          { title: '# Complaints', accessor: 'complaints_num' },
        ],
      },
    }

    this.state = {
      validating: true,
      error: false,
      queryCount: 0,
    }
  }

  $onInit() {
    this.validate()
  }

  download(dids: Did[]): { fileName: string, data: string } {
    return {
      fileName: 'invalid_dids.csv',
      data: Papa.unparse({
        fields: ['number', 'timezone', 'did_pool_name', 'uuid'],
        data: dids,
      }),
    }
  }

  validate() {
    let uriData = {
      company: this.company,
      campaign: this.campaign,
      pool_uuid: this.pool.uuid,
    }

    this.state.validating = true
    this.DidPool.cnam(uriData)
      .then(data => data.plain())
      .then(this.loadResults.bind(this))
      .catch(error => {
        this.state.validating = false
      })
      .finally(() => {
        this.state.validating = false
      })
  }

  loadResults(dids: Did[]) {
    this.config.entityList = dids
    this.config.total = dids.length
    this.makeCSV = this.download.bind(null, dids)
    // TODO delete the below code and make backend retrieve the real data
    for (let i = 0; i < this.config.entityList.length; i++) {
      this.config.entityList[i].expected_cnam = false
      this.config.entityList[i].complaints_num = '0'
    }
  }

  close(callback: Array<Did>) {
    callback ? this.$mdDialog.hide({ callback }) : this.$mdDialog.cancel()
  }
}
