const listMetricsConfig = [
  'sales',
  'calls',
  'conversionRate',
  'contactRate',
  'talkTime',
  'contacted',
  'invalidCalls',
  'answerSeizureRatio'
]

export { listMetricsConfig }
