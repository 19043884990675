import './pitchOptionGroup.scss'
import PitchOptionGroupController from './controller/PitchOptionGroupController.js'
const template = require('./pitchOptionGroup.component.tpl.pug')

const pitchOptionGroup = {
  controller: PitchOptionGroupController,
  template: template(),
  bindings: {
    group: '<',
    baseService: '<',
    removeGroup: '&',
  }
}

export default pitchOptionGroup

