const identifiers = {
  key: 'speedToLead',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Speed To Lead',
    append: ' Hours',
  }
}

const keys = {
  componentKeys: [],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divideNormalized',
  dividend: { stat: 'speed_to_lead', convertTo: 'hours' },
  divisor: { stat: 'calls' },
}

const speedToLead = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { speedToLead }
