export default class DidListService {
  constructor($q, Restangular, RCRestangular, AWSRestangular) {
    'ngInject'

    this.did = Restangular.all('did/')
    this.rcDid = RCRestangular.all('did/')
    this.awsDid = AWSRestangular.all('api/')

    this.buyDid = {
      post: (data, uriData) => {
        if (!uriData.company || !uriData.campaign) {
          return $q.reject('Missing uri data')
        }

        const uri = [
          'company',
          uriData.company.slug,
          'campaign',
          uriData.campaign.slug,
          'pool',
          uriData.pool.uuid,
          'did/rotate',
        ].join('/')
        return this.did.one(`${uri}/`).customPOST(data)
      },
    }

    this.searchList = {
      post: (data, uriData) => {
        if (!uriData.company || !uriData.campaign) {
          return $q.reject('Missing uri data')
        }

        const uri = [
          'company',
          uriData.company.slug || 'all',
          'campaign',
          uriData.campaign.slug || 'all',
          'did/search_list',
        ].join('/')
        return this.did.one(`${uri}/`).customPOST(data)
      },
    }

    this.buyDidList = {
      post: (data, uriData) => {
        if (!uriData.company || !uriData.campaign) {
          return $q.reject('Missing uri data')
        }

        const uri = [
          'company',
          uriData.company.slug || 'all',
          'campaign',
          uriData.campaign.slug || 'all',
          'did/buy_list',
        ].join('/')
        return this.did.one(`${uri}/`).customPOST(data)
      },
    }

    this.buyMassiveDids = {
      post: (data, uriData) => {
        if (!uriData.company || !uriData.campaign) {
          return $q.reject('Missing uri data')
        }

        /*const uri = [
          'company',
          uriData.company.slug || 'all',
          'campaign',
          uriData.campaign.slug || 'all',
          'pool',
          uriData.pool,
          'did/massive-did-purchase',
        ].join('/')*/
        const uri = 'buy'
        return this.awsDid.one(`${uri}/`).customPOST(data)
      },
    }

    this.getStatesCountries = {
      get: () => {
        return this.rcDid.one(`area-codes/`).get()
      },
    }

    this.createPool = {
      post: (data, uriData) => {
        if (!uriData.company || !uriData.campaign) {
          return $q.reject('Missing uri data')
        }

        const uri = [
          'company',
          uriData.company.slug,
          'campaign',
          uriData.campaign.slug,
          'pool',
        ].join('/')
        return this.did.one(`${uri}/`).customPOST(data)
      },
    }

    this.create = {
      post: (data, uriData) => {
        let uri = [
          'company',
          uriData.company || 'all',
          'campaign',
          uriData.campaign || 'all',
          'pool',
          uriData.pool,
          'did',
        ].join('/')
        return this.did.one(`${uri}/`).customPOST(data)
      },
      patch: data => {
        let { company, campaign, pool, nodeData } = data
        let uriParts = [
          'company',
          company || 'all',
          'campaign',
          campaign || 'all',
          'pool',
          pool,
          'did',
        ]
        let uri = uriParts.join('/')
        return this.did.one(`${uri}/`).customPATCH(nodeData)
      },
    }

    this.one = {
      one: data => {
        let { company, campaign, pool, uuid } = data
        let uriParts = [
          'company',
          company,
          'campaign',
          campaign,
          'pool',
          pool,
          'did',
          uuid,
        ]
        let uri = uriParts.join('/')
        return this.did.one(`${uri}/`)
      },
    }

    this.editPool = {
      patch: (data, uriData) => {
        if (!uriData.company || !uriData.campaign || !uriData.uuid) {
          return $q.reject('Missing uri data')
        }

        const uri = [
          'company',
          uriData.company.slug,
          'campaign',
          uriData.campaign.slug,
          'pool',
          uriData.uuid,
        ].join('/')
        return this.did.one(`${uri}/`).customPATCH(data)
      },
    }

    this.edit = {
      patch: (data, uriData) => {
        const { company, campaign, pool, uuid } = uriData
        const uri = [
          'company',
          company,
          'campaign',
          campaign,
          'pool',
          pool,
          'did',
          uuid,
        ].join('/')
        return this.did.one(`${uri}/`).customPATCH(data)
      },
      put: (data, uriData) => {
        const { company, campaign, pool, uuid } = uriData
        const uri = [
          'company',
          company,
          'campaign',
          campaign,
          'pool',
          pool,
          'did',
          uuid,
        ].join('/')
        return this.did.one(`${uri}/`).customPUT(data)
      },
    }

    this.listVoiceProvider = {
      get: data => {
        let resource = this.did.all('voice_provider/')
        return resource.getList()
      },
    }

    this.listAll = {
      get: data => {
        const { company, campaign, active, search } = data
        const uri = [
          'company',
          company || 'all',
          'campaign',
          campaign || 'all',
          'did',
        ].join('/')
        return this.did.all(uri).getList({
          active,
          search,
        })
      },
    }

    this.list = {
      get: data => {
        const { company, campaign, pool, search = null, rotated = null } = data
        const uri = [
          'company',
          company || 'all',
          'campaign',
          campaign || 'all',
          'pool',
          pool,
          'did',
        ].join('/')
        return this.did.all(uri).getList({ search, rotated })
      },
    }

    this.listPool = {
      get: (data = {}) => {
        const { company, campaign, search } = data
        const uri = [
          'company',
          company || 'all',
          'campaign',
          campaign || 'all',
          'pool',
        ].join('/')
        let resource = this.did.all(uri)
        return resource.getList({
          search,
        })
      },
      one: uuid => {
        let uriParts = ['company', 'all', 'campaign', 'all', 'pool', uuid]
        let uri = uriParts.join('/')
        return this.did.one(`${uri}/`).get()
      },
    }

    this.onePool = {
      one: uuid => {
        let uriParts = ['company', 'all', 'campaign', 'all', 'pool', uuid]
        let uri = uriParts.join('/')
        return this.did.one(`${uri}/`)
      },
    }

    this.onePoolCnam = {
      get: (data = {}) => {
        const { company, campaign, pool_uuid } = data
        const uri = [
          'company',
          company || 'all',
          'campaign',
          campaign || 'all',
          'pool',
          pool_uuid,
          'cnam',
        ].join('/')
        let resource = this.did.all(uri)
        return resource.getList()
      },
    }

    this.statesCountriesAreaCodes = {
      get: () => {
        return [
          {
            "state": "Alabama",
            "state_short_name": "AL",
            "area_codes": "205, 251, 256, 334, 938"
          },
          {
            "state": "Alaska",
            "state_short_name": "AK",
            "area_codes": "907"
          },
          {
            "state": "Arizona",
            "state_short_name": "AZ",
            "area_codes": "480, 520, 602, 623, 928"
          },
          {
            "state": "Arkansas",
            "state_short_name": "AR",
            "area_codes": "479, 501, 870"
          },
          {
            "state": "Colorado",
            "state_short_name": "CO",
            "area_codes": "303, 719, 720, 970"
          },
          {
            "state": "Connecticut",
            "state_short_name": "CT",
            "area_codes": "203, 475, 860, 959"
          },
          {
            "state": "Delaware",
            "state_short_name": "DE",
            "area_codes": "302"
          },
          {
            "state": "Georgia",
            "state_short_name": "GA",
            "area_codes": "229, 404, 470, 478, 678, 706, 762, 770, 912"
          },
          {
            "state": "Hawaii",
            "state_short_name": "HI",
            "area_codes": "808"
          },
          {
            "state": "Idaho",
            "state_short_name": "ID",
            "area_codes": "208, 986"
          },
          {
            "state": "Indiana",
            "state_short_name": "IN",
            "area_codes": "219, 260, 317, 463, 574, 765, 812, 930"
          },
          {
            "state": "Iowa",
            "state_short_name": "IA",
            "area_codes": "319, 515, 563, 641, 712"
          },
          {
            "state": "Kansas",
            "state_short_name": "KS",
            "area_codes": "316, 620, 785, 913"
          },
          {
            "state": "Kentucky",
            "state_short_name": "KY",
            "area_codes": "270, 364, 502, 606, 859"
          },
          {
            "state": "Louisiana",
            "state_short_name": "LA",
            "area_codes": "225, 318, 337, 504, 985"
          },
          {
            "state": "Maine",
            "state_short_name": "ME",
            "area_codes": "207"
          },
          {
            "state": "Maryland",
            "state_short_name": "MD",
            "area_codes": "240, 301, 410, 443, 667"
          },
          {
            "state": "Massachusetts",
            "state_short_name": "MA",
            "area_codes": "339, 351, 413, 508, 617, 774, 781, 857, 978"
          },
          {
            "state": "Michigan",
            "state_short_name": "MI",
            "area_codes": "231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989"
          },
          {
            "state": "Minnesota",
            "state_short_name": "MN",
            "area_codes": "218, 320, 507, 612, 651, 763, 952"
          },
          {
            "state": "Mississippi",
            "state_short_name": "MS",
            "area_codes": "228, 601, 662, 769"
          },
          {
            "state": "Missouri",
            "state_short_name": "MO",
            "area_codes": "314, 417, 573, 636, 660, 816"
          },
          {
            "state": "Montana",
            "state_short_name": "MT",
            "area_codes": "406"
          },
          {
            "state": "Nebraska",
            "state_short_name": "NE",
            "area_codes": "308, 402, 531"
          },
          {
            "state": "Nevada",
            "state_short_name": "NV",
            "area_codes": "702, 725, 775"
          },
          {
            "state": "New Hampshire",
            "state_short_name": "NH",
            "area_codes": "603"
          },
          {
            "state": "New Jersey",
            "state_short_name": "NJ",
            "area_codes": "201, 551, 609, 640, 732, 848, 856, 862, 908, 973"
          },
          {
            "state": "New Mexico",
            "state_short_name": "NM",
            "area_codes": "505, 575"
          },
          {
            "state": "New York",
            "state_short_name": "NY",
            "area_codes": "212, 315, 332, 347, 516, 518, 585, 607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934"
          },
          {
            "state": "North Carolina",
            "state_short_name": "NC",
            "area_codes": "252, 336, 704, 743, 828, 910, 919, 980, 984"
          },
          {
            "state": "North Dakota",
            "state_short_name": "ND",
            "area_codes": "701"
          },
          {
            "state": "Ohio",
            "state_short_name": "OH",
            "area_codes": "216, 220, 234, 330, 380, 419, 440, 513, 567, 614, 740, 937"
          },
          {
            "state": "Oklahoma",
            "state_short_name": "OK",
            "area_codes": "405, 539, 580, 918"
          },
          {
            "state": "Oregon",
            "state_short_name": "OR",
            "area_codes": "458, 503, 541, 971"
          },
          {
            "state": "Pennsylvania",
            "state_short_name": "PA",
            "area_codes": "215, 223, 267, 272, 412, 445, 484, 570, 610, 717, 724, 814, 878"
          },
          {
            "state": "Rhode Island",
            "state_short_name": "RI",
            "area_codes": "401"
          },
          {
            "state": "South Carolina",
            "state_short_name": "SC",
            "area_codes": "803, 843, 854, 864"
          },
          {
            "state": "South Dakota",
            "state_short_name": "SD",
            "area_codes": "605"
          },
          {
            "state": "Tennessee",
            "state_short_name": "TN",
            "area_codes": "423, 615, 629, 731, 865, 901, 931"
          },
          {
            "state": "Texas",
            "state_short_name": "TX",
            "area_codes": "210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512, 682, 713, 726, 737, 806, 817, 830, 832, 903, 915, 936, 940, 956, 972, 979"
          },
          {
            "state": "Utah",
            "state_short_name": "UT",
            "area_codes": "385, 435, 801"
          },
          {
            "state": "Vermont",
            "state_short_name": "VT",
            "area_codes": "802"
          },
          {
            "state": "Virginia",
            "state_short_name": "VA",
            "area_codes": "276, 434, 540, 571, 703, 757, 804"
          },
          {
            "state": "Washington",
            "state_short_name": "WA",
            "area_codes": "206, 253, 360, 425, 509, 564"
          },
          {
            "state": "Washington, DC",
            "state_short_name": "DC",
            "area_codes": "202"
          },
          {
            "state": "West Virginia",
            "state_short_name": "WV",
            "area_codes": "304, 681"
          },
          {
            "state": "Wisconsin",
            "state_short_name": "WI",
            "area_codes": "262, 414, 534, 608, 715, 920"
          },
          {
            "state": "Wyoming",
            "state_short_name": "WY",
            "area_codes": "307"
          }
        ]
      }
    }
  }
}