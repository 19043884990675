import EditPhraseController from './controller/EditPhraseController.js'
import template from './editPhraseForm.tpl.pug'

const editPhraseForm = {
  template: template(),
  controller: EditPhraseController,
  bindings: {
    service: '<',
    phrase: '<',
  },
}

export default editPhraseForm
