import './component/editCampaign.scss'
import angular from 'angular'
import { react2angular } from 'react2angular'

// Services
import EditCampaignService from './services/EditCampaignService.js'
import EditPitchService from './services/EditPitchService.js'

// components
import editCampaign from './component/editCampaign.component.js'
import campaignSettings from './sub-components/campaign-settings/campaignSettings.component.js'
import campaignPitch from './sub-components/campaign-pitch/campaignPitch.component.js'
import campaignDataPosting from './sub-components/campaign-data-posting/campaignDataPosting.component.js'
import pitchSegments from './sub-components/pitch-segments/pitchSegments.component.js'
import pitchDetails from './sub-components/pitch-details/pitchDetails.component.js'
import pitchVoices from './sub-components/pitch-voices/pitchVoices.component.js'
import pitchXml from './sub-components/pitch-xml/pitchXml.component.js'
import pitchPanels from './sub-components/pitch-panels/pitchPanels.component.js'
import routerInfoForm from './sub-components/router-info/component/routerInfoForm.component.js'
import pitchPanel from './sub-components/pitch-panels/sub-components/pitch-panel/pitchPanel.component.js'
import pitchOptionGroups from './sub-components/pitch-option-groups/pitchOptionGroups.component.js'
import pitchOptionGroup from './sub-components/pitch-option-groups/sub-components/pitch-option-group/pitchOptionGroup.component.js'
import pitchPhraseBooks from './sub-components/pitch-phrase-books/pitchPhraseBooks.component.js'
import pitchVariables from './sub-components/pitch-variables/pitchVariables.component'
import pitchRapidResponse from './sub-components/pitch-rapid-response/pitchRapidResponse.component.js'

// react components
import campaignDialer from './sub-components/campaign-dialer/react-dialer.js'
import campaignChangelog from './sub-components/campaign-changelog/changelog.js'
import campaignCalls from './sub-components/campaign-calls/react-calls.js'
import campaignIvr from './sub-components/campaign-ivr/react-ivr.js'
import campaignWarms from './sub-components/campaign-warms/react-warms.js'
import campaignQa from './sub-components/campaign-qa/react-qa.js'

export default angular
  .module('portalApp.manage.editCampaign', [])
  .service('EditCampaignService', EditCampaignService)
  .service('EditPitchService', EditPitchService)
  .component('editCampaign', editCampaign)
  .component('campaignSettings', campaignSettings)
  .component('campaignPitch', campaignPitch)
  .component('campaignDataPosting', campaignDataPosting)
  .component('pitchSegments', pitchSegments)
  .component('pitchDetails', pitchDetails)
  .component('pitchVoices', pitchVoices)
  .component('pitchXml', pitchXml)
  .component('pitchPanels', pitchPanels)
  .component('pitchPanel', pitchPanel)
  .component('pitchOptionGroups', pitchOptionGroups)
  .component('pitchOptionGroup', pitchOptionGroup)
  .component('pitchPhraseBooks', pitchPhraseBooks)
  .component('routerInfoForm', routerInfoForm)
  .component('pitchVariables', pitchVariables)
  .component('campaignDialer', react2angular(campaignDialer))
  .component('campaignChangelog', react2angular(campaignChangelog))
  .component('campaignCalls', react2angular(campaignCalls))
  .component('campaignIvr', react2angular(campaignIvr))
  .component('campaignWarms', react2angular(campaignWarms))
  .component('campaignQa', react2angular(campaignQa))
  .component('pitchRapidResponse', pitchRapidResponse).name
