import './dialogs/detailsDialog.scss'

// component
import manage from './component/manage.js'

// Modules
import manageCampaigns from './manage-campaigns/manageCampaigns.js'
import editCampaign from './edit-campaign/editCampaign.js'
import editCompany from './edit-company/editCompany.js'
import editCampaignRouter from './edit-campaign-router/editCampaignRouter.js'
import editAccessList from './edit-access-list/editAccessList.js'
import editAccessListNode from './edit-access-list-node/editAccessListNode'
import editVoices from './edit-voices/editVoices'
import editSegmentVariable from './edit-segment-variables/editSegmentVariables'
import manageCompanies from './manage-companies/manageCompanies.js'
import manageLocations from './manage-locations/manageLocations.js'
import editLocation from './edit-location/editLocation.js'
import editRealms from './edit-realms/manageRealmsEdit.js'
import QAprospectSettings from './edit-campaign-qa-prospect/qaProspectSettings.js'
import manageRealms from './manage-realms/manageRealms.js'
import manageStations from './manage-stations/manageStations.js'
import manageTeams from './manage-teams/manageTeams.js'
import editTeam from './edit-team/editTeam.js'
import manageUsers from './manage-users/manageUsers.js'
import manageDidPool from './manage-did-pool/manageDidPool.js'
import manageDid from './manage-did/manageDid.js'
import manageDnc from './manage-dnc/manageDnc.js'
import editDidPool from './edit-did-pool/editDidPool.js'
import manageAccessLists from './manage-access-lists/manageAccessLists.js'
import managePitchSegments from './manage-global-pitch-segments/manageGlobalPitchSegments'
import editPhraseBook from './edit-phrase-book/editPhraseBook'
import editPhraseBookPhrase from './edit-phrase-book-phrase/editPhraseBookPhrase'
import manageGlobalOptionGroup from './manage-global-option-group/manageGlobalOptionGroup'
import manageGlobalPitchPhraseBooks from './manage-global-pitch-phrasebooks/manageGlobalPitchPhraseBooks'
import editGlobalOptionGroups from './edit-global-option-groups/editGlobalOptionGroups'
import manageGlobalRapidResponse from './manage-global-rapid-response/manageGlobalRapidResponse'
import manageAudioResources from './manage-audio-resources/manageAudioResources.js'
import manageAudioVoice from './manage-audio-voice/manageAudioVoice.js'
import editRapidResponseTests from './edit-rapid-response-tests/editRapidResponseTests'

// Services
import ManageMenuService from './services/ManageMenuService.js'
import ManagementService from './services/ManagementService.js'
import ManageAudioService from './services/ManageAudioService.js'

// Factories
import managementFilterFactory from './factories/managementFilterFactory.js'

// common components
import rightDrawer from './common-components/right-drawer/component/rightDrawer.component.js'
import rightDrawerHeader from './common-components/right-drawer-header/component/rightDrawerHeader.component.js'
import managementSearch from './common-components/management-search/component/managementSearch.component.js'
import managementAsyncTable from './common-components/management-async-table/component/managementAsyncTable.component.js'
import editEntityToolbar from './common-components/edit-entity-toolbar/component/editEntityToolbar.component.js'
import filterToolbar from './common-components/filter-toolbar/component/filterToolbar.component.js'
import voiceAudioTable from './common-components/voice-audio-table/voiceAudioTable.component.js'
import micPermissionOverlay from './common-components/mic-permission-overlay/micPermissionOverlay.component.js'
import manageVoices from './common-components/manage-voices/component/manageVoices.component'
import editVariables from './common-components/edit-variables/component/editVariables'
import manageSettingsForm from './common-components/manage-settings-form/component/manageSettingsForm.component'

export default angular
  .module('portalApp.manage', [
    manageCampaigns,
    editCampaign,
    editCompany,
    editCampaignRouter,
    editAccessList,
    editAccessListNode,
    editPhraseBook,
    editPhraseBookPhrase,
    editGlobalOptionGroups,
    editVoices,
    editSegmentVariable,
    editRapidResponseTests,
    manageCompanies,
    manageLocations,
    editLocation,
    editRealms,
    QAprospectSettings,
    manageRealms,
    manageStations,
    manageTeams,
    editTeam,
    manageUsers,
    manageAccessLists,
    manageAudioResources,
    manageAudioVoice,
    managePitchSegments,
    manageGlobalOptionGroup,
    manageGlobalPitchPhraseBooks,
    manageGlobalRapidResponse,
    manageDidPool,
    manageDid,
    manageDnc,
    editDidPool,
  ])
  .factory('managementFilterFactory', managementFilterFactory)
  .service('ManageMenuService', ManageMenuService)
  .service('ManagementService', ManagementService)
  .service('ManageAudioService', ManageAudioService)
  .component('manage', manage)
  .component('rightDrawer', rightDrawer)
  .component('rightDrawerHeader', rightDrawerHeader)
  .component('managementSearch', managementSearch)
  .component('managementAsyncTable', managementAsyncTable)
  .component('editEntityToolbar', editEntityToolbar)
  .component('filterToolbar', filterToolbar)
  .component('voiceAudioTable', voiceAudioTable)
  .component('micPermissionOverlay', micPermissionOverlay)
  .component('manageVoices', manageVoices)
  .component('editVariables', editVariables)
  .component('manageSettingsForm', manageSettingsForm).name
