import { mergeWithKey, reduce } from 'ramda'

function divisorOrDividend(left, right) {
  const oldVal = left.value || 0
  const newVal = right.value || 0
  const name = left.name
  const combined = oldVal + newVal
  return {
    name,
    value: combined,
  }
}

function mergeAndCalcReps(key, left, right) {
  switch (key) {
    case 'y':
      return left + right
    case 'days':
      return left.concat(right)
    case 'dialed':
      return left + right
    case 'dividend':
      return divisorOrDividend(left, right)
    case 'divisor':
      return divisorOrDividend(left, right)
    default:
      return left
  }
}

function combineRepObjects(past, current) {
  const pastKeys = Object.keys(past)
  const currentKeys = Object.keys(current)
  const repKeys = new Set([...pastKeys, ...currentKeys])
  return reduce(
    (acc, key) => {
      const addFrom = past[key] || {}
      const addTo = current[key] || {}
      acc[key] = mergeWithKey(mergeAndCalcReps, addFrom, addTo)
      return acc
    },
    {},
    repKeys,
  )
}

export { combineRepObjects }
