// @flow

import type { PermissionGroup } from 'permissions.js'
import type { FilterConfig } from '../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../common-components/management-search/component/managementSearch.component.js'

import { COMPANY_MANAGER_GROUP } from 'permissions.js'

export default class ManageCompaniesService {
  defaultEditRoles: PermissionGroup

  constructor() {
    'ngInject'
    this.defaultEditRoles = COMPANY_MANAGER_GROUP
  }

  // Using getters makes it so we don't have to worry about mutation side-effects where these are used

  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 5,
      helperText: 'Try searching for a company name, uuid or realm',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Sort By',
        field: 'order_by',
        defaultValue: '-datetime_modified',
        options: [
          { title: 'Recently Edited', value: '-datetime_modified' },
          { title: 'Name A-Z', value: 'name' },
          { title: 'Name Z-A', value: '-name' },
        ],
      },
      {
        label: 'Active Status',
        field: 'active',
        defaultValue: null,
        options: [
          { title: 'All', value: null },
          { title: 'Active', value: true },
          { title: 'Inactive', value: false },
        ],
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Name', accessor: 'name', type: 'text' },
        { title: 'Slug', accessor: 'slug', type: 'text' },
        { title: 'Email', accessor: 'email', type: 'text' },
        { title: 'Active', accessor: 'active', type: 'active' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editOrView',
        },
      ],
      optionalColumns: [{ title: 'UUID', accessor: 'uuid', type: 'text' }],
    }
  }
  getCompanyManagementConfig() {
    return {
      entity: 'company',
      entityList: [],
      defaultEditRoles: [
        'SUPER_USER',
        'REALM_ADMIN',
        'CLIENT_MANAGER',
        'COMPANY_MANAGER',
      ],
      editState: 'manage.editCompany.settings',
      createState: 'manage.companies.create.companyInfo',
      detailsDialog: null,
    }
  }
}
