import React, {useState, useEffect} from 'react'
import axios from "axios"
import {getBaseUrl} from '../../../global/config/providers/restangularProvider/rest.js'

import DeleteDialog from 'common-components/delete-dialog/DeleteDialog'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'
import CreateLoader from 'common-components/create-loader/CreateLoader'

import { Dialog} from '@material-ui/core'

const API_BASE_URL = getBaseUrl(window.location.host, true)
const CancelToken = axios.CancelToken;
let cancel;

const DeleteStation = props => {
    const [confirmation, setConfirmation] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDelete = () => {
        setLoadingDelete(true);
        axios.
            delete(`${API_BASE_URL}/identity/station/${props.station.uuid}/`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(response => {
                setLoadingDelete(false);
                setConfirmation(true);
                props.handleDelete();
            })
            .catch(console.error)
    }

    const handleCancelDelete = () => {
        console.log("cancel creation");
        setLoadingDelete(false);
        cancel();
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.deleteDialogOpen}
                onClose={props.cancelDeleteDialog}
                aria-labelledby="delete-campaign-dialog"
            >
                {confirmation ?

                    <ConfirmationDialog
                        message={'You have removed "' + props.station.username +
                            '" Station'}
                        SubmitTxt="CREATE STATION"
                        handleCancel={props.cancelAnotherDialog}
                        handleCreateAnother={props.openAdd} />
                    :
                    loadingDelete ?
                        <React.Fragment>
                            <CreateLoader
                                message="One moment. We're removing the station..."
                                handleCancel={handleCancelDelete} />
                        </React.Fragment> :
                        <DeleteDialog
                            message={'Are you sure you want to remove "' + props.station.username +
                                '" Station?'}
                            btnTxt="YES, DELETE"
                            handleCancel={props.cancelDeleteDialog}
                            handleRemove={handleDelete}
                        />
                }
            </Dialog>
        </React.Fragment>
    )
}

export default DeleteStation