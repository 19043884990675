import RouterInfoFormController from './controller/RouterInfoController.js'
const template = require('./routerInfoForm.component.tpl.pug')

const routerInfoForm = {
  template: template(),
  controller: RouterInfoFormController,
  bindings: {
    campaign: '<'
  }
}

export default routerInfoForm
