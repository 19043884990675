import './sideNavLink.scss'
import SideNavLinkController from './controller/SideNavLinkController.js'
const template = require('./sideNavLink.tpl.pug')

const sideNavLink = {
  template: template(),
  controller: SideNavLinkController,
  bindings: {
    page: '<',
    pageIndex: '<',
    activeIndex: '=',
  },
}

export default sideNavLink
