import { MANAGE_AUDIO_VOICE } from '../manage.audioVoice.js'

// manage voice pitch audio view
// state will be | manage.audioVoice.pitchAudio
const MANAGE_VOICE_PITCH_AUDIO = `${MANAGE_AUDIO_VOICE}.pitchAudio`

const pitchAudioConfig = {
  url: '/pitch',
  component: 'pitchAudio',
  resolve: {
    user: function ($sessionStorage) {
      'ngInject'
      return $sessionStorage.user
    }
  }
}

export { MANAGE_VOICE_PITCH_AUDIO, pitchAudioConfig }

