// @flow

import { complement, isNil } from 'ramda'
import roundTo from 'round-to'

const isNotNil = complement(isNil)
const getTotalPages = (totalEntries: number, paginationLimit: number): number => roundTo.up(totalEntries / paginationLimit, 0)

function hasValue(changeObj: any): boolean {
  return isNotNil(changeObj) && isNotNil(changeObj.currentValue)
}

function getSelectablePages(currentPage: number, splitPages: Array<number[]>): number[] {
  const position = currentPage / 4
  const adjustAperture = ((position % 1) === 0)
  const splitIndex = adjustAperture ? (position - 1) : roundTo.down(position, 0)
  return splitPages[splitIndex]
}

export {
  getTotalPages,
  getSelectablePages,
  hasValue,
}
