// dashboard.goals view

const dashboardAgents = 'dashboard.agents'

const dashboardAgentConfig = {
  url: '/agents',
  component: 'dashboardAgents',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign, RealmService) {
      'ngInject'
      return selectedCampaign
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    }
  }
}

export { dashboardAgents, dashboardAgentConfig }
