import CompanyAudioResourcesController from './controller/CompanyAudioResourcesController'
const template = require('./companyAudioResources.component.tpl.pug')

const companyAudioResources = {
  template: template(),
  controller: CompanyAudioResourcesController,
  bindings: {
    company: '<'
  }
}

export default companyAudioResources

