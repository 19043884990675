// create campaign router view

const manageEditCompanyPhraseBookPhraseCreate =
  'manage.editCompanyPhraseBook.phrase.create'

const manageEditCompanyPhraseBookPhraseCreateConfig = {
  params: {
    service: null,
  },
  component: 'createPhraseForm',
  resolve: {
    service: function($transition$) {
      'ngInject'
      return $transition$.params().service
    },
  },
}

export {
  manageEditCompanyPhraseBookPhraseCreate,
  manageEditCompanyPhraseBookPhraseCreateConfig,
}
