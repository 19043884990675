import { MANAGE_GROUP } from 'permissions.js'

// Edit Campaign Router view

const manageCampaignRouter = 'manage.campaignRouter'

const manageCampaignRouterConfig = {
  url: '/edit/router/{campaignId}/{routerId}',
  params: {
    router: null,
    campaign: null,
  },
  data: {
    permissions: {
      only: [...MANAGE_GROUP],
      redirectTo: 'gateway',
    },
  },
  component: 'editCampaignRouter',
  resolve: {
    campaign: function($transition$, IdentityService, ManagementService) {
      'ngInject'

      const campaign = $transition$.params().campaign

      if (campaign) return campaign
      return IdentityService.Campaign.fetch
        .one($transition$.params().campaignId)
        .get()
        .then(campaign =>
          ManagementService.populateEntityRelations(campaign, {
            entityName: 'campaign',
          }),
        )
    },
    router: function($transition$, ProspectService, campaign) {
      'ngInject'

      const router = $transition$.params().router

      if (router) return router
      return ProspectService.router
        .one(
          `${campaign.company.slug}/${campaign.slug}/${
            $transition$.params().routerId
          }`,
        )
        .get()
        .then(router => router.plain())
    },
    statusCodes: function() {
      return import('statuses').then(statuses =>
        statuses.default.codes.map(code => code.toString()),
      )
    },
  },
}

export { manageCampaignRouter, manageCampaignRouterConfig }
