// @flow

import type { Element, RestangularPromise } from 'restangular'
import type { User, Pitch } from 'types/entities.js'

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService from '../../../../../global/index/api-services/IdentityService.js'
import type ToastService from '../../../../../global/index/services/ToastService.js'

import { equals, pipe } from 'ramda'
import { hydrateVoices, recorderGroupFinder } from 'util/voices.js'

export default class ManageVoicesController {
  Groups: Element
  Users: Element
  TS: ToastService
  $state: Object
  voices: string[]
  campaign: string
  voiceUsers: User[]
  state: {
    activeVoices: {
      initial: User[],
      editable: User[],
    },
    availableVoices: {
      initial: User[],
      editable: User[],
    },
  }
  voicesEqual: (User[], User[]) => boolean
  onUpdate: ({ fields: { voices: string[] } }) => RestangularPromise<Pitch>

  constructor(
    IdentityService: IdentityService,
    ToastService: ToastService,
    $state: Object,
  ) {
    'ngInject'

    this.Groups = IdentityService.Groups.list
    this.Users = IdentityService.User.manage.list
    this.TS = ToastService
    this.$state = $state

    this.voiceUsers = []
    this.state = {
      activeVoices: {
        initial: [],
        editable: [],
      },
      availableVoices: {
        initial: [],
        editable: [],
      },
    }

    this.voicesEqual = function(arr1, arr2) {
      return equals(arr1, arr2)
    }
  }

  $onChanges(bindings: Object) {
    // voices is fetched by a parent component async so won't be available right away
    if (bindings.voices.isFirstChange()) {
      this.getVoices(this.voices, this.campaign)
    } else if (bindings.voices.currentValue) {
      this.syncVoices(this.voices, [...this.voiceUsers])
    }
  }

  syncVoices(voiceIds: string[], voiceUsers: User[]) {
    return pipe(
      hydrateVoices(voiceIds),
      this.assignVoices.bind(this),
    )(voiceUsers)
  }

  getVoices(voiceIds: string[], campaignId: string) {
    let params = campaignId ? { campaigns: campaignId } : {}
    this.Groups.get()
      .then(groups => groups.plain())
      .then(recorderGroupFinder)
      .then(group =>
        this.Users.get({
          groups: group.pk,
          ...params,
        }),
      )
      .then(voices => {
        this.voiceUsers = [...voices.results]
        return voices.results
      })
      .then(hydrateVoices(voiceIds))
      .then(this.assignVoices.bind(this))
      .catch(err => {
        this.TS.show({
          text: 'Error fetching voices',
        })
        console.error(err)
      })
  }

  assignVoices(voices: { active: User[], available: User[] }) {
    Object.assign(this.state, {
      availableVoices: {
        initial: [...voices.available],
        editable: [...voices.available],
      },
      activeVoices: {
        initial: [...voices.active],
        editable: [...voices.active],
      },
    })
  }

  addVoice(index: number, voice: User) {
    this.state.availableVoices.editable.splice(index, 1)
    this.state.activeVoices.editable.push(voice)
  }

  removeVoice(index: number, voice: User) {
    this.state.activeVoices.editable.splice(index, 1)
    this.state.availableVoices.editable.push(voice)
  }

  recordProspectVoiceRedirection(voice: User) {
    const params = {
      voice: voice,
    }
    this.$state.go('manage.audioVoice.prospectAudio', params)
  }

  cancelMove() {
    this.state.activeVoices.editable = [...this.state.activeVoices.initial]
    this.state.availableVoices.editable = [
      ...this.state.availableVoices.initial,
    ]
  }

  updateVoices(activeVoices: User[]) {
    this.onUpdate({
      fields: {
        voices: activeVoices.map(voice => voice.uuid),
      },
    }).catch(err => {
      this.TS.show({
        text: 'Error updating pitch voices',
      })
      console.error(err)
    })
  }
}
