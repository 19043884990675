import './manageSettingsForm.scss'
import ManageSettingsFormController from './controller/ManageSettingsFormController.js'
const template = require('./manageSettingsForm.component.tpl.pug')

const manageSettingsForm = {
  template: template(),
  controller: ManageSettingsFormController,
  bindings: {
    entity: '<',
    options: '<',
    entityName: '<',
    hasDelete: '<',
    flexSize: '<',
    inputFlex: '<',
    setPristine: '<',
    onDeleteEntity: '&',
    onSave: '&',
    isDialog: '<',
  },
}

export default manageSettingsForm
