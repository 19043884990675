import React, { useState, useEffect } from 'react'
import axios from 'axios'
import QAPost from './QAPost.js'
import QAEdit from './QAEdit.js'
import QAHistory from './QAHistory.js'
import { Grid, IconButton, Paper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import SettingsIcon from '@material-ui/icons/Settings'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import AppTextField from '../../../../components/AppTextField.js'
import SearchIcon from '@material-ui/icons/Search'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
const faker = require('faker')

const useStyles = makeStyles(theme => ({
  form: {
    width: 100 + '%',
  },
  searchIcon: {
    color: '#bbbbbb',
  },
  tableHead: {
    backgroundColor: '#fbfbfb',
    borderTop: 'solid 1px #eeeeee',
    borderBottom: 'solid 1px #eeeeee',
    fontWeight: 500,
    fontSize: 14,
  },
  menu: {
    position: 'absolute',
    right: 45,
    'z-index': 9999999999999,
    width: 150,
  },
}))

const qa = props => {
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [item, setItem] = useState({})
  const [data, setData] = useState(() => {
    let numbers = [1, 2, 3, 4, 5, 6, 7]
    let data = []
    numbers.forEach(item => {
      let obj = {
        name: faker.name.findName(),
        last: faker.name.lastName(),
        disposition: 'Do not call',
        quality: '',
        ncs: '',
        location: 'sig',
        prospect: `${faker.random.number()} (8_CST_S2_Dec_1_4)`,
        submits: '-',
      }
      data.push(obj)
    })
    return data
  })
  const [modal, setModal] = useState(false)
  const [modalPost, setModalPost] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalHistory, setModalHistory] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [sortedName, setSortedName] = useState(false)
  const [sortedLast, setSortedLast] = useState(false)
  const [sortedProspect, setSortedProspect] = useState(false)

  const handleClickIcon = event => {
    setAnchorEl(event.currentTarget.id)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showModal = item => {
    setItem({ ...item })
    setModal(true)
  }

  const sortList = (sortedItem, type) => {
    let results

    if (sortedItem) results = data.sort((a, b) => (a[type] > b[type] ? -1 : 1))
    else results = data.sort((a, b) => (a[type] < b[type] ? -1 : 1))

    if (type === 'name') setSortedName(!sortedName)
    if (type === 'last') setSortedLast(!sortedLast)
    if (type === 'prospect') setSortedProspect(!sortedProspect)

    setSearchResults([...results])
  }

  useEffect(() => {
    setSearchResults(data)
  }, [data])

  const searchData = value => {
    setSearch(value)
  }

  useEffect(() => {
    const results = data.filter(item =>
      item.name.toLowerCase().includes(search),
    )
    setSearchResults(results)
  }, [search])

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-child(odd)': {
        backgroundColor: '#fbfbfb',
      },
      border: 'none',
    },
  }))(TableRow)

  const StyledHeadTableCell = withStyles(theme => ({
    root: {
      border: 'none',
      fontSize: 14,
      fontFamily: 'Roboto',
      letterSpacing: 'normal',
      color: '#444851',
    },
  }))(TableCell)

  const StyledTableCell = withStyles(theme => ({
    root: {
      border: 'none',
      padding: 20,
    },
  }))(TableCell)

  const classes = useStyles()
  return (
    <div
      className={'qa ' + (modal ? 'modalHeight' : '')}
      style={{ paddingBottom: 150 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <AppTextField
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e)}
            icon={<SearchIcon />}
          />
        </Grid>
      </Grid>

      <Table className="mt-2">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledHeadTableCell>
              <a
                onClick={() => sortList(sortedName, 'name')}
                className="cursor-pointer"
              >
                <span className="left">First name</span>
                {sortedName ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </a>
            </StyledHeadTableCell>
            <StyledHeadTableCell align="left">
              <a
                onClick={() => sortList(sortedLast, 'last')}
                className="cursor-pointer"
              >
                <span className="left">Last name</span>
                {sortedLast ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </a>
            </StyledHeadTableCell>
            <StyledHeadTableCell align="left">Disposition</StyledHeadTableCell>
            <StyledHeadTableCell align="left">Quality</StyledHeadTableCell>
            <StyledHeadTableCell align="left">NCS</StyledHeadTableCell>
            <StyledHeadTableCell align="left">Location</StyledHeadTableCell>
            <StyledHeadTableCell align="left">
              <a
                onClick={() => sortList(sortedProspect, 'prospect')}
                className="cursor-pointer"
              >
                <span className="left">Prospect list</span>
                {sortedProspect ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </a>
            </StyledHeadTableCell>
            <StyledHeadTableCell align="left">Submits</StyledHeadTableCell>
            <StyledHeadTableCell align="left"></StyledHeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <tr></tr>
          {searchResults.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {item.name}
              </StyledTableCell>
              <StyledTableCell align="left">{item.last}</StyledTableCell>
              <StyledTableCell align="left">Do not call</StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell align="left">Si.</StyledTableCell>
              <StyledTableCell align="left">{item.prospect}</StyledTableCell>
              <StyledTableCell align="left">-</StyledTableCell>
              <StyledTableCell align="left" className="position-relative">
                <IconButton id={index} onClick={handleClickIcon}>
                  <SettingsIcon style={{ fontSize: 18, color: '#444851' }} />
                </IconButton>

                {anchorEl == index && (
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper className={classes.menu}>
                      <MenuList>
                        <MenuItem onClick={() => setModalEdit(true)}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => setModalPost(true)}>
                          Send post
                        </MenuItem>
                        <MenuItem onClick={() => setModalHistory(true)}>
                          History
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </ClickAwayListener>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <QAEdit
        hideModal={() => setModalEdit(false)}
        showModal={modalEdit}
        item={item}
      />
      <QAPost hideModal={() => setModalPost(false)} showModal={modalPost} />
      <QAHistory
        hideModal={() => setModalHistory(false)}
        showModal={modalHistory}
      />
    </div>
  )
}

export default qa
