import moment from 'moment'
import roundTo from 'round-to'

class NoSavedCreditError {
  constructor(message) {
    this.message = message
  }
}

export default class BillableHoursTrackingService {
  constructor($q, PortalSettings, CampaignStatsService) {
    'ngInject'

    this.$q = $q
    this.CS = PortalSettings.campaign
    this.CSS = CampaignStatsService
  }

  getBillableHoursCredit(details) {
    return this.CS.get(
      `${details.compSlug}/${details.campSlug}/billable_hours_credit`,
    )
      .then(data => {
        const credit = data.value
        if (!credit) throw new NoSavedCreditError('no_saved_credit')
        return {
          campaign: details.campSlug,
          company: details.compSlug,
          credit: credit,
        }
      })
      .catch(err => {
        if (err.status === 404) throw new NoSavedCreditError('no_saved_credit')
      })
  }

  saveBillableHoursCredit(details, credit) {
    const creditHours = this.CS.one(
      `${details.compSlug}/${details.campSlug}/billable_hours_credit`,
    )
    return creditHours.customPOST({
      value: credit,
    })
  }

  getBillableHours(details) {
    const start = details.credit.creditStart
    const end = moment().toDate()
    const component = {
      metrics: ['BH'],
      context: 'campaign',
      company: details.company,
      campaign: details.campaign,
    }
    const dateRange = {
      startDate: start,
      endDate: end,
      currentDay: end,
      requestTime: moment(),
    }

    return this.$q.all({
      billableHours: this.CSS.getSingleRangeStats(component, dateRange),
      credit: details.credit,
    })
  }

  deleteCredit(details) {
    const creditHours = this.CS.one(
      `${details.compSlug}/${details.campSlug}/billable_hours_credit`,
    )
    return creditHours.customDELETE()
  }

  calcUsedHours(data) {
    const credit = data.credit.hours
    const warning = data.credit.warning
    const creditStart = data.credit.creditStart
    const used = roundTo(data.billableHours.range.BH.y, 2)
    const remaining = roundTo(credit - used, 2)
    return {
      credit,
      warning,
      used,
      remaining,
      creditStart,
    }
  }
}
