import { pipe } from 'ramda'

function getMediaStream(constraints) {
  const { audio = true, video = false } = constraints
  return navigator.mediaDevices
    .getUserMedia({ audio, video })
    .then(stream => stream)
}

function constructRecorder(stream) {
  const recorder = new MediaRecorder(stream, {
    mimeType: 'audio/webm;codecs=opus',
  })
  return recorder
}

function bindEventCallbacks(eventCallbacks, recorder) {
  eventCallbacks.forEach(eventHandler => {
    recorder[eventHandler.eventName] = eventHandler.callback
  })
  return recorder
}

function makeBlob(chunks) {
  return new Blob(chunks, { type: 'audio/webm' })
}

function makeObjectUrl(blob) {
  return window.URL.createObjectURL(blob)
}

// call signature: createAudioElem(chunks)
const createAudioUrl = pipe(
  makeBlob,
  makeObjectUrl,
)

export { getMediaStream, constructRecorder, bindEventCallbacks, createAudioUrl }
