import angular from 'angular'

// services
import ManageCompaniesService from './services/ManageCompaniesService.js'

// components
import manageCompanies from './component/manageCompanies.component.js'
import manageCompaniesCreate from './sub-components/manage-companies-create/component/manageCompaniesCreate.component.js'
import createCompanyInfoForm from './sub-components/manage-companies-create/sub-components/company-info/component/createCompanyInfoForm.component.js'

export default angular
  .module('portalApp.manage.companies', [])
  .service('ManageCompaniesService', ManageCompaniesService)
  .component('manageCompanies', manageCompanies)
  .component('manageCompaniesCreate', manageCompaniesCreate)
  .component('createCompanyInfoForm', createCompanyInfoForm).name
