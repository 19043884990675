import {
  manageDidPools,
  manageDidPoolsConfig,
} from './child-states/manage-did-pools/manage.did-pools'
import {
  manageDid,
  manageDidConfig,
} from './child-states/manage-did/manage.dids'

export default function manageDidRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageDidPools, manageDidPoolsConfig)
    .state(manageDid, manageDidConfig)
}
