// @flow

import type { FilterListConfig } from '../../../../../common-components/filter-list/component/filterList.js'

type DisplayVariable = {|
  key: string,
  value: string,
  pitchUsage: string,
|}

import { reduce } from 'ramda'
import CPVD from '../../../../dialogs/create-pitch-variables/createPitchVariables'
import deleteEntityDialog from '../../../../dialogs/delete-entity/deleteEntity.js'

export default class PitchVariablesController {
  $mdDialog: Object
  $state: Object
  columnsConfig: FilterListConfig
  buildDisplayVariables: (
    acc: Array<DisplayVariable>,
    Array<[string, mixed]>,
  ) => Array<DisplayVariable>
  onUpdate: ({ [key: string]: string }) => void
  variables: Array<{ [key: string]: string }>
  pitchService: Object
  state: {
    pitchVariables: Array<DisplayVariable>,
  }

  constructor($mdDialog: Object, $state: Object) {
    'ngInject'
    this.$state = $state
    this.$mdDialog = $mdDialog

    this.buildDisplayVariables = reduce(
      (acc: Array<DisplayVariable>, [key, value]: [string, string]) => {
        acc.push({
          key,
          value,
          pitchUsage: `{{global.${key}}}`,
        })
        return acc
      },
    )

    this.columnsConfig = [
      { title: 'Key', accessor: 'key', type: 'text' },
      { title: 'Value', accessor: 'value', type: 'text' },
      {
        title: 'Variable Usage',
        accessor: 'pitchUsage',
        type: 'text',
        copyable: true,
      },
      {
        title: 'Edit',
        type: 'menu',
        icon: 'editor:mode_edit',
        menu: [
          {
            title: 'Change Values',
            icon: 'action:swap_horiz',
            actionCb: variable => this.openVariablesDialog(variable),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: variable => this.deleteVariable(variable),
          },
        ],
      },
    ]

    this.state = {
      columnsConfig: [
        { title: 'Key', accessor: 'key' },
        { title: 'Value', accessor: 'value' },
        { title: 'Variable Usage', accessor: 'pitchUsage', copyable: true },
      ],
      pitchVariables: [],
      topBarButtons: [
        {
          title: 'New Variable',
          icon: 'content:add',
          action: this.openVariablesDialog.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onChanges(bindings: Object) {
    // wait for parent component to provide services and initial variables
    if (bindings.variables) {
      this.state.pitchVariables = this.buildDisplayVariables(
        [],
        Object.entries(this.variables),
      )
    }
  }

  openVariablesDialog(variable: DisplayVariable) {
    const locals = {
      isCreate: !variable,
      service: this.pitchService,
      variables: this.variables,
      variable,
    }
    CPVD.locals = locals
    this.$mdDialog.show(CPVD).then(createdVariable => {
      this.onUpdate(createdVariable.variables)
    })
  }

  deleteVariable(variable: DisplayVariable) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Pitch Variable',
      entityName: variable.key,
      identityEndpoint: this.pitchService,
      entity: variable,
      routeWhenSuccessful: this.$state.current.name,
      isVariable: true,
      variables: this.variables,
    }
    this.$mdDialog.show(DED)
  }
}
