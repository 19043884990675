/**
 * Created by amielsinue on 7/3/17.
 */
import angular from 'angular'

// components
import manageGlobalPitchPhraseBooks from './component/manageGlobalPitchPhraseBooks.component'

export default angular
  .module('portalApp.manage.globalPitchPhraseBooks', [])
  .component('manageGlobalPitchPhraseBooks', manageGlobalPitchPhraseBooks)
  .name
