import {
  combineTests,
  pitchCollectionEqual,
} from '../../../utils/pitchObjects.js'

export default class PitchSegmentsController {
  constructor($mdDialog, $q, $state, $localStorage) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.$q = $q
    this.$localStorage = $localStorage
    this.state = {
      activeTests: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      globalTests: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      companyTests: {
        initial: [],
        editable: [],
        showSearch: false,
      },
      search: {
        activeTests: '',
        companyTests: '',
        globalTests: '',
      },
    }
    this.globalTestFilter = this.createTestFilter('globalTests')
    this.activeTestFilter = this.createTestFilter('activeTests')
    this.companyTestFilter = this.createTestFilter('companyTests')
    this.pitchTestsEqual = pitchCollectionEqual
    this.$state = $state
  }

  $onChanges(bindings) {
    // wait for parent component to provide services and initial active rapid response tests
    if (
      bindings.companyService &&
      bindings.globalService &&
      bindings.rrTests.isFirstChange()
    ) {
      this.getTests(this.companyService, this.globalService, this.rrTests)
    } else if (bindings.rrTests) {
      this.state.activeTests.initial = [...this.state.activeTests.editable]
    }
  }

  getTests(companyService, globalService, pitchTests) {
    this.$q
      .all({
        company: companyService.one('tests').get(),
        global: globalService.one('tests').get(),
      })
      .then(({ company, global }) => {
        return {
          company: company.plain(),
          global: global.plain(),
        }
      })
      .then(combineTests(pitchTests))
      .then(this.setAllSegments.bind(this))
  }

  setAllSegments({ active = [], company = [], global = [] }) {
    Object.assign(this.state, {
      activeTests: {
        initial: active,
        editable: [...active],
      },
      companyTests: {
        initial: company,
        editable: [...company],
      },
      globalTests: {
        initial: global,
        editable: [...global],
      },
    })
  }

  openSegmentMenu($mdMenu, ev) {
    $mdMenu.open(ev)
  }

  isEditingDialog() {
    if (this.$localStorage.editingDialog) {
      const segment = JSON.parse(this.$localStorage.editingDialog)
      this.openSegmentDialog(this.companyService, segment)
      delete this.$localStorage.editingDialog
    }
  }

  createTestFilter(key) {
    return ({ searchText }) => {
      this.state.search[key] = searchText
    }
  }

  saveActiveTests(activeTests) {
    this.onUpdate({
      rrTests: activeTests.map(test => test.uuid),
    })
  }

  removeTest(index, arr) {
    const test = arr.splice(index, 1)[0]
    if (test.company) {
      this.state.companyTests.editable.push(test)
    } else {
      this.state.globalTests.editable.push(test)
    }
  }

  cancelMove() {
    this.resetTestsList('activeTests')
    this.resetTestsList('companyTests')
    this.resetTestsList('globalTests')
  }

  resetTestsList(listKey = '') {
    this.state[listKey].editable = [...this.state[listKey].initial]
  }

  toggleSearch(key) {
    this.state[key].showSearch = !this.state[key].showSearch
    this.state.search[key] = ''
  }
}
