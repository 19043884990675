import { makePageTitle } from '../../../utils/resolve/pageTitle.js'

const editSegmentVariables = 'manage.editSegmentVariables'

const pageTitleConfig = {
  title: 'Edit Segment Variables',
}

const editSegmentVariablesConfig = {
  url: '/edit-segment-variables/company/{compSlug}/{compUuid}/segment/{uuid}',
  params: {
    compSlug: null,
    compUuid: null,
    uuid: null,
    isRapidResponse: null,
  },
  component: 'editSegmentVariables',
  resolve: {
    uuid: function($transition$) {
      'ngInject'
      return $transition$.params().uuid
    },
    compUuid: function($transition$) {
      'ngInject'
      return $transition$.params().compUuid
    },
    compSlug: function($transition$) {
      'ngInject'
      return $transition$.params().compSlug
    },
    isRapidResponse: function($transition$) {
      'ngInject'
      return $transition$.params().isRapidResponse || false
    },
    pageTitle: makePageTitle(pageTitleConfig),
  },
}

export { editSegmentVariables, editSegmentVariablesConfig }
