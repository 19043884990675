// Manage Audio Resources
import {
  MANAGE_AUDIO_VOICE,
  manageAudioVoiceConfig,
} from './manage.audioVoice.js'
import {
  MANAGE_VOICE_PITCH_AUDIO,
  pitchAudioConfig,
} from './child-states/manage.audioVoice.pitchAudio.js'
import {
  MANAGE_VOICE_PHRASE_AUDIO,
  phraseAudioConfig,
} from './child-states/manage.audioVoice.phraseAudio.js'
import {
  MANAGE_VOICE_PROSPECT_AUDIO,
  prospectAudioConfig,
} from './child-states/manage.audioVoice.prospectAudio.js'

export default function manageAudioVoiceRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(MANAGE_AUDIO_VOICE, manageAudioVoiceConfig)
    .state(MANAGE_VOICE_PITCH_AUDIO, pitchAudioConfig)
    .state(MANAGE_VOICE_PHRASE_AUDIO, phraseAudioConfig)
    .state(MANAGE_VOICE_PROSPECT_AUDIO, prospectAudioConfig)
}
