import {
  errorParser,
  hideInvalid,
} from '../../../../../../../utils/managementUtils.js'

export default class CompanyInfoFormController {
  constructor($state, $timeout, ManagementService) {
    'ngInject'

    this.$timeout = $timeout
    this.$state = $state
    this.MS = ManagementService
    this.hideInvalid = hideInvalid
    this.urlRegex = /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/:?=&]{1}[\da-z.-]+)*[/?]?$/
    this.state = {
      actionTitle: 'Company Info',
      creationErrors: null,
      creatingCompany: false,
      askNextAction: false,
    }
  }

  createCompany(company) {
    this.resetErrors()
    this.state.actionTitle = 'Creating'
    this.state.creatingCompany = true
    this.MS
      .createEntity({ entity: 'company', data: company })
      .then(() => {
        this.$timeout(() => {
          this.state.actionTitle = 'Company Created'
          this.state.askNextAction = true
        }, 2000)
      })
      .catch(err => {
        console.error('Error creating company: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, 2000)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingCompany = false
        }, 2000)
      })
  }

  resetErrors() {
    this.state.creationErrors = null
  }

  backTo() {
    this.$state.go('manage.companies')
  }

  createAnother() {
    this.company = {}
    this.state.actionTitle = 'Company Info'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit() {
    this.$state.go('manage.companies', null, { reload: true })
  }
}
