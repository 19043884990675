import { map } from 'ramda'

function calcTrueValue(rep) {
  const quotient = rep.isRate
    ? rep.dividend.value / rep.divisor.value * 100
    : rep.dividend.value / rep.divisor.value
  rep.y = isFinite(quotient) ? quotient : 0
  return rep
}

function finalize(metric) {
  metric.data = map(calcTrueValue, metric.data)
  return metric
}

function finalizeRangeValue(data) {
  return map(finalize, data)
}

export { finalizeRangeValue }
