// @flow

import type { Page } from '../side-nav/services/SideNavService.js'

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'

const styles = theme => ({
  button: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    fontSize: '24px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'inherit',
    },
  },
  icon: {
    marginLeft: '5px',
  },
  paper: {
    width: '140px',
  },
})

type Props = {
  classes: Object,
  innerRef: string,
  menuItems?: Page[],
  navDropdown: boolean,
  handleNavClick: (state: string) => void,
  padding: string,
  render?: () => React.Node,
  title: string,
}

type State = {
  menuOpen: boolean,
}

class PageHeader extends React.Component<Props, State> {
  anchorEl: HTMLButtonElement

  state = {
    menuOpen: false,
  }

  openMenu = e => {
    this.setState({ menuOpen: true })
  }

  closeMenu = (e: Event) => this.setState({ menuOpen: false })

  handleNavigate = (state: string, e: SyntheticEvent<>) => {
    this.setState({ menuOpen: false })
    this.props.handleNavClick(state)
  }

  render() {
    const { button, icon, paper } = this.props.classes
    const { menuItems } = this.props
    const padding = this.props.padding || 0

    if (this.props.navDropdown && menuItems) {
      return (
        <Grid
          style={{ padding: padding }}
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Button
              buttonRef={node => {
                this.anchorEl = node
              }}
              className={button}
              onClick={this.openMenu}
            >
              {this.props.title}
              <KeyboardArrowDown className={icon} />
            </Button>
            <Popper
              open={this.state.menuOpen}
              anchorEl={this.anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="nav-menu-grow"
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper className={paper}>
                    <ClickAwayListener onClickAway={this.closeMenu}>
                      <MenuList>
                        {menuItems.map(item => (
                          <MenuItem
                            key={item.state}
                            onClick={e => this.handleNavigate(item.state, e)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
          <Grid item xs={9}>
            <Grid container justify="flex-end">
              {this.props.render && this.props.render()}
            </Grid>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Button className={button}>{this.props.title}</Button>
          </Grid>
          <Grid item xs={9}>
            <Grid container justify="flex-end" spacing={2}>
              {this.props.render && this.props.render()}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }
}

export default withStyles(styles)(PageHeader)
