// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type DidTableService from '../service/DidTableService.js'
import type { TitleBarButtonConfig } from '../../../../../../common-components/title-bar/titleBarComponent.js'
import type {
  BaseState,
  QueryParams,
} from '../../../../../controllers/ManagementController.js'
import type { Entity, EntityString } from 'types/entities.js'

import { ENV, API } from 'settings.js'

import { findIndex, propEq } from 'ramda'
import ManagementController from '../../../../../../manage/controllers/ManagementController.js'
import CDD from '../../../../../dialogs/create-did/createDid.js'
import VDD from '../../../../../dialogs/validate-dids/validateDids.js'
import UDD from '../../../../../dialogs/update-did/updateDid.js'
import RDD from '../../../../../dialogs/rotate-did/rotateDid.js'
import REDD from '../../../../../dialogs/reactivate-did/reactivateDid.js'
import BDD from '../../../../../dialogs/buy-did/buyDid.js'

import type { Company, Campaign, Did, DidPool } from 'types/entities.js'

export default class DidTableController extends ManagementController {
  pool: DidPool
  topBarButtons: TitleBarButtonConfig
  state: BaseState
  rotatedState: BaseState
  entityName: string
  entityPlural: string
  tableConfig: Object
  rotatedEntityList: Entity[]
  rotatedTableConfig: Object
  rotatedSearchConfig: Object
  rotatedPaginateCurrentPage: number
  DTS: Object
  getCompany: () => Company
  getCampaign: () => Campaign
  onRotatedPaginate: (page: number) => void

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: Object,
    ToastService: Object,
    RoleStore: Object,
    DidTableService: DidTableService,
  ) {
    'ngInject'
    super(...arguments)

    this.entity = 'did'
    this.entityList = []
    this.rotatedEntityList = []
    this.searchConfig = DidTableService.searchConfig
    this.DTS = DidTableService
    this.rotatedState = ManagementController.baseState

    this.onRotatedPaginate = (page: number) => {
      this.rotatedState.queryParams.page = page
      this.getRotatedEntityList(
        this.rotatedState.queryParams,
        this.entity,
        this.rotatedSearchConfig.editable,
      )
    }
  }

  $onInit() {
    // initializing variables for dids of did pool
    this.state.queryParams = {
      company: this.getCompany().slug,
      campaign: this.getCampaign().slug,
      pool: this.pool.uuid,
      rotated: false,
    }
    this.tableConfig = this.bindActionCallbacks(this.DTS.tableConfig,)
    this.topBarButtons = [
      {
        action: this.createDid.bind(this),
        title: 'Add existing DID',
        icon: 'content:add',
        className:
          'pp-blue-text md-raised pp-button ng-scope md-ink-ripple layout-row',
      },
      {
        title: "Validate ID's",
        action: this.validateDids.bind(this),
        icon: 'mdi:playlist_add_check',
        className:
          'md-raised pp-button md-warn md-button md-managementToolbar-theme md-button md-ink-ripple right-margin-10',
      },
      {
        action: this.buyDid.bind(this),
        title: 'Buy DID',
        icon: 'action:shopping_cart',
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.topBarButtons = this.topBarButtons
    this.getEntityList(this.state.queryParams, this.entity, true)

    // initializing variables of rotated dids of did pool
    this.rotatedState.queryParams = {
      company: this.getCompany().slug,
      campaign: this.getCampaign().slug,
      pool: this.pool.uuid,
      rotated: true,
    }
    this.rotatedTableConfig = this.bindActionCallbacks(this.DTS.rotatedTableConfig,)
    this.getRotatedEntityList(this.rotatedState.queryParams, this.entity, true)
  }

  createDid() {
    CDD.locals = {
      isCreate: true,
      pool: this.pool,
      company: this.state.queryParams.company,
      campaign: this.state.queryParams.campaign,
    }

    return this.$mdDialog
      .show(CDD)
      .then(createdDids => {
        this.refreshEntityList()
      })
      .catch(console.error)
  }

  refreshDidList() {
    this.getEntityList(this.state.queryParams, this.entity, true)
    this.getRotatedEntityList(this.rotatedState.queryParams, this.entity, true)
  }

  buyDid() {
    BDD.locals = {
      pool: this.pool,
      company: this.getCompany(),
      campaign: this.getCampaign(),
      refreshDidList: this.refreshDidList.bind(this),
    }
    this.$mdDialog.show(BDD).then(() => {
      this.refreshDidList()
    })
  }

  validateDids() {
    VDD.locals = {
      pool: this.pool,
      company: this.state.queryParams.company,
      campaign: this.state.queryParams.campaign,
    }
    this.$mdDialog.show(VDD).then(() => {
      this.refreshDidList()
    })
  }

  rotateDid(ev: Event, did: Did, index: number) {
    RDD.locals = {
      did,
      company: this.getCompany(),
      campaign: this.getCampaign(),
      pool: this.pool,
      refreshDidList: this.refreshDidList.bind(this),
    }
    this.$mdDialog.show(RDD).then(() => {
      this.refreshDidList()
    })
  }

  reactivateDid(ev: Event, did: Did, index: number) {
    REDD.locals = {
      did,
      company: this.getCompany(),
      campaign: this.getCampaign(),
      pool: this.pool,
      refreshDidList: this.refreshDidList.bind(this),
    }
    this.$mdDialog.show(REDD).then(() => {
      this.refreshDidList()
    })
  }

  editDid(ev: Event, did: Did, index: number) {
    UDD.locals = {
      did,
      company: this.state.queryParams.company,
      campaign: this.state.queryParams.campaign,
    }
    return this.$mdDialog
      .show(UDD)
      .then(updatedDid => {
        const didIndex = findIndex(propEq('uuid', updatedDid.uuid))(
          this.entityList,
        )
        this.entityList.splice(didIndex, 1, updatedDid)
      })
      .catch(console.error)
  }

  findEntity(searchText: ?string) {
    this.MS.findEntity({
      entity: this.entity,
      searchText,
      company: this.state.queryParams.company,
      campaign: this.state.queryParams.campaign,
      pool: this.pool.uuid,
      limit: 5,
    })
      .then(results => {
        this.searchConfig.searchResults = results
      })
      .catch(err => {
        console.error(`${this.entity} search error:`, err)
        this.TS.show({
          text: `${this.entity} search error`,
        })
      })
  }

  onRotatedSearchUpdate(searchText: string) {
    this.rotatedSearchConfig.searchValue = searchText
    this.rotatedFindEntity(
      searchText,
      this.rotatedSearchConfig.editable,
      this.rotatedSearchConfig.limit,
    )
  }

  rotatedFindEntity(
    searchText: ?string = '',
    editable: boolean = false,
    limit: number = 5,
  ) {
    this.MS.findEntity({
      searchText,
      editable,
      limit,
      entity: this.entity,
    })
      .then(results => {
        this.rotatedSearchConfig.searchResults = results
      })
      .catch(err => {
        console.error(`${this.entity} search error:`, err)
        this.TS.show({
          text: `${this.entity} search error`,
        })
      })
  }

  getRotatedEntityList(
    params: QueryParams,
    entity: EntityString,
    editable: boolean,
  ) {
    this.rotatedState.isLoading = true
    params.editable = editable

    this.MS.getEntityList(params, entity)
      .then(this._populateRotatedEntityList.bind(this))
      .then(() => {
        if (params.page && typeof params.page === 'number') {
          this.rotatedPaginateCurrentPage = params.page
        } else {
          this.rotatedPaginateCurrentPage = this.rotatedPaginateCurrentPage
        }
      })
      .catch(this._handleEntityListError.bind(this))
      .finally(this._endRotatedLoading.bind(this))
  }

  _populateRotatedEntityList(
    res: Entity[] | { count: number, results: Entity[] },
  ) {
    if (Array.isArray(res)) {
      this.rotatedState.queryCount = res.length
      this.rotatedEntityList = res
    } else if (res.count && res.results) {
      this.rotatedState.queryCount = res.count
      this.rotatedEntityList = res.results
    }
    return res
  }

  _handleEntityListError(err: Object) {
    console.error(`Error fetching ${this.entity}: `, err)
    this.TS.show({
      text: `Error fetching ${this.entity}`,
    })
  }

  _endRotatedLoading() {
    this.rotatedState.isLoading = false
  }

  refreshRotatedEntityList() {
    this.getRotatedEntityList(
      this.rotatedState.queryParams,
      this.entity,
      this.rotatedSearchConfig.editable,
    )
  }
}
