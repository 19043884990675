import { map, merge, pipe, reduce } from 'ramda'
import roundTo from 'round-to'
import ColorHash from 'color-hash'
import statConverter from 'util/conversion/statConverter.js'

function returnAccessObject(access, data) {
  if (Array.isArray(access)) {
    return reduce((acc, key) => merge(acc, data[key]), {}, access)
  }
  return data[access] || {}
}

function convertStat(statKey = '', convertTo = '', stats = {}) {
  return convertTo
    ? statConverter[statKey](stats[statKey], convertTo)
    : stats[statKey] || 0
}

function getQuotient({ dividend = 0, divisor = 0 }) {
  return {
    dividend,
    divisor,
    quotient: dividend / divisor,
  }
}

function convertQuotientToPercentage(terms) {
  return Object.assign(terms, { quotient: terms.quotient * 100 })
}

function roundTerms({ precision }) {
  return map(term => roundTo(term, precision))
}

function getDividendDivisor(metric, stats, normalize = false) {
  const dividend = metric.dividend.convertTo
    ? convertStat(metric.dividend.stat, metric.dividend.convertTo, stats)
    : stats[metric.dividend.stat]
  const divisor = metric.divisor.convertTo
    ? convertStat(metric.divisor.stat, metric.divisor.convertTo, stats)
    : stats[metric.divisor.stat]

  if (normalize) {
    return {
      dividend: divisor ? dividend : 0,
      divisor: dividend ? divisor : 0,
    }
  }

  return {
    dividend,
    divisor,
  }
}

const checkTermsAreFinite = map(term => (isFinite(term) ? term : 0))

const getTermsForRate = pipe(
  getDividendDivisor,
  getQuotient,
  convertQuotientToPercentage,
  checkTermsAreFinite,
  roundTerms({ precision: 2 }),
)

function formatRepMetricDetails({
  metric,
  terms,
  date,
  repName,
  dialed,
  color,
}) {
  const { dividend, divisor, quotient } = terms
  return {
    rep: repName,
    format: metric.format,
    isRate: metric.isRate,
    color,
    healthKey: metric.healthKey,
    subSection: metric.subSections || null,
    y: quotient,
    x: date,
    dialed,
    dividend: {
      name: metric.dividend.stat,
      value: dividend,
    },
    divisor: {
      name: metric.divisor.stat,
      value: divisor,
    },
  }
}

function formatCalculatedMetricDetails({
  metric,
  terms,
  date,
  isRate,
  isCalc,
}) {
  const { dividend, divisor, quotient, y } = terms
  let metricDetails = {
    format: metric.format,
    isRate,
    isCalc,
    healthKey: metric.healthKey,
    subSection: metric.subSection || null,
    x: date,
  }
  if (y !== undefined || null) {
    metricDetails = Object.assign(metricDetails, {
      y,
      aggregate: metric.aggregate,
    })
  } else {
    metricDetails = Object.assign(metricDetails, {
      y: quotient,
      dividend: {
        name: metric.dividend.stat,
        value: dividend,
      },
      divisor: {
        name: metric.divisor.stat,
        value: divisor,
      },
    })
  }
  return metricDetails
}

function simpleRate(metric, data, date) {
  const stats = returnAccessObject(metric.access, data)
  const terms = getTermsForRate(metric, stats)
  return formatCalculatedMetricDetails({
    metric,
    terms,
    date,
    isRate: true,
    isCalc: true,
  })
}

function simpleRateByRep(metric, data, repName, date) {
  const color = new ColorHash().hex(repName)
  const stats = returnAccessObject(metric.access, data)
  const dialed = stats.billable_hours && stats.payable_hours ? 1 : 0
  const terms = getTermsForRate(metric, stats)
  return formatRepMetricDetails({
    metric,
    terms,
    date,
    repName,
    dialed,
    color,
  })
}

const getTermsForDivision = pipe(
  getDividendDivisor,
  getQuotient,
  checkTermsAreFinite,
  roundTerms({ precision: 2 }),
)

function simpleDivision(metric, data, date) {
  const stats = returnAccessObject(metric.access, data)
  const terms = getTermsForDivision(metric, stats)
  return formatCalculatedMetricDetails({
    metric,
    terms,
    date,
    isRate: metric.isRate,
    isCalc: metric.isCalc,
  })
}

function simpleDivisionNormalized(metric, data, date) {
  const stats = returnAccessObject(metric.access, data)
  const terms = getTermsForDivision(metric, stats, true)
  return formatCalculatedMetricDetails({
    metric,
    terms,
    date,
    isRate: metric.isRate,
    isCalc: metric.isCalc,
  })
}

function simpleDivisionByRep(metric, data, repName, date) {
  const color = new ColorHash().hex(repName)
  const stats = returnAccessObject(metric.access, data)
  const dialed = stats.billable_hours && stats.payable_hours ? 1 : 0
  const terms = getTermsForDivision(metric, stats)
  return formatRepMetricDetails({
    metric,
    terms,
    date,
    repName,
    dialed,
    color,
  })
}

function getHangupCauseDividendDivisor(metric, stats) {
  const dividend = metric.dividend.causes.reduce((total, hangup) => {
    total += stats[hangup] || 0
    return total
  }, 0)
  const divisor = metric.divisor.convertTo
    ? statConverter(metric.divisor.stat, metric.divisor.convertTo, stats)
    : stats[metric.divisor.stat]
  return {
    dividend,
    divisor,
  }
}

const getHangupCauseTerms = pipe(
  getHangupCauseDividendDivisor,
  getQuotient,
  convertQuotientToPercentage,
  checkTermsAreFinite,
  roundTerms({ precision: 2 }),
)

function hangupCauseRate(metric, data, date) {
  const stats = returnAccessObject(metric.access, data)
  const terms = getHangupCauseTerms(metric, stats)
  return formatCalculatedMetricDetails({
    metric,
    terms,
    date,
    isRate: metric.isRate,
    isCalc: metric.isCalc,
  })
}

const getTermsForPassStat = pipe(
  convertStat,
  y => ({ y }),
  roundTerms({ precision: 2 }),
)

function passStat(metric, data, date) {
  const stats = returnAccessObject(metric.access, data)
  const terms = getTermsForPassStat(metric.statKey, metric.convertTo, stats)
  return formatCalculatedMetricDetails({
    metric,
    terms,
    date,
    isRate: metric.isRate,
    isCalc: metric.isCalc,
  })
}

export {
  simpleDivision,
  simpleDivisionNormalized,
  simpleDivisionByRep,
  simpleRate,
  simpleRateByRep,
  hangupCauseRate,
  passStat,
}
