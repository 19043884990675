import './dashboard.lists.scss'

import angular from 'angular'

// services
import ListsService from './services/ListsService.js'

// components
import lists from './component/lists.js'

export default angular
  .module('portalApp.dashboard.lists', [])
  .service('ListsService', ListsService)
  .component('lists', lists)
  .name
