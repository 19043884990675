import './buyDid.scss'
import BuyDidController from './controller/BuyDidController'
const template = require('./buyDid.tpl.pug')

const buyDid = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: BuyDidController,
}

export default buyDid
