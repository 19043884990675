import { find, propEq } from 'ramda'
import { MANAGE_GROUP } from 'permissions.js'
import billableDialog from '../../dialogs/track-billable-hours/trackBillable.js'

export default class DashboardController {
  constructor(
    $window,
    $element,
    $state,
    $localStorage,
    $sessionStorage,
    $mdSidenav,
    $mdComponentRegistry,
    $mdDialog,
    AuthService,
    BillableHoursTrackingService,
    DashboardMenuService,
    RoleStore,
    RealmService,
  ) {
    'ngInject'

    this.$mdSidenav = $mdSidenav
    this.$window = $window
    this.$element = $element
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.$sessionStorage = $sessionStorage
    this.$localStorage = $localStorage
    this.$mdSidenav = $mdSidenav
    this.BHTS = BillableHoursTrackingService
    this.AuthService = AuthService
    this.DMS = DashboardMenuService
    this.RS = RoleStore
    this.RealmService = RealmService
    this.MANAGE_GROUP = MANAGE_GROUP
    this.profileMenuConfig = {
      sections: [
        {
          title: 'Change Password',
          icon: 'action:face',
          actionCb: () => this.$state.go('changePassword'),
        },
        {
          title: 'Logout',
          icon: 'action:power_settings_new',
          actionCb: () => this.AuthService.logout(),
        },
      ],
    }
  }

  $onInit() {
    this.menu = this.DMS.getMenu()
    this.userRoles = this.RS.getAllRoles()
    this.$window.document.title = this.selectedCampaign.name

    this.$element.find('input').on('keydown', function(ev) {
      ev.stopPropagation()
    })

    this.state = {
      userCampaigns: this.userCampaigns,
      selectedCampaign: this.selectedCampaign,
      selectedRealm: this.selectedRealm
        ? find(propEq('slug', this.selectedRealm.slug), this.campaignRealms)
        : null,
      remainingCredit: null,
      usedCredit: null,
      loadingCredit: true,
    }
  }

  openProfileMenu($mdMenu, $event) {
    $mdMenu.open($event)
  }

  toggleSideNav(navId) {
    this.$mdSidenav(navId).toggle()
  }

  switchCampaign() {
    const params = {
      realmSlug: null,
      campaignSlug: this.state.selectedCampaign.slug,
    }
    this.$state.go('dashboard.overview', params, { reload: true })
  }

  updateRealm(realm) {
    const params = {
      realmSlug: realm ? realm.slug : null,
    }
    this.$state.go(this.$state.current.name, params)
  }

  openBillableTracker() {
    const details = {
      compSlug: this.currentCompany.slug,
      campSlug: this.selectedCampaign.slug,
    }
    const bd = billableDialog
    bd.locals = {
      compSlug: this.currentCompany.slug,
      campSlug: this.selectedCampaign.slug,
      hours: this.state.billableHoursCredit
        ? this.state.billableHoursCredit.hours
        : null,
      creditStart: this.state.billableHoursCredit
        ? this.state.billableHoursCredit.creditStart
        : null,
      warningHours: this.state.billableHoursCredit
        ? this.state.billableHoursCredit.warning
        : null,
    }
    this.$mdDialog
      .show(bd)
      .then(newHours => {
        if (newHours.hours === this.state.billableHoursCredit.hours) return
        this.getBillableHoursCredit(details)
      })
      .catch(newHours => {
        if (newHours.hours === this.state.billableHoursCredit.hours) return
        this.getBillableHoursCredit(details)
      })
  }

  setNewHours(newHours) {
    if (newHours) {
      this.state.billableHoursCredit.hours = newHours.hours
      this.state.billableHoursCredit.creditStart = newHours.creditStart
    }
  }

  getBillableHoursCredit(details) {
    this.state.loadingCredit = true
    this.BHTS.getBillableHoursCredit(details)
      .then(this.BHTS.getBillableHours.bind(this.BHTS))
      .then(this.BHTS.calcUsedHours)
      .then(data => {
        this.state.billableHoursCredit.hours = data.credit
        this.state.billableHoursCredit.creditStart = data.creditStart
        this.state.billableHoursCredit.warning = data.warning
        this.state.remainingCredit = data.remaining
        this.state.billableHoursCredit.belowWarning =
          data.remaining <= data.warning
        this.state.usedCredit = data.used
        this.state.loadingCredit = false
      })
      .catch(err => {
        if (err.message === 'no_saved_credit') {
          this.state.loadingCredit = false
          this.state.billableHoursCredit.hours = null
        }
      })
  }
}
