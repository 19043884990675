import MetricGoalController from './controller/MetricGoalController.js'
const template = require('./metricGoal.tpl.pug')

const metricGoal = {
  template: template(),
  controller: MetricGoalController,
  bindings: {
    metric: '<',
    updateGoal: '&'
  }
}

export default metricGoal
