function getOpenSections (fromState = '') {
  return function ($transition$) {
    'ngInject'
    const from = $transition$.$from()
    if (from.name === fromState) {
      return [].concat(from.data.openSections)
    }
  }
}

export { getOpenSections }
