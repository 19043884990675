import angular from 'angular'

// components
import editLocation from './component/editLocation.component.js'
import editLocationCreateTeam from './sub-components/edit-location-create-team/component/editLocationCreateTeam.component.js'

export default angular
  .module('portalApp.manage.editLocation', [])
  .component('editLocation', editLocation)
  .component('editLocationCreateTeam', editLocationCreateTeam)
  .name
