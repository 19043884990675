import PitchVoicesController from './controller/PitchVoicesController.js'
const template = require('./pitchVoices.component.tpl.pug')

const pitchVoices = {
  template: template(),
  controller: PitchVoicesController,
  bindings: {
    pitch: '<',
    service: '<',
  }
}

export default pitchVoices
