export default class EditAccessListService {
  constructor (AccessListService) {
    'ngInject'
    this.ACL = AccessListService
  }

  update (accessList) {
    const { companySlug = 'global', ...accessListData } = accessList
    let uriParts = [
      companySlug,
      accessList.uuid
    ]
    if (companySlug !== 'global') {
      uriParts = ['company'].concat(uriParts)
    }
    let uri = uriParts.join('/')
    return this.ACL.acl.oneUrl(`${uri}`).customPUT(accessListData)
  }

  getNodes (accessList) {
    const { companySlug = 'global', ...accessListData } = accessList
    let uriParts = [
      companySlug,
      accessListData.uuid,
      'node'
    ]
    if (companySlug !== 'global') {
      uriParts = ['company'].concat(uriParts)
    }
    let uri = uriParts.join('/')
    return this.ACL.acl.oneUrl(`${uri}/`).get()
      .then(nodes => nodes.plain())
  }

  checkIPAddress (accessList) {
    const { ip_address, companySlug = 'global', ...accessListData } = accessList
    let uri = [
      companySlug,
      accessListData.uuid,
      'ipcheck'
    ].join('/')
    return this.ACL.acl.oneUrl(`${uri}/`).get({'ip_address': ip_address})
      .then(nodes => nodes.plain())
  }
}

