// Edit Rapid Response Test view

const manageEditRapidResponseTestsVariables =
  'manage.editRapidResponseTests.variables'

const manageEditRapidResponseTestsVariablesConfig = {
  url: '/variables',
  component: 'editRapidResponseTestsVariables',
}

export {
  manageEditRapidResponseTestsVariables,
  manageEditRapidResponseTestsVariablesConfig,
}
