import './changePassword.scss'

import ChangePasswordController from './controller/ChangePasswordController.js'
const template = require('./changePassword.component.tpl.pug')

const changePassword = {
  template: template(),
  controller: ChangePasswordController,
  bindings: {}
}

export default changePassword

