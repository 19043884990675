import RapidResponseTestsCampaignsController from './controller/RapidResponseTestsCampaigns.controller'
const template = require('./rapidResponseTestsCampaigns.component.tpl.pug')

const editRapidResponseTestsCampaigns = {
  template: template(),
  controller: RapidResponseTestsCampaignsController,
  bindings: {},
}

export default editRapidResponseTestsCampaigns
