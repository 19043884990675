import { permissions, MANAGE_GROUP } from 'permissions.js'

const { RECORDER } = permissions

// manage view

const manage = 'manage'

const manageConfig = {
  url: '/manage',
  component: 'manage',
  data: {
    authRequired: true,
    permissions: {
      only: [...MANAGE_GROUP, RECORDER],
      redirectTo: 'gateway',
    },
  },
  resolve: {
    user: function($sessionStorage) {
      'ngInject'
      return $sessionStorage.user
    },
  },
}

export { manage, manageConfig }
