import angular from 'angular'

// Services
import EditPhraseBookService from './services/EditPhraseBookService.js'

// components
import createPhraseForm from './sub-components/create-phrase-form/component/createPhraseForm.js'
import editPhraseBook from './component/editPhraseBook.component'
import editPhraseForm from './sub-components/edit-phrase-form/component/editPhraseForm.js'

export default angular
  .module('portalApp.manage.editPhraseBook', [])
  .service('EditPhraseBookService', EditPhraseBookService)
  .component('createPhraseForm', createPhraseForm)
  .component('editPhraseBook', editPhraseBook)
  .component('editPhraseForm', editPhraseForm).name
