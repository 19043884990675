import EditPhraseBookPhraseFormController from './controller/EditPhraseBookPhraseFormController.js'
import template from './editPhraseBookPhraseForm.tpl.pug'

const editPhraseBookPhraseForm = {
  template: template(),
  controller: EditPhraseBookPhraseFormController,
  bindings: {
    service: '<',
    // phraseBook: '<',
    // phrase: '<',
    // compSlug: '<'
  },
}

export default editPhraseBookPhraseForm
