// dashboard.dialerQueue view

const dashboardDialerQueue = 'dashboard.dialerQueue'

const dashboardDialerQueueConfig = {
  url: '/dialer-queue',
  component: 'dialerQueue',
  resolve: {
    campaign: function (selectedCampaign, RealmService) {
      'ngInject'
      return selectedCampaign
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    }
  }
}

export { dashboardDialerQueue, dashboardDialerQueueConfig }

