import { values, map, reduce } from 'ramda'

export default class EditTeamService {
  constructor($q, IdentityService) {
    'ngInject'

    this.$q = $q
    this.IS = IdentityService
  }

  updateTeam(team) {
    return this.IS.Team.fetch
      .one(team.uuid)
      .patch(team)
      .then(updatedTeam => updatedTeam.plain())
  }

  updateUserTeam(user) {
    return this.IS.User.manage.fetch
      .one(user.uuid)
      .patch({ team: user.team })
      .then(user => user.plain())
  }

  removeMembers(removeQueue, team) {
    const removeList = values(removeQueue).map(member => {
      member.team = null
      return member
    })
    return this.$q
      .all(map(this.updateUserTeam.bind(this), removeList))
      .then(map(member => member.uuid))
  }

  addMembers(addQueue, team) {
    const addList = values(addQueue).map(member => {
      member.team = team.uuid
      return member
    })
    return this.$q.all(map(this.updateUserTeam.bind(this), addList))
  }

  getTeamDetails(team) {
    const promisedDetails = {
      users: this.getTeamUsers(team),
    }
    return this.$q.all(promisedDetails).then(details => details)
  }

  getTeamLocation(locationId) {
    return this.IS.Location.list
      .get({ editable: true })
      .then(locations => locations.plain())
  }

  getTeamUsers(team) {
    const detailObject = {
      teamId: team.uuid,
      leaderId: team.leader,
      members: [],
      nonMembers: [],
      editableUsers: [],
      leader: null,
    }
    return this.IS.User.manage.list
      .get({ editable: true })
      .then(reduce(this.teamReducer, detailObject))
      .then(teamDetails => teamDetails)
  }

  teamReducer(acc, user) {
    if (user.uuid === acc.leaderId) {
      acc.leader = user
    } else if (user.team === acc.teamId) {
      acc.members.push(user)
    } else {
      acc.nonMembers.push(user)
    }
    acc.editableUsers.push(user)
    return acc
  }
}
