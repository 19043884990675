import BDCD from '../../buy-did-confirmation/buyDidConfirmation.js'

export default class BuyDidResultsController {
  constructor($mdDialog, DidListService, ToastService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.DidListService = DidListService
    this.TS = ToastService
  }

  $onInit() {
    this.placeOrderButtonDisabled = false
    this.config = {
      entity: 'did',
      entityList: this.entityList,
      total: 0,
      tableConfig: {
        trackBy: 'tier',
        columns: [
          {
            title: 'Phone',
            accessor: 'phone',
            type: 'checkbox',
          },
          { title: 'Extra Information', accessor: 'extraInfo' },
        ],
      },
    }
  }

  selectAll() {
    for (let i = 0; i < this.config.entityList.length; i++) {
      this.config.entityList[i].phone.value = true
    }
  }

  checkInputFields() {
    let disabled = true
    for (let i = 0; i < this.config.entityList.length; i++) {
      if (this.config.entityList[i].phone.value) {
        disabled = false
        break
      }
    }
    return disabled || this.placeOrderButtonDisabled
  }

  placeOrder() {
    const numbers = []
    for (let i = 0; i < this.config.entityList.length; i++) {
      if (this.config.entityList[i].phone.value) {
        numbers.push(this.config.entityList[i].phone.tag)
      }
    }
    const data = {
      numbers: numbers,
      pool_uuid: this.pool.uuid,
      provider: this.provider,
    }
    const uriData = {
      company: this.company,
      campaign: this.campaign,
    }
    this.placeOrderButtonDisabled = true
    this.DidListService.buyDidList
      .post(data, uriData)
      .then(data => {
        let didsPurchased = 0
        const assigned = data.assigned.map(element => {
          if (element[1]) {
            didsPurchased++
          }
          return {
            number: element[0],
            assigned: element[1],
          }
        })
        BDCD.locals = {
          did: this.did,
          pool: this.pool,
          company: this.company,
          campaign: this.campaign,
          refreshDidList: this.refreshDidList.bind(this),
          assigned: assigned,
          didsPurchased: didsPurchased,
        }
        this.$mdDialog.show(BDCD)
      })
      .catch(err => {
        console.error(err)
        this.TS.show({
          text: 'Error while buying dids',
        })
      })
      .finally(() => {
        this.placeOrderButtonDisabled = false
        this.refreshDidList()
      })
  }

  close() {
    this.$mdDialog.destroy()
  }
}
