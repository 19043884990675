import moment from 'moment'
import roundTo from 'round-to'
import {
  backToRoute,
  dialerLiveColumnsConfig,
} from '../config/dialerLiveConfig.js'

export default class DialerLiveController {
  constructor($interval, $filter, DialerLiveService, ToastService) {
    'ngInject'
    this.interval = $interval
    this.intervalMilliSeconds = 10000
    this.intervalPromise = null

    this.title = 'Live Dialer Status Report'
    this.DLS = DialerLiveService
    this.TS = ToastService
    this.state = {
      loading: false,
      columnsConfig: JSON.parse(JSON.stringify(dialerLiveColumnsConfig)),
      backToRoute: backToRoute,
      dialerStatusReports: [],
      sumTotalValues: {},
    }

    this.filteredSumColumns = $filter('filter')(this.state.columnsConfig, {
      total: 'sum',
    })

    this.searchLiveStatusReport = ({ searchText }) => {
      this.searchText = searchText
    }
  }

  $onInit() {
    this.state.selected = {
      campaign: this.campaign,
      realm: this.realm,
    }
    this.dialerControl = {
      isIntervalActive: false,
      lastTimeUpdated: null,
      pauseOrPlayInterval: this.pauseOrPlayInterval.bind(this),
    }
    this.callInterval()
  }

  $onDestroy() {
    this.cancelInterval()
  }

  pauseOrPlayInterval() {
    if (this.dialerControl.isIntervalActive) {
      this.cancelInterval()
      this.dialerControl.isIntervalActive = false
    } else {
      this.callInterval()
    }
  }

  cancelInterval() {
    this.interval.cancel(this.intervalPromise)
  }

  callInterval() {
    this.dialerControl.isIntervalActive = true
    this.getLiveDialerStatusReports()

    this.intervalPromise = this.interval(() => {
      this.getLiveDialerStatusReports()
    }, this.intervalMilliSeconds)
  }

  getLiveDialerStatusReports() {
    const campaignSlugs = this.state.selected.campaign
      ? this.state.selected.campaign.slug
      : null
    const realmSlugs = this.state.selected.realm
      ? this.state.selected.realm.slug
      : null
    this.state.loading = true

    if (campaignSlugs) {
      this.DLS.getLiveDialerStatusReports(campaignSlugs, realmSlugs)
        .then(data => {
          this.populateSumTotalTable(data)
          this.state.columnsConfig = JSON.parse(
            JSON.stringify(dialerLiveColumnsConfig),
          )
          this.state.loading = false
          this.dialerControl.lastTimeUpdated =
            'Last Sync: ' + moment().format('h:mm:ss A')
        })
        .catch(err => {
          this.TS.show({
            text: `Problem fetching ${this.title}`,
          })
          console.error('Live Dialer Error: ', err)
        })
    }
  }

  populateSumTotalTable(dialerEndpointData) {
    this.state.dialerStatusReports = []
    this.state.sumTotalValues = {}

    if (dialerEndpointData.length) {
      for (const realmDialerStatusReport of dialerEndpointData[0].realms) {
        const report = realmDialerStatusReport.report
        // Setting realm in report object
        report.realm = realmDialerStatusReport.realm
        // Creation of stations_per_rep and stations_per_billable_rep values in report object
        report.stations_per_rep = roundTo(
          report.total_stations / report.total_reps,
          2,
        )
        report.stations_per_billable_rep = roundTo(
          report.billable_stations / report.billable_reps,
          2,
        )
        // Creating string values for multidata columns
        report.stations_per_rep_and_billable = `${report.stations_per_rep} / ${
          report.stations_per_billable_rep
        }`
        report.requested_ringing_calls = `${report.num_calls_to_make} / ${
          report.ringing_calls
        }`
        report.campaign_call_ratio = `${report.effective_call_ratio} / ${
          report.ratio
        }`
        report.inqueue_connected_calls = `${report.unactivated_calls} / ${
          report.active_calls
        }`
        report.active_dialable_prospects = `${report.active_prospects} / ${
          report.callable_prospects
        }`
        report.abandonment_5_15_60_min = `${roundTo(
          parseFloat(report.abandonment_5),
          2,
        )} / ${roundTo(parseFloat(report.abandonment_15), 2)} / ${roundTo(
          parseFloat(report.abandonment_60),
          2,
        )}`
        // for to build sumTotalValues
        for (const sumColumn of this.filteredSumColumns) {
          this.state.sumTotalValues[sumColumn.accessor] = this.state
            .sumTotalValues[sumColumn.accessor]
            ? this.state.sumTotalValues[sumColumn.accessor] +
              report[sumColumn.accessor]
            : 0 + report[sumColumn.accessor]
        }
        // Adding row to dialerStatusReports
        this.state.dialerStatusReports.push(report)
      }
    }
  }
}
