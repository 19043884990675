import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'activeProspects',
  type: 'prospect',
}

const formatting = {
  format: {
    title: 'Active Prospects',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'prospect_health',
}

const activeProspects = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { activeProspects }
