import { CAMPAIGN_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'invalidCalls',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Invalid Calls',
  },
}

const keys = {
  statKey: 'invalid_calls',
  componentKeys: [CAMPAIGN_METRICS],
  healthKey: 'prospect_health',
}

const calculation = {
  calcType: 'aggregate',
}

const invalidCalls = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { invalidCalls }
