import './companyInfoForm.component.scss'
import CompanyInfoFormController from './controller/CompanyInfoFormController.js'
const template = require('./companyInfoForm.component.tpl.pug')

const companyInfoForm = {
  template: template(),
  controller: CompanyInfoFormController,
  bindings: {
    user: '<',
    companies: '<',
    lastState: '<',
  }
}

export default companyInfoForm
