// Manage Realms
import { manageStations, manageStationsConfig } from './manage.stations.js'


export default function manageStationsRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageStations, manageStationsConfig)    

}
