import angular from 'angular'
import { react2angular } from 'react2angular'

// components
import { ManageRealmsEdit } from './component/ManageRealmsEdit.component.js'

const dependencies = [
  '$state',
  'IdentityService',
  'SideNavService',
  'ManageMenuService',
  'RoleStore',
]

export default angular
  .module('portalApp.manage.editRealms', [])
  .component('manageRealmsEdit', react2angular(ManageRealmsEdit, [], dependencies))
  .name
