// Edit Rapid Response Test view

const manageEditRapidResponseTestsSettings =
  'manage.editRapidResponseTests.settings'

const manageEditRapidResponseTestsSettingsConfig = {
  url: '/settings',
  component: 'editRapidResponseTestsSettings',
}

export {
  manageEditRapidResponseTestsSettings,
  manageEditRapidResponseTestsSettingsConfig,
}
