//@flow

import type { BaseService, Element } from 'restangular'

export type IdentityUserManage = {|
  create: Element,
  createBulk: Element,
  list: Element,
  fetch: BaseService,
  unique: {|
    email: BaseService,
    username: BaseService,
  |},
  setPassword: Element,
  impersonate: Element,
|}

export type IdentityUser = {|
  profile: Element,
  login: Element,
  logout: Element,
  setPassword: Element,
  fetch: BaseService,
  groups: Element,
  manage: IdentityUserManage,
|}

export type IdentityCompany = {|
  create: Element,
  list: Element,
  fetch: BaseService,
|}

export type IdentityCampaign = {|
  create: Element,
  list: Element,
  fetch: BaseService,
|}

export type IdentityTeam = {|
  create: Element,
  list: Element,
  fetch: BaseService,
|}

export type IdentityGroups = {|
  list: Element,
|}

export type IdentityLocation = {|
  create: Element,
  list: Element,
  fetch: BaseService,
|}

export type IdentityRealm = {|
  create: Element,
  list: Element,
  fetch: BaseService,
|}

export default class IdentityService {
  ACLS: Object
  DIDS: Object
  User: IdentityUser
  Campaign: IdentityCampaign
  Company: IdentityCompany
  Team: IdentityTeam
  Groups: IdentityGroups
  Location: IdentityLocation
  Realm: IdentityRealm
  // FIXME: properly flow type the AccessListService
  Acl: Object
  // FIXME: properly flow type the DidListService
  Did: Object
  DidPool: Object

  constructor(
    Restangular: BaseService,
    AccessListService: Object,
    DidListService: Object,
  ) {
    'ngInject'
    // FIXME: these two services really shouldn't reside within the Identity Service
    this.ACLS = AccessListService
    this.DIDS = DidListService
    this.User = {
      profile: Restangular.oneUrl('identity/user/profile'),
      login: Restangular.all('identity/user/login'),
      logout: Restangular.oneUrl('identity/user/logout'),
      groups: Restangular.oneUrl('identity/user/groups'),
      setPassword: Restangular.all('identity/user/set_password'),
      manage: {
        create: Restangular.all('identity/user/manage/create'),
        createBulk: Restangular.all('identity/user/manage/create/bulk'),
        list: Restangular.oneUrl('identity/user/manage/list'),
        fetch: Restangular.service('identity/user/manage'),
        unique: {
          email: Restangular.service('identity/user/manage/check_email'),
          username: Restangular.service('identity/user/manage/check_username'),
        },
        setPassword: Restangular.all('identity/user/manage/set_password'),
        impersonate: Restangular.oneUrl('identity/user/manage/impersonate/'),
      },
      fetch: Restangular.service('identity/user'),
    }

    this.Campaign = {
      create: Restangular.all('identity/campaign/create'),
      list: Restangular.oneUrl('identity/campaign/list'),
      fetch: Restangular.service('identity/campaign'),
    }

    this.Company = {
      create: Restangular.all('identity/company/create'),
      list: Restangular.oneUrl('identity/company/list'),
      fetch: Restangular.service('identity/company'),
    }

    this.Team = {
      create: Restangular.all('identity/team/create'),
      list: Restangular.oneUrl('identity/team/list'),
      fetch: Restangular.service('identity/team'),
    }

    this.Groups = {
      list: Restangular.oneUrl('identity/group/list'),
    }

    this.Location = {
      create: Restangular.all('identity/location/create'),
      list: Restangular.oneUrl('identity/location/list'),
      fetch: Restangular.service('identity/location'),
    }

    this.Realm = {
      create: Restangular.all('identity/realm/create'),
      list: Restangular.oneUrl('identity/realm/list'),
      fetch: Restangular.service('identity/realm'),
    }

    this.Acl = {
      list: this.ACLS.list,
      create: this.ACLS.create,
      fetch: this.ACLS.list,
      update: this.ACLS.update,
    }

    this.Did = {
      list: this.DIDS.list,
      one: this.DIDS.one.one,
      all: this.DIDS.listAll,
      edit: this.DIDS.edit,
    }

    this.DidPool = {
      list: this.DIDS.listPool,
      one: this.DIDS.onePool.one,
      cnam: this.DIDS.onePoolCnam.get,
    }
  }
}
