// Edit Campaign view

const manageEditPool = 'manage.editDidPool'

const manageEditPoolConfig = {
  url: '/did-pool/edit/{uuid}',
  params: {
    uuid: null,
    entity: null,
    entityIndex: null,
  },
  component: 'editDidPool',
  resolve: {
    pool: function($transition$, DidListService) {
      'ngInject'
      const entity = $transition$.params().entity
      if (entity) return entity
      return DidListService.listPool
        .one($transition$.params().uuid)
        .then(didPool => didPool.plain())
    },
    companies: function(IdentityService) {
      'ngInject'
      return IdentityService.Company.list
        .get({ assignable: true })
        .then(comps => comps.plain())
    },
    campaigns: function(IdentityService, pool) {
      'ngInject'
      return IdentityService.Campaign.list
        .get({ assignable: true, company: pool.company })
        .then(camps => camps.plain())
    },
    voiceProviders: function(DidListService) {
      'ngInject'
      return DidListService.listVoiceProvider.get().then(provs => provs.plain())
    },
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Edit Did Pool'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}
export { manageEditPool, manageEditPoolConfig }
