import RapidResponseTestsVariablesController from './controller/RapidResponseTestsVariables.js'
import template from './rapidResponseTestsVariables.component.tpl.pug'

const editRapidResponseTestsVariables = {
  template: template(),
  controller: RapidResponseTestsVariablesController,
  bindings: {
    compSlug: '<',
    compUuid: '<',
    rapidResponseTest: '<',
  },
}

export default editRapidResponseTestsVariables
