import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// Manage Global Pitch PhraseBooks

const manageGlobalPitchPhraseBooks = 'manage.globalPitchPhraseBooks'
const pageTitleConfig = {
  title: 'Manage Phrase Books'
}

const manageGlobalPitchPhraseBooksConfig = {
  url: '/global-pitch-phrasebooks',
  component: 'manageGlobalPitchPhraseBooks',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageGlobalPitchPhraseBooks, manageGlobalPitchPhraseBooksConfig }
