function createRep (agent) {
  return {
    rep: agent.user.full_name,
    location: agent.location.name,
    team: agent.team.name,
    station_number: agent.replogin.count,
    stations: agent.replogin.results.map(createStations)
  }
}

function createStations (rep) {
  return {
    name: rep.station ? rep.station.username : 'No Identified',
    color: rep.open_session ? rep.open_session.rep_status.color : null
  }
}

export { createRep }
