import CreateDidPoolController from './controller/CreateDidPoolController.js'
import template from './createDidPool.tpl.pug'

const createTests = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreateDidPoolController,
}

export default createTests
