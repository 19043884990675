// @flow

import type { User, Campaign, Pitch } from 'types/entities.js'
import type { PitchDetailFields } from '../../../services/types.js'
import type { GlobalAndMaybeCompanyCampaign } from '../../../../../global/index/api-services/types.js'
import type ToastService from '../../../../../global/index/services/ToastService.js'
import type PitchService from '../../../../../global/index/api-services/PitchService.js'
import type EditPitchService from '../../../services/EditPitchService.js'

type UrlProp =
  | 'details'
  | 'segments'
  | 'variables'
  | 'voices'
  | 'xml'
  | 'panels'
  | 'option-groups'
  | 'phrase-books'
  | 'rapid-response-tests'

const LOADING_MESSAGE = 'Loading Pitch'
const CREATING_MESSAGE = 'Creating Pitch'

export default class CampaignPitchController {
  user: User // passed to component
  campaign: Campaign // passed to component
  currentTab: string // passed to component
  urlProp: string // passed to component
  $state: Object
  PS: PitchService
  EPS: EditPitchService
  MS: Object
  TS: ToastService
  GlobalPhraseBookService: Object
  GlobalSegmentService: Object
  GlobalRapidResponseService: Object
  GuiService: Object
  PhraseBookService: Object
  RapidResponseService: Object
  SegmentService: Object
  CampPitchService: Object
  XmlRawService: Object
  pitch: Pitch

  state: {|
    loadingMessage: string,
    loading: boolean,
    noPitch: boolean,
    serverPitchError: boolean,
  |}

  activeTab: {
    details: boolean,
    segments: boolean,
    voices: boolean,
    xml: boolean,
    panels: boolean,
    optionGroups: boolean,
    phraseBooks: boolean,
  }

  constructor(
    $state: Object,
    PitchService: PitchService,
    EditPitchService: EditPitchService,
    ManagementService: Object,
    ToastService: ToastService,
  ) {
    'ngInject'

    this.$state = $state
    this.MS = ManagementService
    this.PS = PitchService
    this.EPS = EditPitchService
    this.TS = ToastService

    this.state = {
      loadingMessage: LOADING_MESSAGE,
      loading: true,
      noPitch: false,
      serverPitchError: false,
    }

    this.activeTab = {
      details: false,
      segments: false,
      voices: false,
      xml: false,
      panels: false,
      optionGroups: false,
      phraseBooks: false,
      RapidResponseTests: false,
    }
  }

  $onInit() {
    this.MS.populateEntityRelations(this.campaign, { entityName: 'campaign' })
      .then(this.PS.setupBase.bind(this.PS))
      .then(this.setupServices.bind(this))
      .then(this.getPitch.bind(this))
      .then(this.setupPitch.bind(this))
      .catch(this.handleInitializationErrors.bind(this))
      .finally(() => {
        this.state.loading = false
      })
    this.activateTab(this.currentTab)
  }

  activateTab(currentTab: string) {
    this.activeTab[currentTab] = true
  }

  setupPitch(pitch: Pitch) {
    this.pitch = pitch
    this.state.noPitch = false
  }

  setupServices(baseService: GlobalAndMaybeCompanyCampaign) {
    const { company, companyCampaign, global } = baseService

    if (global && global.one) {
      this.GlobalPhraseBookService = global.one('phrases')
      this.GlobalSegmentService = global.one('segments')
      this.GlobalRapidResponseService = global.one('rapid-response')
    }

    if (company && company.one) {
      this.GuiService = company.one('gui')
      this.PhraseBookService = company.one('phrases')
      this.RapidResponseService = company.one('rapid-response')
      this.SegmentService = company.one('segments')
    }

    if (companyCampaign && companyCampaign.one) {
      // Setup the base services for a particular campaign
      this.CampPitchService = companyCampaign.one('')
      this.XmlRawService = this.CampPitchService.one('raw')
    }
  }

  createPitch(pitchName: string) {
    this.state.loadingMessage = CREATING_MESSAGE
    this.CampPitchService.customPOST({
      name: pitchName,
      segments: [],
      voices: [],
    })
      .then(pitch => {
        this.pitch = pitch.plain()
        this.state.noPitch = false
      })
      .catch(err => {
        this.TS.show({
          text: 'Error Creating Pitch',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
        this.state.loadingMessage = LOADING_MESSAGE
      })
  }

  updatePitch(fields: PitchDetailFields) {
    return this.EPS.updatePitchDetails(fields, this.CampPitchService).then(
      updatedPitch => {
        this.pitch = updatedPitch
        return updatedPitch
      },
    )
    // errors handled in child component pitchDetails
  }

  commitPitch() {
    return this.CampPitchService.one(`${this.user.uuid}/commit`).post()
  }

  updateSegments(segments: Array<{ committed: boolean, uuid: string }>) {
    this.CampPitchService.patch({ segments }).then(updatedPitch => {
      this.pitch = updatedPitch.plain()
      return updatedPitch.plain()
    })
  }

  updatePhraseBooks(phraseBooks: Array<{ company: ?string, uuid: string }>) {
    this.CampPitchService.patch({ phrase_books: phraseBooks }).then(
      updatedPitch => {
        this.pitch = updatedPitch.plain()
        return updatedPitch.plain()
      },
    )
  }

  updateRapidResponseTests(rrTests: Array<{ company: ?string, uuid: string }>) {
    this.CampPitchService.patch({ rapid_response_tests: rrTests }).then(
      updatedPitch => {
        this.pitch = updatedPitch.plain()
        return updatedPitch.plain()
      },
    )
  }

  updateVariables(variables: { [string]: string }) {
    this.CampPitchService.patch({ variables: variables }).then(updatedPitch => {
      this.pitch = updatedPitch.plain()
      return updatedPitch.plain()
    })
  }

  handleInitializationErrors(err: Object) {
    if (err.status === 404) {
      this.state.noPitch = true
    } else {
      this.state.serverPitchError = true
      this.TS.show({
        text: 'Error Fetching Pitch',
      })
      console.error('Error Fetching Pitch', err)
    }
  }

  getPitch() {
    return this.CampPitchService.get().then(pitch => pitch.plain())
  }

  updateStateUrl(urlProp: UrlProp) {
    this.$state.go('manage.editCampaign.pitch', { urlProp })
  }
}
