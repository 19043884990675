import './auth.scss'

import angular from 'angular'

// services
import AuthService from './services/AuthService.js'

import authSignIn from './sign-in/auth.sign-in.js'

export default angular
  .module('portalApp.auth', [authSignIn])
  .service('AuthService', AuthService).name
