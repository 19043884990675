import './pitchPanel.scss'
import PitchPanelController from './controller/PitchPanelController.js'
const template = require('./pitchPanel.component.tpl.pug')

const pitchPanel = {
  template: template(),
  controller: PitchPanelController,
  bindings: {
    panel: '<',
    baseService: '<',
    removePanel: '&',
  }
}

export default pitchPanel

