import CompanyPhraseBooksController from './controller/CompanyPhraseBooksController.js'
const template = require('./companyPhraseBooks.component.tpl.pug')

const CompanyPhraseBooks = {
  controller: CompanyPhraseBooksController,
  template: template(),
  bindings: {
    company: '<'
  }
}

export default CompanyPhraseBooks
