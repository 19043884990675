// manage users edit view

const manageUsersEdit = 'manage.users.edit'

/*
| used a template here instead of component to allow the passing of
| callback functions from the parent view to child
| this is due to a limitation of angular-ui-router
| may be fixed at a future date
| https://github.com/angular-ui/ui-router/issues/2896
*/

const manageUsersEditConfig = {
  url: '/edit/{username}',
  params: {
    entity: null,
    username: null,
    entityIndex: null,
  },
  template: `<manage-users-edit
                entity="$resolve.entity"
                entity-index="$resolve.entityIndex"
                on-entity-update="$ctrl.onUserUpdate(user, userIndex)">
             </manage-users-edit>`,
  resolve: {
    entity: function ($transition$, $state) {
      'ngInject'
      const entity = $transition$.params().entity
      if (!entity) return $state.go('manage.users')
      return entity
    },
    entityIndex: function ($transition$) {
      'ngInject'
      return $transition$.params().entityIndex
    },
  }
}

export { manageUsersEdit, manageUsersEditConfig }
