import { contains, map, reduce } from 'ramda'

function doesNotRequireDialed(context) {
  return contains(context, ['list'])
}

function wasDialed(stats) {
  return stats.billable_hours && stats.payable_hours
}

function reduceMetricsForHour(hour) {
  const hourStamp = hour.date * 1000 // convert to milliseconds
  return function(acc, metric) {
    acc[metric.key] = metric.calc(metric, hour.data, hourStamp)
    return acc
  }
}

function calculateMetricsForHour(hour, metrics) {
  return reduce(reduceMetricsForHour(hour), {}, metrics)
}

function hourMapper(day, metrics, context) {
  return function(hour) {
    const stats = hour.data.stats || {}
    const dialed = wasDialed(stats) || doesNotRequireDialed(context)
    const hourMetrics = calculateMetricsForHour(hour, metrics)
    return { metrics: hourMetrics, date: day.date, dialed: dialed }
  }
}

function dayMapper(metrics, context) {
  return function(day) {
    return {
      hours: map(hourMapper(day, metrics, context), day.data),
      date: day.date,
    }
  }
}

function calcMetricsByHour(data, context) {
  // add a <context:String> when calling to fork logic for specific entities (campaign, list, rep)
  // if left out the hour will only be calculated if it contains billable_hours && payable_hours
  // see doesNotRequireDialed() function definition for contexts that don't require billable_hours and payable_hours
  return {
    days: map(dayMapper(data.metrics, context), data.stats),
    metrics: data.metrics,
  }
}

export { calcMetricsByHour }
