import './userBulkUploadDialog.scss'
import UserBulkUploadController from './controller/UserBulkUploadController.js'
const template = require('./userBulkUploadDialog.tpl.pug')

const userBulkUploadDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: UserBulkUploadController
}

export default userBulkUploadDialog
