// @flow

import * as React from 'react'
import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

type Props = {
    classes: Object,
    title: String,
    respose: String
}

const styles = {
    button: {
        padding: '5px',
        margin: '5px',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    },
    icon: {
        color: '#444851',
        height: '16.2px'
    }
}


function CopyElement(props: Props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const copyToClipboard = e => {
        var textField = document.createElement('textarea')
        textField.innerText = e.currentTarget.value;
        document.body.appendChild(textField);
        textField.select();
        textField.focus();
        document.execCommand('copy');
        textField.remove();
        handleClick();
    }

    return (
        <div style={{ display: 'inline-block' }}>
            <Tooltip title={props.title}>
                <IconButton disableRipple={true} aria-label="copy" className={props.classes.button} value={props.value} onClick={copyToClipboard}>
                    <FileCopy className={props.classes.icon} />
                </IconButton>
            </Tooltip>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{props.response}</span>}
            />
        </div>
    )
}

CopyElement.defaultProps = {
    title: 'Copy element',
    response: 'Element copied to clipboard'
}

export default withStyles(styles)(CopyElement)
