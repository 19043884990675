// manage users
import { manageUsers, manageUsersConfig } from './manage.users.js'
import {
  manageUsersCreate,
  manageUsersCreateConfig,
} from './child-states/manage-users-create/manage.users.create.js'
import {
  manageUsersCreatePersonalInfo,
  manageUsersCreatePersonalInfoConfig,
} from './child-states/manage-users-create/child-states/personal-info/manage.users.create.personalInfo.js'
import {
  manageUsersCreateRoleInfo,
  manageUsersCreateRoleInfoConfig,
} from './child-states/manage-users-create/child-states/role-info/manage.users.create.roleInfo.js'
import {
  manageUsersCreateCompanyInfo,
  manageUsersCreateCompanyInfoConfig,
} from './child-states/manage-users-create/child-states/company-info/manage.users.create.companyInfo.js'
import {
  manageUsersCreateCampaignInfo,
  manageUsersCreateCampaignInfoConfig,
} from './child-states/manage-users-create/child-states/campaign-info/manage.users.create.campaignInfo.js'
import {
  manageUsersCreateTeamInfo,
  manageUsersCreateTeamInfoConfig,
} from './child-states/manage-users-create/child-states/team-info/manage.users.create.teamInfo.js'
import {
  manageUsersCreatePasswordInfo,
  manageUsersCreatePasswordInfoConfig,
} from './child-states/manage-users-create/child-states/password-info/manage.users.create.passwordInfo.js'
import {
  manageUsersEdit,
  manageUsersEditConfig,
} from './child-states/manage-users-edit/manage.users.edit.js'

export default function manageUsersRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageUsers, manageUsersConfig)
    .state(manageUsersCreate, manageUsersCreateConfig)
    .state(manageUsersCreatePersonalInfo, manageUsersCreatePersonalInfoConfig)
    .state(manageUsersCreateRoleInfo, manageUsersCreateRoleInfoConfig)
    .state(manageUsersCreateCompanyInfo, manageUsersCreateCompanyInfoConfig)
    .state(manageUsersCreateCampaignInfo, manageUsersCreateCampaignInfoConfig)
    .state(manageUsersCreateTeamInfo, manageUsersCreateTeamInfoConfig)
    .state(manageUsersCreatePasswordInfo, manageUsersCreatePasswordInfoConfig)
    .state(manageUsersEdit, manageUsersEditConfig)
}
