import TimerController from './controller/TimerController.js'
const template = require('./timer.component.tpl.pug')

const timer = {
  controller: TimerController,
  template: template(),
  bindings: {
    startTimer: '<',
    stopTimer: '<',
    resetTimer: '<',
  }
}

export default timer

