import {
  CAMPAIGN_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'callsPerBillableHour',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Calls / Billable Hour',
  },
}

const keys = {
  componentKeys: [
    CAMPAIGN_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'calls' },
  divisor: { stat: 'billable_hours', convertTo: 'hours' },
}

const callsPerBillableHour = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { callsPerBillableHour }
