import { REP_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'repsLive',
  type: 'repsLive',
}

const formatting = {
  format: {
    title: 'Agents',
  },
}

const keys = {
  componentKeys: [REP_METRICS],
  healthKey: 'reps_live',
}
const repsLive = {
  metaData: Object.assign(identifiers, formatting, keys),
}

export { repsLive }
