import { clone } from 'ramda'

export default class SimpleBarChart {
  constructor($timeout) {
    'ngInject'

    this.barChart = {
      options: {
        chart: {
          type: 'bar',
          marginBottom: 75,
          events: {
            drilldown: undefined,
          },
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          min: 0,
          max: 10,
          title: {
            text: '',
            align: 'right',
          },
          labels: {
            overflow: 'justify',
            format: '',
          },
        },
        drilldown: {
          series: [],
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        tooltip: {},
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      series: [],
      func: function(chart) {
        $timeout(() => {
          chart.reflow()
        }, 200)
      },
    }
  }

  getChart() {
    return clone(this.barChart)
  }

  dynamicHeight(seriesLength) {
    return seriesLength * 28 + 150
  }
}
