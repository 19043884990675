import { MANAGE_GROUP } from 'permissions.js'
// manage companies view

const manageCompanies = 'manage.companies'

const manageCompaniesConfig = {
  url: '/companies',
  component: 'manageCompanies',
  data: {
    permissions: {
      only: [ ...MANAGE_GROUP ],
      redirectTo: 'gateway',
    }
  },
  resolve: {
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Manage Companies'
      $window.document.title = pageTitle
      return pageTitle
    }
  }
}

export { manageCompanies, manageCompaniesConfig }
