import moment from 'moment'
import { contains, find, propEq } from 'ramda'
import changePasswordDialog from '../../../../../dialogs/change-password/changePassword.js'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'
import { IMPERSONATE } from 'util/event.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class ManageUsersEditController {
  constructor(
    $document,
    $q,
    $timeout,
    $state,
    $mdDialog,
    $sessionStorage,
    $scope,
    IdentityService,
    ToastService,
    ManageUsersEditService,
    $element
  ) {
    'ngInject'
    this.$element = $element
    this.$scope = $scope
    this.$sessionStorage = $sessionStorage
    this.$mdDialog = $mdDialog
    this.$document = $document
    this.$q = $q
    this.$timeout = $timeout
    this.$state = $state
    this.IS = IdentityService
    this.TS = ToastService
    this.MUES = ManageUsersEditService
    this.ADMIN_GROUP = ADMIN_GROUP
    
    this.eventHandler = e => {
      if (e.keyCode === 27) {
        this.backTo()
      }
    }
  }

  $onInit() {
    this.state = {
      loading: false,
      isSelectedCompanyActive: true,
      isEditingSelf: this.entity.uuid === this.$sessionStorage.user.uuid,
    }
    
    this.$document.on('keydown', this.eventHandler)
    this.$element.find('input').on('keydown', function(ev) {
      ev.stopPropagation()
    })
    this.fetchUser(this.entity)
  }

  $onDestroy() {
    this.$document.off('keydown', this.eventHandler)
  }

  backTo() {
    this.$state.go('manage.users')
  }

  cancelEdit(form) {
    this.state.editableUser = Object.assign({}, this.state.pristineUser)
    form.$setPristine()
  }

  fetchUser(user) {
    const parsedHireDate = user.hire_date
      ? { hire_date: moment(user.hire_date).toDate() }
      : null
    this.IS.User.manage.fetch
      .one(user.uuid)
      .get()
      .then(user => user.plain())
      .then(user => Object.assign(user, parsedHireDate))
      .then(user => {
        this.user = user
        this.state.pristineUser = user
        this.state.editableUser = { ...user }
        this.fetchRelations()
      })
  }

  updateUser(user) {
    if (this.state.isEditingSelf) delete user.groups
    this.MUES.updateUser(user)
      .then(updatedUser => {
        this.onEntityUpdate({ user: updatedUser, userIndex: this.entityIndex })
        this.entity = Object.assign({}, updatedUser)
        this.state.editableUser = Object.assign({}, updatedUser)
        this.editUserForm.$setPristine()
      })
      .catch(err => {
        console.error(err)
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error Updating User',
        })
      })
  }

  fetchRelations() {
    this.state.loading = true
    return this.MUES.fetchRelations()
      .then(relation => {
        this.teamSelection = relation.teams
        this.companySelection = relation.companies
        this.campaignSelection = relation.campaigns
        this.groupsSelection = relation.groups
        this.checkCompanyActive(
          this.state.editableUser.company,
          relation.companies,
        )
        this.$timeout(() => {
          this.editUserForm.$setPristine()
          this.state.loading = false
        }, 500)
      })
      .catch(() => {
        this.state.loading = false
      })
  }

  getSelectedCampaign(companyId){
    return this.campaignSelection.filter(camp => camp.company === companyId).map(item => {
      return item.uuid
    })
  }

  checkCompanyActive(companyId, companies) {
    if (companyId === 'null' || !companyId) {
      this.state.isSelectedCompanyActive = true
      return
    }else{
      this.state.editableUser.campaigns.forEach(item => {
        if(!this.getSelectedCampaign(companyId).includes(item)){
          this.state.editableUser.campaigns = []
        }
      })
    }
    const selectedCompany = find(propEq('uuid', companyId))(companies)
    this.state.isSelectedCompanyActive = selectedCompany.active
  }

  containsGroup(pk, userGroups) {
    return contains(pk, userGroups)
  }

  impersonate(user) {
    this.MUES.impersonate(user.uuid)
      .then(({ user, nextState }) => {
        this.$scope.$emit(IMPERSONATE, user)
        this.$state.go(nextState, null, { reload: true })
        console.warn('Impersonation beginning')
      })
      .catch(err => {
        this.TS.show({
          text: 'Error impersonating.',
        })
        console.error(err)
      })
  }

  changePassword(user) {
    const CPD = changePasswordDialog
    CPD.locals = {
      userId: user.uuid,
    }
    this.$mdDialog.show(CPD)
  }

  deleteUser(user) {
    const DUD = deleteEntityDialog
    DUD.locals = {
      title: 'Delete User',
      entityName: user.username,
      identityEndpoint: this.IS.User.manage.fetch.one(user.uuid),
      entity: user,
      routeWhenSuccessful: 'manage.users',
    }
    this.$mdDialog.show(DUD)
  }
}
