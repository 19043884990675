import PitchVariablesController from './controller/pitchVariables.controller'
const template = require('./pitchVariables.component.tpl.pug')

const pitchVariables = {
  template: template(),
  controller: PitchVariablesController,
  bindings: {
    variables: '<',
    pitchService: '<',
    onUpdate: '&'
  }
}

export default pitchVariables
