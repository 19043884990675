import './manageAudioResources.scss'
import ManageAudioResourcesController from './controller/ManageAudioResourcesController.js'
import template from './manageAudioResources.component.tpl.pug'

const manageAudioResources = {
  template: template(),
  controller: ManageAudioResourcesController,
  bindings: {},
}

export default manageAudioResources
