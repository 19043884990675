import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'invalidCallRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Invalid Call Rate',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'invalid_calls' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const invalidCallRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { invalidCallRate }
