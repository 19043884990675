import ManageCampaignsController from '../../../../../manage-campaigns/component/controller/ManageCampaignsController.js'

export default class RapidResponseTestsCampaignsController extends ManageCampaignsController {
  constructor(
    $state,
    $mdDialog,
    ManagementService,
    ToastService,
    RoleStore,
    ManageCampaignsService,
  ) {
    'ngInject'
    super(...arguments)
  }
}
