// dashboard scss
import './dashboard.scss'

import angular from 'angular'

// modules
import dashboardOverview from './overview/dashboard.overview.js'
import dashboardRepTrend from './rep-trend/dashboard.repTrend.js'
import dashboardRepRankings from './rep-rankings/dashboard.repRankings.js'
import dashboardLists from './lists/dashboard.lists.js'
import dashboardListOverview from './list-overview/dashboard.listOverview.js'
import dashboardGoals from './goals/dashboard.goals.js'
import dashboardDialerLive from './dialer-live/dashboard.dialerLive.js'
import dashboardDialerQueue from './dialer-queue/dashboard.dialerQueue.js'
import dashboardAgents from './agents/dashboard.agents.js'

// services
import StatsService from './services/stats/StatsService.js'
import MetricsService from './services/metrics/MetricsService.js'
import CampaignStatsService from './services/campaign/stats/CampaignStatsService.js'
import RepStatsService from './services/rep/stats/RepStatsService.js'
import SimpleBarChart from './services/charts/SimpleBarChart.js'
import PieChart from './services/charts/PieChart.js'
import DualAxisSplineChart from './services/charts/DualAxisSplineChart.js'
import StackedBarChart from './services/charts/StackedBarChart.js'
import MetricSeriesService from './services/metrics/MetricSeriesService.js'
import BillableHoursTrackingService from './services/billable-hours-tracking/BillableHoursTrackingService.js'
import DashboardMenuService from './services/dashboard-menu/DashboardMenuService.js'

// directives
import downloadable from './directives/downloadable/downloadable.js'

// components
import dashboardComponent from './component/dashboard.component.js'
import dateRangePicker from './sub-components/date-range-picker/dateRangePicker.js'
import sumTotalTable from './sub-components/sum-total-table/component/sumTotalTable.js'

export default angular
  .module('portalApp.dashboard', [
    dashboardOverview, dashboardRepTrend, dashboardRepRankings,
    dashboardLists, dashboardListOverview, dashboardGoals, dashboardDialerLive,
    dashboardDialerQueue, dashboardAgents
  ])
  .service('StatsService', StatsService)
  .service('MetricsService', MetricsService)
  .service('CampaignStatsService', CampaignStatsService)
  .service('MetricSeriesService', MetricSeriesService)
  .service('BillableHoursTrackingService', BillableHoursTrackingService)
  .service('RepStatsService', RepStatsService)
  .service('SimpleBarChart', SimpleBarChart)
  .service('DualAxisSplineChart', DualAxisSplineChart)
  .service('PieChart', PieChart)
  .service('StackedBarChart', StackedBarChart)
  .service('DashboardMenuService', DashboardMenuService)
  .directive('downloadable', downloadable)
  .component('dashboard', dashboardComponent)
  .component('dateRangePicker', dateRangePicker)
  .component('sumTotalTable', sumTotalTable)
  .name
