// @flow

import type ToastService from '../../../../../global/index/services/ToastService.js'
import type { PermissionGroup } from 'permissions.js'
import type { Campaign, Company, Realm } from 'types/entities.js'

import deleteEntityDialog from '../../../../dialogs/delete-entity/deleteEntity.js'
import { SUPER_USER_ONLY } from 'permissions.js'

export default class CampaignSettingsController {
  $state: Object
  $timeout: Function
  $mdDialog: Object
  MS: Object
  TS: ToastService
  IS: Object
  SS: Object
  SUPER_USER_ONLY: PermissionGroup
  campaignEditForm: Object
  campaign: Campaign
  editableCampaign: Campaign
  state: {|
    companies?: Array<Company>,
    realms?: Array<Realm>,
    isDialing: boolean,
    dialingRealms: Array<{ realm: string }>,
    loading: {
      details: boolean,
    },
    show: {
      details: boolean,
    },
  |}

  constructor(
    $state: Object,
    $timeout: Function,
    $mdDialog: Object,
    ManagementService: Object,
    IdentityService: Object,
    ToastService: ToastService,
    StatsService: Object,
  ) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.TS = ToastService
    this.IS = IdentityService
    this.MS = ManagementService
    this.SS = StatsService
    this.SUPER_USER_ONLY = SUPER_USER_ONLY

    this.state = {
      isDialing: false,
      dialingRealms: [],
      loading: {
        details: true,
      },
      show: {
        details: false,
      },
    }
  }

  $onInit() {
    this.editableCampaign = Object.assign({}, this.campaign)
    this.getCampaignRelationOptions(['company', 'realm'])
  }

  $onChanges() {
    console.log('onChanges')
    console.log(this.selectedIndex)
  }

  createNewRoute(campaign: Campaign) {
    this.$state.go('manage.editCampaign.settings.create.routerInfo', {
      campaign,
    })
  }

  updateCampaign(campaign: Campaign) {
    campaign = {
      ...campaign,
      dialingparams: campaign.dialingparams ? campaign.dialingparams : {},
      queue: campaign.queue ? campaign.queue : {},
      warmtransfer: campaign.warmtransfer ? campaign.warmtransfer : {},
      callback: campaign.callback ? campaign.callback : {},
    }
    this.MS.updateEntity({ entity: 'campaign', data: campaign })
      .then(updatedCampaign => {
        if (
          typeof this.campaign.company !== 'string' &&
          updatedCampaign.company === this.campaign.company.uuid
        ) {
          this.campaign = Object.assign({}, updatedCampaign, {
            company: this.campaign.company,
          })
          this.campaignEditForm.$setPristine()
        } else {
          this.MS.populateEntityRelations(updatedCampaign, {
            entityName: 'campaign',
          }).then(populatedCampaign => {
            this.campaign = populatedCampaign
            this.campaignEditForm.$setPristine()
          })
        }
        this.TS.show({
          text: 'Campaign successfully updated',
        })
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error updating campaign',
        })
      })
  }

  checkDialing() {
    return this.SS.dialerLive.get
      .call(this.SS, { campaigns: [this.campaign.slug] })
      .then(res => {
        if (res.length) {
          this.state.isDialing = true
          this.state.dialingRealms = res[0].realms
        }
      })
  }

  getCampaignRelationOptions(relationOptions: ['company', 'realm']) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.state.companies = relations.company
        this.state.realms = relations.realm
        return relations
      })
      // .then(this.checkDialing.bind(this))
      // .catch(err => {
      //   console.error(err)
      //   this.TS.show({
      //     text: 'Error fetching campaign relations',
      //   })
      // })
      .finally(() => {
        this.$timeout(() => {
          this.state.loading.details = false
          this.campaignEditForm.$setPristine()
        }, 1000)
      })
  }

  cancelEdit(form: Object) {
    this.editableCampaign = Object.assign({}, this.campaign)
    form.$setPristine()
  }

  toggleActive(active: boolean) {
    this.editableCampaign.active = active
  }

  handleCopy() {
    this.TS.show({
      text: 'Copied To Clipboard',
    })
  }

  deleteCampaign(campaign: Campaign) {
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Campaign',
      entityName: campaign.name,
      identityEndpoint: this.IS.Campaign.fetch.one(campaign.uuid),
      entity: campaign,
      routeWhenSuccessful: 'manage.campaigns',
    }
    this.$mdDialog.show(DCD)
  }
}
