import './emptyState.scss'
import template from './emptyState.tpl.pug'

const emptyState = {
  template: template(),
  bindings: {
    pluralEntityName: '@',
  },
}

export default emptyState
