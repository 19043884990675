import CreatePhraseFormController from './controller/CreatePhraseFormController.js'
import template from './createPhraseForm.tpl.pug'

const createPhraseForm = {
  template: template(),
  controller: CreatePhraseFormController,
  bindings: {
    service: '<',
  },
}

export default createPhraseForm
