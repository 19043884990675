import EditCampaignController from './controller/EditCampaignController.js'
const template = require('./editCampaign.component.tpl.pug')

const editCampaign = {
  controller: EditCampaignController,
  template: template(),
  bindings: {
    campaign: '<',
  },
}

export default editCampaign
