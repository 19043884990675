import './readOnlyTable.scss'
import ReadOnlyTableController from './controller/ReadOnlyTableController.js'
const template = require('./readOnlyTable.tpl.pug')

const readOnlyTable = {
  template: template(),
  controller: ReadOnlyTableController,
  bindings: {
    listName: '@',
    entity: '@',
    entityPlural: '@',
    placeholderText: '@',
    showSearch: '<',
    onSearch: '<',
    searchText: '<',
    isLoading: '<',
    tableData: '<',
    tableConfig: '<'
  }
}

export default readOnlyTable
