import { REP_METRICS, REP_STACK_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'interruptRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Interrupt Rate',
    sort: 'ascending',
  },
}

const keys = {
  componentKeys: [REP_METRICS, REP_STACK_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'interrupt' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const interruptRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { interruptRate }
