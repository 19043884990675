// Edit Campaign Pitch View

const manageEditCampaignPitch = 'manage.editCampaign.pitch'

const manageEditCampaignPitchConfig = {
  url: '/pitch/{urlProp}',
  component: 'campaignPitch',
  params: {
    urlProp: { dynamic: true, value: 'details' },
  },
  resolve: {
    urlProp: function($transition$) {
      'ngInject'
      return $transition$.params().urlProp
    },
    currentTab: function(urlProp) {
      'ngInject'
      // camel cases the urlProp for idiomatic use in controller
      return urlProp.split('-').reduce(function(acc, piece, i) {
        i < 1
          ? (acc += piece)
          : (acc += piece[0].toUpperCase() + piece.substr(1))
        return acc
      })
    },
  },
}

export { manageEditCampaignPitch, manageEditCampaignPitchConfig }
