import angular from 'angular'

// components
import manageGlobalRapidResponse from './component/manageGlobalRapidResponse.component.js'
import rapidResponseSegments from './sub-components/rapid-response-segments/component/rapidResponseSegments.component'
import rapidResponseTests from './sub-components/rapid-reponse-tests/component/rapidResponseTests.component'

export default angular
  .module('portalApp.manage.globalRapidResponse', [])
  .component('manageGlobalRapidResponse', manageGlobalRapidResponse)
  .component('rapidResponseSegments', rapidResponseSegments)
  .component('rapidResponseTests', rapidResponseTests)
  .name
