// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Details from 'manage/edit-campaign-qa-prospect/sub-component/DetailsTab'
import CallHistory from 'manage/edit-campaign-qa-prospect/sub-component/CallHistory'
import Upload from 'manage/edit-campaign-qa-prospect/sub-component/Upload'

const API_BASE_URL = getBaseUrl(window.location.host, true)

const styles = {
  root: {
    margin: '20px 0',
    width: '100%',
  },
  header: {
    color: '#444851',
    fontSize: '14px',
    backgroundColor: '#fff',
  },
  pre: {
    backgroundColor: '#f5f5f5',
    padding: '0 20px',
    color: '#444851',
  },
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent!important',
    '& > div': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#f89523',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: '100px',
  },
}))(props => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  }
}

function ProspectPanel(props) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={props.classes.root}>
      <AppBar position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          className={props.classes.header}
          variant="fullWidth"
          aria-label="wrapped label tabs"
        >
          <StyledTab label="DETAILS" {...a11yProps(0)} />
          <StyledTab label="PROSPECT CALL HISTORY" wrapped {...a11yProps(1)} />
          <StyledTab label="CALL LOG " {...a11yProps(2)} />
          <StyledTab label="PITCH VARS" {...a11yProps(3)} />
          <StyledTab label="TRANSFERS" {...a11yProps(4)} />
          <StyledTab label="POSTING" {...a11yProps(5)} />
          <StyledTab label="UPLOAD" {...a11yProps(6)} />
          <StyledTab label="CDR" {...a11yProps(7)} />
          <StyledTab label="REP INFO" {...a11yProps(8)} />
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Details />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CallHistory />
      </TabPanel>
      <TabPanel value={value} index={2}>
        CALL LOG
      </TabPanel>
      <TabPanel value={value} index={3}>
        PITCH VARS
      </TabPanel>
      <TabPanel value={value} index={4}>
        TRANSFERS
      </TabPanel>
      <TabPanel value={value} index={5}>
        POSTING
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Upload />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <p>
          <span style={{ color: '#444851' }}>Prospect:</span>&emsp;
          <span style={{ color: '#777777' }}>
            eb37d2a0-eaa8-11e8-a215-5254001397e0
          </span>
        </p>

        <pre className={props.classes.pre}>{`
<?xml version="1.0" encoding="ISO-8859-1"?>  
    <note>  
        <to>Tove</to>  
        <from>Jani</from>  
        <heading>Reminder</heading>  
        <body>Don't forget me this weekend!</body>  
    </note>  
          `}</pre>
      </TabPanel>
      <TabPanel value={value} index={8}>
        REP INFO
      </TabPanel>
    </div>
  )
}

export default withStyles(styles)(ProspectPanel)
