import PitchAudioController from './controller/PitchAudioController.js'
const template = require('./pitchAudio.component.tpl.pug')

const pitchAudio = {
  controller: PitchAudioController,
  template: template(),
  bindings: {
    user: '<',
  },
}

export default pitchAudio

