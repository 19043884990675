import angular from 'angular'
import { react2angular } from 'react2angular'

// components
import { ManageRealms } from './component/ManageRealms.component.js'

const dependencies = [
  '$state',
  'IdentityService',
  'SideNavService',
  'ManageMenuService',
  'RoleStore',
]

export default angular
  .module('portalApp.manage.realms', [])
  .component('manageRealms', react2angular(ManageRealms, [], dependencies))
  .name
