import './createTests.scss'
import CreateTestsController from './controller/CreateTestsController.js'
import template from './createTests.tpl.pug'

const createTests = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreateTestsController,
}

export default createTests
