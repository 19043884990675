// Dashboard
import {
  dashboardListOverview,
  dashboardListOverviewConfig,
} from './dashboard.listOverview.js'

export default function dashboardListOverviewRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboardListOverview, dashboardListOverviewConfig)
}
