// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type ManagementService from '../../../services/ManagementService.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../../../global/index/services/ToastService.js'
import type { DidPool } from 'types/entities.js'
import type { BaseState } from '../../../controllers/ManagementController.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TitleBarButtonConfig } from '../../../../common-components/title-bar/component/titleBarComponent.js'

import { ADMIN_GROUP } from 'permissions.js'
import { addColumns, containsRole } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'
import CDD from '../../../dialogs/create-did-pool/createDidPool.js'

export default class ManageDidPoolController extends ManagementController {
  MDS: Object
  filterConfig: FilterConfig
  topBarButtons: TitleBarButtonConfig
  state: BaseState

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
    ManageDidPoolService: Object,
  ) {
    'ngInject'
    super(...arguments)
    this.MDS = ManageDidPoolService
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()

    this.entity = 'pool'
    this.entityList = []
    this.tableConfig = this.bindActionCallbacks(this.MDS.tableConfig)
    this.searchConfig = this.MDS.searchConfig
    this.filterConfig = this.MDS.filterConfig
    this.searchConfig.editable = containsRole(
      userRoles,
      this.MDS.defaultEditRoles,
    )
    this.topBarButtons = [
      {
        action: this.createDidPool.bind(this),
        title: 'New DID Pool',
        icon: 'content:add',
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.state.queryParams = this.getInitialParams(this.filterConfig)
    this.state.queryParams.limit = this.paginateLimit

    const addColumnsOptions = {
      userRoles,
      allowedRoles: ADMIN_GROUP,
      sliceIndex: this.tableConfig.columns.length - 2,
      columns: this.tableConfig.columns,
      addColumns: this.tableConfig.optionalColumns,
    }

    this.tableConfig.columns = addColumns(addColumnsOptions)
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }

  createDidPool() {
    const locals = {
      isCreate: true,
    }
    CDD.locals = locals
    this.$mdDialog.show(CDD)
      .then((res) => {
        if (res.wasCreate) {
          this.entityList.push(res.newDidPool)
        }
      })
      .catch(err => {
        console.warn(err)
      })
  }

  editDidPool(event: Event, entity: DidPool, index: number) {
    this.$state.go('manage.editDidPool', {
      compSlug: this.state.compSlug,
      uuid: entity.uuid,
      entity,
    })
  }
}
