import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'carrierFailure',
  type: 'call',
  access: ['stats', 'causes'],
}

const formatting = {
  format: {
    title: 'Carrier Failure',
    append: '%',
    sort: 'ascending',
    subSection: 'hangup_causes',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'sub_metric_health',
}

const calculation = {
  calcType: 'hangupCause',
  dividend: {
    stat: 'carrier_failure',
    causes: [
      'call_rejected',
      'destination_out_of_order',
      'facility_rejected',
      'normal_circuit_congestion',
      'network_out_of_order',
      'normal_temporary_failure',
      'switch_congestion',
      'requested_chan_unavail',
      'bearercapability_notavail',
      'facility_not_implemented',
      'service_not_implemented',
      'recovery_on_timer_expire',
    ],
  },
  divisor: { stat: 'calls' },
  isRate: true,
}

const carrierFailure = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { carrierFailure }
