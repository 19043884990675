// create users password info view

const manageUsersCreatePasswordInfo = 'manage.users.create.passwordInfo'

const manageUsersCreatePasswordInfoConfig = {
  params: {
    user: null
  },
  component: 'passwordInfoForm',
  resolve: {
    user: function ($state, $transition$) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) {
        $state.go('manage.users.create.personalInfo')
      }
      return user
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreatePasswordInfo, manageUsersCreatePasswordInfoConfig }
