import moment from 'moment'

export default class TrackBillableController {
  constructor($timeout, $scope, $mdDialog, BillableHoursTrackingService) {
    'ngInject'
    this.$scope = $scope
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.BHTS = BillableHoursTrackingService
    this.queryDetails = {
      compSlug: this.compSlug,
      campSlug: this.campSlug,
    }
    this.state = {
      currentCreditHours: this.hours,
      currentCreditDate: moment(this.creditStart).format('MM/DD/YYYY'),
      currentWarningHours: this.warningHours,
      saveSuccess: {
        success: false,
        fail: false,
      },
    }
  }

  addHours(date, hours, warningHours) {
    const day = moment(date).startOf('day')
    const credit = {
      creditStart: day,
      hours: hours,
      warning: warningHours,
    }
    this.BHTS.saveBillableHoursCredit(this.queryDetails, credit)
      .then(data => {
        this.state.saveSuccess.success = true
        this.state.currentCreditDate = moment(data.value.creditStart).format(
          'MM/DD/YYYY',
        )
        this.state.currentCreditHours = data.value.hours
        this.state.currentWarningHours = data.value.warning
        this.state.selectedDate = null
        this.state.enteredHours = null
        this.state.warningHours = null
        this.resetForm(this.$scope.billableHoursForm)
        this.$timeout(() => {
          this.state.saveSuccess.success = false
        }, 5000)
      })
      .catch(() => {
        this.state.saveSuccess.fail = true
        this.$timeout(() => {
          this.state.saveSuccess.fail = false
        }, 2000)
      })
  }

  deleteCredit() {
    this.BHTS.deleteCredit(this.queryDetails).then(data => {
      this.removeCreditFromDialog()
      this.resetForm(this.$scope.billableHoursForm)
    })
  }

  resetForm(form) {
    if (form) {
      form.$setPristine()
      form.$setUntouched()
    }
  }

  removeCreditFromDialog() {
    this.state.currentCreditHours = null
    this.state.currentCreditDate = null
    this.state.currentWarningHours = null
    this.hours = null
    this.creditStart = null
  }

  close() {
    const newCredit = {
      creditStart: this.state.currentCreditDate,
      hours: this.state.currentCreditHours,
    }
    this.$mdDialog.hide(newCredit)
  }

  cancel() {
    const newCredit = {
      creditStart: this.state.currentCreditDate,
      hours: this.state.currentCreditHours,
    }
    this.$mdDialog.cancel(newCredit)
  }
}
