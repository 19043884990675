import React, { useState, useEffect } from 'react'
import {
  Grid,
  Link,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AppButton from '../../../../components/AppButton.js'
import SettingsIcon from '@material-ui/icons/Settings'
import AppSelect from '../../../../components/AppSelect.js'
import AppModal from '../../../../components/AppModal.js'
import AppTextField from '../../../../components/AppTextField.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  line: {
    width: 100 + '%',
    borderBottom: '1px solid #d5d5d5',
  },
}))

const QAEdit = props => {
  const classes = useStyles()
  const [item, setItem] = useState('')
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  return (
    <AppModal
      modalWith={800}
      title="Edit prospect: Samuel Maira"
      showModal={props.showModal}
      hideModal={props.hideModal}
    >
      <Grid container className="p-3" spacing={4}>
        <Grid item xs={12} className="mt-2">
          <Typography
            style={{ color: '#444851', fontSize: 18 }}
            className="left"
            variant="h6"
          >
            QA Scoring
          </Typography>
          <span className="right">
            <SettingsIcon
              className="left"
              style={{ fontSize: 18, marginRight: 4 }}
            />
            <Link
              className="left"
              style={{ color: '#444851' }}
              component="button"
              onClick={() => {
                console.info("I'm a button.")
              }}
            >
              Advance settings
            </Link>
          </span>
        </Grid>
        <Grid item xs={12}>
          <audio className="mt-1" style={{ width: 100 + '%' }} controls></audio>
        </Grid>
        <Grid item xs={12} className="mt-2">
          <div className={classes.line}></div>
        </Grid>
        <Grid item xs={12} className="mt-2">
          <Typography
            style={{ color: '#444851', fontSize: 18 }}
            className="left"
            variant="h6"
          >
            Samuel Maira information
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="First name*" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Last name" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" type="number" label="Primary phone" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" type="email" label="Primary email" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" type="email" label="Secondary email" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" type="number" label="Third phone" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Revenue" />
        </Grid>
        <Grid item xs={6}>
          <AppSelect label="Gender" data={['male', 'female']} />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Lead ID" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Street" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Street 2" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="City" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="State" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Zip code" />
        </Grid>
        <Grid item xs={12} className="mt-1">
          <div className={classes.line}></div>
        </Grid>
        <Grid item xs={12} className="mt-2">
          <Typography
            style={{ color: '#444851', fontSize: 18 }}
            className="left"
            variant="h6"
          >
            Scoring
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <AppSelect label="Disposition" data={['Do not Call', 'Call']} />
        </Grid>
        <Grid item xs={6}>
          <AppSelect label="NCS" data={['option 1', 'option 2', 'option 3']} />
        </Grid>
        <Grid item xs={6}>
          <AppSelect
            label="Quality"
            data={['option 1', 'option 2', 'option 3']}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            style={{ marginTop: 16 }}
            control={
              <Checkbox defaultChecked color="default" value="default" />
            }
            label="Finished"
          />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Notes" />
        </Grid>
        <Grid item xs={12} className="mt-1">
          <div className={classes.line}></div>
        </Grid>
        <Grid item xs={12} className="mt-2">
          <Typography
            style={{ color: '#444851', fontSize: 18 }}
            className="left"
            variant="h6"
          >
            Custom: US Troopers and Police Program
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Campaign" />
        </Grid>
        <Grid item xs={6}>
          <AppTextField value="" label="Amount" />
        </Grid>
        <Grid item xs={12} className="text-center mt-4">
          <AppButton onClick={props.hideModal} width={165}>CANCEL</AppButton>
          <AppButton className="ml-2" type="Green" width={165}>SEND</AppButton>
        </Grid>
      </Grid>
    </AppModal>
  )
}

export default QAEdit
