import {
  hideInvalid,
  errorParser,
} from '../../../../../utils/managementUtils.js'

const TIMEOUT = 2000
const actionTitles = {
  ENTITY: 'Create Phrase',
  CREATING: 'Creating',
  CREATED: 'Phrase Created',
}

export default class CreatePhraseFormController {
  constructor($state, $timeout) {
    'ngInject'
    this.$state = $state
    this.$timeout = $timeout
    this.hideInvalid = hideInvalid
    this.state = {
      actionTitle: actionTitles.ENTITY,
      askNextAction: false,
      creating: false,
      creationErrors: [],
    }
  }

  $onInit() {
    this.Phrase = this.service
  }

  createPhrase(phrase) {
    this.state.actionTitle = actionTitles.CREATING
    this.state.creating = true
    this.Phrase.customPOST(phrase)
      .then(phrase => phrase.plain())
      .then(this._handleCreate.bind(this))
      .catch(this._handleError.bind(this))
      .finally(this._finally.bind(this))
  }

  createAnother() {
    this.phrase = {}
    this.state.actionTitle = actionTitles.ENTITY
    this.state.askNextAction = false
  }

  resetErrors() {
    this.state.creationErrors = []
  }

  backTo({ reload }) {
    this.$state.go('^.^', null, { reload })
  }

  _handleCreate(createdPhrase) {
    return this.$timeout(() => {
      this.state.actionTitle = actionTitles.CREATED
      this.state.askNextAction = true
    }, TIMEOUT)
  }

  _handleError(err) {
    return this.$timeout(() => {
      this.state.creationErrors = errorParser(err.data)
    }, TIMEOUT)
  }

  _finally() {
    this.state.creating = false
  }

  _setFormPristine() {
    this.phraseForm.$setPristine()
  }
}
