import './editVoices.component.scss'
import EditVoicesController from './controller/EditVoices.controller.js'
const template = require('./editVoices.component.tpl.pug')

const editVoices = {
  template: template(),
  controller: EditVoicesController,
  bindings: {}
}

export default editVoices
