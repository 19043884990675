// create users company info view

const manageUsersCreateCompanyInfo = 'manage.users.create.companyInfo'

const manageUsersCreateCompanyInfoConfig = {
  params: {
    user: null
  },
  component: 'companyInfoForm',
  resolve: {
    user: function ($state, $transition$) {
      'ngInject'
      const user = $transition$.params().user
      if (!user) {
        $state.go('manage.users.create.personalInfo')
      }
      return user
    },
    companies: function (IdentityService) {
      'ngInject'
      return IdentityService.Company.list.get()
        .then(companies => companies.plain())
    },
    lastState: function ($transition$) {
      'ngInject'
      return $transition$.from().name
    }
  }
}

export { manageUsersCreateCompanyInfo, manageUsersCreateCompanyInfoConfig }
