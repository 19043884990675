export default class EditRapidResponseTestsController {
  constructor($state) {
    'ngInject'
    this.$state = $state
    this.state = {
      currentNavItem: null,
      navItems: [
        {
          name: 'Settings',
          route: 'manage.editRapidResponseTests.menu',
        },
        {
          name: 'segments',
          route: 'manage.editRapidResponseTests.segments',
        },
        {
          name: 'voices',
          route: 'manage.editRapidResponseTests.voices',
        },
        {
          name: 'variables',
          route: 'manage.editRapidResponseTests.variables',
        },
      ],
      backTo: {
        name: 'Rapid Response',
        route: '',
      },
    }
  }

  $onInit() {
    this.state.currentNavItem = this.$state.current.name
    let compSlug = this.compSlug || 'global'
    const isGlobal = compSlug === 'global'
    this.state.backTo.route = isGlobal
      ? 'manage.globalRapidResponse.tests'
      : `manage.editCompany.rapidResponseTests({slug:'${
          this.compSlug
        }', uuid:'${this.rapidResponseTest.company}'})`
  }

  goTo(stateKey) {
    this.state.currentNavItem = this.state[stateKey]
    this.$state.go(this.state[stateKey])
  }
}
