import './roleInfoForm.component.scss'
import RoleInfoFormController from './controller/RoleInfoFormController.js'
const template = require('./roleInfoForm.component.tpl.pug')

const roleInfoForm = {
  template: template(),
  controller: RoleInfoFormController,
  bindings: {
    user: '<',
    groups: '<',
    lastState: '<',
  }
}

export default roleInfoForm
