export default class ManageAccessListsService {
  constructor (managementFilterFactory) {
    'ngInject'

    this.mff = managementFilterFactory
  }

  getAccessListsManagementConfig () {
    return {
      entity: 'acl',
      entityList: [],
      defaultEditRoles: [ 'SUPER_USER', 'REALM_ADMIN' ],
      editState: 'manage.editAccessList',
      createState: 'manage.accessLists.one.create',
      detailsDialog: 'accessListDetails',
      tableConfig: {
        trackBy: 'uuid',
        columns: [
          { title: 'Name', accessor: 'name' },
          { title: 'Slug', accessor: 'slug' },
          { title: 'Company', accessor: 'company' },
          { title: 'Allow By Default', accessor: 'allow_by_default' },
        ],
        optionalColumns: [
          { title: 'UUID', accessor: 'uuid' },
        ]
      },
      searchConfig: {

        helperText: 'Try searching for a access list name or uuid',
        searchValue: '',
        searchResults: []
      },
      filterConfig: [
        {
          label: 'Global',
          field: 'global',
          defaultValue: true,
          options: [
            { title: 'True', value: true },
            { title: 'False', value: false },
          ]
        },
        {
          label: 'Company',
          field: 'company',
          hasAll: false,
          hideWhen: paramsState => paramsState.global === true,
          defaultValue: '',
          valueAccessor: 'slug',
          labelAccessor: 'name',
          options: this.mff.optionFetcher(this.mff.companies, { assignable: true }),
        },
        // // FIXME Does this filter work?
        // {
        //   label: 'Sort By',
        //   field: 'order_by',
        //   defaultValue: null,
        //   options: [
        //     { title: 'Name A-Z', value: 'name' },
        //     { title: 'Name Z-A', value: '-name' },
        //   ],
        // },
        // // FIXME Does this filter work
        // {
        //   label: 'Allow By Default',
        //   field: 'allow_by_default',
        //   defaultValue: null,
        //   options: [
        //     { title: 'All', value: null, },
        //     { title: 'Allowed', value: true },
        //     { title: 'Not Allowed', value: false },
        //   ],
        // },
      ],
    }
  }
}
