import './deleteEntity.scss'
import DeleteEntityController from './controller/DeleteEntityController.js'
const template = require('./deleteEntity.tpl.pug')

const deleteEntity = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: DeleteEntityController,
}

export default deleteEntity
