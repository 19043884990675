export default class AudioResourceController {
  constructor($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.state = {
      savingAudioResource: false,
      addAudioFile: false,
      error: false,
    }
  }

  saveAudioResource(audioResource) {
    this.state.savingAudioResource = true
    if (!audioResource.uuid) {
      this.service
        .customPOST(audioResource)
        .then(responseAudioResource => responseAudioResource.plain())
        .then(resource => {
          this.audioResource = resource
          this.state.addAudioFile = true
        })
        .catch(this.handleError.bind(this))
        .finally(this.afterSave.bind(this))
    } else {
      this.service
        .one(audioResource.uuid)
        .customPATCH(audioResource)
        .then(responseAudioResource => responseAudioResource.plain())
        .then(resource => {
          this.audioResource = resource
          this.state.addAudioFile = true
        })
        .catch(this.handleError.bind(this))
        .finally(this.afterSave.bind(this))
    }
  }

  handleError(err) {
    this.state.error = true
    this.state.savingAudioResource = false
    console.error(err, this.state)
  }

  afterSave(audioResource) {
    this.state.savingAudioResource = false
  }

  resetError() {
    this.state.error = false
  }

  close(audioResource) {
    audioResource && audioResource.uuid
      ? this.$mdDialog.hide(audioResource)
      : this.$mdDialog.cancel()
  }

  closeAndOpenFileForm(audioResource) {
    this.close(audioResource)
    this.openAudioResourceFileFormDialog(audioResource)
  }
}
