import { pipe } from 'ramda'
import { calcByAttemptByDay } from './helpers/byDay.js'
import { calcByAttemptByHour } from './helpers/byHour.js'
import { calcByAttemptByRange } from './helpers/byRange.js'

const calculateMetricsByAttempt = pipe(
  calcByAttemptByHour,
  calcByAttemptByDay,
  calcByAttemptByRange,
)

export { calculateMetricsByAttempt }
