import './noContentError.scss'
import template from './noContentError.tpl.pug'

const noContentError = {
  template: template(),
  bindings: {
    entityName: '@',
  },
}

export default noContentError
