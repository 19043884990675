function toggleRecording () {
  this.updateBindings('state', {
    trackArmed: !this.state.trackArmed,
    timerReset: true,
  })
}

function startRecording () {
  this.updateBindings('state', {
    isRecording: true,
    hasPreviewAudio: false,
  })
  this.resetTakePreview()
  this.previewAudio = null
  this.recorder.start()
}

function stopRecording () {
  this.recorder.stop()
  this.updateBindings('state', {
    isRecording: false,
    trackArmed: false,
    hasPreviewAudio: true,
  })
}

function disarmTrack () {
  this.updateBindings('state', {
    trackArmed: false,
  })
}

function playPreviewAudio () {
  this.updateBindings('state', { isPlaying: true, timerReset: true })
  this.previewAudio.addEventListener('ended', (ev) => {
    this.updateBindings('state', { isPlaying: false, timerReset: false })
  })
  this.previewAudio.play()
}

function stopPreviewAudio () {
  this.previewAudio.pause()
  this.previewAudio.currentTime = 0
  this.state.timerReset = false
  this.$timeout(() => {
    this.updateBindings('state', {
      isPlaying: false,
      timerReset: true,
    })
  })
}

export {
  toggleRecording,
  startRecording,
  stopRecording,
  disarmTrack,
  playPreviewAudio,
  stopPreviewAudio,
}
