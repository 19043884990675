import './validateDids.scss'
import ValidateDidsController from './controller/ValidateDidsController.js'
import template from './validateDids.tpl.pug'

const validateDids = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: ValidateDidsController,
}

export default validateDids
