// @flow

import type { PermissionGroup } from 'permissions.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../../common-components/management-search/component/managementSearch.component.js'

import { ADMIN_GROUP } from 'permissions.js'

export default class ManageDidsService {
  Dids: Object
  mff: Object
  defaultEditRoles: PermissionGroup

  constructor(DidListService: Object, managementFilterFactory: Object) {
    'ngInject'

    this.Dids = DidListService
    this.mff = managementFilterFactory
    this.defaultEditRoles = ADMIN_GROUP
  }

  get searchConfig(): SearchConfig {
    return {
      editable: false,
      limit: 5,
      helperText: 'Try searching for a pool name',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Company',
        field: 'company',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'slug',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.companies, {
          assignable: true,
        }),
      },
      {
        label: 'Campaign',
        field: 'campaigns',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'slug',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.campaigns, {
          assignable: true,
        }),
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        {
          title: 'Name',
          accessor: 'name',
          type: 'text',
        },
        {
          title: 'Active',
          accessor: 'active',
          type: 'active',
        },
        {
          title: 'Allow Inbound',
          accessor: 'allow_inbound',
          type: 'boolean',
        },
        {
          title: 'Matching Preference',
          accessor: 'matching_preference',
          type: 'text',
        },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editDidPool',
        },
      ],
      optionalColumns: [{ title: 'UUID', accessor: 'uuid', type: 'text' }],
    }
  }
}
