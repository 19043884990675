// @flow

import { permissions } from 'permissions.js'

const {
  SUPER_USER,
  REALM_ADMIN,
  CLIENT_MANAGER,
  LOCATION_MANAGER,
  TEAM_LEAD,
  REP,
  CLIENT,
} = permissions

export default class DashboardMenuService {
  getMenu() {
    return [
      {
        name: 'Agent Performance',
        type: 'toggle',
        icon: 'content:sort',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
        pages: [
          {
            name: 'Rankings',
            type: 'link',
            icon: 'content:sort',
            state: 'dashboard.repRankings',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
              REP,
              CLIENT,
            ],
          },
          {
            name: 'Trends',
            type: 'link',
            icon: 'mdi:chart-line',
            state: 'dashboard.repTrend',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
              REP,
              CLIENT,
            ],
          },
          {
            name: 'Live Agent Report',
            type: 'link',
            icon: 'navigation:check',
            state: 'dashboard.agents',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
              REP,
              CLIENT,
            ],
          },
        ],
      },
      {
        name: 'List Performance',
        type: 'toggle',
        icon: 'action:list',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
        pages: [
          {
            name: 'Lists',
            type: 'link',
            icon: 'action:list',
            state: 'dashboard.lists',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              TEAM_LEAD,
              CLIENT,
            ],
          },
        ],
      },
      {
        name: 'Campaign Options',
        type: 'toggle',
        icon: 'navigation:check',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          LOCATION_MANAGER,
          CLIENT,
        ],
        pages: [
          {
            name: 'Goals',
            type: 'link',
            icon: 'navigation:check',
            state: 'dashboard.goals',
            permissions: [
              SUPER_USER,
              REALM_ADMIN,
              CLIENT_MANAGER,
              LOCATION_MANAGER,
              CLIENT,
            ],
          },
        ],
      },
      {
        name: 'Dialer live',
        type: 'topLink',
        icon: 'communication:dialpad',
        state: 'dashboard.dialerLive',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
      },
      {
        name: 'Dialer queue',
        type: 'topLink',
        icon: 'communication:clear_all',
        state: 'dashboard.dialerQueue',
        permissions: [
          SUPER_USER,
          REALM_ADMIN,
          CLIENT_MANAGER,
          LOCATION_MANAGER,
          TEAM_LEAD,
          REP,
          CLIENT,
        ],
      },
    ]
  }
}
