// Manage Access Lists
import {
  manageAccessLists,
  manageAccessListsConfig,
} from './manage-access-lists.js'
import {
  manageAccessList,
  manageAccessListConfig,
} from './child-states/manage-access-list/manage-access-list.js'
import {
  manageAccessListsCreate,
  manageAccessListsCreateConfig,
} from './child-states/manage-access-list/child-states/manage-access-lists-create/manage-access-list.create.js'
import {
  manageAccessListsEdit,
  manageAccessListsEditConfig,
} from './child-states/manage-access-list/child-states/manage-access-lists-edit/manage-access-list.edit.js'

export default function manageAccessListsRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageAccessLists, manageAccessListsConfig)
    .state(manageAccessList, manageAccessListConfig)
    .state(manageAccessListsCreate, manageAccessListsCreateConfig)
    .state(manageAccessListsEdit, manageAccessListsEditConfig)
}
