// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';

import ActionButton from 'common-components/action-button/ActionButton'

type Props = {
    message: string,
    classes: Object,
}

const styles = theme => ({
    root: {
        fontFamily: theme.typography.fontFamily,
    },
    container: {
        padding: '15px 30px 30px 30px',
        fontColor: theme.palette.text.primary,
        '& p': {
            color: '#7c8a97',
            margin: '0 0 15px 0',
            textAlign: 'center',
        }
    },
    message: {
        fontWeight: 500,
    },
    errorIcon: {
        color: '#ff504d',
        fontSize: 30,
    },
    close: {
        padding: '0!important',
        '&:hover': {
            backgroundColor: 'transparent!important',
        },
    }
})

function DeleteDialog(props: Props) {
    return (
        <Grid container direction="column" className={props.classes.root}>
            <Grid
                alignItems="stretch"
                className={props.classes.container}
                container
                direction="column"
                justify="center"
            >
                <Grid item style={{ textAlign: 'right' }}>
                    <IconButton disableRipple={true} className={props.classes.close} aria-label="close" onClick={props.handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item style={{
                    marginBottom: '10px',
                    marginTop: '10px', textAlign: 'center'
                }}>
                    <ErrorIcon className={props.classes.errorIcon} />
                </Grid>
                <Grid item>
                    <p className={props.classes.message}>{props.message}</p>
                </Grid>
                <Grid item style={{ marginTop: '15px', width: '100%' }}>
                    <div style={{ display: 'inline-block' }}>
                        <ActionButton
                            handleClick={props.handleCancel}
                            buttonColor="default">CANCEL</ActionButton>
                    </div>
                    <div style={{ display: 'inline-block', float: 'right' }}>
                        <ActionButton
                            handleClick={props.handleRemove}
                            buttonColor="primary">{props.btnTxt}</ActionButton>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(DeleteDialog)
