// Manage Edit Company Phrase Book
import {
  manageEditCompanyPhraseBook,
  manageEditCompanyPhraseBookConfig,
} from './manage.editCompanyPhraseBook'
import {
  manageEditCompanyPhraseBookPhrase,
  manageEditCompanyPhraseBookPhraseConfig,
} from './child-states/phrase-book-phrase/manage.PhraseBookPhrase'
import {
  manageEditCompanyPhraseBookPhraseCreate,
  manageEditCompanyPhraseBookPhraseCreateConfig,
} from './child-states/phrase-book-phrase/child-states/phrase-create/manage.editCompanyPhraseBook.create'
import {
  manageEditCompanyPhraseBookPhraseEdit,
  manageEditCompanyPhraseBookPhraseEditConfig,
} from './child-states/phrase-book-phrase/child-states/phrase-edit/manage.editCompanyPhraseBook.edit'

export default function manageEditCompanyPhraseBookRoutes($stateProvider) {
  'ngInject'
  $stateProvider
    .state(manageEditCompanyPhraseBook, manageEditCompanyPhraseBookConfig)
    .state(
      manageEditCompanyPhraseBookPhrase,
      manageEditCompanyPhraseBookPhraseConfig,
    )
    .state(
      manageEditCompanyPhraseBookPhraseCreate,
      manageEditCompanyPhraseBookPhraseCreateConfig,
    )
    .state(
      manageEditCompanyPhraseBookPhraseEdit,
      manageEditCompanyPhraseBookPhraseEditConfig,
    )
}
