import angular from 'angular'
import uiRouter from '@uirouter/angularjs'
import permissions from '../../../permissions/index.js'
import { ENV } from 'settings.js'
import { addVisualizer } from './run-blocks/routerVisualizer.js'

import signInRoutes from './sign-in/signInRoutes'
import gatewayRoutes from './gateway/gatewayRoutes'
import updatePasswordRoutes from './updatePassword/updatePasswordRoutes'
import changePasswordRoutes from './changePassword/changePasswordRoutes'
import manageRoutes from './manage/manageRoutes'
import dashboardRoutes from './dashboard/dashboardRoutes'

export default angular
  .module('portalApp.global.router', [uiRouter, permissions])
  .run(addVisualizer)
  .config(routes).name

function routes($stateProvider, $urlServiceProvider, $locationProvider) {
  'ngInject'
  changePasswordRoutes($stateProvider)
  dashboardRoutes($stateProvider)
  gatewayRoutes($stateProvider)
  manageRoutes($stateProvider)
  signInRoutes($stateProvider)
  updatePasswordRoutes($stateProvider)
  $urlServiceProvider.rules.otherwise('/sign-in')
  // this is so tests run correctly
  if (ENV.NODE_ENV === 'test') {
    $urlServiceProvider.deferIntercept()
  }
}
