// Company Rapid Response Tests view

const manageEditCompanyRapidResponseTests = 'manage.editCompany.rapidResponseTests'

const manageEditCompanyRapidResponseTestsConfig = {
  url: '/rapidResponseTests',
  component: 'companyRapidResponseTests',
}

export { manageEditCompanyRapidResponseTests, manageEditCompanyRapidResponseTestsConfig }
