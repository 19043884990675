const HUNDREDTHS = 2
const SECONDS = 1
const MINUTES = 0

export default class TimerController {
  constructor ($interval) {
    'ngInject'

    this.$interval = $interval
    this.isRunning = false
    this.startTime = null
    this.displayTime = [ 0, 0, 0 ]
    this.timeString = this.formatDisplayTime(this.displayTime)
  }

  $onChanges (bindings) {
    if (bindings.startTimer && bindings.startTimer.currentValue === true) {
      this.start()
    } else if (bindings.stopTimer && bindings.stopTimer.currentValue === false) {
      this.stop()
    }

    if (bindings.resetTimer && bindings.resetTimer.currentValue === true) {
      this.reset()
    }
  }

  $onDestroy () {
    this.cancelInterval()
  }

  start () {
    this.timerInterval = this.$interval(this.stepDisplayTime.bind(this), 10)
  }

  stop () {
    this.cancelInterval()
  }

  reset () {
    this.displayTime = [ 0, 0, 0, ]
    this.timeString = this.formatDisplayTime(this.displayTime)
  }

  cancelInterval () {
    this.$interval.cancel(this.timerInterval)
  }

  stepDisplayTime (timestamp) {
    this.displayTime[HUNDREDTHS] += 1
    this.calculateSeconds(this.displayTime[HUNDREDTHS])
    this.calculateMinutes(this.displayTime[SECONDS])
    this.printDisplayTime()
  }

  printDisplayTime () {
    this.timeString = this.formatDisplayTime(this.displayTime)
  }

  formatDisplayTime (displayTime) {
    return displayTime.map(timeUnit => {
      const value = timeUnit.toString()
      if (value.length < 2) {
        return '0'.concat(value)
      } else {
        return value
      }
    }).join(':')
  }

  calculateSeconds (hundredths) {
    if (hundredths >= 100) {
      this.displayTime[SECONDS] += 1
      this.displayTime[HUNDREDTHS] -= 100
    }
  }

  calculateMinutes (seconds) {
    if (seconds >= 60) {
      this.displayTime[MINUTES] += 1
      this.displayTime[SECONDS] -= 60
    }
  }
}

