import { contains, find, propEq } from 'ramda'

export default class CompanyInfoFormController {
  constructor($state, $element, $sessionStorage, RoleStore) {
    'ngInject'

    this.$element = $element
    this.$state = $state
    this.$sessionStorage = $sessionStorage
    this.RS = RoleStore
  }

  $onInit() {
    this.state = {
      isSelectedCompanyActive: true,
    }
    this.creator = this.$sessionStorage.user
    // Have to kill the keydown events on the input or search box won't work
    this.$element.find('input').on('keydown', function(ev) {
      ev.stopPropagation()
    })

    this.shouldAutoAssign()
  }

  backTo() {
    this.goingBack = true
    this.$state.go(
      'manage.users.create.roleInfo',
      { user: this.user },
      { location: false },
    )
  }

  goTo() {
    this.$state.go(
      'manage.users.create.campaignInfo',
      { user: this.user },
      { location: false },
    )
  }

  shouldAutoAssign() {
    const autoAssignRoles = ['LOCATION_MANAGER', 'TEAM_LEAD']
    const creatorRoles = Object.keys(this.RS.getAllRoles())
    const shouldAssign = creatorRoles.filter(role =>
      contains(role, autoAssignRoles),
    )
    if (shouldAssign.length > 0) {
      this.state.autoAssignCompany = true
      this.user.company = find(propEq('uuid', this.creator.company))(
        this.companies,
      )
      return
    }
    this.state.autoAssignCompany = false
  }

  checkCompanyActive(companyId) {
    if (!companyId) return
    this.state.isSelectedCompanyActive = find(propEq('uuid', companyId))(
      this.companies,
    ).active
  }
}
