import angular from 'angular'

// services
import ListOverviewService from './component/services/ListOverviewService.js'
import ListMetricHealthService from './sub-components/list-metric-health/services/ListMetricHealthService.js'

// components
import listOverview from './component/listOverview.js'
import listDetails from './sub-components/list-details/component/listDetails.js'
import listMetrics from './sub-components/list-metrics/component/listMetrics.js'
import listMetricHealth from './sub-components/list-metric-health/component/listMetricHealth.js'

export default angular
  .module('portalApp.dashbaord.listOverview', [])
  .service('ListOverviewService', ListOverviewService)
  .service('ListMetricHealthService', ListMetricHealthService)
  .component('listOverview', listOverview)
  .component('listDetails', listDetails)
  .component('listMetrics', listMetrics)
  .component('listMetricHealth', listMetricHealth)
  .name
