// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

type Props = {
  classes: Object,
  children: boolean,
  className: string,
  innerRef: string,
}

const styles = {
  root: {
    '& span': {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      height: '20px',
      textTransform: 'capitalize',
      borderRadius: '3px',
      color: 'white',
      '&.active': {
        backgroundColor: '#6698c7',
        fontSize: '12px'
      },
      '&.inactive': {
        backgroundColor: '#ff504d',
        fontSize: '12px'
      },
    },
  },
}

function ActiveCell(props: Props) {
  const classes = `${props.className} ${props.classes.root}`
  const style = props.children === 'Active' ? 'active' : 'inactive'
  return (
    <TableCell className={classes}>
      <span className={style}>{props.children.toString()}</span>
    </TableCell>
  )
}

export default withStyles(styles)(ActiveCell)
