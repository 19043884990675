import { MANAGE_GROUP } from 'permissions.js'
import DED from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class EditPhraseController {
  constructor($state, $mdDialog) {
    'ngInject'
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.MANAGE_GROUP = MANAGE_GROUP
    this.state = {
      wasUpated: false,
      errors: false,
    }
  }

  $onInit() {
    this.editablePhrase = Object.assign({}, this.phrase)
    this.Phrase = this.service.one(this.phrase.uuid)
  }

  updatePhrase(updatedPhrase) {
    this.Phrase.customPUT(updatedPhrase)
      .then(this._handleUpdate.bind(this))
      .catch(this._handleError.bind(this))
  }

  deletePhrase() {
    DED.locals = {
      title: 'Delete Phrase',
      entityName: this.phrase.name,
      identityEndpoint: this.Phrase,
      entity: this.phrase,
      routeWhenSuccessful: '^.^',
    }
    this.$mdDialog.show(DED)
  }

  resetErrors() {
    this.state.errors = false
  }

  cancelUpdate() {
    this.editablePhrase = Object.assign({}, this.phrase)
    this._setFormPristine()
  }

  backTo() {
    this.$state.go('^.^', null, { reload: this.state.wasUpdated })
  }

  _handleUpdate(updatedPhrase) {
    this.phrase = updatedPhrase
    this.editablePhrase = Object.assign({}, updatedPhrase)
    this.state.wasUpdated = true
    this._setFormPristine()
  }

  _handleError(err) {
    this.state.errors = true
  }

  _setFormPristine() {
    this.phraseForm.$setPristine()
  }
}
