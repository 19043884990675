// @flow
/*
| This is where we define the conditions that allow for a route transition to occur
| see https://ui-router.github.io/docs/1.0.0-beta.1/classes/transition.transitionservice.html
| for more details on the $transisions service
*/

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type AuthService from '../../app/auth/services/AuthService.js'
// eslint-disable-next-line no-unused-vars
import type RoleValidator from '../role-validator/services/RoleValidator.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../app/global/index/services/ToastService.js'

export default function routeProtector(
  $transitions: Object,
  $state: Object,
  $localStorage: Object,
  $rootScope: Object,
  AuthService: AuthService,
  RoleValidator: RoleValidator,
  ToastService: ToastService,
) {
  'ngInject'
  // for routes that have permissions defined
  const permissionsMatcher = {
    to: function(state) {
      return state.data != null && !!state.data.permissions
    },
  }

  // only for signIn route
  const signInMatcher = {
    to: 'signIn',
  }

  // for routes that require auth
  const authMatcher = {
    to: function(state) {
      return state.data != null && state.data.authRequired
    },
  }

  // check if user needs to sign-in
  $transitions.onBefore(signInMatcher, function() {
    return AuthService.isAuthorized()
      .then(profile => {
        if (profile && !profile.password_update_required) {
          return $state.target('gateway')
        } else if (profile.password_update_required && profile.uuid) {
          return $state.target('updatePassword', { userId: profile.uuid })
        }
        return true
      })
      .catch(() => {
        delete $localStorage.ppToken
        return true
      })
  })

  // Check if user is authorized
  $transitions.onBefore(authMatcher, function() {
    $rootScope.loading = true
    return AuthService.isAuthorized()
      .then(AuthService.getGroups.bind(AuthService))
      .then(AuthService.defineUserRoles.bind(AuthService))
      .then(() => true)
      .catch(e => {
        console.error(e)
        redirector('signIn')
      })
      .finally(() => {
        $rootScope.loading = false
      })
  })

  // Check if user has correct permissions to transition to routes
  $transitions.onBefore(permissionsMatcher, function(trans) {
    $rootScope.loading = true
    const permissions = trans.$to().data.permissions
    if (!trans.$from.name) {
      /*
      | if a transition is coming from a state that doesn't have a name it is usually a page refresh
      | we have to re-fetch the user's permissions or they will be kicked from the view if it is
      | permissions protected. If the user is not authorized they are redirected to signIn
      */
      return AuthService.isAuthorized()
        .then(AuthService.getGroups.bind(AuthService))
        .then(AuthService.defineUserRoles.bind(AuthService))
        .then(() => {
          return RoleValidator.validate(permissions)
        })
        .then(canEnter => {
          if (canEnter) return true
          return redirector(permissions.redirectTo)
        })
        .catch(e => {
          console.error(e)
          redirector(permissions.redirectTo)
        })
        .finally(() => {
          $rootScope.loading = false
        })
    }

    return RoleValidator.validate(permissions)
      .then(canEnter => {
        if (canEnter) return true
        return redirector(permissions.redirectTo)
      })
      .finally(() => {
        $rootScope.loading = false
      })
  })

  function redirector(redirectTo) {
    ToastService.show({
      text:
        'Your user does not have access to that page. You may need to sign-in.',
    })
    if (!redirectTo) return false
    return $state.target(redirectTo)
  }
}
