// @flow

import type { BaseService, Element } from 'restangular'
import type PitchService from '../../../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../../../global/index/services/ToastService.js'
import type { FilterListConfig } from '../../../../../../common-components/filter-list/component/filterList.js'
import type { RapidResponseTest } from 'types/entities.js'

import CTD from '../../../../../dialogs/create-tests/createTests.js'

export default class RapidResponseTestsController {
  PS: PitchService
  TS: ToastService
  $mdDialog: Object
  $state: Object
  $localStorage: Object
  rrService: BaseService
  rrTestService: Element
  rrSegmentService: Element
  columnsConfig: FilterListConfig
  state: {
    loading: boolean,
    rrTests: RapidResponseTest[],
  }

  constructor(
    $mdDialog: Object,
    $state: Object,
    PitchService: PitchService,
    ToastService: ToastService,
  ) {
    'ngInject'
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.$state = $state

    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'Final Revenue', accessor: 'final_revenue', type: 'text' },
      {
        title: 'Test Type',
        accessor: 'test_type',
        type: 'text',
        parser: type => (type === 1 ? 'Long' : 'Short'),
      },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      { title: 'Live', accessor: 'live', type: 'boolean' },
      { title: 'Active', accessor: 'active', type: 'active' },
      {
        title: 'Edit',
        icon: 'editor:mode_edit',
        type: 'button',
        actionCb: test => this.editTest(test),
      },
    ]

    this.state = {
      loading: true,
      rrTests: [],
      topBarButtons: [
        {
          title: 'New Global Test',
          icon: 'content:add',
          action: this.createTest.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onChanges() {
    this.rrService = this.PS.setupBase(null, 'rapid-response').global
    this.rrTestService = this.rrService.one('tests')
    this.rrSegmentService = this.rrService.one('segments')
    this.getRapidResponseTests()
  }

  getRapidResponseTests() {
    this.rrTestService
      .get()
      .then(tests => {
        this.state.rrTests = tests.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching rapid response tests',
        })
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  createTest() {
    CTD.locals = {
      isRapidResponse: true,
      testService: this.rrTestService,
      rapidResponseService: this.rrSegmentService,
      company: 'global',
      test: null,
    }
    this.$mdDialog.show(CTD).then(() => {
      this.$state.go(this.$state.current.name, null, { reload: true })
    })
  }

  editTest(test: RapidResponseTest) {
    this.$state.go('manage.editRapidResponseTests.menu', {
      compSlug: 'global',
      testUuid: test.uuid,
      entity: test,
    })
  }
}
