import { errorParser, hideInvalid, getHttpCodes } from '../../../../../utils/managementUtils.js'
import { ADMIN_GROUP } from 'permissions.js'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'

const TRANSITION_DELAY = 2000

export default class EditAccessListNodeFormController {
  constructor ($state, $timeout, $mdDialog, ManagementService, AccessListService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.MS = ManagementService
    this.ACLS = AccessListService
    this.hideInvalid = hideInvalid
    this.errorParser = errorParser
    this.ADMIN_GROUP = ADMIN_GROUP
    this.editableNode = {
      allow: true,
      active: true
    }
    this.editMode = false
    this.formStates = {
      'initial': 0,
      'saving': 1,
      'saved': 2,
      'error': 3
    }

    this.state = {
      actionTitle: '',
      creationErrors: null,
      creatingNode: false,
      askNextAction: false,
    }
  }

  $onInit () {
    this.allowOptions = [
      {'key': true, 'value': 'Yes'},
      {'key': false, 'value': 'No'},
    ]
    if (this.node) {
      this.editableNode = angular.copy(this.node)
    }

    if (this.node && this.node.name) {
      this.editMode = true
    } else {
      this.editableNode.allow = this.accessList.allow_by_default
    }

    if (this.company) {
      this.compSlug = this.company.slug
    }

    this.state.actionTitle = this.getActionTitle(this.formStates.initial)
    getHttpCodes()
      .then(codes => {
        this.statusCodes = codes
      })
  }

  getActionTitle (state) {
    if (state === this.formStates.initial) {
      return this.editMode ? `Editing ${this.node.name}` : `New Node for ${this.accessList.name}`
    }
    if (state === this.formStates.saving) {
      return this.editMode ? `Updating` : 'Creating'
    }
    if (state === this.formStates.saved) {
      return this.editMode ? `Updated` : 'Node Created'
    }
  }

  saveNode (node) {
    if (this.state.creationErrors) this.resetErrors()
    this.state.actionTitle = this.getActionTitle(this.formStates.saving)
    this.state.creatingNode = true
    let data = {nodeData: node, editMode: this.editMode, acl: this.accessList.uuid}
    if (this.compSlug) {
      data['compSlug'] = this.compSlug
    }
    this.ACLS.saveNode(data).then(node => {
      this.$timeout(() => {
        this.state.actionTitle = this.getActionTitle(this.formStates.saved)
        this.state.askNextAction = true
      }, TRANSITION_DELAY)
    })
    .catch(err => {
      console.error('Error creating access list node: ', err)
      this.$timeout(() => {
        this.state.creationErrors = errorParser(err.data)
      }, TRANSITION_DELAY)
    })
    .finally(() => {
      this.$timeout(() => {
        this.state.creatingNode = false
      }, TRANSITION_DELAY)
    })
  }

  deleteNode (node) {
    const DUD = deleteEntityDialog
    if (this.company) {
      node.compSlug = this.company.slug
    }
    DUD.locals = {
      title: 'Delete Node',
      entityName: node.name,
      identityEndpoint: this.ACLS.deleteNode.one(node),
      entity: node,
      routeParams: {
        uuid: node.acl
      },
      routeWhenSuccessful: 'manage.editAccessList'
    }
    this.$mdDialog.show(DUD)
  }

  createAnother () {
    this.editableNode = {
      allow: 1,
      active: 1
    }
    this.state.actionTitle = this.getActionTitle(this.formStates.initial)
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit () {
    this.$state.go('^.^', null, { reload: true })
  }

  resetErrors () {
    this.state.creationErrors = null
  }

  backTo () {
    this.$state.go('^.^')
  }
}
