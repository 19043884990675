// create campaign router view

const manageEditAccessListNodeCreate = 'manage.editAccessList.node.create'

const manageEditAccessListNodeCreateConfig = {
  params: {
    node: null,
    accessList: null,
    company: null
  },
  component: 'editAccessListNodeForm',
  resolve: {
    node: function ($transition$) {
      'ngInject'
      return $transition$.params().node
    },
    accessList: function ($transition$) {
      'ngInject'
      return $transition$.params().accessList
    },
    company: function ($transition$) {
      'ngInject'
      let company = $transition$.params().company
      return company
    }
  }
}

export { manageEditAccessListNodeCreate, manageEditAccessListNodeCreateConfig }
