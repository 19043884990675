import { reduce } from 'ramda'
import CPVD from '../../../../../dialogs/create-pitch-variables/createPitchVariables'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class CompanyRapidResponseVariablesController {
  constructor($mdDialog, $state, PitchService) {
    'ngInject'
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.PS = PitchService
    this.state = {
      columnsConfig: [
        { title: 'Key', accessor: 'key' },
        { title: 'Value', accessor: 'value' },
        { title: 'Variable Usage', accessor: 'pitchUsage', copyable: true },
      ],
      pitchVariables: [],
    }

    this.searchSegment = ({ searchText }) => {
      this.searchText = searchText
    }
  }

  $onChanges() {
    this.companyService = this.PS.setupCompanyBase(this.company).company.one(
      'rapid-response',
    )
    this.getVariables()
    this.buildVariablesTable = []
    this.buildVariablesTable = reduce(this.variableBuilder, [])
  }

  getVariables() {
    this.companyService.get().then(segment => {
      this.variables = segment.plain().variables
      this.state.pitchVariables = this.updateVariables(this.variables)
    })
  }

  updateVariables(variables) {
    return this.buildVariablesTable(Object.entries(variables))
  }

  variableBuilder(acc, [key, value]) {
    acc.push({
      key,
      value,
      pitchUsage: `{{global.${key}}}`,
    })
    return acc
  }

  openVariablesDialog(variable) {
    const locals = {
      isCreate: !variable,
      service: this.companyService,
      variables: this.variables,
      variable,
    }
    CPVD.locals = locals
    this.$mdDialog.show(CPVD).then(() => {
      this.$state.go(this.$state.current.name, this.$state.params, {
        reload: true,
      })
    })
  }

  deleteVariable(ev, variable) {
    ev.cancelBubble = true
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Rapid Response Segment',
      entityName: variable.key,
      identityEndpoint: this.companyService,
      entity: variable,
      routeWhenSuccessful: this.$state.current.name,
      isVariable: true,
      variables: this.variables,
    }
    this.$mdDialog.show(DED)
  }
}
