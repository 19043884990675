import moment from 'moment'
import { map, mapObjIndexed, all } from 'ramda'
import { HIRE_DATE_FORMAT } from '../../../../utils/managementUtils.js'

export default class ManageUsersCreateService {
  constructor($q, IdentityService, ManagementService) {
    'ngInject'

    this.$q = $q
    this.IS = IdentityService
    this.MS = ManagementService
    this.userScaffold = {
      first_name: null,
      last_name: null,
      username: null,
      email: null,
      password: null,
      campaigns: [],
      company: null,
      groups: null,
      team: null,
      hire_date: null,
    }
  }

  createUser(user) {
    const teamToLead = user.teamToLead || null
    const locationToManage = user.locationToManage || null
    const normalizedUser = this.normalizeUser(user)
    const updateTeam = user => {
      if (teamToLead) {
        return this.IS.Team.fetch
          .one(teamToLead.uuid)
          .patch({ leader: user.uuid })
      }
      return user
    }

    const updateLocation = user => {
      if (locationToManage) {
        return this.IS.Location.fetch
          .one(locationToManage.uuid)
          .patch({ leader: user.uuid })
      }
      return user
    }

    return this.MS.createEntity({ entity: 'user', data: normalizedUser })
      .then(updateTeam.bind(this))
      .then(updateLocation.bind(this))
      .then(createdUser => createdUser)
  }

  getUUID(val) {
    if (val) return val.uuid
    return null
  }

  normalizeCampaigns(campaigns) {
    const normMap = camp => {
      return camp.uuid
    }
    if (campaigns) return map(normMap, campaigns)
    return null
  }

  normalizeGroups(groups) {
    if (groups) return groups.map(group => group.pk)
    return null
  }

  normalizeDate(dateString) {
    if (!dateString) return null
    return moment(dateString).format(HIRE_DATE_FORMAT)
  }

  normalizer(val, key) {
    switch (key) {
      case 'company':
      case 'team':
        return this.getUUID(val)
      case 'campaigns':
        return this.normalizeCampaigns(val)
      case 'groups':
        return this.normalizeGroups(val)
      case 'hire_date':
        return this.normalizeDate(val)
      default:
        return val
    }
  }

  normalizeUser(user) {
    const hydratedUser = Object.assign({}, this.userScaffold, user)
    return mapObjIndexed(this.normalizer.bind(this), hydratedUser)
  }

  validateUsername(user) {
    return this.IS.User.manage.unique.username
      .post({ username: user.username })
      .then(res => {
        return {
          username: user.username,
          valid: res.plain().valid,
        }
      })
  }

  bulkUploadUserValidator(users) {
    const promisedValidations = {
      userNamesValid: this.validateBulkUsernames(users),
      userFieldsValid: this.validateUserProperties(users),
      group: this.findUserGroup('REP'),
      userList: users,
    }
    return this.$q
      .all(promisedValidations)
      .then(this.bulkUploadUserBuilder.bind(this))
      .then(this.postBulkUserUpload.bind(this))
  }

  postBulkUserUpload(users) {
    return this.IS.User.manage.createBulk
      .post(users)
      .then(createdUsers => createdUsers.plain())
  }

  bulkUploadUserBuilder(validated) {
    const builder = user => {
      user = Object.assign({}, this.userScaffold, user)
      user.groups = [validated.group.pk]
      return user
    }
    return map(builder, validated.userList)
  }

  validateBulkUsernames(users) {
    const validUsernames = users.map(this.validateUsername.bind(this))

    return this.$q.all(validUsernames).then(users => {
      const invalidUsernames = users.filter(user => user.valid === false)
      if (invalidUsernames.length > 0) {
        return this.$q.reject({
          message:
            'Some of the usernames you have chosen are already in use. Please choose different usernames for the following users.',
          data: invalidUsernames,
        })
      }
      return true
    })
  }

  findUserGroup(groupName) {
    return this.IS.Groups.list
      .get({ assignable: true })
      .then(groupList => groupList.find(group => group.name === groupName))
  }

  validateUserProperties(users) {
    const anyFalsy = value => {
      if (value) return true
      return false
    }
    const findRequiredProps = user => {
      const props = props(
        ['username', 'first_name', 'last_name', 'password'],
        user,
      )
      user.requiredPropsDefined = all(anyFalsy)(props)
      return user
    }

    const invalidUsers = users
      .map(findRequiredProps)
      .filter(user => user.requiredPropsDefined === false)
    if (invalidUsers.length > 0) {
      return this.$q.reject({
        message:
          'The following users have missing required properties, Please download the .csv template to see what properties are required.',
        data: invalidUsers,
      })
    }
    return true
  }
}
