export default class RealmService {
  constructor (Restangular) {
    'ngInject'

    this.Rest = Restangular
    this.Realm = this.setRealm({})
    this.Campaign = this.Realm.all('campaign')
    this.CampaignSettings = this.Realm.all('campaign/portal-settings')
    this.Company = this.Realm.all('company')
    this.Lists = this.Realm.all('list')
    this.PortalSettings = this.Realm.all('user-profile/portal-settings')
    this.Report = this.Realm.all('report')
  }

  updateRealm ({ name = 'api' }) {
    this.Realm.configuration.baseUrl = `https://${name}.perfectpitchtech.com/api/`
  }

  getCurrentRealm () {
    const baseUrl = this.Realm.configuration.baseUrl
    const extractRealm = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/ig
    const realm = extractRealm.exec(baseUrl)
    return realm[1] || null
  }

  setRealm ({ name = 'api' }) {
    return this.Rest.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(`https://${name}.perfectpitchtech.com/api/`)
    })
  }
}
