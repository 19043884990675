/*
*
* Directive to add attributes to a certain html element.
* It's necessary to add an `options` attribute that is an array
*
*/
export default function addAttr() {
  'ngInject'
  function linkFunc(scope, elem) {
    if (scope.options) {
      let element = elem[0]
      scope.options.forEach(elem => {
        let attr = elem.split('=')
        let key = attr[0]
        let value = ''
        if (attr.length > 1) {
          value = attr[1]
        }
        element.setAttribute(key, value)
      })
    }
  }

  return {
    restrict: 'A',
    scope: {
      options: '<',
    },
    link: linkFunc,
  }
}
