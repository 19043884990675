import React, { useState, useEffect } from 'react'
import { Grid, FormControl, InputAdornment, Input } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles(theme => ({
  form: {
    width: 100 + '%',
  },
  searchIcon: {
    color: '#bbbbbb',
  },
  input: {
    marginTop: 16,
  },
}))

const AppSearchBar = props => {
  const classes = useStyles()
  //   const [search, setSearch] = useState('')
  //   const [searchResults, setSearchResults] = useState([])

  //   useEffect(() => {
  //     setSearchResults(data)
  //   }, [data])

  return (
    <FormControl className={classes.form}>
      <InputLabel id="user" shrink={props.shrink}>
        {props.label}
      </InputLabel>
      <Input
        id="user"
        className={classes.input}
        onChange={e => props.onChange(e.target.value)}
        placeholder={props.placeholder || 'Search'}
        value={props.value}
        id="standard-adornment-weight"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
        aria-describedby="standard-weight-helper-text"
      />
    </FormControl>
  )
}

export default AppSearchBar
