// css
import './component/dialerLive.scss'

// services
import DialerLiveService from './services/DialerLiveService.js'

// components
import dialerLive from './component/dialerLive.js'

export default angular
  .module('portalApp.dashboard.dialerLive', [])
  .service('DialerLiveService', DialerLiveService)
  .component('dialerLive', dialerLive)
  .name
