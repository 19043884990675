import {
  REP_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'silencePerKeypress',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Silence / Keypress',
    sort: 'ascending',
    append: '/sec',
  },
}

const keys = {
  componentKeys: [
    REP_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'silence' },
  divisor: { stat: 'keypress' },
}

const silencePerKeypress = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { silencePerKeypress }
