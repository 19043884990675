import moment from 'moment'

const toMinutes = seconds => moment.duration(seconds, 'seconds').asMinutes()
const toHours = seconds => moment.duration(seconds, 'seconds').asHours()
const toDays = seconds => moment.duration(seconds, 'seconds').asDays()

function secondsConverter(seconds, convertTo) {
  switch (convertTo) {
    case 'minutes':
      return toMinutes(seconds)
    case 'hours':
      return toHours(seconds)
    case 'days':
      return toDays(seconds)
    default:
      return seconds
  }
}

const conversions = {
  billable_hours: secondsConverter,
  machine_hours: secondsConverter,
  payable_hours: secondsConverter,
  speed_to_lead: secondsConverter,
  talk_time: secondsConverter,
  transfer_seconds: secondsConverter,
  transfer_talk_time: secondsConverter,
}

export default conversions
