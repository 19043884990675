export default class MetricSeriesService {
  constructor () {
    this.series = {
      drilldown: {}
    }
  }

  registerMainSeries (metric, series) {
    this.series[metric.key] = Object.assign({
      format: metric.format
    }, series)
  }

  registerDrilldownSeries (metric, drillDownSeries) {
    this.series.drilldown[metric.key] = drillDownSeries
  }

  getAllSeries () {
    return Object.assign({}, this.series)
  }
}
