/*
 * This module is for parsing stats into metrics from various endpoints on
 * api.perfectpitchtech.com/reporting
 * search for its use in various services
 */

import { pipe } from 'ramda'

import { calcMetricsByHour } from './helpers/metricsByHour.js'
import { calcMetricsByDay } from './helpers/metricsByDay.js'
import { calcMetricForRange } from './helpers/metricsByRange.js'
import { finalizeDays, finalizeRange } from './helpers/finalizer.js'

const calculateMetrics = pipe(
  calcMetricsByHour,
  calcMetricsByDay,
  finalizeDays,
  calcMetricForRange,
  finalizeRange,
)

export { calculateMetrics }
