import { MANAGE_GROUP } from 'permissions.js'
// manage users view

const manageDidPools = 'manage.didpools'

const manageDidPoolsConfig = {
  url: '/did-pool',
  component: 'manageDidPool',
  data: {
    permissions: {
      only: [...MANAGE_GROUP],
      redirectTo: 'gateway',
    },
  },
  resolve: {
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Manage Did Pool'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageDidPools, manageDidPoolsConfig }
