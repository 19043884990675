// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService, {
  IdentityUserManage,
} from '../../../../global/index/api-services/IdentityService.js'
import type { PermissionGroup } from 'permissions.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../../common-components/management-search/component/managementSearch.component.js'

import { TEAM_LEADER_GROUP } from 'permissions.js'

export default class ManageUsersService {
  Users: IdentityUserManage
  mff: Object
  defaultEditRoles: PermissionGroup

  constructor(
    IdentityService: IdentityService,
    managementFilterFactory: Object,
  ) {
    'ngInject'

    this.Users = IdentityService.User.manage
    this.mff = managementFilterFactory
    this.defaultEditRoles = TEAM_LEADER_GROUP
  }

  // Using getters makes it so we don't have to worry about mutation where these are used

  
  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 15,
      helperText:
        'Try searching for a username, first name, last name, email, or uuid',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Sort By',
        field: 'order_by',
        defaultValue: '-datetime_modified',
        options: [
          { title: 'Recently Edited', value: '-datetime_modified' },
          { title: 'First Name', value: 'first_name' },
          { title: 'Last Name', value: 'last_name' },
          { title: 'Email', value: 'email' },
        ],
      },
      {
        label: 'Active Status',
        field: 'is_active',
        defaultValue: null,
        options: [
          { title: 'All', value: null },
          { title: 'Active', value: true },
          { title: 'Inactive', value: false },
        ],
      },
      {
        label: 'Company',
        field: 'company',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'uuid',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.companies, {
          assignable: true,
        }),
      },
      {
        label: 'Campaign',
        field: 'campaigns',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'uuid',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.campaigns, {
          assignable: true,
        }),
      },
      {
        label: 'Has Company',
        field: 'no_company',
        defaultValue: null,
        options: [
          { title: 'All', value: null },
          { title: 'True', value: false },
          { title: 'False', value: true },
        ],
      },
      {
        label: 'Role',
        field: 'groups',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'pk',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.groups, {
          assignable: true,
        }),
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Username', accessor: 'username', type: 'text' },
        { title: 'First Name', accessor: 'first_name', type: 'text' },
        { title: 'Last Name', accessor: 'last_name', type: 'text' },
        { title: 'Email', accessor: 'email', type: 'text' },
        { title: 'Active', accessor: 'is_active', type: 'active' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editOrView',
        },
      ],
      optionalColumns: [{ title: 'UUID', accessor: 'uuid', type: 'text' }],
    }
  }

  changeUserPassword(changeData: {
    id: string,
    new_password: string,
    re_new_password: string,
  }) {
    const { new_password, re_new_password, id } = changeData
    return this.Users.setPassword
      .one(id)
      .customPOST({ new_password, re_new_password })
  }
}
