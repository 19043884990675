// edit campaign router route view

const manageCampaignRouterEditRoute = 'manage.campaignRouter.edit.route'

const manageCampaignRouterEditRouteConfig = {
  params: {
    route: null,
    router: null,
    campaign: null,
  },
  component: 'editRouteInfoForm',
  resolve: {
    route: function ($transition$) {
      'ngInject'
      return $transition$.params().route
    },
    router: function ($transition$) {
      'ngInject'
      return $transition$.params().router
    },
    campaign: function ($transition$) {
      'ngInject'
      return $transition$.params().campaign
    }
  }
}

export { manageCampaignRouterEditRoute, manageCampaignRouterEditRouteConfig }
