// Manage Global Pitch Phrase Books
import {
  manageGlobalPitchPhraseBooks,
  manageGlobalPitchPhraseBooksConfig,
} from './manage.globalPitchPhraseBooks'

export default function manageGlobalPitchPhraseBooksRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(
    manageGlobalPitchPhraseBooks,
    manageGlobalPitchPhraseBooksConfig,
  )
}
