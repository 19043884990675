import angular from 'angular'
import { react2angular } from 'react2angular'
// Services
import ManageUsersService from './component/services/ManageUsersService.js'
import ManageUsersEditService from './sub-components/manage-users-edit/services/ManageUsersEditService.js'
import ManageUsersCreateService from './sub-components/manage-users-create/services/ManageUsersCreateService.js'
// filters
import filterCampaignsForCompany from './filters/filterCampaignsForCompany.js'
// components
import manageUsers from './component/manageUsers.component.js'
import manageUsersEdit from './sub-components/manage-users-edit/component/manageUsersEdit.component.js'
import roleInfoForm from './sub-components/manage-users-create/sub-components/role-info/component/roleInfoForm.component.js'
import companyInfoForm from './sub-components/manage-users-create/sub-components/company-info/component/companyInfoForm.component.js'
import campaignInfoForm from './sub-components/manage-users-create/sub-components/campaign-info/component/campaignInfoForm.component.js'
import teamInfoForm from './sub-components/manage-users-create/sub-components/team-info/component/teamInfoForm.component.js'
import passwordInfoForm from './sub-components/manage-users-create/sub-components/password-info/component/passwordInfoForm.component.js'
import ManageUsers from './component/ManageUsersReact.component.js'
const dependencies = [
  '$state',
  'IdentityService',
  'SideNavService',
  'ManageMenuService',
  'RoleStore',
]
export default angular
  .module('portalApp.manage.users', [])
  .filter('filterCampaignsForCompany', filterCampaignsForCompany)
  .service('ManageUsersService', ManageUsersService)
  .service('ManageUsersEditService', ManageUsersEditService)
  .service('ManageUsersCreateService', ManageUsersCreateService)
  .component('manageUsers', manageUsers)
  .component('manageUsersEdit', manageUsersEdit)
  .component('personalInfoForm', react2angular(ManageUsers, [], dependencies))
  .component('roleInfoForm', roleInfoForm)
  .component('companyInfoForm', companyInfoForm)
  .component('campaignInfoForm', campaignInfoForm)
  .component('teamInfoForm', teamInfoForm)
  .component('passwordInfoForm', passwordInfoForm)
  .name
