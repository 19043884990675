import angular from 'angular'
import uiRouter from '@uirouter/angularjs'

// Run blocks
import routeProtector from './routeProtector/routeProtector.js'

// Services
import PermissionsStore from './permissions-store/services/PermissionsStore.js'
import RoleStore from './role-store/services/RoleStore.js'
import RoleValidator from './role-validator/services/RoleValidator.js'

// Directives
import dPermissions from './directives/permissions/dPermissions.js'

export default angular
  .module('permissions', [uiRouter])
  .run(routeProtector)
  .service('PermissionsStore', PermissionsStore)
  .service('RoleStore', RoleStore)
  .service('RoleValidator', RoleValidator)
  .directive('dPermissions', dPermissions).name
