import PerformanceHealthController from './controller/PerformanceHealthController.js'
import './performanceHealth.scss'
const template = require('./performanceHealth.tpl.pug')

const performanceHealth = {
  template: template(),
  controller: PerformanceHealthController,
  bindings: {
    key: '<',
    metric: '<'
  }
}

export default performanceHealth
