export default class EditEntityToolbarController {
  handleToggle (id) {
    if (id) {
      this.onToggle({id})
    } else {
      this.onToggle()
    }
  }

  $onInit () {
    let defaultOptions = {
      'value': this.entity.active,
      'name': this.entity.name
    }
    let {value, name, textFalse = 'Inactive', textTrue = 'Active'} = this.options || defaultOptions
    this.value = value || this.entity.active
    this.name = name || this.entity.name
    this.textFalse = textFalse
    this.textTrue = textTrue
  }
}
