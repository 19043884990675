export default function requestIntercept(
  $state,
  $mdToast,
  $localStorage,
  Restangular,
) {
  'ngInject'
  Restangular.addFullRequestInterceptor(
    (element, operation, route, url, headers, params, httpConfig) => {
      let authToken =
        $localStorage.impersonateToken || $localStorage.ppToken || null
      if (authToken) {
        headers.Authorization = `Token ${authToken}`
      }
      headers.Accept = 'application/json'
      return {
        element: element,
        params: params,
        headers: headers,
        httpConfig: httpConfig,
      }
    },
  )

  Restangular.setErrorInterceptor((response, deferred, responseHandler) => {
    switch (response.status) {
      case 401:
        return call401()
      default:
        return true
    }
  })

  function call401() {
    console.error('Unauthorized Request')
    return true
  }
}
