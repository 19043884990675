import './sideNav.scss'
import SideNavController from './controller/SideNavController.js'
import template from './sideNav.tpl.pug'

const sideNav = {
  template: template(),
  controller: SideNavController,
  bindings: {
    menuId: '<',
    menu: '<',
    userRoles: '<',
  },
}

export default sideNav
