import './createPitchSegments.scss'
import CreatePitchSegmentsController from './controller/CreatePitchSegmentsController.js'
import template from './createPitchSegments.tpl.pug'

const createPitchSegments = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreatePitchSegmentsController,
}

export default createPitchSegments
