// @flow
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

type Props = {
  message: string,
  classes: Object,
}

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    height: '55vh',
  },
  message: {
    padding: '30px',
    fontColor: theme.palette.text.primary,
    '& p': {
      color: '#7c8a97',
      margin: '0 0 25px 0',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
})

function Loader({ classes, message }: Props) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid
        alignItems="center"
        className={classes.message}
        container
        direction="column"
        justify="center"
      >
        <Grid item>
          <p>{message}</p>
        </Grid>
        <Grid item>
          <CircularProgress size={45} style={{ color: '#1194f6' }} />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(Loader)
