// Checking for string 'null' here because of a strange behavior of
// md-options value attribute

export default function () {
  return function (camps, company) {
    if (!company || company === 'null') {
      return camps
    } else if (camps) {
      return camps.filter(camp => camp.company === company)
    } else {
      return []
    }
  }
}
