import PaginationFooterController from './controller/PaginationFooterController.js'
import template  from './paginationFooter.tpl.pug'

const paginationFooter = {
  controller: PaginationFooterController,
  template: template(),
  bindings: {
    paginateTotal: '<',
    paginateLimit: '<',
    paginateCb: '&',
    paginateCurrentPage: '<',
  }
}

export default paginationFooter
