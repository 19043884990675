// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type IdentityService, {
  IdentityCampaign,
  IdentityRealm,
} from '../../../global/index/api-services/IdentityService.js'
import type { PermissionGroup } from 'permissions.js'
import type { FilterConfig } from '../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TableConfig } from '../../common-components/management-async-table/component/managementAsyncTable.component.js'
import type { SearchConfig } from '../../common-components/management-search/component/managementSearch.component.js'

import { CAMPAIGN_MANAGER_GROUP } from 'permissions.js'

export default class ManageCampaignsService {
  Company: IdentityCampaign
  Realm: IdentityRealm
  mff: Object
  defaultEditRoles: PermissionGroup

  constructor(
    IdentityService: IdentityService,
    managementFilterFactory: Object,
  ) {
    'ngInject'
    this.Company = IdentityService.Company
    this.Realm = IdentityService.Realm
    this.mff = managementFilterFactory
    this.defaultEditRoles = CAMPAIGN_MANAGER_GROUP
  }

  // Using getters makes it so we don't have to worry about mutation side-effects where these are used

  get searchConfig(): SearchConfig {
    return {
      editable: true,
      limit: 5,
      helperText: 'Try searching for a campaign name, uuid, or realm',
      searchValue: '',
      searchResults: [],
    }
  }

  get filterConfig(): FilterConfig {
    return [
      {
        label: 'Sort By',
        field: 'order_by',
        defaultValue: '-datetime_modified',
        options: [
          { title: 'Recently Edited', value: '-datetime_modified' },
          { title: 'Name A-Z', value: 'name' },
          { title: 'Name Z-A', value: '-name' },
        ],
      },
      {
        label: 'Active Status',
        field: 'active',
        defaultValue: null,
        options: [
          { title: 'All', value: null },
          { title: 'Active', value: true },
          { title: 'Inactive', value: false },
        ],
      },
      {
        label: 'Realm',
        field: 'realms',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'uuid',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.realms, { assignable: true }),
      },
      {
        label: 'Company',
        field: 'company',
        hasAll: true,
        defaultValue: null,
        valueAccessor: 'uuid',
        labelAccessor: 'name',
        options: this.mff.optionFetcher(this.mff.companies, {
          assignable: true,
        }),
      },
    ]
  }

  get tableConfig(): TableConfig {
    return {
      trackBy: 'uuid',
      columns: [
        { title: 'Name', accessor: 'name', type: 'text' },
        { title: 'Slug', accessor: 'slug', type: 'text' },
        { title: 'Status', accessor: 'active', type: 'active' },
        {
          title: 'Edit',
          type: 'button',
          icon: 'editor:mode_edit',
          actionCb: 'editOrView',
        },
      ],
      optionalColumns: [{ title: 'UUID', accessor: 'uuid', type: 'text' }],
    }
  }
}
