import './buyDidResults.scss'
import BuyDidResultsController from './controller/BuyDidResultsController'
const template = require('./buyDidResults.tpl.pug')

const buyDidResults = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: BuyDidResultsController,
}

export default buyDidResults
