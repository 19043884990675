export default class CreateProspectVoicesController {
  constructor(
    $mdDialog,
    $timeout,
    $state,
    PitchService,
    ToastService,
    ManagementService,
  ) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.$timeout = $timeout
    this.$state = $state
    this.PS = PitchService
    this.TS = ToastService
    this.MS = ManagementService

    this.createProspectVoice = {
      name: '',
      user: null,
    }

    this.state = {
      loading: false,
      message: false,
      error: false,
    }
  }

  $onInit() {
    this.service = this._getService(this.compSlug)
    this.testService = this.service.one('tests').one(this.testUuid)
  }

  _getService(compSlug) {
    const service = this.PS.setupRapidResponse(compSlug)
    if (service.company) {
      return service.company
    } else {
      return service.global
    }
  }

  searchUsers(searchText) {
    return this.MS.findEntity({
      searchText: searchText,
      editable: false,
      limit: 5,
      entity: 'user',
    })
      .then(results => results)
      .catch(err => {
        console.error(`user search error:`, err)
        this.TS.show({
          text: `user search error`,
        })
        return []
      })
  }

  addProspectVoice(voice) {
    const addProspectVoiceService = this.PS.setupAddProspectVoice()
    const newProspectVoice = {
      name: voice.name,
      user: voice.user.uuid,
    }
    this.state.loading = true
    addProspectVoiceService
      .post(newProspectVoice)
      .then(newVoice => {
        this.rapidResponseTest.voices.push(newVoice.user)
        this.testService
          .customPATCH(this.rapidResponseTest)
          .then(updatedRapidResponseTest => {
            this.$timeout(() => {
              this.state.message = true
              this.state.loading = false
            }, 1000)
          })
          .catch(err => {
            this.prospectVoiceCreationError(err)
          })
      })
      .catch(err => {
        this.prospectVoiceCreationError(err)
      })
  }

  prospectVoiceCreationError(err) {
    this.TS.show({
      text: 'Error on creating prospect voice',
    })
    this.$timeout(() => {
      this.state.error = true
      this.state.loading = false
    }, 1000)
    console.error(err)
  }

  recordAudio(voice) {
    const params = {
      voice: voice,
    }
    this.$state.go('manage.audioVoice.prospectAudio', params)
    this.$mdDialog.destroy()
  }

  close(newVariables) {
    this.$mdDialog.destroy()
  }
}
