import { map, reduce, omit } from 'ramda'
import WavWorker from 'workers/exportWAV.worker.js'
import { getBaseUrl } from '../../global/config/providers/restangularProvider/rest'
import "core-js/stable";
import "regenerator-runtime/runtime";
import { CREDENTIALS } from '../../settings';

const API_BASE_URL = getBaseUrl(window.location.host, true)
// Helpers
const plainify = map(entity => (entity.plain ? entity.plain() : entity))
const combineFileAndAudio = map(file =>
  Object.assign(omit(['audio'], file), file.audio),
)
function makePromisedAudio(service, audioType) {
  service[audioType] = service[audioType].get()
  return service
}

function makeAudioFilePromises(requester, audio) {
  const path = audio.key ? `${audio.key}` : `phrase/${audio.uuid}/file`
  requester.files.push(requester.service.one(path).get())
  return requester
}

function prepareFormData(file) {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

function fileUpload(service, uploadOptions, progressHandler, formData) {
  const eventHandlers = {
    progress: progressHandler,
  }
  return service
    .withHttpConfig({
      transformRequest: angular.identity,
      uploadEventHandlers: eventHandlers,
    })
    .customPOST(formData, '', uploadOptions, { 'Content-Type': undefined })
}

function audioFileUpload(file, service, uploadOptions, progressHandler) {
  const formData = new FormData()
  formData.append('file', file)
  const eventHandlers = {
    progress: progressHandler,
  }
  console.log("uploadAudioFileUPload")
  return service
    .withHttpConfig({
      transformRequest: angular.identity,
      uploadEventHandlers: eventHandlers,
    })
    .customPOST(formData, '', uploadOptions, { 'Content-Type': undefined })
}



// Service
export default class ManageAudioService {
  constructor($q, $http, Restangular, QueueService) {
    'ngInject'
    this.$q = $q
    this.$http = $http
    this.tempUploadService = Restangular.oneUrl('pitch/audio/temporary-upload')
    this.QS = QueueService
    this.audioCtx = new AudioContext()
    this.workerActions = Object.freeze({
      FETCH_FILE: 'FETCH_FILE',
      CONVERT_TO_WAV: 'CONVERT_TO_WAV',
    })
  }
  

  getAudioFile(audioCtx, blobUrl) {
    const wavWorker = new WavWorker() // TODO explore a worker pool
    return this.$q(resolve => {
      wavWorker.onmessage = e => {
        const buffer = e.data
        audioCtx.decodeAudioData(buffer).then(audioData => {
          resolve(audioData)
          wavWorker.terminate()
        })
      }
      wavWorker.postMessage({
        type: this.workerActions.FETCH_FILE,
        payload: blobUrl,
      })
    })
  }
  fetchVoiceAudio({ service, audio }) {
    const promisedAudio = reduce(makePromisedAudio, service, audio)
    return this.$q.all(promisedAudio).then(plainify)
  }

  bulkUploadAudio(toUpload) {
    return this.QS.execQueue({
      queue: toUpload,
      maxConcurrent: 2,
      promiseCb: this.uploadBlobUrl.bind(this),
    }).then(plainify)
  }

  convertToWav(fileName, audioBuffer) {
    const wavWorker = new WavWorker()
    return this.$q(resolve => {
      wavWorker.onmessage = e => {
        resolve(e.data)
        wavWorker.terminate()
      }
      wavWorker.postMessage({
        type: this.workerActions.CONVERT_TO_WAV,
        payload: {
          channels: {
            mono: audioBuffer.getChannelData(0),
          },
          format: '16bit',
          sampleRate: audioBuffer.sampleRate,
          fileName,
        },
      })
    })
  }
  realmData(uuid){
    return this.$http({
      method: 'GET',
      url: `${API_BASE_URL}/identity/realm/${uuid}`,
      headers : {
        'Authorization': `Token ${userToken}`,
        'Content-Type': 'undefined',
      },
    }).then(function successCallback(response) {
        return response
      }, function errorCallback(err) {
        return err
      })
      
  }
  async getAllSlugs(realms){
    let userToken = JSON.parse(localStorage.getItem('ngStorage-ppToken'))
    const slugArr = []
    for (const item of realms) {
      const slugValue = await this.$http({
        method: 'GET',
        url: `${API_BASE_URL}/identity/realm/${item}`,
        headers : {
          'Authorization': `Token ${userToken}`,
          'Content-Type': 'application/json',
        },
      }).then(function successCallback(response) {
          return response
        }, function errorCallback(err) {
          return err
        })
        .then(({data}) => {
          return data.slug
        })
      
      slugArr.push(slugValue)
    }
    return slugArr
  }

  async getRealms(namespaceKey){
    let realms = null
    let userToken = ''
    let realmSlug = []
    switch (namespaceKey) {
      case 'namespace':
        realms = JSON.parse(sessionStorage.getItem('voiceRealms'))
        realmSlug = await this.getAllSlugs(realms)
        break;
      case 'phrase_book':
        await this.$http({
          method: 'POST',
          url: `${API_BASE_URL}/identity/user/login/`,
          data: CREDENTIALS
        }).then(function successCallback(response) {
          return response.data
        }, function errorCallback(err) {
          return err
        }).then(({auth_token}) => {
          userToken = auth_token
        })
    
        
        let data = await this.$http({
          method: 'GET',
          url: `${API_BASE_URL}/identity/realm/list/`,
          headers : {
            'Content-Type': undefined,
            'Authorization': `Token ${userToken}`
          },
        }).then(function successCallback(response) {
              return response.data
            }, function errorCallback(err) {
              return err
            })
        let globalRealmSlug = data.map(item => {
          return item.slug
        })
        
        realmSlug = globalRealmSlug
        break;
      default:
        break;
    }
    return realmSlug
  }
  
  async syncUploadFile(namespaceKey, namespaceValue, file_url, audio, audio_filename){
    console.log("Syncing audio file processing...")
    sessionStorage.setItem('syncLoading', true)
    let userInfo = JSON.parse(sessionStorage.getItem('ngStorage-user'))
    let slugs = await this.getRealms(namespaceKey)
    if(slugs){
      let data = {
        "username": userInfo.username,
        "realms": slugs,
        "file_url": file_url,
        "audio": audio,
        "filename": audio_filename
      }
      data[namespaceKey] = namespaceValue
      this.$http({
        method: 'POST',
        url: `https://kn4wwqlr34.execute-api.us-east-1.amazonaws.com/production/`,
        headers : {
          'Content-Type': undefined,
        },
        data: data
      }).then(function successCallback(response) {
            console.log(response)
            sessionStorage.setItem('syncLoading', false)
            return response
          }, function errorCallback(err) {
            return err
          })
    }
    
  }
  
  uploadBlobUrl(config) {
    const {
      service,
      fileName,
      blobUrl,
      uploadOptions,
      progressHandler,
    } = config
    return this.getAudioFile(this.audioCtx, blobUrl)
      .then(this.convertToWav.bind(this, fileName))
      .then(prepareFormData)
      .then(fileUpload.bind(null, service, uploadOptions, progressHandler))
      .then(res => {
        // Sync audio file
        // if(res.voice){
        //   let audio, namespaceValue, namespaceKey, audio_filename = null
        //   if(res.audio.hash){
        //     namespaceValue = res.audio.namespace
        //     namespaceKey = 'namespace'
        //     audio = 'pitch'
        //     audio_filename = res.audio.hash
        //   }
        //   if(res.audio.phrase){
        //     let phrase = JSON.parse(sessionStorage.getItem('phraseBook'))
        //     namespaceValue = phrase.slug
        //     namespaceKey = 'phrase_book'
        //     audio = 'phrase'
        //     audio_filename = res.audio.slug
        //   }
        //   this.syncUploadFile(namespaceKey, namespaceValue, res.original_url, audio, audio_filename)
        // }
        res.fileName = fileName
        return res
      })
  }
  
  uploadAudioFile(config) {
    const { service, fileName, uploadOptions, progressHandler, file } = config
    return audioFileUpload(file, service, uploadOptions, progressHandler).then(
      res => {
        // Sync upload audio file
        // if(res.voice){
        //   let audio, namespaceValue, namespaceKey, audio_filename = null
        //   if(res.audio.hash){
        //     namespaceValue = res.audio.namespace
        //     namespaceKey = 'namespace'
        //     audio = 'pitch'
        //     audio_filename = res.audio.hash
        //   }
        //   if(res.audio.phrase){
        //     let phrase = JSON.parse(sessionStorage.getItem('phraseBook'))
        //     namespaceValue = phrase.slug
        //     namespaceKey = 'phrase_book'
        //     audio = 'phrase'
        //     audio_filename = res.audio.slug
        //   }
        //   this.syncUploadFile(namespaceKey, namespaceValue, res.original_url, audio, audio_filename)
        // }
        res.fileName =  fileName
        return res
      },
    )
  }

  populateAudioFiles({ service, audio = [] }) {
    const promisedAudioFiles = reduce(
      makeAudioFilePromises,
      { files: [], service },
      audio,
    )
    return this.$q
      .all(promisedAudioFiles.files)
      .then(plainify)
      .then(combineFileAndAudio)
  }

  revokeObjectUrl(audio) {
    window.URL.revokeObjectURL(audio.blobUrl)
  }
}

