import { ADMIN_GROUP } from 'permissions.js'

export default class ManageSettingsFormController {
  constructor($scope, ToastService) {
    'ngInject'
    this.$scope = $scope
    this.TS = ToastService
    this.ADMIN_GROUP = ADMIN_GROUP
  }

  $onInit() {
    this.editableEntity = Object.assign({}, this.entity)
    this.$scope.$watch(
      '$ctrl.setPristine',
      newValue => {
        if (newValue) {
          this.manageSettingsForm.$setPristine()
        }
      },
      true,
    )
  }

  cancelEdit(form) {
    this.editableEntity = Object.assign({}, this.entity, {
      leader: this.locationLeaderObj,
    })
    form.$setPristine()
  }

  handleCopy(label) {
    this.TS.show({
      text: `${label} copied to clipboard`,
    })
  }

  toggleActive(entity, accessor) {
    entity[accessor] = !entity[accessor]
  }
}
