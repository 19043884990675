// dashboard.repRankings view

const dashboardRepRankings = 'dashboard.repRankings'

const dashboardRepRankingsConfig = {
  url: '/agent-rank',
  component: 'repRankings',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign) {
      'ngInject'
      return selectedCampaign
    },
    offsetMinutes: function (timezoneOffsetMinutes) {
      'ngInject'
      return timezoneOffsetMinutes
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    }
  }
}

export { dashboardRepRankings, dashboardRepRankingsConfig }
