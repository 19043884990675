import { map } from 'ramda'

export default class updatePasswordController {
  constructor($timeout, $sessionStorage, IdentityService) {
    'ngInject'

    this.$timeout = $timeout
    this.$sessionStorage = $sessionStorage
    this.IS = IdentityService
    this.containsDigit = /\d/ // test if string contains at least 1 digit
    this.state = {
      updating: false,
      updateError: false,
      needsUpdate: true,
      errorObj: {},
    }
  }

  updatePassword(password) {
    this.dismissErrors()
    this.IS.User.setPassword
      .post(password)
      .then(updatedUser => {
        this.$sessionStorage.user = updatedUser
        this.state.updating = true
        this.$timeout(() => {
          this.state.updating = false
          this.state.needsUpdate = false
        }, 1000)
      })
      .catch(err => {
        this.state.errorObj = this.processErrorObj(err.data)
        this.state.updateError = true
        console.error('COULD NOT SET PASSWORD: ', this.state.errorObj)
      })
  }

  processErrorObj(errorData) {
    return map(err => err.join(''), errorData)
  }

  dismissErrors() {
    this.state.updateError = false
    this.state.errorObj = {}
  }
}
