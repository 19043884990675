export default class PieChart {
  constructor ($timeout) {
    'ngInject'

    this.pieChart = {
      options: {
        credits: false,
        chart: {
          type: 'pie'
        },
        tooltip: {},
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y} %<br> {point.value}',
            }
          }
        },
        exporting: {
          chartOptions: {
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          }
        }
      },
      title: {
        text: ''
      },
      series: [],
      func: function (chart) {
        $timeout(() => {
          chart.reflow()
        }, 200)
      }
    }
  }

  getChart () {
    return angular.copy(this.pieChart)
  }
}
