import { map } from 'ramda'
import { LOCATION_LEADER_GROUP } from 'permissions.js'
import { groupFilter } from 'util/permissions/groups.js'

export default class EditLocationController {
  constructor(
    $scope,
    $state,
    $timeout,
    $mdDialog,
    $sessionStorage,
    ManagementService,
    ManageLocationsService,
    ToastService,
    IdentityService,
  ) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.user = $sessionStorage.user
    this.MS = ManagementService
    this.MLS = ManageLocationsService
    this.TS = ToastService
    this.groups = IdentityService.Groups
    this.state = {
      showDetails: false,
      loadingDetails: true,
      allowedLocationLeaderGroups: null,
      teams: {
        list: [],
        loading: true,
      },
      backTo: {
        name: 'locations',
        route: 'manage.locations',
      },
    }
  }

  $onInit() {
    this.editableLocation = Object.assign({}, this.location)
    this.getLocationLeader(this.location, 'location')
    this.getLocationTeams(this.location.uuid)
    this.getLocationLeaderGroupPKs()
  }

  goToCreateTeam() {
    this.$state.go('manage.editLocation.createTeam.create', null, {
      location: false,
    })
  }

  editTeam(team) {
    const params = {
      teamSlug: team.slug,
      teamId: team.uuid,
      team: team,
      location: this.location,
    }
    this.$state.go('manage.editTeam', params)
  }

  getLocationLeaderGroupPKs() {
    this.groups.list
      .get({ assignable: false })
      .then(groups => groups.plain())
      .then(groupFilter(LOCATION_LEADER_GROUP))
      .then(map(group => group.pk))
      .then(locationLeaderGroups => {
        this.allowedLocationLeaderGroups = locationLeaderGroups
      })
  }

  getLocationLeader(entity, entityName) {
    this.MS.populateEntityRelations(entity, { entityName })
      .then(relations => {
        this.locationLeaderObj = relations.leader
        this.editableLocation.leader = relations.leader
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.loadingDetails = false
          this.locationForm.$setPristine()
        }, 1000)
      })
  }

  searchUsers(searchText) {
    const searchConfig = {
      entity: 'user',
      searchText,
      editable: true,
      groups: this.allowedLocationLeaderGroups,
    }
    return this.MS.findEntity(searchConfig).then(users => users)
  }

  getLocationTeams(locationId) {
    this.state.teams.loading = true
    this.MLS.getLocationTeams(locationId)
      .then(teamsList => {
        this.state.teams.list = teamsList
      })
      .catch(console.error)
      .finally(() => {
        this.state.teams.loading = false
      })
  }

  updateLocation(location) {
    const parsedLocation = this.parseLocation(location)
    this.MS.updateEntity({ entity: 'location', data: parsedLocation })
      .then(updatedLocation => {
        this.location = updatedLocation
        this.editableLocation = Object.assign({}, updatedLocation)
        this.getLocationLeader(updatedLocation, 'location')
        this.locationForm.$setPristine()
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0],
          })
        }
        this.TS.show({
          text: 'Error updating location',
        })
      })
  }

  parseLocation(location) {
    const leaderId = location.leader ? location.leader.uuid : null
    return Object.assign({}, location, { leader: leaderId })
  }

  cancelEdit(form) {
    this.editableLocation = Object.assign({}, this.location, {
      leader: this.locationLeaderObj,
    })
    form.$setPristine()
  }

  toggleDetails() {
    this.state.showDetails = !this.state.showDetails
  }

  // callback for active-toggle component
  toggleActive(bool) {
    this.editableLocation.active = bool
  }

  parseLocationLeader(leaderObj) {
    if (leaderObj) {
      return `${leaderObj.username} | ${leaderObj.first_name} ${
        leaderObj.last_name
      }`
    }
    return 'None'
  }

  removeLeader() {
    this.editableLocation.leader = null
    this.searchForm.$setPristine()
  }
}
