import { calculateMetricsByRep } from 'util/calculate/metrics/by-rep/calculateMetricsByRep.js'
import { formatRepData, formatRepMetrics } from 'util/calculate/metrics/by-rep/formatting.js'
import { createRangeDetails } from 'util/query/queryBuilder.js'

export default class RepStatsService {
  constructor ($q, StatsService, MetricsService) {
    'ngInject'

    this.$q = $q
    this.promisifyStats = StatsService.promisifyStats.bind(StatsService)
    this.getComponentMetrics = MetricsService.getMetrics.bind(MetricsService)
  }

  getStats (component, dateRange, realm) {
    const queryDetails = createRangeDetails(component, dateRange, realm, 'metricByRep')
    const componentMetrics = this.getComponentMetrics(component.metrics)
    const repStats = this.fetchRepStats(queryDetails, componentMetrics.call)

    return this.$q
      .all(repStats)
      .then(formatRepData)
      .then(calculateMetricsByRep)
      .then(formatRepMetrics)
  }

  fetchRepStats (query, metrics) {
    return {
      stats: this.promisifyStats(query),
      metrics
    }
  }
}
