// dashboard.repTrend view

const dashboardRepTrend = 'dashboard.repTrend'

const dashboardRepTrendConfig = {
  url: '/agent-trend',
  component: 'repTrend',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign) {
      'ngInject'
      return selectedCampaign
    },
    offsetMinutes: function (timezoneOffsetMinutes) {
      'ngInject'
      return timezoneOffsetMinutes
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    },
  }
}

export { dashboardRepTrend, dashboardRepTrendConfig }
