// @flow

import GlobalPitchSegmentsController from './controller/manageGlobalPitchSegments.js'
const template = require('./manageGlobalPitchSegments.component.tpl.pug')

const manageGlobalPitchSegments = {
  template: template(),
  controller: GlobalPitchSegmentsController,
  bindings: {},
}

export default manageGlobalPitchSegments
