import './rapidResponseTestsMenu.scss'
import RapidResponseTestsMenuController from './controller/RapidResponseTestsMenuController.js'
const template = require('./rapidResponseTestsMenu.component.tpl.pug')

const editRapidResponseTestsMenu = {
  controller: RapidResponseTestsMenuController,
  template: template(),
  bindings: {
    compSlug: '<',
    testUuid: '<',
    rapidResponseTest: '<',
  },
}

export default editRapidResponseTestsMenu
