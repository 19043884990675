// Dashboard states
import { dashboard, dashboardConfig } from './dashboard.js'
import dashboardAgentsRoutes from './child-states/dashboard-agents/dashboard.agents.routes'
import dashboardDialerLiveRoutes from './child-states/dashboard-dialerLive/dashboard.dialerLive.routes'
import dashboardDialerQueueRoutes from './child-states/dashboard-dialerQueue/dashboard.dialerQueue.routes'
import dashboardGoalsRoutes from './child-states/dashboard-goals/dashboard.goals.routes'
import dashboardListOverviewRoutes from './child-states/dashboard-listOverview/dashboard.listOverview.routes'
import dashboardListsRoutes from './child-states/dashboard-lists/dashboard.lists.routes'
import dashboardOverviewRoutes from './child-states/dashboard-overview/dashboard.overview.routes'
import dashboardRepRankingsRoutes from './child-states/dashboard-repRankings/dashboard.repRankings.routes'
import dashboardRepTrendRoutes from './child-states/dashboard-repTrend/dashboard.repTrend.routes'

export default function manageRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(dashboard, dashboardConfig)
  dashboardAgentsRoutes($stateProvider)
  dashboardDialerLiveRoutes($stateProvider)
  dashboardDialerQueueRoutes($stateProvider)
  dashboardGoalsRoutes($stateProvider)
  dashboardListOverviewRoutes($stateProvider)
  dashboardListsRoutes($stateProvider)
  dashboardOverviewRoutes($stateProvider)
  dashboardRepRankingsRoutes($stateProvider)
  dashboardRepTrendRoutes($stateProvider)
}
