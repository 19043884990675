// @flow

import template from './profileMenu.tpl.pug'

type MenuSection = {
  title: string,
  icon: string,
  actionCb: Function,
}

export type ProfileMenuConfig = {
  openCb: Function,
  settings: {
    offset: string,
    positionMode: string,
    contentWidth: string,
  },
  sections: Array<MenuSection>,
}

const profileMenu = {
  template: template(),
  bindings: {
    displayName: '<',
    menuConfig: '<',
    openCb: '&',
    positionMode: '@',
  },
}

export default profileMenu
