import { map } from 'ramda'
import moment from 'moment'

function detailAdder(details) {
  return function(metric) {
    metric.details = details
    return metric
  }
}

function createCompareRangeDates(dateRange) {
  const dstOffset = moment(dateRange.startDate).isDST() ? 1 : 0
  return {
    epochStart: moment(dateRange.startDate)
      .add(dstOffset, 'hours')
      .unix(),
    epochEnd: moment(dateRange.endDate).unix(),
    historical: moment(dateRange.endDate).isBefore(dateRange.currentDay),
  }
}

function createBaseRangeDates(dateRange) {
  const dstOffset = moment(dateRange.currentDate)
    .subtract(89, 'days')
    .isDST()
    ? 1
    : 0
  const start = moment(dateRange.currentDate)
    .subtract(89, 'days')
    .add(dstOffset, 'hour')
    .unix()
  const end = moment(dateRange.currentDate)
    .subtract(1, 'day')
    .endOf('day')
    .add(dateRange.normalizedOffset, 'minutes')
    .unix()

  return {
    epochStart: start,
    epochEnd: end,
    historical: true,
  }
}

function getDateRangeDetails(dateRange, rangeType = '') {
  switch (rangeType) {
    case 'compareRange':
    case 'metricByRep':
      return createCompareRangeDates(dateRange)
    case 'baseRange':
      return createBaseRangeDates(dateRange)
  }
}

function addDetails(component, metrics, realm) {
  const details = {
    compSlug: component.company,
    campSlug: component.campaign,
    realm: realm,
  }
  return map(detailAdder(details), metrics)
}

function createRangeDetails(component, dateRange, realm, rangeType = '') {
  const { historical, epochStart, epochEnd } = getDateRangeDetails(
    dateRange,
    rangeType,
  )
  return {
    type: rangeType,
    historical,
    context: component.context,
    compSlug: component.company,
    campSlug: component.campaign,
    realm,
    epochStart,
    epochEnd,
    requestTime: dateRange.requestTime,
  }
}

function addSingleQueryDetail(component, dateRange, metrics, realm) {
  return {
    range: createRangeDetails(component, dateRange, realm, 'compareRange'),
    call: metrics.call,
    dataDog: addDetails(component, metrics.dataDog, realm),
    repsLive: addDetails(component, metrics.repsLive, realm),
    prospect: addDetails(component, metrics.prospect, realm),
    metricKeysArr: metrics.metricKeysArr,
  }
}

function addQueryDetails(component, dateRange, metrics, realm) {
  return {
    baseRange: createRangeDetails(component, dateRange, realm, 'baseRange'),
    compareRange: createRangeDetails(
      component,
      dateRange,
      realm,
      'compareRange',
    ),
    call: metrics.call,
    dataDog: addDetails(component, metrics.dataDog, realm),
    repsLive: addDetails(component, metrics.repsLive, realm),
    prospect: addDetails(component, metrics.prospect, realm),
    metricKeysArr: metrics.metricKeysArr,
  }
}

export { addQueryDetails, addSingleQueryDetail, createRangeDetails }
