import ManageCampaignsController from './controller/ManageCampaignsController.js'
const template = require('./manageCampaigns.component.tpl.pug')

const manageCampaigns = {
  template: template(),
  controller: ManageCampaignsController,
  bindings: {},
}

export default manageCampaigns
