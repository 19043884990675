import { CAMPAIGN_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'transferMinutes',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Transfer Minutes',
    append: '/min',
  },
}

const keys = {
  statKey: 'transfer_seconds',
  componentKeys: [CAMPAIGN_METRICS],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'aggregate',
  convertTo: 'minutes',
}

const transferMinutes = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { transferMinutes }
