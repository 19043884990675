import moment from 'moment'
import {
  dialerColumnsQueueConfig,
  dialerColumnsConnectedConfig,
} from '../config/dialerQueueConfig.js'
import { sortByWaitTime, sortByDuration } from '../util/dialerQueueUtil.js'

export default class QueueController {
  constructor($interval, DialerQueueService, ToastService) {
    'ngInject'
    this.interval = $interval
    this.intervalMilliSeconds = 10000
    this.intervalPromise = null
    this.isIntervalActive = false

    this.title = 'Dialer Queue Report'
    this.DQS = DialerQueueService
    this.TS = ToastService
    this.state = {
      loadingConnected: false,
      loadingQueue: false,
      columnsQueueConfig: dialerColumnsQueueConfig,
      columnsConnectedConfig: dialerColumnsConnectedConfig,
      dialerQueueReports: [],
      dialerConnectedReports: [],
    }

    this.searchLiveQueueReport = ({ searchText }) => {
      this.searchQueueText = searchText
    }
    this.searchLiveConnectedReport = ({ searchText }) => {
      this.searchConnectedText = searchText
    }
  }

  $onInit() {
    this.state.selected = {
      campaign: this.campaign,
      realm: this.realm,
    }
    this.callInterval()
  }

  $onDestroy() {
    this.cancelInterval()
  }

  pauseOrPlayInterval() {
    if (this.isIntervalActive) {
      this.cancelInterval()
      this.isIntervalActive = false
    } else {
      this.callInterval()
    }
  }

  cancelInterval() {
    this.interval.cancel(this.intervalPromise)
  }

  callInterval() {
    this.isIntervalActive = true
    this.getLiveDialerQueueReports()
    this.intervalPromise = this.interval(() => {
      this.getLiveDialerQueueReports()
    }, this.intervalMilliSeconds)
  }

  getLiveDialerQueueReports() {
    const campaignSlugs = this.state.selected.campaign
      ? this.state.selected.campaign.slug
      : null
    const realmSlugs = this.state.selected.realm
      ? this.state.selected.realm.slug
      : null
    this.state.loadingConnected = true
    this.state.loadingQueue = true

    if (campaignSlugs) {
      this.DQS.getLiveDialerQueueReports(campaignSlugs, realmSlugs)
        .then(data => {
          this.populateSumTotalTable(data)
          this.state.loadingConnected = false
          this.state.loadingQueue = false
          this.lastTimeUpdated = 'Last Sync: ' + moment().format('h:mm:ss A')
        })
        .catch(err => {
          this.TS.show({
            text: `Problem fetching ${this.title}`,
          })
          console.error('Live Dialer Queue Error: ', err)
        })
    }
  }

  populateSumTotalTable(dialerEndpointData) {
    this.state.dialerQueueReports = []
    this.state.dialerConnectedReports = []

    if (dialerEndpointData.length) {
      for (const realmDialerQueueReport of dialerEndpointData[0].realms) {
        if (realmDialerQueueReport.report.queue) {
          for (const queueReport of realmDialerQueueReport.report.queue) {
            queueReport.realm = realmDialerQueueReport.realm
            this.state.dialerQueueReports.push(queueReport)
          }
        }
        if (realmDialerQueueReport.report.connected) {
          for (const connectedeReport of realmDialerQueueReport.report
            .connected) {
            connectedeReport.realm = realmDialerQueueReport.realm
            this.state.dialerConnectedReports.push(connectedeReport)
          }
        }
      }
      // Apply sorting by wait time for QueueReports and by duration for ConnectedReports
      this.state.dialerQueueReports.sort(sortByWaitTime)
      this.state.dialerConnectedReports.sort(sortByDuration)
    }
  }
}
