import debounce from 'lodash/debounce'
import braceBundle from '../../../../../bundles/brace.bundle.js'

const themePrefix = 'ace/theme'
const modePrefix = 'ace/mode'

function debouncedUpdate(scope, editorDocument) {
  return debounce(function() {
    scope.onTextUpdate({ text: editorDocument.getValue() })
  }, 500)
}

function linkFn(scope, elem, attrs) {
  let editor
  let session
  let editorDocument
  const { readOnly = false } = scope.editorOptions
  // See webpack.config.js for the chunk configuration
  braceBundle(aceMod => {
    const ace = aceMod.default
    editor = ace.edit(elem[0])
    session = editor.getSession()
    editorDocument = session.getDocument()
    if (scope.editorText) editorDocument.setValue(scope.editorText)
    // Registering a function that can be called from outside the isolated scope to reset the editor
    if (!readOnly) {
      scope.editorReset = scope.editorReset(editorDocument)
    }

    editor.setTheme(`${themePrefix}/${scope.editorOptions.theme}`)
    editor.setShowInvisibles(true)
    editor.setFontSize(scope.editorOptions.fontSize)
    editor.setReadOnly(readOnly)
    session.setMode(`${modePrefix}/${scope.editorOptions.mode}`)
    session.on('change', debouncedUpdate(scope, editorDocument))
  })

  scope.$watch('editorOptions.theme', (newVal, oldVal) => {
    if (newVal === oldVal) return
    editor.setTheme(`${themePrefix}/${newVal}`)
  })

  scope.$watch('editorOptions.fontSize', (newVal, oldVal) => {
    if (newVal === oldVal) return
    editor.setFontSize(newVal)
  })

  scope.$watch('editorText', (newVal, oldVal) => {
    if (newVal === oldVal) return
    editorDocument.setValue(scope.editorText)
  })

  elem.on('$destroy', () => {
    session.$stopWorker()
    editor.destroy()
  })
}

export default function() {
  return {
    restrict: 'A',
    scope: {
      editorOptions: '=',
      editorText: '=',
      // This is a HOF that allows this function to be defined and called from outside the directive
      editorReset: '=',
      onTextUpdate: '&',
    },
    link: linkFn,
  }
}
