import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AppButton from '../../../../components/AppButton.js'
import AppSelect from '../../../../components/AppSelect.js'
import AppModal from '../../../../components/AppModal.js'
import AppTextField from '../../../../components/AppTextField.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({}))

const QAPost = props => {
  const [item, setItem] = useState('')
  //const classes = useStyles()
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  return (
    <AppModal
      modalWith={420}
      modalHeight={270}
      title={'Send post'}
      showModal={props.showModal}
      hideModal={props.hideModal}
    >
      <Grid container className="p-2">
        <Grid item xs={12} className="mt-2">
          <AppSelect
            data={numbers}
            label="Select post"
            item={item}
            onChange={e => setItem(e)}
          />
        </Grid>
        <Grid item xs={12} className="text-center mt-4">
          <AppButton type={'Green'} width={165}>SEND</AppButton>
        </Grid>
      </Grid>
    </AppModal>
  )
}

export default QAPost
