import './editCampaignRouter.scss'
import EditCampaignRouterController from './controller/EditCampaignRouterController.js'
const template = require('./editCampaignRouter.component.tpl.pug')

const editCampaignRouter = {
  template: template(),
  controller: EditCampaignRouterController,
  bindings: {
    campaign: '<',
    router: '<',
    statusCodes: '<',
  },
}

export default editCampaignRouter
