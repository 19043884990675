// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useState } from 'react';
import axios from "axios"
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';

import ActionButton from 'common-components/action-button/ActionButton'
import CreateLoader from 'common-components/create-loader/CreateLoader'

import './Create.scss'

const API_BASE_URL = getBaseUrl(window.location.host, true)
const CancelToken = axios.CancelToken;
let cancel;

const styles = ({
    input: {
        marginTop: '20px',
        '& label.Mui-focused': {
            color: '#1194f6 !important',
        },
        '& .MuiInput-underline.Mui-focused:after': {
            borderBottomColor: '#1194f6',
        },
    },
})

const CreateRealmForm = (props) => {
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState({});

    const handleSubmit = () => {
        setIsLoading(true);

        axios.
            post(`${API_BASE_URL}/identity/realm/create/`, {
                slug: name.toLocaleLowerCase().replace(/\s/g, '-'),
                name: name,
                active: active,
            }, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                const newRealm = {
                    active: res.data.active,
                    name: res.data.name,
                    slug: res.data.slug,
                    uuid: res.data.uuid,
                };

                props.handleCreate(newRealm);
                setIsLoading(false);
            })
            .catch((e: { data?: { name: string[] } }) => {
                setIsLoading(false);
                if (e.response) {
                    if (e.response.data.name) {
                        setErrMsg({ ...errMsg, name: e.response.data.name[0] });
                    } else if (e.response.data.slug) {
                        setErrMsg({ ...errMsg, name: e.response.data.slug[0] });
                    }
                }

                if (axios.isCancel(e)) {
                    console.log('Request canceled');
                }
            })
    }

    const handleCancelCreation = () => {
        console.log("cancel creation");
        setIsLoading(false);
        cancel();
    }

    return (
        isLoading ?
            <React.Fragment>
                <CreateLoader
                    message="One moment. We're creating the new Realm..."
                    handleCancel={handleCancelCreation} />
            </React.Fragment> :
            < React.Fragment >
                <DialogTitle id="create-realm-dialog">Create new realm
            <IconButton disableRipple={true} aria-label="close" className="closeButton" onClick={props.handleCancel}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form>
                        <TextField
                            required
                            className={props.classes.input}
                            label="Realm name"
                            id="standard-required"
                            fullWidth
                            type="text"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                                setErrMsg({ ...errMsg, name: "" });
                            }}
                            error={errMsg.name ? true : false}
                            helperText={errMsg.name ? errMsg.name : " "}
                            onBlur={() => {
                                if (name) {
                                    setDisabled(false);
                                    setErrMsg({ ...errMsg, name: "" });
                                } else {
                                    setDisabled(true);
                                    setErrMsg({ ...errMsg, name: "A realm name is required" });
                                }
                            }}
                        />
                        <Input
                            id="standard-full-width"
                            label="Realm status"
                            className={props.classes.input}
                            placeholder="Realm status"
                            value={active ? 'Active' : 'Inactive'}
                            fullWidth
                            endAdornment={<InputAdornment position="end">
                                <Switch
                                    id='realm-active'
                                    checked={active}
                                    onChange={e => {
                                        setActive(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            </InputAdornment>
                            }
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="center" spacing={2}
                        style={{ marginBottom: '15px', marginTop: '15px' }}>
                        <Grid item>
                            <ActionButton handleClick={props.handleCancel}
                                buttonColor="default" >CANCEL</ActionButton>
                        </Grid>
                        <Grid item>
                            <ActionButton
                                handleClick={handleSubmit}
                                disabled={disabled}
                                buttonColor="secondary"
                            > CREATE </ActionButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </React.Fragment >
    )

}

export default withStyles(styles)(CreateRealmForm) 
