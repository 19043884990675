import './audioRecording.dialog.scss'
import AudioRecordingDialogController from './controller/AudioRecordingDialogController.js'
import template from './audioRecording.dialog.tpl.pug'

const audioRecordingDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  escapeToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: AudioRecordingDialogController,
}

export default audioRecordingDialog
