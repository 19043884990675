export default class SumTotalTableController {
  setOrdering(accessor, ordering) {
    if (ordering === 'asc')
      this.tableData.sort((a, b) => a[accessor] - b[accessor])
    else if (ordering === 'desc')
      this.tableData.sort((a, b) => b[accessor] - a[accessor])

    for (let i = 0; i < this.tableConfig.length; i++) {
      if (this.tableConfig[i].accessor === accessor)
        this.tableConfig[i].ordering = ordering
      else if (this.tableConfig[i].hasOwnProperty('ordering'))
        this.tableConfig[i].ordering = 'desc'
    }
  }
}
