import { MANAGE_AUDIO_VOICE } from '../manage.audioVoice.js'

// manage voice prospect audio view
// state will be | manage.audioVoice.prospectAudio
const MANAGE_VOICE_PROSPECT_AUDIO = `${MANAGE_AUDIO_VOICE}.prospectAudio`

const prospectAudioConfig = {
  url: '/prospect',
  params: {
    voice: null,
  },
  component: 'prospectAudio',
  resolve: {
    voice: function($transition$) {
      'ngInject'
      return $transition$.params().voice
    },
  },
}

export { MANAGE_VOICE_PROSPECT_AUDIO, prospectAudioConfig }
