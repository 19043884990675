// Edit team view

const manageEditTeam = 'manage.editTeam'

const manageEditTeamConfig = {
  url: '/team/edit/{teamSlug}/{teamId}',
  params: {
    teamSlug: null,
    teamId: null,
    team: null,
    location: null,
  },
  component: 'editTeam',
  resolve: {
    team: function ($state, $transition$, IdentityService) {
      'ngInject'
      const team = $transition$.params().team
      if (team) return team
      return IdentityService.Team.fetch
        .one($transition$.params().teamId)
        .get()
        .then(team => team.plain())
    },
    location: function ($transition$, IdentityService, team) {
      'ngInject'
      const location = $transition$.params().location
      if (location) return location
      return IdentityService.Location.fetch
        .one(team.location)
        .get()
        .then(location => location.plain())
    },
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Editing Team'
      $window.document.title = pageTitle
      return pageTitle
    },
  }
}

export { manageEditTeam, manageEditTeamConfig }
