import CompanyRapidResponseSegmentsController from './controller/CompanyRapidResponseSegmentsController'
import template from './companyRapidResponseSegments.component.tpl.pug'

const companyRapidResponseSegments = {
  template: template(),
  controller: CompanyRapidResponseSegmentsController,
  bindings: {
    company: '<',
  },
}

export default companyRapidResponseSegments
