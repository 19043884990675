// @flow

export interface NameSpaceStore {
  store: {[string]: {[string]: any}};

  createStoreSpace(nameSpace: string): NameSpaceStore;

  setInStore(space: string, key: string, content: any): NameSpaceStore;

  getFromStore(space: string, key: string): any;

  getStore(space: string): {[string]: any};

  removeFromStore(space: string, key: string): NameSpaceStore;

  clearStore(space: string): NameSpaceStore;
}


export default class Store implements NameSpaceStore {
  store: {[storeSpace: string]: {[string]: any}}

  constructor () {
    this.store = {}
  }

  createStoreSpace (storeSpace: string) {
    this.store[storeSpace] = {}
    return this
  }

  setInStore (space: string, key: string, contents: any) {
    this.store[space][key] = contents
    return this
  }

  getFromStore (space: string, key: string) {
    return Object.assign({}, this.store[space][key])
  }

  getStore (space: string) {
    return Object.assign({}, this.store[space])
  }

  removeFromStore (space: string, key: string) {
    delete this.store[space][key]
    return this
  }

  clearStore (space: string) {
    this.store[space] = {}
    return this
  }
}
