import './headMenu.scss'
import HeadMenuController from './controller/HeadMenuController.js'
const template = require('./headMenu.tpl.pug')

const headMenu = {
  template: template(),
  controller: HeadMenuController,
  bindings: {
    selectedRealm: '<',
    manageGroup: '<'
  }
}

export default headMenu
