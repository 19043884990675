import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { MuiThemeProvider } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import GroupOutlined from '@material-ui/icons/GroupOutlined'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Link from '@material-ui/core/Link';

import { theme } from 'theme'
import PageHeader from 'common-components/page-header/PageHeader'
import TableLoader from 'common-components/loader/table-loader/TableLoader'
import ActionButton from 'common-components/action-button/ActionButton'
import AsyncTable from 'common-components/async-table/AsyncTable'
import AddStationForm from './AddStation'
import TableSearch from 'common-components/table-search-prompt/TableSearch'
import CopyElement from 'common-components/copy-element/CopyElement'
import Paginator from 'components/Paginator'
import EditStation from './EditStation'
import AppSnackbar from 'components/AppSnackbar'

import {
  ActiveCell,
  UnderlineCell,
} from 'common-components/table-cells/tableCells'

import './ManageStations.scss'

const API_BASE_URL = getBaseUrl(window.location.host, true)

const Loader = (
  <TableLoader
    headerText="Management"
    message="One moment, we are loading your content..."
    Icon={GroupOutlined}
  />
)

const StationTableRow = ({
  station,
  rowClass,
  cellClass,
  openEdit
}) => (
    <TableRow className={rowClass}>
      <UnderlineCell className={cellClass} style={{ color: '#777777' }} >
        <Link onClick={() => { openEdit(station)}} style={{ cursor: 'pointer'}}>{station.uuid} </Link>
        <CopyElement title="Copy UUID" value={station.uuid} response="UUID copied to clipboard" />
      </UnderlineCell>
      <TableCell className={cellClass} style={{ color: '#777777'}}>{station.username}
      </TableCell>
      <ActiveCell className={cellClass}>{station.active == true ? 'Active' : 'Inactive'}</ActiveCell>
      <TableCell className={cellClass} style={{
        color: '#444851', textAlign: 'right',
        textDecoration: 'underline'
      }}>
        <IconButton
          disableRipple={true}
          className="btn"
          onClick={() => { openEdit(station)} }>
          <EditIcon
            style={{
            fontSize: '14px',
            marginRight: 8
          }}/>
          Edit
        </IconButton>
      </TableCell>
    </TableRow>
  )


export class ManageStations extends React.Component {
  state = {
    state: 'DATA_LOADING',
    createDialogOpen: false,
    menuItems: [],
    stations: [],
    searchField: '',
    editModal: false,
    stationSelected: [],
    openSnack: false,
    snackText: '',
  }

  loadData = () => {
    axios.
      get(`${API_BASE_URL}/identity/station/list/`)
      .then(stations => {
        this.setState({
          state: 'DATA_LOADED',
          stations: stations.data,
          searchField: '',
        })
      })
      .catch( error => {
        this.setState({openSnack: true, snackText: "There's an error loading the station list."});
        this.setState({ state: 'DATA_LOADED'})
        console.log(error)
      })
  }

  handleNavClick = (state) => this.props.$state.go(state)

  openCreateDialog = () => this.setState({ createDialogOpen: true })

  cancelCreateDialog = () => this.setState({ createDialogOpen: false, })

  openEditModal = (station) => { this.setState({ stationSelected: station, editModal: true })}

  closeEdit  = () => this.setState({editModal: false })

  closeSnackbar = () => this.setState({openSnack: false})

  editStation = () => {
    this.loadData();
      
    this.setState({openSnack: true, snackText: "Changes have been saved"});
    this.setState({editModal: false });
  }

  deleteStation = () => {
    this.setState({editModal: false });

    this.loadData();
  }

  updateStations = (newStation) => {
    this.loadData()
  }

  getMenuItems = () => {
    return this.props.SideNavService.createMenuForRole(
      this.props.ManageMenuService.getMenu(),
      this.props.RoleStore.getAllRoles(),
    )
      .filter(menuSection => menuSection.name === 'Management')
      .pop()
      .pages.filter(page => page.state !== this.props.$state.current.name)
  }

  searchData = (value) => {
    this.setState({ searchField: value }, function () {
      axios
      .get(
        `${API_BASE_URL}/identity/station/list/?search=${value}`
      )
      .then(res => {
        this.setState({
          state: 'DATA_LOADED',
          stations: res.data,
        })
      })
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme} >
        <PageHeader
          padding="30px 0 36px"
          title="Stations"
          navDropdown={true}
          handleNavClick={this.handleNavClick}
          menuItems={this.getMenuItems()}
          render={() => (
            <ActionButton
              buttonColor="secondary"
              handleClick={this.openCreateDialog}
            >
              <Add style={{ fontSize: 20, marginRight: 5 }} />
              Add station
            </ActionButton>
          )}
        />
        <Paper style={{ width: '100%', overflowX: 'auto' }}>
          <TableSearch
            placeholder="Search Stations"
            handleChange={e => this.searchData(e.target.value)}  />
          <AsyncTable
            loading={this.state.state === 'DATA_LOADING'}
            Loader={Loader}
            tableData={this.state.stations.results}
            headers={['UUID', 'Username', 'Status', '']}
            render={(stations, { row, cell, icon }) =>
              stations.map(station => (
                <StationTableRow
                  openEdit={this.openEditModal}
                  key={station.uuid}
                  station={station}
                  rowClass={row}
                  cellClass={cell}
                  iconClass={icon}
                />
              ))
            }
          />
        </Paper>

        <Paginator
          colection={this.state.stations}
          setColection={data => this.setState({ stations: data })}
          urlPage={`/identity/station/list/?page=PAGE`}
        />

        <AddStationForm 
          modalWith={651}
          showModal={this.state.createDialogOpen}
          station={this.state.stationSelected}
          handleCancel={this.cancelCreateDialog}
          handleCreate={this.updateStations}>
        </AddStationForm>

        <AppSnackbar
            vertical="top"
            horizontal="right"
            open={this.state.openSnack}
            close={this.closeSnackbar}
            text={this.state.snackText}
        />

        <EditStation
          modalWith={651}
          showModal={this.state.editModal}
          station={this.state.stationSelected}
          hideModal={this.closeEdit}
          handleEdit={this.editStation}
          openAddDialog={this.openCreateDialog}
          handleDelete={this.deleteStation}>
        </EditStation>
      </MuiThemeProvider>
    )
  }
}
