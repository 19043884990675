// @flow

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import ErrorIcon from '@material-ui/icons/Error'
import ReplayIcon from '@material-ui/icons/Replay'
import { CSVLink } from 'react-csv'

import ActionButton from 'common-components/action-button/ActionButton'

type Props = {
  message: string,
  classes: Object,
}

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  container: {
    padding: ' 30px',
    fontColor: theme.palette.text.primary,
    '& p': {
      color: '#7c8a97',
      margin: '0 0 15px 0',
      textAlign: 'center',
    },
  },
  message: {
    fontWeight: 500,
  },
  check: {
    color: '#b6d36b',
    fontSize: 30,
  },
  close: {
    padding: '0!important',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  failed: {
    color: '#ff504d',
    fontSize: 30,
  },
  retry: {
    '&:hover': {
      color: '#1d1d1d',
    },
    textDecoration: 'underline',
    fontSize: '16px',
  },
})

function ConfirmationDialog({
  classes,
  message,
  handleCancel,
  handleCreateAnother,
  SubmitTxt,
  failed,
  handleSubmit,
  removeClose
}: Props) {
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        alignItems="stretch"
        className={classes.container}
        container
        direction="column"
        justify="center"
      >
        <Grid item style={{ textAlign: 'right' }}>
          {
            removeClose ?
              null
            :
            <IconButton
                disableRipple={true}
                className={classes.close}
                aria-label="close"
                onClick={handleCancel}
              >
              <CloseIcon />
            </IconButton>
          }
        </Grid>
        <Grid item style={{ marginBottom: '10px', textAlign: 'center' }}>
          <CheckCircleIcon className={classes.check} />
        </Grid>
        <Grid item>
          <p className={classes.message}>{message}</p>
        </Grid>
        {failed ? (
          failed.length != 0 ? (
            <React.Fragment>
              <Grid item style={{ marginBottom: '10px', textAlign: 'center' }}>
                <ErrorIcon className={classes.failed} />
              </Grid>
              <Grid item>
                <p className={classes.message}>
                  {failed.length} Stations failed to create:{' '}
                </p>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {failed.map(f => f.username).join('\n')}
                </p>
              </Grid>
              <Grid item>
                {/* <p
                  onClick={() => {
                    handleSubmit()
                  }}
                  className={classes.retry}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ReplayIcon style={{ fontSize: '16px' }} /> Retry failed
                  request.
                </p> */}
                <p
                  className={classes.retry}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                  }}
                >
                  <CSVLink
                    data={failed}
                    filename={'failed.csv'}
                    target="_blank"
                  >
                    Download failed request.
                  </CSVLink>
                </p>
              </Grid>
            </React.Fragment>
          ) : null
        ) : null}

        <Grid item>
          <p>What do you want to do next?</p>
        </Grid>
        <Grid item style={{ marginTop: '15px', width: '100%' }}>
          <div style={{ display: 'inline-block' }}>
            <ActionButton handleClick={handleCancel} buttonColor="default">
              CLOSE
            </ActionButton>
          </div>

          {SubmitTxt && (
            <div style={{ display: 'inline-block', float: 'right' }}>
              <ActionButton
                handleClick={handleCreateAnother}
                buttonColor="primary"
              >
                {SubmitTxt}
              </ActionButton>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ConfirmationDialog)
