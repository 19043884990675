// management states
import { manage, manageConfig } from './manage.js'
import manageAccessListsRoutes from './child-states/manage-access-lists/manage.accessLists.routes'
import manageEditAccessListsRoutes from './child-states/edit-access-list/manage.editAccessLists.routes'
import manageCompaniesRoutes from './child-states/manage-companies/manage.companies.routes'
import manageEditCompaniesRoutes from './child-states/edit-company/manage.editCompany.routes'
import manageEditCompanyPhraseBookRoutes from './child-states/edit-company-phrase-book/manage.editCompanyPhraseBook.routes'
import manageCampaignRoutes from './child-states/manage-campaigns/manage.campaigns.routes'
import manageEditQaProspectSettings from './child-states/edit-campaign-qa-prospect/manage.editCampaign.qaProspect.routes'
import manageEditCampaignRoutes from './child-states/edit-campaign/manage.editCampaign.routes'
import manageCampaignRouterRoutes from './child-states/manage-campaign-router/manage.campaignRouter.routes'
import manageEditVoicesRoutes from './child-states/edit-voices/manage.editVoices.routes'
import manageEditLocationRoutes from './child-states/edit-location/manage.editLocation.routes'
import manageLocationRoutes from './child-states/manage-locations/manage.manageLocations.routes'
import manageUsersRoutes from './child-states/manage-users/manage.users.routes'
import editTeamRoutes from './child-states/edit-team/manage.editTeam.routes'
import manageTeamsRoutes from './child-states/manage-teams/manage.team.routes'
import manageAudioResourcesRoutes from './child-states/manage-audio-resources/manage.audioResources.routes'
import manageAudioVoiceRoutes from './child-states/manage-audio-voice/manage.audioVoice.routes'
import manageEditPhraseBookRoutes from './child-states/edit-phrase-book/manage.editPhraseBook.routes'
import editSegmentVariablesRoutes from './child-states/edit-segment-variables/manage.editSegmentVariables.routes'
import manageEditGlobalOptionGroupsRoutes from './child-states/edit-global-option-groups/manage.editGlobalOptionGroups.routes'
import manageGlobalOptionGroupsRoutes from './child-states/manage-global-option-groups/manage.globalOptionGroups.routes'
import manageGlobalRapidResponseRoutes from './child-states/manage-global-rapid-response/manage.globalRapidResponse.routes'
import manageRealmsRoutes from './child-states/manage-realms/manage.realms.routes'
import manageStationsRoutes from './child-states/manage-stations/manage.stations.routes'
import manageEditRealmsRoutes from './child-states/edit-realms/manage.editRealms.routes'
import manageDncRoutes from './child-states/manage-dnc/manage.dnc.routes'
import manageGlobalPitchSegmentsRoutes from './child-states/manage-global-pitch-segments/manage.globalPitchSements.routes'
import manageGlobalPitchPhraseBooksRoutes from './child-states/manage-global-pitch-phrasebooks/manage.globalPitchPhraseBooks.routes'
import manageEditRapidResponseTestsRoutes from './child-states/edit-rapid-response-tests/manage.editRapidResponseTests.routes'
import manageDidsRoutes from './child-states/manage-dids/manage.dids.routes'
import manageEditPoolRoutes from './child-states/edit-pool/manage.editPool.routes'

export default function manageRoutes($stateProvider) {
  'ngInject'
  $stateProvider.state(manage, manageConfig)
  // Manage Access Lists States
  manageAccessListsRoutes($stateProvider)
  manageEditAccessListsRoutes($stateProvider)
  // Manage Audio
  manageAudioResourcesRoutes($stateProvider)
  manageAudioVoiceRoutes($stateProvider)
  // Manage Campaigns
  manageCampaignRoutes($stateProvider)
  manageEditCampaignRoutes($stateProvider)
  manageEditQaProspectSettings($stateProvider)
  // Manage Campaign Router
  manageCampaignRouterRoutes($stateProvider)
  // Manage Companies
  manageCompaniesRoutes($stateProvider)
  manageEditCompaniesRoutes($stateProvider)
  manageEditCompanyPhraseBookRoutes($stateProvider)
  // Manage Edit Phrase Books
  manageEditPhraseBookRoutes($stateProvider)
  // Manage Edit Segment Variables
  editSegmentVariablesRoutes($stateProvider)
  // Manage Edit Team
  editTeamRoutes($stateProvider)
  // Manage Edit Voices
  manageEditVoicesRoutes($stateProvider)
  // Manage Global Option Groups
  manageEditGlobalOptionGroupsRoutes($stateProvider)
  manageGlobalOptionGroupsRoutes($stateProvider)
  // Manage Edit Rapid Response Tests
  manageEditRapidResponseTestsRoutes($stateProvider)
  // Manage Global Pitch Phrase Books
  manageGlobalPitchPhraseBooksRoutes($stateProvider)
  // Manage Global Pitch Segments
  manageGlobalPitchSegmentsRoutes($stateProvider)
  // Manage Locations
  manageEditLocationRoutes($stateProvider)
  manageLocationRoutes($stateProvider)
  // Manage Global Rapid Response
  manageGlobalRapidResponseRoutes($stateProvider)
  // Edit Realms
  manageEditRealmsRoutes($stateProvider)
  // Manage Realms
  manageRealmsRoutes($stateProvider)
  // Manage Stations
  manageStationsRoutes($stateProvider)
  // Manage Dnc
  manageDncRoutes($stateProvider)
  // Manage Teams
  manageTeamsRoutes($stateProvider)
  // Manage Users
  manageUsersRoutes($stateProvider)
  // Manage Did Pool
  manageDidsRoutes($stateProvider)
  // Manage Edit Did Pool
  manageEditPoolRoutes($stateProvider)
}
