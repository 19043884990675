import './dashboard.overview.scss'
import angular from 'angular'

// services
import CampaignHealthService from './sub-components/campaign-health/services/CampaignHealthService.js'
import MetricHealthService from './sub-components/metric-health/services/MetricHealthService.js'

// components
import overview from './component/dashboard.overview.js'
import campaignHealth from './sub-components/campaign-health/campaignHealth.js'
import metricHealth from './sub-components/metric-health/metricHealth.js'
import prospectHealth from './sub-components/prospect-health/prospectHealth.js'
import performanceHealth from './sub-components/performance-health/performanceHealth.js'
import repsLive from './sub-components/reps-live/repsLive.js'

export default angular
  .module('portalApp.dashboard.overview', [])
  .service('CampaignHealthService', CampaignHealthService)
  .service('MetricHealthService', MetricHealthService)
  .component('overview', overview)
  .component('campaignHealth', campaignHealth)
  .component('metricHealth', metricHealth)
  .component('prospectHealth', prospectHealth)
  .component('performanceHealth', performanceHealth)
  .component('repsLive', repsLive)
  .name
