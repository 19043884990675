export default function slideToggle ($animateCss) {
  'ngInject'
  return {
    addClass: function (element, className, doneFn) {
      if (className === 'ng-hide') {
        let animator = $animateCss(element, {
          from: { height: `${element[0].offsetHeight}px` },
          to: { height: '0px' }
        })
        if (animator) {
          return animator.start().finally(function () {
            element[0].style.height = ''
            doneFn()
          })
        }
      }
      doneFn()
    },
    removeClass: function (element, className, doneFn) {
      if (className === 'ng-hide') {
        let height = element[0].offsetHeight
        let optionalHeight = parseInt(element[0].dataset.slideToggleAdd) || 0
        let animator = $animateCss(element, {
          from: { height: '0px' },
          to: { height: `${height + optionalHeight}px`, opacity: 1 }
        })
        if (animator) {
          return animator.start().finally(doneFn)
        }
      }
      doneFn()
    }
  }
}
