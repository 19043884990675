const listOverviewMetricsConfig = [
  'sales',
  'calls',
  'conversionRate',
  'contactedConversionRate',
  'contactRate',
  'talkTime',
  'contacted',
  'invalidCalls',
  'invalidCallRate',
  'badNumbers',
  'abandonmentRate',
  'answerSeizureRatio',
  'speedToLead',
]

export { listOverviewMetricsConfig }
