import metricInfoDialog from '../../../../../dialogs/metric-info/metricInfoDialog.js'

export default class ListDetailsController {
  constructor($mdDialog, ListOverviewService) {
    'ngInject'
    this.$mdDialog = $mdDialog
    this.LOS = ListOverviewService
  }

  $onInit() {
    this.state = {
      showListDetails: !!this.details,
    }
  }

  $onChanges(bindings) {
    this.updateBindings(bindings)
    this.state.showListDetails = !!this.details
  }

  updateBindings(bindings) {
    const bndKeys = Object.keys(bindings)
    bndKeys.forEach(bnd => {
      this[bnd] = bindings[bnd].currentValue
    })
  }

  toggleListDetails() {
    this.state.showListDetails = !this.state.showListDetails
  }

  openInfo(event, title, key) {
    const MID = metricInfoDialog
    MID.targetEvent = event
    MID.locals = {
      title: title,
      key: key,
    }
    this.$mdDialog.show(MID)
  }
}
