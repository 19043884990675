import angular from 'angular'
import { react2angular } from 'react2angular'

// Services
import ManageLocationsService from './services/ManageLocationsService.js'

// components
import manageLocations from './component/manageLocations.component.js'
import createLocation from './sub-components/create-location/component/createLocation.component.js'
import CreateLocationReact from './component/CreateLocationReact.js'
const dependencies = [
  '$state',
  'IdentityService',
  'SideNavService',
  'ManageMenuService',
  'RoleStore',
  'ManageLocationsService'
]
export default angular
  .module('portalApp.manage.locations', [])
  .service('ManageLocationsService', ManageLocationsService)
  .component('manageLocations', manageLocations)
  // .component('createLocation', createLocation)
  .component('createLocation', react2angular(CreateLocationReact, [], dependencies))
  .name
