// @flow
import { getBaseUrl } from '../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';

import CopyElement from 'common-components/copy-element/CopyElement'
import ActionButton from 'common-components/action-button/ActionButton'
import AssociatedCampaigns from 'manage/edit-realms/sub-component/AssociatedCampaigns'

import './SettingsTabs.scss'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    classes: Object,
    active: Boolean,
}

const styles = {
    root: {
        margin: '20px 0',
    },
    header: {
        color: '#444851',
        fontSize: '14px',
        backgroundColor: '#fff',
    },

    input: {
        color: '#444851',
        fontSize: '18px!important',
        '&:hover:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)!important',
        },
        '&:after ': {
            borderBottom: '2px solid #1194f6!important',
        },
    },
    label: {
        color: '#bbbbbb',
        fontSize: '16px!important',
        margin: '20px 0 0 0',
        fontWeight: '500'
    },
    text: {
        fontSize: '16px!important',
        fontWeight: 'normal!important',
        color: '#4a4a4a',
    },
    switch: {
        backgroundColor: '#1194f6'
    },
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function SettingsTabs(props: Props) {
    const [value, setValue] = useState(0);
    const [checkedStatus, setCheckedStatus] = useState(false);
    const [name, setName] = useState(props.name);
    const [status, setStatus] = useState(props.activeTxt);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setName(props.name);
        setCheckedStatus(props.active);
        setStatus(props.activeTxt);
    }, [props]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setActive = event => {
        setCheckedStatus(event.target.checked);
        setStatus(event.target.checked ? 'Active' : 'Inactive');
        setDisabledBtn(false);
    };

    const saveChanges = () => {
        axios.
            patch(`${API_BASE_URL}/identity/realm/${props.uuid}/`,
                {
                    active: checkedStatus
                })
            .then(res => {
                setOpen(true);
                setDisabledBtn(true);
            })
            .catch(err => console.log(err));
    }

    const handleClose = () => setOpen(false);

    return (
        <div className={props.classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} className={props.classes.header} >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Associated Campaigns" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <form style={{ width: '60%', margin: '0 auto', }} noValidate autoComplete="off">
                    <InputLabel className={props.classes.label}
                        htmlFor="adornment-name">Realm name*</InputLabel>
                    <Input
                        className={props.classes.input}
                        disabled
                        type='text'
                        id="standard-full-width"
                        label="Realm name"
                        value={name}
                        fullWidth
                    />
                    <InputLabel className={props.classes.label} htmlFor="adornment-status"
                    >Realm status</InputLabel>
                    <Input
                        className={props.classes.input}
                        id="standard-full-width"
                        label="Realm status"
                        placeholder="Realm status"
                        value={status}
                        fullWidth
                        endAdornment={<InputAdornment position="end">
                            <Switch
                                className='switch'
                                checked={checkedStatus}
                                onChange={e => setActive(e)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </InputAdornment>}
                    />
                    <InputLabel className={props.classes.label} htmlFor="adornment-uuid"
                    >UUID</InputLabel>
                    <Input
                        className={props.classes.input}
                        disabled
                        id="standard-full-width"
                        label="UUID"
                        placeholder="UUID"
                        value={props.uuid}
                        fullWidth
                        endAdornment={<InputAdornment position="end">
                            <CopyElement title="Copy UUID" value={props.uuid} response="UUID copied to clipboard" />
                        </InputAdornment>}
                    />
                    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Changes have been saved</span>}
                    />
                    <div style={{ textAlign: 'center', marginTop: '25px' }}>
                        <ActionButton
                            className={props.classes.button}
                            disabled={disabledBtn}
                            buttonColor="secondary"
                            handleClick={saveChanges}
                        >SAVE CHANGES</ActionButton>
                    </div>
                </form>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AssociatedCampaigns uuid={props.uuid} nameRealm={name} />
            </TabPanel>
        </div>
    )
}

SettingsTabs.defaultProps = {
    title: 'Settings',
}

export default withStyles(styles)(SettingsTabs)
