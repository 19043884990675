import { pipe } from 'ramda'
import { calcMetricsByHour } from './helpers/metricsByHour.js'
import { calcMetricsByDay } from './helpers/metricsByDay.js'
import { calcMetricsByRange } from './helpers/metricsByRange.js'
import { finalizeRangeValue } from './helpers/finalize.js'

const calculateMetricsByRep = pipe(
  calcMetricsByHour,
  calcMetricsByDay,
  calcMetricsByRange,
  finalizeRangeValue,
)

export { calculateMetricsByRep }
