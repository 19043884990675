import moment from 'moment'
import { merge } from 'ramda'

export default class DateRangePickerController {
  constructor($interval) {
    'ngInject'
    this.$interval = $interval
  }

  $onInit() {
    this.timePeriods = [
      { name: 'Today', method: 'getToday' },
      { name: 'Yesterday', method: 'getYesterday' },
      { name: 'This Week', method: 'getThisWeek' },
      { name: 'Last Week', method: 'getLastWeek' },
      { name: 'Last 30 Days', method: 'getLast30Days' },
      { name: 'Last 90 Days', method: 'getLast90Days' },
      { name: 'Custom', method: 'showDatePicker' },
    ]
    this.intervals = [
      { name: 'Refresh Off', value: null },
      { name: 'Refresh 5 min', value: 300000 },
      { name: 'Refresh: 10 min', value: 600000 },
      { name: 'Refresh: 30 min', value: 1800000 },
      { name: 'Refresh: 60 min', value: 3600000 },
    ]

    this.normalizedOffset = this.dateRange.normalizedOffset
    this.intervalPromise = null
    this.today = this.dateTime.startOf('day').toDate()
    this.maxDate = this.dateTime
      .endOf('day')
      .add(this.normalizedOffset, 'minutes')
      .toDate()
    this.minDate = this.dateTime
      .subtract(90, 'days')
      .startOf('day')
      .toDate()

    this.state = {
      showDatePicker: false,
      dateRange: this.dateRange,
      selected: {
        interval: this.intervals[2],
        startDate: this.today,
        endDate: this.dateRange.endDate,
        timePeriod: this.timePeriods[0],
      },
    }
    this.changeInterval(this.intervals[2])
  }

  $onDestroy() {
    this.$interval.cancel(this.intervalPromise)
  }

  changeTimePeriod(method) {
    this[method]()
  }

  updateDateRange(startDate, endDate) {
    // startDate and endDate are moment objects
    this.state.selected.startDate = startDate.toDate()
    this.state.selected.endDate = endDate.toDate()
    const start = startDate.toDate()
    const end = endDate.toDate()
    this.dateRange = merge(this.dateRange, {
      startDate: start,
      endDate: end,
      requestTime: moment().startOf('minute'),
    })
  }

  customUpdateRange(startDate, endDate) {
    const start = moment(startDate)
      .startOf('day')
      .add(this.normalizedOffset, 'minutes')
    const end = moment(endDate).endOf('day')
    this.updateDateRange(start, end)
  }

  changeInterval(interval) {
    if (!interval.value) {
      return this.$interval.cancel(this.intervalPromise)
    }
    this.intervalPromise = this.$interval(() => {
      this.dateRange = merge(this.dateRange, {
        startDate: this.state.selected.startDate,
        endDate: this.state.selected.endDate,
        requestTime: moment().startOf('minute'),
      })
    }, interval.value)
  }

  getToday() {
    this.state.showDatePicker = false
    const today = moment(this.today)
    const todayEndOf = moment(this.today).add({
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
    this.updateDateRange(today, todayEndOf)
  }

  getYesterday() {
    this.state.showDatePicker = false
    const yesterday = moment(this.today).subtract(24, 'hours')
    const yesterdayEndOf = moment(yesterday).add({
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
    this.updateDateRange(yesterday, yesterdayEndOf)
  }

  getThisWeek() {
    this.state.showDatePicker = false
    const startDate = moment(this.today).subtract(6, 'days')
    const endDate = moment(this.today).add({
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
    this.updateDateRange(startDate, endDate)
  }

  getLastWeek() {
    const startDate = moment(this.today).subtract(14, 'days')
    const endDate = moment(this.today)
      .add({ hours: 23, minutes: 59, seconds: 59 })
      .subtract(8, 'days')
    this.state.showDatePicker = false
    this.updateDateRange(startDate, endDate)
  }

  getLast30Days() {
    this.state.showDatePicker = false
    const startDate = moment(this.today).subtract(29, 'days')
    const endDate = moment(this.today).add({
      hours: 23,
      minutes: 59,
      seconds: 59,
    })
    this.updateDateRange(startDate, endDate)
  }

  getLast90Days() {
    this.state.showDatePicker = false
    const startDate = moment(this.minDate)
    const endDate = moment(this.today)
      .subtract(1, 'day')
      .add({ hours: 23, minutes: 59, seconds: 59 })
    this.updateDateRange(startDate, endDate)
  }

  showDatePicker() {
    this.state.showDatePicker = true
  }

  verifyStartDate(endDate) {
    const invalidStart = moment(endDate).isBefore(this.state.selected.startDate)
    if (invalidStart) {
      this.state.selected.startDate = endDate
    }
  }

  verifyEndDate(startDate) {
    const invalidEnd = moment(startDate).isAfter(this.state.selected.endDate)
    if (invalidEnd) {
      this.state.selected.endDate = startDate
    }
  }
}
