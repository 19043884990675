export default class CreatePanelController {
  constructor($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.state = {
      creating: false,
      error: false,
      formOptions: [
        {
          accessor: 'name',
          inputType: 'input',
          label: 'Panel name',
          type: 'text',
          attributes: ['required'],
        },
        {
          accessor: 'description',
          inputType: 'input',
          label: 'Panel description',
          type: 'text',
          attributes: [],
        },
      ],
    }
  }

  createPanel(panel) {
    this.state.creating = true
    this.service
      .customPOST(panel)
      .then(createdPanel => createdPanel.plain())
      .then(this.close.bind(this))
      .catch(err => {
        this.state.error = true
        console.error(err)
      })
  }

  resetError() {
    this.state.error = false
  }

  close(panel) {
    panel && panel.uuid ? this.$mdDialog.hide(panel) : this.$mdDialog.cancel()
  }
}
