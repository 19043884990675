import { curry, find, propEq } from 'ramda'
import {
  isRecorderOnly,
  filterCampaignsForVoice,
} from '../../common/util/userHelpers.js'

/* When extending this controller the following must be implemented in the extension class:
 * - this.recordingDialog -> An angular material dialog config
 * - this.viewingDialog -> An angular material dialog config
 * - this.selectCampaignCb -> func -> callback when campaign is selected
 * - this.clearSelections -> func -> function that resets this.state.selected properties
 */

export default class BaseAudioController {
  constructor(
    $mdDialog,
    $sessionStorage,
    IdentityService,
    ManagementService,
    RoleStore,
    PitchService,
    ManageAudioService,
    ToastService,
    $rootScope,
    $scope,
  ) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.User = IdentityService.User
    this.Groups = IdentityService.Groups
    this.RS = RoleStore
    this.MS = ManagementService
    this.PS = PitchService
    this.MAS = ManageAudioService
    this.TS = ToastService
    this.$rootScope = $rootScope
    this.openAudioDialog = curry(this._openAudioDialog)
    this.voiceAudioService = null

    // Handlers for opening dialogs
    this.unrecordedOpenDialog = this.openAudioDialog('unrecorded')
    this.rerecordOpenDialog = this.openAudioDialog('rerecord')
    this.recordedOpenDialog = this.openAudioDialog('recorded')

    this.user = $sessionStorage.user
    this.unrecorded = []
    this.rerecord = []
    this.recorded = []
    this.recorderGroup = null

    // UI related state
    this.state = {
      loading: true,
      loadingAudio: false,
      user: {
        isRecorderOnly: false,
      },
      selectOptions: {
        campaigns: [],
      },
      selected: {
        // state of inputs
        voice: null,
        campaign: null,
      },
    }
  }

  $onInit() {
    this.$rootScope.refreshOptions.refreshFunction = this.fetchAudio.bind(this)
    if (isRecorderOnly(this.RS.getAllRoles())) {
      this.getCampaignOptions()
      this.state.user.isRecorderOnly = true
    } else {
      this.getRecorderPermission()
    }

    // when voice coming as parameter from the component redirection
    if (this.voice) {
      this.state.selected.voice = this.voice
      this.selectVoice(this.voice)
    }
  }

  getRecorderPermission() {
    // get recorder permission so we can use it to filter voice user queries
    return this.Groups.list
      .get({ assignable: false })
      .then(find(propEq('name', 'RECORDER')))
      .then(recorderGroup => {
        this.recorderGroup = recorderGroup
      })
      .finally(_ => {
        this.state.loading = false
      })
  }

  getCampaignOptions(voice = null) {
    this.MS.getRelationOptions(['campaign'], { editable: false })
      .then(({ campaign }) => campaign)
      .then(filterCampaignsForVoice(voice))
      .then(campaigns => {
        if (!campaigns.length) {
          this.showAlert({
            title: 'No Campaigns Available',
            text: `This voice hasn't been assigned to any campaigns, so no recordings are available. Please contact a Perfect Pitch Administrator to request access`,
          })
          return
        }
        this.state.selectOptions.campaigns = campaigns
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching campaign options',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  searchVoices(searchText) {
    return this.User.manage.list
      .get({
        search: searchText,
        groups: this.recorderGroup.pk,
        limit: 10,
      })
      .then(users => users.plain().results)
  }

  selectVoice(voice) {
    if (voice) {
      // get more detailed user so we know which campaigns are available
      this.User.manage.fetch
        .one(voice.uuid)
        .get()
        .then(user => user.plain())
        .then(user => {
          this.getCampaignOptions(user)
        })
    } else {
      this._clearAudio()
      this.clearSelections()
    }
  }

  showAlert({ title, text }) {
    const alert = this.$mdDialog
      .alert()
      .clickOutsideToClose(true)
      .title(title)
      .textContent(text)
      .ariaLabel('No campaigns found dialog')
      .ok('OK')

    this.$mdDialog.show(alert).then(_ => {
      this.state.selected.campaign = null
    })
  }

  setAudio({ recorded, rerecord, unrecorded }) {
    this.unrecorded = unrecorded
    this.rerecord = rerecord
    this.recorded = recorded
  }

  moveToRecord({ ev, item, itemIndex }) {
    ev.cancelBubble = true
    const [toMove] = this.rerecord.splice(itemIndex, 1)
    this.recorded.push(toMove)
    this.updateVoiceAudio(toMove, { rerecord: false })
  }

  moveToRerecord({ ev, item, itemIndex }) {
    ev.cancelBubble = true
    const [toMove] = this.recorded.splice(itemIndex, 1)
    this.rerecord.push(toMove)
    this.updateVoiceAudio(toMove, { rerecord: true })
  }

  updateVoiceAudio(audio, patchObj) {
    const path =
      this.audioType === 'pitch' || this.audioType === 'prospect'
        ? `${audio.key}`
        : `phrase/${audio.uuid}/file`
    return this.voiceAudioService.base
      .one(path)
      .patch(patchObj)
      .catch(err => {
        this.TS.show({
          text: `There was a problem updating ${audio.key}`,
        })
        console.error(err)
      })
  }

  _clearAudio() {
    Object.assign(this, {
      unrecorded: [],
      rerecord: [],
      recorded: [],
    })
  }

  _setupRecordingDialogService(audioType, listKey) {
    if (audioType === 'pitch') {
      return this.PS.setupDialogVoice({
        pitchVersionId: this.state.selected.pitchVersion,
        voiceId: this.user.uuid,
        toPick: [listKey, 'base'],
      })
    } else if (audioType === 'phrase') {
      return this.PS.setupPhraseBookVoice({
        voiceId: this.user.uuid,
        phraseBookId: this.state.selected.phraseBook.uuid,
        companySlug:
          this.state.selected.campaign === 'global'
            ? null
            : this.state.selected.campaign.company.slug,
        toPick: [listKey, 'base'],
      })
    } else if (audioType === 'prospect') {
      return this.PS.setupProspectVoice({
        pitchVersionId: this.state.selected.pitchVersion,
        voiceId: this.user.uuid,
        toPick: [listKey, 'base'],
      })
    }
  }

  _openAudioDialog(listKey, itemIndex) {
    if (!this.state.user.isRecorderOnly) {
      this.viewingDialog.locals = {
        selectedAudio: this[listKey][itemIndex],
        audioService: this.voiceAudioService.base,
        audioType: this.audioType,
      }
      this.$mdDialog
        .show(this.viewingDialog)
        .then(this.fetchAudio.bind(this))
        .catch(this.fetchAudio.bind(this))
      return
    }

    const locals = {
      selectedAudio: this[listKey][itemIndex],
      selectedIndex: itemIndex,
      audioList: this[listKey],
      audioListKey: listKey,
      audioType: this.audioType,
      voiceService: this._setupRecordingDialogService(this.audioType, listKey),
    }
    this.recordingDialog.locals = locals
    this.$mdDialog
      .show(this.recordingDialog)
      .then(this.fetchAudio.bind(this))
      .catch(this.fetchAudio.bind(this))
  }
}
