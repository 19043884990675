// create campaign router view

const manageEditPhraseBookPhrase = 'manage.editPhraseBook.phrase'

const manageEditPhraseBookPhraseConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageEditPhraseBookPhrase, manageEditPhraseBookPhraseConfig }
