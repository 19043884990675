import { forEach, map, pipe, reduce } from 'ramda'
import { calculateAttemptMetrics, attemptMetricMerger } from './metrics.js'

const buildAttemptMetricsForDay = map(attemptMetricMerger)

function buildDayMetrics(day) {
  const reducedAttempts = reduce(
    (acc, hour) => {
      forEach(attempt => {
        if (!acc[attempt.attemptNumber]) acc[attempt.attemptNumber] = []
        acc[attempt.attemptNumber].push(attempt.metrics)
      }, hour.attempts)
      return acc
    },
    {},
    day.hours,
  )

  return {
    date: day.date,
    hours: day.hours,
    attempts: buildAttemptMetricsForDay(reducedAttempts),
  }
}

function calcDayMetrics(days) {
  return map(buildDayMetrics, days)
}

function finalizeDayMetricsByAttempt(day) {
  day.attempts = map(calculateAttemptMetrics, day.attempts)
  return day
}

const calcDayMetricsByAttempt = pipe(
  calcDayMetrics,
  map(finalizeDayMetricsByAttempt),
)

function calcMetricsByDayByAttempt(days = [], metrics = []) {
  return calcDayMetricsByAttempt(days, metrics)
}

function calcByAttemptByDay({ days = [], metrics = [] }) {
  return {
    days: calcMetricsByDayByAttempt(days, metrics),
    metrics,
  }
}

export { calcByAttemptByDay }
