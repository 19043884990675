import './manageVoices.scss'
import ManageVoicesController from './controller/ManageVoicesController.js'
const template = require('./manageVoices.component.tpl.pug')

const manageVoices = {
  template: template(),
  controller: ManageVoicesController,
  bindings: {
    voices: '<',
    campaign: '<?',
    onUpdate: '&',
    isProspect: '=',
  },
}

export default manageVoices
