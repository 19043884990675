import { reduce, zipObj } from 'ramda'

export default class UserBulkUploadController {
  constructor($mdDialog, IdentityService, ManageUsersCreateService) {
    'ngInject'

    this.$mdDialog = $mdDialog
    this.MUCS = ManageUsersCreateService
    this.IS = IdentityService

    this.state = {
      selectedCompany: null,
      selectedCampaigns: [],
      validationError: {
        show: false,
        msg: '',
        data: [],
      },
    }

    this.filterCampaigns = campaign => {
      if (this.state.selectedCompany) {
        return campaign.company === this.state.selectedCompany
      }
      return true
    }

    this.getCompanies()
    this.getCampaigns()
  }

  uploadMembers(members, team) {
    const membersWithTeam = members.map(member => {
      member.team = team.uuid
      return member
    })
    this.MUCS.bulkUploadUserValidator(membersWithTeam)
      .then(createdUsers => {
        this.$mdDialog.hide(createdUsers)
      })
      .catch(err => {
        this.displayError(err)
      })
  }

  displayError(err) {
    this.state.validationError.msg = err.message
    this.state.validationError.data = err.data.map(user => user.username)
    this.state.validationError.show = true
  }

  hideErrorMessage() {
    this.state.validationError.show = false
    this.state.validationError.data = []
    this.state.validationError.msg = ''
  }

  updateUsers(users, key, userField) {
    users = users.map(user => {
      user[userField] = this.state[key]
      return user
    })
  }

  getCompanies() {
    this.IS.Company.list.get({ editable: true }).then(companies => {
      this.companiesList = companies.plain()
    })
  }

  getCampaigns() {
    this.IS.Campaign.list.get({ editable: true }).then(campaigns => {
      this.campaignsList = campaigns.plain()
    })
  }

  readFile(file) {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.parseFile(reader.result)
      }
      reader.readAsText(file)
    }
  }

  parseFile(csv) {
    const parsed = csv.split('\n')
    const fieldKeys = parsed[0].split(',').map(key => key.trim())
    const users = parsed.slice(1)
    const zipper = (acc, user) => {
      // regex eliminates empty rows from csv file
      const validRow = user && !/^,+$/.test(user.trim())
      if (validRow) {
        const agent = zipObj(fieldKeys, user.split(','))
        agent.company = null
        agent.campaigns = []
        acc.push(agent)
      }
      return acc
    }
    this.userList = reduce(zipper, [], users)
  }

  downloadTemplate() {
    return {
      fileName: 'bulk_upload_template',
      data: 'username,first_name,last_name,email,password\n',
    }
  }

  removeUser(index) {
    this.userList.splice(index, 1)
  }

  close() {
    this.$mdDialog.cancel('Dialog canceled')
  }
}
