import angular from 'angular'

// services
import ManageCampaignsService from './services/ManageCampaignsService.js'

// components
import manageCampaigns from './component/manageCampaigns.component.js'
import createCampaignInfo from './sub-components/manage-campaigns-create/sub-components/campaign-info/component/createCampaignInfo.js'

export default angular
  .module('portalApp.manage.campaigns', [])
  .service('ManageCampaignsService', ManageCampaignsService)
  .component('manageCampaigns', manageCampaigns)
  .component('createCampaignInfo', createCampaignInfo)
  .name

