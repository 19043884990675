import { validateMetricConfig } from './metricFactoryValidator.js'
import { getCalculationMethods } from './getCalculationMethods'

function metricFactory (metaData, calculationConfig) {
  const {
    key,
    format,
    type,
    access,
    componentKeys,
    healthKey
  } = validateMetricConfig(metaData)
  const calculation = getCalculationMethods(metaData, calculationConfig) || {}
  const validatedMetaData = { key, format, type, access, componentKeys, healthKey, calculation }
  return Object.assign(validatedMetaData, calculation)
}

export { metricFactory }
