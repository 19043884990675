import StackChartController from './controller/StackChartController.js'
import './stackChart.scss'
const template = require('./stackChart.tpl.pug')

const stackChart = {
  template: template(),
  controller: StackChartController,
  bindings: {
    title: '<',
    chartConfig: '=',
    dateRange: '<'
  }
}

export default stackChart
