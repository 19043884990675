import { ADMIN_GROUP } from 'permissions.js'
import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// manage realms view

const manageStations = 'manage.stations'
const pageTitleConfig = {
  title: 'Manage Stations'
}

const manageStationsConfig = {
  url: '/stations',
  component: 'manageStations',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig)
  }
}

export { manageStations, manageStationsConfig }
