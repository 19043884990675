import CompanyRapidResponseVariablesController from './controller/CompanyRapidResponseVariables.controller'
const template = require('./companyRapidResponseVariables.component.tpl.pug')

const companyRapidResponseVariables = {
  template: template(),
  controller: CompanyRapidResponseVariablesController,
  bindings: {
    company: '<'
  }
}

export default companyRapidResponseVariables
