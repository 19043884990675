// components
import manageAudioVoice from './component/manageAudioVoice.component.js'
import pitchAudio from './sub-components/pitch-audio/pitchAudio.component.js'
import phraseAudio from './sub-components/phrase-audio/phraseAudio.component.js'
import prospectAudio from './sub-components/prospect-audio/prospectAudio.component.js'

export default angular
  .module('portalApp.manage.audioVoice', [])
  .component('manageAudioVoice', manageAudioVoice)
  .component('pitchAudio', pitchAudio)
  .component('phraseAudio', phraseAudio)
  .component('prospectAudio', prospectAudio).name
