// Manage Rapid Response Tests View

const manageGlobalRapidResponseTests = 'manage.globalRapidResponse.tests'

const manageGlobalRapidResponseTestsConfig = {
  url: '/tests',
  component: 'rapidResponseTests',
}

export { manageGlobalRapidResponseTests, manageGlobalRapidResponseTestsConfig }
