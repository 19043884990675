const dialerColumnsQueueConfig = [
  { title: 'Server', accessor: 'realm', type: 'string', thSize: '40' },
  { title: 'Station', accessor: 'station', type: 'string', thSize: '40' },
  { title: 'Rep', accessor: 'rep', type: 'string', total: 'sum', thSize: '20', collapsed: true },
  { title: 'Wait time', accessor: 'wait_time', type: 'string', thSize: '47' },
  { title: 'Talk time', accessor: 'talk_time', type: 'string', thSize: '93' }
]

const dialerColumnsConnectedConfig = [
  { title: 'Server', accessor: 'realm', type: 'string', thSize: '40' },
  { title: 'Station', accessor: 'station', type: 'string', thSize: '20' },
  { title: 'Rep', accessor: 'rep', type: 'string', thSize: '20', collapsed: true },
  { title: 'Duration', accessor: 'duration', type: 'string', thSize: '20', collapsed: true },
  { title: 'Transferred', accessor: 'transferred', type: 'string', thSize: '20', collapsed: true }
]

export { dialerColumnsQueueConfig, dialerColumnsConnectedConfig }
