// manage access lists create

const manageAccessListsCreate = 'manage.accessLists.one.create'

const manageAccessListsCreateConfig = {
  url: '/create',
  component: 'manageAccessListsCreate',
  params: {
    compSlug: null
  },
  resolve: {
    compSlug: function ($transition$) {
      'ngInject'
      return $transition$.params().compSlug
    }
  }

}

export { manageAccessListsCreate, manageAccessListsCreateConfig }
