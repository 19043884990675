import './campaignSettings.scss'
import CampaignSettingsController from './controller/CampaignSettingsController.js'
const template = require('./campaignSettings.component.tpl.pug')

const campaignSettings = {
  controller: CampaignSettingsController,
  template: template(),
  bindings: {
    campaign: '<',
  },
}

export default campaignSettings
