import { makePageTitle } from '../../../utils/resolve/pageTitle.js'

// Manage EditVoices View

const manageEditVoices = 'manage.manageEditVoices'
const pageTitleConfig = {
  title: 'Manage Pitch Segments'
}

const manageEditVoicesConfig = {
  url: '/edit-voices/{compSlug}/{endpointRoute}/{uuid}',
  params: {
    compSlug: null,
    uuid: null,
    endpointRoute: null
  },
  component: 'editVoices',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageEditVoices, manageEditVoicesConfig }
