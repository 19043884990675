// @flow

import type ToastService from '../../../global/index/services/ToastService.js'
import type PitchService from '../../../global/index/api-services/PitchService.js'
import type { FilterListConfig } from '../../../common-components/filter-list/component/filterList.js'
import type { GuiOptionGroup } from 'types/entities.js'

import OGD from '../../dialogs/options-group/optionsGroupDialog.js'
import DED from '../../dialogs/delete-entity/deleteEntity.js'

export default class GlobalOptionGroupController {
  PS: PitchService
  MS: Object
  TS: ToastService
  GlobalOptionGroupService: Object
  $state: Object
  $mdDialog: Object
  columnsConfig: FilterListConfig
  state: {
    loading: boolean,
    globalOptionGroups: GuiOptionGroup[],
  }

  constructor(
    PitchService: PitchService,
    ManagementService: Object,
    ToastService: ToastService,
    $state: Object,
    $mdDialog: Object,
  ) {
    'ngInject'

    this.$state = $state
    this.$mdDialog = $mdDialog
    this.PS = PitchService
    this.MS = ManagementService
    this.TS = ToastService
    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      {
        title: 'Edit',
        type: 'menu',
        icon: 'editor:mode_edit',
        menu: [
          {
            title: 'Modify',
            icon: 'action:settings',
            actionCb: (optionGroup, index) =>
              this.editOptionGroup(optionGroup, index),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: optionGroup => this.deleteGroup(optionGroup),
          },
        ],
      },
    ]

    this.state = {
      loading: true,
      dialogs: {
        createOptionsGroup: OGD,
      },
      globalOptionGroups: [],
      topBarButtons: [
        {
          title: 'New Option Group',
          icon: 'content:add',
          action: this.createOptionsGroup.bind(this),
          className:
            'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
        },
      ],
    }
  }

  $onInit() {
    this.GlobalOptionGroupService = this.PS.setupBase().global.one(
      'gui/field-option-group',
    )
    this.getGlobalOptionGroup()
  }

  getGlobalOptionGroup() {
    this.GlobalOptionGroupService.get()
      .then(globalOptionGroups => {
        this.state.globalOptionGroups = globalOptionGroups.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching global option groups',
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  createOptionsGroup() {
    OGD.locals = {
      service: this.GlobalOptionGroupService,
    }
    this.$mdDialog
      .show(OGD)
      .then(createdOptionsGroup => {
        this.state.globalOptionGroups.push(createdOptionsGroup)
      })
      .catch(console.error)
  }

  editOptionGroup(entity: GuiOptionGroup, index: number) {
    const params = this.MS.createStateParams(entity, index)
    this.$state.go('manage.editGlobalOptionGroups', params)
  }

  setGroupService(group: GuiOptionGroup) {
    return this.PS.setupBase().global.one(
      `gui/field-option-group/${group.uuid}`,
    )
  }

  deleteGroup(group: GuiOptionGroup) {
    DED.locals = {
      title: 'Delete Field Option Group',
      entityName: group.name,
      identityEndpoint: this.setGroupService(group),
      routeWhenSuccessful: 'manage.globalOptionGroup',
    }
    this.$mdDialog.show(DED)
  }
}
