// signIn view

const signIn = 'signIn'

const signInConfig = {
  url: '/sign-in',
  component: 'signIn',
  resolve: {
    pageTitle: function ($window) {
      'ngInject'
      const pageTitle = 'Portal'
      $window.document.title = pageTitle
      return pageTitle
    },
  }
}

export { signIn, signInConfig }
