import { makePageTitle } from '../../../utils/resolve/pageTitle.js'
// Manage Global Option Group View

const manageGlobalOptionGroups = 'manage.globalOptionGroup'
const pageTitleConfig = {
  title: 'Manage Option Groups'
}

const manageGlobalOptionGroupsConfig = {
  url: '/global-option-group',
  component: 'manageGlobalOptionGroup',
  resolve: {
    pageTitle: makePageTitle(pageTitleConfig),
  }
}

export { manageGlobalOptionGroups, manageGlobalOptionGroupsConfig }
