import CreateDidController from './controller/CreateDidController.js'
import template from './createDid.tpl.pug'

const createTests = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: CreateDidController,
}

export default createTests
