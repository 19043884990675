// @flow

import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { Grid, FormControlLabel, Checkbox, Dialog } from '@material-ui/core'

import CreateLoader from 'common-components/create-loader/CreateLoader'
import AppModal from 'components/AppModal.js'
import AppSelect from 'components/AppSelect.js'
import AppTextField from 'components/AppTextField.js'
import AppButton from 'components/AppButton.js'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'
import AppSnackbar from 'components/AppSnackbar'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Papa from 'papaparse'

const API_BASE_URL = getBaseUrl(window.location.host, true)
const CancelToken = axios.CancelToken
let cancel

const useStyles = makeStyles(theme => ({
  line: {
    width: 100 + '%',
    borderBottom: '1px solid #d5d5d5',
  },
  active: {
    '& span.MuiFormControlLabel-label': {
      color: '#777777',
      fontSize: 16,
    },
  },
}))

const AddStationForm = props => {
  const classes = useStyles()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [realm, setRealm] = useState(null)
  const [active, setActive] = useState(true)
  const [realmsList, setRealmsList] = useState([])
  const [button, setButton] = useState({ disabled: true, type: 'Gray' })
  const [isLoading, setIsLoading] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [snack, setSnack] = useState({ active: false, text: '' })
  const [errMsg, setErrMsg] = useState({})
  const [tab, setTab] = useState(0)
  const [fileName, setFileName] = useState('')
  const [file, setFile] = useState(null)
  const [result, setResult] = useState({})

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const fileUpload = event => {
    const file = event.target.files[0]
    setFileName(file.name)
    setFile(file)
    setButton({ disabled: false, type: 'Green' })
  }

  const handleUser = value => {
    setUserName(value), setErrMsg({ ...errMsg, username: '' })
  }

  const handlePassword = value => {
    setPassword(value), setErrMsg({ ...errMsg, password: '' })
  }

  const handleActive = event => setActive(event.target.checked)

  const handleRealm = value => setRealm(value)

  const fillRealms = () => {
    axios
      .get(`${API_BASE_URL}/identity/realm/list/`)
      .then(realms => {
        setRealmsList(realms.data)
      })
      .catch(err => {
        setSnack({
          active: true,
          text: "There's a problem loading the realm's list.",
        })
        console.log(err)
      })
  }

  const closeSnackbar = () => {
    setSnack({ active: false, text: '' })
  }

  const clearForm = () => {
    setUserName('')
    setPassword('')
    setActive(true)
    setRealm(null)
    setButton({ disabled: true, type: 'Gray' })
  }

  useEffect(() => {
    fillRealms()
  }, [])

  const handleSubmit = () => {
    if (tab === 0) {
      setIsLoading(true)

      let data = {
        username: userName.toLocaleLowerCase().replace(/\s/g, '-'),
        slug: userName.toLocaleLowerCase().replace(/\s/g, '-'),
        password: password,
        realm: realm,
        active: active,
      }

      axios
        .post(`${API_BASE_URL}/identity/station/create/`, data, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c
          }),
        })
        .then(res => {
          props.handleCreate(res.data)
          setConfirmation(true)
        })
        .catch(err => {
          setIsLoading(false)
          if (err.response) {
            if (err.response.data.username) {
              setErrMsg({ ...errMsg, username: err.response.data.username[0] })
            } else if (err.response.data.slug) {
              setErrMsg({ ...errMsg, username: err.response.data.slug[0] })
            } else {
              setSnack({
                active: true,
                text: "There's a problem, the station wasn't added.",
              })
              console.log(err)
            }
          }
        })
    } else {
      setIsLoading(true)
      var data = new FormData()
      data.append('file', file)
      // http://127.0.0.1:8000/stations/upload/
      axios
        .post(`https://phdev.perfectpitchtech.com:9006/stations/upload/`, data, {  headers : {"Authorization": "Token " + localStorage.getItem("ngStorage-ppToken"), "Content-Type": "multipart/form-data"} })
        .then(res => {
          console.log("RES: ", res)
          props.handleCreate(res.data)
          setResult(res.data)
          if (res.data.retry_data.length != 0){
            const file = Papa.unparse(res.data.retry_data);
            var contentType = "text/csv";
            var csvFile = new Blob([file], { type: contentType });
            setFile(csvFile)
          } else{
            setFile({})
          }
          setConfirmation(true)
          setFileName('')
        })
        .catch(err => {
          setIsLoading(false)
          if (err.response) {
            if (err.response.data.username) {
              setErrMsg({ ...errMsg, username: err.response.data.username[0] })
            } else if (err.response.data.slug) {
              setErrMsg({ ...errMsg, username: err.response.data.slug[0] })
            } else {
              setSnack({
                active: true,
                text: "There's a problem, the station wasn't added.",
              })
              console.log(err)
            }
          }
        })
    }
  }

  const handleCancelCreation = () => {
    console.log('cancel creation')
    setIsLoading(false)
    cancel()
  }

  const closeConfirmation = () => {
    props.handleCancel()
    setIsLoading(false)
    setConfirmation(false)
    clearForm()
  }

  const handleCreateAnother = () => {
    setConfirmation(false)
    setIsLoading(false)
    clearForm()
  }

  return isLoading ? (
    <React.Fragment>
      <Dialog
        open={isLoading}
        onClose={closeConfirmation}
        aria-labelledby="loading-campaign-dialog"
      >
        {confirmation ? (
          <ConfirmationDialog
            message={
              tab === 0
                ? 'You have added the"' + userName + '" Station'
                : 'You have added "' + result.processed + '" New Station'
            }
            SubmitTxt="ADD ANOTHER"
            handleCancel={closeConfirmation}
            handleCreateAnother={handleCreateAnother}
            failed={'retry_data' in result ? result.retry_data : []}
            handleSubmit = {handleSubmit}
          />
        ) : (
          <CreateLoader
            message="One moment. We're creating the new Station..."
            handleCancel={handleCancelCreation}
          />
        )}
      </Dialog>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AppModal
        modalWith={420}
        showModal={props.showModal}
        hideModal={closeConfirmation}
        title="Add Station"
      >
        <Paper className={classes.root}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Single Create" />
            <Tab label="Bulk Create" />
          </Tabs>
        </Paper>
        {tab === 0 ? (
          <Grid container className="p-3" spacing={4}>
            <Grid item xs={12}>
              <AppTextField
                value={userName}
                label="Username"
                onChange={value => handleUser(value)}
                error={errMsg.username ? true : false}
                helperText={
                  errMsg.username ? errMsg.username : 'Identifies this station'
                }
                onBlur={() => {
                  if (userName) {
                    setErrMsg({ ...errMsg, username: '' })
                    if (password) setButton({ disabled: false, type: 'Green' })
                  } else {
                    setButton({ disabled: true, type: 'Gray' })
                    setErrMsg({ ...errMsg, username: 'A username is required' })
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextField
                value={password}
                label="Password"
                onChange={value => handlePassword(value)}
                error={errMsg.password ? true : false}
                helperText={
                  errMsg.password ? errMsg.password : 'Choose a secure password'
                }
                onBlur={() => {
                  if (password) {
                    setErrMsg({ ...errMsg, password: '' })
                    if (userName) setButton({ disabled: false, type: 'Green' })
                  } else {
                    setButton({ disabled: true, type: 'Gray' })
                    setErrMsg({ ...errMsg, password: 'A password is required' })
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AppSelect
                item={realm}
                label="Realm (Optional)"
                keyName="slug"
                displayName={'name'}
                data={realmsList}
                onChange={value => handleRealm(value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                className={classes.active}
                control={
                  <Checkbox
                    checked={active}
                    onChange={handleActive}
                    value={active}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column">
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                margin: '0px 30px',
                padding: fileName ? '40px 0px 20px 0px' : '10px',
              }}
            >
              <span>{fileName}</span>
            </div>

            <Grid item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '30px',
                }}
              >
                <input
                  accept=".csv"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={fileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <CloudUploadIcon style={{ marginRight: '15px' }} /> Upload
                    File
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container className="p-3" spacing={4}>
          <Grid item xs={12} className="text-center mt-1">
            <AppButton onClick={closeConfirmation} width={165}>
              CANCEL
            </AppButton>
            <AppButton
              onClick={handleSubmit}
              className="ml-2"
              type={button.type}
              width={165}
              disabled={button.disabled}
            >
              SAVE
            </AppButton>
          </Grid>
        </Grid>
      </AppModal>

      <AppSnackbar
        vertical="top"
        horizontal="right"
        open={snack.active}
        close={closeSnackbar}
        text={snack.text}
      />
    </React.Fragment>
  )
}

export default AddStationForm
