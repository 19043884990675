import { Grid, TextField, Typography, Stack, Select, MenuItem, Checkbox, ListItemText, Menu, Dialog } from '@material-ui/core'
import React, {useState} from 'react'
import AppSelectMultiple from '../../../components/AppSelectMultiple';
import AppTextField from '../../../components/AppTextField'
import AppSelect from "../../../components/AppSelect"
import CreateLoader from 'common-components/create-loader/CreateLoader'
import axios from "axios"
import AppSnackbar from 'components/AppSnackbar'
import { useEffect } from 'react';
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest';
import LoaderReact from '../../../common-components/loader-overlay/loader-react';
import AppButton from "../../../components/AppButton"
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'

const API_BASE_URL = 'https://api.perfectpitchtech.com'
const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
const AddSingleUser = (props) => {

  let todayDate = new Date().toISOString().slice(0, 10);
  const [loading, setLoading] = useState(false)  
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [date, setDate] = useState(todayDate)
  const [button, setButton] = useState({ disabled: true, type: 'Gray' })
  const [roles, setRoles] = useState([])
  const [companies, setCompanies] = useState([])
  const [originalCompany, setOriginalCompany] = useState([])
  const [snack, setSnack] = useState({ active: false, text: '' })
  const [teams, setTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  
  const [offset, setOffset] = useState(60)
  const [campaigns, setCampaigns] = useState([])
  const [originalCampaign, setOriginalCampaign] = useState([])
  const [campaignList, setCampaignList] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('None')
  const [selectedCampaign, setSelectedCampaign] = useState([])
  
  const [searchCompany, setSearchCompany] = useState('')
  const [searchCampaign, setSearchCampaign] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState({})
  const [confirmation, setConfirmation] = useState(false)
  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  useEffect(() => {
    setLoading(true)
    axios.
      get(`${API_BASE_URL}/identity/group/list/?assignable=true`)
      .then(({data}) => {
        setRoles(data)
      })
      .then(() => {
        axios.get(`${API_BASE_URL}/identity/company/list`)
        .then(({data})=> {
          setOriginalCompany(data)
          setCompanies(data)
        })
        .then(()=> {
          axios.get(`${API_BASE_URL}/identity/campaign/list?active=true`)
          .then(({data}) => {
            setCampaigns(data)
            setOriginalCampaign(data)
            setCampaignList(data)
          })
          
        })
        .then(()=> {
          axios.get(`${API_BASE_URL}/identity/team/list/?editable=true`)
          .then(({data}) => {
            setTeams(data)
          })
          setLoading(false)
        })
      })
      .catch(console.error)
  }, [])
  
  const [errMsg, setErrMsg] = useState({})

  var filtered = function(list, key, value){
    var filtered = [], i = list.length;
    var reg = new RegExp("(.*)(" + value.toLowerCase() + ")(.*)");
    while (i--) {
        if (reg.test(list[i][key].toLowerCase())) {
            filtered.push(list[i]);
        }
        
    }
    return filtered;
  };

  const filterCampaign = (e) =>{
    let value = e.target.value
    setSearchCampaign(value)
    if(campaigns.length > 0){
      let filterCampaigns = filtered(campaigns, 'name', value)
      if(Object.keys(filterCampaigns).length > 0){  
        setCampaigns(filterCampaigns)
      }
      else{
        setCampaigns([])
      }
    }
    if(value === ""){
      setCampaigns(campaignList)
    }
  }
  
  const filterCompany = (e) => {
    let value = e.target.value
    setSearchCompany(value)
    let filterCompanies = filtered(companies, 'name', value)
    if(companies.length > 0){
      if(Object.keys(filterCompanies).length > 0){  
        setCompanies(filterCompanies)
      }else{
        setCompanies([])
      }
     
    }
    if(value === ""){
      setCompanies(originalCompany)
    }
  }
  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleChange = (event) => {
    setSelectedRoles(event.target.value);
  };
  const handleSelectCampaign = (e) => {setSelectedCampaign(e.target.value)}
  const handleCompanySelect = (e) => {
    setSelectedCompany(e.target.value)
    if(e.target.value === "None"){
      setCampaigns(campaignList)
      setSelectedCampaign([])
    }else{
      let newCampaign = campaignList.filter(item => {
        return item.company === e.target.value
      })
      setCampaigns(newCampaign)
      setSelectedCampaign([])
      setOriginalCampaign(newCampaign)
    }
    
  }

  const withCompany = () => {
    return ![10, 2, 4,].some(r => selectedRoles.includes(r))  && selectedRoles.length > 0
  }
  const checkForm = () => {
    if(firstname !== '' 
      && lastname !== '' 
      && selectedRoles.length > 0 
      && errMsg.username === ''
      && username !== ''
      && password !== '' 
      && confirmPassword === password 
      ){
        if(withCompany()){
          if(selectedCompany.length > 0
              && selectedCampaign.length > 0
              && selectedTeam.length > 0){
                return false
              }
        }else{
          return false
        }
    }
    return true
  }
  useEffect(() => {
      if(username.length > 0){
        setErrMsg({ ...errMsg, username: 'Checking username' })
        const delayDebounceFn = setTimeout( async () => {
          let searchRes =  await axios.get(`${API_BASE_URL}/identity/user/manage/list/?editable=true&limit=100000&search=${username}`)
          .then(({data}) => {
            let items = data.results.map(item => {
              return item.username
            })
            return items
          })
          const found = await searchRes.find(user => {
            return user.toLowerCase() === username.toLowerCase()
            });
            if(found === undefined){
              setErrMsg({ ...errMsg, username: '' })
            }else{
              setErrMsg({ ...errMsg, username: 'Invalid Username' })
            }
      }, 500)
      return () => clearTimeout(delayDebounceFn)
      }
    

    }, [username])
  const checkUsername = async (value) => {
    if(username.length > 0){
        setErrMsg({ ...errMsg, username: 'Checking username' })
          let searchRes =  await axios.get(`${API_BASE_URL}/identity/user/manage/list/?editable=true&limit=100000&search=${username}`)
          .then(({data}) => {
            let items = data.results.map(item => {
              return item.username
            })
            return items
          })
          
          const found = await searchRes.find(user => {
            return user.toLowerCase() === value.toLowerCase()
          });
          console.log(found)
          if(found === undefined){
            setErrMsg({ ...errMsg, username: '' })
          }else{
            setErrMsg({ ...errMsg, username: 'Invalid Username' })
          }
    }
  }
  const checkEmail = (value) => {
    if(email.length > 0){
      setTimeout(() => {
        axios.post(`${API_BASE_URL}/identity/user/manage/check_email/`, {email: value})
       .then(({data}) => {
        if(data.valid){
          setErrMsg({ ...errMsg, email: '' })
          return true
        }else{
          setErrMsg({ ...errMsg, email: 'Invalid Email' })
          return false
        }
       })
      }, 1000);
    }else{
      setErrMsg({ ...errMsg, email: '' })
    }
  }
  const onSave = () => {
    setIsLoading(true)
    let data = {
      "first_name":firstname,
      "last_name": lastname,
      "username": username,
      "email":email,
      "password":password,
      "campaigns":selectedCampaign,
      "company":selectedCompany === "None" ? null : selectedCompany,
      "groups": selectedRoles,
      "team": selectedTeam,
      "hire_date":date
   }
   axios
   .post(`${API_BASE_URL}/identity/user/manage/create/`, data,)
    .then(res => {
      setConfirmation(true)
      setIsLoading(false)
      setResult(res.data)
    }).catch(()=>{
      setSnack({
        active: true,
        text: "There's a problem, the user wasn't added.",
      })
      setIsLoading(false)
    })
    
  }
  const createAnother = () => {
    setLoading(false)  
    setFirstname('')
    setLastname('')
    setUsername('')
    setEmail('')
    setDate(todayDate)
    setButton({ disabled: true, type: 'Gray' })
    setSelectedTeam('')
    setPassword('')
    setConfirmPassword('')
    setSelectedRoles([])
    setSelectedCompany('None')
    setSelectedCampaign([])
    setSearchCompany('')
    setSearchCampaign('')
    setIsLoading(false)
    setResult({})
    setConfirmation(false)
    setSnack({ active: false, text: '' })
  }
  const closeSnackbar = () => {
    setSnack({ active: false, text: '' })
  }
  const handlerScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      setOffset(offset+30)
    }
  }
  return (
          <Grid container className="p-3" spacing={4} style={{paddingBottom:15}}>
            {
              loading ? 
              <LoaderReact message='Please wait a moment' />
              : isLoading ?
              <CreateLoader
                    message="One moment. We're creating the new User..."
                    handleCancel={() => {
                      props.$state.go('manage.users', null, { reload: true })
                    }}
                />
              :
              confirmation ? (
                <ConfirmationDialog
                    message={`You have added ${firstname} New User`}
                    SubmitTxt="ADD ANOTHER"
                    handleCancel={()=>{
                      props.$state.go('manage.users', null, { reload: true })
                    }}
                    handleCreateAnother={createAnother}
                    removeClose={true}
                  />
                )
              :
              <React.Fragment>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} >
                        <Typography variant="h6" component="h6" style={{padding:"-15px",}}>
                          Personal Info
                        </Typography> 
                      </Grid>
                    <Grid item xs={6}>
                      <AppTextField
                        required
                        value={firstname}
                        label="First name"
                        onChange={value => setFirstname(value)}
                        error={errMsg.firstname ? true : false}
                        helperText={
                          errMsg.firstname ? errMsg.firstname : ' '
                        }
                        onBlur={() => {
                          if (firstname) {
                            setErrMsg({ ...errMsg, firstname: '' })
                          } else {
                            setButton({ disabled: true, type: 'Gray' })
                            setErrMsg({ ...errMsg, firstname: 'A firstname is required' })
                          }
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={6}>
                      <AppTextField
                        value={lastname}
                        required
                        label="Last name"
                        onChange={value => setLastname(value)}
                        error={errMsg.lastname ? true : false}
                        helperText={
                          errMsg.lastname ? errMsg.lastname : ' '
                        }
                        onBlur={() => {
                          if (lastname) {
                            setErrMsg({ ...errMsg, lastname: '' })
                          
                          } else {
                            setButton({ disabled: true, type: 'Gray' })
                            setErrMsg({ ...errMsg, lastname: 'A Lastname is required' })
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AppTextField
                        required
                        value={username}
                        label="Username"
                        onChange={value => {
                          // checkUsername(value)
                          setUsername(value)
                        }}
                        error={errMsg.username ? true : false}
                        helperText={
                          errMsg.username ? errMsg.username : ' '
                        }
                       
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <AppTextField
                        value={email}
                        label="E-mail"
                        onChange={value => {
                          if(value.length > 0){
                            checkEmail(value)
                          }
                          setEmail(value)
                        }}
                        error={errMsg.email ? true : false}
                        helperText={
                          errMsg.email ? errMsg.email : ' '
                        }
                        onBlur={() => {
                          if(email.length === 0){
                            setErrMsg({ ...errMsg, email: '' })
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                    <AppTextField
                        type="date"
                        value={date}
                        label="Hired Date"
                        onChange={value => {
                          setDate(value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} >
                  <Grid container spacing={3}>
                      <Grid item xs={12} style={{padding:"-15px"}}>
                        <Typography variant="h6" component="h6" style={{padding:"-15px"}}>
                          Roles Info
                        </Typography> 
                      </Grid>
                    <Grid item xs={12}>
                      <AppSelectMultiple
                        required
                        multiple
                        label="User Roles"
                        value={selectedRoles}
                        onChange={handleChange}
                        renderValue={(selected) => selected.map(obj=> {
                          let index = roles.map(item => item.pk).indexOf(obj)
                          return roles[index].name
                        }).join(", ")}

                        error={errMsg.selectedRoles ? true : false}
                        helpText={
                          errMsg.selectedRoles ? errMsg.selectedRoles : ' '
                        }
                        onBlur={() => {
                          if (selectedRoles.length > 0) {
                            setErrMsg({ ...errMsg, selectedRoles: '' })
                          
                          } else {
                            setButton({ disabled: true, type: 'Gray' })
                            setErrMsg({ ...errMsg, selectedRoles: 'User Role is required' })
                          }
                        }}
                      >
                        {roles.map(({name,pk}) => {

                          return (
                          <MenuItem key={pk} value={pk}>
                              <Checkbox checked={selectedRoles.includes(pk)} />
                              <ListItemText primary={name} />
                          </MenuItem>
                          )
                        })
                        }
                      </AppSelectMultiple>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={6} >
                 <Grid container spacing={3}>
                     <Grid item xs={12} style={{padding:"-15px"}}>
                       <Typography variant="h6" component="h6" style={{padding:"-15px"}}>
                         Setup password
                       </Typography> 
                     </Grid>
                   
                   <Grid item xs={6}>
                     <AppTextField
                         name='password'
                         label="Password"
                         value={password}
                         autoComplete='off'
                         type='password'
                         helperText={
                          errMsg.password ? errMsg.password : ' '
                         }
                         onChange={(value)=>{
                          if(value.length > 9){
                            setErrMsg({ ...errMsg, password: '' })
                          }else{
                            setErrMsg({ ...errMsg, password: 'Password must be 10 characters long' })
                          }
                          setPassword(value)
                         }}
                         error={errMsg.password ? true : false}
                         onBlur={() => {
                          if (password.length > 9) {
                            setErrMsg({ ...errMsg, password: '' })
                          } else {
                            setButton({ disabled: true, type: 'Gray' })
                            setErrMsg({ ...errMsg, password: 'Password must be 10 characters long' })
                          }
                          if(password === ''){
                            setErrMsg({ ...errMsg, password: 'Password is required' })
                          }
                        }}
                       />
                   </Grid>
                   
                   <Grid item xs={6}>
                      <AppTextField
                         label="Confirm Password"
                         value={confirmPassword}
                         autoComplete='off'
                         type='password'
                         onChange={(value)=>{
                          if(value === password){
                            setErrMsg({ ...errMsg, confirmPassword: false })
                          }else{
                            setErrMsg({ ...errMsg, confirmPassword: 'Password does not match' })
                          }
                          setConfirmPassword(value)
                         }}
                         helperText={
                          errMsg.confirmPassword ? errMsg.confirmPassword : ' '
                         }
                         error={errMsg.confirmPassword ? true : false}
                         onBlur={() => {
                          if(confirmPassword.length > 0){
                            if (confirmPassword === password) {
                              setErrMsg({ ...errMsg, confirmPassword: '' })
                              
                            } 
                          }
                          else {
                            setButton({ disabled: true, type: 'Gray' })
                            setErrMsg({ ...errMsg, confirmPassword: 'Please confirm your password' })
                          }
                          
                        }}
                         
                       />
                   </Grid>

                 </Grid>
               </Grid>         
                
                <React.Fragment>
                  {
                     withCompany() ?
                    (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Grid item xs={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{padding:"-15px"}}>
                                            <Typography variant="h6" component="h6" style={{padding:"-15px"}}>
                                              Campaign and Company
                                            </Typography> 
                                        </Grid>
                                        <Grid item xs={12}>
                                        <AppSelectMultiple
                                            value={selectedCompany}
                                            label="Company"
                                            onChange={e => {
                                                handleCompanySelect(e)
                                            }}
                                            error={errMsg.selectedCompany ? true : false}
                                            helpText={
                                              errMsg.selectedCompany ? errMsg.selectedCompany : ' '
                                            }
                                            onBlur={() => {
                                              if (selectedCompany.length > 0) {
                                                setErrMsg({ ...errMsg, selectedCompany: '' })
                                              
                                              } else {
                                                setButton({ disabled: true, type: 'Gray' })
                                                setErrMsg({ ...errMsg, selectedCompany: 'Please select a company' })
                                              }
                                            }}
                                        >
                                            <div style={{padding:15}}>
                                              <TextField
                                                onClickCapture={stopImmediatePropagation}
                                                onKeyDown={e => e.stopPropagation()}
                                                label="Search for company" 
                                                fullWidth 
                                                value={searchCompany}
                                                onChange={(e) => filterCompany(e)}
                                                />
                                            </div>
                                            <MenuItem value={"None"} key="" onKeyDown={(e) => e.stopPropagation()}>
                                                None
                                            </MenuItem>
                                            {companies.map((company) => (
                                              <MenuItem value={company.uuid} key={company.uuid} onKeyDown={(e) => e.stopPropagation()}>
                                                  {company.name}
                                              </MenuItem>
                                            ))}
                                            {
                                            companies.length === 0 &&
                                            <div style={{padding:15}}>
                                                <b>No result</b>
                                            </div>
                                            }
                                        </AppSelectMultiple>
                                        
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                        
                                        <AppSelectMultiple
                                            onScroll={(e) => {
                                              handlerScroll(e)
                                            }}
                                            multiple
                                            label="Campaigns"
                                            value={selectedCampaign}
                                            onChange={handleSelectCampaign}
                                            renderValue={(selected) => selected.map(obj=> {
                                            if(campaigns.length > 0){
                                                let index = campaigns.map(item => item.uuid).indexOf(obj)
                                                if(campaigns[index]){
                                                return campaigns[index].name
                                                }
                                            }
                                            }).join(", ")}
                                            error={errMsg.selectedCampaign ? true : false}
                                            helpText={
                                              errMsg.selectedCampaign ? errMsg.selectedCampaign : ' '
                                            }
                                            onBlur={() => {
                                              if (selectedCampaign.length > 0) {
                                                setErrMsg({ ...errMsg, selectedCampaign: '' })
                                              
                                              }
                                            }}
                                        >
                                            <div style={{padding:15, overflow:"auto"}} >
                                            <TextField
                                                onClickCapture={stopImmediatePropagation}
                                                onKeyDown={e => e.stopPropagation()}
                                                label="Search for campaigns" 
                                                fullWidth 
                                                value={searchCampaign}
                                                onChange={(e) => filterCampaign(e)}
                                                />
                                            </div>
                                              {paginate(campaigns, offset, 1).map(({name,uuid}) => {
                                                return (
                                                  <MenuItem key={uuid} value={uuid} onBlur={()=>{
                                                    setCampaigns(campaignList)
                                                    setSearchCampaign('')
                                                  }}>
                                                      <Checkbox checked={selectedCampaign.includes(uuid)} />
                                                      <ListItemText primary={name} />
                                                  </MenuItem>
                                                )
                                              }) 
                                              }
                                            
                                            {
                                            campaigns.length === 0 &&
                                            <div style={{padding:15}}>
                                                <b>No result</b>
                                            </div>
                                            }
                                        </AppSelectMultiple>
                                        
                                        </Grid>
            
                                        
                                    </Grid>
                                </Grid>

                                
                            </Grid>
                            <Grid item xs={6}>&nbsp;</Grid>
                            <Grid item xs={6}>
                                <Grid item xs={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{padding:"-15px"}}>
                                            <Typography variant="h6" component="h6" style={{padding:"-15px"}}>
                                              Team Info
                                            </Typography> 
                                        </Grid>
                                        <Grid item xs={12}>
                                        <AppSelectMultiple
                                            value={selectedTeam}
                                            label="User Team"
                                            onChange={e => {
                                                setSelectedTeam(e.target.value)
                                            }}
                                            error={errMsg.selectedTeam ? true : false}
                                            helpText={
                                              errMsg.selectedTeam ? errMsg.selectedTeam : ' '
                                            }
                                            onBlur={() => {
                                              if (selectedTeam.length > 0) {
                                                setErrMsg({ ...errMsg, selectedTeam: '' })
                                              
                                              } else {
                                                setButton({ disabled: true, type: 'Gray' })
                                                setErrMsg({ ...errMsg, selectedTeam: 'User team is required' })
                                              }
                                            }}
                                        >
                                            {teams.map((team) => (
                                              <MenuItem value={team.uuid} key={team.uuid} onKeyDown={(e) => e.stopPropagation()}>
                                                  {team.name}
                                              </MenuItem>
                                            ))}
                                            
                                        </AppSelectMultiple>
                                        
                                        </Grid>
                                    </Grid>
                                </Grid>

                                
                            </Grid>           
                        </React.Fragment>
                    )
                    :null
                  }
                </React.Fragment>
                
                
                <Grid container className="p-3" spacing={4}>
                  <Grid item xs={12} className="text-center mt-1">
                    <AppButton onClick={() => {
                      props.$state.go('manage.users', null, { reload: true })
                    }} width={165}>
                      CANCEL
                    </AppButton>
                    <AppButton
                      onClick={onSave}
                      className="ml-2"
                      type={checkForm() ? "Gray" : "Green"}
                      width={165}
                      disabled={checkForm()}
                    >
                      SAVE
                    </AppButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <AppSnackbar
              vertical="top"
              horizontal="right"
              open={snack.active}
              close={closeSnackbar}
              text={snack.text}
            />
          </Grid>
  )
}
export default AddSingleUser