import './pitchXml.scss'
import PitchXmlController from './controller/PitchXmlController.js'
const template = require('./pitchXml.component.tpl.pug')

const pitchXml = {
  template: template(),
  controller: PitchXmlController,
  bindings: {
    versions: '<',
    xmlRawService: '<',
  },
}

export default pitchXml
