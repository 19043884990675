import RouteInfoController from './controller/RouteInfoController.js'
const template = require('./routeInfoForm.component.tpl.pug')

const routeInfoForm = {
  template: template(),
  controller: RouteInfoController,
  bindings: {
    router: '<',
    campaign: '<',
  }
}

export default routeInfoForm
