import { pick } from 'ramda'
import ARD from '../../../../dialogs/audio-recording/audioRecording.dialog.js'
import PDD from '../../../../dialogs/audio-dialog/audio.dialog.js'
import BaseAudioController from '../../common/controller/BaseAudioController.js'
import {
  unrecordedConfig,
  rerecordConfig,
  recordedConfig,
  addButtonActions,
} from '../config/phraseAudioTables.js'

export default class PhraseAudioController extends BaseAudioController {
  constructor(
    $mdDialog,
    $sessionStorage,
    IdentityService,
    ManagementService,
    RoleStore,
    PitchService,
    ManageAudioService,
    ToastService,
    $rootScope,
    $scope,
  ) {
    'ngInject'
    super(...arguments)
    this.disableButton = true
    this.isSelectionApplied = false
    this.audioType = 'phrase'
    this.viewingDialog = PDD
    this.recordingDialog = ARD
    this.unrecordedTable = unrecordedConfig
    this.rerecordTable = addButtonActions(
      { MOVE_TO_RECORD: this.moveToRecord.bind(this) },
      rerecordConfig,
    )
    this.recordedTable = addButtonActions(
      { MOVE_TO_RERECORD: this.moveToRerecord.bind(this) },
      recordedConfig,
    )
    $scope.$watch('$ctrl.state.selected.phraseBook', (newVal, oldVal) => {
      $rootScope.refreshOptions.isVisible = newVal ? true : false
    })
    Object.assign(this.state.selectOptions, {
      phraseBooks: [],
    })
  }

  selectCampaignCb(campaign) {
    this.isSelectionApplied = false
    Object.assign(this.state.selected, { phraseBook: null })
    if (campaign === 'global') {
      const global = this.PS.setupPhraseBooks().phraseBooks
      global
        .get()
        .then(phraseBooks => phraseBooks.plain())
        .then(phraseBooks => {
          this.state.selectOptions.phraseBooks = phraseBooks
        })
      return
    }
    campaign.company = campaign.company.uuid
      ? campaign.company.uuid
      : campaign.company // ensure this is always a uuid string
    this.MS.populateEntityRelations(campaign, {
      entityName: 'campaign',
      populate: ['company'],
    })
      .then(({ company }) => {
        this.state.selected.campaign.company = company
        return { company }
      })
      .then(this.PS.setupPhraseBooks.bind(this.PS))
      .then(services => services.phraseBooks.get())
      .then(phraseBooks => phraseBooks.plain())
      .then(phraseBooks => {
        this.state.selectOptions.phraseBooks = phraseBooks
      })
      .catch(err => {
        this.state.selectOptions.phraseBooks = []
        this.TS.show({ text: 'Problem fetching phrase books' })
        console.error(err)
      })
  }

  selectPhraseBookCb(phraseBook = {}) {
    sessionStorage.setItem("phraseBook", JSON.stringify(phraseBook))
    this.state.loadingAudio = true
    this.setupVoiceService(phraseBook)
    const serviceKeys = ['recorded', 'rerecord', 'unrecorded']
    const service = pick(serviceKeys, this.voiceAudioService)
    this.MAS.fetchVoiceAudio({ service, audio: serviceKeys })
      .then(this.setAudio.bind(this))
      .finally(() => {
        this.state.loadingAudio = false
      })
  }

  setupVoiceService(phraseBook) {
    const voiceId = this.state.selected.voice
      ? this.state.selected.voice.uuid
      : this.user.uuid
    let serviceConfig = {
      voiceId,
      phraseBookId: phraseBook.uuid,
    }

    if (phraseBook.company) {
      Object.assign(serviceConfig, {
        companySlug: this.state.selected.campaign.company.slug,
      })
    }
  
    this.voiceAudioService = this.PS.setupPhraseBookVoice(serviceConfig)
  }

  fetchAudio() {
    this.selectPhraseBookCb(this.state.selected.phraseBook)
    this.disableButton = true
    this.isSelectionApplied = true
  }

  clearSelections() {
    Object.assign(this.state.selected, {
      voice: null,
      campaign: null,
      phraseBook: null,
    })
  }
  canFetchAudio() {
    this.disableButton = false
    this.isSelectionApplied = false
  }
}
