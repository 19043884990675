// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { MuiThemeProvider } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import GroupOutlined from '@material-ui/icons/GroupOutlined'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { theme } from 'theme'
import PageHeader from 'common-components/page-header/PageHeader'
import TableLoader from 'common-components/loader/table-loader/TableLoader'
import ActionButton from 'common-components/action-button/ActionButton'
import AsyncTable from 'common-components/async-table/AsyncTable'
import CreateRealmForm from 'common-components/forms/create/CreateRealm'
import TableSearch from 'common-components/table-search-prompt/TableSearch'
import CopyElement from 'common-components/copy-element/CopyElement'
import ConfirmationDialog from 'common-components/confirmation-dialog/ConfirmationDialog'
import Paginator from 'components/Paginator'

import {
  ActiveCell,
  UnderlineCell,
} from 'common-components/table-cells/tableCells'

import './ManageRealms.scss'

const API_BASE_URL = getBaseUrl(window.location.host, true)

const Loader = (
  <TableLoader
    headerText="Management"
    message="One moment, we are loading your content..."
    Icon={GroupOutlined}
  />
)

const editRealm = (uuid) => {
  let url = window.location + '/edit/' + uuid;
  window.location.href = url;
}

const RealmTableRow = ({
  realm,
  rowClass,
  cellClass,
}: {
  realm: Realm,
  rowClass: string,
  cellClass: string,
}) => (
    <TableRow className={rowClass}>
      <UnderlineCell className={cellClass}>
        <a href={window.location.origin + window.location.pathname + '#!/manage/realms/edit/' + realm.uuid}
          style={{ color: '#444851' }}>{realm.name}</a>
      </UnderlineCell>
      <TableCell className={cellClass} style={{ color: '#777777' }}>{realm.uuid}
        <CopyElement title="Copy UUID" value={realm.uuid} response="UUID copied to clipboard" />
      </TableCell>
      <ActiveCell className={cellClass}>{realm.active == true ? 'Active' : 'Inactive'}</ActiveCell>
      <TableCell className={cellClass} style={{
        color: '#444851', textAlign: 'right',
        textDecoration: 'underline'
      }}>
        <IconButton disableRipple={true} className="btn" onClick={() => editRealm(realm.uuid)}>
          <EditIcon style={{ fontSize: '14px', marginRight: 8 }} /> Edit
        </IconButton>
      </TableCell>
    </TableRow>
  )

type Props = {
  // All Angular services
  IdentityService: IdentityService,
  SideNavService: SideNavService,
  ManageMenuService: Object,
  RoleStore: RoleStore,
  $state: Object,
}

type State = {
  state: 'DATA_LOADING' | 'DATA_LOADED',
  createDialogOpen: boolean,
  confirmation: Boolean,
  menuItems: Page[],
  realms: Realm[],
  searchField: string,
  realmName: string,
}

export class ManageRealms extends React.Component<Props, State> {
  state = {
    state: 'DATA_LOADING',
    createDialogOpen: false,
    confirmation: false,
    menuItems: [],
    realms: [],
    searchField: '',
    realmName: '',
  }

  handleNavClick = (state: string) => this.props.$state.go(state)

  openCreateDialog = () => this.setState({ createDialogOpen: true })

  cancelCreateDialog = () => this.setState({ createDialogOpen: false, confirmation: false, })

  createAnotherDialog = () => this.setState({ confirmation: false })

  updateRealms = (newRealm: Realm) => {
    this.setState({
      realmName: newRealm.name,
      confirmation: true,
      realms: [newRealm, ...this.state.realms],
    })
  }

  getMenuItems = () => {
    return this.props.SideNavService.createMenuForRole(
      this.props.ManageMenuService.getMenu(),
      this.props.RoleStore.getAllRoles(),
    )
      .filter(menuSection => menuSection.name === 'Management')
      .pop()
      .pages.filter(page => page.state !== this.props.$state.current.name)
  }

  componentDidMount() {
    axios.
      get(`${API_BASE_URL}/identity/realm/list/`)
      .then(realms => {
        this.setState({
          state: 'DATA_LOADED',
          realms: realms.data,
          searchField: '',
        })
      })
      .catch(console.error)
  }

  render() {
    var copyRealms = this.state.realms;
    var filteredData = copyRealms.filter(item => item.name.includes(this.state.searchField));

    return (
      <MuiThemeProvider theme={theme} >
        <PageHeader
          padding="30px 0 36px"
          title="Realms"
          navDropdown={true}
          handleNavClick={this.handleNavClick}
          menuItems={this.getMenuItems()}
          render={() => (
            <ActionButton
              buttonColor="secondary"
              handleClick={this.openCreateDialog}
            >
              <Add style={{ fontSize: 20, marginRight: 5 }} />
              New realm
            </ActionButton>
          )}
        />
        <Paper style={{ width: '100%', overflowX: 'auto' }}>
          <TableSearch
            placeholder="Search Realms"
            handleChange={e => this.setState({ searchField: e.target.value })} />
          <AsyncTable
            loading={this.state.state === 'DATA_LOADING'}
            Loader={Loader}
            tableData={filteredData}
            headers={['Name', 'UUID', 'Status', '']}
            render={(realms: Realm[], { row, cell, icon }) =>
              realms.map(realm => (
                <RealmTableRow
                  key={realm.uuid}
                  realm={realm}
                  rowClass={row}
                  cellClass={cell}
                  iconClass={icon}
                />
              ))
            }
          />
        </Paper>
        <Dialog
          open={this.state.createDialogOpen}
          onClose={this.cancelCreateDialog}
          aria-labelledby="create-realm-dialog"
        >
          {this.state.confirmation ?

            <ConfirmationDialog
              message={"You have created the " + this.state.realmName + " Realm"}
              handleCancel={this.cancelCreateDialog}
              SubmitTxt="CREATE ANOTHER"
              handleCreateAnother={this.createAnotherDialog} />
            :
            <CreateRealmForm
              handleCreate={this.updateRealms}
              handleCancel={this.cancelCreateDialog}
            />}
        </Dialog>
      </MuiThemeProvider>
    )
  }
}
