import deleteEntityDialog from '../../../dialogs/delete-entity/deleteEntity.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class EditAccessListController {
  constructor ($state, $timeout, $mdDialog, ManagementService, IdentityService, EditAccessListService, ToastService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.TS = ToastService
    this.IS = IdentityService
    this.MS = ManagementService
    this.EACL = EditAccessListService
    this.ADMIN_GROUP = ADMIN_GROUP

    this.state = {
      loading: {
        details: true,
        nodes: true,
      },
      show: {
        ipAddressResult: false,
        ipAddressAllowed: false,
        ipAddressInvalid: false,
        details: true,
        nodes: true,
        ip_checker: true,
      },
      nodes: [],
    }

    this.toolbarOptions = {
      textTrue: 'Allowed',
      textFalse: 'Not Allowed',
      value: false,
      name: 'Access List'
    }

    this.validateIp = ({ searchText }) => {
      this.state.show.ipAddressResult = false
      this.state.show.ipAddressInvalid = false
      if (!searchText.match(/^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/)) {
        this.state.show.ipAddressInvalid = true
        return
      }
      if (searchText) {
        // finish logic here for validation
        let accessList = angular.copy(this.accessList)
        if (this.company) {
          accessList.company_slug = this.company.slug
        }
        accessList.ip_address = searchText
        this.EACL.checkIPAddress(accessList).then((data) => {
          this.state.show.ipAddressResult = true
          this.state.show.ipAddressAllowed = data.allowed
        })
        return
      }
      this.state.show.ipAddressResult = false
    }
  }

  $onInit () {
    this.editableAccessList = Object.assign({}, this.accessList)
    this.allowOptions = [
      {'key': true, 'value': 'Yes'},
      {'key': false, 'value': 'No'},
    ]
    this.fillToolbarOptions()
    this.getCampaignRelationOptions([ 'company' ])

    this.$timeout(() => {
      if (this.openSections) {
        this.state.show = true // setVisibility(this.state.show, this.openSections, true)
      }
    }, 500)
  }
  fillToolbarOptions () {
    this.toolbarOptions.name = this.editableAccessList.name
    this.toolbarOptions.value = this.editableAccessList.allow_by_default
  }
  backTo () {
    this.$state.go('manage.accessLists', {compSlug: this.compSlug})
  }

  update (accessList) {
    this.state.actionTitle = 'Updating'
    this.state.loading.details = true
    this.state.loading.dataPosting = true
    accessList.companySlug = this.compSlug
    this.EACL.update(accessList)
      .then(updatedAcl => {
        this.editableAccessList = Object.assign({}, updatedAcl)
        this.editableAccessList.companySlug = this.compSlug
        this.accessList = this.editableAccessList
        this.accessListEditForm.$setPristine()
        this.TS.show({
          text: 'Access list has been successfully updated'
        })
      })
      .catch(err => {
        if (err.data.length) {
          return this.TS.show({
            text: err.data[0]
          })
        }
        this.TS.show({
          text: 'Error Updating Acl'
        })
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.updating = false
          this.state.loading.details = false
          this.state.loading.dataPosting = false
        }, 2000)
      })
  }

  exit () {
    this.$state.go('manage.access-lists', null, { reload: true })
  }

  getCampaignRelationOptions (relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
        if (this.accessList.company) {
          this.company = this.companyOptions.find((c) => { return c.uuid === this.accessList.company })
        }
        this.getAclNodes(angular.copy(this.accessList))
      })
      .catch(err => {
        this.TS.show({
          text: 'Problem fetching options, you wont be able to create a access list'
        })
        console.error(err)
      })
      .finally(() => {
        this.state.loading.details = false
        this.accessListEditForm.$setPristine()
      })
  }

  getAclNodes (accessList) {
    this.state.loading.nodes = true
    accessList.companySlug = this.compSlug
    this.EACL.getNodes(accessList)
      .then(nodes => {
        this.state.nodes = nodes
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.state.loading.nodes = false
      })
  }

  toggleSection (toggleId) {
    this.state.show[toggleId] = !this.state.show[toggleId]
  }

  cancelEdit (form) {
    this.editableAccessList = Object.assign({}, this.accessList)
    form.$setPristine()
  }

  deleteAcl (acl) {
    const DCD = deleteEntityDialog
    DCD.locals = {
      title: 'Delete Access List',
      entityName: acl.name,
      identityEndpoint: this.IS.Acl.fetch.one(acl.uuid, this.compSlug),
      entity: acl,
      routeWhenSuccessful: 'manage.accessLists'
    }
    this.$mdDialog.show(DCD)
  }

  createNewNode (accessList) {
    let company = this.company
    this.$state.go('.node.create', { accessList, company })
  }

  editNode (node, accessList) {
    let company = this.company
    this.$state.go('.node.edit', { node, accessList, company })
  }

  toggleAllowed (value) {
    this.editableAccessList.allow_by_default = value
    this.fillToolbarOptions()
  }
}
