export default class DeleteEntityController {
  constructor ($mdDialog, $state, $timeout) {
    'ngInject'

    this.$timeout = $timeout
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.state = {
      deleting: false,
      deleteError: false,
      deleteSuccess: false,
    }
  }

  deleteEntity () {
    this.dismissErrors()
    this.state.deleting = true
    const action = this.isVariable ? 'patch' : 'remove'
    let data = null

    if (this.isVariable) {
      delete this.variables[this.entityName]
      data = {
        variables: this.variables
      }
    }

    this.identityEndpoint[action](data)
      .then(res => {
        this.$timeout(() => {
          this.state.deleting = false
          this.state.deleteSuccess = true
        }, 1000)
      })
      .catch(() => {
        this.state.deleting = false
        this.state.deleteError = true
      })
  }

  dismissErrors () {
    this.state.deleteError = false
  }

  exitToManageUsers () {
    const params = this.routeParams || null
    if (this.routeWhenSuccessful) {
      this.$state.go(this.routeWhenSuccessful, params, { reload: true })
    }
    this.$mdDialog.hide()
  }

  close () {
    this.$mdDialog.cancel()
  }
}
