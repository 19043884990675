import './selectMetricsDialog.scss'
import SelectMetricsController from './controller/SelectMetricsController.js'
const template = require('./selectMetricsDialog.tpl.pug')

const selectMetricsDialog = {
  parent: angular.element(document.body),
  template: template(),
  skipHide: true,
  clickOutsideToClose: true,
  fullscreen: false,
  multiple: true,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: SelectMetricsController
}

export default selectMetricsDialog
