import ManageTeamsController from './controller/ManageTeamsController.js'
const template = require('./manageTeams.component.tpl.pug')

const manageTeams = {
  template: template(),
  controller: ManageTeamsController,
  bindings: {}
}

export default manageTeams

