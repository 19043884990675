import './dashboard.repTrend.scss'

import angular from 'angular'

// services
import RepTrendService from './services/RepTrendService.js'

// components
import repTrend from './component/repTrend.js'
import trendTable from './sub-components/trend-table/trendTable.js'

export default angular
  .module('portalApp.dashboard.repTrend', [])
  .service('RepTrendService', RepTrendService)
  .component('repTrend', repTrend)
  .component('trendTable', trendTable)
  .name
