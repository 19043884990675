// @flow
import { getBaseUrl } from '../../../global/config/providers/restangularProvider/rest.js'

import * as React from 'react'
import axios from "axios"
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncTable from 'common-components/async-table/AsyncTable'

const API_BASE_URL = getBaseUrl(window.location.host, true)

type Props = {
    classes: Object,
    active: Boolean,
}

const styles = {
    root: {
        margin: '20px 0',
        width: '100%',
    },
    header: {
        color: '#444851',
        fontSize: '14px',
        backgroundColor: '#eeeeee',
        borderRadius: '3px'
    },
    appBar: {
        backgroundColor: 'transparent',
    },
    cellStyle: {
        color: '#444851',
    }
}


function Upload(props: Props) {
    const [list, setList] = useState([
        { key: 'City', value: 'Bozeman' },
        { key: 'First_name', value: 'Samuel' },
        { key: 'Last_name', value: 'Maira' },
        { key: 'Primary_phone', value: '6046617942' },
    ]);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        /* axios.
            get(`${API_BASE_URL}/identity/campaign/list/`)
            .then(response => {
                setLoadingData(false)
                setList(response.data)
            })
            .catch(console.error) */

    }, []);

    const Loader = () => (
        <Grid alignItems="center"
            container
            direction="column"
            justify="center"
            style={{ margin: '40px 0' }}>
            <Grid item>
                <CircularProgress size={55} style={{ color: '#777777' }} />
            </Grid>
            <Grid item>
                <p>Loading data...</p>
            </Grid>
        </Grid >
    )

    const UploadRow = ({
        element,
        rowClass,
        cellClass,
    }: {
        row: Object,
        rowClass: string,
        cellClass: string,
    }) => (
            <TableRow className={rowClass}>
                <TableCell className={cellClass}>{element.key}</TableCell>
                <TableCell className={cellClass}>{element.value}</TableCell>
            </TableRow>
        )


    return (
        <React.Fragment>
            <AsyncTable
                loading={loadingData}
                Loader={
                    <Loader />
                }
                tableData={list}
                headers={['Key', 'Value']}
                render={(listData, { row, cell, icon }) =>
                    listData.map(element => (
                        <UploadRow
                            key={element.key}
                            element={element}
                            rowClass={row}
                            cellClass={[cell, props.classes.cellStyle].join(' ')}
                            iconClass={icon}
                        />
                    ))
                }
            />
        </React.Fragment>
    )
}


export default withStyles(styles)(Upload)
