import status from 'statuses'

const formConfig = [
  {
    accessor: 'name',
    inputType: 'input',
    label: 'Router Name',
    type: 'text',
    attributes: ['required'],
  },
  {
    accessor: 'uuid',
    inputType: 'input',
    label: 'UUID',
    type: 'text',
    copyable: true,
    attributes: ['disabled'],
  },
  {
    accessor: 'route_continue',
    inputType: 'multiple',
    label: 'Route Continue Codes',
    selectOptions: status.codes.map(code => {
      return {
        name: code.toString(),
        value: code.toString(),
      }
    }),
    attributes: ['required'],
  },
  {
    accessor: 'slug',
    inputType: 'input',
    label: 'Slug',
    type: 'text',
    copyable: true,
    attributes: ['disabled'],
  },
  {
    accessor: 'distribution_type',
    inputType: 'select',
    label: 'Distribution Type',
    selectOptions: [
      { name: 'Round Robin', value: 0 },
      { name: 'Broadcast', value: 1 },
    ],
  },
  {
    accessor: 'active',
    inputType: 'toggle',
    label: 'Route Active',
  },
]

export default formConfig
