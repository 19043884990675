export default class AgentService {
  constructor (ReportService) {
    'ngInject'
    this.RS = ReportService
  }

  getLiveAgentReports (companySlug, campaignSlug) {
    const url = `/live/rep/${companySlug}/${campaignSlug}`
    return this.RS.reporting
          .one(url)
          .get()
          .then((data) => {
            return data.plain()
          })
  }
}
