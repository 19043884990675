// @flow

import type { Element } from 'restangular'
import type { TitleBarButtonConfig } from '../../../../../../common-components/title-bar/component/titleBarComponent.js'
import type PitchService from '../../../../../../global/index/api-services/PitchService.js'
import type ToastService from '../../../../../../global/index/services/ToastService.js'
import type { FilterListConfig } from '../../../../../../common-components/filter-list/component/filterList.js'
import type { RapidResponseSegment } from 'types/entities.js'

import CPSD from '../../../../../dialogs/create-pitch-segments/createPitchSegments.js'
import deleteEntityDialog from '../../../../../dialogs/delete-entity/deleteEntity.js'

export default class RapidResponseSegmentsController {
  $state: Object
  $mdDialog: Object
  $localStorage: Object
  PS: PitchService
  TS: ToastService
  GlobalSegmentService: Element
  columnsConfig: FilterListConfig
  topBarButtons: TitleBarButtonConfig
  state: {|
    loading: boolean,
    rapidResponseSegments: RapidResponseSegment[],
    endpoint: 'rapid-response/segments',
  |}

  constructor(
    $state: Object,
    $localStorage: Object,
    $mdDialog: Object,
    PitchService: PitchService,
    ToastService: ToastService,
  ) {
    'ngInject'
    this.PS = PitchService
    this.TS = ToastService
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.$localStorage = $localStorage
    this.columnsConfig = [
      { title: 'Name', accessor: 'name', type: 'text' },
      { title: 'Slug', accessor: 'slug', type: 'text' },
      { title: 'Type', accessor: 'type', type: 'text' },
      { title: 'UUID', accessor: 'uuid', type: 'text', copyable: true },
      { title: 'Active', accessor: 'active', type: 'active' },
      {
        title: 'Edit',
        icon: 'editor:mode_edit',
        type: 'menu',
        menu: [
          {
            title: 'XML',
            icon: 'action:code',
            actionCb: segment => this.openSegmentDialog(segment),
          },
          {
            title: 'Variables',
            icon: 'action:visibility',
            actionCb: segment => this.goToVariables(segment),
          },
          {
            title: 'Delete',
            icon: 'action:outline_delete',
            actionCb: segment => this.deleteSegment(segment),
          },
        ],
      },
    ]
    this.topBarButtons = [
      {
        title: 'New Segment',
        icon: 'content:add',
        action: this.createSegment.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.state = {
      loading: true,
      endpoint: 'rapid-response/segments',
      rapidResponseSegments: [],
    }
  }

  $onChanges() {
    this.GlobalSegmentService = this.PS.setupBase(
      null,
      'rapid-response',
    ).global.one('segments')
    this.getSegments()
  }

  isEditingDialog() {
    if (this.$localStorage.editingDialog) {
      const segment = JSON.parse(this.$localStorage.editingDialog)
      this.openSegmentDialog(segment)
      delete this.$localStorage.editingDialog
    }
  }

  getSegments() {
    this.GlobalSegmentService.get()
      .then(rapidResponseSegments => {
        this.state.rapidResponseSegments = rapidResponseSegments.plain()
      })
      .catch(err => {
        this.TS.show({
          text: 'There was a problem fetching rapid response segments',
        })
      })
      .finally(() => {
        this.isEditingDialog()
        this.state.loading = false
      })
  }

  goToVariables(segment: RapidResponseSegment) {
    const params = {
      compUuid: '1',
      compSlug: 'global',
      uuid: segment.uuid,
      isRapidResponse: true,
    }
    this.$state.go('manage.editSegmentVariables', params)
  }

  createSegment() {
    const locals = {
      isCreate: true,
      isRapidResponse: true,
      service: this.GlobalSegmentService,
      endpoint: this.state.endpoint,
      company: 'global',
    }
    CPSD.locals = locals
    this.$mdDialog
      .show(CPSD)
      .then(createdSegment => {
        this.state.rapidResponseSegments.push(createdSegment)
      })
      .catch(err => {
        console.info('canceled rapid response segment creation')
      })
  }

  openSegmentDialog(segment: RapidResponseSegment) {
    const locals = {
      isCreate: false,
      isRapidResponse: true,
      service: this.GlobalSegmentService,
      endpoint: this.state.endpoint,
      company: 'global',
      segment,
    }
    CPSD.locals = locals
    this.$mdDialog.show(CPSD)
  }

  deleteSegment(segment: RapidResponseSegment) {
    const DED = deleteEntityDialog
    DED.locals = {
      title: 'Delete Rapid Response Segment',
      entityName: segment.name,
      identityEndpoint: this.GlobalSegmentService.one(segment.uuid),
      entity: segment,
      routeWhenSuccessful: 'manage.globalRapidResponse.segments',
    }
    this.$mdDialog.show(DED)
  }
}
