import OptionsGroupDialogController from './controller/OptionsGroupDialogController.js'
const template = require('./optionsGroupDialog.tpl.pug')

const optionsGroupDialog = {
  parent: angular.element(document.body),
  template: template(),
  clickOutsideToClose: false,
  fullscreen: false,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: OptionsGroupDialogController,
}

export default optionsGroupDialog

