import ChartContainerController from './controller/ChartContainerController.js'
const template = require('./chartContainer.tpl.pug')

const chartContainer = {
  template: template(),
  controller: ChartContainerController,
  bindings: {
    componentConfig: '<',
    dateRange: '<',
    company: '<',
    campaign: '<',
    realm: '<',
  }
}

export default chartContainer
