import { findIndex, propEq } from 'ramda'
import DED from '../../../../../../dialogs/delete-entity/deleteEntity.js'
import FOD from '../../../../../../dialogs/field-option/fieldOptionDialog.js'

export default class PitchOptionGroupController {
  constructor($mdDialog) {
    'ngInject'

    this.$mdDialog = $mdDialog

    this.state = {
      loadingOptions: true,
      options: [],
    }
    this.editGroup = false
  }

  $onChanges(bindings) {
    if (bindings.group.isFirstChange()) {
      this.editableGroup = Object.assign({}, this.group)
    }

    if (bindings.group && bindings.baseService) {
      this.setupServices(this.group)
      this.fetchOptions(this.group)
    }
  }

  toggleEdit() {
    this.editGroup = !this.editGroup
  }
  setupServices(group) {
    this.groupService = this.baseService.one(`field-option-group/${group.uuid}`)
    this.optionsService = this.baseService.one(
      `field-option-group/${group.uuid}/options`,
    )
  }

  fetchOptions() {
    this.state.loadingFields = true
    this.optionsService
      .get()
      .then(options => options.plain())
      .then(options => {
        this.state.options = options
      })
      .catch(console.error)
      .finally(() => {
        this.state.loadingOptions = false
      })
  }

  resetGroup() {
    this.editableGroup = Object.assign({}, this.group)
    this.groupForm.$setPristine()
    this.toggleEdit()
  }

  saveGroup(group) {
    delete group.options // nested field not writable
    this.groupService
      .patch(group)
      .then(updatedGroup => {
        this.editableGroup = Object.assign({}, updatedGroup)
        this.groupForm.$setPristine()
        this.toggleEdit()
      })
      .catch(console.error)
  }

  updateOrCreateOption(group, option) {
    FOD.locals = {
      isCreate: !option,
      groupId: group.uuid,
      option: Object.assign({}, option),
      service: this.optionsService,
    }
    this.$mdDialog.show(FOD).then(({ wasCreate, option }) => {
      if (!wasCreate) {
        this.updateOrRemoveOption(option, { update: true })
        return
      }
      this.state.options.unshift(option)
    })
  }

  updateOptionsList(option = {}) {
    const optionIndex = findIndex(propEq('uuid', option.uuid))(
      this.state.options,
    )
    this.state.options.splice(optionIndex, 1, option)
  }

  deleteGroup(group) {
    DED.locals = {
      title: 'Delete Field Option Group',
      entityName: group.name,
      identityEndpoint: this.groupService,
      routeWhenSuccessful: false,
    }
    this.$mdDialog.show(DED).then(() => {
      this.removeGroup({ groupId: this.group.uuid })
    })
  }

  updateOrRemoveOption(option = {}, config = {}) {
    const { update } = config
    const index = findIndex(propEq('uuid', option.uuid))(this.state.options)
    update
      ? this.state.options.splice(index, 1, option)
      : this.state.options.splice(index, 1)
  }

  deleteOption(option) {
    DED.locals = {
      title: 'Delete Field Option',
      entityName: option.description,
      identityEndpoint: this.optionsService.one(option.uuid),
      routeWhenSuccessful: false,
    }
    this.$mdDialog.show(DED).then(() => {
      this.updateOrRemoveOption(option)
    })
  }
  openMenu($mdMenu, ev) {
    ev.cancelBubble = true
    $mdMenu.open(ev)
  }
}
