import { range } from 'ramda'

const fontSizes = Array.from(range(12, 25), num => ({
  value: num,
  display: `${num}px`,
}))

export default class PitchXmlController {
  constructor() {
    'ngInject'
    this.state = {
      selectedVersion: null,
    }
    this.version_xml = ''
    this.fontSizes = fontSizes
    this.editorOptions = {
      theme: 'monokai',
      fontSize: 12,
      readOnly: true,
      mode: 'xml',
    }
    this.appliedEditorOptions = Object.assign({}, this.editorOptions)
  }

  applyChanges() {
    this.appliedEditorOptions = Object.assign({}, this.editorOptions)
    this.appliedVersionXml = this.version_xml
  }

  $onChanges(bindings) {
    if (bindings.versions.isFirstChange()) {
      // Set the last version
      this.state.selectedVersion = this.versions.length
        ? this.versions[this.versions.length - 1].uuid
        : null
    }
    if (bindings.xmlRawService.isFirstChange() && this.state.selectedVersion) {
      this.getVersion()
    }
  }

  getVersion() {
    this.xmlRawService
      .one(`${this.state.selectedVersion}`)
      .get()
      .then(data => {
        this.version_xml = data.xml
        // Only enters for the first time to update the xml with the default values
        if (!this.appliedVersionXml) {
          this.applyChanges()
        }
      })
  }
}
