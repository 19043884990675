// create router route view

const manageCampaignRouterCreate = 'manage.campaignRouter.create'

const manageCampaignRouterCreateConfig = {
  abstract: true,
  component: 'rightDrawer',
}

export { manageCampaignRouterCreate, manageCampaignRouterCreateConfig }
