// Edit Campaign Data Posting view

const manageEditCampaignDataPosting = 'manage.editCampaign.dataPosting'

const manageEditCampaignDataPostingConfig = {
  url: '/dataposting',
  component: 'campaignDataPosting',
}

export { manageEditCampaignDataPosting, manageEditCampaignDataPostingConfig }
