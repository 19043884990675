// Dependencies
import angular from 'angular'
import dndLists from 'angular-drag-and-drop-lists'
import ngAnimate from 'angular-animate'
import ngAria from 'angular-aria'
import ngFileUpload from 'ng-file-upload'
import ngHighcharts from 'highcharts-ng'
import ngMarked from 'angular-marked'
import ngMaterial from 'angular-material'
import ngMdDataTable from 'angular-material-data-table'
import ngMdIcons from 'angular-material-icons'
import ngMessages from 'angular-messages'
import ngSmartTable from 'angular-smart-table'
import restangular from 'restangular/src/restangular.js'
import { name as ngClipboard } from 'angular-clipboard'
import { name as ngStorage } from 'ngstorage'

// Providers
import mdThemeProvider from './providers/themeProvider/theme.js'
import mdIconProvider from './providers/iconProvider/icons.js'
import { BaseRestangular } from './providers/restangularProvider/rest.js'
import { RCRestangular } from './providers/restangularProvider/rest.js'
import { AWSRestangular } from './providers/restangularProvider/rest.js'
import compileProvider from './providers/compileProvider/compile.js'

export default angular
  .module('portalApp.global.config', [
    ngAnimate,
    ngMaterial,
    ngMdIcons,
    ngAria,
    ngMdDataTable,
    ngSmartTable,
    ngMessages,
    restangular,
    ngStorage,
    ngHighcharts,
    ngClipboard,
    dndLists,
    ngMarked,
    ngFileUpload,
  ])
  .config(compileProvider)
  .config(BaseRestangular)
  .config(mdThemeProvider)
  .config(mdIconProvider)
  .factory('RCRestangular', RCRestangular)
  .factory('AWSRestangular', AWSRestangular).name
