// css
import './component/dialerQueue.scss'

// services
import DialerQueueService from './services/DialerQueueService.js'

// components
import dialerQueue from './component/dialerQueue.js'

export default angular
  .module('portalApp.dashboard.queue', [])
  .service('DialerQueueService', DialerQueueService)
  .component('dialerQueue', dialerQueue)
  .name
