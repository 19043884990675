import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'badNumbers',
  type: 'call',
  access: ['stats', 'causes'],
}

const formatting = {
  format: {
    title: 'Bad Numbers',
    sort: 'ascending',
    subSection: 'hangup_causes',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'sub_metric_health',
}

const calculation = {
  calcType: 'hangupCause',
  dividend: {
    stat: 'bad_numbers',
    causes: [
      'bearercapability_notauth',
      'bearercapability_notimpl',
      'exchange_routing_error',
      'incoming_call_barred',
      'incompatible_destination',
      'invalid_number_format',
      'no_answer',
      'no_route_destination',
      'no_route_transit_net',
      'no_user_response',
      'normal_unspecified',
      'number_changed',
      'originator_cancel',
      'outgoing_call_barred',
      'redirection_to_new_destination',
      'subscriber_absent',
      'unallocated_number',
      'user_busy',
    ],
  },
  divisor: { stat: 'calls' },
  isRate: true,
}

const badNumbers = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { badNumbers }
