import {
  CAMPAIGN_METRICS,
  REP_STACK_METRICS,
  REP_TREND_METRICS,
  GATEWAY_METRICS,
} from '../componentKeys.js'

const identifiers = {
  key: 'interruptsPerCall',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Interrupt / Call',
    sort: 'ascending',
  },
}

const keys = {
  componentKeys: [
    CAMPAIGN_METRICS,
    REP_STACK_METRICS,
    REP_TREND_METRICS,
    GATEWAY_METRICS,
  ],
  healthKey: 'metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'interrupt' },
  divisor: { stat: 'calls' },
}

const interruptsPerCall = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { interruptsPerCall }
