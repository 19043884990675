// @flow

import './titleBarComponent.scss'
import TitleBarComponentController from './controller/TitleBarComponent.controller.js'
import template from './titleBarComponent.tpl.pug'

type BarButton = {|
  title: string,
  icon: string,
  action: Function,
  className: string,
|}

export type TitleBarButtonConfig = BarButton[]

// TODO mark which properties are optional or required
const titleBarComponent = {
  template: template(),
  controller: TitleBarComponentController,
  bindings: {
    entity: '<',
    entityName: '@',
    entityPlural: '@',
    buttonsList: '<',
    refreshOptions: '=',
    isEditEntityPage: '@',
    backToRoute: '<',
    dialerControl: '<',
    hideMenu: '<',
  },
}

export default titleBarComponent
