import {
  errorParser,
  hideInvalid,
} from '../../../../../../../utils/managementUtils.js'

export default class CreateCampaignInfoController {
  constructor($state, $timeout, ManagementService, ToastService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.MS = ManagementService
    this.TS = ToastService
    this.hideInvalid = hideInvalid
    this.state = {
      actionTitle: 'Campaign Info',
      creationErrors: null,
      creatingCampaign: false,
      askNextAction: false,
    }
  }

  $onInit() {
    this.campaign = {
      realms: [],
      dialingparams: {},
      queue: {},
      warmtransfer: {},
      callback: {},
    }
    this.getCampaignRelationOptions(['company', 'realm'])
  }

  createCampaign(campaign) {
    this.resetErrors()
    this.state.actionTitle = 'Creating'
    this.state.creatingCampaign = true
    this.MS.createEntity({ entity: 'campaign', data: campaign })
      .then(() => {
        this.$timeout(() => {
          this.state.actionTitle = 'Campaign Created'
          this.state.askNextAction = true
        }, 2000)
      })
      .catch(err => {
        console.error('Error creating campaign: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, 2000)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creatingCampaign = false
        }, 2000)
      })
  }

  backTo() {
    this.$state.go('manage.campaigns')
  }

  resetErrors() {
    this.state.creationErrors = null
  }

  createAnother() {
    this.campaign = {}
    this.state.actionTitle = 'Campaign Info'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit() {
    this.$state.go('manage.campaigns', null, { reload: true })
  }

  getCampaignRelationOptions(relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
        this.realmOptions = relations.realm
      })
      .catch(err => {
        this.TS.show({
          text:
            'Problem fetching options, you wont be able to create a campaign',
        })
        console.error(err)
      })
  }
}
