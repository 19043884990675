import EditAccessListController from './controller/EditAccessListController.js'
const template = require('./editAccessList.component.tpl.pug')

const editAccessList = {
  controller: EditAccessListController,
  template: template(),
  bindings: {
    compSlug: '<',
    accessList: '<',
    openSections: '<',
  }
}

export default editAccessList
