export default function managementFilterFactory(IdentityService) {
  'ngInject'

  const groups = IdentityService.Groups
  const company = IdentityService.Company
  const campaign = IdentityService.Campaign
  const realm = IdentityService.Realm

  function getGroupOptions(assignable = true) {
    return function() {
      return groups.list.get({ assignable: true }).then(groups => {
        this.groups.optionsList = groups.plain()
      })
    }
  }

  function getCampaignOptions(assignable = true) {
    return function() {
      return campaign.list.get({ assignable: true }).then(campaigns => {
        this.campaigns.optionsList = campaigns.plain()
      })
    }
  }

  function getCompanyOptions(assignable = true) {
    return function() {
      return company.list.get({ assignable }).then(companies => {
        this.company.optionsList = companies.plain()
      })
    }
  }

  function getRealmOptions(assignable = true) {
    return function() {
      return realm.list.get({ assignable }).then(realms => {
        this.realms.optionsList = realms.plain()
      })
    }
  }

  const options = {
    getGroupOptions,
    getCampaignOptions,
    getCompanyOptions,
    getRealmOptions,
  }

  function optionFetcher(optionsKey, { assignable }) {
    return options[optionsKey](assignable)
  }

  return {
    groups: 'getGroupOptions',
    campaigns: 'getCampaignOptions',
    companies: 'getCompanyOptions',
    realms: 'getRealmOptions',
    optionFetcher,
  }
}
