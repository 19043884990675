// @flow

// FIXME: https://github.com/gajus/eslint-plugin-flowtype/issues/336#issuecomment-393972112
// waiting until this issue is resolved then we can remove the linter disables

// eslint-disable-next-line no-unused-vars
import type ManagementService from '../../../services/ManagementService.js'
// eslint-disable-next-line no-unused-vars
import type ToastService from '../../../../global/index/services/ToastService.js'
import type ManageUsersService from '../services/ManageUsersService.js'
import type { BaseState } from '../../../controllers/ManagementController.js'
import type { User } from 'types/entities.js'
import type { FilterConfig } from '../../../common-components/filter-toolbar/component/filterToolbar.component.js'
import type { TitleBarButtonConfig } from '../../../../common-components/title-bar/component/titleBarComponent.js'

import { containsRole, addColumns } from '../../../utils/managementUtils.js'
import ManagementController from '../../../controllers/ManagementController.js'
import { ADMIN_GROUP } from 'permissions.js'

export default class ManageUsersController extends ManagementController {
  MUS: ManageUsersService
  filterConfig: FilterConfig
  topBarButtons: TitleBarButtonConfig
  state: BaseState

  constructor(
    $state: Object,
    $mdDialog: Object,
    ManagementService: ManagementService,
    ToastService: ToastService,
    RoleStore: Object,
    ManageUsersService: ManageUsersService,
  ) {
    'ngInject'
    super(...arguments)
    this.MUS = ManageUsersService
  }

  $onInit() {
    const userRoles = this.RS.getAllRoles()

    this.entity = 'user'
    this.entityList = []
    this.editState = 'manage.users.edit'
    this.createState = 'manage.users.create.personalInfo'
    this.detailsDialog = 'userDetails'
    this.tableConfig = this.bindActionCallbacks(this.MUS.tableConfig)
    this.searchConfig = this.MUS.searchConfig
    this.filterConfig = this.MUS.filterConfig
    this.searchConfig.editable = containsRole(
      userRoles,
      this.MUS.defaultEditRoles,
    )
    this.topBarButtons = [
      {
        title: 'New User',
        icon: 'content:add',
        action: this.createEntity.bind(this),
        className:
          'md-raised pp-button md-accent md-button ng-scope md-ink-ripple layout-row',
      },
    ]
    this.state.queryParams = this.getInitialParams(this.filterConfig)
    this.state.queryParams.limit = this.paginateLimit

    const addColumnsOptions = {
      userRoles,
      allowedRoles: ADMIN_GROUP,
      sliceIndex: this.tableConfig.columns.length - 3,
      columns: this.tableConfig.columns,
      addColumns: this.tableConfig.optionalColumns,
    }

    this.tableConfig.columns = addColumns(addColumnsOptions)
    this.getEntityList(
      this.state.queryParams,
      this.entity,
      this.searchConfig.editable,
    )
  }

  onUserUpdate(user: User) {
    const entityListIndex = this.MS.getEntityIndex(user.uuid, this.entityList)
    const searchListIndex = this.MS.getEntityIndex(
      user.uuid,
      this.searchConfig.searchResults,
    )
    this.MS.updateEntityInList(entityListIndex, this.entityList, user)
    this.MS.updateEntityInList(
      searchListIndex,
      this.searchConfig.searchResults,
      user,
    )
  }
}
