// @flow

import type { Page } from '../side-nav/services/SideNavService.js'

import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ArrowBack from '@material-ui/icons/ArrowBackIos';

const styles = theme => ({
    goBack: {
        color: '#1194f6',
        fontSize: '16px',
        textDecoration: 'none'
    },
    container: {
        width: '100%',
        marginBottom: '25px'
    },
    status: {
        '& span': {
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60px',
            height: '20px',
            textTransform: 'capitalize',
            borderRadius: '3px',
            color: 'white',
            fontSize: '12px',
            '&.Active': {
                backgroundColor: '#6698c7',
            },
            '&.Inactive': {
                backgroundColor: '#ff504d',
            },
        },
    },
})

type Props = {
    classes: Object,
    title: string,
}


class GeneralHeader extends React.Component<Props, State> {

    render() {
        const { goBack, container, status } = this.props.classes
        const padding = this.props.padding || 0
        const name = this.props.title.toLowerCase();

        return (
            <React.Fragment>
                <Grid
                    style={{ padding: padding }}
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <div className={container}>
                        <a href={window.location.origin + window.location.pathname + '#!/manage/' + name} className={goBack}>
                            <ArrowBack style={{ fontSize: '12px' }} /> Back to {this.props.title}
                        </a>
                    </div>

                    <div>
                        <span style={{ fontSize: '24px', color: '#444851' }}>{this.props.name}</span>
                        <div style={{ display: 'inline-block', marginLeft: '20px' }} className={status}>
                            <span className={this.props.activeTxt}>{this.props.activeTxt}</span></div>
                    </div>
                </Grid>
            </React.Fragment>
        )

    }
}

export default withStyles(styles)(GeneralHeader)
