import { cond, equals, always, T, F } from 'ramda'

const getNoAudioText = cond([
  [equals('Unrecorded'), always('No unrecorded audio')],
  [equals('Rerecord'), always('No audio to rerecord')],
  [equals('Recorded'), always('No recorded audio')],
])

const hasFiles = cond([
  [equals('Recorded'), T],
  [equals('Rerecord'), T],
  [equals('Unrecorded'), F],
])

export default class VoiceAudioTableController {
  constructor($scope, $sessionStorage, ManageAudioService, PitchService) {
    'ngInject'

    this.$scope = $scope
    this.MAS = ManageAudioService
    this.PS = PitchService
    this.hasFiles = hasFiles
    this.showSearch = false
    this.searchAudio = ({ searchText }) => {
      this.searchText = searchText
    }
  }

  $onInit() {
    // potential for a performance problem, deep object equality checking
    this.$scope.$watch(
      '$ctrl.audioList',
      (newValue, oldValue) => {
        if (newValue && newValue.length) {
          this.audioList = newValue
        }
      },
      true,
    )
  }

  $onChanges(bindings) {
    if (bindings.tableTitle && bindings.tableTitle.isFirstChange()) {
      this.noAudioText = getNoAudioText(this.tableTitle)
    }
  }

  populateAudioFiles(audioList) {
    this.MAS.populateAudioFiles({
      service: this.audioService,
      audio: audioList,
    })
      .then(populatedAudio => {
        this.audioList = populatedAudio
      })
      .catch(err => {
        console.error(err)
      })
  }

  toggleSearch() {
    this.showSearch = !this.showSearch
    if (!this.showSearch) {
      this.searchText = ''
    }
  }
}
