export default class AudioDialogController {
  constructor($interval, $mdDialog, ToastService, ManageAudioService) {
    'ngInject'
    this.$interval = $interval
    this.$mdDialog = $mdDialog
    this.TS = ToastService
    this.MAS = ManageAudioService
    this.file = null
    this.fileName = 'Not file choosen'
    this.uploadProgress = {}
    this.uploadOptions = {
      fadeIn: false,
      fadeOut: false,
      convert: true,
      noModification: false,
    }
  }

  setFile(fileElement) {
    if (fileElement && fileElement.files.length === 1) {
      this.fileName = fileElement.files[0].name
      this.$scope.$apply()
    }
  }

  upload() {
    this.inputFiles = document.getElementById('file')

    if (
      this.inputFiles &&
      this.inputFiles.files.length === 1 &&
      /\.wav$/i.test(this.inputFiles.files[0].name)
    ) {
      this.uploadAudioFile(this.inputFiles.files[0])
        .then(res => {
          this.TS.show({
            text: `File upload successful`,
          })
          this.close()
        })
        .catch(this.handleUploadError.bind(this))
    } else {
      this.TS.show({
        text: `Error on the selected audio file, choose a valid audio file with extension .wav`,
      })
    }
  }

  uploadAudioFile(audioFile) {
    const path = /pitch|prospect/.test(this.audioType)
      ? `${this.selectedAudio.key}/upload`
      : `phrase/${this.selectedAudio.uuid}/upload`
    return this.MAS.uploadAudioFile({
      service: this.audioService.one(path),
      fileName: this.selectedAudio.name,
      file: audioFile,
      uploadOptions: this.uploadOptions,
      progressHandler: ev => {
        const progress = Math.floor((ev.loaded * 100) / ev.total)
        this.uploadProgress[this.selectedAudio.key] = progress
      },
    })
  }

  handleUploadError(err) {
    this.errors = err.data.map(err => 'Upload Error: '.concat(err))
    console.error(err)
  }

  close() {
    this.$mdDialog.hide()
  }
}
