// dashboard.overview view

const dashboardOverview = 'dashboard.overview'

const dashboardOverviewConfig = {
  url: '/overview',
  params: {
    realmSlug: null
  },
  component: 'overview',
  resolve: {
    company: function (currentCompany) {
      'ngInject'
      return currentCompany
    },
    campaign: function (selectedCampaign, RealmService) {
      'ngInject'
      return selectedCampaign
    },
    offsetMinutes: function (timezoneOffsetMinutes) {
      'ngInject'
      return timezoneOffsetMinutes
    },
    realm: function (selectedRealm) {
      'ngInject'
      return selectedRealm
    },
  }
}

export { dashboardOverview, dashboardOverviewConfig }
