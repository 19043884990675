function makePageTitle ({title = '', resolveEntity = '', accessor = ''}) {
  const angularWindow = ['$window']
  const injectables = resolveEntity ? angularWindow.concat(resolveEntity) : angularWindow

  let makeTitle = function ($window, resolveEntity) {
    'ngInject'
    const pageTitle = title
    $window.document.title = resolveEntity ? `${pageTitle}${resolveEntity[accessor]}` : `${pageTitle}`
    return pageTitle
  }

  makeTitle.$inject = injectables
  return makeTitle
}

export { makePageTitle }

