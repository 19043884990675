export default class FilterToolbarController {
  $onInit () {
    this.setAsyncOptionLists(this.filterConfig)
  }

  setAsyncOptionLists (filterConfig) {
    filterConfig.forEach((selector) => {
      if (typeof selector.options === 'function') {
        selector.options = selector.options.bind(this)
        this[selector.field] = { optionsList: [] }
      }
    })
  }

  getTypeOf (value) {
    return typeof value
  }
}
