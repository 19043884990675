import moment from 'moment'
import { contains } from 'ramda'
import { getRealmSlug } from 'util/realm.js'

export default class ListController {
  constructor($mdToast, $state, $stateParams, $anchorScroll, ListsService) {
    'ngInject'

    this.$mdToast = $mdToast
    this.$state = $state
    this.$stateParams = $stateParams
    this.$anchorScroll = $anchorScroll
    this.LS = ListsService

    this.onPaginate = page => {
      this.getLists({
        company: this.company,
        campaign: this.campaign,
        offsetMinutes: this.offsetMinutes,
        pageNum: page,
      })
    }

    this.onSearchUpdate = ({ searchText }) => {
      this.state.searchText = searchText
      this.searchProspectLists(searchText)
    }
  }

  $onInit() {
    this.dateTime = moment.utc().utcOffset(this.offsetMinutes)
    this.today = this.dateTime.startOf('month').toDate()

    this.state = {
      loading: false,
      company: this.company,
      campaign: this.campaign,
      searchText: '',
      lists: [],
      listsDisplay: [],
      pagination: {
        lowerBound: 1,
        upperBound: 25,
        currentPage: 1,
      },
    }

    if (this.listRealm) {
      this.state.listRealm = { name: this.listRealm, slug: this.listRealm }
      this.getLists({
        company: this.company,
        campaign: this.campaign,
        offsetMinutes: this.offsetMinutes,
        realm: this.listRealm,
        pagenum: null,
      })
    }
  }

  getRealms() {
    return this.LS.getRealms().then(realms => {
      this.realmsList = realms.filter(realm =>
        contains(realm.uuid, this.campaign.realms),
      )
    })
  }

  changeListRealm(listRealm) {
    this.$state.go(
      'dashboard.lists',
      { listRealmSlug: listRealm },
      { location: 'replace' },
    )
    this.getLists({
      company: this.company,
      campaign: this.campaign,
      offsetMinutes: this.offsetMinutes,
      realm: listRealm,
      pagenum: null,
    })
  }

  searchProspectLists(searchText) {
    this.getLists({
      company: this.company,
      campaign: this.campaign,
      offsetMinutes: this.offsetMinutes,
      realm: getRealmSlug(this.realm),
      pagenum: null,
      searchText,
    })
  }

  getLists(options) {
    this.state.loading = true
    this.tablePromise = this.LS.getLists(options)
      .then(data => {
        this.state.lists = data.lists
        this.state.listsDisplay = [...data.lists]
        this.state.pagination = Object.assign(this.state.pagination, {
          next: data.pagination.next,
          previous: data.pagination.previous,
          count: data.pagination.count,
        })
        this.state.loading = false
        this.scrollToTop('table-top')
        return data
      })
      .then(this.LS.getListStats.bind(this.LS))
      .then(data => {
        this.state.lists = data.lists
        this.state.listsDisplay = data.lists.data
      })
      .catch(err => {
        this.$mdToast.show(
          this.$mdToast
            .simple()
            .textContent('Problem Fetching Lists')
            .position('top right')
            .theme('default')
            .hideDelay(5000),
        )
      })
      .finally(() => {
        this.state.loading = false
      })
  }

  scrollToTop(hash) {
    this.$anchorScroll(hash)
  }

  // angular expressions cannot access the window object so this has to be
  // wrapped in a controller function
  isFinite(num) {
    return isFinite(num)
  }

  toggleMetrics(index) {
    const show = this.state.listsDisplay[index].showMetrics
    if (show) {
      this.state.listsDisplay[index].showMetrics = !show
      return
    }
    this.state.listsDisplay[index].showMetrics = true
  }
}
