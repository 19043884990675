import './pitchRapidResponse.component.scss'
import PitchRapidResponseController from './controller/PitchRapidResponseController.js'
import template from './pitchRapidResponse.component.tpl.pug'

const pitchRapidResponse = {
  template: template(),
  controller: PitchRapidResponseController,
  bindings: {
    companyService: '<',
    globalService: '<',
    rrTests: '<',
    onUpdate: '&',
  },
}

export default pitchRapidResponse
