import './editLocation.component.scss'
import EditLocationController from './controller/EditLocationController.js'
const template = require('./editLocation.component.tpl.pug')

const editLocation = {
  template: template(),
  controller: EditLocationController,
  bindings: {
    location: '<'
  }
}

export default editLocation
