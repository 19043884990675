import { errorParser, hideInvalid } from '../../../../../utils/managementUtils.js'

export default class ManageAccessListsCreateController {
  constructor ($state, $timeout, ManagementService, ToastService) {
    'ngInject'

    this.$state = $state
    this.$timeout = $timeout
    this.MS = ManagementService
    this.TS = ToastService
    this.hideInvalid = hideInvalid
    this.state = {
      actionTitle: 'Create Access List',
      creationErrors: null,
      creating: false,
      askNextAction: false,
    }
  }

  $onInit () {
    this.accessList = {}
    this.accessList['compSlug'] = this.compSlug
    this.allowOptions = [
      {'key': 1, 'value': 'Yes'},
      {'key': 0, 'value': 'No'},
    ]
    this.getAclRelationOptions([ 'company' ])
  }

  backTo () {
    this.$state.go('manage.accessLists')
  }

  resetErrors () {
    this.state.creationErrors = null
  }

  create (accessList) {
    accessList = angular.copy(accessList)
    this.resetErrors()
    this.state.actionTitle = 'Creating'
    this.state.creating = true
    if (accessList.company === 'global') {
      accessList.company = null
    }
    this.MS.createEntity({ entity: 'acl', data: accessList })
      .then(acl => {
        this.$timeout(() => {
          this.state.actionTitle = 'Access List Created'
          this.state.askNextAction = true
          this.compSlug = accessList.company
        }, 2000)
      })
      .catch(err => {
        console.error('Error creating Access List: ', err)
        this.$timeout(() => {
          this.state.creationErrors = errorParser(err.data)
        }, 2000)
      })
      .finally(() => {
        this.$timeout(() => {
          this.state.creating = false
        }, 2000)
      })
  }

  createAnother () {
    this.accessList = {}
    this.accessList['compSlug'] = this.compSlug
    this.state.actionTitle = 'Create Access List'
    this.state.creationErrors = null
    this.state.askNextAction = false
  }

  exit () {
    this.$state.go('manage.accessLists', {compSlug: this.compSlug}, { reload: true })
  }

  getAclRelationOptions (relationOptions) {
    this.MS.getRelationOptions(relationOptions, { editable: false })
      .then(relations => {
        this.companyOptions = relations.company
      })
      .catch(err => {
        this.TS.show({
          text: 'Problem fetching options, you wont be able to create a access list'
        })
        console.error(err)
      })
  }
}
