import { errorParser } from '../../../utils/managementUtils.js'

export default class ChangePasswordDialogController {
  constructor ($mdDialog, $timeout, ManageUsersService) {
    'ngInject'

    this.$timeout = $timeout
    this.$mdDialog = $mdDialog
    this.MUS = ManageUsersService
    this.containsDigit = /\d/
    this.state = {
      updating: false,
      updateErrors: [],
      updateSuccess: false,
    }
  }

  changePassword (user) {
    this.dismissErrors()
    this.state.updating = true
    const changeData = {
      id: this.userId,
      new_password: user.newPassword,
      re_new_password: user.reNewPassword
    }
    this.MUS.changeUserPassword(changeData)
      .then(res => {
        this.$timeout(() => {
          this.state.updating = false
          this.state.updateSuccess = true
        }, 1000)
      })
      .catch((err) => {
        this.state.updating = false
        this.state.updateErrors = errorParser(err.data)
      })
  }

  dismissErrors () {
    this.state.updateErrors = []
  }

  close () {
    this.$mdDialog.hide()
  }
}
