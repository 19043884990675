import { MANAGE_GROUP } from 'permissions.js'
import { gateway } from '../../../gateway/gateway.js'
// manage users view

const manageUsers = 'manage.users'

const manageUsersConfig = {
  url: '/users',
  component: 'manageUsers',
  data: {
    permissions: {
      only: [...MANAGE_GROUP],
      redirectTo: gateway,
    },
  },
  resolve: {
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Manage Users'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageUsers, manageUsersConfig }
