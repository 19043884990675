import './editTeam.component.scss'
import EditTeamController from './controller/EditTeamController.js'
const template = require('./editTeam.component.tpl.pug')

const editTeam = {
  template: template(),
  controller: EditTeamController,
  bindings: {
    team: '<',
    location: '<',
  }
}

export default editTeam
