// Edit realms view

const manageEditRealms = 'manage.editRealms'

const manageEditRealmsConfig = {
  url: '/realms/edit/{realmId}',
  params: {
    realmId: null,
  },
  component: 'manageRealmsEdit'
}

export { manageEditRealms, manageEditRealmsConfig }
