export default class DialerLiveService {
  constructor (ReportService) {
    'ngInject'
    this.RS = ReportService
  }

  getLiveDialerStatusReports (campaignSlugs, realmSlugs) {
    const params = {}
    if (campaignSlugs) {
      params.campaigns = campaignSlugs
    }
    if (realmSlugs) {
      params.realms = realmSlugs
    }
    const url = '/live/dialer/'
    return this.RS.reporting
          .one(url)
          .get(params)
          .then((data) => {
            return data.plain()
          })
  }
}
