import './campaignInfoForm.component.scss'
import CampaignInfoFormController from './controller/CampaignInfoFormController.js'
const template = require('./campaignInfoForm.component.tpl.pug')

const campaignInfoForm = {
  template: template(),
  controller: CampaignInfoFormController,
  bindings: {
    user: '<',
    campaigns: '<',
    lastState: '<',
  }
}

export default campaignInfoForm
