import { DATA_METRICS } from '../componentKeys.js'

const identifiers = {
  key: 'voicemailRate',
  type: 'call',
  access: 'stats',
}

const formatting = {
  format: {
    title: 'Voicemail',
    sort: 'ascending',
    subSection: 'disposition',
  },
}

const keys = {
  componentKeys: [DATA_METRICS],
  healthKey: 'sub_metric_health',
}

const calculation = {
  calcType: 'divide',
  dividend: { stat: 'voicemail' },
  divisor: { stat: 'calls' },
  isRate: true,
}

const voicemailRate = {
  metaData: Object.assign(identifiers, formatting, keys, calculation),
  calculationConfig: calculation,
}

export { voicemailRate }
