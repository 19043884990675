// Edit Rapid Response Test view

const manageEditRapidResponseTests = 'manage.editRapidResponseTests'

const manageEditRapidResponseTestsConfig = {
  url: '/rapid-response-tests/{compSlug}/edit/{testUuid}',
  params: {
    compSlug: null,
    testUuid: null,
    entity: null,
    entityIndex: null,
  },
  component: 'editRapidResponseTests',
  resolve: {
    compSlug: function($transition$) {
      'ngInject'
      return $transition$.params().compSlug || 'global'
    },
    testUuid: function($transition$) {
      'ngInject'
      return $transition$.params().testUuid || null
    },
    rapidResponseTest: function($transition$, PitchService) {
      'ngInject'
      const entity = $transition$.params().entity
      if (entity) return entity
      let compSlug = $transition$.params().compSlug || 'global'
      let services = PitchService.setupRapidResponse(compSlug)
      let service = compSlug === 'global' ? services.global : services.company
      return service
        .one('tests')
        .one($transition$.params().testUuid)
        .get()
        .then(rapidResponseTest => rapidResponseTest.plain())
    },
    pageTitle: function($window) {
      'ngInject'
      const pageTitle = 'Editing Rapid Response Test'
      $window.document.title = pageTitle
      return pageTitle
    },
  },
}

export { manageEditRapidResponseTests, manageEditRapidResponseTestsConfig }
