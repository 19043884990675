import './editVariables.scss'

import editVariablesController from './controller/editVariablesController'
const template = require('./editVariables.tpl.pug')

const editVariables = {
  template: template(),
  controller: editVariablesController,
  bindings: {
    entityName: '<',
    entity: '<',
    company: '<',
    endpoint: '<'
  }
}

export default editVariables
