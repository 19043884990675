
class DirectiveCtrl {

}

function linkFunc (scope, elem, attrs, ctrl) {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  elem.on('click', () => {
    const file = ctrl.cb()()
    const blob = new Blob([file.data], { type: ctrl.downloadType })
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = file.fileName
    a.click()
    window.URL.revokeObjectURL(url)
  })
}

export default function () {
  return {
    restrict: 'A',
    scope: {},
    controller: DirectiveCtrl,
    controllerAs: '$ctrl',
    bindToController: {
      cb: '&downloadFunc', // A callback function that should return the raw file type data
      downloadType: '@' // A string defining the MIME type of the downloadable resource
    },
    link: linkFunc
  }
}
