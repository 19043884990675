import React from 'react'
import { Modal, Grid, Paper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 1,
    paddingBottom: 10,
    marginTop: '5%',
    marginBottom: '40px',
  },
  root: {
    height: 100 + 'vh',
    flexGrow: 1,
    overflow: 'auto !important',
  },
  closeButton: {
    position: 'absolute',
    right: 18,
    top: 18,
    cursor: 'pointer',
  },
  header: {
    position: 'relative',
    height: 43,
    paddingTop: 17,
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#5f7d98',
  },
}))

const AppModal = props => {
  const classes = useStyles()
  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={classes.root}
    >
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        <Paper
          className={classes.paper}
          style={{
            width: props.modalWith || 500,
            height: props.modalHeight || 'auto',
          }}
        >
          <Grid container justify="center">
            <Grid item xs={12}>
              <div className={classes.header}>
                <span>{props.title}</span>
                <CloseIcon
                  onClick={props.hideModal}
                  className={classes.closeButton}
                />
              </div>
            </Grid>
          </Grid>
          {props.children}
        </Paper>
      </Grid>
    </Modal>
  )
}

export default AppModal
