// @flow

import type ToastService from '../../../../global/index/services/ToastService.js'
import type { FilterListConfig } from '../filterList.js'

export default class FilterListController {
  TS: ToastService
  listName: string
  entity: string
  entityPlural: string
  isLoading: boolean
  tableConfig: FilterListConfig
  tableData: Array<any>
  handleSearch: ({ searchText: string }) => void
  state: {
    searchText: string,
  }

  constructor(ToastService: ToastService) {
    'ngInject'
    this.TS = ToastService
    this.tableData = []
    this.handleSearch = ({ searchText }) => {
      this.state.searchText = searchText
    }
    this.state = {
      searchText: '',
    }
  }

  handleCopy(name: string): void {
    this.TS.show({
      text: name ? `${name} copied to clipboard` : 'Copied to clipboard',
    })
  }

  openMenu($mdMenu: Object, ev: Event) {
    $mdMenu.open(ev)
  }
}
